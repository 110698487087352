import {formatDate} from "../../../util/utilFunctions";
import {isDate} from "date-fns";

const DateCellRenderer = (date: Date): string => {
  let renderValue = "-"
  try {
    if (isDate(date) && !isNaN(date.valueOf())) {
      renderValue = formatDate(date);
    }
  } catch (e) {}
  return renderValue;
};
export default DateCellRenderer;
