import { Box, Button, Divider, SimpleGrid, Text } from "@chakra-ui/react";
import debounce from "lodash.debounce";
import React, { useCallback, useContext, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useInfinitePaginatedPlayers } from "../../../hooks/usePlayers";
import { useScrollPositionStore, useSearchResultSize } from "../../../store/store";
import ScrollPortal from "../../general/ScrollPortal";
import PlayerCard from "./PlayerCard";
import PlayerCardSkeleton from "./PlayerCardSkeleton";
import { LanguageContext } from "../../../store/languageContext";
import { LanguageKeys } from "../../../language/LanguageKeys";

interface Props {
  onOpenModal: () => void;
}

const PlayerGrid = ({ onOpenModal }: Props) => {
  const { guiText } = useContext(LanguageContext);
  const {
    data,
    error,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfinitePaginatedPlayers();

  const skeletons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const { setSize } = useSearchResultSize();

  const { scrollPosition, setScrollPosition } = useScrollPositionStore();

  useEffect(() => {
    if (data?.pages[0].totalResultSetSize !== undefined) {
      setSize(data?.pages[0].totalResultSetSize);
    }
  }, [data, setSize]);

  const fetchedPlayersCount =
    data?.pages.reduce(
      (total, page) => total + (page.players?.length ?? 0),
      0
    ) ?? 0;

  const handleScroll = useCallback(
    debounce((event) => {
      if (event && event.target) {
        setScrollPosition(event.target.scrollTop);
      }
    }, 500),
    []
  );

  useEffect(() => {
    return () => handleScroll.cancel();
  }, [handleScroll]);

  if (error) return <Text>{error.message}</Text>;

  return (
    <ScrollPortal
      id="player-grid-scroll-container"
      width="100%"
      onScroll={handleScroll}
      data-cy={"playerGrid"}
    >
      <InfiniteScroll
        dataLength={fetchedPlayersCount}
        hasMore={!!hasNextPage}
        next={() => fetchNextPage()}
        loader={false}
        scrollableTarget="player-grid-scroll-container"
        initialScrollY={scrollPosition}
      >
        <SimpleGrid
          columns={{ sm: 1, md: 2, lg: 3, xl: 4, "2xl": 5 }}
          spacing={30}
          m={2}
          pb={2}
        >
          {isLoading &&
            skeletons.map((skeleton) => <PlayerCardSkeleton key={skeleton} />)}
          {data?.pages.map((page, pageIndex) => (
            <React.Fragment key={pageIndex}>
              {page.players?.map((player, playerIndex) => (
                <PlayerCard
                  player={player}
                  key={playerIndex}
                  onOpenModal={onOpenModal}
                />
              ))}
            </React.Fragment>
          ))}
        </SimpleGrid>
        {hasNextPage && (
          <Box mr={2} ml={2} mb={2}>
            <Divider width="100%" borderColor="gray.300" />
            <Button
              width="100%"
              onClick={() => fetchNextPage()}
              boxShadow="md"
              bg="white"
              fontWeight="semibold"
              mt={3}
            >
              {isFetchingNextPage ? "Loading..." : "Load more"}
            </Button>
          </Box>
        )}
        {data?.pages[0].players?.length === 0 && !isLoading && (
          <Text>{guiText[LanguageKeys.KeineSpielerGefunden]}</Text>
        )}
      </InfiniteScroll>
    </ScrollPortal>
  );
};

export default PlayerGrid;
