import { Box, Center, HStack, Spacer, Text } from "@chakra-ui/react";
import {
  Chart as ChartJS,
} from "chart.js";
import { useContext, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { useLocation } from "react-router-dom";
import { useMarketHistory } from "../../../hooks/usePlayer";
import transformMarketValueData, {
  createOptions,
  imageOnTopPlugin,
} from "../../../hooks/usePrepareMarketHistoryData";
import { LanguageContext } from "../../../store/languageContext";
import { LanguageKeys } from "../../../language/LanguageKeys";
import { textColors } from "../../../colorStyles";

const MarketHistory = () => {
  const { guiText } = useContext(LanguageContext);
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const idTm = Number(queryParams.get("idTm"));

  const { data: marketValues } = useMarketHistory(idTm);

  const [transformedDataState, setTransformedDataState] = useState<any>();

  useEffect(() => {
    if (marketValues) {
      (async () => {
        try {
          const data = await transformMarketValueData(marketValues);
          setTransformedDataState(data);
        } catch (err) {
          console.log(err);
        }
      })();
    }
  }, [marketValues]);

  ChartJS.register(imageOnTopPlugin);

  let maxValue = 0;
  if (marketValues && marketValues.length > 0) {
    const marketWerts = marketValues
      .map((value) => value.marktwert)
      .filter(
        (marktwert) => marktwert !== null && marktwert !== undefined
      ) as number[];
    maxValue = Math.max(...marketWerts);
  }

  const chartOptions = createOptions(maxValue * 2);

  return (
    <>
      {marketValues && transformedDataState ? (
        <Box flex={1} display="flex" flexDir="column">
          <HStack p={5} flex={0}>
            <Box width="50%" color={textColors.primary}>
              <Text fontWeight="semibold">{guiText[LanguageKeys.SpielerprofilAktuellerMarktwert]}</Text>
              <Text>
                {Intl.NumberFormat("de-DE", {
                  style: "currency",
                  currency: "EUR",
                }).format(marketValues[marketValues.length - 1].marktwert!)}
              </Text>
            </Box>
            <Box width="50%" color={textColors.primary}>
              <Text fontWeight="semibold">{guiText[LanguageKeys.SpielerprofilMaximalerMarktwert]}</Text>
              <Text>
                {Intl.NumberFormat("de-DE", {
                  style: "currency",
                  currency: "EUR",
                }).format(maxValue)}
              </Text>
            </Box>
          </HStack>
          <Box flex={1} display="flex">
            <Box w="99%" h="99%">
              <Line options={chartOptions} data={transformedDataState} />
            </Box>
          </Box>
        </Box>
      ) : (
        <Center h="100%" w="100%">
          <Text fontSize={20} color={textColors.primary}>{guiText[LanguageKeys.KeineMarktdatenVorhanden]}</Text>
        </Center>
      )}
    </>
  );
};

export default MarketHistory;
