import { CopyIcon } from "@chakra-ui/icons";
import { IconButton } from "@mui/material";
import { ShadowteamOverview } from "../../../generated";
import { useDuplicateShadowteam } from "../../../hooks/useShadowteams";

interface Props {
  shadowteam: ShadowteamOverview;
}

const DuplicateIcon = ({ shadowteam }: Props) => {
  const duplicate = useDuplicateShadowteam();

  return (
    <IconButton
      aria-label="Duplicate"
      onClick={() => duplicate(shadowteam.shadowteamId)}
    >
      <CopyIcon boxSize="18px" color="#8a8a8a" />
    </IconButton>
  );
};

export default DuplicateIcon;
