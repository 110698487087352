import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Draggable } from "react-beautiful-dnd";
import {
  Shadowteam,
  ShadowteamOverview,
  WatchlistEntry,
} from "../generated";
import { useGetAllShadowteams } from "../hooks/useShadowteams";
import { useComparisonStore } from "../store/store";
import PlayerViewSmall from "./comparison/PlayerViewSmall";
import { StrictModeDroppable } from "./comparison/StrictModeDroppable";
import { useContext, useEffect } from "react";
import { LanguageContext } from "../store/languageContext";
import { LanguageKeys } from "../language/LanguageKeys";
import { colorsSecondary, textColors } from "../colorStyles";

interface Props {
  title?: string;
  selection: LanguageKeys;
  setSelection: (selection: LanguageKeys) => void;
  shadowteamSelection: ShadowteamOverview | undefined;
  setShadowteamSelection: (
    shadowteamSelection: ShadowteamOverview | undefined
  ) => void;
  shadowteamAside: Shadowteam | undefined;
  watchlist: WatchlistEntry[] | undefined;
}

const PlayerSelectAside = ({
  title,
  selection,
  setSelection,
  shadowteamSelection,
  setShadowteamSelection,
  shadowteamAside,
  watchlist,
}: Props) => {
  const { guiText, language } = useContext(LanguageContext);
  const { data: shadowteams } = useGetAllShadowteams();

  useEffect(() => {
  }, [language]);

  const { players: comparisonPlayers } = useComparisonStore();
  return (
    <Box w="320px" minW="320px" pr={4} display="flex" flexDir="column" data-cy="playerSelect">
      {title && (
        <Heading pb={3} fontSize="24" color={textColors.primary} fontFamily={"Text_Regular"}>
          {title}
        </Heading>
      )}
      <Menu>
        <MenuButton
          bg={colorsSecondary.cardBackground}
          color={textColors.primary}
          as={Button}
          rightIcon={<ChevronDownIcon />}
          w="100%"
          textAlign="start"
          mb={2}
          boxShadow="sm"
          h="45px"
          data-cy={"categoryDropdown"}
        >
          <Text fontWeight="normal">{guiText[selection]}</Text>
        </MenuButton>
        <MenuList backgroundColor={colorsSecondary.cardBackground}>
          <MenuItem onClick={() => setSelection(LanguageKeys.SidebarWatchlist)} backgroundColor={colorsSecondary.cardBackground} color={textColors.primary}>
            {guiText[LanguageKeys.SidebarWatchlist]}
          </MenuItem>
          <MenuItem onClick={() => setSelection(LanguageKeys.SidebarSchattenmannschaften)} backgroundColor={colorsSecondary.cardBackground} color={textColors.primary}>
            {guiText[LanguageKeys.SidebarSchattenmannschaften]}
          </MenuItem>
          <MenuItem onClick={() => setSelection(LanguageKeys.SpielervergleichWarenkorb)} backgroundColor={colorsSecondary.cardBackground} color={textColors.primary}>
            {guiText[LanguageKeys.SpielervergleichWarenkorb]}
          </MenuItem>
        </MenuList>
      </Menu>

      {selection === LanguageKeys.SidebarSchattenmannschaften && (
        <Menu>
          <MenuButton
            bg={colorsSecondary.cardBackground}
            color={textColors.primary}
            as={Button}
            rightIcon={<ChevronDownIcon />}
            w="100%"
            textAlign="start"
            mb={2}
            boxShadow="sm"
            h="40px"
            data-cy={"shadowteamDropdown"}
          >
            <Text fontWeight="normal">
              {shadowteamSelection
                ? shadowteamSelection.shadowteamName
                : guiText[LanguageKeys.WaehleEineSchattenmannschaft] + "..."}
            </Text>
          </MenuButton>
          <MenuList backgroundColor={colorsSecondary.cardBackground}>
            {shadowteams?.map((team, idx) => (
              <MenuItem key={idx} onClick={() => setShadowteamSelection(team)} backgroundColor={colorsSecondary.cardBackground} color={textColors.primary}>
                {team.shadowteamName}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      )}

      <StrictModeDroppable droppableId="comparisonList">
        {(provided) => (
          <Box
            flex={1}
            overflow="hidden"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <VStack
              overflow="auto"
              w="100%"
              h="100%"
              spacing={1}
              bg={colorsSecondary.cardBackground}
              borderRadius={5}
              boxShadow="md"
              p={2}
              sx={{
                "::-webkit-scrollbar": {
                  display: "none",
                },
                scrollbarWidth: "none",
                msOverflowStyle: "none",
              }}
            >
              {selection === LanguageKeys.SidebarSchattenmannschaften &&
                shadowteamSelection &&
                shadowteamAside?.players.map((playerEntry, idx) => (
                  <Draggable
                    key={
                      playerEntry.player.id +
                      "_" +
                      playerEntry.player.positionsGruppe
                    }
                    draggableId={
                      playerEntry.player.id +
                      "_" +
                      playerEntry.player.positionsGruppe
                    }
                    index={idx}
                  >
                    {(provided) => (
                      <Box
                        key={idx}
                        p={2}
                        bg={colorsSecondary.background}
                        borderRadius={3}
                        borderLeft={
                          playerEntry.color
                            ? "5px solid " + playerEntry.color
                            : "5px solid white"
                        }
                        w="100%"
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                      >
                        <PlayerViewSmall
                          player={playerEntry.player}
                          useCase="shadowteam"
                          shadowteamPosition={playerEntry.shadowteamPosition}
                        />
                      </Box>
                    )}
                  </Draggable>
                ))}
              {selection === LanguageKeys.SpielervergleichWarenkorb &&
                comparisonPlayers.map((player, idx) => (
                  <Draggable
                    key={player.id + "_" + player.positionsGruppe}
                    draggableId={player.id + "_" + player.positionsGruppe}
                    index={idx}
                  >
                    {(provided) => (
                      <Box
                        key={idx}
                        p={2}
                        bg={colorsSecondary.background}
                        borderRadius={3}
                        w="100%"
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                      >
                        <PlayerViewSmall player={player} useCase="comparison" />
                      </Box>
                    )}
                  </Draggable>
                ))}

              {selection === LanguageKeys.SidebarWatchlist &&
                watchlist?.map((entry, idx) => (
                  <Draggable
                    key={entry.player.id + "_" + entry.player.positionsGruppe}
                    draggableId={
                      entry.player.id + "_" + entry.player.positionsGruppe
                    }
                    index={idx}
                  >
                    {(provided) => (
                      <Box
                        key={idx}
                        p={2}
                        bg={colorsSecondary.background}
                        borderRadius={3}
                        w="100%"
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                      >
                        <PlayerViewSmall
                          player={entry.player}
                          useCase="comparisonWatchlist"
                        />
                      </Box>
                    )}
                  </Draggable>
                ))}
            </VStack>
            {provided.placeholder}
          </Box>
        )}
      </StrictModeDroppable>
    </Box>
  );
};

export default PlayerSelectAside;
