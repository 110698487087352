import { LanguageKeys } from "../../language/LanguageKeys";
import { guiText } from "../../language/englishLocale";

export const accordionPanelContentEnglish = new Map<string, string>();

accordionPanelContentEnglish.set(
  "General Information",
  `
* Position-dependant calculation
* Minimum of 450 minutes of playing time in a position necessary for having a rating
* Observation period always retroactive 12 months (e.g. rating from Nov 23 means from Dec 22 to Nov 23)
* ${guiText[LanguageKeys.SearchBarAlleyesVorjahr]}: Highest rating from the period 3 to 15 months before the current rating
* ${guiText[LanguageKeys.SearchBarAlleyesVorjahr2]}: Highest rating from the period 15 to 27 months before the current rating
* Filter Option "${guiText[LanguageKeys.SearchBarSpielerAlle]}": All players are displayed, regardless of whether they have a current rating or not
* Filter Option "${guiText[LanguageKeys.SearchBarSpielerAktuellesRatingNurMitAktuellemRating]}": Only players with a current rating (not older than 3 months) are displayed
* Filter Option "${guiText[LanguageKeys.SearchBarSpielerKeinAktuellesRatingNurOhneAktuellesRating]}": Only players without a current rating are displayed

`
);

accordionPanelContentEnglish.set(
  "Ranking System",
  `
  <table>
  <tr>
    <th>Mark</th>
    <th>Ranking</th>
    <th>Meaning</th>
  </tr>
  <tr>
    <td>Very good</td>
    <td style="width: 80px; padding: 0px 24px;">
      <table >
        <tr ><td>10</td></tr>
        <tr><td style="border-bottom: none">9</td></tr>
      </table>
    </td>
    <td>World-class player</td>
  </tr>
  <tr>
  <td>Good</td>
  <td style="width: 80px; padding: 0px 24px;">
  <table>
      <tr><td>8</td></tr>
      <tr><td style="border-bottom: none">7</td></tr>
    </table>
  </td>
  <td>Top Bundesliga player</td>
</tr>
<tr>
<td>Average</td>
<td style="width: 80px; padding: 0px 24px;">
<table>
    <tr><td>6</td></tr>
    <tr><td style="border-bottom: none">5</td></tr>
  </table>
</td>
<td>Average player 1. Bundesliga</td>
</tr>
<tr>
<td>Sufficient</td>
<td style="width: 80px; padding: 0px 24px;">
<table>
    <tr><td>4</td></tr>
    <tr><td style="border-bottom: none">3</td></tr>
  </table>
</td>
<td>Average player 2. Bundesliga</td>
</tr>
<tr>
<td>Poor</td>
<td style="width: 80px; padding: 0px 24px;">
<table>
    <tr><td>2</td></tr>
    <tr><td style="border-bottom: none">1</td></tr>
  </table>
</td>
<td>Not relevant for us</td>
</tr>

</table>

`
);

// Note: two spaces indicate a linebreak in markdown -> in the following, two spaces at the end of a line are no coincidence
accordionPanelContentEnglish.set(
  guiText[LanguageKeys.SidebarHome],
  `
**Top Alleyes Mover, ${guiText[LanguageKeys.KurzfristigerTrend]}**  
The players in the market of the logged-in user with the highest positive change in Alleyes rating in the short-term trend with the following characteristics:
- complete rating
- age < 30
- market value <= 10 Mio 
&nbsp;  
The change in the short-term trend is calculated as the difference between the current Alleyes rating and the Alleyes rating of the previous month, provided that both have the same amount of data available.

&nbsp;  

**Top Alleyes Mover, ${guiText[LanguageKeys.LangfristigerTrend]}**  
The players in the market of the logged-in user with the highest positive change in Alleyes rating in the long-term trend with the following characteristics:
- complete rating
- age < 30
- market value <= 10 Mio 
&nbsp;  
The change in the long-term trend is calculated through a linear regression over the last 8 months of the Alleyes rating, provided there are at least 4 data points available that are based on the same data basis as the most recent Alleyes rating.

&nbsp;  

**${guiText[LanguageKeys.HomeNewPlayers]}**  
The players in the market of the logged-in user
- that in the last 60 days have received a full rating for the first time ever
- market value <= 10 Mio 

&nbsp;  

*${guiText[LanguageKeys.HomePriorityPlayers]}**  
The players in the market of the logged-in user who meet the following criteria:
- Uncontracted or contract expiring by the following June
- Last Alleyes rating >= 70
- Market value <= 5 million
- Age <= 26
- No buy option, no condition-related purchase obligation, no loan

&nbsp;  

**${guiText[LanguageKeys.HomeMissingReviews]}**  
The players in one of the logged-in user's shadow squads for whom
- a rating from the user is missing
- do not already play for Schalke 
`
);

accordionPanelContentEnglish.set(
  guiText[LanguageKeys.SpielerprofilAhnlicheSpieler],
  `
Based on the player's metrics, similar players are suggested.
These suggestions are intended to generate alternative candidates for departures and unavailable players (for example, due to financial constraints).

<br>\n

**Filters**\n
The filters are intended to narrow down the list of players based on your own preferences. The following filters are available: \n
${guiText[LanguageKeys.SearchBarMarktwert]},
${guiText[LanguageKeys.SearchBarAlter]},
${guiText[LanguageKeys.SearchBarVertragsendeBis]},
${guiText[LanguageKeys.SearchBarStarterFuß]},
${guiText[LanguageKeys.SpielerprofilGewichtung]} (the weighting of metrics based on an underlying player profile),
${guiText[LanguageKeys.SpielerprofilSpieler]} (comparison based on current or historical ratings)

<br>\n

**List of Suggestions** \n
The list of suggestions is generated by the algorithm based on the filters mentioned above. The list is sorted based on similarity (see last column).
`
);

accordionPanelContentEnglish.set(
  guiText[LanguageKeys.SpielerprofilAthletisch].replace('\n', ' '),
  `
| | |
| :- | :- |
| ${guiText[LanguageKeys.SpielerprofilDistanz].replace('\n', ' ')} | Total running distance over the entire match (with ball, against the ball, and ball out of play) |
| ${guiText[LanguageKeys.SpielerprofilAktivitatInBallbesitz].replace('\n', ' ')} | Ratio of distance over 11 km/h to total running distance in possession |
| ${guiText[LanguageKeys.SpielerprofilIntensitatInBallbesitz].replace('\n', ' ')} | Ratio of distance over 17 km/h to total running distance in possession |
| ${guiText[LanguageKeys.SpielerprofilIntensitatGegenDenBall].replace('\n', ' ')} | Ratio of distance over 17 km/h to total running distance against the ball |
| ${guiText[LanguageKeys.SpielerprofilSprints].replace('\n', ' ')} | Distance covered over 25 km/h |
| ${guiText[LanguageKeys.SpielerprofilTempolaufe].replace('\n', ' ')} | 	Distance covered between 20 km/h and 25 km/h |
| ${guiText[LanguageKeys.SpielerprofilExplosivitat].replace('\n', ' ')} | Sum of maximum accelerations in speed range 8 km/h to 15 km/h |
| ${guiText[LanguageKeys.SpielerprofilMaxSpeed].replace('\n', ' ')} | 90th percentile of Maxspeed from all encounters |
`
);

accordionPanelContentEnglish.set(
  guiText[LanguageKeys.SpielerprofilTechnischPassenScoren].replace('\n', ' '),
  `
| | |
| :- | :- |
| ${guiText[LanguageKeys.SpielerprofilPassqualitat].replace('\n', ' ')} ((Minimum count: 100) | Evaluation of passes based on pass and target location |
| ${guiText[LanguageKeys.SpielerprofilAnzahlPasse].replace('\n', ' ')} (100) | |
| ${guiText[LanguageKeys.SpielerprofilPasseeunterMittleremDruck].replace('\n', ' ')} (Minimum 200 min. in possession) | Pass accuracy under moderate pressure |
| ${guiText[LanguageKeys.SpielerprofilPasseeunterHohemDruck].replace('\n', ' ')} (Minimum 200 min. in possession) | Pass accuracy under high pressure |
| ${guiText[LanguageKeys.SpielerprofilFlankenqualitat].replace('\n', ' ')} (15) | Ratio of crosses resulting in positive xGoal to total number of crosses |
| ${guiText[LanguageKeys.SpielerprofilAbschlussqualitat].replace('\n', ' ')} (15) | Ratio of post-shot and pre-shot xG values |
| ${guiText[LanguageKeys.SpielerprofilAnzahlSchusse].replace('\n', ' ')} (15) | |
| ${guiText[LanguageKeys.SpielerprofilBessermacher].replace('\n', ' ')} (300 possession phases) | Involvement in possession phases resulting in xGoal (excluding the shooter and the assister) |
`
);

accordionPanelContentEnglish.set(
  guiText[LanguageKeys.SpielerprofilTechnischDuelle].replace('\n', ' '),
  `
| | |
| :- | :- |
| ${guiText[LanguageKeys.SpielerprofilDribbling].replace('\n', ' ')} (20) | Ratio of successful and unsuccessful offensive 1-on-1 situations |
| ${guiText[LanguageKeys.SpielerprofilBodenzweikampfInBallbesitz].replace('\n', ' ')} (20) | Ratio of successful and unsuccessful ground duels in possession |
| ${guiText[LanguageKeys.SpielerprofilLuftzweikampfInBallbesitz].replace('\n', ' ')} (15) | Ratio of successful and unsuccessful aerial duels in possession |
| ${guiText[LanguageKeys.SpielerprofilBallbehauptungUnterMittleremDruck].replace('\n', ' ')} (200 minutes of possession necessary) | Ball retention rate under moderate pressure |
| ${guiText[LanguageKeys.SpielerprofilBallbehauptungUnterHohemDruck].replace('\n', ' ')} (200 minutes of possession necessary) | Ball retention rate under high pressure |
| ${guiText[LanguageKeys.SpielerprofilAbwehrGegenDribbling].replace('\n', ' ')} (10) | Ratio of successful and unsuccessful defensive 1-on-1 situations |
| ${guiText[LanguageKeys.SpielerprofilBodenzweikampfGegenDenBall].replace('\n', ' ')} (20) | Ratio of successful and unsuccessful ground duels against the ball |
| ${guiText[LanguageKeys.SpielerprofilLuftzweikampfGegenDenBall].replace('\n', ' ')} (15) | Ratio of successful and unsuccessful aerial duels against the ball |
| ${guiText[LanguageKeys.SpielerprofilZweiteBalle].replace('\n', ' ')} (20) | Ratio of successful and unsuccessful duels for the second ball |
`
);

accordionPanelContentEnglish.set(
  guiText[LanguageKeys.SpielerprofilTaktischFreilaufverhalten].replace('\n', ' ') + " (200 minutes of possession necessary)",
  `
| | |
| :- | :- |
| Number of runs in different run types	 | |
`
);

accordionPanelContentEnglish.set(
  guiText[LanguageKeys.SpielerprofilTaktischPassverhalten].replace('\n', ' ') + " (200 minutes of possession necessary)",
  `
| | |
| :- | :- |
| Number of passes to different run types	 | |
`
);
