/* tslint:disable */
/* eslint-disable */
/**
 * alleyes SCOUT BACKEND
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PolarChartData
 */
export interface PolarChartData {
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof PolarChartData
     */
    datum: Date;
    /**
     * 
     * @type {string}
     * @memberof PolarChartData
     */
    positionsGruppe: string;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    athletischDistanz?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    athletischAktivitaetInBallbesitz?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    athletischAktivitaetGegenDenBall?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    athletischIntensitaetMitBall?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    athletischIntensitaetGegenDenBall?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    athletischSprints?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    athletischTempolaeufe?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    athletischExplosivitaet?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    athletischMaxSpeed?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    technischPsPaesse?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    technischPsPaesseAnzahl?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    technischPsPassenUnterMittleremDruck?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    technischPsPassenUnterHohemDruck?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    technischPsFlanken?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    technischPsTorgefahrSchuesse?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    technischPsTorgefahrSchuesseAnzahl?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    technischPsBessermacher?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    technischPsChancenqualitaet?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    technischDDribbling?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    technischDBodenzweikampfInBallbesitz?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    technischDLuftzweikampfInBallbesitz?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    technischDBallbehauptungInBallbesitzMittlererDruck?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    technischDBallbehauptungInBallbesitzHoherDruck?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    technischDAbwehrGgDribbler?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    technischDBodenzweikampfGegenDenBall?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    technischDLuftzweikampfGegenDenBall?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    technischD2Baelle?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    taktischRunAbkippen?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    taktischRunNachruecken?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    taktischRunEntgegenkommen?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    taktischRunHinterlaufen?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    taktischRunLaufInDieHalbspur?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    taktischRunFreilaufenInDieBreite?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    taktischRunFreilaufenVorDerGegnKette?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    taktischRunTiefenlaeufeHinterDieKette?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    taktischRunLaufInDieBox?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    taktischPassesAbkippen?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    taktischPassesNachruecken?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    taktischPassesEntgegenkommen?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    taktischPassesHinterlaufen?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    taktischPassesLaufInDieHalbspur?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    taktischPassesFreilaufenInDieBreite?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    taktischPassesFreilaufenVorDerGegnKette?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    taktischPassesTiefenlaeufeHinterDieKette?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    taktischPassesLaufInDieBox?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    technischPs?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    athletisch?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    technischD?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    taktischRun?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartData
     */
    taktischPasses?: number;
}

/**
 * Check if a given object implements the PolarChartData interface.
 */
export function instanceOfPolarChartData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "datum" in value;
    isInstance = isInstance && "positionsGruppe" in value;

    return isInstance;
}

export function PolarChartDataFromJSON(json: any): PolarChartData {
    return PolarChartDataFromJSONTyped(json, false);
}

export function PolarChartDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): PolarChartData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'datum': (new Date(json['datum'])),
        'positionsGruppe': json['positionsGruppe'],
        'athletischDistanz': !exists(json, 'athletischDistanz') ? undefined : json['athletischDistanz'],
        'athletischAktivitaetInBallbesitz': !exists(json, 'athletischAktivitaetInBallbesitz') ? undefined : json['athletischAktivitaetInBallbesitz'],
        'athletischAktivitaetGegenDenBall': !exists(json, 'athletischAktivitaetGegenDenBall') ? undefined : json['athletischAktivitaetGegenDenBall'],
        'athletischIntensitaetMitBall': !exists(json, 'athletischIntensitaetMitBall') ? undefined : json['athletischIntensitaetMitBall'],
        'athletischIntensitaetGegenDenBall': !exists(json, 'athletischIntensitaetGegenDenBall') ? undefined : json['athletischIntensitaetGegenDenBall'],
        'athletischSprints': !exists(json, 'athletischSprints') ? undefined : json['athletischSprints'],
        'athletischTempolaeufe': !exists(json, 'athletischTempolaeufe') ? undefined : json['athletischTempolaeufe'],
        'athletischExplosivitaet': !exists(json, 'athletischExplosivitaet') ? undefined : json['athletischExplosivitaet'],
        'athletischMaxSpeed': !exists(json, 'athletischMaxSpeed') ? undefined : json['athletischMaxSpeed'],
        'technischPsPaesse': !exists(json, 'technischPsPaesse') ? undefined : json['technischPsPaesse'],
        'technischPsPaesseAnzahl': !exists(json, 'technischPsPaesseAnzahl') ? undefined : json['technischPsPaesseAnzahl'],
        'technischPsPassenUnterMittleremDruck': !exists(json, 'technischPsPassenUnterMittleremDruck') ? undefined : json['technischPsPassenUnterMittleremDruck'],
        'technischPsPassenUnterHohemDruck': !exists(json, 'technischPsPassenUnterHohemDruck') ? undefined : json['technischPsPassenUnterHohemDruck'],
        'technischPsFlanken': !exists(json, 'technischPsFlanken') ? undefined : json['technischPsFlanken'],
        'technischPsTorgefahrSchuesse': !exists(json, 'technischPsTorgefahrSchuesse') ? undefined : json['technischPsTorgefahrSchuesse'],
        'technischPsTorgefahrSchuesseAnzahl': !exists(json, 'technischPsTorgefahrSchuesseAnzahl') ? undefined : json['technischPsTorgefahrSchuesseAnzahl'],
        'technischPsBessermacher': !exists(json, 'technischPsBessermacher') ? undefined : json['technischPsBessermacher'],
        'technischPsChancenqualitaet': !exists(json, 'technischPsChancenqualitaet') ? undefined : json['technischPsChancenqualitaet'],
        'technischDDribbling': !exists(json, 'technischDDribbling') ? undefined : json['technischDDribbling'],
        'technischDBodenzweikampfInBallbesitz': !exists(json, 'technischDBodenzweikampfInBallbesitz') ? undefined : json['technischDBodenzweikampfInBallbesitz'],
        'technischDLuftzweikampfInBallbesitz': !exists(json, 'technischDLuftzweikampfInBallbesitz') ? undefined : json['technischDLuftzweikampfInBallbesitz'],
        'technischDBallbehauptungInBallbesitzMittlererDruck': !exists(json, 'technischDBallbehauptungInBallbesitzMittlererDruck') ? undefined : json['technischDBallbehauptungInBallbesitzMittlererDruck'],
        'technischDBallbehauptungInBallbesitzHoherDruck': !exists(json, 'technischDBallbehauptungInBallbesitzHoherDruck') ? undefined : json['technischDBallbehauptungInBallbesitzHoherDruck'],
        'technischDAbwehrGgDribbler': !exists(json, 'technischDAbwehrGgDribbler') ? undefined : json['technischDAbwehrGgDribbler'],
        'technischDBodenzweikampfGegenDenBall': !exists(json, 'technischDBodenzweikampfGegenDenBall') ? undefined : json['technischDBodenzweikampfGegenDenBall'],
        'technischDLuftzweikampfGegenDenBall': !exists(json, 'technischDLuftzweikampfGegenDenBall') ? undefined : json['technischDLuftzweikampfGegenDenBall'],
        'technischD2Baelle': !exists(json, 'technischD2Baelle') ? undefined : json['technischD2Baelle'],
        'taktischRunAbkippen': !exists(json, 'taktischRunAbkippen') ? undefined : json['taktischRunAbkippen'],
        'taktischRunNachruecken': !exists(json, 'taktischRunNachruecken') ? undefined : json['taktischRunNachruecken'],
        'taktischRunEntgegenkommen': !exists(json, 'taktischRunEntgegenkommen') ? undefined : json['taktischRunEntgegenkommen'],
        'taktischRunHinterlaufen': !exists(json, 'taktischRunHinterlaufen') ? undefined : json['taktischRunHinterlaufen'],
        'taktischRunLaufInDieHalbspur': !exists(json, 'taktischRunLaufInDieHalbspur') ? undefined : json['taktischRunLaufInDieHalbspur'],
        'taktischRunFreilaufenInDieBreite': !exists(json, 'taktischRunFreilaufenInDieBreite') ? undefined : json['taktischRunFreilaufenInDieBreite'],
        'taktischRunFreilaufenVorDerGegnKette': !exists(json, 'taktischRunFreilaufenVorDerGegnKette') ? undefined : json['taktischRunFreilaufenVorDerGegnKette'],
        'taktischRunTiefenlaeufeHinterDieKette': !exists(json, 'taktischRunTiefenlaeufeHinterDieKette') ? undefined : json['taktischRunTiefenlaeufeHinterDieKette'],
        'taktischRunLaufInDieBox': !exists(json, 'taktischRunLaufInDieBox') ? undefined : json['taktischRunLaufInDieBox'],
        'taktischPassesAbkippen': !exists(json, 'taktischPassesAbkippen') ? undefined : json['taktischPassesAbkippen'],
        'taktischPassesNachruecken': !exists(json, 'taktischPassesNachruecken') ? undefined : json['taktischPassesNachruecken'],
        'taktischPassesEntgegenkommen': !exists(json, 'taktischPassesEntgegenkommen') ? undefined : json['taktischPassesEntgegenkommen'],
        'taktischPassesHinterlaufen': !exists(json, 'taktischPassesHinterlaufen') ? undefined : json['taktischPassesHinterlaufen'],
        'taktischPassesLaufInDieHalbspur': !exists(json, 'taktischPassesLaufInDieHalbspur') ? undefined : json['taktischPassesLaufInDieHalbspur'],
        'taktischPassesFreilaufenInDieBreite': !exists(json, 'taktischPassesFreilaufenInDieBreite') ? undefined : json['taktischPassesFreilaufenInDieBreite'],
        'taktischPassesFreilaufenVorDerGegnKette': !exists(json, 'taktischPassesFreilaufenVorDerGegnKette') ? undefined : json['taktischPassesFreilaufenVorDerGegnKette'],
        'taktischPassesTiefenlaeufeHinterDieKette': !exists(json, 'taktischPassesTiefenlaeufeHinterDieKette') ? undefined : json['taktischPassesTiefenlaeufeHinterDieKette'],
        'taktischPassesLaufInDieBox': !exists(json, 'taktischPassesLaufInDieBox') ? undefined : json['taktischPassesLaufInDieBox'],
        'technischPs': !exists(json, 'technischPs') ? undefined : json['technischPs'],
        'athletisch': !exists(json, 'athletisch') ? undefined : json['athletisch'],
        'technischD': !exists(json, 'technischD') ? undefined : json['technischD'],
        'taktischRun': !exists(json, 'taktischRun') ? undefined : json['taktischRun'],
        'taktischPasses': !exists(json, 'taktischPasses') ? undefined : json['taktischPasses'],
    };
}

export function PolarChartDataToJSON(value?: PolarChartData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'datum': (value.datum.toISOString().substr(0,10)),
        'positionsGruppe': value.positionsGruppe,
        'athletischDistanz': value.athletischDistanz,
        'athletischAktivitaetInBallbesitz': value.athletischAktivitaetInBallbesitz,
        'athletischAktivitaetGegenDenBall': value.athletischAktivitaetGegenDenBall,
        'athletischIntensitaetMitBall': value.athletischIntensitaetMitBall,
        'athletischIntensitaetGegenDenBall': value.athletischIntensitaetGegenDenBall,
        'athletischSprints': value.athletischSprints,
        'athletischTempolaeufe': value.athletischTempolaeufe,
        'athletischExplosivitaet': value.athletischExplosivitaet,
        'athletischMaxSpeed': value.athletischMaxSpeed,
        'technischPsPaesse': value.technischPsPaesse,
        'technischPsPaesseAnzahl': value.technischPsPaesseAnzahl,
        'technischPsPassenUnterMittleremDruck': value.technischPsPassenUnterMittleremDruck,
        'technischPsPassenUnterHohemDruck': value.technischPsPassenUnterHohemDruck,
        'technischPsFlanken': value.technischPsFlanken,
        'technischPsTorgefahrSchuesse': value.technischPsTorgefahrSchuesse,
        'technischPsTorgefahrSchuesseAnzahl': value.technischPsTorgefahrSchuesseAnzahl,
        'technischPsBessermacher': value.technischPsBessermacher,
        'technischPsChancenqualitaet': value.technischPsChancenqualitaet,
        'technischDDribbling': value.technischDDribbling,
        'technischDBodenzweikampfInBallbesitz': value.technischDBodenzweikampfInBallbesitz,
        'technischDLuftzweikampfInBallbesitz': value.technischDLuftzweikampfInBallbesitz,
        'technischDBallbehauptungInBallbesitzMittlererDruck': value.technischDBallbehauptungInBallbesitzMittlererDruck,
        'technischDBallbehauptungInBallbesitzHoherDruck': value.technischDBallbehauptungInBallbesitzHoherDruck,
        'technischDAbwehrGgDribbler': value.technischDAbwehrGgDribbler,
        'technischDBodenzweikampfGegenDenBall': value.technischDBodenzweikampfGegenDenBall,
        'technischDLuftzweikampfGegenDenBall': value.technischDLuftzweikampfGegenDenBall,
        'technischD2Baelle': value.technischD2Baelle,
        'taktischRunAbkippen': value.taktischRunAbkippen,
        'taktischRunNachruecken': value.taktischRunNachruecken,
        'taktischRunEntgegenkommen': value.taktischRunEntgegenkommen,
        'taktischRunHinterlaufen': value.taktischRunHinterlaufen,
        'taktischRunLaufInDieHalbspur': value.taktischRunLaufInDieHalbspur,
        'taktischRunFreilaufenInDieBreite': value.taktischRunFreilaufenInDieBreite,
        'taktischRunFreilaufenVorDerGegnKette': value.taktischRunFreilaufenVorDerGegnKette,
        'taktischRunTiefenlaeufeHinterDieKette': value.taktischRunTiefenlaeufeHinterDieKette,
        'taktischRunLaufInDieBox': value.taktischRunLaufInDieBox,
        'taktischPassesAbkippen': value.taktischPassesAbkippen,
        'taktischPassesNachruecken': value.taktischPassesNachruecken,
        'taktischPassesEntgegenkommen': value.taktischPassesEntgegenkommen,
        'taktischPassesHinterlaufen': value.taktischPassesHinterlaufen,
        'taktischPassesLaufInDieHalbspur': value.taktischPassesLaufInDieHalbspur,
        'taktischPassesFreilaufenInDieBreite': value.taktischPassesFreilaufenInDieBreite,
        'taktischPassesFreilaufenVorDerGegnKette': value.taktischPassesFreilaufenVorDerGegnKette,
        'taktischPassesTiefenlaeufeHinterDieKette': value.taktischPassesTiefenlaeufeHinterDieKette,
        'taktischPassesLaufInDieBox': value.taktischPassesLaufInDieBox,
        'technischPs': value.technischPs,
        'athletisch': value.athletisch,
        'technischD': value.technischD,
        'taktischRun': value.taktischRun,
        'taktischPasses': value.taktischPasses,
    };
}

