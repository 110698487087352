import { Box, Heading, useDisclosure } from "@chakra-ui/react";
import { GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid-pro";
import { ChangeEvent, useContext, useState } from "react";
import PlayerTable, { playerId } from "../components/general/PlayerTable";
import PlayerModal from "../components/search/playerGrid/PlayerModal";
import QuickViewModal from "../components/shadowteams/QuickViewModal";
import columns from "../components/watchlist/WatchlistColumns";
import { WatchlistEntry } from "../generated";
import {
  useDeletePlayerFromWatchlist,
  useWatchlist,
} from "../hooks/useWatchlist";
import { useComparisonStore } from "../store/store";
import { LanguageContext } from "../store/languageContext";
import { LanguageKeys } from "../language/LanguageKeys";
import { textColors } from "../colorStyles";

const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarQuickFilter></GridToolbarQuickFilter>
    </GridToolbarContainer>
  );
};

const WatchList = () => {
  const { guiText } = useContext(LanguageContext)
  const { data, isLoading } = useWatchlist();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenQuickView,
    onOpen: onOpenQuickView,
    onClose: onCloseQuickView,
  } = useDisclosure();

  const { players, addPlayer, deletePlayer } = useComparisonStore();

  const [quickViewPlayer, setQuickViewPlayer] = useState<
    WatchlistEntry | undefined
  >(undefined);

  const onCommentClick = (player: WatchlistEntry) => {
    setQuickViewPlayer(player);
    onOpenQuickView();
  };

  const deletee = useDeletePlayerFromWatchlist();

  const deletePlayerFromWatchlist = (id: number, positionsGruppe?: string) => {
    deletee({ playerId: id, position: positionsGruppe! });
  };

  const selectedPlayerIds = players.map((player) => playerId(player));

  const onSelect = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
    id: string
  ) => {
    const rowId = id.split("_");

    if (checked) {
      const entryToAdd = data?.find(
        (entry) =>
          entry.player.id === Number(rowId[0]) &&
          entry.player.positionsGruppe === rowId[1]
      );
      if (entryToAdd) {
        addPlayer(entryToAdd.player);
      }
    } else {
      deletePlayer(Number(rowId[0]), rowId[1]);
    }
  };

  return (
    <Box flex={1} pb={3} display="flex" flexDir="column" overflow="auto">
      <Heading pb={3} fontSize="24" color={textColors.primary} fontFamily={"Text_Regular"}>
        {guiText[LanguageKeys.SidebarWatchlist]}
      </Heading>
      <Box minW="1500px" flex={1} display="flex" overflow="hidden">
        <PlayerTable
          name="watchlist"
          players={data ?? []}
          getRowId={(entry: WatchlistEntry) => playerId(entry.player)}
          columnDefinitions={columns({
            onCommentClick,
            onOpenModal: onOpen,
            selectedPlayerIds,
            onSelect,
            guiText,
          })}
          loading={isLoading}
          slots={{
            toolbar: CustomToolbar,
          }}
        />
      </Box>
      {isOpen && <PlayerModal isOpen={isOpen} onClose={onClose} location="watchlist" />}
      <QuickViewModal
        onClose={onCloseQuickView}
        isOpen={isOpenQuickView}
        player={quickViewPlayer}
        deletePlayerLocal={deletePlayerFromWatchlist}
      />
    </Box>
  );
};

export default WatchList;
