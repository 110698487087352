/* tslint:disable */
/* eslint-disable */
/**
 * alleyes SCOUT BACKEND
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Transfer
 */
export interface Transfer {
    /**
     * Identifier of the player.
     * @type {number}
     * @memberof Transfer
     */
    spielerIdTm: number;
    /**
     * Identifier of the old team.
     * @type {number}
     * @memberof Transfer
     */
    teamAltIdTm?: number;
    /**
     * Name of the old team.
     * @type {string}
     * @memberof Transfer
     */
    teamAltNameTm?: string;
    /**
     * Image URL of the old team.
     * @type {string}
     * @memberof Transfer
     */
    teamAltImageTm?: string;
    /**
     * Identifier of the new team.
     * @type {number}
     * @memberof Transfer
     */
    teamNeuIdTm?: number;
    /**
     * Name of the new team.
     * @type {string}
     * @memberof Transfer
     */
    teamNeuNameTm?: string;
    /**
     * Image URL of the new team.
     * @type {string}
     * @memberof Transfer
     */
    teamNeuImageTm?: string;
    /**
     * Transfer value.
     * @type {number}
     * @memberof Transfer
     */
    transferwert?: number;
    /**
     * Transfer value unit.
     * @type {string}
     * @memberof Transfer
     */
    transferwertEinheit?: string;
    /**
     * Currency of the transfer value.
     * @type {string}
     * @memberof Transfer
     */
    transferwertWaehrung?: string;
    /**
     * Date of the transfer.
     * @type {Date}
     * @memberof Transfer
     */
    transferDatum: Date;
    /**
     * Transfer season.
     * @type {string}
     * @memberof Transfer
     */
    transferSaison?: string;
    /**
     * Loan business detail.
     * @type {string}
     * @memberof Transfer
     */
    leihgeschaeft?: string;
}

/**
 * Check if a given object implements the Transfer interface.
 */
export function instanceOfTransfer(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "spielerIdTm" in value;
    isInstance = isInstance && "transferDatum" in value;

    return isInstance;
}

export function TransferFromJSON(json: any): Transfer {
    return TransferFromJSONTyped(json, false);
}

export function TransferFromJSONTyped(json: any, ignoreDiscriminator: boolean): Transfer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'spielerIdTm': json['spielerIdTm'],
        'teamAltIdTm': !exists(json, 'teamAltIdTm') ? undefined : json['teamAltIdTm'],
        'teamAltNameTm': !exists(json, 'teamAltNameTm') ? undefined : json['teamAltNameTm'],
        'teamAltImageTm': !exists(json, 'teamAltImageTm') ? undefined : json['teamAltImageTm'],
        'teamNeuIdTm': !exists(json, 'teamNeuIdTm') ? undefined : json['teamNeuIdTm'],
        'teamNeuNameTm': !exists(json, 'teamNeuNameTm') ? undefined : json['teamNeuNameTm'],
        'teamNeuImageTm': !exists(json, 'teamNeuImageTm') ? undefined : json['teamNeuImageTm'],
        'transferwert': !exists(json, 'transferwert') ? undefined : json['transferwert'],
        'transferwertEinheit': !exists(json, 'transferwertEinheit') ? undefined : json['transferwertEinheit'],
        'transferwertWaehrung': !exists(json, 'transferwertWaehrung') ? undefined : json['transferwertWaehrung'],
        'transferDatum': (new Date(json['transferDatum'])),
        'transferSaison': !exists(json, 'transferSaison') ? undefined : json['transferSaison'],
        'leihgeschaeft': !exists(json, 'leihgeschaeft') ? undefined : json['leihgeschaeft'],
    };
}

export function TransferToJSON(value?: Transfer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'spielerIdTm': value.spielerIdTm,
        'teamAltIdTm': value.teamAltIdTm,
        'teamAltNameTm': value.teamAltNameTm,
        'teamAltImageTm': value.teamAltImageTm,
        'teamNeuIdTm': value.teamNeuIdTm,
        'teamNeuNameTm': value.teamNeuNameTm,
        'teamNeuImageTm': value.teamNeuImageTm,
        'transferwert': value.transferwert,
        'transferwertEinheit': value.transferwertEinheit,
        'transferwertWaehrung': value.transferwertWaehrung,
        'transferDatum': (value.transferDatum.toISOString().substr(0,10)),
        'transferSaison': value.transferSaison,
        'leihgeschaeft': value.leihgeschaeft,
    };
}

