import {
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Spacer,
  useDisclosure,
} from "@chakra-ui/react";

import { useKeycloak } from "@react-keycloak/web";
import FilterAside from "../components/search/filterComponents/FilterAside";
import SelectedFilterBadges from "../components/search/filterComponents/SelectedFilterBadges";
import GridTableSwitch from "../components/search/GridTableSwitch";
import OverallPotentialSwitch from "../components/search/OverallPotentialSwitch";
import PlayerBaseMenu from "../components/search/playerGrid/PlayerBaseMenu";
import PlayerGrid from "../components/search/playerGrid/PlayerGrid";
import PlayerModal from "../components/search/playerGrid/PlayerModal";
import SortingMenu from "../components/search/playerGrid/SortingMenu";
import SearchTable from "../components/search/playerTable/SearchTable";
import { useFilterValueStore, useSearchViewStore } from "../store/store";
import roles from "../util/roles";
import { useFilterSpecification } from "../hooks/useFilterSpecification";
import { LanguageContext } from "../store/languageContext";
import { useContext } from "react";
import { LanguageKeys } from "../language/LanguageKeys";
import { textColors } from "../colorStyles";

const Search = () => {
  const { guiText } = useContext(LanguageContext);

  const { view } = useSearchViewStore();
  const { removeFilter, filterValues } = useFilterValueStore();
  const { data: filterSpecifications } = useFilterSpecification();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const k = useKeycloak();
  const hasRoleSearchPlayers = k.keycloak.hasResourceRole(roles.search_players);

  return (
    <>
      {hasRoleSearchPlayers ? (
        <>
          <Box height="100%" display="flex" flex={1} flexDir="column">
            <Heading fontSize="26" color={textColors.primary} fontFamily={"Text_Regular"}>{guiText[LanguageKeys.SidebarSpielersuche]}</Heading>
            <Box flex={1} overflow="hidden">
              <Grid
                templateAreas={`"aside main"`}
                templateColumns={"310px 1fr"}
                height="100%"
              >
                <GridItem
                  area="aside"
                  paddingTop={6}
                  paddingRight={5}
                  // display="flex"
                  overflow="hidden"
                  height="100%"
                >
                  <FilterAside />
                </GridItem>

                <GridItem
                  area="main"
                  display="flex"
                  flexDirection="column"
                  overflow="hidden"
                >
                  <Flex pb={2} ml={2} mr={2}>
                    <SelectedFilterBadges
                      filterSpecifications={filterSpecifications}
                      filterValues={filterValues}
                      removeFilter={removeFilter}
                    />
                    <Spacer />
                    <HStack spacing={3}>
                      <PlayerBaseMenu />
                      {view == "card" && (
                        <>
                          <SortingMenu />
                          <OverallPotentialSwitch useCase="search" />
                        </>
                      )}
                      <GridTableSwitch />
                    </HStack>
                  </Flex>

                  <Box flex="1" overflow="hidden" pt={1} display="flex">
                    {view === "table" ? (
                      <SearchTable onOpenModal={onOpen} />
                    ) : (
                      <PlayerGrid onOpenModal={onOpen} />
                    )}
                  </Box>
                </GridItem>
              </Grid>
            </Box>
          </Box>
          {isOpen && <PlayerModal isOpen={isOpen} onClose={onClose} location="search" />}
        </>
      ) : (
        <Center h="100%" w="100%">
          <Heading fontSize={20}>{guiText[LanguageKeys.KeinZugriff]}</Heading>
        </Center>
      )}
    </>
  );
};

export default Search;
