import { HStack, IconButton, Spacer, Text } from "@chakra-ui/react";
import { Draggable } from "react-beautiful-dnd";
import { AiFillCloseCircle } from "react-icons/ai";
import { ShadowteamEntry } from "../../../generated";
import { useOverallPotentialStore } from "../../../store/store";
import { getRatingColor } from "../../../util/utilFunctions";
import RatingBadge from "../../general/RatingBadge";
import { colorsSecondary } from "../../../colorStyles";

interface Props {
  playerEntry: ShadowteamEntry;
  bench?: boolean;
  deletePlayer?: (playerId: number) => void;
  onClick: (player: ShadowteamEntry) => void;
  isDragDisabled: boolean;
}

const DnDShadowteamEntry = ({
  playerEntry,
  bench = false,
  deletePlayer,
  onClick,
  isDragDisabled,
}: Props) => {
  const { overallShadowteams } = useOverallPotentialStore();

  const player = playerEntry.player;

  const alleyesRating = player.alleyes;

  const subjectiveRating = overallShadowteams
    ? player.subjectiveOverall
    : player.subjectivePotential;

  return (
    <Draggable
      key={player.id + "_" + playerEntry.shadowteamPosition}
      draggableId={player.id + "_" + playerEntry.shadowteamPosition}
      index={playerEntry.prio - 1}
      isDragDisabled={isDragDisabled}
    >
      {(provided) => (
        <HStack
          key={playerEntry.prio}
          backgroundColor={colorsSecondary.background}
          w="100%"
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          borderLeft={
            playerEntry.color
              ? "5px solid " + playerEntry.color
              : "5px solid white"
          }
          userSelect="none"
          onClick={() => onClick(playerEntry)}
          cursor={isDragDisabled ? "pointer" : undefined}
        >
          <Text pl={1} fontSize={bench ? "md" : "sm"}>
            {player.playerName}
          </Text>
          <Spacer />
          {!bench && (
            <HStack spacing={1} px={2}>
              <RatingBadge
                rating={
                  alleyesRating ? Math.round(alleyesRating).toString() : undefined
                }
                backgroundColor={getRatingColor(alleyesRating)}
                small
                style={{
                  fontSize: "var(--chakra-fontSizes-xs)",
                  padding: "2px 2px",
                }}
              />
              <RatingBadge
                rating={subjectiveRating?.toFixed(1)}
                backgroundColor={getRatingColor(subjectiveRating, 0, 10)}
                small
                style={{
                  fontSize: "var(--chakra-fontSizes-xs)",
                  padding: "2px 2px",
                }}
              />
            </HStack>
          )}

          {bench && !isDragDisabled && (
            <>
              <Spacer />
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  deletePlayer!(playerEntry.player.id);
                }}
                icon={<AiFillCloseCircle size={16} />}
                boxSize={5}
                aria-label="Delete"
                color="gray.500"
                as="div"
                role="button"
              ></IconButton>
            </>
          )}
        </HStack>
      )}
    </Draggable>
  );
};

export default DnDShadowteamEntry;
