import { Box, Heading, Text, VStack } from "@chakra-ui/react";
import React, { ReactNode } from "react";

interface Props {
  icon?: ReactNode;
  title: string;
  subtitle?: string;
  [x: string]: any;
}

const TileHeader = ({ icon, title, subtitle, ...otherProps }: Props) => {
  return (
    <Box
      display="flex"
      flexDir="row"
      justifyContent="center"
      alignItems="center"
      data-cy="tile-header-root-container"
      {...otherProps}
    >
      {icon}
      <VStack
        alignItems="flex-start"
        spacing={0}
        pl={3}
        justifyContent="center"
        display="flex"
      >
        <Heading
          color="white"
          fontSize={20}
          fontWeight="bold"
          lineHeight={1}
          data-cy="tile-header-title"
        >
          {title}
        </Heading>
        {subtitle && <Text color="white" data-cy="tile-header-subtitle">{subtitle}</Text>}
      </VStack>
    </Box>
  );
};

export default TileHeader;
