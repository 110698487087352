import { VStack } from "@chakra-ui/react";
import { ReactNode } from "react";
import { Player } from "../../generated";
import { useWindowSize } from "../../hooks/useWindowSize";
import Tile from "../general/Tile";
import TileHeader from "../general/TileHeader";
import PlayerSummaryDetailed from "./PlayerSummaryDetailed";

interface Props {
  title: string;
  subtitle?: string;
  icon: ReactNode;
  players: Player[];
  trend?: "long" | "short";
}

const Top5PlayersList = ({ title, subtitle, icon, players, trend }: Props) => {
  const windowSize = useWindowSize();

  const Header = (
    <TileHeader pb={6} title={title} icon={icon} subtitle={subtitle} />
  );

  const playerTrend = (player: Player) => {
    switch (trend) {
      case "long":
        return player.trendLong;
      case "short":
        return player.trendShort;
      default:
        return undefined;
    }
  };

  return (
    <Tile header={Header} flex={1} height="100%">
      <VStack flex={1} spacing={8} className="scroll-portal scroll-y">
        {players.map((player, idx) => (
          <PlayerSummaryDetailed
            key={player.id}
            player={player}
            trend={playerTrend(player)}
            small={windowSize.width ? windowSize.width < 1400 : false}
          />
        ))}
      </VStack>
    </Tile>
  );
};

export default Top5PlayersList;
