import { Box, HStack, Menu, MenuButton, MenuItem, MenuList, Spacer, Text } from "@chakra-ui/react";
import React, { useContext } from "react";
import { LanguageContext } from "../../store/languageContext";
import { LanguageKeys } from "../../language/LanguageKeys";
import CountryFlag from "../CountryFlag";
import { LocaleKeys } from "../../language/LocaleKeys";
import { CheckIcon } from "@chakra-ui/icons";

const localeToCountryCode: { [key in LocaleKeys]: string } = {
  [LocaleKeys.DE]: "Switzerland",
  [LocaleKeys.EN]: "UK",
  [LocaleKeys.ES]: "Spain",
}

interface Props {
  localeKey: LocaleKeys,
  languageKey: LanguageKeys
}

const LanguageMenuItem = ({ localeKey, languageKey }: Props) => {
  const { language, setLanguage, guiText } = useContext(LanguageContext);

  return (
    <MenuItem onClick={() => setLanguage(localeKey)}>
      <HStack width="100%">
        <CountryFlag nationality={localeToCountryCode[localeKey]} size={18} />
        <Text>{guiText[languageKey]}</Text>
        <Spacer />
        {language === localeKey && <CheckIcon />}
      </HStack>
    </MenuItem>
  );
}

const LanguageSelection = () => {
  const { language } = useContext(LanguageContext);

  return (
    <Menu>
      <MenuButton
        py={2}
        transition="all 0.3s"
        _focus={{ boxShadow: "none" }}
      >
        <Box w="30px">
          <CountryFlag nationality={localeToCountryCode[language]} />
        </Box>
      </MenuButton>
      <MenuList bg="white" borderColor="gray.200" zIndex={9999}>
        {/* <LanguageMenuItem localeKey={LocaleKeys.CH} languageKey={LanguageKeys.LanguageGerman} /> */}
        <LanguageMenuItem localeKey={LocaleKeys.DE} languageKey={LanguageKeys.LanguageGerman} />
        <LanguageMenuItem localeKey={LocaleKeys.EN} languageKey={LanguageKeys.LanguageEnglish} />
        <LanguageMenuItem localeKey={LocaleKeys.ES} languageKey={LanguageKeys.LanguageSpanish} />
      </MenuList>
    </Menu>
  )
};

export default LanguageSelection;
