import ButtonGroupFilter from "../components/search/filterComponents/ButtonGroupFilter";
import CheckboxFilter from "../components/search/filterComponents/CheckboxFilter";
import DateSelectorFilter from "../components/search/filterComponents/DateSelectorFilter";
import MenuFilter from "../components/search/filterComponents/MenuFilter";
import RangeSliderFilter from "../components/search/filterComponents/RangeSliderFilter";
import TextMenuFilter from "../components/search/filterComponents/TextMenuFilter";

import { FilterSpecification } from "../generated";

const useFilterType = () => {
  const getFilter = (filterSpec: FilterSpecification): React.FC<any> => {
    switch (filterSpec.type) {
      case "rangeSlider":
        return RangeSliderFilter;
      case "buttonGroup":
        return ButtonGroupFilter;
      case "menu":
        return MenuFilter;
      case "textMenu":
        return TextMenuFilter;
      case "date":
        return DateSelectorFilter;
      case "checkbox":
        return CheckboxFilter;
      default:
        return () => <div>Default Filter</div>;
    }
  };

  return { getFilter };
};

export default useFilterType;
