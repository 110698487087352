import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Heading,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { colorsPrimary, colorsSecondary, textColors } from "../../../colorStyles";
import { ShadowteamOverview, User } from "../../../generated";
import { useCreateShadowteam } from "../../../hooks/useShadowteams";
import Formations from "../formation/FormationsAndPositions";
import UserSearch from "./UserSearch";
import { LanguageContext } from "../../../store/languageContext";
import { LanguageKeys } from "../../../language/LanguageKeys";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const CreateShadowteamModal = ({ isOpen, onClose }: Props) => {
  const { guiText } = useContext(LanguageContext);

  const createShadowTeam = useCreateShadowteam();

  const formations = Formations();


  const [name, setName] = useState("");
  const [formation, setFormation] = useState("Formation");

  const [sharedWithAccess, setSharedWithAccess] = useState<string[]>([]);
  const [sharedWithNames, setSharedWithNames] = useState<string[]>([]);
  const [sharedWith, setSharedWith] = useState<string[]>([]);

  const [warningActive, setWarningActive] = useState(false);

  const handleRadioChange = (idx: number, value: string) => {
    const updatedAccess = [...sharedWithAccess];
    updatedAccess[idx] = value;
    setSharedWithAccess(updatedAccess);
  };

  const handleSave = () => {
    if (formation === "Formation") {
      setWarningActive(true);
      return;
    }
    const newShadowteam: ShadowteamOverview = {
      shadowteamName: name,
      formation: formation,
      lastChanged: new Date(),
      sharedWith: sharedWith,
      sharedWithAccess: sharedWithAccess,
      noOfPlayers: 0,
      userId: "",
      shadowteamId: -1,
      userName: "",
    };
    createShadowTeam(newShadowteam);

    handleClose();
  };

  const handleClose = () => {
    setFormation("Formation");
    setName("");
    setSharedWithAccess([]);
    setSharedWithNames([]);
    setSharedWith([]);
    setWarningActive(false);
    onClose();
  };

  const handleAddUser = (user: User) => {
    setSharedWithAccess([...sharedWithAccess, "read"]);
    setSharedWithNames([
      ...sharedWithNames,
      user.firstName + " " + user.lastName,
    ]);
    setSharedWith([...sharedWith, user.userId!]);
  };

  const handleDeleteUser = (idx: number) => {
    const newSharedWithAccess = [...sharedWithAccess];
    const newSharedWithNames = [...sharedWithNames];
    const newSharedWith = [...sharedWith];

    newSharedWithAccess.splice(idx, 1);
    newSharedWithNames.splice(idx, 1);
    newSharedWith.splice(idx, 1);

    setSharedWithAccess(newSharedWithAccess);
    setSharedWithNames(newSharedWithNames);
    setSharedWith(newSharedWith);
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="2xl">
      <ModalOverlay />
      <ModalContent backgroundColor={colorsSecondary.cardBackground} color={textColors.primary}>
        <ModalHeader borderBottom="2px solid #CBD5E0">
          {guiText[LanguageKeys.NeueSchattenmannschaftErstellen]}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6} pt={4}>
          <Box pb={4}>
            <FormControl>
              <Heading size="sm" pb={2}>
                {guiText[LanguageKeys.Name]}
              </Heading>
              <Input
                placeholder={guiText[LanguageKeys.Schattenmannschaft]}
                value={name}
                onChange={(e) => setName(e.target.value)}
                _focus={{ borderColor: colorsSecondary.accent }}
              />
            </FormControl>
          </Box>
          <Divider borderColor="gray.300" />
          <Heading size="sm" pt={3} pb={2}>
            {guiText[LanguageKeys.SchattenmannschaftenFormation]}
          </Heading>
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              borderRadius="md"
              borderWidth="1px"
              mb={warningActive ? 1 : 3}
              w="40%"
              fontWeight="thin"
              color={formation === "Formation" ? "gray.500" : textColors.primary}
              textAlign="left"
              _focus={{ borderColor: colorsSecondary.accent }}
            >
              {formation}
            </MenuButton>

            <MenuList maxH="300px" overflow="auto" backgroundColor={colorsSecondary.background}>
              {Array.from(formations.entries()).map(formationGroups => (
                <MenuGroup title={formationGroups[0]} key={formationGroups[0]}>
                  {formationGroups[1].map(formation => (
                    <MenuItem
                      _hover={{ bg: colorsSecondary.accent }}
                      onClick={() => {
                        setFormation(formation);
                        setWarningActive(false);
                      }}
                      key={formation}
                      backgroundColor={colorsSecondary.cardBackground}
                    >
                      {formation}
                    </MenuItem>
                  ))}
                </MenuGroup>
              ))}
            </MenuList>
          </Menu>
          {warningActive && (
            <Text fontSize="sm" color="red">
              {guiText[LanguageKeys.BitteWaehleEineFormationAus]}
            </Text>
          )}
          <Divider borderColor="gray.300" />
          <Box pt={4}>
            <Heading size="sm" pb={2}>
              {guiText[LanguageKeys.TeilenMit]}
            </Heading>
            <UserSearch
              addUser={handleAddUser}
              excludeUsers={sharedWith}

            ></UserSearch>
            <TableContainer >
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th pl={1} w="60%" color={textColors.primary}>
                      {guiText[LanguageKeys.Benutzername]}
                    </Th>
                    <Th pl={1} w="30%" color={textColors.primary}>
                      {guiText[LanguageKeys.Zugriff]}
                    </Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {sharedWithNames.length > 0 ? (
                    sharedWithNames.map((name, idx) => (
                      <Tr key={idx}>
                        <Td pl={1}>{name}</Td>
                        <Td pl={1}>
                          <RadioGroup
                            onChange={(value) => handleRadioChange(idx, value)}
                            value={sharedWithAccess[idx]}
                          >
                            <Stack direction="row">
                              <Radio value="read">{guiText[LanguageKeys.Lesen]}</Radio>
                              <Radio value="write">{guiText[LanguageKeys.Schreiben]}</Radio>
                            </Stack>
                          </RadioGroup>
                        </Td>
                        <Td>
                          <IconButton
                            icon={
                              <AiFillCloseCircle size="15px" color="#8a8a8a" />
                            }
                            aria-label={guiText[LanguageKeys.Loeschen]}
                            onClick={() => handleDeleteUser(idx)}
                          ></IconButton>
                        </Td>
                      </Tr>
                    ))
                  ) : (
                    <Tr>
                      <Td pl={1}>-</Td>
                      <Td pl={1}>-</Td>
                    </Tr>
                  )}
                </Tbody>
                <Tfoot></Tfoot>
              </Table>
            </TableContainer>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button
            mr={3}
            backgroundColor={colorsSecondary.accent}
            textColor={colorsSecondary.background}
            boxShadow="sm"
            onClick={handleSave}
          >
            {guiText[LanguageKeys.Erstellen]}
          </Button>
          <Button
            onClick={handleClose}
            variant="outline"
            color={colorsSecondary.accent}
          >
            {guiText[LanguageKeys.Abbrechen]}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal >
  );
};

export default CreateShadowteamModal;
