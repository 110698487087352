import { IconButton } from "@mui/material";
import { FaRegStar, FaStar } from "react-icons/fa";
import { Player } from "../../generated";
import {
  useToggleFavoriteStatus,
} from "../../hooks/useWatchlist";
import { colorsSecondary, textColors } from "../../colorStyles";

interface Props {
  player: Player;
  favorite: boolean;
}

const FavoriteIcon = ({ player, favorite }: Props) => {
  const toggleFavorite = useToggleFavoriteStatus();

  return (
    <IconButton
      aria-label="Add to Watchlist or ShadowTeam"
      onClick={() =>
        toggleFavorite({
          playerId: player.id,
          position: player.positionsGruppe,
        })
      }
    >
      {favorite ? (
        <FaStar size="20px" color={colorsSecondary.accent} />
      ) : (
        <FaRegStar size="20px" color={textColors.primary} />
      )}
    </IconButton>
  );
};

export default FavoriteIcon;
