import { VStack } from "@chakra-ui/react";
import { useContext } from "react";
import { FaStarOfLife } from "react-icons/fa";
import { useNewPlayers } from "../../hooks/useHome";
import PlayerSummaryCompact from "../general/PlayerSummaryCompact";
import Tile from "../general/Tile";
import TileHeader from "../general/TileHeader";
import { useWindowSize } from "../../hooks/useWindowSize";
import { LanguageContext } from "../../store/languageContext";
import { LanguageKeys } from "../../language/LanguageKeys";

const NewPlayers = () => {
  const { guiText } = useContext(LanguageContext);
  const windowSize = useWindowSize();
  const newPlayers = useNewPlayers();
  const Header = (
    <TileHeader title={guiText[LanguageKeys.HomeNewPlayers]} icon={<FaStarOfLife size={18} color="white" />} />
  );

  return (
    <Tile header={Header} overflow="hidden">
      <VStack spacing={0} flex={1} className="scroll-portal scroll-y">
        {newPlayers.data?.map((player) => (
          <PlayerSummaryCompact
            player={player}
            key={"new-player-" + player.id}
            small={windowSize.width ? windowSize.width < 1400 : false}
          />
        ))}
      </VStack>
    </Tile>
  );
};

export default NewPlayers;
