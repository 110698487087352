import { PiSneakerMove } from "react-icons/pi";
import { useTopTrendLongPlayers } from "../../hooks/useHome";
import Top5PlayersList from "./Top5PlayersList";
import { useContext } from "react";
import { LanguageContext } from "../../store/languageContext";
import { LanguageKeys } from "../../language/LanguageKeys";

const TopTrendLongPlayers = () => {
  const { guiText } = useContext(LanguageContext);

  const topTrendLongPlayers = useTopTrendLongPlayers();

  //alternative Icons: TbStairsUp, GiStairsGoal, GiSoccerKick

  return (
    <Top5PlayersList
      title="Top alleyes Mover"
      subtitle={guiText[LanguageKeys.LangfristigerTrend]}
      players={topTrendLongPlayers.data ?? []}
      icon={<PiSneakerMove size={40} color="white" />}
      trend="long"
    ></Top5PlayersList >
  );
};
export default TopTrendLongPlayers;
