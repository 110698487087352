import { colors, ThemeProvider } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-US';
import es from 'date-fns/locale/es';
import { endOfMonth } from "date-fns";
import { useLocation, useParams } from "react-router-dom";
import { useDataAvailability } from "../../hooks/usePlayer";
import { useComparisonStore, usePlayerProfileStore } from "../../store/store";
import theme from "../../themes/playerTableTheme";
import { useContext, useEffect } from "react";
import { LocaleKeys } from "../../language/LocaleKeys";
import { LanguageContext } from "../../store/languageContext";
import { colorsSecondary, textColors } from "../../colorStyles";

interface Props {
  usecase: string;
  active: boolean;
  height?: string;
  playerId?: number;
}

const DateRangeSelector = ({
  usecase,
  active,
  height = "50px",
  playerId = -1,
}: Props) => {
  const { date, setDate } = usePlayerProfileStore();
  const {
    date1,
    setDate1,
    date2,
    setDate2,
    date3,
    setDate3,
    player1,
    player2,
    player3,
  } = useComparisonStore();

  const location = useLocation();

  const { id } = useParams();

  const queryParams = new URLSearchParams(location.search);
  const position = queryParams.get("position");

  let playerPosition = "";
  let dateValue: Date | undefined = undefined;

  switch (usecase) {
    case "profile":
      playerId = playerId != -1 ? playerId : Number(id);
      playerPosition = position!;
      dateValue = date;
      break;
    case "column1":
      playerId = player1?.id ?? -1;
      playerPosition = player1?.positionsGruppe ?? "";
      dateValue = date1;
      break;
    case "column2":
      playerId = player2?.id ?? -1;
      playerPosition = player2?.positionsGruppe ?? "";
      dateValue = date2;
      break;
    case "column3":
      playerId = player3?.id ?? -1;
      playerPosition = player3?.positionsGruppe ?? "";
      dateValue = date3;
      break;
    default:
      break;
  }

  const { data: dataAvailability, isLoading } = useDataAvailability(
    playerId,
    playerPosition
  );

  useEffect(() => {
    if (!isLoading && dataAvailability && active) {
      const sortedDates = dataAvailability.sort();
      setDate(new Date(sortedDates[sortedDates.length - 1]));
    }
  }, [position, isLoading]);

  const handleAccept = (date: Date | null) => {
    switch (usecase) {
      case "profile":
        setDate(endOfMonth(date!));
        break;
      case "column1":
        setDate1(endOfMonth(date!));
        break;
      case "column2":
        setDate2(endOfMonth(date!));
        break;
      case "column3":
        setDate3(endOfMonth(date!));
        break;
      default:
        break;
    }
  };

  const isYearAvailable = (yearToCheck: Date) => {
    // Check if the array has any date from the year to check
    return (
      dataAvailability?.some(
        (date) => new Date(date).getFullYear() === yearToCheck.getFullYear()
      ) ?? false
    );
  };

  const isMonthAvailable = (monthToCheck: Date) => {
    // Check if the array has any date from the month and year to check
    return (
      dataAvailability?.some((date) => {
        const availableDate = new Date(date);
        return (
          availableDate.getFullYear() === monthToCheck.getFullYear() &&
          availableDate.getMonth() === monthToCheck.getMonth()
        );
      }) ?? false
    );
  };

  const { language } = useContext(LanguageContext);
  let muiLocale: Object;
  switch (language) {
    case LocaleKeys.DE:
      muiLocale = de;
      break;
    case LocaleKeys.EN:
      muiLocale = en;
      break;
    case LocaleKeys.ES:
      muiLocale = es;
      break;
    default:
      muiLocale = de;
  }

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={muiLocale}>
        <DatePicker
          shouldDisableMonth={(month) => !isMonthAvailable(month)}
          shouldDisableYear={(year) => !isYearAvailable(year)}
          views={["year", "month"]}
          value={dateValue}
          onAccept={handleAccept}
          slotProps={{
            textField: { fullWidth: true },
            inputAdornment: { position: "start" },
            popper: {
              sx: {
                "& .MuiPaper-root": {
                  backgroundColor: colorsSecondary.background,
                  color: textColors.primary,// Set Paper background color
                },
                "& .MuiYearPicker-root": {
                  backgroundColor: colorsSecondary.background, // Year picker background color
                  color: textColors.primary,
                },
                "& .MuiMonthPicker-root": {
                  backgroundColor: colorsSecondary.background,
                  color: textColors.primary, // Month picker background color
                },
                "& .MuiPickersCalendarHeader-root": {
                  backgroundColor: colorsSecondary.background,
                  color: textColors.primary,
                },
              },
            },
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              height: { height },
              background: colorsSecondary.cardBackground,
              color: textColors.primary,
              boxShadow:
                usecase === "profile"
                  ? "none"
                  : "0 2px 4px -1px rgba(0, 0, 0, 0.1),0 1px 2px -1px rgba(0, 0, 0, 0.06)",
              "& fieldset": {
                border: usecase === "profile" ? "none" : "0px",
                borderRadius: 0,
              },
              "&:hover fieldset": {
                borderColor: colorsSecondary.accent,
              },
              "& .MuiSvgIcon-root": {
                fill: colorsSecondary.accent,
              },
              "&.Mui-error": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: colorsSecondary.accent,
                },
              },
            },
            opacity: active ? 1 : 0.7,
          }}
          minDate={new Date("2021-01-01")}
          maxDate={endOfMonth(new Date())}
        />
      </LocalizationProvider>

    </ThemeProvider>
  );
};

export default DateRangeSelector;
