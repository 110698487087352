import { Box, Checkbox, HStack, Icon, Text } from "@chakra-ui/react";
import { FilterValueStoreCore } from "../../../store/store";
import { FilterSpecification } from "../../../generated";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { filterOpacity } from "../searchStyles";
import { LocaleKeys } from "../../../language/LocaleKeys";
import { LanguageContext } from "../../../store/languageContext";
import { textColors } from "../../../colorStyles";

interface Props {
  filter: FilterSpecification;
  filterValueFunc: FilterValueStoreCore;
}

interface Specification {
  defaultChecked: boolean;
}

const CheckboxFilter = ({ filter, filterValueFunc }: Props) => {
  const { language } = useContext(LanguageContext);
  const filterSpecification = JSON.parse(filter.specification) as Specification;

  const [filterValue, setFilterValue] = useState(
    filterSpecification.defaultChecked
  );

  useEffect(() => {
    const filterString = filterValueFunc.filterValues[filter.specId - 1]?.value;
    if (filterString !== null && filterString !== undefined) {
      const parsedFilter = JSON.parse(filterString);
      setFilterValue(parsedFilter.value);
    } else {
      setFilterValue(filterSpecification.defaultChecked);
    }
  }, [filterValueFunc.filterValues[filter.specId - 1]]);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFilterValue(event.target.checked);

    if (filterSpecification.defaultChecked) {
      event.target.checked
        ? filterValueFunc.removeFilter(filter.specId)
        : filterValueFunc.addFilter({
          specId: filter.specId,
          value: JSON.stringify({ value: event.target.checked }),
        });
    } else {
      event.target.checked
        ? filterValueFunc.addFilter({
          specId: filter.specId,
          value: JSON.stringify({ value: event.target.checked }),
        })
        : filterValueFunc.removeFilter(filter.specId);
    }
  };

  return (
    <Box opacity={filterValue ? filterOpacity.active : filterOpacity.notActive} data-cy={"checkboxFilter"}>
      <HStack justifyContent="space-between">
        <Checkbox size="lg" onChange={handleOnChange} isChecked={filterValue}>
          <Text mt="1px" color={textColors.primary}>{language == LocaleKeys.DE ? filter.labelGerman : language == LocaleKeys.EN ? filter.labelEnglish : filter.labelSpanish}</Text>
        </Checkbox>
        <Icon
          as={AiFillCloseCircle}
          color="gray.500"
          style={{ cursor: "pointer" }}
          onClick={() => filterValueFunc.removeFilter(filter.specId)}
          boxSize={4}
          data-cy={"resetButton"}
        ></Icon>
      </HStack>
    </Box>
  );
};

export default CheckboxFilter;
