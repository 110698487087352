import React from "react";
import { MdOutlineCompareArrows } from "react-icons/md";

const CustomCheckboxIcon = ({
  isChecked,
  isIndeterminate,
  ...rest
}: {
  isChecked?: boolean;
  isIndeterminate?: boolean;
}) => {
  return (
    <MdOutlineCompareArrows fill={isChecked ? "white" : "#A0AEC0"} size={20} />
  );
};

export default CustomCheckboxIcon;
