import { Badge, Text, VStack } from "@chakra-ui/react";
import React from "react";

interface Props {
  label?: string;
  rating: string | undefined;
  backgroundColor: string;
  brackets?: boolean;
  small?: boolean;
  [x: string]: any;
}

const RatingBadge = ({
  label,
  rating = "-",
  backgroundColor,
  brackets = false,
  small = false,
  ...rest
}: Props) => {
  brackets = brackets && rating != "-";
  const ratingString = brackets ? "(" + rating + ")" : rating;

  const classes = small ? "rating-badge-small" : "rating-badge-full";

  return (
    <Badge
      display="flex"
      alignItems="center"
      justifyContent="center"
      background={backgroundColor}
      className={classes}
      data-cy="rating-badge-root-container"
      {...rest}
    >
      <VStack spacing={0}>
        {label && !small && (
          <Text
            color="white"
            fontSize="10"
            lineHeight="1"
            fontWeight="thin"
            data-cy="rating-badge-label"
          >
            {label}
          </Text>
        )}
        <Text
          color="white"
          lineHeight="1"
          fontWeight="bold"
          data-cy="rating-badge-rating"
        >
          {ratingString}
        </Text>
      </VStack>
    </Badge>
  );
};
export default RatingBadge;
