import { useCallback, useRef } from "react";
import { getElementAtEvent } from "react-chartjs-2";
import { Player } from "../generated";
import { RatingHistoryModalStore, useRatingHistoryModalStore } from "../store/store";
import {hoveredLabel} from "./usePreparePolarChartData";

const useChartClickComparison = (
  player: Player | undefined,
  date: Date,
  chartData: any,
  onOpenRH: () => void,
  selectKpi: (kpi: string) => void
) => {
  const chartRefs = useRef<Record<string, any>>({});
  const { setRatingHistoryModalData } = useRatingHistoryModalStore();

  const handleChartClick = useCallback(
    (chartKey: string, event: any, index: number) => {
      const chart = chartRefs.current[chartKey];
      const clickedElements = getElementAtEvent(
        chart,
        event
      );

      if (clickedElements.length > 0) {
        const x = event.nativeEvent.offsetX - chart.scales["r"].xCenter;
        const y = event.nativeEvent.offsetY - chart.scales["r"].yCenter;

        if (Math.pow(x, 2) + Math.pow(y, 2) < 324) {
          const title = chart.titleBlock.options.text;

          setRatingHistoryModalData({
            chart: title,
            rating: chartKey.slice(0, -1),
            id: player!.id,
            position: player!.positionsGruppe,
            date: date,
          } as RatingHistoryModalStore);
          onOpenRH();
          return;
        } else {
          const label = chartData[index].labels[clickedElements[0].index].join(" ");
          selectKpi(label);
        }
      } else {
        const label = hoveredLabel({x: event.nativeEvent.offsetX, y: event.nativeEvent.offsetY}, chart);
        if (label !== null) {
          const title = chart.titleBlock.options.text;

          setRatingHistoryModalData({
            chart: title,
            rating: label,
            id: player!.id,
            position: player!.positionsGruppe,
            date: date,
          } as RatingHistoryModalStore);
          onOpenRH();
          return;
        }
      }
    },
    [player, date, chartData]
  );

  return {
    chartRefs,
    handleChartClick,
  };
};

export default useChartClickComparison;
