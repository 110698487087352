import { ArrowDownIcon, ArrowUpIcon, ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { useSortingStore } from "../../../store/store";
import { LanguageContext } from "../../../store/languageContext";
import { LanguageKeys } from "../../../language/LanguageKeys";
import { colorsSecondary, textColors } from "../../../colorStyles";
import { text } from "d3";
const SortingMenu = () => {
  const { guiText } = useContext(LanguageContext);
  const { sortBy, ascending, setSortBy, setAscending } = useSortingStore();
  const [selectedItem, setSelectedItem] = useState(sortBy);

  const handleMenuItemClick = (value: string) => {
    if (selectedItem === value) {
      setAscending(!ascending);
    } else {
      setSelectedItem(value);
      setSortBy(value);
      setAscending(false);
    }
  };

  const menuOptions = {
    alleyes: guiText[LanguageKeys.SearchBarAlleyesAktuell],
    subjectiveOverall: guiText[LanguageKeys.SearchBarGesamtbewertungAktuell],
    subjectivePotential: guiText[LanguageKeys.SearchBarGesamtbewertungPotential],
    subjectiveGameRatingsOverall: guiText[LanguageKeys.SearchBarSpielbewertungAktuell],
    subjectiveGameRatingsPotential: guiText[LanguageKeys.SearchBarSpielbewertungPotential],
    surname: guiText[LanguageKeys.Name],
  };

  return (
    <Menu closeOnSelect={false} autoSelect={false}>
      <MenuButton
        as={Button}
        rightIcon={<ChevronDownIcon color={textColors.primary} />}
        backgroundColor={colorsSecondary.cardBackground}
        boxShadow="sm"
        h="35px"
      >
        <Text align="left" fontWeight="medium" color={textColors.primary}>
          <strong>{guiText[LanguageKeys.SortierenNach]}:</strong>{" "}
          {menuOptions[sortBy as keyof typeof menuOptions]}
        </Text>
      </MenuButton>
      <MenuList zIndex={999} backgroundColor={colorsSecondary.cardBackground}>
        <MenuOptionGroup type="radio" value={selectedItem} backgroundColor={colorsSecondary.cardBackground}>
          {Object.entries(menuOptions).map(([key, value]) => (
            <MenuItemOption
              key={key}
              value={key}
              onClick={() => handleMenuItemClick(key)}
              backgroundColor={colorsSecondary.cardBackground}
              color={textColors.primary}
            >
              {value}
              {selectedItem === key &&
                (ascending ? <ArrowUpIcon ml={2} /> : <ArrowDownIcon ml={2} />)}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};

export default SortingMenu;
