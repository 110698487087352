import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import ms from "ms";
import { PlayerQuery, PlayerQueryResult } from "../generated";
import { PlayerApi } from "../generated/apis/PlayerApi";
import { Player } from "../generated/models/Player";
import { usePlayerQueryStore, useSearchResultSize } from "../store/store";
import { getPlayerApi } from "../util/keycloak";

const fetchAllPlayers = async (): Promise<Player[]> => {
  const api = getPlayerApi();
  return api.getPlayers();
};

export const usePlayers = () => {
  return useQuery<Player[], Error>(["players"], fetchAllPlayers, {
    staleTime: ms("10h"),
  });
};

interface FetchPaginatedPlayersParams {
  pageParam?: number;
  playerQuery: PlayerQuery;
}

const fetchPaginatedPlayers = async ({
  pageParam = 1,
  playerQuery,
}: FetchPaginatedPlayersParams) => {
  const api = getPlayerApi();

  const mergedQuery = {
    ...playerQuery,
    paginationInfo: {
      ...playerQuery.paginationInfo,
      page: pageParam,
    },
  };

  const response = await api.getFilteredPlayers(mergedQuery);
  return response;
};

export const useInfinitePaginatedPlayers = () => {
  const { playerQuery } = usePlayerQueryStore();

  const pageSize = playerQuery.paginationInfo?.pageSize!;

  return useInfiniteQuery<PlayerQueryResult, Error>({
    queryKey: ["players", "infinite", playerQuery],
    queryFn: (context) => fetchPaginatedPlayers({ ...context, playerQuery }),
    getNextPageParam: (lastPage, allPages) => {
      const fetchedItems = allPages.reduce(
        (acc, page) => acc + page.players!.length,
        0
      );
      if (fetchedItems < lastPage.totalResultSetSize!) {
        return fetchedItems / pageSize + 1;
      }
      return undefined;
    },

    staleTime: ms("10h"),
  });
};

export const usePaginatedPlayers = () => {
  const { playerQuery } = usePlayerQueryStore();

  return useQuery<PlayerQueryResult, Error>(
    ["players", playerQuery],
    () =>
      fetchPaginatedPlayers({
        pageParam: playerQuery?.paginationInfo?.page,
        playerQuery: playerQuery,
      }),

    {
      staleTime: ms("10h"),
    }
  );
};
