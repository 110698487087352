import { LinearProgress } from "@mui/material";
import { Theme } from '@mui/material/styles';
import {
  GridColDef, UncapitalizedGridProSlotsComponent,
  GridColumnVisibilityModel, GridFooterContainer, GridPagination, GridToolbarColumnsButton, GridToolbarContainer,
} from "@mui/x-data-grid-pro";
import { PropsWithChildren, useEffect, useState } from "react";
import Table from "../general/Table";
import {
  fetchColumnSetting,
  useSaveOrUpdateColumnSettings,
} from "../../hooks/useColumnSettings";
import { textColors } from "../../colorStyles";


export const CustomFooter = (props: any) => {
  return (
    <GridFooterContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <GridToolbarContainer>
          <GridToolbarColumnsButton
            sx={{
              color: textColors.primary,
              textTransform: "none",
              fontWeight: "bold",
            }}
          />
        </GridToolbarContainer>
      </div>
      <GridPagination {...props} />
    </GridFooterContainer>
  );
};

interface Props<DataType> {
  name: string;
  rows: DataType[];
  columnDefinitions: GridColDef[];
  initialColumnVisibilitySettings: GridColumnVisibilityModel;
  theme: Theme;
  slots?: UncapitalizedGridProSlotsComponent;
  [x: string]: any;
}

function TableWithSelectableColumns<DataType>({
  name,
  rows,
  columnDefinitions,
  initialColumnVisibilitySettings,
  theme,
  slots,
  ...rest
}: PropsWithChildren<Props<DataType>>) {

  const [visibilityModel, setVisibilityModel]
    = useState<GridColumnVisibilityModel>(initialColumnVisibilitySettings);

  useEffect(() => {
    const fetchSettings = async () => {
      const fetchedSettings = await fetchColumnSetting(name);
      if (fetchedSettings.setting != null) {
        setVisibilityModel(JSON.parse(fetchedSettings.setting));
      }
    };
    fetchSettings().catch(console.error);
  }, []);

  const saveColumnSettings = useSaveOrUpdateColumnSettings();
  const onColumnVisibilityModelChange = (model: GridColumnVisibilityModel) => {
    setVisibilityModel(model);

    const columnSetting = {
      userId: "",
      page: name,
      setting: JSON.stringify(model),
    };

    saveColumnSettings(columnSetting);
  };

  return (
    <Table
      theme={theme}
      // columns definition and settings
      columnDefinitions={columnDefinitions}
      onColumnVisibilityModelChange={onColumnVisibilityModelChange}
      columnVisibilityModel={visibilityModel}
      // rows definition and settings
      rows={rows}
      slots={{
        footer: CustomFooter,
        loadingOverlay: LinearProgress,
        ...slots
      }}
      {...rest}
    />
  );
}

export default TableWithSelectableColumns;
