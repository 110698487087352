import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"; // Import React Query hooks
import ms from "ms";
import React from "react";
import { Formation, Shadowteam, ShadowteamOverview } from "../generated"; // Import your model
import { getShadowteamApi } from "../util/keycloak"; // Import your API client

// Define your API methods
const getAllShadowteams = async () => {
  const api = getShadowteamApi();
  return await api.getAllShadowteams();
};

const getShadowteam = async (shadowteamId: number, formation?: string) => {
  const api = getShadowteamApi();
  return await api.getShadowteam(shadowteamId, formation);
};

const addPlayerToShadowteam = async ({
  shadowteamId,
  playerId,
  shadowteamPosition,
}: {
  shadowteamId: number;
  playerId: number;
  shadowteamPosition: string;
}) => {
  const api = getShadowteamApi();
  return await api.addPlayerToShadowteam(
    shadowteamId,
    playerId,
    shadowteamPosition
  );
};

const deletePlayerFromShadowteam = async ({
  shadowteamId,
  playerId,
}: {
  shadowteamId: number;
  playerId: number;
}) => {
  const api = getShadowteamApi();
  return await api.deletePlayerFromShadowteam(shadowteamId, playerId);
};

const getShadowteamFormations = async (shadowteamId: number) => {
  const api = getShadowteamApi();
  return await api.getShadowteamFormations(shadowteamId);
};

const createShadowteam = async (shadowteamOverview: ShadowteamOverview) => {
  const api = getShadowteamApi();
  await api.createShadowteam(shadowteamOverview);
};

const saveShadowteam = async (shadowteam: Shadowteam) => {
  const api = getShadowteamApi();
  await api.saveShadowteam(shadowteam);
};

const editShadowteam = async ({
  shadowteamId,
  shadowteamOverview,
}: {
  shadowteamId: number;
  shadowteamOverview: ShadowteamOverview;
}) => {
  const api = getShadowteamApi();
  await api.editShadowteam(shadowteamId, shadowteamOverview);
};

const deleteShadowteam = async (shadowteamId: number) => {
  const api = getShadowteamApi();
  await api.deleteShadowteam(shadowteamId);
};

const deleteShadowteamFormation = async ({
  shadowteamId,
  formation,
}: {
  shadowteamId: number;
  formation: string;
}) => {
  const api = getShadowteamApi();
  await api.deleteShadowteamFormation(shadowteamId, formation);
};

const duplicateShadowteam = async (shadowteamId: number) => {
  const api = getShadowteamApi();
  await api.duplicateShadowteam(shadowteamId);
};

export const useGetAllShadowteams = () => {
  return useQuery<ShadowteamOverview[], Error>(
    ["shadowteams"],
    getAllShadowteams,
    { staleTime: ms("5m") }
  );
};

export const useGetShadowteam = (shadowteamId: number, formation?: string) => {
  return useQuery<Shadowteam, Error>(
    ["shadowteam", shadowteamId, formation],
    () => getShadowteam(shadowteamId, formation),
    { refetchOnWindowFocus: false, enabled: shadowteamId != -1, retry: false }
  );
};

export const useGetShadowteamFormations = (shadowteamId: number) => {
  const result = useQuery<Formation[], Error>(
    ["shadowteamFormations", shadowteamId],
    () => getShadowteamFormations(shadowteamId),
    {
      refetchOnWindowFocus: false,
      staleTime: ms("10m"),
      enabled: shadowteamId ? true : false,
    }
  );
  return React.useMemo(() => result, [result]);
};

export const useCreateShadowteam = () => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation(createShadowteam, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["shadowteams"] });
    },
    onError: (error) => {
      console.error("Error creating shadowteam:", error);
    },
  });

  return mutate;
};

export const useEditShadowteam = () => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation(editShadowteam, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["shadowteams"] });
      queryClient.invalidateQueries({ queryKey: ["shadowteamFormations"] });
      queryClient.invalidateQueries({ queryKey: ["isNotInShadowteams"] });
      queryClient.invalidateQueries({ queryKey: ["isInShadowteams"] });
    },
    onError: (error) => {
      console.error("Error editing shadowteam:", error);
    },
  });

  return mutate;
};

export const useDeleteShadowteam = () => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation(deleteShadowteam, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["shadowteams"] });
    },
    onError: (error) => {
      console.error("Error deleting shadowteam:", error);
    },
  });

  return mutate;
};

export const useDeleteFormation = () => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation(deleteShadowteamFormation, {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ["shadowteams"] });
      queryClient.invalidateQueries({
        queryKey: ["shadowteamFormations", variables.shadowteamId],
      });
    },
    onError: (error) => {
      console.error("Error deleting shadowteam formation:", error);
    },
  });

  return mutate;
};

export const useDuplicateShadowteam = () => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation(duplicateShadowteam, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["shadowteams"] });
    },
    onError: (error) => {
      console.error("Error duplicating shadowteam:", error);
    },
  });

  return mutate;
};

export const useSaveShadowteam = () => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation(saveShadowteam, {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ["shadowteams"] });
      queryClient.removeQueries({
        queryKey: ["shadowteam", variables.shadowteamId],
      });
      queryClient.invalidateQueries({
        queryKey: ["shadowteamFormations", variables.shadowteamId],
      });

      queryClient.invalidateQueries({
        queryKey: ["isInShadowteams"],
      });
      queryClient.invalidateQueries({
        queryKey: ["isInWatchlistOrShadowteam"],
      });
      queryClient.invalidateQueries({
        queryKey: ["isNotInShadowteams"],
      });
    },
    onError: (error) => {
      console.error("Error saving shadowteam:", error);
    },
  });

  return mutate;
};

const toggleFavoriteStatus = async (shadowteamId: number) => {
  const api = getShadowteamApi();
  await api.toggleShadowteamFavoriteStatus(shadowteamId);
};

export const useToggleFavoriteStatus = (shadowteamId: number) => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation(() => toggleFavoriteStatus(shadowteamId), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["shadowteams"] });
    },
    onError: (error) => {
      console.error("Error toggling favorite status:", error);
    },
  });

  return mutate;
};

export const useAddPlayerToShadowteam = () => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation(addPlayerToShadowteam, {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: ["isInShadowteams", variables.playerId],
      });
      queryClient.invalidateQueries({
        queryKey: ["isInWatchlistOrShadowteam", variables.playerId],
      });
      queryClient.invalidateQueries({
        queryKey: ["isNotInShadowteams", variables.playerId],
      });

      queryClient.invalidateQueries({
        queryKey: ["shadowteams"],
      });

      queryClient.removeQueries({
        queryKey: ["shadowteam", variables.shadowteamId],
      });
    },
    onError: (error) => {
      console.error("Error adding player to Shadowteam:", error);
    },
  });

  return mutate;
};

export const useDeletePlayerFromShadowteam = () => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation(deletePlayerFromShadowteam, {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: ["isInShadowteams", variables.playerId],
      });
      queryClient.invalidateQueries({
        queryKey: ["isNotInShadowteams", variables.playerId],
      });
    },
    onError: (error) => {
      console.error("Error deleting player from Shadowteam:", error);
    },
  });

  return mutate;
};

export const exportShadowteamPDF = (id: number, formation: string, includePlayerProfiles: boolean, language: string) => {
  const api = getShadowteamApi();
  return api.exportShadowteamToPDF(id, formation, includePlayerProfiles, language,
    {
      headers: {
        Accept: "application/pdf",
      },
    }
  );
};
