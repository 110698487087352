/* tslint:disable */
/* eslint-disable */
/**
 * alleyes SCOUT BACKEND
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Player } from './Player';
import {
    PlayerFromJSON,
    PlayerFromJSONTyped,
    PlayerToJSON,
} from './Player';

/**
 * 
 * @export
 * @interface WatchlistEntry
 */
export interface WatchlistEntry {
    /**
     * 
     * @type {Player}
     * @memberof WatchlistEntry
     */
    player: Player;
    /**
     * 
     * @type {Date}
     * @memberof WatchlistEntry
     */
    added: Date;
    /**
     * 
     * @type {boolean}
     * @memberof WatchlistEntry
     */
    favorite: boolean;
}

/**
 * Check if a given object implements the WatchlistEntry interface.
 */
export function instanceOfWatchlistEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "player" in value;
    isInstance = isInstance && "added" in value;
    isInstance = isInstance && "favorite" in value;

    return isInstance;
}

export function WatchlistEntryFromJSON(json: any): WatchlistEntry {
    return WatchlistEntryFromJSONTyped(json, false);
}

export function WatchlistEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): WatchlistEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'player': PlayerFromJSON(json['player']),
        'added': (new Date(json['added'])),
        'favorite': json['favorite'],
    };
}

export function WatchlistEntryToJSON(value?: WatchlistEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'player': PlayerToJSON(value.player),
        'added': (value.added.toISOString().substr(0,10)),
        'favorite': value.favorite,
    };
}

