/* tslint:disable */
/* eslint-disable */
/**
 * alleyes SCOUT BACKEND
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ColumnSetting,
  ErrorBody,
} from '../models';
import {
    ColumnSettingFromJSON,
    ColumnSettingToJSON,
    ErrorBodyFromJSON,
    ErrorBodyToJSON,
} from '../models';

export interface GetColumnSettingRequest {
    page: string;
}

export interface SaveOrUpdateColumnSettingRequest {
    columnSetting: ColumnSetting;
}

/**
 * 
 */
export class ColumnSettingApi extends runtime.BaseAPI {

    /**
     * Retrieve a specific column setting for a given user and page.
     */
    async getColumnSettingRaw(requestParameters: GetColumnSettingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ColumnSetting>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getColumnSetting.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/columnSettings/{page}`.replace(`{${"page"}}`, encodeURIComponent(String(requestParameters.page))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ColumnSettingFromJSON(jsonValue));
    }

    /**
     * Retrieve a specific column setting for a given user and page.
     */
    async getColumnSetting(page: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ColumnSetting> {
        const response = await this.getColumnSettingRaw({ page: page }, initOverrides);
        return await response.value();
    }

    /**
     * Save or update a column setting for a user.
     */
    async saveOrUpdateColumnSettingRaw(requestParameters: SaveOrUpdateColumnSettingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.columnSetting === null || requestParameters.columnSetting === undefined) {
            throw new runtime.RequiredError('columnSetting','Required parameter requestParameters.columnSetting was null or undefined when calling saveOrUpdateColumnSetting.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/columnSettings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ColumnSettingToJSON(requestParameters.columnSetting),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Save or update a column setting for a user.
     */
    async saveOrUpdateColumnSetting(columnSetting: ColumnSetting, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.saveOrUpdateColumnSettingRaw({ columnSetting: columnSetting }, initOverrides);
    }

}
