import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Heading,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList, Spacer,
  Text,
} from "@chakra-ui/react";
import { AiFillCloseCircle } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { Shadowteam } from "../../../generated";
import useFormationManagement from "../../../hooks/useFormationManagement";
import {
  exportShadowteamPDF,
  useDeleteFormation,
  useGetShadowteamFormations,
  useSaveShadowteam,
} from "../../../hooks/useShadowteams";
import OverallPotentialSwitch from "../../search/OverallPotentialSwitch";
import Formations from "../formation/FormationsAndPositions";
import FootballFieldListSwitch from "./FootballFieldListSwitch";
import ExportButton from "../../general/ExportButton";
import { LanguageKeys } from "../../../language/LanguageKeys";
import { useContext } from "react";
import { LanguageContext } from "../../../store/languageContext";
import { colorsSecondary, textColors } from "../../../colorStyles";

interface Props {
  shadowteam: Shadowteam | undefined;
  setShadowteam: (shadowteam: Shadowteam | undefined) => void;
  writeAccess: boolean;
  view: string;
  setView: (view: string) => void;
}

const ShadowteamHeader = ({
  shadowteam,
  setShadowteam,
  writeAccess,
  view,
  setView,
}: Props) => {
  const { language, guiText } = useContext(LanguageContext);
  const { id } = useParams();
  const { data: existingFormations } = useGetShadowteamFormations(Number(id!));
  const saveShadowteam = useSaveShadowteam();

  const formations = Formations();

  const deleteFormation = useDeleteFormation();

  const { handleFormationDeletion, handleFormationChange } =
    useFormationManagement({
      shadowteam,
      existingFormations,
      deleteFormation,
      saveShadowteam,
      setShadowteam,
    });

  return (
    <Box
      display="flex"
      flexDir="row"
      justifyContent="space-between"
      alignItems="center"
      pb={2}
      flex={0}
    >
      <Box display="flex" alignItems="center">
        <Heading fontSize="20" pr={3} color={textColors.primary} fontFamily={"Text_Regular"}>
          {shadowteam?.shadowteamName}
        </Heading>
        <Menu>
          <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon />}
            textAlign="start"
            size="sm"
            border="2px solid #CBD5E0"
            data-cy={"formationDropdown"}
            color={textColors.primary}
          >
            <Text lineHeight={0}>{shadowteam?.formation}</Text>
          </MenuButton>
          <MenuList maxH="300px" overflow="auto" zIndex={9998} backgroundColor={colorsSecondary.background} color={textColors.primary}>
            {Array.from(formations.entries()).map(formationGroups => (
              <MenuGroup title={formationGroups[0]} key={formationGroups[0]}>
                {formationGroups[1].map(formation => (
                  <MenuItem
                    _hover={{ bg: colorsSecondary.accent }}
                    onClick={() => handleFormationChange(formation)}
                    key={formation}
                    opacity={
                      existingFormations?.some(
                        (existingFormation) =>
                          existingFormation.name === formation
                      )
                        ? 1
                        : 0.4
                    }
                    isDisabled={
                      !writeAccess &&
                      !existingFormations?.some(
                        (existingFormation) =>
                          existingFormation.name === formation
                      )
                    }
                    display="flex"
                    data-cy={formation}
                    backgroundColor={colorsSecondary.cardBackground}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      flex={1}
                    >
                      <Text>{formation}</Text>{" "}
                      {existingFormations?.some(
                        (existingFormation) =>
                          existingFormation.name === formation
                      ) &&
                        !existingFormations.find(
                          (exisitingFormation) =>
                            exisitingFormation.name === formation
                        )?.isBaseFormation &&
                        writeAccess && (
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleFormationDeletion(formation);
                            }}
                            icon={<AiFillCloseCircle size={14} />}
                            boxSize={4}
                            aria-label="Delete"
                            color="gray.500"
                            as="div"
                            role="button"
                            _hover={{ color: "gray.400" }}
                          ></IconButton>
                        )}
                    </Box>
                  </MenuItem>
                ))}
              </MenuGroup>
            ))}
          </MenuList>
        </Menu>
      </Box>

      <Spacer />
      {shadowteam &&
        <ExportButton
          h="100%"
          w="140px"
          mr={9}
          borderStyle="inset"
          cursor="pointer"
          onClick={() => exportShadowteamPDF(shadowteam.shadowteamId, shadowteam.formation, true, language)}
          filename={`Schattenmannschaft-${shadowteam.shadowteamName}-${shadowteam.formation}.pdf`}
        >
          {guiText[LanguageKeys.PDFExport]}
        </ExportButton>
      }

      <HStack spacing={3}>
        {view === "field" && <OverallPotentialSwitch useCase="shadowteams" />}
        <FootballFieldListSwitch view={view} setView={setView} />
      </HStack>
    </Box>
  );
};

export default ShadowteamHeader;
