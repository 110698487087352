import { LanguageKeys } from "../../language/LanguageKeys";
import { guiText } from "../../language/spanishLocale";

export const accordionPanelContentSpanish = new Map<string, string>();

accordionPanelContentSpanish.set(
  "General Information",
  `
* Cálculo dependiente de la posición
* Mínimo de 450 minutos de tiempo de juego en una posición necesario para tener una calificación
* Período de observación siempre retroactivo 12 meses (por ejemplo, calificación de noviembre de 23 significa desde diciembre de 22 hasta noviembre de 23)
* ${guiText[LanguageKeys.SearchBarAlleyesVorjahr]}: Calificación más alta del período de 3 a 15 meses antes de la calificación actual
* ${guiText[LanguageKeys.SearchBarAlleyesVorjahr2]}: Calificación más alta del período de 15 a 27 meses antes de la calificación actual
* Opción de filtro "${guiText[LanguageKeys.SearchBarSpielerAlle]}": Se muestran todos los jugadores, independientemente de si tienen una calificación actual o no
* Opción de filtro "${guiText[LanguageKeys.SearchBarSpielerAktuellesRatingNurMitAktuellemRating]}": Solo se muestran los jugadores con una calificación actual (no mayor a 3 meses)
* Opción de filtro "${guiText[LanguageKeys.SearchBarSpielerKeinAktuellesRatingNurOhneAktuellesRating]}": Solo se muestran los jugadores sin una calificación actual

`
);

accordionPanelContentSpanish.set(
  "Ranking System",
  `
  <table>
  <tr>
    <th>Marca</th>
    <th>Ranking</th>
    <th>Significado</th>
  </tr>
  <tr>
    <td>Muy bueno</td>
    <td style="width: 80px; padding: 0px 24px;">
      <table >
        <tr ><td>10</td></tr>
        <tr><td style="border-bottom: none">9</td></tr>
      </table>
    </td>
    <td>Jugador de clase mundial</td>
  </tr>
  <tr>
  <td>Bueno</td>
  <td style="width: 80px; padding: 0px 24px;">
  <table>
      <tr><td>8</td></tr>
      <tr><td style="border-bottom: none">7</td></tr>
    </table>
  </td>
  <td>Jugador destacado de la Bundesliga</td>
</tr>
<tr>
<td>Promedio</td>
<td style="width: 80px; padding: 0px 24px;">
<table>
    <tr><td>6</td></tr>
    <tr><td style="border-bottom: none">5</td></tr>
  </table>
</td>
<td>Jugador de la 1. Bundesliga</td>
</tr>
<tr>
<td>Suficiente</td>
<td style="width: 80px; padding: 0px 24px;">
<table>
    <tr><td>4</td></tr>
    <tr><td style="border-bottom: none">3</td></tr>
  </table>
</td>
<td>Jugador de la 2. Bundesliga</td>
</tr>
<tr>
<td>Insuficiente</td>
<td style="width: 80px; padding: 0px 24px;">
<table>
    <tr><td>2</td></tr>
    <tr><td style="border-bottom: none">1</td></tr>
  </table>
</td>
<td>No relevante para nosotros</td>
</tr>

</table>

`
);

// Note: two spaces indicate a linebreak in markdown -> in the following, two spaces at the end of a line are no coincidence
accordionPanelContentSpanish.set(
  guiText[LanguageKeys.SidebarHome],
  `
**Top Alleyes Mover, ${guiText[LanguageKeys.KurzfristigerTrend]}**  
Los jugadores en el mercado del usuario conectado con el mayor cambio positivo en la calificación Alleyes en la tendencia a corto plazo con las siguientes características:
- calificación completa
- edad < 30
- valor de mercado <= 10 Mio 
&nbsp;  
El cambio en la tendencia a corto plazo se calcula como la diferencia entre la calificación Alleyes actual y la calificación Alleyes del mes anterior, siempre y cuando ambas tengan la misma cantidad de datos disponibles.

&nbsp;  

**Top Alleyes Mover, ${guiText[LanguageKeys.LangfristigerTrend]}**  
Los jugadores en el mercado del usuario conectado con el mayor cambio positivo en la calificación Alleyes en la tendencia a largo plazo con las siguientes características:
- calificación completa
- edad < 30
- valor de mercado <= 10 Mio 
&nbsp;  
El cambio en la tendencia a largo plazo se calcula mediante una regresión lineal durante los últimos 8 meses de la calificación Alleyes, siempre y cuando haya al menos 4 puntos de datos disponibles que se basen en la misma base de datos que la calificación Alleyes más reciente.

&nbsp;  

**${guiText[LanguageKeys.HomeNewPlayers]}**  
Los jugadores en el mercado del usuario conectado
- que en los últimos 60 días han recibido una calificación completa por primera vez
- valor de mercado <= 10 Mio 

&nbsp;  

**${guiText[LanguageKeys.HomePriorityPlayers]}**  
Los jugadores en el mercado del usuario conectado que cumplan los siguientes criterios:
- Sin contrato o contrato que expira en junio siguiente
- Última calificación Alleyes >= 70
- Valor de mercado <= 5 millones
- Edad <= 26
- Sin opción de compra, sin obligación de compra condicionada, sin préstamo

&nbsp;  

**${guiText[LanguageKeys.HomeMissingReviews]}**  
Los jugadores en uno de los equipos sombra del usuario conectado para los cuales:
- Falta una calificación del usuario
- No juegan actualmente para Schalke 
`
);

accordionPanelContentSpanish.set(
  guiText[LanguageKeys.SpielerprofilAhnlicheSpieler],
  `
Basándose en las métricas del jugador, se sugieren jugadores similares.
Estas sugerencias tienen la intención de generar candidatos alternativos para salidas y jugadores no disponibles (por ejemplo, debido a restricciones financieras).

<br>\n

**Filtros**\n
Los filtros están destinados a reducir la lista de jugadores según tus propias preferencias. Los siguientes filtros están disponibles: \n
${guiText[LanguageKeys.SearchBarMarktwert]},
${guiText[LanguageKeys.SearchBarAlter]},
${guiText[LanguageKeys.SearchBarVertragsendeBis]},
${guiText[LanguageKeys.SearchBarStarterFuß]},
${guiText[LanguageKeys.SpielerprofilGewichtung]} (la ponderación de las métricas basada en un perfil de jugador subyacente),
${guiText[LanguageKeys.SpielerprofilSpieler]} (comparación basada en calificaciones actuales o históricas)

<br>\n

**Lista de sugerencias** \n
La lista de sugerencias se genera mediante un algoritmo basado en los filtros mencionados anteriormente. La lista se ordena según la similitud (ver última columna). ` );


accordionPanelContentSpanish.set(
  guiText[LanguageKeys.SpielerprofilAthletisch].replace('\n', ' '),
  `
| | |
| :- | :- |
| ${guiText[LanguageKeys.SpielerprofilDistanz].replace('\n', ' ')} | Distancia total recorrida durante todo el partido (con balón, contra el balón y fuera de juego) |
| ${guiText[LanguageKeys.SpielerprofilAktivitatInBallbesitz].replace('\n', ' ')} | Relación de distancia recorrida a una velocidad superior a 11 km/h con la distancia total recorrida en posesión |
| ${guiText[LanguageKeys.SpielerprofilIntensitatInBallbesitz].replace('\n', ' ')} | Relación de distancia recorrida a una velocidad superior a 17 km/h con la distancia total recorrida en posesión |
| ${guiText[LanguageKeys.SpielerprofilIntensitatGegenDenBall].replace('\n', ' ')} | Relación de distancia recorrida a una velocidad superior a 17 km/h con la distancia total recorrida contra el balón |
| ${guiText[LanguageKeys.SpielerprofilSprints].replace('\n', ' ')} | Distancia recorrida a una velocidad superior a 25 km/h |
| ${guiText[LanguageKeys.SpielerprofilTempolaufe].replace('\n', ' ')} | Distancia recorrida entre 20 km/h y 25 km/h |
| ${guiText[LanguageKeys.SpielerprofilExplosivitat].replace('\n', ' ')} | Suma de las máximas aceleraciones en el rango de velocidad de 8 km/h a 15 km/h |
| ${guiText[LanguageKeys.SpielerprofilMaxSpeed].replace('\n', ' ')} | Percentil 90 de la velocidad máxima de todas las acciones |
`
);

accordionPanelContentSpanish.set(
  guiText[LanguageKeys.SpielerprofilTechnischPassenScoren].replace('\n', ' '),
  `
| | |
| :- | :- |
| ${guiText[LanguageKeys.SpielerprofilPassqualitat].replace('\n', ' ')} (Recuento mínimo: 100) | Evaluación de los pases según la ubicación del pase y del objetivo |
| ${guiText[LanguageKeys.SpielerprofilAnzahlPasse].replace('\n', ' ')} (100) | |
| ${guiText[LanguageKeys.SpielerprofilPasseeunterMittleremDruck].replace('\n', ' ')} (Mínimo 200 min. en posesión) | Precisión del pase bajo presión moderada |
| ${guiText[LanguageKeys.SpielerprofilPasseeunterHohemDruck].replace('\n', ' ')} (Mínimo 200 min. en posesión) | Precisión del pase bajo alta presión |
| ${guiText[LanguageKeys.SpielerprofilFlankenqualitat].replace('\n', ' ')} (15) | Relación de centros que generan xGoal positivos en el total de centros |
| ${guiText[LanguageKeys.SpielerprofilAbschlussqualitat].replace('\n', ' ')} (15) | Relación del valor xG después del disparo y antes del disparo |
| ${guiText[LanguageKeys.SpielerprofilAnzahlSchusse].replace('\n', ' ')} (15) | |
| ${guiText[LanguageKeys.SpielerprofilBessermacher].replace('\n', ' ')} (300 fases de posesión) | Participación en fases de posesión que generan xGoal (excluyendo al tirador y al asistente) |
`
);

accordionPanelContentSpanish.set(
  guiText[LanguageKeys.SpielerprofilTechnischDuelle].replace('\n', ' '),
  `
| | |
| :- | :- |
| ${guiText[LanguageKeys.SpielerprofilDribbling].replace('\n', ' ')} (20) | Relación de situaciones ofensivas de 1 contra 1 exitosas y no exitosas |
| ${guiText[LanguageKeys.SpielerprofilBodenzweikampfInBallbesitz].replace('\n', ' ')} (20) | Relación de duelos terrestres en posesión exitosos y no exitosos |
| ${guiText[LanguageKeys.SpielerprofilLuftzweikampfInBallbesitz].replace('\n', ' ')} (15) | Relación de duelos aéreos en posesión exitosos y no exitosos |
| ${guiText[LanguageKeys.SpielerprofilBallbehauptungUnterMittleremDruck].replace('\n', ' ')} (Mínimo 200 min. de posesión) | Porcentaje de retención de balón bajo presión moderada |
| ${guiText[LanguageKeys.SpielerprofilBallbehauptungUnterHohemDruck].replace('\n', ' ')} (Mínimo 200 min. de posesión) | Porcentaje de retención de balón bajo alta presión |
| ${guiText[LanguageKeys.SpielerprofilAbwehrGegenDribbling].replace('\n', ' ')} (10) | Relación de situaciones defensivas de 1 contra 1 exitosas y no exitosas |
| ${guiText[LanguageKeys.SpielerprofilBodenzweikampfGegenDenBall].replace('\n', ' ')} (20) | Relación de duelos terrestres contra el balón exitosos y no exitosos |
| ${guiText[LanguageKeys.SpielerprofilLuftzweikampfGegenDenBall].replace('\n', ' ')} (15) | Relación de duelos aéreos contra el balón exitosos y no exitosos |
| ${guiText[LanguageKeys.SpielerprofilZweiteBalle].replace('\n', ' ')} (20) | Relación de duelos por el segundo balón exitosos y no exitosos |
`
);

accordionPanelContentSpanish.set(
  guiText[LanguageKeys.SpielerprofilTaktischFreilaufverhalten].replace('\n', ' ') + " (Mínimo 200 min. de posesión)",
  `
| | |
| :- | :- |
| Número de carreras en diferentes tipos de carreras | |
`
);

accordionPanelContentSpanish.set(
  guiText[LanguageKeys.SpielerprofilTaktischPassverhalten].replace('\n', ' ') + " (Mínimo 200 min. de posesión)",
  `
| | |
| :- | :- |
| Número de pases a diferentes tipos de carreras | |
`
);