import {
  Box,
  Center,
  IconButton,
  Image,
  SkeletonCircle,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { FaEye } from "react-icons/fa";
import { MdComment } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import fallbackPicture from "../../assets/placeholder-picture.png";
import { colorsPrimary, colorsSecondary } from "../../colorStyles";
import { Player } from "../../generated";
import {
  useHasComments,
  useIsInWatchlistOrShadowteam,
  useProfilePicture,
  useTeamPicture,
} from "../../hooks/usePlayer";
import { usePlayerModalStore } from "../../store/store";
import CountryFlag from "../CountryFlag";

interface Props {
  player: Player;
  onOpenModal: () => void;
  onCommentClick: (player: Player) => void;
}

const PlayerPicture = ({
  player,
  onOpenModal,
  onCommentClick,
}: Props) => {
  const { data: profilePic, isLoading } = useProfilePicture(player.id);
  const {
    data: teamPic,
    error: teamError,
  } = useTeamPicture(player.teamId ?? -1);

  const { setPlayer } = usePlayerModalStore();

  const hasComments = useHasComments(player.id);

  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(
      `/player/${player.id}?position=${player.positionsGruppe}&idTm=${player.idTm}`
    );
  };

  const customShadow = "0 4px 6px -1px rgba(0, 0, 0, 0.1)";

  const { data: isInWatchlistOrShadowteam } = useIsInWatchlistOrShadowteam(
    player.id,
    player.positionsGruppe
  );

  const isInWatchlistOrShadowteamBoolean =
    isInWatchlistOrShadowteam != undefined &&
    parseInt(isInWatchlistOrShadowteam + "") == 1;

  return (
    <Box
      bg={colorsSecondary.cardBackground}
      borderTopLeftRadius={3}
      borderTopRightRadius={3}
      onClick={handleCardClick}
      cursor="pointer"
      pt={5}
      pr={5}
      pl={5}
      zIndex={0}
      minH="200px"
      width="100%"
      boxShadow={customShadow}
    >
      <Box position="relative">
        <Center>
          {isLoading ? (
            <SkeletonCircle boxSize="170px" />
          ) : (
            <Image
              src={profilePic ?? fallbackPicture}
              borderRadius="lg"
              boxSize="170px"
              align="center"
            />
          )}
        </Center>
        {/* left badges ---------------------------------------------- */}
        <VStack position="absolute" top="0px" left="0px" spacing={2}>
          <IconButton
            icon={
              <FaEye
                size="30px"
                color={
                  isInWatchlistOrShadowteamBoolean
                    ? colorsSecondary.accent
                    : "#CBD5E0"
                }
              />
            }
            aria-label="Add to Watchlist or ShadowTeam"
            onClick={(event) => {
              event.stopPropagation();
              setPlayer(player);
              onOpenModal();
            }}
          />

          <IconButton
            icon={
              <MdComment
                color={
                  hasComments.data?.toString() == "true"
                    ? colorsSecondary.accent
                    : "#CBD5E0"
                }
                size="28px"
              />
            }
            aria-label="Has Comment"
            onClick={(event) => {
              event.stopPropagation();
              onCommentClick(player);
            }}
          />

          {player.nationality && (
            <CountryFlag
              nationality={player.nationality}
              size={30}
            ></CountryFlag>
          )}
          {!teamError && teamPic && <Image src={teamPic} boxSize={8}></Image>}
        </VStack>
      </Box>
    </Box>
  );
};

export default PlayerPicture;
