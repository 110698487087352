import { ButtonGroup, HStack, IconButton } from "@chakra-ui/react";
import React from "react";
import { BiSolidGrid, BiListUl } from "react-icons/bi";
import { colorsSecondary, textColors } from "../../colorStyles";
import { useSearchViewStore } from "../../store/store";
import { colors } from "@mui/material";

const GridTableSwitch = () => {
  const { view, setView } = useSearchViewStore();

  return (
    <ButtonGroup isAttached boxShadow="xm" h="35px">
      <IconButton
        onClick={() => setView("card")}
        icon={
          <BiSolidGrid size={22} color={view === "card" ? colorsSecondary.background : textColors.lightgrey} />
        }
        // boxSize={8}
        h="100%"
        aria-label="Card View"
        backgroundColor={view === "card" ? colorsSecondary.accent : colorsSecondary.cardBackground}
        borderTopLeftRadius="md"
        borderBottomLeftRadius="md"
        borderTopRightRadius="0"
        borderBottomRightRadius="0"
      ></IconButton>
      <IconButton
        onClick={() => setView("table")}
        icon={
          <BiListUl size={25} color={view === "table" ? colorsSecondary.background : textColors.lightgrey} />
        }
        // boxSize={8}
        h="100%"
        aria-label="Table View"
        backgroundColor={
          view === "table" ? colorsSecondary.accent : colorsSecondary.cardBackground
        }
        borderTopLeftRadius="0"
        borderBottomLeftRadius="0"
        borderTopRightRadius="md"
        borderBottomRightRadius="md"
        data-cy={"tableViewButton"}
      ></IconButton>
    </ButtonGroup>
  );
};

export default GridTableSwitch;
