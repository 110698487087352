import {
  Box,
  HStack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Player } from "../../../generated";
import { useSimilarPlayersFilterSpecification } from "../../../hooks/useFilterSpecification";
import useFilterType from "../../../hooks/useFilterType";
import { useSimilarPlayersFilterValueStore } from "../../../store/store";
import SelectedFilterBadges from "../../search/filterComponents/SelectedFilterBadges";
import PlayerBaseMenu from "../../search/playerGrid/PlayerBaseMenu";
import PlayerModal from "../../search/playerGrid/PlayerModal";
import SimilarPlayersTable from "./SimilarPlayersTable";
import { useParams } from "react-router-dom";
import { textColors } from "../../../colorStyles";

const buttonGroupWidth = "150px";

interface Props {
  position: string;
  onClickPlayer: (player: Player) => void;
}

const SimilarPlayers = ({ position, onClickPlayer }: Props) => {
  const { id } = useParams();

  const { data: filterSpecifications, error } =
    useSimilarPlayersFilterSpecification(Number(id!), position);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const filterValueFunctionality = useSimilarPlayersFilterValueStore(
    (state) => ({
      addFilter: state.addFilter,
      removeFilter: state.removeFilter,
      filterValues: state.filterValues,
    })
  );

  const { getFilter } = useFilterType();

  if (error) return <Text>{error.message}</Text>;

  return (
    <>
      <Box flex={1} overflow="hidden" paddingRight={1} display="flex" flexDir="column">
        <Box
          minHeight="30px"
          marginY={3}
        >
          <SelectedFilterBadges
            filterSpecifications={filterSpecifications}
            removeFilter={filterValueFunctionality.removeFilter}
            filterValues={filterValueFunctionality.filterValues}
          />
        </Box>

        <HStack
          justify="start"
          align="stretch"
          spacing={7}
          paddingLeft={3}
        >
          {filterValueFunctionality &&
            filterSpecifications?.map((filter) => {
              const FilterComponent = getFilter(filter);
              return (
                <Box
                  key={filter.specId}
                  minWidth="100px"
                  maxWidth="200px"
                  width={filter.type === "buttonGroup" ? buttonGroupWidth : undefined}
                >
                  <FilterComponent
                    filter={filter}
                    filterValueFunc={filterValueFunctionality}
                  />
                </Box>
              );
            })}
          <PlayerBaseMenu outline mode="buttonGroup" width={buttonGroupWidth} />
        </HStack>

        <Box flex="1" overflow="hidden" display="flex" flexDir="column">
          <SimilarPlayersTable
            position={position}
            onOpenModal={onOpen}
            onClickPlayer={onClickPlayer}
          />
        </Box>
      </Box>
      {isOpen && <PlayerModal isOpen={isOpen} onClose={onClose} location="profile" />}
    </>
  );
};

export default SimilarPlayers;
