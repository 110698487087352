import {
  Box,
  Center,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useLocation, useParams } from "react-router-dom";
import { useTransferHistory } from "../../../hooks/usePlayer";
import MarketHistory from "./MarketHistory";
import TransferBar from "./TransferBar";
import { useContext } from "react";
import { LanguageContext } from "../../../store/languageContext";
import { LanguageKeys } from "../../../language/LanguageKeys";
import { colorsSecondary, textColors } from "../../../colorStyles";

const TransferMarketHistory = () => {
  const { guiText } = useContext(LanguageContext);
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const idTm = Number(queryParams.get("idTm"));

  const { data: transfers, isLoading, error } = useTransferHistory(idTm);

  return (
    <Tabs variant="baseStyle" mt={2} flex={1} display="flex">
      <TabList h="50px" alignSelf="end" pb={1}>
        <Tab>{guiText[LanguageKeys.SpielerprofilTransferhistorie]}</Tab>
        <Tab>{guiText[LanguageKeys.SpielerprofilMarkthistorie]}</Tab>
      </TabList>
      <TabPanels
        overflow="hidden"
        height="100%"
        display="flex"
        flex={1}
        flexDir="column"
      >
        <TabPanel
          display="flex"
          flex={1}
          flexDir="column"
          height="100%"
          bg={colorsSecondary.background}
          p={2}
          pt={3}
        >
          {transfers && transfers.length > 0 ? (
            <>
              <HStack width="100%" pb={3} spacing={0} pl={2}>
                <Box w="10%">
                  <Text fontWeight="semibold" color={textColors.primary}>{guiText[LanguageKeys.SpielerprofilSaison]}</Text>
                </Box>
                <Box w="15%">
                  <Text fontWeight="semibold" color={textColors.primary}>{guiText[LanguageKeys.SpielerprofilDatum]}</Text>
                </Box>
                <Box w="30%">
                  <Text fontWeight="semibold" color={textColors.primary}>{guiText[LanguageKeys.SpielerprofilAbgebenderVerein]}</Text>
                </Box>
                <Box w="30%">
                  <Text fontWeight="semibold" color={textColors.primary}>{guiText[LanguageKeys.SpielerprofilAufnehmenderVerein]}</Text>
                </Box>
                <Box w="15%">
                  <Text fontWeight="semibold" color={textColors.primary}>{guiText[LanguageKeys.Abloese]}</Text>
                </Box>
              </HStack>
              <Box flex={1} overflow="hidden">
                <VStack overflow="auto" spacing={3} w="100%" h="100%">
                  {transfers.map((transfer, idx) => (
                    <TransferBar key={idx} transfer={transfer} />
                  ))}
                </VStack>
              </Box>
            </>
          ) : (
            <Center h="100%" w="100%" color={colorsSecondary.background}>
              <Text fontSize={20} color={textColors.primary}>{guiText[LanguageKeys.KeineTransferdatenVorhanden]}</Text>
            </Center>
          )}
        </TabPanel>
        <TabPanel display="flex" flex={1} flexDir="column" height="100%">
          <MarketHistory />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default TransferMarketHistory;
