import { createGlobalStyle } from "styled-components";

const ComponentStyles = createGlobalStyle`
  .hover-highlight {
    &:hover {
      transform: scale(1.015);
      transition: transform .15s ease-in;
      cursor: pointer;
    }
  }
  
  .scroll-portal {
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  
  .scroll-x {
    -ms-overflow-x: auto;
    overflow-x: auto;
    -ms-overflow-y: hidden;
    overflow-y: hidden;
  }

  .scroll-y {
    -ms-overflow-y: auto;
    overflow-y: auto;
    -ms-overflow-x: hidden;
    overflow-x: hidden;
  }
  
  .rating-badge-full {
    border-radius: 50%;
    min-width: 45px;
    width: 45px;
    min-height: 45px;
    height: 45px;
    font-size: 16px;
  }

  .rating-badge-small {
    padding: var(--chakra-space-1) var(--chakra-space-2);
    width: 1rem;
    box-sizing: content-box;
    font-size: var(--chakra-fontSizes-xs);
  }
`;

export default ComponentStyles;
