/* tslint:disable */
/* eslint-disable */
/**
 * alleyes SCOUT BACKEND
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AlleyesRating
 */
export interface AlleyesRating {
    /**
     * 
     * @type {number}
     * @memberof AlleyesRating
     */
    ratingCurrent: number;
    /**
     * 
     * @type {number}
     * @memberof AlleyesRating
     */
    ratingPotential?: number;
    /**
     * 
     * @type {Date}
     * @memberof AlleyesRating
     */
    date: Date;
    /**
     * 
     * @type {boolean}
     * @memberof AlleyesRating
     */
    completeRating?: boolean;
}

/**
 * Check if a given object implements the AlleyesRating interface.
 */
export function instanceOfAlleyesRating(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "ratingCurrent" in value;
    isInstance = isInstance && "date" in value;

    return isInstance;
}

export function AlleyesRatingFromJSON(json: any): AlleyesRating {
    return AlleyesRatingFromJSONTyped(json, false);
}

export function AlleyesRatingFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlleyesRating {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ratingCurrent': json['ratingCurrent'],
        'ratingPotential': !exists(json, 'ratingPotential') ? undefined : json['ratingPotential'],
        'date': (new Date(json['date'])),
        'completeRating': !exists(json, 'completeRating') ? undefined : json['completeRating'],
    };
}

export function AlleyesRatingToJSON(value?: AlleyesRating | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ratingCurrent': value.ratingCurrent,
        'ratingPotential': value.ratingPotential,
        'date': (value.date.toISOString().substr(0,10)),
        'completeRating': value.completeRating,
    };
}

