import * as d3 from "d3";
import { useContext, useEffect } from "react";

import { Box, Center, Heading, Text } from "@chakra-ui/react";
import fieldImage2 from "../../../assets/football-court-diagram.png";
import { colorsPrimary } from "../../../colorStyles";
import { useHeatmap } from "../../../hooks/usePlayer";
import { LanguageContext } from "../../../store/languageContext";
import { LanguageKeys } from "../../../language/LanguageKeys";
import { textColors } from "../../../colorStyles";
function transformData(dataString: string) {
  if (dataString == undefined) return;
  const gridWidth = 14;
  const gridHeight = 20;

  const data: any[] = [];
  for (let x = 0; x < gridWidth; x++) {
    for (let y = 0; y < gridHeight; y++) {
      data.push({
        x: "X" + (x + 1),
        y: "Y" + (y + 1),
        value: parseInt(dataString[x * gridHeight + y], 10),
      });
    }
  }

  return data;
}

interface Props {
  id: number;
  position: string;
  [x: string]: any;
}

const Heatmap = ({ id, position, ...rest }: Props) => {
  const { guiText } = useContext(LanguageContext);

  const { data, isLoading } = useHeatmap(id, position);

  const scalingFactor = 5.8;

  const margin = { top: 5, right: 5, bottom: 5, left: 5 },
    width = 68 * scalingFactor - margin.left - margin.right,
    height = 105 * scalingFactor - margin.top - margin.bottom;

  useEffect(() => {
    if (data?.heatmap == undefined) return;
    const transformedData = transformData(data.heatmap);

    const container = d3.select("div#container");
    container.selectAll("*").remove();
    const svg = container
      .append("svg")
      .attr("preserveAspectRatio", "xMidYMid meet")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .attr(
        "viewBox",
        `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom
        }`
      )
      .classed("svg-content", true);

    const g = svg
      .attr("preserveAspectRatio", "none")
      .append("g")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    const xCoords = Array.from({ length: 14 }, (_, i) => "X" + (i + 1));
    const yCoords = Array.from({ length: 20 }, (_, i) => "Y" + (i + 1));

    const x = d3.scaleBand().range([0, width]).domain(xCoords).padding(0.01);

    const y = d3.scaleBand().range([height, 0]).domain(yCoords).padding(0.01);

    const myColor = d3
      .scaleLinear()
      .range(["white", colorsPrimary.koenigsblau] as any)
      .domain([0, 9]);

    g.selectAll()
      .data(transformedData!)
      .enter()
      .append("rect")
      .attr("x", (d: any) => x(d.x) as number)
      .attr("y", (d: any) => y(d.y) as number)
      .attr("width", x.bandwidth())
      .attr("height", y.bandwidth())
      .style("fill", (d: any) => myColor(d.value));

    g.append("image")
      .attr("href", fieldImage2)
      .attr("x", -2)
      .attr("y", -2)
      .attr("width", width + 5)
      .attr("height", height + 5)
      .attr("preserveAspectRatio", "none");
  }, [data]);

  return (
    <>
      {isLoading ? (
        <Center h="100%" w="100%">
          <Text fontSize={22} color={textColors.primary}>Loading...</Text>
        </Center>
      ) : !data || data.heatmap == null ? (
        <Center id="heatmap-no-data" h="100%" w="100%">
          <Text fontSize={22} color={textColors.primary}>{guiText[LanguageKeys.KeineHeatmapdatenvorhanden]}</Text>
        </Center>
      ) : (
        <Box
          id="heatmap"
          display="flex"
          alignItems="center"
          flexDir="column"
          justifyContent="flex-start"
          flex={1}
          sx={{
            ".svg-container": {
              display: "flex",
              flexDir: "column",
              overflow: "hidden",
              alignItems: "center",
              justifyContent: "center",
              maxH: "90% !important",
              minH: "90% !important",
              aspectRatio: width + "/" + height,
            },
            ".svg-content": {
              display: "flex",
              flexDir: "column",
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            },
          }}
          {...rest}
        >
          <Heading fontSize="20" pb={5} color={textColors.primary}>
            Heatmap
          </Heading>
          <div id="container" className="svg-container"></div>
        </Box>
      )}
    </>
  );
};

export default Heatmap;
