import { AddIcon } from "@chakra-ui/icons";
import { Box, Circle, Flex, Text } from "@chakra-ui/react";
import { Player } from "../../generated";
import { useComparisonStore } from "../../store/store";
import PlayerPicture from "./PlayerPicture";
import { useContext } from "react";
import { LanguageContext } from "../../store/languageContext";
import { LanguageKeys } from "../../language/LanguageKeys";
import { colorsSecondary, textColors } from "../../colorStyles";
interface Props {
  columnNo: number;
  player: Player | undefined;
  onOpenModal: () => void;
  onCommentClick: (player: Player) => void;
}

const ComparisonColumn = ({
  player,
  columnNo,
  onOpenModal,
  onCommentClick,
}: Props) => {
  const { guiText } = useContext(LanguageContext);

  const customShadow = "0 4px 6px -1px rgba(0, 0, 0, 0.1)";

  const { setAddToComp1, setAddToComp2, setAddToComp3 } = useComparisonStore();

  const focusSearchBar = () => {
    switch (columnNo) {
      case 1:
        setAddToComp1(true);
        break;
      case 2:
        setAddToComp2(true);
        break;
      case 3:
        setAddToComp3(true);
        break;
    }

    const inputElement = document.getElementById("searchbar");
    if (inputElement) {
      inputElement.focus();
    }
  };

  return (
    <Box flex={1} display="flex" flexDir="column">
      <Box
        h="100%"
        bg={colorsSecondary.cardBackground}
        userSelect="none"
        onClick={!player ? focusSearchBar : () => void 0}
        cursor="pointer"
        alignItems="center"
        display="flex"
        flexDir="column"
        boxShadow={customShadow}
      >
        {player ? (
          <PlayerPicture
            player={player}
            onOpenModal={onOpenModal}
            onCommentClick={onCommentClick}
          />
        ) : (
          <Flex height="200px" alignItems="center" pt="105px" data-cy="comparisonAddPlayerDroppable">
            <Circle size="40px" bg={colorsSecondary.accent} color="white">
              <AddIcon color={colorsSecondary.background} />
            </Circle>
            <Text pl={3} fontSize={18} fontWeight="600" opacity={0.9} color={textColors.primary}>
              {guiText[LanguageKeys.SpielerHinzufugen]}
            </Text>
          </Flex>
        )}
      </Box>
    </Box>
  );
};

export default ComparisonColumn;
