import { endOfMonth } from "date-fns";
import { Player, PlayerBaseData } from "../generated";

export const hexToRgba = (hex: string, alpha: number) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const calculateAge = (date: Date): number => {
  const today = new Date();
  let age = today.getFullYear() - date.getFullYear();
  const m = today.getMonth() - date.getMonth();

  if (m < 0 || (m === 0 && today.getDate() < date.getDate())) {
    age--;
  }

  return age;
};

export const positionAbbreviation = (position: string): string => {
  const mapping: { [key: string]: string } = {
    Forward: "FW",
    Winger: "WIN",
    "Central Midfield": "CM",
    "Defensive Midfield": "DM",
    "Full Back": "FB",
    "Central Defender": "CD",
    Goalkeeper: "GK",
  };

  return mapping[position] ?? position;
};

export const formatDate = (d: Date): string => {
  const day = d.getDate().toString().padStart(2, "0");
  const month = (d.getMonth() + 1).toString().padStart(2, "0");
  const year = d.getFullYear();

  return `${day}.${month}.${year}`;
};

export const formatDateTime = (d: Date): string => {
  const day = d.getDate().toString().padStart(2, "0");
  const month = (d.getMonth() + 1).toString().padStart(2, "0");
  const year = d.getFullYear();
  const hours = d.getHours().toString().padStart(2, "0");
  const minutes = d.getMinutes().toString().padStart(2, "0");

  return `${day}.${month}.${year}, ${hours}:${minutes} Uhr`;
};

export const resizeImage = (
  imageUrl: string,
  size: number
): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = imageUrl;
    img.crossOrigin = "anonymous";

    img.onload = () => {
      // Calculate the aspect ratio
      const aspectRatio = img.width / img.height;
      let newWidth, newHeight;

      if (img.width > img.height) {
        // If width is greater than height
        newWidth = size;
        newHeight = size / aspectRatio;
      } else {
        // If height is greater than or equal to width
        newHeight = size;
        newWidth = size * aspectRatio;
      }

      const canvas = document.createElement("canvas");
      canvas.width = newWidth;
      canvas.height = newHeight;
      const ctx = canvas.getContext("2d");
      ctx?.drawImage(img, 0, 0, newWidth, newHeight);

      const newImg = new Image();
      newImg.src = canvas.toDataURL();
      newImg.onload = () => resolve(newImg);
      newImg.onerror = reject;
    };

    img.onerror = reject;
  });
};

export const germanMonths = [
  "Jan",
  "Feb",
  "Mär",
  "Apr",
  "Mai",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Okt",
  "Nov",
  "Dez",
];

export const convertToDate = (germanDate: string) => {
  const [monthString, yearString] = germanDate.split(" ");

  const monthIndex = germanMonths.indexOf(monthString);

  const year = parseInt(yearString, 10) + 2000;

  return endOfMonth(new Date(year, monthIndex, 1));
};

export function getRatingColor(
  rating: number | undefined,
  min = 0,
  max = 100,
  opacity = 1
) {
  if (rating === undefined) return "rgb(211, 211, 211)";

  const normalizedRating = ((rating - min) / (max - min)) * 100;

  let hue, lightness;
  if (normalizedRating <= 50) {
    // Transition from red (0°) to yellow (60°)
    hue = normalizedRating * (60 / 50);
    lightness = 45; // Standard brightness
  } else {
    // Transition from yellow (60°) to green (120°)
    hue = 60 + (normalizedRating - 50) * (60 / 50);

    // Start decreasing the lightness for values above 50
    lightness = 40 - (normalizedRating - 50) * (15 / 50); // Decreases up to 15% for max value
  }

  return `hsla(${hue}, 100%, ${lightness}%, ${opacity})`;
}

export const playerBaseDataToPlayer = (
  pbd: PlayerBaseData,
  position: string
): Player => {
  return {
    id: pbd.id,
    idTm: pbd.idTm,
    firstName: pbd.firstName,
    surname: pbd.surname,
    birthday: pbd.birthday,
    age: pbd.age,
    nationality: pbd.nationality,
    strongFoot: pbd.strongFoot,
    height: pbd.height,
    alleyes: pbd.alleyes,
    subjectiveOverall: pbd.subjectiveOverall,
    subjectivePotential: pbd.subjectivePotential,
    subjectiveGameRatingsOverall: pbd.subjectiveGameRatingsOverall,
    subjectiveGameRatingsPotential: pbd.subjectiveGameRatingsPotential,
    league: pbd.league,
    team: pbd.team,
    teamId: pbd.teamId,
    positionsGruppe: position,
    marketValue: pbd.marketValue,
  };
};

export function isNumber(value?: any): boolean {
  return (value != null && typeof value === "number" && !isNaN(value));
}
