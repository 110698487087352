import {
  Box,
  Center,
  HStack, IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  VStack
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useKpiClip } from "../../../hooks/usePlayer";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Case, Switch } from "../../general/Switch";
import { KpiClip } from "../../../generated";
import { NameMapping } from "../../../hooks/usePreparePolarChartData";
import { LanguageContext } from "../../../store/languageContext";
import { LanguageKeys } from "../../../language/LanguageKeys";
import { colorsSecondary, textColors } from "../../../colorStyles";

interface Props {
  id: number;
  position: string;
  kpi: string;
  data?: KpiClip[];
  onClose: () => void
}

const KpiClips = ({ id, position, kpi, data, onClose }: Props) => {
  const { guiText } = useContext(LanguageContext);

  const [activeClip, setActiveClip] = useState(0);
  const [clips, setClips] = useState<string[]>(new Array(data?.length ?? 0));

  const nameMapping = NameMapping(guiText);

  const kpiLabel = () => {
    for (const category of Object.keys(nameMapping)) {
      const categoryNameMapping = nameMapping[category];
      if (kpi in categoryNameMapping) {
        return categoryNameMapping[kpi].join(" ").replace("- ", "");
      }
    }
    return "";
  }

  const { data: clip, error: clipDataError, isLoading: clipLoading } = useKpiClip(
    id, position, kpi,
    data?.at(activeClip)?.matchId, data?.at(activeClip)?.start, data?.at(activeClip)?.end,
    data != undefined && activeClip >= 0 && activeClip < data.length
  );

  const { data: nextClip, isLoading: nextClipLoading } = useKpiClip(
    id, position, kpi,
    data?.at(activeClip + 1)?.matchId, data?.at(activeClip + 1)?.start, data?.at(activeClip + 1)?.end,
    data != undefined && activeClip >= 0 && activeClip < data.length - 1
  );

  useEffect(() => {
    return () => {
      clips.forEach(c => URL.revokeObjectURL(c));
    }
  }, []);

  useEffect(() => {
    if (data && data.length > 0) {
      setActiveClip(0);
      setClips(new Array(data.length));
    } else {
      setActiveClip(-1);
      setClips([]);
    }
  }, [data]);

  useEffect(() => {
    if (!clipLoading && clip) {
      clips[activeClip] = URL.createObjectURL(clip);
      setClips([...clips]);
    }
  }, [clipLoading, clip]);

  useEffect(() => {
    if (!nextClipLoading && nextClip) {
      clips[activeClip + 1] = URL.createObjectURL(nextClip);
      setClips([...clips]);
    }
  }, [nextClipLoading, nextClip]);

  const [error, setError] = useState(false);
  const handleVideoError = (error: any) => {
    console.error(error);
    setError(true);
  };

  const handleNextClip = () => {
    if (activeClip < clips.length - 1) {
      setActiveClip(activeClip + 1);
    }
  };

  const handlePreviousClip = () => {
    if (activeClip > 0) {
      setActiveClip(activeClip - 1);
    }
  };

  let renderSwitch;
  switch (true) {
    case (error || clipDataError) && !clip:
      renderSwitch = "error";
      break;
    case data && data.length === 0:
      renderSwitch = "noData";
      break;
    case clipLoading:
      renderSwitch = "loading";
      break;
    default:
      renderSwitch = "default";
  }

  return (
    <Modal isOpen={kpi != ""} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="50%" height="70vh" marginTop="100px" backgroundColor={colorsSecondary.cardBackground}>
        <ModalHeader borderBottom="2px solid #CBD5E0" color={textColors.primary}>
          {kpiLabel()}
        </ModalHeader>
        <ModalCloseButton color={textColors.primary} />
        <ModalBody pb={6} pt={4}>
          <Box w="100%" h="100%" overflow="hidden">
            <Switch condition={renderSwitch}>
              <Case value="error">
                <Center h="100%" w="100%">
                  <Text fontSize={22}>{guiText[LanguageKeys.SpielerprofilVideoNichtVerfugbar]}</Text>
                </Center>
              </Case>
              <Case value="noData">
                <Center h="100%" w="100%">
                  <Text fontSize={22}>{guiText[LanguageKeys.KeinVideovorhanden]}</Text>
                </Center>
              </Case>
              <Case value="loading">
                <Center h="100%" w="100%">
                  <Spinner color={colorsSecondary.accent} />
                </Center>
              </Case>
              <Case isDefault>
                <VStack h="100%" w="100%">
                  <ReactPlayer
                    url={clips[activeClip]}
                    playing={activeClip > 0}
                    config={{ file: { attributes: { disablePictureInPicture: true } } }}
                    controls
                    muted
                    width="auto"
                    height="100%"
                    onEnded={() => (activeClip < clips.length - 1) && setActiveClip(activeClip + 1)}
                    onError={handleVideoError}
                  />
                  <HStack>
                    <IconButton
                      variant="outline"
                      border="none"
                      aria-label="Letzter"
                      icon={<ChevronLeftIcon color={textColors.primary} />}
                      onClick={handlePreviousClip}
                      isDisabled={activeClip <= 0}
                    />
                    <Text color={textColors.primary}>Clip: {activeClip + 1} / {clips.length}</Text>
                    <IconButton
                      variant="outline"
                      border="none"
                      aria-label="Nächster"
                      icon={<ChevronRightIcon color={textColors.primary} />}
                      onClick={handleNextClip}
                      isDisabled={activeClip >= clips.length - 1 || clips[activeClip + 1] === undefined}
                    />
                  </HStack>
                </VStack>
              </Case>
            </Switch>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default KpiClips;
