import { Box } from "@chakra-ui/react";
import { ChangeEvent, useContext } from "react";
import { ShadowteamEntry } from "../../../generated";
import { useComparisonStore } from "../../../store/store";
import PlayerTable, { playerId } from "../../general/PlayerTable";
import {columns} from "./ShadowteamTableColumns";
import { LanguageContext } from "../../../store/languageContext";

interface Props {
  data: ShadowteamEntry[];
  deletePlayerLocal: (playerId: number) => void;
  onOpenQuickView: (entry: ShadowteamEntry) => void;
}
const ShadowteamTable = ({
  data,
  deletePlayerLocal,
  onOpenQuickView,
}: Props) => {
  const {guiText} = useContext(LanguageContext)

  const { players, addPlayer, deletePlayer } = useComparisonStore();

  const selectedPlayerIds = players.map(
    (player) => player.id + "_" + player.positionsGruppe
  );

  const onSelect = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
    id: string
  ) => {
    const rowId = id.split("_");

    if (checked) {
      const entryToAdd = data?.find(
        (entry) =>
          entry.player.id === Number(rowId[0]) &&
          entry.player.positionsGruppe === rowId[1]
      );
      if (entryToAdd) {
        addPlayer(entryToAdd.player);
      }
    } else {
      deletePlayer(Number(rowId[0]), rowId[1]);
    }
  };

  const sortedData = [...data].sort((a, b) => {
    if (a.shadowteamPosition < b.shadowteamPosition) return -1;
    if (a.shadowteamPosition > b.shadowteamPosition) return 1;

    // Names are equal, sort by age
    return a.prio - b.prio;
  });

  return (
    <Box flex={1} display="flex" overflow="hidden">
      <PlayerTable
        name="shadowteams"
        players={sortedData ?? []}
        getRowId={(entry: ShadowteamEntry) => playerId(entry.player)}
        columnDefinitions={columns({
          onClick: onOpenQuickView,
          onSelect,
          selectedPlayerIds,
          deletePlayerLocal,
          guiText
        })}
      />
    </Box>
  );
};

export default ShadowteamTable;
