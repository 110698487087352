import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { GridValueGetterParams, GridRowOrderChangeParams } from "@mui/x-data-grid-pro";
import { cloneDeep } from "lodash";
import { ChangeEvent } from "react";
import { colorsPrimary, colorsSecondary, textColors } from "../../../colorStyles";
import { Shadowteam, ShadowteamEntry } from "../../../generated";
import { useComparisonStore } from "../../../store/store";
import PlayerTable, { playerId } from "../../general/PlayerTable";
import { columns } from "../table/ShadowteamTableColumns";
import { GuiTextType } from "../../../store/languageContext";
import { LanguageKeys } from "../../../language/LanguageKeys";
import { text } from "d3";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  shadowteam: Shadowteam | undefined;
  position: string;
  onOpenQuickView: (player: ShadowteamEntry) => void;
  deletePlayer: (playerId: number) => void;
  saveShadowteam: (shadowteam: Shadowteam) => void;
  guiText: GuiTextType;
}

const PositionDetailsModal = ({
  isOpen,
  onClose,
  shadowteam,
  position,
  onOpenQuickView,
  deletePlayer,
  saveShadowteam,
  guiText,
}: Props) => {
  const {
    players: comparisonPlayers,
    addPlayer: addPlayerToComparison,
    deletePlayer: deltePlayerFromComparison,
  } = useComparisonStore();

  const selectedPlayerIds = comparisonPlayers.map(
    (player) => player.id + "_" + player.positionsGruppe
  );

  const onSelect = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
    id: string
  ) => {
    const rowId = id.split("_");

    if (checked) {
      const entryToAdd = shadowteam?.players?.find(
        (entry) =>
          entry.player.id === Number(rowId[0]) &&
          entry.player.positionsGruppe === rowId[1]
      );
      if (entryToAdd) {
        addPlayerToComparison(entryToAdd.player);
      }
    } else {
      deltePlayerFromComparison(Number(rowId[0]), rowId[1]);
    }
  };

  const filteredPlayers = shadowteam?.players
    .filter((player) => player.shadowteamPosition === position)
    .map((player) => ({
      ...player,
      __reorder__: player.player.firstName + " " + player.player.surname,
    }))
    .sort((a, b) => a.prio - b.prio);

  const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
    const playersCopy = cloneDeep(filteredPlayers);
    const player = playersCopy?.splice(params.oldIndex, 1)[0];
    playersCopy?.splice(params.targetIndex, 0, player!);

    playersCopy?.forEach((player, index) => {
      player.prio = index + 1;
    });

    shadowteam?.players.forEach((player) => {
      const matchingPlayer = playersCopy?.find(
        (p) =>
          p.player.id === player.player.id &&
          p.player.positionsGruppe === player.player.positionsGruppe
      );

      if (matchingPlayer) {
        player.prio = matchingPlayer.prio;
      }
    });

    saveShadowteam(shadowteam!);
  };

  const addAllPlayersToComparison = () => {
    filteredPlayers?.forEach((player) => {
      if (player.player.positionsGruppe) {
        addPlayerToComparison(player.player);
      }
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay overflow="hidden" />
      <ModalContent
        minW="fit-content"
        overflow="hidden"
        containerProps={{ overflow: "hidden" }}
        backgroundColor={colorsSecondary.background}
        color={textColors.primary}
      >
        <ModalHeader pb={0}>
          <Box display="flex" flexDirection="column" position="relative">
            {" "}
            <Text>{guiText[LanguageKeys.Schattenmannschaft] + ": " + shadowteam?.shadowteamName}</Text>
            <Text fontWeight="normal" fontSize="18px">
              {guiText[LanguageKeys.SchattenmannschaftenFormation] + ": " + shadowteam?.formation}
            </Text>
            <Text fontWeight="normal" fontSize="18px">
              {guiText[LanguageKeys.SearchBarPosition] + ": " + position}
            </Text>
            <Button
              position="absolute"
              bottom="0"
              right="0"
              color="white"
              bgColor={colorsSecondary.accent}
              fontSize={14}
              onClick={addAllPlayersToComparison}
            >
              {guiText[LanguageKeys.AlleSpielerzumVergleichHinzufuegen]}
            </Button>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          display="flex"
          flex={1}
          overflow="hidden"
          minH="80vh"
          maxH="80vh"
          minW="85vw"
        >
          <Box flex={1} display="flex" flexDir="column" overflow="auto" pb={4}>
            <PlayerTable
              name="positionDetails"
              players={filteredPlayers ?? []}
              getRowId={(entry: ShadowteamEntry) => playerId(entry.player)}
              columnDefinitions={[
                {
                  field: "prio",
                  headerName: "Prio",
                  valueGetter: (params: GridValueGetterParams) =>
                    params.row.prio,

                  flex: 0.1,
                  align: "center",
                  headerAlign: "center",
                  disableColumnMenu: true,
                },
                ...columns({
                  onClick: onOpenQuickView,
                  selectedPlayerIds,
                  onSelect,
                  deletePlayerLocal: deletePlayer,
                  guiText,
                }),
              ]}
              rowReordering
              onRowOrderChange={handleRowOrderChange}
            />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PositionDetailsModal;
