import { Box } from "@chakra-ui/react";
import { SxProps, ThemeProvider } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { deDE, enUS, esES, GridColDef, DataGridPro, GridLocaleText, } from "@mui/x-data-grid-pro";
import { PropsWithChildren, useContext } from "react";
import { LanguageContext } from "../../store/languageContext";
import { LocaleKeys } from "../../language/LocaleKeys";

interface Props<DataType> {
  rows: DataType[];
  columnDefinitions: GridColDef[];
  theme: Theme;
  sx?: SxProps<Theme>;
  [x: string]: any;
}

function Table<DataType>({
  rows,
  columnDefinitions,
  theme,
  sx,
  ...rest
}: PropsWithChildren<Props<DataType>>) {
  const { language } = useContext(LanguageContext);

  let muiLocaleText: Partial<GridLocaleText>;
  switch (language) {
    case LocaleKeys.DE:
      muiLocaleText = deDE.components.MuiDataGrid.defaultProps.localeText;
      break;
    case LocaleKeys.EN:
      muiLocaleText = enUS.components.MuiDataGrid.defaultProps.localeText;
      break;
    case LocaleKeys.ES:
      muiLocaleText = esES.components.MuiDataGrid.defaultProps.localeText;
      break;
    default:
      muiLocaleText = deDE.components.MuiDataGrid.defaultProps.localeText;
  }

  return (
    <ThemeProvider theme={theme}>
      <Box flex={1} h="100%">
        <DataGridPro
          // columns definition and settings
          columns={columnDefinitions}
          disableColumnFilter
          // rows definition and settings
          rows={rows}
          rowCount={rows.length}
          getRowClassName={(params) => {
            if (
              params.columns[params.columns.length - 1].field === "similarity"
            ) {
              return params.row.pinned ? "even" : "odd";
            }

            return params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd";
          }}
          getRowHeight={() => "auto"}
          disableRowSelectionOnClick
          // pagination
          initialState={{
            pagination: { paginationModel: { pageSize: 20 } },
            sorting: {
              sortModel: [
                {
                  field: "favorite",
                  sort: "desc",
                },
              ],
            },
          }}
          pagination
          pageSizeOptions={[20, 50, 100]}
          // styling
          localeText={muiLocaleText}
          style={{ width: "100%" }}
          sx={{
            "& .MuiDataGrid-columnHeaderTitle": {
              textOverflow: "clip",
              whiteSpace: "pre-line",
              lineHeight: 1,
              textAlign: "center",
            },
            "& .MuiDataGrid-cell:last-child": {
              backgroundColor: "transparent",
            },
            "& .MuiDataGrid-columnHeader:last-child": {
              backgroundColor: "transparent",
            },
            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
              display: "none",

            },
            ...sx
          }}
          scrollbarSize={0}
          {...rest}
        />
      </Box>
    </ThemeProvider>
  );
}

export default Table;
