import { Button, HStack } from "@chakra-ui/react";
import { colorsSecondary, textColors } from "../../colorStyles";

interface Props {
  categories: { [key: string]: string };
}

const CategoryButtonsRow = ({ categories }: Props) => {
  return (
    <HStack
      flex={1}
      display="flex"
      spacing={3}
      position="sticky"
      top="155px"
      zIndex={999}
      pb={2}
      bg={colorsSecondary.background}
    >
      <Button
        onClick={() => {
          document.getElementById("container")?.scrollTo({
            top: 0,

            behavior: "smooth",
          });
        }}
        bg={colorsSecondary.accent}
        boxShadow="md"
        color={colorsSecondary.cardBackground}
      >
        alleyes Scores
      </Button>
      {Object.entries(categories).map(([key, value]) => (
        <Button
          onClick={() => {
            document.getElementById(key)?.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }}
          bg={colorsSecondary.accent}
          color={colorsSecondary.cardBackground}
          boxShadow="md"
          key={key}
        >
          {value}
        </Button>
      ))}
    </HStack>
  );
};

export default CategoryButtonsRow;
