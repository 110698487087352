/* tslint:disable */
/* eslint-disable */
/**
 * alleyes SCOUT BACKEND
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ScoutingReport
 */
export interface ScoutingReport {
    /**
     * Report identifier.
     * @type {string}
     * @memberof ScoutingReport
     */
    reportId: string;
    /**
     * Player identifier.
     * @type {number}
     * @memberof ScoutingReport
     */
    spielerId: number;
    /**
     * First name of the player.
     * @type {string}
     * @memberof ScoutingReport
     */
    spielerVorname: string;
    /**
     * Last name of the player.
     * @type {string}
     * @memberof ScoutingReport
     */
    spielerNachname: string;
    /**
     * The team of the player.
     * @type {string}
     * @memberof ScoutingReport
     */
    spielerTeam?: string;
    /**
     * First name of the scout.
     * @type {string}
     * @memberof ScoutingReport
     */
    scoutVorname: string;
    /**
     * Last name of the scout.
     * @type {string}
     * @memberof ScoutingReport
     */
    scoutNachname: string;
    /**
     * Home game player identifier.
     * @type {string}
     * @memberof ScoutingReport
     */
    spielerIdHeimspiel?: string;
    /**
     * SAP player identifier.
     * @type {string}
     * @memberof ScoutingReport
     */
    spielerIdSap?: string;
    /**
     * Type of year group.
     * @type {string}
     * @memberof ScoutingReport
     */
    jahrgangstyp?: string;
    /**
     * Team.
     * @type {string}
     * @memberof ScoutingReport
     */
    mannschaft?: string;
    /**
     * SAP position.
     * @type {string}
     * @memberof ScoutingReport
     */
    positionSap?: string;
    /**
     * Detailed SAP position.
     * @type {string}
     * @memberof ScoutingReport
     */
    positionSapDetail?: string;
    /**
     * Category.
     * @type {string}
     * @memberof ScoutingReport
     */
    kategorie?: string;
    /**
     * Event name.
     * @type {string}
     * @memberof ScoutingReport
     */
    eventName?: string;
    /**
     * Event date.
     * @type {Date}
     * @memberof ScoutingReport
     */
    eventDate?: Date;
    /**
     * Rating.
     * @type {number}
     * @memberof ScoutingReport
     */
    rating?: number;
    /**
     * Textual representation of the rating.
     * @type {string}
     * @memberof ScoutingReport
     */
    ratingText?: string;
    /**
     * Potential rating.
     * @type {number}
     * @memberof ScoutingReport
     */
    potentialRating?: number;
    /**
     * Textual representation of the potential rating.
     * @type {string}
     * @memberof ScoutingReport
     */
    potentialRatingText?: string;
    /**
     * Scout comment.
     * @type {string}
     * @memberof ScoutingReport
     */
    kommentarScout?: string;
    /**
     * Creation date.
     * @type {Date}
     * @memberof ScoutingReport
     */
    erstellungsdatum?: Date;
    /**
     * Last modification date.
     * @type {Date}
     * @memberof ScoutingReport
     */
    letztesAenderungsdatum?: Date;
}

/**
 * Check if a given object implements the ScoutingReport interface.
 */
export function instanceOfScoutingReport(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "reportId" in value;
    isInstance = isInstance && "spielerId" in value;
    isInstance = isInstance && "spielerVorname" in value;
    isInstance = isInstance && "spielerNachname" in value;
    isInstance = isInstance && "scoutVorname" in value;
    isInstance = isInstance && "scoutNachname" in value;

    return isInstance;
}

export function ScoutingReportFromJSON(json: any): ScoutingReport {
    return ScoutingReportFromJSONTyped(json, false);
}

export function ScoutingReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScoutingReport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reportId': json['reportId'],
        'spielerId': json['spielerId'],
        'spielerVorname': json['spielerVorname'],
        'spielerNachname': json['spielerNachname'],
        'spielerTeam': !exists(json, 'spielerTeam') ? undefined : json['spielerTeam'],
        'scoutVorname': json['scoutVorname'],
        'scoutNachname': json['scoutNachname'],
        'spielerIdHeimspiel': !exists(json, 'spielerIdHeimspiel') ? undefined : json['spielerIdHeimspiel'],
        'spielerIdSap': !exists(json, 'spielerIdSap') ? undefined : json['spielerIdSap'],
        'jahrgangstyp': !exists(json, 'jahrgangstyp') ? undefined : json['jahrgangstyp'],
        'mannschaft': !exists(json, 'mannschaft') ? undefined : json['mannschaft'],
        'positionSap': !exists(json, 'positionSap') ? undefined : json['positionSap'],
        'positionSapDetail': !exists(json, 'positionSapDetail') ? undefined : json['positionSapDetail'],
        'kategorie': !exists(json, 'kategorie') ? undefined : json['kategorie'],
        'eventName': !exists(json, 'eventName') ? undefined : json['eventName'],
        'eventDate': !exists(json, 'eventDate') ? undefined : (new Date(json['eventDate'])),
        'rating': !exists(json, 'rating') ? undefined : json['rating'],
        'ratingText': !exists(json, 'ratingText') ? undefined : json['ratingText'],
        'potentialRating': !exists(json, 'potentialRating') ? undefined : json['potentialRating'],
        'potentialRatingText': !exists(json, 'potentialRatingText') ? undefined : json['potentialRatingText'],
        'kommentarScout': !exists(json, 'kommentarScout') ? undefined : json['kommentarScout'],
        'erstellungsdatum': !exists(json, 'erstellungsdatum') ? undefined : (new Date(json['erstellungsdatum'])),
        'letztesAenderungsdatum': !exists(json, 'letztesAenderungsdatum') ? undefined : (new Date(json['letztesAenderungsdatum'])),
    };
}

export function ScoutingReportToJSON(value?: ScoutingReport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reportId': value.reportId,
        'spielerId': value.spielerId,
        'spielerVorname': value.spielerVorname,
        'spielerNachname': value.spielerNachname,
        'spielerTeam': value.spielerTeam,
        'scoutVorname': value.scoutVorname,
        'scoutNachname': value.scoutNachname,
        'spielerIdHeimspiel': value.spielerIdHeimspiel,
        'spielerIdSap': value.spielerIdSap,
        'jahrgangstyp': value.jahrgangstyp,
        'mannschaft': value.mannschaft,
        'positionSap': value.positionSap,
        'positionSapDetail': value.positionSapDetail,
        'kategorie': value.kategorie,
        'eventName': value.eventName,
        'eventDate': value.eventDate === undefined ? undefined : (value.eventDate.toISOString().substr(0,10)),
        'rating': value.rating,
        'ratingText': value.ratingText,
        'potentialRating': value.potentialRating,
        'potentialRatingText': value.potentialRatingText,
        'kommentarScout': value.kommentarScout,
        'erstellungsdatum': value.erstellungsdatum === undefined ? undefined : (value.erstellungsdatum.toISOString().substr(0,10)),
        'letztesAenderungsdatum': value.letztesAenderungsdatum === undefined ? undefined : (value.letztesAenderungsdatum.toISOString().substr(0,10)),
    };
}

