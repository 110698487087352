import {
  Badge,
  Box,
  HStack,
  IconButton,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { forwardRef } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { MdOutlineCompareArrows } from "react-icons/md";
import fallbackPicture from "../../assets/placeholder-picture.png";
import { colorsPrimary, colorsSecondary, textColors } from "../../colorStyles";
import { Player } from "../../generated";
import { useProfilePicture } from "../../hooks/usePlayer";
import { useComparisonStore } from "../../store/store";
import { getRatingColor, positionAbbreviation } from "../../util/utilFunctions";
import { text } from "d3";

interface Props {
  player: Player;
  useCase: string;
  clearSearchbar?: () => void;
  shadowteamPosition?: string;
}

const PlayerViewSmall = forwardRef(
  (
    { player, useCase, clearSearchbar, shadowteamPosition }: Props,
    ref: any
  ) => {
    const {
      deletePlayer,
      addPlayer,
      clearAddToComp,
      addToComp1,
      addToComp2,
      addToComp3,
      setPlayer1,
      setPlayer2,
      setPlayer3,
    } = useComparisonStore();
    const { data: profilePic, error, isLoading } = useProfilePicture(player.id);

    const handleOnAddToComp = (e: React.MouseEvent) => {
      e.stopPropagation();
      addPlayer(player);

      if (addToComp1) {
        setPlayer1(player);
        clearAddToComp();
      } else if (addToComp2) {
        setPlayer2(player);
        clearAddToComp();
      } else if (addToComp3) {
        setPlayer3(player);
        clearAddToComp();
      }

      if (clearSearchbar) {
        // Check if clearSearchbar is defined
        clearSearchbar();
      }
    };

    return (
      <Box width="100%" zIndex={1900}>
        <HStack justifyContent="space-between">
          <HStack>
            <Image
              src={profilePic ? profilePic : fallbackPicture}
              borderRadius="lg"
              boxSize="45px"
              align="center"
              data-cy={"profile"}
            />
            <Stack spacing="0">
              <HStack>
                <Text fontSize="md" data-cy={"fullName"} color={textColors.primary}>
                  {useCase == "searchBar"
                    ? player.firstName + " " + player.surname
                    : player.playerName}
                </Text>
                <Badge
                  alignItems="center"
                  justifyContent="center"
                  background={getRatingColor(player.alleyes)}
                  color="white"
                  pl={2}
                  pr={2}
                  data-cy={"badge"}
                >
                  {player.alleyes ? Math.round(player.alleyes) : "-"}
                </Badge>
              </HStack>

              <Text color={textColors.secondary} fontSize="sm" data-cy={"info"}>
                {player.team +
                  " | " +
                  (shadowteamPosition
                    ? shadowteamPosition
                    : positionAbbreviation(player.positionsGruppe!)) +
                  " | " +
                  (player.age + " y.o.")}
              </Text>
            </Stack>
          </HStack>
          {(useCase == "comparisonCart" || useCase == "comparison") && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                deletePlayer(player.id, player.positionsGruppe!);
              }}
              icon={<AiFillCloseCircle size={22} />}
              boxSize={10}
              aria-label="Delete"
              // outline={"1px solid " + colorsPrimary.dunkelblau40}
              // background="white"
              _hover={{
                color: colorsSecondary.highlight2,
              }}
              color="gray.500"
              as="div"
              role="button"
              data-cy={"removeButton"}
            ></IconButton>
          )}
          {useCase == "searchBar" && (
            <IconButton
              onClick={handleOnAddToComp}
              icon={<MdOutlineCompareArrows size={15} />}
              boxSize={10}
              aria-label="Add to comparison"
              // outline={"1px solid " + colorsPrimary.dunkelblau40}
              // background="white"
              _hover={{
                color: colorsSecondary.accent,
              }}
              color={textColors.primary}
              as="div"
              role="button"
              data-cy={"addToComparisonButton"}
            ></IconButton>
          )}
        </HStack>
      </Box>
    );
  }
);

export default PlayerViewSmall;
