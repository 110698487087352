import { Box, Grid, GridItem, Heading, useDisclosure } from "@chakra-ui/react";
import { useKeycloak } from "@react-keycloak/web";

import React, { useContext } from "react";
import MissingReviews from "../components/home/MissingReviews";
import NewPlayers from "../components/home/NewPlayers";
import SliderPanel from "../components/home/SliderPanel";
import PlayerModal from "../components/search/playerGrid/PlayerModal";
import { Player } from "../generated";
import { LanguageKeys } from "../language/LanguageKeys";
import { LanguageContext } from "../store/languageContext";
import { textColors } from "../colorStyles";

const HomePage = () => {
  const { guiText } = useContext(LanguageContext);

  const { keycloak } = useKeycloak();
  const idToken = keycloak.idTokenParsed;
  const { isOpen, onClose } = useDisclosure();

  return (
    <>
      <Box display="flex" flex={1} flexDir="column" overflow="auto">
        <Heading id="home-greeting" pb={2} fontSize={26} color={textColors.primary} fontFamily={"Text_Regular"}>
          {guiText[LanguageKeys.Greeting] + idToken?.given_name + "!"}
        </Heading>
        <Grid
          templateAreas={`"slider newPlayers"
                          "slider missingReview"`}
          gridTemplateRows={"1fr 1fr"}
          gridTemplateColumns={"1fr 30%"}
          gap="4"
          color={textColors.primary}
          flex={1}
          pb={3}
          overflow="auto"
        >
          <GridItem
            area={"slider"}
            display="flex"
            overflow="hidden"
            alignItems="flex-start"
          >
            <SliderPanel />
          </GridItem>

          <GridItem area={"newPlayers"} display="flex" overflow="hidden">
            <NewPlayers />
          </GridItem>

          <GridItem area={"missingReview"} display="flex" overflow="hidden">
            <MissingReviews />
          </GridItem>
        </Grid>
      </Box >
      {isOpen && <PlayerModal isOpen={isOpen} onClose={onClose} location="home" />
      }
    </>
  );
};

export default HomePage;
