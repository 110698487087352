import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  List,
  ListItem,
  Text,
} from "@chakra-ui/react";
import { useKeycloak } from "@react-keycloak/web/lib/useKeycloak";
import debounce from "lodash.debounce";
import { ChangeEvent, useCallback, useContext, useRef, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { usePlayersByName } from "../hooks/usePlayer";
import roles from "../util/roles";
import PlayerViewSmall from "./comparison/PlayerViewSmall";
import { LanguageContext } from "../store/languageContext";
import { LanguageKeys } from "../language/LanguageKeys";
import { colorsSecondary, textColors } from "../colorStyles";
import { text } from "d3";
const SearchBar = () => {
  const { guiText } = useContext(LanguageContext);

  const [name, setName] = useState("");
  const [debouncedName, setDebouncedName] = useState("");

  const ref = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const { data: players, isLoading } = usePlayersByName(debouncedName);

  const [debouncing, setDebouncing] = useState(false);

  const [isResultsBoxOpen, setIsResultsBoxOpen] = useState(false);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    debouncedOnChange(e.target.value);
  };

  const searchDebounced = useCallback(debounce((searchValue) => {
    setDebouncing(false);
    setDebouncedName(searchValue);
  }, 500), []);
  const debouncedOnChange = useCallback((searchValue: string) => {
    setDebouncing(true);
    searchDebounced(searchValue);
  }, [],
  );

  const blurTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleBlur = () => {
    blurTimeoutRef.current = setTimeout(() => {
      setIsResultsBoxOpen(false);
    }, 150);
  };

  const handleFocus = () => {
    if (blurTimeoutRef.current) {
      clearTimeout(blurTimeoutRef.current);
      blurTimeoutRef.current = null;
    }
    setIsResultsBoxOpen(true);
  };

  const clearSearchBar = () => {
    setName("");
  };

  const k = useKeycloak();
  const hasRoleSearchPlayers = k.keycloak.hasResourceRole(roles.search_players);

  return (
    <Box w="100%" data-cy={"searchBar"}>
      <InputGroup w="100%">
        <InputLeftElement>
          <BsSearch color={textColors.primary} />
        </InputLeftElement>
        {name && (
          <InputRightElement onClick={() => setName("")} data-cy={"clearButton"}>
            <AiFillCloseCircle fill="#718096" />
          </InputRightElement>
        )}
        <Input
          id="searchbar"
          ref={ref}
          borderRadius={20}
          placeholder={guiText[LanguageKeys.SearchBarSpielernamenEingeben]}
          variant="filled"
          color={textColors.primary}
          background={colorsSecondary.cardBackground}
          focusBorderColor={colorsSecondary.accent}
          _hover={{}}
          onChange={onChange}
          value={name}
          onBlur={handleBlur}
          onFocus={handleFocus}
          disabled={!hasRoleSearchPlayers}
        />
      </InputGroup>
      {name && isResultsBoxOpen && (
        <Box
          mt={4}
          position="absolute"
          bg={colorsSecondary.cardBackground}
          color={textColors.primary}
          borderRadius={15}
          minW="25%"
          p={3}
          zIndex={1600}
          boxShadow="lg"
          data-cy={"resultBox"}
        >
          {debouncing || isLoading ? (
            <Text>Loading...</Text>
          ) : players?.length === 0 ? (
            <Text color={textColors.primary}>{guiText[LanguageKeys.KeineSpielerGefunden]}</Text>
          ) : (
            <List>
              {players?.map((player, idx) => (
                <ListItem
                  key={player.id}
                  pb={idx === players.length - 1 ? 0 : 2}
                  onClick={() => {
                    setName("");
                    navigate(
                      `/player/${player.id}?position=${player.positionsGruppe}&idTm=${player.idTm}`,
                    );
                  }}
                  style={{ cursor: "pointer" }}
                  _hover={{ backgroundColor: "rgba(0, 75, 156,0.1)" }}
                  p={2}
                  data-cy={`result-${player.id}`}
                >
                  <PlayerViewSmall
                    player={player}
                    useCase={"searchBar"}
                    clearSearchbar={clearSearchBar}
                  ></PlayerViewSmall>
                </ListItem>
              ))}
            </List>
          )}
        </Box>
      )
      }
    </Box >
  );
};

export default SearchBar;
