import {
  Box,
  Card,
  CardBody,
  CardFooter,
  Center,
  Checkbox,
  Divider,
  Image,
  SkeletonCircle,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { Player } from "../../../generated";
import CountryFlag from "../../CountryFlag";

import fallbackPicture from "../../../assets/placeholder-picture.png";

import {
  useNavigateToPlayer,
  useProfilePicture,
  useTeamPicture,
} from "../../../hooks/usePlayer";
import { useComparisonStore, useOverallPotentialStore } from "../../../store/store";
import PlayerText from "../../general/PlayerText";
import RatingBadgeStack from "../../general/RatingBadgeStack";
import WatchlistButton from "../../general/WatchlistButton";
import { LanguageKeys } from "../../../language/LanguageKeys";
import { LanguageContext } from "../../../store/languageContext";
import { colorsSecondary, textColors } from "../../../colorStyles";

interface Props {
  player: Player;
  onOpenModal: () => void;
}

const PlayerCard = React.memo(({ player, onOpenModal }: Props) => {
  const { guiText } = useContext(LanguageContext);
  const { addPlayer, deletePlayer, isInComparison } = useComparisonStore();

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      addPlayer(player);
    } else {
      deletePlayer(player.id, player.positionsGruppe);
    }
  };

  const navigateToPlayer = useNavigateToPlayer();

  const { overall } = useOverallPotentialStore();

  const { data: profilePic, isLoading } = useProfilePicture(player.id);
  const { data: teamPic, error: teamError } = useTeamPicture(
    player.teamId ?? -1
  );

  return (
    <Card onClick={() => navigateToPlayer(player)} className="hover-highlight" data-cy={"playerCard"} backgroundColor={colorsSecondary.cardBackground} >
      <CardBody pb={2}>
        <Box position="relative">
          <Center>
            {isLoading ? (
              <SkeletonCircle boxSize="130px" />
            ) : (
              <Image
                src={profilePic ?? fallbackPicture}
                borderRadius="lg"
                boxSize="170px"
                align="center"
              />
            )}
          </Center>
          {/* left badges ---------------------------------------------- */}
          <VStack position="absolute" top="0px" left="0px" spacing={3}>
            <WatchlistButton player={player} onClick={onOpenModal} />
            {player.nationality && (
              <CountryFlag
                nationality={player.nationality}
                size={30}
              ></CountryFlag>
            )}
            {!teamError && teamPic && <Image src={teamPic} boxSize={8}></Image>}
          </VStack>
          {/* right badges ---------------------------------------------- */}
          <VStack position="absolute" top="0px" right="0px" spacing={3}>
            <RatingBadgeStack player={player} overall={overall} />
          </VStack>
        </Box>
        <PlayerText player={player} mt={6} />
      </CardBody>
      <Divider color="gray.300" />
      <CardFooter padding={2.5}>
        <div onClick={(e) => e.stopPropagation()} role="button">
          <Checkbox
            size="lg"
            onChange={handleCheckboxChange}
            isChecked={isInComparison(player.id, player.positionsGruppe)}
          >
            <Text size="sm" color={textColors.lightgrey}>
              {guiText[LanguageKeys.SearchBarVergleichen]}
            </Text>
          </Checkbox>
        </div>
      </CardFooter>
    </Card>
  );
});

export default PlayerCard;
