import { Badge, Box, Center, HStack, Text, VStack } from "@chakra-ui/react";
import { Chart as ChartJS, Filler } from "chart.js";
import { Line } from "react-chartjs-2";
import { useAlleyesRatingHistory } from "../../hooks/usePlayer";
import usePrepareAlleyesRatingsHistoryData, {
  useAlleyesRatingHistoryOptions,
} from "../../hooks/usePrepareAlleyesRatingsHistoryData";
import { usePlayerProfileStore } from "../../store/store";
import { formatDate, getRatingColor } from "../../util/utilFunctions";
import { useContext } from "react";
import { LanguageContext } from "../../store/languageContext";
import { LanguageKeys } from "../../language/LanguageKeys";
import { textColors } from "../../colorStyles";

ChartJS.register(Filler);

interface Props {
  playerId: number;
  position: string;
  date?: Date;
  useCase: string;
  [x: string]: any;
}

const AlleyesScores = ({ playerId, position, date, useCase, ...rest }: Props) => {
  const { guiText } = useContext(LanguageContext);
  const { data, isLoading } = useAlleyesRatingHistory(playerId, position);

  const { date: profileDate } = usePlayerProfileStore();
  const datee = date ?? profileDate;

  const rating = data?.alleyeshistory!.find(
    (rating) => formatDate(rating.date) == formatDate(datee)
  );

  const graphData = usePrepareAlleyesRatingsHistoryData(data?.alleyeshistory!, datee);
  const options = useAlleyesRatingHistoryOptions({ useCase });

  return (
    <>
      {isLoading ? (
        <Center h="100%" w="100%" >
          <Text fontSize={22}>Loading...</Text>
        </Center>
      ) : (
        <Box
          textAlign="center"
          w="100%"
          h="100%"
          pt={2}
          display="flex"
          flexDir="column"
          flex={1}
          {...rest}
        >
          <Text fontSize="16" fontWeight="bold" m={0} p={0} color={textColors.primary}>
            All Eyes
          </Text>
          <HStack
            justifyContent="space-around"
            flex={0}
            p={0}
            alignItems="flex-start"
          >
            <VStack>
              <Text fontSize="14" color={textColors.primary}>{guiText[LanguageKeys.Gesamtrating]}</Text>
              <Badge
                borderRadius="50%"
                width="40px"
                height="40px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                background={getRatingColor(rating?.ratingCurrent)}
              >
                <Text color="white" fontSize="16" lineHeight="1">
                  {rating?.ratingCurrent
                    ? Math.round(rating.ratingCurrent)
                    : "-"}
                </Text>
              </Badge>
            </VStack>
          </HStack>

          <Box flex={1} overflow="hidden">
            <Line
              options={options}
              data={graphData}
              style={{ maxWidth: "100%", maxHeight: "100%" }}

            />
          </Box>
          <Box flex={0}>
            <HStack>
              <Box textAlign="center" w="50%" alignItems="center" color={textColors.primary}>
                <Text pb={2}>{guiText[LanguageKeys.Gesamtbewertung]}</Text>
                <HStack justify="space-evenly">
                  <Box display="flex" alignItems="center" flexDir="column">
                    <Text fontSize="13" pb={1}>
                      {guiText[LanguageKeys.Aktuell]}
                    </Text>
                    <Badge
                      borderRadius="50%"
                      width="40px"
                      height="40px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      background={getRatingColor(
                        data?.subjectiveOverallRating,
                        0,
                        10
                      )}
                    >
                      <Text color="white" fontSize="16" lineHeight="1">
                        {data?.subjectiveOverallRating?.toFixed(1) ?? "-"}
                      </Text>
                    </Badge>
                  </Box>
                  <Box display="flex" alignItems="center" flexDir="column">
                    <Text fontSize="13" pb={1}>
                      {guiText[LanguageKeys.Potential]}
                    </Text>
                    <Badge
                      borderRadius="50%"
                      width="40px"
                      height="40px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      background={getRatingColor(
                        data?.subjectiveOverallPotential,
                        0,
                        10
                      )}
                    >
                      <Text color="white" fontSize="16" lineHeight="1">
                        {data?.subjectiveOverallPotential?.toFixed(1) ?? "-"}
                      </Text>
                    </Badge>
                  </Box>
                </HStack>
              </Box>
              <Box textAlign="center" w="50%" color={textColors.primary}>
                <Text pb={2}>{guiText[LanguageKeys.Spielbewertung]}</Text>
                <HStack justify="space-evenly">
                  <Box display="flex" alignItems="center" flexDir="column">
                    <Text fontSize="13" pb={1}>
                      {guiText[LanguageKeys.Aktuell]}
                    </Text>
                    <Badge
                      borderRadius="50%"
                      width="40px"
                      height="40px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      background={getRatingColor(
                        data?.subjectiveGameRating,
                        0,
                        10
                      )}
                    >
                      <Text color="white" fontSize="16" lineHeight="1">
                        {data?.subjectiveGameRating?.toFixed(1) ?? "-"}
                      </Text>
                    </Badge>
                  </Box>
                  <Box display="flex" alignItems="center" flexDir="column">
                    <Text fontSize="13" pb={1}>
                      {guiText[LanguageKeys.Potential]}
                    </Text>
                    <Badge
                      borderRadius="50%"
                      width="40px"
                      height="40px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      background={getRatingColor(
                        data?.subjectiveGamePotential,
                        0,
                        10
                      )}
                    >
                      <Text color="white" fontSize="16" lineHeight="1">
                        {data?.subjectiveGamePotential?.toFixed(1) ?? "-"}
                      </Text>
                    </Badge>
                  </Box>
                </HStack>
              </Box>
            </HStack>
          </Box>
        </Box>
      )}
    </>
  );
};

export default AlleyesScores;
