import { useKeycloak } from "@react-keycloak/web";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const { keycloak, initialized } = useKeycloak();
  const navigate = useNavigate();

  useEffect(() => {
    if (initialized) {
      if (!keycloak.authenticated) {
        keycloak.login().catch(console.error);
      } else {
        navigate("/");
      }
    }
  }, [keycloak.authenticated, initialized]);

  return null;
};

export default Login;
