/* tslint:disable */
/* eslint-disable */
/**
 * alleyes SCOUT BACKEND
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddCommentRequest,
  AlleyesRatingHistory,
  Comment,
  ErrorBody,
  GetGamesAndGametime200Response,
  Heatmap,
  Injury,
  KpiClip,
  MarketValueEntry,
  Player,
  PlayerBaseData,
  PlayerQuery,
  PlayerQueryResult,
  PolarChartComparisonData,
  PolarChartData,
  Rating,
  ScoutingReport,
  ShadowteamBaseInfo,
  Shot,
  SimilarPlayer,
  Transfer,
} from '../models';
import {
    AddCommentRequestFromJSON,
    AddCommentRequestToJSON,
    AlleyesRatingHistoryFromJSON,
    AlleyesRatingHistoryToJSON,
    CommentFromJSON,
    CommentToJSON,
    ErrorBodyFromJSON,
    ErrorBodyToJSON,
    GetGamesAndGametime200ResponseFromJSON,
    GetGamesAndGametime200ResponseToJSON,
    HeatmapFromJSON,
    HeatmapToJSON,
    InjuryFromJSON,
    InjuryToJSON,
    KpiClipFromJSON,
    KpiClipToJSON,
    MarketValueEntryFromJSON,
    MarketValueEntryToJSON,
    PlayerFromJSON,
    PlayerToJSON,
    PlayerBaseDataFromJSON,
    PlayerBaseDataToJSON,
    PlayerQueryFromJSON,
    PlayerQueryToJSON,
    PlayerQueryResultFromJSON,
    PlayerQueryResultToJSON,
    PolarChartComparisonDataFromJSON,
    PolarChartComparisonDataToJSON,
    PolarChartDataFromJSON,
    PolarChartDataToJSON,
    RatingFromJSON,
    RatingToJSON,
    ScoutingReportFromJSON,
    ScoutingReportToJSON,
    ShadowteamBaseInfoFromJSON,
    ShadowteamBaseInfoToJSON,
    ShotFromJSON,
    ShotToJSON,
    SimilarPlayerFromJSON,
    SimilarPlayerToJSON,
    TransferFromJSON,
    TransferToJSON,
} from '../models';

export interface AddCommentOperationRequest {
    playerId: number;
    addCommentRequest: AddCommentRequest;
}

export interface DeleteCommentRequest {
    playerId: number;
    commentId: number;
}

export interface EditCommentRequest {
    playerId: number;
    comment: Comment;
}

export interface ExportPlayerProfileToPDFRequest {
    playerId: number;
    position: string;
    date: Date;
    language: string;
}

export interface GetAlleyesRatingHistoryRequest {
    playerId: number;
    position: string;
}

export interface GetAvailableKpiClipsRequest {
    playerId: number;
    position: string;
}

export interface GetCommentsRequest {
    playerId: number;
}

export interface GetDataAvailabilityRequest {
    playerId: number;
    position: string;
}

export interface GetFilteredPlayersRequest {
    playerQuery?: PlayerQuery;
}

export interface GetGamesAndGametimeRequest {
    playerId: number;
    position: string;
    date?: Date;
}

export interface GetHeatmapRequest {
    playerId: number;
    position: string;
    date: Date;
}

export interface GetInjuryHistoryRequest {
    playerIdTm: number;
}

export interface GetKpiClipRequest {
    playerId: number;
    position: string;
    kpiLabel: string;
    matchId: number;
    start: number;
    end: number;
}

export interface GetMarketHistoryRequest {
    playerIdTm: number;
}

export interface GetPlayerRequest {
    playerId: number;
}

export interface GetPlayerVideoRequest {
    playerId: number;
}

export interface GetPlayerWithPositionRequest {
    playerId: number;
    position: string;
}

export interface GetPlayerWithPositionAndDateRequest {
    playerId: number;
    position: string;
    date: Date;
}

export interface GetPlayersByNameRequest {
    playerName: string;
}

export interface GetPolarChartsRequest {
    playerId: number;
    date?: Date;
    position?: string;
}

export interface GetPolarChartsComparisonDataRequest {
    position: string;
    date: Date;
}

export interface GetProfilePictureRequest {
    playerId: number;
}

export interface GetRatingHistoryRequest {
    playerId: number;
    position: string;
    rating: string;
}

export interface GetScoutingReportsRequest {
    playerId: number;
}

export interface GetShotsRequest {
    playerId: number;
    position: string;
    date: Date;
}

export interface GetSimilarPlayersRequest {
    playerId: number;
    position: string;
    date: Date;
    playerQuery?: PlayerQuery;
}

export interface GetTeamPictureRequest {
    teamId: number;
}

export interface GetTransferHistoryRequest {
    playerIdTm: number;
}

export interface HasCommentsRequest {
    playerId: number;
}

export interface IsInShadowteamsRequest {
    playerId: number;
}

export interface IsInWatchlistRequest {
    playerId: number;
    position: string;
}

export interface IsInWatchlistOrShadowteamRequest {
    playerId: number;
    position: string;
}

export interface IsNotInShadowteamsRequest {
    playerId: number;
}

/**
 * 
 */
export class PlayerApi extends runtime.BaseAPI {

    /**
     * Add comment.
     */
    async addCommentRaw(requestParameters: AddCommentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.playerId === null || requestParameters.playerId === undefined) {
            throw new runtime.RequiredError('playerId','Required parameter requestParameters.playerId was null or undefined when calling addComment.');
        }

        if (requestParameters.addCommentRequest === null || requestParameters.addCommentRequest === undefined) {
            throw new runtime.RequiredError('addCommentRequest','Required parameter requestParameters.addCommentRequest was null or undefined when calling addComment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/player/{playerId}/comments`.replace(`{${"playerId"}}`, encodeURIComponent(String(requestParameters.playerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddCommentRequestToJSON(requestParameters.addCommentRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add comment.
     */
    async addComment(playerId: number, addCommentRequest: AddCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addCommentRaw({ playerId: playerId, addCommentRequest: addCommentRequest }, initOverrides);
    }

    /**
     * Delete comment.
     */
    async deleteCommentRaw(requestParameters: DeleteCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.playerId === null || requestParameters.playerId === undefined) {
            throw new runtime.RequiredError('playerId','Required parameter requestParameters.playerId was null or undefined when calling deleteComment.');
        }

        if (requestParameters.commentId === null || requestParameters.commentId === undefined) {
            throw new runtime.RequiredError('commentId','Required parameter requestParameters.commentId was null or undefined when calling deleteComment.');
        }

        const queryParameters: any = {};

        if (requestParameters.commentId !== undefined) {
            queryParameters['commentId'] = requestParameters.commentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/player/{playerId}/comments`.replace(`{${"playerId"}}`, encodeURIComponent(String(requestParameters.playerId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete comment.
     */
    async deleteComment(playerId: number, commentId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCommentRaw({ playerId: playerId, commentId: commentId }, initOverrides);
    }

    /**
     * Edit an existing Comment.
     */
    async editCommentRaw(requestParameters: EditCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.playerId === null || requestParameters.playerId === undefined) {
            throw new runtime.RequiredError('playerId','Required parameter requestParameters.playerId was null or undefined when calling editComment.');
        }

        if (requestParameters.comment === null || requestParameters.comment === undefined) {
            throw new runtime.RequiredError('comment','Required parameter requestParameters.comment was null or undefined when calling editComment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/player/{playerId}/comments`.replace(`{${"playerId"}}`, encodeURIComponent(String(requestParameters.playerId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CommentToJSON(requestParameters.comment),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Edit an existing Comment.
     */
    async editComment(playerId: number, comment: Comment, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.editCommentRaw({ playerId: playerId, comment: comment }, initOverrides);
    }

    /**
     * Exports the specified player\'s profile to a PDF document based on the provided ID, position, and date.
     * Export a player profile to PDF.
     */
    async exportPlayerProfileToPDFRaw(requestParameters: ExportPlayerProfileToPDFRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.playerId === null || requestParameters.playerId === undefined) {
            throw new runtime.RequiredError('playerId','Required parameter requestParameters.playerId was null or undefined when calling exportPlayerProfileToPDF.');
        }

        if (requestParameters.position === null || requestParameters.position === undefined) {
            throw new runtime.RequiredError('position','Required parameter requestParameters.position was null or undefined when calling exportPlayerProfileToPDF.');
        }

        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling exportPlayerProfileToPDF.');
        }

        if (requestParameters.language === null || requestParameters.language === undefined) {
            throw new runtime.RequiredError('language','Required parameter requestParameters.language was null or undefined when calling exportPlayerProfileToPDF.');
        }

        const queryParameters: any = {};

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = (requestParameters.date as any).toISOString().substr(0,10);
        }

        if (requestParameters.language !== undefined) {
            queryParameters['language'] = requestParameters.language;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/player/{playerId}/{position}/export`.replace(`{${"playerId"}}`, encodeURIComponent(String(requestParameters.playerId))).replace(`{${"position"}}`, encodeURIComponent(String(requestParameters.position))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Exports the specified player\'s profile to a PDF document based on the provided ID, position, and date.
     * Export a player profile to PDF.
     */
    async exportPlayerProfileToPDF(playerId: number, position: string, date: Date, language: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.exportPlayerProfileToPDFRaw({ playerId: playerId, position: position, date: date, language: language }, initOverrides);
        return await response.value();
    }

    /**
     * Get the alleyesrating history for a certain player for a certain rating for the last 3 years
     */
    async getAlleyesRatingHistoryRaw(requestParameters: GetAlleyesRatingHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AlleyesRatingHistory>> {
        if (requestParameters.playerId === null || requestParameters.playerId === undefined) {
            throw new runtime.RequiredError('playerId','Required parameter requestParameters.playerId was null or undefined when calling getAlleyesRatingHistory.');
        }

        if (requestParameters.position === null || requestParameters.position === undefined) {
            throw new runtime.RequiredError('position','Required parameter requestParameters.position was null or undefined when calling getAlleyesRatingHistory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/player/{playerId}/{position}/alleyesratingHistory`.replace(`{${"playerId"}}`, encodeURIComponent(String(requestParameters.playerId))).replace(`{${"position"}}`, encodeURIComponent(String(requestParameters.position))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlleyesRatingHistoryFromJSON(jsonValue));
    }

    /**
     * Get the alleyesrating history for a certain player for a certain rating for the last 3 years
     */
    async getAlleyesRatingHistory(playerId: number, position: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AlleyesRatingHistory> {
        const response = await this.getAlleyesRatingHistoryRaw({ playerId: playerId, position: position }, initOverrides);
        return await response.value();
    }

    /**
     * Get data of available video clips corresponding to the player\'s kpi.
     * Get data of available video clips corresponding to the player\'s kpi.
     */
    async getAvailableKpiClipsRaw(requestParameters: GetAvailableKpiClipsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<KpiClip>>> {
        if (requestParameters.playerId === null || requestParameters.playerId === undefined) {
            throw new runtime.RequiredError('playerId','Required parameter requestParameters.playerId was null or undefined when calling getAvailableKpiClips.');
        }

        if (requestParameters.position === null || requestParameters.position === undefined) {
            throw new runtime.RequiredError('position','Required parameter requestParameters.position was null or undefined when calling getAvailableKpiClips.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/player/{playerId}/{position}/video/kpi/availableClips`.replace(`{${"playerId"}}`, encodeURIComponent(String(requestParameters.playerId))).replace(`{${"position"}}`, encodeURIComponent(String(requestParameters.position))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(KpiClipFromJSON));
    }

    /**
     * Get data of available video clips corresponding to the player\'s kpi.
     * Get data of available video clips corresponding to the player\'s kpi.
     */
    async getAvailableKpiClips(playerId: number, position: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<KpiClip>> {
        const response = await this.getAvailableKpiClipsRaw({ playerId: playerId, position: position }, initOverrides);
        return await response.value();
    }

    /**
     * Get comments.
     */
    async getCommentsRaw(requestParameters: GetCommentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Comment>>> {
        if (requestParameters.playerId === null || requestParameters.playerId === undefined) {
            throw new runtime.RequiredError('playerId','Required parameter requestParameters.playerId was null or undefined when calling getComments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/player/{playerId}/comments`.replace(`{${"playerId"}}`, encodeURIComponent(String(requestParameters.playerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CommentFromJSON));
    }

    /**
     * Get comments.
     */
    async getComments(playerId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Comment>> {
        const response = await this.getCommentsRaw({ playerId: playerId }, initOverrides);
        return await response.value();
    }

    /**
     * Get the data availability ofr a player
     */
    async getDataAvailabilityRaw(requestParameters: GetDataAvailabilityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Date>>> {
        if (requestParameters.playerId === null || requestParameters.playerId === undefined) {
            throw new runtime.RequiredError('playerId','Required parameter requestParameters.playerId was null or undefined when calling getDataAvailability.');
        }

        if (requestParameters.position === null || requestParameters.position === undefined) {
            throw new runtime.RequiredError('position','Required parameter requestParameters.position was null or undefined when calling getDataAvailability.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/player/dataAvailability/{playerId}/{position}`.replace(`{${"playerId"}}`, encodeURIComponent(String(requestParameters.playerId))).replace(`{${"position"}}`, encodeURIComponent(String(requestParameters.position))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get the data availability ofr a player
     */
    async getDataAvailability(playerId: number, position: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Date>> {
        const response = await this.getDataAvailabilityRaw({ playerId: playerId, position: position }, initOverrides);
        return await response.value();
    }

    /**
     * Get a (filtered and sorted) list of players.
     */
    async getFilteredPlayersRaw(requestParameters: GetFilteredPlayersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PlayerQueryResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/player`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PlayerQueryToJSON(requestParameters.playerQuery),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlayerQueryResultFromJSON(jsonValue));
    }

    /**
     * Get a (filtered and sorted) list of players.
     */
    async getFilteredPlayers(playerQuery?: PlayerQuery, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PlayerQueryResult> {
        const response = await this.getFilteredPlayersRaw({ playerQuery: playerQuery }, initOverrides);
        return await response.value();
    }

    /**
     * Get the no of games, the total gametime and the average game time that the rating for the given date is based on
     */
    async getGamesAndGametimeRaw(requestParameters: GetGamesAndGametimeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetGamesAndGametime200Response>> {
        if (requestParameters.playerId === null || requestParameters.playerId === undefined) {
            throw new runtime.RequiredError('playerId','Required parameter requestParameters.playerId was null or undefined when calling getGamesAndGametime.');
        }

        if (requestParameters.position === null || requestParameters.position === undefined) {
            throw new runtime.RequiredError('position','Required parameter requestParameters.position was null or undefined when calling getGamesAndGametime.');
        }

        const queryParameters: any = {};

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = (requestParameters.date as any).toISOString().substr(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/player/{playerId}/{position}/gamesAndGametime`.replace(`{${"playerId"}}`, encodeURIComponent(String(requestParameters.playerId))).replace(`{${"position"}}`, encodeURIComponent(String(requestParameters.position))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetGamesAndGametime200ResponseFromJSON(jsonValue));
    }

    /**
     * Get the no of games, the total gametime and the average game time that the rating for the given date is based on
     */
    async getGamesAndGametime(playerId: number, position: string, date?: Date, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetGamesAndGametime200Response> {
        const response = await this.getGamesAndGametimeRaw({ playerId: playerId, position: position, date: date }, initOverrides);
        return await response.value();
    }

    /**
     * Get the heatmap of a player
     */
    async getHeatmapRaw(requestParameters: GetHeatmapRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Heatmap>> {
        if (requestParameters.playerId === null || requestParameters.playerId === undefined) {
            throw new runtime.RequiredError('playerId','Required parameter requestParameters.playerId was null or undefined when calling getHeatmap.');
        }

        if (requestParameters.position === null || requestParameters.position === undefined) {
            throw new runtime.RequiredError('position','Required parameter requestParameters.position was null or undefined when calling getHeatmap.');
        }

        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling getHeatmap.');
        }

        const queryParameters: any = {};

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = (requestParameters.date as any).toISOString().substr(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/player/{playerId}/{position}/heatmap`.replace(`{${"playerId"}}`, encodeURIComponent(String(requestParameters.playerId))).replace(`{${"position"}}`, encodeURIComponent(String(requestParameters.position))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HeatmapFromJSON(jsonValue));
    }

    /**
     * Get the heatmap of a player
     */
    async getHeatmap(playerId: number, position: string, date: Date, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Heatmap> {
        const response = await this.getHeatmapRaw({ playerId: playerId, position: position, date: date }, initOverrides);
        return await response.value();
    }

    /**
     * Get the injury history of a player.
     */
    async getInjuryHistoryRaw(requestParameters: GetInjuryHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Injury>>> {
        if (requestParameters.playerIdTm === null || requestParameters.playerIdTm === undefined) {
            throw new runtime.RequiredError('playerIdTm','Required parameter requestParameters.playerIdTm was null or undefined when calling getInjuryHistory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/player/{playerIdTm}/injuryHistory`.replace(`{${"playerIdTm"}}`, encodeURIComponent(String(requestParameters.playerIdTm))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InjuryFromJSON));
    }

    /**
     * Get the injury history of a player.
     */
    async getInjuryHistory(playerIdTm: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Injury>> {
        const response = await this.getInjuryHistoryRaw({ playerIdTm: playerIdTm }, initOverrides);
        return await response.value();
    }

    /**
     * Supports partial content requests for streaming large .mp4 video files.
     * Stream kpi video clip.
     */
    async getKpiClipRaw(requestParameters: GetKpiClipRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.playerId === null || requestParameters.playerId === undefined) {
            throw new runtime.RequiredError('playerId','Required parameter requestParameters.playerId was null or undefined when calling getKpiClip.');
        }

        if (requestParameters.position === null || requestParameters.position === undefined) {
            throw new runtime.RequiredError('position','Required parameter requestParameters.position was null or undefined when calling getKpiClip.');
        }

        if (requestParameters.kpiLabel === null || requestParameters.kpiLabel === undefined) {
            throw new runtime.RequiredError('kpiLabel','Required parameter requestParameters.kpiLabel was null or undefined when calling getKpiClip.');
        }

        if (requestParameters.matchId === null || requestParameters.matchId === undefined) {
            throw new runtime.RequiredError('matchId','Required parameter requestParameters.matchId was null or undefined when calling getKpiClip.');
        }

        if (requestParameters.start === null || requestParameters.start === undefined) {
            throw new runtime.RequiredError('start','Required parameter requestParameters.start was null or undefined when calling getKpiClip.');
        }

        if (requestParameters.end === null || requestParameters.end === undefined) {
            throw new runtime.RequiredError('end','Required parameter requestParameters.end was null or undefined when calling getKpiClip.');
        }

        const queryParameters: any = {};

        if (requestParameters.matchId !== undefined) {
            queryParameters['matchId'] = requestParameters.matchId;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.end !== undefined) {
            queryParameters['end'] = requestParameters.end;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/player/{playerId}/{position}/video/kpi/{kpiLabel}`.replace(`{${"playerId"}}`, encodeURIComponent(String(requestParameters.playerId))).replace(`{${"position"}}`, encodeURIComponent(String(requestParameters.position))).replace(`{${"kpiLabel"}}`, encodeURIComponent(String(requestParameters.kpiLabel))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Supports partial content requests for streaming large .mp4 video files.
     * Stream kpi video clip.
     */
    async getKpiClip(playerId: number, position: string, kpiLabel: string, matchId: number, start: number, end: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getKpiClipRaw({ playerId: playerId, position: position, kpiLabel: kpiLabel, matchId: matchId, start: start, end: end }, initOverrides);
        return await response.value();
    }

    /**
     * Get the market history of a player.
     */
    async getMarketHistoryRaw(requestParameters: GetMarketHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MarketValueEntry>>> {
        if (requestParameters.playerIdTm === null || requestParameters.playerIdTm === undefined) {
            throw new runtime.RequiredError('playerIdTm','Required parameter requestParameters.playerIdTm was null or undefined when calling getMarketHistory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/player/{playerIdTm}/marketHistory`.replace(`{${"playerIdTm"}}`, encodeURIComponent(String(requestParameters.playerIdTm))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MarketValueEntryFromJSON));
    }

    /**
     * Get the market history of a player.
     */
    async getMarketHistory(playerIdTm: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MarketValueEntry>> {
        const response = await this.getMarketHistoryRaw({ playerIdTm: playerIdTm }, initOverrides);
        return await response.value();
    }

    /**
     * Get the base data of a player based on playerId.
     */
    async getPlayerRaw(requestParameters: GetPlayerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PlayerBaseData>> {
        if (requestParameters.playerId === null || requestParameters.playerId === undefined) {
            throw new runtime.RequiredError('playerId','Required parameter requestParameters.playerId was null or undefined when calling getPlayer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/player/{playerId}`.replace(`{${"playerId"}}`, encodeURIComponent(String(requestParameters.playerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlayerBaseDataFromJSON(jsonValue));
    }

    /**
     * Get the base data of a player based on playerId.
     */
    async getPlayer(playerId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PlayerBaseData> {
        const response = await this.getPlayerRaw({ playerId: playerId }, initOverrides);
        return await response.value();
    }

    /**
     * Supports partial content requests for streaming large .mov video files.
     * Stream player video from mounted drive on host machine.
     */
    async getPlayerVideoRaw(requestParameters: GetPlayerVideoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.playerId === null || requestParameters.playerId === undefined) {
            throw new runtime.RequiredError('playerId','Required parameter requestParameters.playerId was null or undefined when calling getPlayerVideo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/player/{playerId}/video`.replace(`{${"playerId"}}`, encodeURIComponent(String(requestParameters.playerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Supports partial content requests for streaming large .mov video files.
     * Stream player video from mounted drive on host machine.
     */
    async getPlayerVideo(playerId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getPlayerVideoRaw({ playerId: playerId }, initOverrides);
        return await response.value();
    }

    /**
     * Get a player.
     */
    async getPlayerWithPositionRaw(requestParameters: GetPlayerWithPositionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Player>> {
        if (requestParameters.playerId === null || requestParameters.playerId === undefined) {
            throw new runtime.RequiredError('playerId','Required parameter requestParameters.playerId was null or undefined when calling getPlayerWithPosition.');
        }

        if (requestParameters.position === null || requestParameters.position === undefined) {
            throw new runtime.RequiredError('position','Required parameter requestParameters.position was null or undefined when calling getPlayerWithPosition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/player/{playerId}/{position}`.replace(`{${"playerId"}}`, encodeURIComponent(String(requestParameters.playerId))).replace(`{${"position"}}`, encodeURIComponent(String(requestParameters.position))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlayerFromJSON(jsonValue));
    }

    /**
     * Get a player.
     */
    async getPlayerWithPosition(playerId: number, position: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Player> {
        const response = await this.getPlayerWithPositionRaw({ playerId: playerId, position: position }, initOverrides);
        return await response.value();
    }

    /**
     * Get a player with given position and date.
     */
    async getPlayerWithPositionAndDateRaw(requestParameters: GetPlayerWithPositionAndDateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Player>> {
        if (requestParameters.playerId === null || requestParameters.playerId === undefined) {
            throw new runtime.RequiredError('playerId','Required parameter requestParameters.playerId was null or undefined when calling getPlayerWithPositionAndDate.');
        }

        if (requestParameters.position === null || requestParameters.position === undefined) {
            throw new runtime.RequiredError('position','Required parameter requestParameters.position was null or undefined when calling getPlayerWithPositionAndDate.');
        }

        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling getPlayerWithPositionAndDate.');
        }

        const queryParameters: any = {};

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = (requestParameters.date as any).toISOString().substr(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/player/{playerId}/{position}/withDate`.replace(`{${"playerId"}}`, encodeURIComponent(String(requestParameters.playerId))).replace(`{${"position"}}`, encodeURIComponent(String(requestParameters.position))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlayerFromJSON(jsonValue));
    }

    /**
     * Get a player with given position and date.
     */
    async getPlayerWithPositionAndDate(playerId: number, position: string, date: Date, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Player> {
        const response = await this.getPlayerWithPositionAndDateRaw({ playerId: playerId, position: position, date: date }, initOverrides);
        return await response.value();
    }

    /**
     * Get the attributes of players
     */
    async getPlayersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Player>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/player`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PlayerFromJSON));
    }

    /**
     * Get the attributes of players
     */
    async getPlayers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Player>> {
        const response = await this.getPlayersRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get the base data players with that name.
     */
    async getPlayersByNameRaw(requestParameters: GetPlayersByNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Player>>> {
        if (requestParameters.playerName === null || requestParameters.playerName === undefined) {
            throw new runtime.RequiredError('playerName','Required parameter requestParameters.playerName was null or undefined when calling getPlayersByName.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/player/name/{playerName}`.replace(`{${"playerName"}}`, encodeURIComponent(String(requestParameters.playerName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PlayerFromJSON));
    }

    /**
     * Get the base data players with that name.
     */
    async getPlayersByName(playerName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Player>> {
        const response = await this.getPlayersByNameRaw({ playerName: playerName }, initOverrides);
        return await response.value();
    }

    /**
     * Get the attributes of a player based on playerId.
     */
    async getPolarChartsRaw(requestParameters: GetPolarChartsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PolarChartData>>> {
        if (requestParameters.playerId === null || requestParameters.playerId === undefined) {
            throw new runtime.RequiredError('playerId','Required parameter requestParameters.playerId was null or undefined when calling getPolarCharts.');
        }

        const queryParameters: any = {};

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = (requestParameters.date as any).toISOString().substr(0,10);
        }

        if (requestParameters.position !== undefined) {
            queryParameters['position'] = requestParameters.position;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/player/{playerId}/polarcharts`.replace(`{${"playerId"}}`, encodeURIComponent(String(requestParameters.playerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PolarChartDataFromJSON));
    }

    /**
     * Get the attributes of a player based on playerId.
     */
    async getPolarCharts(playerId: number, date?: Date, position?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PolarChartData>> {
        const response = await this.getPolarChartsRaw({ playerId: playerId, date: date, position: position }, initOverrides);
        return await response.value();
    }

    /**
     * Get the polar chart comparison data of a position.
     */
    async getPolarChartsComparisonDataRaw(requestParameters: GetPolarChartsComparisonDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PolarChartComparisonData>> {
        if (requestParameters.position === null || requestParameters.position === undefined) {
            throw new runtime.RequiredError('position','Required parameter requestParameters.position was null or undefined when calling getPolarChartsComparisonData.');
        }

        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling getPolarChartsComparisonData.');
        }

        const queryParameters: any = {};

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = (requestParameters.date as any).toISOString().substr(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/player/{position}/polarchartscomparisondata`.replace(`{${"position"}}`, encodeURIComponent(String(requestParameters.position))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PolarChartComparisonDataFromJSON(jsonValue));
    }

    /**
     * Get the polar chart comparison data of a position.
     */
    async getPolarChartsComparisonData(position: string, date: Date, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PolarChartComparisonData> {
        const response = await this.getPolarChartsComparisonDataRaw({ position: position, date: date }, initOverrides);
        return await response.value();
    }

    /**
     * Get player profile picture from mounted drive on host machine.
     * Get player profile picture from mounted drive on host machine.
     */
    async getProfilePictureRaw(requestParameters: GetProfilePictureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.playerId === null || requestParameters.playerId === undefined) {
            throw new runtime.RequiredError('playerId','Required parameter requestParameters.playerId was null or undefined when calling getProfilePicture.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/player/{playerId}/profilePicture`.replace(`{${"playerId"}}`, encodeURIComponent(String(requestParameters.playerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Get player profile picture from mounted drive on host machine.
     * Get player profile picture from mounted drive on host machine.
     */
    async getProfilePicture(playerId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getProfilePictureRaw({ playerId: playerId }, initOverrides);
        return await response.value();
    }

    /**
     * Get the rating history for a certain player for a certain rating for the last 3 years
     */
    async getRatingHistoryRaw(requestParameters: GetRatingHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Rating>>> {
        if (requestParameters.playerId === null || requestParameters.playerId === undefined) {
            throw new runtime.RequiredError('playerId','Required parameter requestParameters.playerId was null or undefined when calling getRatingHistory.');
        }

        if (requestParameters.position === null || requestParameters.position === undefined) {
            throw new runtime.RequiredError('position','Required parameter requestParameters.position was null or undefined when calling getRatingHistory.');
        }

        if (requestParameters.rating === null || requestParameters.rating === undefined) {
            throw new runtime.RequiredError('rating','Required parameter requestParameters.rating was null or undefined when calling getRatingHistory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/player/{playerId}/{position}/{rating}`.replace(`{${"playerId"}}`, encodeURIComponent(String(requestParameters.playerId))).replace(`{${"position"}}`, encodeURIComponent(String(requestParameters.position))).replace(`{${"rating"}}`, encodeURIComponent(String(requestParameters.rating))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RatingFromJSON));
    }

    /**
     * Get the rating history for a certain player for a certain rating for the last 3 years
     */
    async getRatingHistory(playerId: number, position: string, rating: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Rating>> {
        const response = await this.getRatingHistoryRaw({ playerId: playerId, position: position, rating: rating }, initOverrides);
        return await response.value();
    }

    /**
     * Get the scouting reports of a player.
     */
    async getScoutingReportsRaw(requestParameters: GetScoutingReportsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ScoutingReport>>> {
        if (requestParameters.playerId === null || requestParameters.playerId === undefined) {
            throw new runtime.RequiredError('playerId','Required parameter requestParameters.playerId was null or undefined when calling getScoutingReports.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/player/{playerId}/scoutingreports`.replace(`{${"playerId"}}`, encodeURIComponent(String(requestParameters.playerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ScoutingReportFromJSON));
    }

    /**
     * Get the scouting reports of a player.
     */
    async getScoutingReports(playerId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ScoutingReport>> {
        const response = await this.getScoutingReportsRaw({ playerId: playerId }, initOverrides);
        return await response.value();
    }

    /**
     * Get the shots of a player
     */
    async getShotsRaw(requestParameters: GetShotsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Shot>>> {
        if (requestParameters.playerId === null || requestParameters.playerId === undefined) {
            throw new runtime.RequiredError('playerId','Required parameter requestParameters.playerId was null or undefined when calling getShots.');
        }

        if (requestParameters.position === null || requestParameters.position === undefined) {
            throw new runtime.RequiredError('position','Required parameter requestParameters.position was null or undefined when calling getShots.');
        }

        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling getShots.');
        }

        const queryParameters: any = {};

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = (requestParameters.date as any).toISOString().substr(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/player/{playerId}/{position}/shots`.replace(`{${"playerId"}}`, encodeURIComponent(String(requestParameters.playerId))).replace(`{${"position"}}`, encodeURIComponent(String(requestParameters.position))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ShotFromJSON));
    }

    /**
     * Get the shots of a player
     */
    async getShots(playerId: number, position: string, date: Date, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Shot>> {
        const response = await this.getShotsRaw({ playerId: playerId, position: position, date: date }, initOverrides);
        return await response.value();
    }

    /**
     * Get a (filtered and sorted) list of similar players.
     */
    async getSimilarPlayersRaw(requestParameters: GetSimilarPlayersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SimilarPlayer>>> {
        if (requestParameters.playerId === null || requestParameters.playerId === undefined) {
            throw new runtime.RequiredError('playerId','Required parameter requestParameters.playerId was null or undefined when calling getSimilarPlayers.');
        }

        if (requestParameters.position === null || requestParameters.position === undefined) {
            throw new runtime.RequiredError('position','Required parameter requestParameters.position was null or undefined when calling getSimilarPlayers.');
        }

        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling getSimilarPlayers.');
        }

        const queryParameters: any = {};

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = (requestParameters.date as any).toISOString().substr(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/player/{playerId}/{position}/similar`.replace(`{${"playerId"}}`, encodeURIComponent(String(requestParameters.playerId))).replace(`{${"position"}}`, encodeURIComponent(String(requestParameters.position))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PlayerQueryToJSON(requestParameters.playerQuery),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SimilarPlayerFromJSON));
    }

    /**
     * Get a (filtered and sorted) list of similar players.
     */
    async getSimilarPlayers(playerId: number, position: string, date: Date, playerQuery?: PlayerQuery, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SimilarPlayer>> {
        const response = await this.getSimilarPlayersRaw({ playerId: playerId, position: position, date: date, playerQuery: playerQuery }, initOverrides);
        return await response.value();
    }

    /**
     * Get team picture from mounted drive on host machine.
     * Get team picture from mounted drive on host machine.
     */
    async getTeamPictureRaw(requestParameters: GetTeamPictureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling getTeamPicture.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/player/teamPicture/{teamId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Get team picture from mounted drive on host machine.
     * Get team picture from mounted drive on host machine.
     */
    async getTeamPicture(teamId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getTeamPictureRaw({ teamId: teamId }, initOverrides);
        return await response.value();
    }

    /**
     * Get the transfer history of a player.
     */
    async getTransferHistoryRaw(requestParameters: GetTransferHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Transfer>>> {
        if (requestParameters.playerIdTm === null || requestParameters.playerIdTm === undefined) {
            throw new runtime.RequiredError('playerIdTm','Required parameter requestParameters.playerIdTm was null or undefined when calling getTransferHistory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/player/{playerIdTm}/transferHistory`.replace(`{${"playerIdTm"}}`, encodeURIComponent(String(requestParameters.playerIdTm))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TransferFromJSON));
    }

    /**
     * Get the transfer history of a player.
     */
    async getTransferHistory(playerIdTm: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Transfer>> {
        const response = await this.getTransferHistoryRaw({ playerIdTm: playerIdTm }, initOverrides);
        return await response.value();
    }

    /**
     * Check if player has comments
     */
    async hasCommentsRaw(requestParameters: HasCommentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.playerId === null || requestParameters.playerId === undefined) {
            throw new runtime.RequiredError('playerId','Required parameter requestParameters.playerId was null or undefined when calling hasComments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/player/hasComments/{playerId}`.replace(`{${"playerId"}}`, encodeURIComponent(String(requestParameters.playerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Check if player has comments
     */
    async hasComments(playerId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.hasCommentsRaw({ playerId: playerId }, initOverrides);
        return await response.value();
    }

    /**
     * Check if player is in any shadowteams
     */
    async isInShadowteamsRaw(requestParameters: IsInShadowteamsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.playerId === null || requestParameters.playerId === undefined) {
            throw new runtime.RequiredError('playerId','Required parameter requestParameters.playerId was null or undefined when calling isInShadowteams.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/player/isInShadowteams/{playerId}`.replace(`{${"playerId"}}`, encodeURIComponent(String(requestParameters.playerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Check if player is in any shadowteams
     */
    async isInShadowteams(playerId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.isInShadowteamsRaw({ playerId: playerId }, initOverrides);
        return await response.value();
    }

    /**
     * Check if player is in a watchlist
     */
    async isInWatchlistRaw(requestParameters: IsInWatchlistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.playerId === null || requestParameters.playerId === undefined) {
            throw new runtime.RequiredError('playerId','Required parameter requestParameters.playerId was null or undefined when calling isInWatchlist.');
        }

        if (requestParameters.position === null || requestParameters.position === undefined) {
            throw new runtime.RequiredError('position','Required parameter requestParameters.position was null or undefined when calling isInWatchlist.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/player/isInWatchlist/{playerId}/{position}`.replace(`{${"playerId"}}`, encodeURIComponent(String(requestParameters.playerId))).replace(`{${"position"}}`, encodeURIComponent(String(requestParameters.position))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Check if player is in a watchlist
     */
    async isInWatchlist(playerId: number, position: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.isInWatchlistRaw({ playerId: playerId, position: position }, initOverrides);
        return await response.value();
    }

    /**
     * Check if player is in a watchlist or shadowteam
     */
    async isInWatchlistOrShadowteamRaw(requestParameters: IsInWatchlistOrShadowteamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.playerId === null || requestParameters.playerId === undefined) {
            throw new runtime.RequiredError('playerId','Required parameter requestParameters.playerId was null or undefined when calling isInWatchlistOrShadowteam.');
        }

        if (requestParameters.position === null || requestParameters.position === undefined) {
            throw new runtime.RequiredError('position','Required parameter requestParameters.position was null or undefined when calling isInWatchlistOrShadowteam.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/player/isInWatchlistOrShadowteam/{playerId}/{position}`.replace(`{${"playerId"}}`, encodeURIComponent(String(requestParameters.playerId))).replace(`{${"position"}}`, encodeURIComponent(String(requestParameters.position))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Check if player is in a watchlist or shadowteam
     */
    async isInWatchlistOrShadowteam(playerId: number, position: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.isInWatchlistOrShadowteamRaw({ playerId: playerId, position: position }, initOverrides);
        return await response.value();
    }

    /**
     * Check if player is not in shadowteams
     */
    async isNotInShadowteamsRaw(requestParameters: IsNotInShadowteamsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ShadowteamBaseInfo>>> {
        if (requestParameters.playerId === null || requestParameters.playerId === undefined) {
            throw new runtime.RequiredError('playerId','Required parameter requestParameters.playerId was null or undefined when calling isNotInShadowteams.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/player/isNotInShadowteams/{playerId}`.replace(`{${"playerId"}}`, encodeURIComponent(String(requestParameters.playerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ShadowteamBaseInfoFromJSON));
    }

    /**
     * Check if player is not in shadowteams
     */
    async isNotInShadowteams(playerId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ShadowteamBaseInfo>> {
        const response = await this.isNotInShadowteamsRaw({ playerId: playerId }, initOverrides);
        return await response.value();
    }

}
