import { Center, Spinner } from "@chakra-ui/react";
import { useKeycloak } from "@react-keycloak/web";
import { ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";

interface Props {
  children: ReactNode | ReactNode[];
}

const AuthenticationWrapper = ({ children }: Props) => {
  const { keycloak, initialized } = useKeycloak();
  const location = useLocation();

  useEffect(() => {
    if (
      initialized &&
      !keycloak?.authenticated &&
      location.pathname !== "/login"
    ) {
      keycloak.login().catch(console.error);
    }
  }, [keycloak, initialized, location.pathname]);

  if (!initialized) {
    return (
      <Center height="100vh" width="100%">
        <Spinner
          thickness="4px"
          speed="0.8"
          emptyColor="gray.200"
          color="blue.500"
          height="200px"
          width="200px"
        />
      </Center>
    );
  }

  if (!keycloak?.authenticated) {
    return null;
  }

  return <>{children}</>;
};

export default AuthenticationWrapper;
