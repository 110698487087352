import { Box, HStack, Image, Stack, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import fallbackPicture from "../../../assets/placeholder-picture.png";
import { textColors } from "../../../colorStyles";
import { useProfilePicture } from "../../../hooks/usePlayer";
import CountryFlag from "../../CountryFlag";

interface Props {
  id: number;
  idTm: number | undefined;
  firstName: string;
  surName: string;
  nationality: string | undefined;
  club: string | undefined;
  league: string | undefined;
  positionsGruppe: string;
  borderColor?: string;
  size?: "small" | "medium" | "large";
  onClick?: () => void;
}

const StammdatenColumnView = ({
  id,
  idTm,
  firstName,
  surName,
  nationality,
  club,
  league,
  positionsGruppe,
  borderColor,
  size,
  onClick,
}: Props) => {
  const navigate = useNavigate();

  const { data: profilePic } = useProfilePicture(id);

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      navigate(`/player/${id}?position=${positionsGruppe}&idTm=${idTm}`);
    }
  };

  let lineHeight, boxSize: string;
  switch (size) {
    case "large":
      lineHeight = "normal";
      boxSize = "55px";
      break;
    case "medium":
      lineHeight = "shorter";
      boxSize = "45px";
      break;
    default:
      lineHeight = "shorter";
      boxSize = "40px";
  }

  return (
    <Box
      width="100%"
      p={2}
      onClick={handleClick}
      borderLeft={borderColor && "5px solid " + borderColor}
    >
      <HStack
        justifyContent="space-between"
        _hover={{
          cursor: "pointer",
        }}
      >
        <HStack>
          <Image
            src={profilePic ?? fallbackPicture}
            borderRadius="lg"
            boxSize={boxSize}
            align="center"
          />
          <Stack spacing="0" alignItems="flex-start">
            <HStack
              alignContent="flex-start"
              justifyContent="flex-start"
              display="flex"
            >
              <Text fontSize={size === "large" ? "md" : "sm"} textAlign="left" color={textColors.primary}>
                {firstName + " " + surName}
              </Text>

              {nationality && (
                <CountryFlag nationality={nationality} size={18} />
              )}
            </HStack>

            {size != "small" && <>
              <Text
                color={textColors.secondary}
                fontSize={size === "large" ? "sm" : "xs"}
                lineHeight={lineHeight}
                textAlign="left"
              >
                {club}
              </Text>
              <Text
                color={textColors.secondary}
                fontSize={size === "large" ? "sm" : "xs"}
                lineHeight={lineHeight}
                textAlign="left"
              >
                {league?.replace("_", " ")}
              </Text>
            </>}
          </Stack>
        </HStack>
      </HStack>
    </Box>
  );
};

export default StammdatenColumnView;
