import {
  Badge,
  Box,
  Button,
  Center,
  Checkbox,
  Divider,
  Grid,
  GridItem,
  HStack,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { TwitterPicker } from "react-color";
import { AiFillCloseCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import fallbackPicture from "../../assets/placeholder-picture.png";
import { colorsPrimary, colorsSecondary, textColors } from "../../colorStyles";
import { Player, ShadowteamEntry, WatchlistEntry } from "../../generated";
import { useProfilePicture, useTeamPicture } from "../../hooks/usePlayer";
import { useComparisonStore } from "../../store/store";
import { getRatingColor } from "../../util/utilFunctions";
import CountryFlag from "../CountryFlag";
import Comments from "../profile/comments/Comments";
import { LanguageContext } from "../../store/languageContext";
import { LanguageKeys } from "../../language/LanguageKeys";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  player: ShadowteamEntry | WatchlistEntry | Player | undefined;
  handleColorChange?: (color: string | undefined) => void;
  deletePlayerLocal: (id: number, positionsGruppe?: string) => void;
}

const twitterStyle = {
  default: {
    card: {
      background: colorsSecondary.cardBackground,
      boxShadow: "none",
    },
    input: {
      display: "none",
    },
    hash: {
      display: "none",
    },

  },
};

const QuickViewModal = ({
  isOpen,
  onClose,
  player,
  handleColorChange,
  deletePlayerLocal,
}: Props) => {
  const { guiText } = useContext(LanguageContext);
  const playerr = player && "player" in player ? player.player : player;
  const { data: profilePic } = useProfilePicture(Number(playerr?.id) || -1);

  const [color, setColor] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (player && "color" in player) setColor(player.color);
  }, [player]);

  const colorChange = (color: string | undefined) => {
    handleColorChange != undefined && handleColorChange(color);
    setColor(color);
  };

  const {
    data: teamPic,
    error: teamError,
  } = useTeamPicture(playerr?.teamId ?? -1);

  const { addPlayer, deletePlayer, isInComparison } = useComparisonStore();

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      addPlayer(playerr!);
    } else {
      deletePlayer(playerr?.id!, playerr?.positionsGruppe!);
    }
  };

  const navigate = useNavigate();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay overflow="hidden" />
      <ModalContent
        minW="fit-content"
        overflow="hidden"
        containerProps={{ overflow: "hidden" }}
        data-cy={"quickViewModal"}
        backgroundColor={colorsSecondary.cardBackground}
      >
        <ModalHeader></ModalHeader>
        <ModalCloseButton color={textColors.primary} />
        <ModalBody
          display="flex"
          flex={1}
          overflow="hidden"
          minH="70vh"
          maxH="70vh"
          minW="55vw"
          backgroundColor={colorsSecondary.cardBackground}
        >
          <Grid
            flex={1}
            overflowY="auto"
            sx={{
              "::-webkit-scrollbar": {
                display: "none",
              },
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
            templateAreas={`"left divider right"
                 `}
            gridTemplateRows={"1fr"}
            gridTemplateColumns={"auto auto 1fr"}
          >
            {/* Left box */}
            <GridItem display="flex" flexDir="column" pr={2} area="left">
              <Box display="flex" flexDir="column" flex={0} px={2}>
                <Box
                  position="relative"
                  pl={3}
                  borderLeft={color && "5px solid " + color}
                  data-cy={"profilePicWithBorder"}
                >
                  <Center>
                    <Image
                      src={profilePic ?? fallbackPicture}
                      borderRadius="lg"
                      boxSize="160px"
                      align="center"
                    />
                  </Center>
                  <VStack position="absolute" top="0px" left="8px" spacing={3}>
                    {playerr?.nationality && (
                      <CountryFlag
                        nationality={playerr?.nationality}
                        size={30}
                      ></CountryFlag>
                    )}
                    {!teamError && teamPic && (
                      <Image src={teamPic} boxSize={8}></Image>
                    )}
                  </VStack>
                </Box>
                <Box
                  h="28px"
                  mt={3}
                  mb={1}
                  display="flex"
                  flexDir="column"
                  w="100%"
                  flex={1}
                  color={textColors.primary}
                >
                  <Text fontWeight="bold" fontSize={17} lineHeight="short">
                    {playerr?.firstName + " " + playerr?.surname}
                  </Text>
                  <Text lineHeight="short">{playerr?.positionsGruppe}</Text>
                  <Text color="gray.400" lineHeight="short">
                    {playerr?.league?.replace(/_/g, " ") +
                      " | " +
                      playerr?.team}
                  </Text>
                  <Text color="gray.400" lineHeight="short" pb={3}>
                    {playerr?.age + " y.o. | " + playerr?.height}
                  </Text>

                  <Checkbox
                    size="lg"
                    color={colorsSecondary.accent}
                    _checked={{
                      "& .chakra-checkbox__control": {
                        background: colorsSecondary.accent,
                      },
                    }}
                    sx={{
                      "[data-checked]": {
                        borderColor: colorsSecondary.accent,
                        fill: colorsSecondary.accent,
                      },
                      "[data-checked]:hover": {
                        borderColor: colorsSecondary.accent,
                        fill: colorsSecondary.accent,
                      },
                    }}
                    onChange={handleCheckboxChange}
                    isChecked={
                      playerr?.positionsGruppe
                        ? isInComparison(playerr?.id, playerr?.positionsGruppe)
                        : false
                    }
                    isDisabled={!playerr?.positionsGruppe}
                    pb={3}
                  >
                    <Text size="sm" color={textColors.primary}>
                      {guiText[LanguageKeys.SearchBarVergleichen]}
                    </Text>
                  </Checkbox>
                </Box>
              </Box>
              {player && "color" in player && (
                <Divider border="1px solid #CBD5E0" />
              )}
              <Box display="flex" flex={1} flexDir="column" pt={3}>
                {player && (
                  <>
                    <HStack justifyContent="space-between" pb={2} w="100%">
                      <Text fontWeight="normal" color={textColors.primary}>{guiText[LanguageKeys.SchattenmannschaftenMarkierungWahlen]}</Text>
                      <Icon
                        as={AiFillCloseCircle}
                        boxSize={4}
                        fill="gray.500"
                        onClick={() => colorChange(undefined)}
                        cursor="pointer"
                      />
                    </HStack>
                    <div data-cy={"twitterPicker"}>
                      <TwitterPicker
                        onChange={(color) => colorChange(color.hex)}
                        triangle="hide"
                        colors={[
                          "#FF6900",
                          "#FCB900",
                          "#7BDCB5",
                          "#00D084",
                          "#006400",
                          "#8ED1FC",
                          "#0693E3",
                          "#004B9C",
                          "#b21e35",
                          "#ff0000",
                          "#F78DA7",
                          "#9900EF",
                        ]}
                        width="240px"
                        styles={twitterStyle}
                      />
                    </div>
                  </>
                )}

                <Spacer></Spacer>
                <Box display="flex" flexDir="column">
                  <Button
                    backgroundColor={colorsSecondary.accent}
                    textColor={colorsSecondary.background}
                    boxShadow="sm"
                    onClick={() =>
                      navigate(
                        `/player/${playerr?.id}?position=${playerr?.positionsGruppe}&idTm=${playerr?.idTm}`
                      )
                    }
                    mt={4}
                  >
                    {guiText[LanguageKeys.SchattenmannschaftenZumSpielerprofil]}
                  </Button>
                  {player && "player" in player && (
                    <Button
                      onClick={() => {
                        deletePlayerLocal(
                          playerr?.id!,
                          playerr?.positionsGruppe
                        );
                        onClose();
                      }}
                      variant="outline"
                      color={colorsSecondary.accent}
                      mt={2}
                    >
                      {guiText[LanguageKeys.SchattenmannschaftenSpielerEntfernen]}
                    </Button>
                  )}
                </Box>
              </Box>
            </GridItem>
            <GridItem area="divider" pl={2}>
              <Divider orientation="vertical" border="1px solid #CBD5E0" />
            </GridItem>

            {/* Right box */}

            <GridItem
              area="right"
              pl={3}
              display="flex"
              flexDir="column"
              overflow="hidden"
            >
              <Box display="flex" flex={1} flexDir="column" overflow="hidden">
                <HStack
                  textAlign="center"
                  pt={2}
                  display="flex"
                  spacing={5}
                  pb={3}
                  flex={0}
                  justifyContent="space-between"
                  color={textColors.primary}
                >
                  <Box >
                    <Text pb={2} fontWeight="bold">
                      All Eyes
                    </Text>
                    <HStack>
                      <VStack>
                        <Text fontSize="14">{guiText[LanguageKeys.Aktuell]}</Text>
                        <Badge
                          borderRadius="50%"
                          width="60px"
                          height="60px"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          background={getRatingColor(playerr?.alleyes)}
                        >
                          <Text color="white" fontSize="20" lineHeight="1">
                            {(playerr?.alleyes &&
                              Math.round(playerr?.alleyes)) ||
                              "?"}
                          </Text>
                        </Badge>
                      </VStack>
                    </HStack>
                  </Box>
                  <Box>
                    <Text pb={2} fontWeight="bold">
                      {guiText[LanguageKeys.Gesamtbewertung]}
                    </Text>
                    <HStack>
                      <VStack>
                        <Text fontSize="14">{guiText[LanguageKeys.Aktuell]}</Text>
                        <Badge
                          borderRadius="50%"
                          width="60px"
                          height="60px"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          background={getRatingColor(
                            playerr?.subjectiveOverall,
                            0,
                            10
                          )}
                        >
                          <Text color="white" fontSize="20" lineHeight="1">
                            {playerr?.subjectiveOverall?.toFixed(1) ?? "?"}
                          </Text>
                        </Badge>
                      </VStack>

                      <VStack>
                        <Text fontSize="14">{guiText[LanguageKeys.Potential]}</Text>
                        <Badge
                          borderRadius="50%"
                          width="60px"
                          height="60px"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          background={getRatingColor(
                            playerr?.subjectivePotential,
                            0,
                            10
                          )}
                        >
                          <Text color="white" fontSize="20" lineHeight="1">
                            {playerr?.subjectivePotential?.toFixed(1) ?? "?"}
                          </Text>
                        </Badge>
                      </VStack>
                    </HStack>
                  </Box>

                  <Box>
                    <Text pb={2} fontWeight="bold">
                      {guiText[LanguageKeys.Spielbewertung]}
                    </Text>
                    <HStack>
                      <VStack>
                        <Text fontSize="14">{guiText[LanguageKeys.Aktuell]}</Text>
                        <Badge
                          borderRadius="50%"
                          width="60px"
                          height="60px"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          background={getRatingColor(
                            playerr?.subjectiveGameRatingsOverall,
                            0,
                            10
                          )}
                        >
                          <Text color="white" fontSize="20" lineHeight="1">
                            {playerr?.subjectiveGameRatingsOverall?.toFixed(
                              1
                            ) ?? "?"}
                          </Text>
                        </Badge>
                      </VStack>

                      <VStack>
                        <Text fontSize="14">{guiText[LanguageKeys.Potential]}</Text>
                        <Badge
                          borderRadius="50%"
                          width="60px"
                          height="60px"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          background={getRatingColor(
                            playerr?.subjectiveGameRatingsPotential,
                            0,
                            10
                          )}
                        >
                          <Text color="white" fontSize="20" lineHeight="1">
                            {playerr?.subjectiveGameRatingsPotential?.toFixed(
                              1
                            ) ?? "?"}
                          </Text>
                        </Badge>
                      </VStack>
                    </HStack>
                  </Box>
                </HStack>
                <Divider border="1px solid #CBD5E0" />
                <Text pt={2} fontWeight="bold" color={textColors.primary}>
                  {guiText[LanguageKeys.SpielerprofilKommentare]}
                </Text>
                <Box display="flex" pt={1} flex={1} overflow="hidden">
                  <Comments useCase="quickView" id={playerr?.id!}></Comments>
                </Box>
              </Box>
            </GridItem>
          </Grid>
        </ModalBody>
      </ModalContent>
    </Modal >
  );
};

export default QuickViewModal;
