import { Box, HStack } from "@chakra-ui/react";
import { Player } from "../../generated";
import AlleyesScores from "../profile/AlleyesScores";
import { colors } from "@mui/material";
import { colorsSecondary } from "../../colorStyles";

interface Props {
  players: (Player | undefined)[];
  dates: Date[];
}
const AlleyesScoresRow = ({ players, dates }: Props) => {
  return (
    <HStack spacing={5} pb={5} flex={1}>
      {players.map((player, idx) => (
        player !== undefined ? (
          <Box
            w={"100%"}
            bg={colorsSecondary.cardBackground}
            borderRadius={5}
            p={3}
            boxShadow={"md"}
            key={idx}
          >
            <AlleyesScores
              playerId={player.id}
              position={player.positionsGruppe}
              date={dates[idx]}
              useCase={"comparisonColumn" + (idx + 1)}
            />
          </Box>
        ) : (
          <Box
            w={"100%"}
            bg={"transparent"}
            borderRadius={5}
            p={3}
            boxShadow={"transparent"}
            key={idx}
          />
        )
      ))}
    </HStack>
  );
};

export default AlleyesScoresRow;
