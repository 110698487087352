import * as d3 from "d3";
import React, { useContext, useEffect, useRef } from "react";
// import fieldImage from "../../../assets/Soccer_Field_Transparent_Half.png";
import fieldImage from "../../../assets/Spielfeld_halb.svg";

import { Box, Center, Heading, Text } from "@chakra-ui/react";
import { useShotHistory } from "../../../hooks/usePlayer";
import { LanguageContext } from "../../../store/languageContext";
import { LanguageKeys } from "../../../language/LanguageKeys";
import { textColors } from "../../../colorStyles";

const GoalsMapLegend = () => {
  const { guiText } = useContext(LanguageContext);

  const containerId = `container-legend`;

  const width = 1000;
  const height = 70;

  const legendData = [
    { color: textColors.primary, label: guiText[LanguageKeys.SpielerprofilFuß] + " [" + guiText[LanguageKeys.SpielerprofilVersuch] + "]", shape: "circle" },
    { color: "url(#stripePattern-6)", label: guiText[LanguageKeys.SpielerprofilFuß] + " [" + guiText[LanguageKeys.SpielerprofilTor] + "]", shape: "circle" },
    { color: textColors.primary, label: guiText[LanguageKeys.SpielerprofilKopf] + " [" + guiText[LanguageKeys.SpielerprofilVersuch] + "]", shape: "rect" },
    { color: "url(#stripePattern-6)", label: guiText[LanguageKeys.SpielerprofilKopf] + " [" + guiText[LanguageKeys.SpielerprofilTor] + "]", shape: "rect" },
  ];

  const colors = [
    "rgb(0,128,0)",
    "rgb(0,255,0)",
    "rgb(254,207,0)",
    "rgb(231,81,19)",
    "rgb(227,6,19)",
    "rgb(126,0,21)",
    "black",
  ];

  useEffect(() => {
    const container = d3.select(`div#${containerId}`);
    container.selectAll("*").remove();
    const svg = container

      .append("svg")
      .attr("preserveAspectRatio", "xMidYMid meet")
      .attr("viewBox", `0 0 ${width} ${height}`)
      .classed("svg-content", true);
    svg.selectAll("*").remove();

    const legendRadius = 7;
    const legendSpacing = 60;
    const legendX = 0;
    const legendY = height - 50;
    const legendItemWidth = [110, 80, 115, 80];

    let xOffset = 0;
    const legend = svg
      .selectAll(".legend")
      .data(legendData)
      .enter()
      .append("g")
      .attr("class", "legend")
      .attr("transform", (d, i) => {
        xOffset = i == 0 ? 0 : xOffset + legendItemWidth[i - 1] + legendSpacing;
        return `translate(${xOffset}, 0)`;
      });

    // Draw legend circles
    legend.each(function (d) {
      if (d.shape === "circle") {
        d3.select(this)
          .append("circle")
          .attr("cx", legendX + legendRadius)
          .attr("cy", legendY)
          .attr("r", legendRadius)
          .style("fill", d.color);
      } else if (d.shape === "rect") {
        d3.select(this)
          .append("rect")
          .attr("x", legendX)
          .attr("y", legendY - legendRadius)
          .attr("width", 2 * legendRadius)
          .attr("height", 2 * legendRadius)
          .style("fill", d.color);
      }
    });

    legend
      .append("text")
      .attr("x", legendX + 2 * legendRadius + 5)
      .attr("y", legendY + legendRadius / 2 - 3)
      .attr("dy", ".35em")
      .style("text-anchor", "start")
      .style("fill", textColors.primary) // Add this line to set text color
      .text((d) => d.label);

    //////////////////////////////////////////////////

    const rectWidth = 20;
    const rectHeight = 20;

    const colorBarY = legendY - 10;
    const legendItemWidthSum = legendItemWidth.reduce((x, y) => {
      return x + y;
    }, 0);
    const colorBarX =
      legendItemWidthSum + legendSpacing * (legendItemWidth.length + 1);

    colors.slice(0, -1).forEach((color, i) => {
      svg
        .append("rect")
        .attr("x", colorBarX + i * rectWidth)
        .attr("y", colorBarY)
        .attr("width", rectWidth)
        .attr("height", rectHeight)
        .style("fill", color);
    });

    // Left Label ("lowxG")
    svg
      .append("text")
      .attr("x", colorBarX - 10)
      .attr("y", colorBarY + rectHeight / 2)
      .attr("dy", ".35em")
      .attr("text-anchor", "end")
      .style("fill", textColors.primary) // Add this line to set text color
      .text("low xG");

    // Right Label ("highxG")
    svg
      .append("text")
      .attr("x", colorBarX + (colors.length - 1) * rectWidth + 10)
      .attr("y", colorBarY + rectHeight / 2)
      .attr("dy", ".35em")
      .attr("text-anchor", "start")
      .style("fill", textColors.primary) // Add this line to set text color
      .text("high xG");
  }, []);

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDir="column"
      height="70px"
      w="100%"
      sx={{
        ".svg-container": {
          display: "flex", // Changed to block to fill the parent
          flexDir: "column",
          width: "100%",
          height: "auto", // Height auto for intrinsic aspect ratio
          overflow: "hidden",
          alignItems: "center",
          justifyContent: "center",
        },
        ".svg-content": {
          display: "flex", // Changed to block to fill the parent
          flexDir: "column",
          width: "100%", // Set SVG width to 100%
          height: "100%", // Set SVG height to 100%
          justifyContent: "center",
          alignItems: "center",
        },
      }}
    >
      <div id={containerId} className="svg-container"></div>
    </Box>
  );
};

export default GoalsMapLegend;
