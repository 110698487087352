import { SimpleGrid, Box, Button, HStack, Icon, Text } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { colorsPrimary, colorsSecondary, textColors } from "../../../colorStyles";
import { FilterSpecification } from "../../../generated";
import { FilterValueStoreCore } from "../../../store/store";
import { filterOpacity } from "../searchStyles";
import { LanguageContext } from "../../../store/languageContext";
import { LocaleKeys } from "../../../language/LocaleKeys";

interface Props {
  filter: FilterSpecification;
  filterValueFunc: FilterValueStoreCore;
}
interface Specification {
  columns: number;
  options: (string | number)[];
  langDepLabels: { [key: string]: (string | number)[] };
  label: (string | number)[];
  allowMultiple: boolean;
  min: number;
  max: number;
  hideNumbers?: boolean;
  unit: string;
  default?: string;
  step: number;
  startLabel?: string;
  endLabel?: string;
}

export function getFilterSpecification(filter: any, language: string) {
  const filterSpecification: Specification = JSON.parse(filter.specification);

  if ("langDepLabels" in filterSpecification) {
    filterSpecification.label = language === LocaleKeys.ES
      ? filterSpecification.langDepLabels.Spanish
      : language === LocaleKeys.DE
        ? filterSpecification.langDepLabels.German
        : filterSpecification.langDepLabels.English;
  }

  return filterSpecification;
}

const ButtonGroupFilter = ({ filter, filterValueFunc }: Props) => {
  const { language } = useContext(LanguageContext);
  const filterSpecification: Specification = getFilterSpecification(filter, language);
  const [filterValue, setFilterValue] = useState<(string | number)[]>([]);

  useEffect(() => {
    const filterString = filterValueFunc.filterValues[filter.specId - 1]?.value;
    if (filterString !== null && filterString !== undefined) {
      const parsedFilter = JSON.parse(filterString);

      setFilterValue(parsedFilter.values);
    } else {
      setFilterValue([]);
    }
  }, [filterValueFunc.filterValues[filter.specId - 1]]);

  const handleButtonClick = (label: string | number) => {
    const button =
      filterSpecification.options[filterSpecification.label.indexOf(label)];

    let newSelectedButtons = [...filterValue];
    if (filterSpecification.allowMultiple) {
      const index = newSelectedButtons.indexOf(button);
      if (index > -1) {
        newSelectedButtons.splice(index, 1);
      } else {
        newSelectedButtons.push(button);
      }
    } else {
      newSelectedButtons = [];
      newSelectedButtons.push(button);
    }

    if (newSelectedButtons.length === 0) {
      filterValueFunc.removeFilter(filter.specId);
    } else {
      filterValueFunc.addFilter({
        specId: filter.specId,
        value: `{"values":${JSON.stringify(newSelectedButtons)}}`,
      });
    }
  };

  const setToDefault = () => {
    const defaultValueIndex = filterSpecification.default ?
      filterSpecification?.options.indexOf(filterSpecification.default)
      : undefined;

    if (defaultValueIndex !== undefined) {
      handleButtonClick(filterSpecification.label[defaultValueIndex]);
    }
  };

  const handleClear = () => {
    filterValueFunc.removeFilter(filter.specId);
    setToDefault();
  };

  useEffect(() => {
    setToDefault();
  }, []);

  const active = filterValue.length > 0;

  return (
    <Box
      pb={3}
      opacity={active ? filterOpacity.active : filterOpacity.notActive}
      data-cy={"buttonFilter"}
    >
      <HStack marginBottom={2} justifyContent="space-between">
        <Text fontSize="md" color={textColors.primary}>{language == LocaleKeys.DE ? filter.labelGerman : language == LocaleKeys.EN ? filter.labelEnglish : filter.labelSpanish}</Text>
        <Icon
          as={AiFillCloseCircle}
          color="gray.500"
          style={{ cursor: "pointer" }}
          onClick={handleClear}
          boxSize={4}
          data-cy={"resetButton"}
        ></Icon>
      </HStack>
      <SimpleGrid columns={filterSpecification.columns} spacing={2}>
        {filterSpecification.label.map((button, buttonIndex) => (
          <Button
            key={buttonIndex}
            onClick={() => handleButtonClick(button)}
            backgroundColor={
              filterValue.includes(filterSpecification.options[buttonIndex])
                ? colorsSecondary.accent
                : ""
            }
            color={
              filterValue.includes(filterSpecification.options[buttonIndex])
                ? colorsSecondary.background
                : textColors.primary
            }
            variant="outline"
            fontWeight="thin"
          >
            {button}
          </Button>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default ButtonGroupFilter;
