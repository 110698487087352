import { accordionAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(accordionAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    bg: "white",
    borderRadius: "lg",
    boxShadow: "sm",
  },
  button: {
    _hover: {
      bg: "",
    },
  },
});

export const accordionTheme = defineMultiStyleConfig({ baseStyle });
