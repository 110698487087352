import { LanguageKeys } from "../../../language/LanguageKeys";
import {
  added,
  age,
  athletisch, comment, compare, deleteFromShadowteam, DeletePlayerProps,
  height, LanguageProps, lastReview,
  marketValue, numberOfReviews, OnClickCommentProps,
  OnSelectProps,
  positionsGruppe,
  SelectedPlayersProps,
  stammdaten,
  alleyes,
  subjectiveGameRatingsOverall, subjectiveGameRatingsPotential,
  subjectiveOverall,
  subjectivePotential,
  taktischPasses,
  taktischRun,
  technischD,
  technischPs
} from "../../general/ColumnDefinitions";
import { GridColDef } from "@mui/x-data-grid-pro";

const disabled = (params: any) => !params.row.player.positionsGruppe;

export const columns = (
  { onClick, onSelect, selectedPlayerIds, deletePlayerLocal, guiText }: OnClickCommentProps & OnSelectProps & SelectedPlayersProps & DeletePlayerProps & LanguageProps
): GridColDef[] => {
  return [
    stammdaten("row.player", guiText[LanguageKeys.SpielerprofilStammdaten], {
      sortComparator: (v1: string, v2: string) => {
        const surname1 = v1.split(" ").pop()!;
        const surname2 = v2.split(" ").pop()!;
        return surname1.localeCompare(surname2);
      },
    }, "row.color"),
    positionsGruppe("row.shadowteamPosition", guiText[LanguageKeys.SearchBarPosition]),
    age("row.player.age", guiText[LanguageKeys.SearchBarAlter]),
    height("row.player.height", guiText[LanguageKeys.SearchBarKorpergrobe]),
    marketValue("row.player.marketValue", guiText[LanguageKeys.SearchBarMarktwert]),
    alleyes("row.player.alleyes", guiText[LanguageKeys.SearchBarAlleyesAktuell]),
    athletisch("row.player.athletisch", guiText[LanguageKeys.SpielerprofilAthletisch]),
    technischPs("row.player.technischPs", guiText[LanguageKeys.TechnischnPS]),
    technischD("row.player.technischD", guiText[LanguageKeys.TechnischnDuelle]),
    taktischRun("row.player.taktischRun", guiText[LanguageKeys.TaktischnRun]),
    taktischPasses("row.player.taktischPasses", guiText[LanguageKeys.TaktischnPass]),
    subjectiveOverall("row.player.subjectiveOverall", guiText[LanguageKeys.GesamtbewertungA]),
    subjectivePotential("row.player.subjectivePotential", guiText[LanguageKeys.GesamtbewertungP]),
    subjectiveGameRatingsOverall("row.player.subjectiveGameRatingsOverall", guiText[LanguageKeys.SpielbewertungA]),
    subjectiveGameRatingsPotential("row.player.subjectiveGameRatingsPotential", guiText[LanguageKeys.SpielbewertungP]),
    numberOfReviews("row.player.numberOfReviews", guiText[LanguageKeys.WatchlistAnzahlSichtungen]),
    lastReview("row.player.lastReview", guiText[LanguageKeys.WatchlistLetzteSichtung]),
    added("row.added", guiText[LanguageKeys.WatchlistHinzugefugtAm]),
    comment("row.player.id", { onClick }),
    compare({ selectedPlayerIds, onSelect, disabled },),
    deleteFromShadowteam("row.player", { deletePlayerLocal }),
  ]
};
