import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  Table,
  Tbody,
  Td,
  Tr,
} from "@chakra-ui/react";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import { accordionPanelContentGerman } from "../components/glossary/GlossaryContentGerman";
import { useContext } from "react";
import { LanguageContext } from "../store/languageContext";
import { LanguageKeys } from "../language/LanguageKeys";
import { LocaleKeys } from "../language/LocaleKeys";
import { accordionPanelContentEnglish } from "../components/glossary/GlossaryContentEnglish";
import { accordionPanelContentSpanish } from "../components/glossary/GlossaryContentSpanish";
import { colorsSecondary, textColors } from "../colorStyles";

const StyledTable = (props: any) => (
  <Table variant="simple" size="md" {...props} />
);
const StyledTd = (props: any) => <Td textAlign="center" p={2} {...props} />;
const StyledTr = (props: any) => <Tr {...props} />;

const Glossary = () => {
  const { guiText, language } = useContext(LanguageContext);

  let accordionPanel: Map<string, string>;
  switch (language) {
    case LocaleKeys.DE:
      accordionPanel = accordionPanelContentGerman;
      break;
    case LocaleKeys.EN:
      accordionPanel = accordionPanelContentEnglish;
      break;
    case LocaleKeys.ES:
      accordionPanel = accordionPanelContentSpanish;
      break;
    default:
      accordionPanel = accordionPanelContentGerman;
  }

  return (
    <Box flex={1} pb={3} display="flex" flexDir="column" overflowY="auto">
      <Heading color={textColors.primary} fontFamily={"Text_Regular"}>{guiText[LanguageKeys.Glossar]}</Heading>
      <Box flex={1} >
        <Accordion defaultIndex={[0]} allowToggle >
          {Array.from(accordionPanel).map(([title, markdown]) => (
            <AccordionItem key={title} mt="2" backgroundColor={colorsSecondary.cardBackground} borderColor={colorsSecondary.cardBackground}>
              <h3>
                <AccordionButton backgroundColor={colorsSecondary.cardBackground}>
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontWeight="bold"
                    fontSize={18}
                    color={textColors.primary}
                  >
                    {title}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h3>
              <AccordionPanel
                p={1}
                pl={8}
                pb={3}
                borderBottomRadius={markdown ? "md" : "none"}
                style={{ listStyle: "inside" }}
                color={textColors.primary}
              >
                <Markdown
                  remarkPlugins={[remarkGfm]}
                  rehypePlugins={[rehypeRaw]}
                  components={{
                    table: StyledTable,
                    td: StyledTd,
                    tr: StyledTr,
                    tbody: Tbody,
                  }}
                >
                  {markdown}
                </Markdown>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Box>
    </Box>
  );
};

export default Glossary;
