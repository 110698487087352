import { Box, Divider, Heading, VStack } from "@chakra-ui/react";
import { useContext, useState } from "react";
import footballField from "../../../assets/FootballFieldBlue.png";
import { Shadowteam, ShadowteamEntry } from "../../../generated";
import { StrictModeDroppable } from "../../comparison/StrictModeDroppable";
import DnDPosition from "../formation/DnDPosition";
import DnDShadowteamEntry from "../formation/DnDShadowteamEntry";
import { formationMapping } from "../formation/FormationsAndPositions";
import { LanguageKeys } from "../../../language/LanguageKeys";
import { LanguageContext } from "../../../store/languageContext";
import { colorsSecondary, textColors } from "../../../colorStyles";

interface Props {
  shadowteam: Shadowteam | undefined;
  players: Map<string, ShadowteamEntry[]>;
  writeAccess: boolean;
  deletePlayer: (playerId: number) => void;
  onPlayerClick: (player: ShadowteamEntry) => void;
  handleOpenPositionDetails: (position: string) => void;
}

const ShadowteamFootballField = ({
  shadowteam,
  players,
  writeAccess,
  deletePlayer,
  onPlayerClick,
  handleOpenPositionDetails,
}: Props) => {
  const { guiText } = useContext(LanguageContext);
  const [expandedPosition, setExpandedPosition] = useState("");

  const handleCollapsePosition = () => {
    setExpandedPosition("");
  };

  const handleExpandPosition = (positionName: string) => {
    setExpandedPosition(positionName);
  };

  return (
    <Box flex={1} display="flex" width="100%">
      <Box
        bg="green"
        backgroundImage={footballField}
        backgroundSize="cover"
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        boxSizing="border-box"
        // outline={`5px solid ${colorsSecondary.background}`}
        outlineOffset="-5px"
        position="relative"
        backgroundColor={colorsSecondary.background}
        w="70vw"
      >
        {shadowteam &&
          formationMapping.get(shadowteam.formation)?.map((position, idx) => {
            return (
              <DnDPosition
                players={players.get(position.name) ?? []}
                top={position.top}
                left={position.left}
                position={position.name}
                key={idx}
                onClickEntry={onPlayerClick}
                isDropDisabled={
                  !writeAccess ||
                  (expandedPosition !== "" &&
                    expandedPosition !== position.name)
                }
                onExpand={handleExpandPosition}
                onCollapse={handleCollapsePosition}
                onClickPositionDetails={handleOpenPositionDetails}
                expanded={
                  position.name !== "GK" && expandedPosition === position.name
                }
              />
            );
          })}
      </Box>
      <Box flex={1} pl={3} display="flex" minW="250px">
        <Box
          flex={1}
          bg={colorsSecondary.cardBackground}
          borderRadius={4}
          display="flex"
          flexDir="column"
          color={textColors.primary}
        >
          <Heading pl={2} py={1} fontSize="17">
            {guiText[LanguageKeys.SchattenmannschaftenBank]}
          </Heading>
          <Divider borderColor="gray.300"></Divider>
          <StrictModeDroppable
            droppableId="BENCH"
            isDropDisabled={!writeAccess}
          >
            {(provided) => (
              <Box
                flex={1}
                overflow="hidden"
                ref={provided.innerRef}
                {...provided.droppableProps}
                pt={1}
              >
                <VStack
                  w="100%"
                  h="100%"
                  spacing={1}
                  className="scroll-portal scroll-y"
                >
                  {players.get("BENCH")?.map((playerEntry) => (
                    <DnDShadowteamEntry
                      key={playerEntry.player.id}
                      playerEntry={playerEntry}
                      bench={true}
                      deletePlayer={deletePlayer}
                      onClick={onPlayerClick}
                      isDragDisabled={!writeAccess}
                    ></DnDShadowteamEntry>
                  ))}
                </VStack>
                {provided.placeholder}
              </Box>
            )}
          </StrictModeDroppable>
        </Box>
      </Box>
    </Box>
  );
};

export default ShadowteamFootballField;
