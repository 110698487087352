import {
  Badge,
  Box,
  Grid,
  GridItem,
  HStack,
  Image,
  Link,
  Text,
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import picture from "../../../assets/placeholder-picture.png";
import { colorsSecondary } from "../../../colorStyles";
import { ScoutingReport } from "../../../generated";
import { formatDate, getRatingColor } from "../../../util/utilFunctions";
import { LanguageContext} from "../../../store/languageContext";
import { LanguageKeys } from "../../../language/LanguageKeys";

interface Props {
  report: ScoutingReport;
}

const Report = ({report}: Props) => {
  const {guiText} = useContext(LanguageContext);
  const [expanded, setExpanded] = useState(false);
  const limit = 600;

  const summary = expanded
    ? report.kommentarScout
    : report.kommentarScout?.substring(0, limit) + "...";

  return (
    <Box
      p={2}
      background={colorsSecondary.background}
      mb={5}
      pb={1}
      boxShadow="md"
    >
      <Grid
        templateRows="auto auto 3px auto"
        templateColumns={
          report.kategorie == "Spielbewertung"
            ? "18% 18% 30% 34%"
            : "18% 18% 64%"
        }
        pl={2}
        pb={1}
      >
        <GridItem>
          <Text fontWeight="semibold">{guiText[LanguageKeys.SpielerprofilSpieler]}</Text>
        </GridItem>
        <GridItem>
          <Text fontWeight="semibold">{guiText[LanguageKeys.SpielerprofilEventdatum]}</Text>
        </GridItem>
        {report.kategorie == "Spielbewertung" && (
          <GridItem>
            <Text fontWeight="semibold">{guiText[LanguageKeys.SpielerprofilSpiel]}</Text>
          </GridItem>
        )}
        <GridItem>
          <Text fontWeight="semibold">{guiText[LanguageKeys.SpielerprofilScout]}</Text>
        </GridItem>

        <GridItem pb={2}>
          <Box>
            <HStack>
              <Image
                src={picture}
                borderRadius="lg"
                boxSize="33px"
                align="center"
              />
              <Box>
                <Text fontSize={15} lineHeight="short">
                  {report.spielerVorname + " " + report.spielerNachname}
                </Text>
                <Text fontSize={15} lineHeight="short">
                  {report.mannschaft}
                </Text>
              </Box>
            </HStack>
          </Box>
        </GridItem>
        <GridItem pb={2}>
          <Text fontSize={15}>
            {report.eventDate ? formatDate(report.eventDate) : ""}
          </Text>
        </GridItem>
        {report.kategorie == "Spielbewertung" && (
          <GridItem pb={2}>
            <Text fontSize={15}>
              {report.eventName ? report.eventName : ""}
            </Text>
          </GridItem>
        )}
        <GridItem pb={2}>
          <Box>
            <Text fontSize={15}>
              {report.scoutVorname + " " + report.scoutNachname}
            </Text>
            <Text fontSize={12} lineHeight="short">
              {"am " + formatDate(report.erstellungsdatum!)}
            </Text>
          </Box>
        </GridItem>

        <GridItem
          colSpan={report.kategorie == "Spielbewertung" ? 4 : 3}
          bg="white"
          mr={-2}
          ml={-4}
        />

        <GridItem colSpan={2} pt={2}>
          <HStack h="100%">
            <Box w="50%" h="100%">
              <HStack h="100%">
                <Badge
                  borderRadius="50%"
                  width="40px"
                  height="40px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  background={
                    report.rating
                      ? getRatingColor(report.rating, 0, 10)
                      : "white"
                  }
                  alignSelf="start"
                  border={report.rating ? "" : "2px solid #CBD5E0"}
                >
                  <Text
                    color={report.rating ? "white" : "gray.300"}
                    fontSize="16"
                    lineHeight="1"
                  >
                    {report.rating ? report.rating : "?"}
                  </Text>
                </Badge>
                <Box alignSelf="start">
                  <Text fontSize={15} fontWeight="semibold">
                    {guiText[LanguageKeys.SpielerprofilOverallRating]}
                  </Text>
                  <Text fontSize={15} pb={3}>
                    {report.ratingText}
                  </Text>
                  <Text fontSize={15} fontWeight="semibold">
                    {guiText[LanguageKeys.SpielerprofilPositionImReport]}
                  </Text>
                  <Text fontSize={15} pb={3}>
                    {report.positionSap + " | " + report.positionSapDetail}
                  </Text>
                  <Text fontSize={15} fontWeight="semibold">
                    {guiText[LanguageKeys.SpielerprofilJahrgangstyp]}
                  </Text>
                  <Text fontSize={15} wordBreak="break-word">
                    {report.jahrgangstyp}
                  </Text>
                </Box>
              </HStack>
            </Box>
            <Box w="50%" h="100%">
              <HStack h="100%">
                <Badge
                  borderRadius="50%"
                  width="40px"
                  height="40px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  background={
                    report.potentialRating
                      ? getRatingColor(report.potentialRating, 0, 10)
                      : "white"
                  }
                  alignSelf="start"
                  border={report.potentialRating ? "" : "2px solid #CBD5E0"}
                >
                  <Text
                    color={report.potentialRating ? "white" : "gray.300"}
                    fontSize="16"
                    lineHeight="1"
                  >
                    {report.potentialRating ? report.potentialRating : "?"}
                  </Text>
                </Badge>
                <Box alignSelf="start">
                  <Text fontSize={15} fontWeight="semibold">
                    {guiText[LanguageKeys.SpielerprofilPotentialRating]}
                  </Text>
                  <Text fontSize={15} pb={3}>
                    {report.potentialRatingText}
                  </Text>
                </Box>
              </HStack>
            </Box>
          </HStack>
        </GridItem>
        <GridItem colSpan={report.kategorie == "Spielbewertung" ? 2 : 1} pt={2}>
          {report.kommentarScout &&
            (report.kommentarScout?.length <= limit ? (
              <Text fontSize={15}>{report.kommentarScout}</Text>
            ) : (
              <>
                <Text>
                  {summary?.split("\\n").map((str, index, array) =>
                    index === array.length - 1 ? (
                      str
                    ) : (
                      <>
                        {str}
                        <br />
                        <br />
                      </>
                    )
                  )}
                </Text>

                <Link
                  fontWeight="normal"
                  onClick={() => setExpanded(!expanded)}
                  textDecoration="underline"
                >
                  {expanded ? guiText[LanguageKeys.WenigerAnzeigen] : guiText[LanguageKeys.MehrAnzeigen]}
                </Link>
              </>
            ))}
        </GridItem>
      </Grid>
      <Text fontSize="12" color="gray.500" textAlign="end">
        {guiText[LanguageKeys.SpielerprofilZuletztGeandert] + ": " + formatDate(report.letztesAenderungsdatum!)}
      </Text>
    </Box>
  );
};

export default Report;
