import {Box, Center, Spacer, Text, VStack} from "@chakra-ui/react";
import {useLocation} from "react-router-dom";
import {useGetShadowteam} from "../../hooks/useShadowteams";
import {colorsPrimary} from "../../colorStyles";
import {useContext, useEffect} from "react";
import { LanguageContext } from "../../store/languageContext";
import { LanguageKeys } from "../../language/LanguageKeys";
import {LocaleKeys} from "../../language/LocaleKeys";

const ShadowteamTitlepagePrintView = () => {
  const { setLanguage, guiText } = useContext(LanguageContext);

  const today = new Date().toLocaleDateString("de-DE");

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = Number(queryParams.get("id")!);
  const formation = queryParams.get("formation") ?? undefined;

  const { data, isLoading, error } = useGetShadowteam(id, formation);

  const language = queryParams.get("language");
  useEffect(() => {
    switch (language) {
      case LocaleKeys.DE:
        setLanguage(LocaleKeys.DE);
        break;
      case LocaleKeys.EN:
        setLanguage(LocaleKeys.EN);
        break;
      case LocaleKeys.ES:
        setLanguage(LocaleKeys.ES);
        break;
      default:
        setLanguage(LocaleKeys.DE);
    }
  }, []);

  return (
    <>
      {isLoading && !error &&
        <Center h="100%" w="100%">
          <Text fontSize={20}>{guiText[LanguageKeys.DatenWerdenGeladen]}</Text>
        </Center>
      }
      {!isLoading && error &&
        <Center h="100%" w="100%">
          <Text fontSize={20}>{guiText[LanguageKeys.Datenkonntennichtgeladenwerden]}</Text>
        </Center>
      }
      {!isLoading && data &&
        <VStack
          id="shadowteam-titlepage-print-view-main-content"
          width="100%"
          height="100%"
          align="start"
          color={colorsPrimary.koenigsblau}
          pl={12}
        >
          <Box h="30%" />
          <Text fontSize="6xl" pb={30}>{data.shadowteamName}</Text>
          <Text fontSize="4xl">{data.formation}</Text>
          <Text fontSize="4xl">{guiText[LanguageKeys.SpielerprofilSpieler]}: {data.noOfPlayers} </Text>
          <Spacer />
          <Text fontSize="md">{guiText[LanguageKeys.Erstellungsdatum]}: {today}</Text>
        </VStack>
      }
    </>
  );
};

export default ShadowteamTitlepagePrintView;
