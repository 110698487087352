/* tslint:disable */
/* eslint-disable */
/**
 * alleyes SCOUT BACKEND
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddPlayerToWatchlistRequest
 */
export interface AddPlayerToWatchlistRequest {
    /**
     * 
     * @type {number}
     * @memberof AddPlayerToWatchlistRequest
     */
    playerId: number;
    /**
     * 
     * @type {string}
     * @memberof AddPlayerToWatchlistRequest
     */
    positionsGruppe: string;
    /**
     * 
     * @type {string}
     * @memberof AddPlayerToWatchlistRequest
     */
    comment?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AddPlayerToWatchlistRequest
     */
    favorite?: boolean;
}

/**
 * Check if a given object implements the AddPlayerToWatchlistRequest interface.
 */
export function instanceOfAddPlayerToWatchlistRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "playerId" in value;
    isInstance = isInstance && "positionsGruppe" in value;

    return isInstance;
}

export function AddPlayerToWatchlistRequestFromJSON(json: any): AddPlayerToWatchlistRequest {
    return AddPlayerToWatchlistRequestFromJSONTyped(json, false);
}

export function AddPlayerToWatchlistRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddPlayerToWatchlistRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'playerId': json['playerId'],
        'positionsGruppe': json['positionsGruppe'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'favorite': !exists(json, 'favorite') ? undefined : json['favorite'],
    };
}

export function AddPlayerToWatchlistRequestToJSON(value?: AddPlayerToWatchlistRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'playerId': value.playerId,
        'positionsGruppe': value.positionsGruppe,
        'comment': value.comment,
        'favorite': value.favorite,
    };
}

