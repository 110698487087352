import {
  Button,
  ButtonGroup,
} from "@chakra-ui/react";
import { colorsSecondary, textColors } from "../../colorStyles";
import { useOverallPotentialStore } from "../../store/store";
import { LanguageKeys } from "../../language/LanguageKeys";
import { useContext } from "react";
import { LanguageContext } from "../../store/languageContext";
import { text } from "d3";

interface Props {
  useCase: "search" | "shadowteams";
}

const OverallPotentialSwitch = ({ useCase }: Props) => {
  const { guiText } = useContext(LanguageContext);
  const { overall, setOverall, overallShadowteams, setOverallShadowteams } =
    useOverallPotentialStore();
  const value = useCase == "search" ? overall : overallShadowteams;
  const setter = useCase == "search" ? setOverall : setOverallShadowteams;

  return (
    <ButtonGroup isAttached boxShadow="sm" h="35px">
      <Button
        onClick={() => setter(true)}
        backgroundColor={value ? colorsSecondary.accent : colorsSecondary.cardBackground}
        color={value ? colorsSecondary.background : textColors.lightgrey}
        fontSize="13"
        p={2}
        borderTopLeftRadius="md"
        borderBottomLeftRadius="md"
        borderTopRightRadius="0"
        borderBottomRightRadius="0"
        h="35px"
      >
        {guiText[LanguageKeys.Aktuell]}
      </Button>
      <Button
        fontSize="13"
        onClick={() => setter(false)}
        backgroundColor={!value ? colorsSecondary.accent : colorsSecondary.cardBackground}
        color={!value ? colorsSecondary.background : textColors.lightgrey}
        borderTopLeftRadius="0"
        borderBottomLeftRadius="0"
        borderTopRightRadius="md"
        borderBottomRightRadius="md"
        p={2}
        h="35px"
      >
        {guiText[LanguageKeys.Potential]}
      </Button>
    </ButtonGroup>
  );
};

export default OverallPotentialSwitch;
