/* tslint:disable */
/* eslint-disable */
/**
 * alleyes SCOUT BACKEND
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorBody,
  Formation,
  Shadowteam,
  ShadowteamOverview,
} from '../models';
import {
    ErrorBodyFromJSON,
    ErrorBodyToJSON,
    FormationFromJSON,
    FormationToJSON,
    ShadowteamFromJSON,
    ShadowteamToJSON,
    ShadowteamOverviewFromJSON,
    ShadowteamOverviewToJSON,
} from '../models';

export interface AddPlayerToShadowteamRequest {
    shadowteamId: number;
    playerId: number;
    shadowteamPosition: string;
}

export interface CreateShadowteamRequest {
    shadowteamOverview: ShadowteamOverview;
}

export interface DeletePlayerFromShadowteamRequest {
    shadowteamId: number;
    playerId: number;
}

export interface DeleteShadowteamRequest {
    shadowteamId: number;
}

export interface DeleteShadowteamFormationRequest {
    shadowteamId: number;
    formation: string;
}

export interface DuplicateShadowteamRequest {
    shadowteamId: number;
}

export interface EditShadowteamRequest {
    shadowteamId: number;
    shadowteamOverview: ShadowteamOverview;
}

export interface ExportShadowteamToPDFRequest {
    shadowteamId: number;
    formation: string;
    includePlayerProfiles: boolean;
    language: string;
}

export interface GetShadowteamRequest {
    shadowteamId: number;
    formation?: string;
}

export interface GetShadowteamFormationsRequest {
    shadowteamId: number;
}

export interface SaveShadowteamRequest {
    shadowteam: Shadowteam;
}

export interface ToggleShadowteamFavoriteStatusRequest {
    shadowteamId: number;
}

/**
 * 
 */
export class ShadowteamApi extends runtime.BaseAPI {

    /**
     * Add a player to a shadowteam.
     */
    async addPlayerToShadowteamRaw(requestParameters: AddPlayerToShadowteamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.shadowteamId === null || requestParameters.shadowteamId === undefined) {
            throw new runtime.RequiredError('shadowteamId','Required parameter requestParameters.shadowteamId was null or undefined when calling addPlayerToShadowteam.');
        }

        if (requestParameters.playerId === null || requestParameters.playerId === undefined) {
            throw new runtime.RequiredError('playerId','Required parameter requestParameters.playerId was null or undefined when calling addPlayerToShadowteam.');
        }

        if (requestParameters.shadowteamPosition === null || requestParameters.shadowteamPosition === undefined) {
            throw new runtime.RequiredError('shadowteamPosition','Required parameter requestParameters.shadowteamPosition was null or undefined when calling addPlayerToShadowteam.');
        }

        const queryParameters: any = {};

        if (requestParameters.shadowteamPosition !== undefined) {
            queryParameters['shadowteamPosition'] = requestParameters.shadowteamPosition;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/shadowteams/{shadowteamId}/{playerId}`.replace(`{${"shadowteamId"}}`, encodeURIComponent(String(requestParameters.shadowteamId))).replace(`{${"playerId"}}`, encodeURIComponent(String(requestParameters.playerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add a player to a shadowteam.
     */
    async addPlayerToShadowteam(shadowteamId: number, playerId: number, shadowteamPosition: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addPlayerToShadowteamRaw({ shadowteamId: shadowteamId, playerId: playerId, shadowteamPosition: shadowteamPosition }, initOverrides);
    }

    /**
     * Create a new Shadowteam.
     */
    async createShadowteamRaw(requestParameters: CreateShadowteamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.shadowteamOverview === null || requestParameters.shadowteamOverview === undefined) {
            throw new runtime.RequiredError('shadowteamOverview','Required parameter requestParameters.shadowteamOverview was null or undefined when calling createShadowteam.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/shadowteams`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ShadowteamOverviewToJSON(requestParameters.shadowteamOverview),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a new Shadowteam.
     */
    async createShadowteam(shadowteamOverview: ShadowteamOverview, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createShadowteamRaw({ shadowteamOverview: shadowteamOverview }, initOverrides);
    }

    /**
     * Delete a player from a shadowteam.
     */
    async deletePlayerFromShadowteamRaw(requestParameters: DeletePlayerFromShadowteamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.shadowteamId === null || requestParameters.shadowteamId === undefined) {
            throw new runtime.RequiredError('shadowteamId','Required parameter requestParameters.shadowteamId was null or undefined when calling deletePlayerFromShadowteam.');
        }

        if (requestParameters.playerId === null || requestParameters.playerId === undefined) {
            throw new runtime.RequiredError('playerId','Required parameter requestParameters.playerId was null or undefined when calling deletePlayerFromShadowteam.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/shadowteams/{shadowteamId}/{playerId}`.replace(`{${"shadowteamId"}}`, encodeURIComponent(String(requestParameters.shadowteamId))).replace(`{${"playerId"}}`, encodeURIComponent(String(requestParameters.playerId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a player from a shadowteam.
     */
    async deletePlayerFromShadowteam(shadowteamId: number, playerId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deletePlayerFromShadowteamRaw({ shadowteamId: shadowteamId, playerId: playerId }, initOverrides);
    }

    /**
     * Delete an existing Shadowteam.
     */
    async deleteShadowteamRaw(requestParameters: DeleteShadowteamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.shadowteamId === null || requestParameters.shadowteamId === undefined) {
            throw new runtime.RequiredError('shadowteamId','Required parameter requestParameters.shadowteamId was null or undefined when calling deleteShadowteam.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/shadowteams/{shadowteamId}`.replace(`{${"shadowteamId"}}`, encodeURIComponent(String(requestParameters.shadowteamId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an existing Shadowteam.
     */
    async deleteShadowteam(shadowteamId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteShadowteamRaw({ shadowteamId: shadowteamId }, initOverrides);
    }

    /**
     * Delete an existing Shadowteams formation.
     */
    async deleteShadowteamFormationRaw(requestParameters: DeleteShadowteamFormationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.shadowteamId === null || requestParameters.shadowteamId === undefined) {
            throw new runtime.RequiredError('shadowteamId','Required parameter requestParameters.shadowteamId was null or undefined when calling deleteShadowteamFormation.');
        }

        if (requestParameters.formation === null || requestParameters.formation === undefined) {
            throw new runtime.RequiredError('formation','Required parameter requestParameters.formation was null or undefined when calling deleteShadowteamFormation.');
        }

        const queryParameters: any = {};

        if (requestParameters.formation !== undefined) {
            queryParameters['formation'] = requestParameters.formation;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/shadowteams/formations/{shadowteamId}`.replace(`{${"shadowteamId"}}`, encodeURIComponent(String(requestParameters.shadowteamId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an existing Shadowteams formation.
     */
    async deleteShadowteamFormation(shadowteamId: number, formation: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteShadowteamFormationRaw({ shadowteamId: shadowteamId, formation: formation }, initOverrides);
    }

    /**
     * Duplicate an existing Shadowteam.
     */
    async duplicateShadowteamRaw(requestParameters: DuplicateShadowteamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.shadowteamId === null || requestParameters.shadowteamId === undefined) {
            throw new runtime.RequiredError('shadowteamId','Required parameter requestParameters.shadowteamId was null or undefined when calling duplicateShadowteam.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/shadowteams/{shadowteamId}`.replace(`{${"shadowteamId"}}`, encodeURIComponent(String(requestParameters.shadowteamId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Duplicate an existing Shadowteam.
     */
    async duplicateShadowteam(shadowteamId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.duplicateShadowteamRaw({ shadowteamId: shadowteamId }, initOverrides);
    }

    /**
     * Edit an existing Shadowteam.
     */
    async editShadowteamRaw(requestParameters: EditShadowteamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.shadowteamId === null || requestParameters.shadowteamId === undefined) {
            throw new runtime.RequiredError('shadowteamId','Required parameter requestParameters.shadowteamId was null or undefined when calling editShadowteam.');
        }

        if (requestParameters.shadowteamOverview === null || requestParameters.shadowteamOverview === undefined) {
            throw new runtime.RequiredError('shadowteamOverview','Required parameter requestParameters.shadowteamOverview was null or undefined when calling editShadowteam.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/shadowteams/{shadowteamId}`.replace(`{${"shadowteamId"}}`, encodeURIComponent(String(requestParameters.shadowteamId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ShadowteamOverviewToJSON(requestParameters.shadowteamOverview),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Edit an existing Shadowteam.
     */
    async editShadowteam(shadowteamId: number, shadowteamOverview: ShadowteamOverview, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.editShadowteamRaw({ shadowteamId: shadowteamId, shadowteamOverview: shadowteamOverview }, initOverrides);
    }

    /**
     * Exports the specified shadowteam to a PDF document based on the provided ID and formation.
     * Export a shadowteam to PDF.
     */
    async exportShadowteamToPDFRaw(requestParameters: ExportShadowteamToPDFRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.shadowteamId === null || requestParameters.shadowteamId === undefined) {
            throw new runtime.RequiredError('shadowteamId','Required parameter requestParameters.shadowteamId was null or undefined when calling exportShadowteamToPDF.');
        }

        if (requestParameters.formation === null || requestParameters.formation === undefined) {
            throw new runtime.RequiredError('formation','Required parameter requestParameters.formation was null or undefined when calling exportShadowteamToPDF.');
        }

        if (requestParameters.includePlayerProfiles === null || requestParameters.includePlayerProfiles === undefined) {
            throw new runtime.RequiredError('includePlayerProfiles','Required parameter requestParameters.includePlayerProfiles was null or undefined when calling exportShadowteamToPDF.');
        }

        if (requestParameters.language === null || requestParameters.language === undefined) {
            throw new runtime.RequiredError('language','Required parameter requestParameters.language was null or undefined when calling exportShadowteamToPDF.');
        }

        const queryParameters: any = {};

        if (requestParameters.includePlayerProfiles !== undefined) {
            queryParameters['includePlayerProfiles'] = requestParameters.includePlayerProfiles;
        }

        if (requestParameters.language !== undefined) {
            queryParameters['language'] = requestParameters.language;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/shadowteams/{shadowteamId}/{formation}/export`.replace(`{${"shadowteamId"}}`, encodeURIComponent(String(requestParameters.shadowteamId))).replace(`{${"formation"}}`, encodeURIComponent(String(requestParameters.formation))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Exports the specified shadowteam to a PDF document based on the provided ID and formation.
     * Export a shadowteam to PDF.
     */
    async exportShadowteamToPDF(shadowteamId: number, formation: string, includePlayerProfiles: boolean, language: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.exportShadowteamToPDFRaw({ shadowteamId: shadowteamId, formation: formation, includePlayerProfiles: includePlayerProfiles, language: language }, initOverrides);
        return await response.value();
    }

    /**
     * Get all Shadowteams.
     */
    async getAllShadowteamsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ShadowteamOverview>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/shadowteams`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ShadowteamOverviewFromJSON));
    }

    /**
     * Get all Shadowteams.
     */
    async getAllShadowteams(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ShadowteamOverview>> {
        const response = await this.getAllShadowteamsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get a shadowteam.
     */
    async getShadowteamRaw(requestParameters: GetShadowteamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Shadowteam>> {
        if (requestParameters.shadowteamId === null || requestParameters.shadowteamId === undefined) {
            throw new runtime.RequiredError('shadowteamId','Required parameter requestParameters.shadowteamId was null or undefined when calling getShadowteam.');
        }

        const queryParameters: any = {};

        if (requestParameters.formation !== undefined) {
            queryParameters['formation'] = requestParameters.formation;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/shadowteams/{shadowteamId}`.replace(`{${"shadowteamId"}}`, encodeURIComponent(String(requestParameters.shadowteamId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShadowteamFromJSON(jsonValue));
    }

    /**
     * Get a shadowteam.
     */
    async getShadowteam(shadowteamId: number, formation?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Shadowteam> {
        const response = await this.getShadowteamRaw({ shadowteamId: shadowteamId, formation: formation }, initOverrides);
        return await response.value();
    }

    /**
     * Get a shadowteams existing formations.
     */
    async getShadowteamFormationsRaw(requestParameters: GetShadowteamFormationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Formation>>> {
        if (requestParameters.shadowteamId === null || requestParameters.shadowteamId === undefined) {
            throw new runtime.RequiredError('shadowteamId','Required parameter requestParameters.shadowteamId was null or undefined when calling getShadowteamFormations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/shadowteams/formations/{shadowteamId}`.replace(`{${"shadowteamId"}}`, encodeURIComponent(String(requestParameters.shadowteamId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FormationFromJSON));
    }

    /**
     * Get a shadowteams existing formations.
     */
    async getShadowteamFormations(shadowteamId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Formation>> {
        const response = await this.getShadowteamFormationsRaw({ shadowteamId: shadowteamId }, initOverrides);
        return await response.value();
    }

    /**
     * Update/save an existing Shadowteam.
     */
    async saveShadowteamRaw(requestParameters: SaveShadowteamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.shadowteam === null || requestParameters.shadowteam === undefined) {
            throw new runtime.RequiredError('shadowteam','Required parameter requestParameters.shadowteam was null or undefined when calling saveShadowteam.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/shadowteams/saveShadowteam`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ShadowteamToJSON(requestParameters.shadowteam),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update/save an existing Shadowteam.
     */
    async saveShadowteam(shadowteam: Shadowteam, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.saveShadowteamRaw({ shadowteam: shadowteam }, initOverrides);
    }

    /**
     * Toggle a shadowteams\'s favorite status
     */
    async toggleShadowteamFavoriteStatusRaw(requestParameters: ToggleShadowteamFavoriteStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.shadowteamId === null || requestParameters.shadowteamId === undefined) {
            throw new runtime.RequiredError('shadowteamId','Required parameter requestParameters.shadowteamId was null or undefined when calling toggleShadowteamFavoriteStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/shadowteams/{shadowteamId}`.replace(`{${"shadowteamId"}}`, encodeURIComponent(String(requestParameters.shadowteamId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Toggle a shadowteams\'s favorite status
     */
    async toggleShadowteamFavoriteStatus(shadowteamId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.toggleShadowteamFavoriteStatusRaw({ shadowteamId: shadowteamId }, initOverrides);
    }

}
