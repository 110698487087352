import { Box, Divider, Text, VStack } from "@chakra-ui/react";
import { MutableRefObject } from "react";

import { ChartData } from "chart.js";
import { PolarArea } from "react-chartjs-2";
import styled from "styled-components";
import { colorsSecondary, textColors } from "../../colorStyles";
import { getRatingColor } from "../../util/utilFunctions";

interface Props {
  chartData: ChartData<"polarArea"> | undefined;
  options: any;
  category: string;
  categoryIdx: number;
  chartRefs: MutableRefObject<Record<string, any>>;
  columnNo: number;
  handleChartClick: (
    chartKey: string,
    event: any,
    index: number,
    columnIndex: number
  ) => void;
}

const StyledPolarArea = styled(PolarArea)`
  width: 100% !important;
  height: 100% !important;
  min-width: 350px !important;
  min-height: 350px !important;
  display: block;
  margin: 0 auto;
`;

const polarChartSize = "80%";

const PolarChart = ({
  chartData,
  options,
  category,
  categoryIdx,
  chartRefs,
  columnNo,
  handleChartClick,
}: Props) => {
  return (
    <Box flex={1} bg={colorsSecondary.cardBackground} borderRadius={5} boxShadow="md">
      {chartData?.labels && (
        <Box display="flex" flexDir="column" alignItems="center">
          <Box flex={1} overflow="hidden" w={polarChartSize}>
            <StyledPolarArea
              ref={(el) => (chartRefs.current[`${category + columnNo}`] = el)}
              data={chartData}
              options={options}
              onClick={(e) =>
                handleChartClick(
                  `${category + columnNo}`,
                  e,
                  categoryIdx,
                  columnNo
                )
              }
            />
          </Box>

          <Divider borderColor={colorsSecondary.background} borderWidth="1px" />
          <VStack spacing={0} pb={1} w="100%">
            {chartData.labels.map((label: any, idx: number) => (
              <Box width="100%" key={idx}>
                <Box
                  p={1}
                  pl={5}
                  display="flex"
                  w="full"
                  textAlign="start"
                  bgColor={
                    chartData.datasets[2].data[idx]
                      ? getRatingColor(
                        chartData.datasets[2].data[idx],
                        undefined,
                        undefined,
                        0.4
                      )
                      : "transparent"
                  }
                  color={textColors.lightgrey}
                >
                  <Text fontSize={14} width="70%">
                    {label.join(" ").replace(/-\s/g, "")}
                  </Text>
                  <Text width="30%" fontSize={14} pl={5} fontWeight="semibold">
                    {chartData.datasets[2].data[idx]
                      ? Math.round(chartData.datasets[2].data[idx])
                      : "-"}
                  </Text>
                </Box>
                {idx < chartData.labels!.length - 1 && (
                  <Divider
                    borderColor={colorsSecondary.background}
                    borderWidth="1px"
                  />
                )}
              </Box>
            ))}
          </VStack>
        </Box>
      )}
    </Box>
  );
};
export default PolarChart;
