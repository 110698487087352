import { createBrowserRouter } from "react-router-dom";
import Compare from "./pages/Compare";
import ErrorPage from "./pages/ErrorPage";
import Glossary from "./pages/Glossary";
import HomePage from "./pages/HomePage";
import Layout from "./pages/Layout";
import Login from "./pages/Login";
import PlayerProfile from "./pages/PlayerProfile";
import PrintView from "./pages/PrintView";
import Search from "./pages/Search";
import ShadowTeams from "./pages/ShadowTeams";
import ShadowteamDetails from "./pages/ShadowteamDetails";
import WatchList from "./pages/WatchList";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <HomePage /> },
      { path: "player/:id", element: <PlayerProfile /> },
      { path: "shadowTeams/:id", element: <ShadowteamDetails /> },
      { path: "shadowTeams", element: <ShadowTeams /> },
      { path: "watchlist", element: <WatchList /> },
      { path: "compare", element: <Compare /> },
      { path: "search", element: <Search /> },
      { path: "glossary", element: <Glossary /> },
    ],
  },
  { path: "/login", element: <Login /> },
  { path: "/printView/:page", element: <PrintView /> },
]);

export default router;
