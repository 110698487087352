import { Box, Grid, GridItem } from "@chakra-ui/react";
import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { colorsSecondary } from "../colorStyles";
import AuthenticationWrapper from "../components/AuthenticationWrapper";
import BreadcrumbNavigation from "../components/BreadcrumbNavigation";
import NavBar from "../components/NavBar";
import AllEyesSidebar from "../components/sidebar/Sidebar";
import {
  useComparisonStore,
  useLastMainPageStore,
  usePlayerProfileStore,
  useSidebarCollapsedStore,
} from "../store/store";

const Layout = () => {
  const { collapsed } = useSidebarCollapsedStore();

  const location = useLocation();
  const { setLastMainPage } = useLastMainPageStore();

  useEffect(() => {
    if (location.pathname.split("/")[1] != "player") {
      const mainPage = location.pathname.split("/")[1] || "Home";
      setLastMainPage(mainPage);
    }
  }, [location]);

  return (
    <AuthenticationWrapper>
      <Grid
        templateAreas={`"side nav"
                      "side breadcrumb"
                      "side main"`}
        gridTemplateRows={"70px 30px 1fr"}
        gridTemplateColumns={collapsed ? "80px 1fr" : "230px 1fr"}
        transition="0.3s"
        gap="0"
        backgroundColor={colorsSecondary.background}
        height="100vh"
      >
        <GridItem area="side" >
          <AllEyesSidebar />
        </GridItem>
        <GridItem area="nav">
          <NavBar onOpen={() => { }} />
        </GridItem>
        <GridItem area="breadcrumb" pl={4} pt={2}>
          <BreadcrumbNavigation />
        </GridItem>
        <GridItem area="main" overflow="hidden" height="100%" width="100%">
          <Box height="100%" pt={3} pr={4} pl={4} display="flex">
            <Outlet />
          </Box>
        </GridItem>
      </Grid>
    </AuthenticationWrapper>
  );
};

export default Layout;
