import { Box, Button, Center, Divider, Text, Textarea, useToast } from "@chakra-ui/react";
import { useContext, useState } from "react";
import { colorsPrimary, colorsSecondary, textColors } from "../../../colorStyles";
import { useAddComment, useGetComments } from "../../../hooks/usePlayer";
import CommentComponent from "./CommentComponent";
import { LanguageContext } from "../../../store/languageContext";
import { LanguageKeys } from "../../../language/LanguageKeys";
interface Props {
  id: number;
  useCase: "profile" | "quickView";
}
const Comments = ({ id, useCase }: Props) => {
  const { guiText } = useContext(LanguageContext);

  const [value, setValue] = useState("");
  const handleChange = (event: any) => setValue(event.target.value);

  const { data: comments, isLoading, error } = useGetComments(id);

  const toast = useToast();
  const useOnError = (error: any) => {
    let message = guiText[LanguageKeys.ErrorGeneral];
    if (error.response?.status == 403) {
      message = guiText[LanguageKeys.ErrorForbidden];
    }

    toast({
      title: guiText[LanguageKeys.Achtung] + "!",
      description: message + ` (Error ${error.response?.status})`,
      status: "warning",
      duration: 5000,
      isClosable: true,
      variant: "subtle",
    });
  };
  const addComment = useAddComment(useOnError);

  const handleSave = () => {
    addComment({ playerId: id, comment: value });
    setValue("");
  };

  return (
    <Box display="flex" flex={1} flexDir="column">
      <Box display="flex" flex={1} flexDir="column" overflow="auto" mb={2}>
        {comments &&
          comments.map((comment, idx) => (
            <CommentComponent comment={comment} key={idx} id={id} />
          ))}
        {isLoading && (
          <Center flex={1}>
            <Text fontSize={20} color={textColors.primary}>Loading...</Text>
          </Center>
        )}
        {(comments?.length == 0 || error) && (
          <Center flex={1}>
            <Text fontSize={useCase == "profile" ? 20 : 17} color={textColors.primary}>
              {guiText[LanguageKeys.SpielerprofilKeineKommentareVorhanden]}
            </Text>
          </Center>
        )}
      </Box>
      <Box flex={0} display="flex" flexDir="column" p="2px">
        <Divider border="1px solid #CBD5E0"></Divider>
        <Textarea
          value={value}
          onChange={handleChange}
          placeholder={guiText[LanguageKeys.SpielerprofilFugeEinenKommentarHinzu]}
          flex={1}
          my={2}
          boxShadow="md"
          resize="none"
          rows={useCase == "profile" ? 4 : 2}
          color={textColors.primary}
          _focus={{ borderColor: colorsSecondary.accent }}
        />
        <Button
          backgroundColor={colorsSecondary.accent}
          textColor={colorsSecondary.background}
          boxShadow="sm"
          height="35px"
          w="130px"
          alignSelf="flex-end"
          onClick={handleSave}
          isDisabled={value == ""}
          _hover={{ backgroundColor: "" }}
        >
          {guiText[LanguageKeys.SpielerprofilSpeichern]}
        </Button>
      </Box>
    </Box>
  );
};

export default Comments;
