import { GridColDef } from "@mui/x-data-grid-pro";
import { PropsWithChildren } from "react";
import theme from "../../themes/playerTableTheme";
import { Player } from "../../generated";
import TableWithSelectableColumns from "./TableWithSelectableColumns";

export const playerId = (player: Player) => player.id + "_" + player.positionsGruppe;

const initColumnVisibilitySettings = {
  technischPs: false,
  athletisch: false,
  technischD: false,
  taktischRun: false,
  taktischPass: false,
};

interface Props<DataType> {
  name: "watchlist" | "shadowteams" | "search" | "positionDetails" | "similarPlayers" | "printViewShadowteamPlayers";
  players: DataType[];
  columnDefinitions: GridColDef[];
  [x: string]: any;
}

function PlayerTable<DataType>({
  name,
  players,
  columnDefinitions,
  ...rest
}: PropsWithChildren<Props<DataType>>) {

  return (
    <TableWithSelectableColumns
      name={name}
      theme={theme}
      columnDefinitions={columnDefinitions}
      rows={players}
      initialColumnVisibilitySettings={initColumnVisibilitySettings}
      {...rest}
    />
  );
}

export default PlayerTable;
