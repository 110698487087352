import { useQuery } from "@tanstack/react-query";
import ms from "ms";
import { FilterSpecification } from "../generated";
import {useFilterSpecificationStore, usePlayerProfileStore, useSimilarPlayersFilterSpecificationStore} from "../store/store";
import { getFilterSpecApi } from "../util/keycloak";

const fetchAllFilters = async (): Promise<FilterSpecification[]> => {
  const api = getFilterSpecApi();
  return api.getFilterSpecification();
};

const fetchSimilarPlayersFilters = async (id: number, position: string, date: Date,): Promise<FilterSpecification[]> => {
  const api = getFilterSpecApi();
  return api.getSimilarPlayersFilterSpecification(id, position, date);
};

export const useFilterSpecification = () => {
  const { data, error, ...rest } = useQuery<FilterSpecification[], Error>(
    ["filterSpecification"],
    fetchAllFilters,
    {
      onSuccess: (filterSpecifications) => {
        useFilterSpecificationStore
          .getState()
          .setFilterSpecifications(filterSpecifications);
      },
      staleTime: ms("24h"),
    }
  );

  return { data, error, ...rest };
};

export const useSimilarPlayersFilterSpecification = (id: number, position: string | undefined) => {
  const { date } = usePlayerProfileStore();

  const { data, error, ...rest } = useQuery<FilterSpecification[], Error>(
    ["filterSpecification/similarPlayers", id, position, date],
    () => fetchSimilarPlayersFilters(id, position!, date),
    {
      onSuccess: (filterSpecifications) => {
        useSimilarPlayersFilterSpecificationStore
          .getState()
          .setFilterSpecifications(filterSpecifications);
      },
      enabled: position !== undefined,
    }
  );

  return { data, error, ...rest };
};

////////////////////////////////////////////////////////////////////////

const fetchTextMenuFilterInfo = async (
  input: string,
  filter: string
): Promise<string[]> => {
  const api = getFilterSpecApi();

  if (filter == "nationality") {
    return api.getNationality(input);
  } else if (filter == "team") {
    return api.getTeam(input);
  } else if (filter == "league") {
    return api.getLeague(input);
  }
  return [];
};

export const useTextMenuFilterInfo = (input: string, filter: string) => {
  return useQuery<string[], Error>(
    ["textMenuFilterInfo", filter, input],
    () => fetchTextMenuFilterInfo(input, filter),
    {
      staleTime: ms("10h"),
      enabled: !!input,
    }
  );
};
