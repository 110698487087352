import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ms from "ms";
import { ColumnSetting, FilterPreset } from "../generated";
import { getColumnSettingApi, getFilterPresetApi } from "../util/keycloak";

const saveOrUpdateColumnSettings = async (columnSetting: ColumnSetting) => {
  const api = getColumnSettingApi();
  return api.saveOrUpdateColumnSetting(columnSetting);
};

export const useSaveOrUpdateColumnSettings = () => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation(saveOrUpdateColumnSettings, {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: ["columnSetting", variables.page],
      });
    },
    onError: (error) => {
      console.error("Error saving column settings:", error);
    },
  });

  return mutate;
};

export const fetchColumnSetting = async (
  page: string
): Promise<ColumnSetting> => {
  const api = getColumnSettingApi();
  return await api.getColumnSetting(page);
};

export const useGetColumnSetting = (page: string) => {
  return useQuery<ColumnSetting, Error>(
    ["columnSetting", page],
    () => fetchColumnSetting(page),
    {
      staleTime: ms("10h"),
    }
  );
};
