import {Box} from "@chakra-ui/react";
import React, {PropsWithChildren, useEffect, useRef} from "react";

interface Props {
  id: string;
  mode?: "vertical" | "horizontal";
  [x:string]: any;
}

const ScrollPortal = ({id, mode = "vertical", children, ...rest}: PropsWithChildren<Props>) => {
  const ref = useRef<HTMLInputElement>(null);

  const classes = mode === "horizontal" ? "scroll-portal scroll-x" : "scroll-portal scroll-y";

  useEffect(() => {
    if (mode === "horizontal") {
      const scrollableContainer = ref.current;
      const handleHorizontalScroll = (e: WheelEvent) => {
        if (!scrollableContainer)
          return;

        if (e.deltaY > 0) {
          scrollableContainer.scrollLeft += 50;
        } else {
          scrollableContainer.scrollLeft -= 50;
        }
        e.preventDefault();
      }

      scrollableContainer?.addEventListener("wheel", handleHorizontalScroll);

      // remove event listener on unmount
      return () => {
        scrollableContainer?.removeEventListener("wheel", handleHorizontalScroll);
      };
    }
  }, [ref]);

  return (
    <Box
      id={id}
      ref={ref}
      className={classes}
      data-cy="scroll-portal-root-container"
      {...rest}
    >
      {children}
    </Box>
  );
};
export default ScrollPortal;
