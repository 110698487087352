import { Box, Checkbox } from "@mui/material";
import { get } from "lodash";
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid-pro";
import { ChangeEvent } from "react";
import { MdOutlineCompareArrows } from "react-icons/md";
import { positionAbbreviation } from "../../util/utilFunctions";
import EyeIconColumn from "../search/playerTable/EyeIconColumn";

import { colorsSecondary, textColors } from "../../colorStyles";
import RatingCellRenderer from "../shadowteams/cellRenderers/RatingCellRenderer";
import StammdatenColumnView from "../search/playerTable/StammdatenColumnView";
import DateCellRenderer from "../shadowteams/cellRenderers/DateCellRenderer";
import HasCommentsIcon from "../shadowteams/table/HasCommentsIcon";
import DeleteIconShadowteamDetails from "../shadowteams/table/DeleteIconShadowteamDetails";
import FavoriteIcon from "../watchlist/FavoriteIcon";
import DeleteIcon from "../watchlist/DeleteIcon";
import NumberCellRenderer from "../shadowteams/cellRenderers/NumberCellRenderer";
import { Player } from "../../generated";
import { LanguageKeys } from "../../language/LanguageKeys";

export const fieldToSortColumn = {
  stammdaten: "surname",
};

export interface LanguageProps {
  guiText: { [key in LanguageKeys]: string }
}

export interface OnOpenModalProps {
  onOpenModal: () => void;
}

export interface OnClickCommentProps {
  onClick: (entry: any) => void;
}

export interface DeletePlayerProps {
  deletePlayerLocal: (playerId: number) => void;
}

export interface SelectedPlayersProps {
  selectedPlayerIds: string[];
}

export interface OnSelectProps {
  onSelect: (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
    id: string
  ) => void;
}

export interface DisabledProps {
  disabled?: (params: any) => boolean;
}

interface GeneralProps {
  props?: Object;
}

export const stammdaten = (
  key: string,
  headerName: string,
  props?: Object,
  borderColorKey?: string,
  onClick?: (player: Player) => void
): GridColDef => {
  return {
    field: "stammdaten",
    headerName: headerName,
    flex: 2,
    disableColumnMenu: true,
    renderCell: (params: GridRenderCellParams) => {
      const val: Player = get(params, key);
      const handleClick = onClick ? () => onClick(val) : undefined;

      return (
        <StammdatenColumnView
          id={val.id}
          idTm={val.idTm}
          firstName={val.firstName}
          surName={val.surname}
          nationality={val.nationality}
          club={val.team}
          league={val.league}
          positionsGruppe={val.positionsGruppe}
          borderColor={borderColorKey ? get(params, borderColorKey) : undefined}
          onClick={handleClick}
        />
      );
    },
    valueGetter: (params: GridValueGetterParams) => {
      const val = get(params, key);
      return val.firstName + " " + val.surname;
    },
    ...props
  }
}

export const positionsGruppe = (key: string, headerName: string, props?: Object): GridColDef => {
  return {
    field: "positionsGruppe",
    headerName: headerName,
    flex: 0.5,
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    renderCell: (params: GridRenderCellParams) => (
      <Box
        component="span"
        sx={{
          p: 0.5,
          border: "1px solid #7E84A3",
          color: textColors.secondary,
          fontWeight: 400,
          fontSize: 12,
          width: 45,
          textAlign: "center",
        }}
      >
        {positionAbbreviation(get(params, key))}
      </Box>
    ),
    valueGetter: (params: GridValueGetterParams) => get(params, key),
    ...props
  }
}

export const age = (key: string, headerName: string, props?: Object): GridColDef => {
  return {
    field: "age",
    headerName: headerName,
    flex: 0.4,
    type: "number",
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    valueGetter: (params: GridValueGetterParams) => get(params, key),
    ...props
  }
}

export const height = (key: string, headerName: string, props?: Object): GridColDef => {
  return {
    field: "height",
    headerName: headerName,
    type: "string",
    flex: 0.5,
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    valueGetter: (params: GridValueGetterParams) => {
      const height = get(params, key);
      return height ? height + "cm" : "-";
    },
    ...props
  }
}

export const marketValue = (key: string, headerName: string, props?: Object): GridColDef => {
  return {
    field: "marketValue",
    headerName: headerName,
    type: "number",
    flex: 0.8,
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    renderCell: (params: GridRenderCellParams) => {
      const val = get(params, key);
      return val || val === 0
        ? Intl.NumberFormat("de-DE", {
          style: "currency",
          currency: "EUR",
          minimumFractionDigits: 0,
          maximumFractionDigits: 1,
        }).format(val)
        : "-"
    },
    valueGetter: (params: GridValueGetterParams) => parseFloat(get(params, key, "0")),
    ...props
  }
}

export const alleyes = (key: string, headerName: string, props?: Object): GridColDef => {
  return {
    field: "alleyes",
    headerName: headerName,
    type: "number",
    flex: 0.5,
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    renderCell: (params: any) => (
      <RatingCellRenderer
        params={params}
        statKey="alleyes"
        maxRating={100}
      />
    ),
    valueGetter: (params: GridValueGetterParams) => get(params, key),
    ...props
  }
}

export const alleyesPotential = (key: string, headerName: string, props?: Object): GridColDef => {
  return {
    field: "alleyesPotential",
    headerName: headerName,
    type: "number",
    flex: 0.5,
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    renderCell: (params: any) => (
      <RatingCellRenderer
        params={params}
        statKey="alleyesPotential"
        maxRating={100}
      />
    ),
    valueGetter: (params: GridValueGetterParams) => get(params, key),
    ...props
  }
}

export const athletisch = (key: string, headerName: string, props?: Object): GridColDef => {
  return {
    field: "athletisch",
    headerName: headerName,
    type: "number",
    flex: 0.5,
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    renderCell: (params: any) => (
      <RatingCellRenderer
        params={params}
        statKey="athletisch"
        maxRating={100}
      />
    ),
    valueGetter: (params: GridValueGetterParams) => get(params, key),
    ...props
  }
}

export const technischPs = (key: string, headerName: string, props?: Object): GridColDef => {
  return {
    field: "technischPs",
    headerName: headerName,
    type: "number",
    flex: 0.6,
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    renderCell: (params: any) => (
      <RatingCellRenderer
        params={params}
        statKey="technischPs"
        maxRating={100}
      />
    ),
    valueGetter: (params: GridValueGetterParams) => get(params, key),
    ...props
  }
}

export const technischD = (key: string, headerName: string, props?: Object): GridColDef => {
  return {
    field: "technischD",
    headerName: headerName,
    type: "number",
    flex: 0.6,
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    renderCell: (params: any) => (
      <RatingCellRenderer
        params={params}
        statKey="technischD"
        maxRating={100}
      />
    ),
    valueGetter: (params: GridValueGetterParams) => get(params, key),
    ...props
  }
}

export const taktischRun = (key: string, headerName: string, props?: Object): GridColDef => {
  return {
    field: "taktischRun",
    headerName: headerName,
    type: "number",
    flex: 0.6,
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    renderCell: (params: any) => (
      <RatingCellRenderer
        params={params}
        statKey="taktischRun"
        maxRating={100}
      />
    ),
    valueGetter: (params: GridValueGetterParams) => get(params, key),
    ...props
  }
}

export const taktischPasses = (key: string, headerName: string, props?: Object): GridColDef => {
  return {
    field: "taktischPasses",
    headerName: headerName,
    type: "number",
    flex: 0.7,
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    renderCell: (params: any) => (
      <RatingCellRenderer
        params={params}
        statKey="taktischPasses"
        maxRating={100}
      />
    ),
    valueGetter: (params: GridValueGetterParams) => get(params, key),
    ...props
  }
}

export const subjectiveOverall = (key: string, headerName: string, props?: Object): GridColDef => {
  return {
    field: "subjectiveOverall",
    headerName: headerName,
    type: "number",
    flex: 0.8,
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    renderCell: (params: any) => (
      <RatingCellRenderer
        params={params}
        statKey="subjectiveOverall"
        maxRating={10}
      />
    ),
    valueGetter: (params: GridValueGetterParams) => get(params, key),
    ...props
  }
}

export const subjectivePotential = (key: string, headerName: string, props?: Object): GridColDef => {
  return {
    field: "subjectivePotential",
    headerName: headerName,
    type: "number",
    flex: 0.8,
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    renderCell: (params: any) => (
      <RatingCellRenderer
        params={params}
        statKey="subjectivePotential"
        maxRating={10}
      />
    ),
    valueGetter: (params: GridValueGetterParams) => get(params, key),
    ...props
  }
}

export const subjectiveGameRatingsOverall = (key: string, headerName: string, props?: Object): GridColDef => {
  return {
    field: "subjectiveGameRatingsOverall",
    headerName: headerName,
    type: "number",
    flex: 0.8,
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    renderCell: (params: any) => (
      <RatingCellRenderer
        params={params}
        statKey="subjectiveGameRatingsOverall"
        maxRating={10}
      />
    ),
    valueGetter: (params: GridValueGetterParams) => get(params, key),
    ...props
  }
}

export const subjectiveGameRatingsPotential = (key: string, headerName: string, props?: Object): GridColDef => {
  return {
    field: "subjectiveGameRatingsPotential",
    headerName: headerName,
    type: "number",
    flex: 0.8,
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    renderCell: (params: any) => (
      <RatingCellRenderer
        params={params}
        statKey="subjectiveGameRatingsPotential"
        maxRating={10}
      />
    ),
    valueGetter: (params: GridValueGetterParams) => get(params, key),
    ...props
  }
}

export const numberOfReviews = (key: string, headerName: string, props?: Object): GridColDef => {
  return {
    field: "numberOfReviews",
    headerName: headerName,
    type: "number",
    flex: 0.7,
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    renderCell: (params: any) => NumberCellRenderer(get(params, key, 0)),
    valueGetter: (params: GridValueGetterParams) => get(params, key, 0),
    ...props
  }
}

export const lastReview = (key: string, headerName: string, props?: Object): GridColDef => {
  return {
    field: "lastReview",
    headerName: headerName,
    type: "date",
    flex: 0.8,
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    renderCell: (params: any) => DateCellRenderer(new Date(get(params, key))),
    valueGetter: (params: GridValueGetterParams) => new Date(get(params, key)),
    ...props
  }
}

export const contractExpiryDate = (key: string, headerName: string, props?: Object): GridColDef => {
  return {
    field: "contractExpiryDate",
    headerName: headerName,
    type: "date",
    flex: 0.8,
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    renderCell: (params: any) => DateCellRenderer(new Date(get(params, key))),
    valueGetter: (params: GridValueGetterParams) => new Date(get(params, key)),
    ...props
  }
}

export const added = (key: string, headerName: string, props?: Object): GridColDef => {
  return {
    field: "added",
    headerName: headerName,
    type: "date",
    flex: 0.8,
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    renderCell: (params: any) => DateCellRenderer(new Date(get(params, key))),
    valueGetter: (params: GridValueGetterParams) => new Date(get(params, key)),
    ...props
  }
}

export const comment = (key: string, { onClick, props }: OnClickCommentProps & GeneralProps): GridColDef => {
  return {
    field: "comment",
    headerName: "",
    flex: 0.01,
    align: "center",
    disableColumnMenu: true,
    sortable: false,
    renderCell: (params: any) => (
      <HasCommentsIcon
        playerId={get(params, key)}
        onOpen={() => onClick(params.row)}
      />
    ),
    ...props
  }
}

export const deleteFromShadowteam = (key: string, { deletePlayerLocal, props }: DeletePlayerProps & GeneralProps): GridColDef => {
  return {
    field: "delete",
    headerName: "",
    flex: 0.01,
    align: "center",
    disableColumnMenu: true,
    sortable: false,
    renderCell: (params: any) => (
      <DeleteIconShadowteamDetails
        player={get(params, key)}
        deletePlayerLocal={deletePlayerLocal}
      />
    ),
    ...props
  }
}

export const deleteFromWatchlist = (key: string, props?: Object): GridColDef => {
  return {
    field: "delete",
    headerName: "",
    flex: 0.1,
    align: "center",
    disableColumnMenu: true,
    sortable: false,
    renderCell: (params) => <DeleteIcon player={get(params, key)} />,
    ...props
  }
}

export const compare = (
  { selectedPlayerIds, onSelect, disabled, props }: SelectedPlayersProps & OnSelectProps & DisabledProps & GeneralProps
): GridColDef => {
  return {
    field: "compare",
    flex: 0.1,
    align: "center",
    disableColumnMenu: true,
    sortable: false,
    headerAlign: "center",
    renderHeader: () => <MdOutlineCompareArrows size={20} />,
    renderCell: (params: any) => (
      <Checkbox
        onChange={(e, b) => onSelect(e, b, params.id.toString())}
        checked={selectedPlayerIds.includes(params.id.toString())}
        disabled={disabled ? disabled(params) : false}
        sx={{
          color: textColors.primary, // Color when not selected
          '&.Mui-checked': {
            color: colorsSecondary.accent, // Color when selected
          },
        }}
      />
    ),
    ...props
  }
}

export const watch = (key: string, { onOpenModal, props }: OnOpenModalProps & GeneralProps): GridColDef => {
  return {
    field: "eye",
    headerName: "",
    flex: 0.1,
    align: "center",
    disableColumnMenu: true,
    sortable: false,
    renderCell: (params: { row: any; }) => (
      <EyeIconColumn
        onOpenModal={onOpenModal}
        player={get(params, key)}
      />

    ),
    ...props
  }
}

export const favorite = (key: string, props?: Object): GridColDef => {
  return {
    field: "favorite",
    headerName: "",
    align: "center",
    disableColumnMenu: true,
    sortable: true,
    flex: 0.1,
    valueGetter: (params: GridValueGetterParams) => params.row.favorite,
    renderCell: (params) => (
      <FavoriteIcon
        player={get(params, key)}
        favorite={params.row.favorite}
      />
    ),
    ...props
  }
}

export const similarity = (key: string, headerName: string, props?: Object): GridColDef => {
  return {
    field: "similarity",
    headerName: headerName,
    type: "number",
    flex: 0.5,
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    hideable: false,
    renderCell: (params: any) => {
      const val = 100 * get(params, key, 0);
      return (val !== 0) && NumberCellRenderer(val, 1) + " %"
    },
    valueGetter: (params: GridValueGetterParams) => get(params, key, 0),
    ...props
  }
}
