import { Box, HStack, Icon, Text } from "@chakra-ui/react";
import { Select, SingleValue } from "chakra-react-select";
import { useContext, useEffect, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { FilterSpecification } from "../../../generated";
import { FilterValueStoreCore } from "../../../store/store";
import { filterOpacity } from "../searchStyles";
import { LocaleKeys } from "../../../language/LocaleKeys";
import { LanguageContext } from "../../../store/languageContext";
import { textColors } from "../../../colorStyles";
import { text } from "d3";

interface Props {
  filter: FilterSpecification;
  filterValueFunc: FilterValueStoreCore;
}

interface Specification {
  options: string[];
  allowMultiple: boolean;
  default?: string;
}

const MenuFilter = ({ filter, filterValueFunc }: Props) => {
  const { language } = useContext(LanguageContext)
  const filterSpecification = JSON.parse(filter.specification) as Specification;
  const [filterValue, setFilterValue] = useState<
    readonly { value: string; label: string }[]
  >([]);

  useEffect(() => {
    const filterString = filterValueFunc.filterValues[filter.specId - 1]?.value;
    if (filterString !== null && filterString !== undefined) {
      const parsedFilter = JSON.parse(filterString);
      setFilterValue(parsedFilter.values);
    } else {
      setFilterValue([]);
    }
  }, [filterValueFunc.filterValues[filter.specId - 1]]);

  const active = filterValue.length > 0;

  const transformedOptions = filterSpecification.options.map((option) => ({
    value: option,
    label: option,
  }));

  const handleChange = (
    newValue:
      | ReadonlyArray<{ value: string; label: string }>
      | SingleValue<{ value: string; label: string }>
      | null
  ) => {
    if (newValue) {
      if (newValue instanceof Array) {
        setFilterValue(newValue);

        if (newValue.length === 0) {
          filterValueFunc.removeFilter(filter.specId);
        } else {
          filterValueFunc.addFilter({
            specId: filter.specId,
            value: `{"values":${JSON.stringify(newValue)}}`,
          });
        }
      } else {
        setFilterValue([newValue]);
        filterValueFunc.addFilter({
          specId: filter.specId,
          value: `{"values":${JSON.stringify([newValue])}}`,
        });
      }
    } else {
      setFilterValue([]);
    }
  };

  useEffect(() => {
    const defaultValue = filterSpecification.default;
    if (defaultValue !== undefined) {
      handleChange({
        value: defaultValue.toLowerCase().replace(/\s+/g, "_"),
        label: defaultValue
      });
    }
  }, [])

  return (
    <Box pb={3} data-cy={"selectFilter"}>
      <HStack
        marginBottom={2}
        justifyContent="space-between"
        opacity={active ? filterOpacity.active : filterOpacity.notActive}
      >
        <Text fontSize="md" color={textColors.primary}>{language == LocaleKeys.DE ? filter.labelGerman : language == LocaleKeys.EN ? filter.labelEnglish : filter.labelSpanish}</Text>
        <Icon
          as={AiFillCloseCircle}
          color="gray.500"
          style={{ cursor: "pointer" }}
          onClick={() => filterValueFunc.removeFilter(filter.specId)}
          boxSize={4}
          data-cy={"resetButton"}
        ></Icon>
      </HStack>

      <Select
        isMulti={filterSpecification.allowMultiple}
        options={transformedOptions}
        placeholder={language == LocaleKeys.DE ? filter.labelGerman : language == LocaleKeys.EN ? filter.labelEnglish : filter.labelSpanish}
        closeMenuOnSelect={true}
        value={filterValue}
        onChange={handleChange}

      />
    </Box>
  );
};

export default MenuFilter;
