import { IconButton } from "@mui/material";
import { AiFillCloseCircle } from "react-icons/ai";
import { Player } from "../../../generated";

interface Props {
  player: Player;
  deletePlayerLocal: (playerId: number) => void;
}

const DeleteIconShadowteamDetails = ({ player, deletePlayerLocal }: Props) => {
  return (
    <IconButton
      aria-label="Add to Watchlist or ShadowTeam"
      onClick={() => deletePlayerLocal(player.id)}
    >
      <AiFillCloseCircle size="20px" color="#8a8a8a" />
    </IconButton>
  );
};

export default DeleteIconShadowteamDetails;
