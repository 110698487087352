import { useQuery } from "@tanstack/react-query";
import ms from "ms";
import { User } from "../generated";
import { getUserApi } from "../util/keycloak";
const fetchUsersByName = async (
  name: string,
  exclude: string[]
): Promise<User[]> => {
  const api = getUserApi();
  return api.getUsersByName(name, exclude);
};

export const useUsersByName = (name: string, exclude: string[]) => {
  return useQuery<User[], Error>(
    ["usersByName", name],
    () => fetchUsersByName(name, exclude),
    {
      enabled: !!name,
    }
  );
};
