import { Box } from "@chakra-ui/react";
import { GridColDef, GridPinnedColumns, GridPinnedRowsProp } from "@mui/x-data-grid-pro";
import { ChangeEvent, useContext } from "react";
import { useParams } from "react-router-dom";
import { Player } from "../../../generated";
import { usePlayerWithDate, useSimilarPlayers } from "../../../hooks/usePlayer";
import { useComparisonStore, usePlayerProfileHistoryStore } from "../../../store/store";
import {
  LanguageProps,
  OnOpenModalProps,
  OnSelectProps,
  SelectedPlayersProps,
  age,
  athletisch,
  compare,
  height,
  lastReview,
  marketValue,
  numberOfReviews,
  positionsGruppe,
  similarity,
  stammdaten,
  alleyes,
  subjectiveGameRatingsOverall,
  subjectiveGameRatingsPotential,
  subjectiveOverall,
  subjectivePotential,
  taktischPasses,
  taktischRun,
  technischD,
  technischPs,
  watch,
} from "../../general/ColumnDefinitions";
import PlayerTable, { playerId } from "../../general/PlayerTable";
import { LanguageKeys } from "../../../language/LanguageKeys";
import { LanguageContext } from "../../../store/languageContext";

const initColumnVisibilitySettings = {
  stammdaten: true,
  positionsGruppe: false,
  age: true,
  height: false,
  marketValue: true,
  alleyes: true,
  athletisch: true,
  technischPs: true,
  technischD: true,
  taktischRun: true,
  taktischPass: true,
  subjectiveOverall: false,
  subjectivePotential: false,
  subjectiveGameRatingsOverall: false,
  subjectiveGameRatingsPotential: false,
  numberOfReviews: false,
  lastReview: false,
  compare: true,
  watch: true,
  similiarty: true
};

const columns = (
  onClickPlayer: (...args: any[]) => void,
  {
    onOpenModal,
    onSelect,
    selectedPlayerIds,
    guiText
  }: OnOpenModalProps & OnSelectProps & SelectedPlayersProps & LanguageProps,
): GridColDef[] => {
  return [
    stammdaten("row", guiText[LanguageKeys.SpielerprofilStammdaten], { sortable: false }, undefined, onClickPlayer),
    positionsGruppe("value", guiText[LanguageKeys.SearchBarPosition], { sortable: false }),
    age("row.age", guiText[LanguageKeys.SearchBarAlter], { sortable: false }),
    height("row.height", guiText[LanguageKeys.SearchBarKorpergrobe], { sortable: false }),
    marketValue("row.marketValue", guiText[LanguageKeys.SearchBarMarktwert], { sortable: false }),
    alleyes("row.alleyes", guiText[LanguageKeys.SearchBarAlleyesAktuell], { sortable: false }),
    athletisch("row.athletisch", guiText[LanguageKeys.SpielerprofilAthletisch], { sortable: false }),
    technischPs("row.technischPs", guiText[LanguageKeys.TechnischnPS], { sortable: false }),
    technischD("row.technischD", guiText[LanguageKeys.TechnischnDuelle], { sortable: false }),
    taktischRun("row.taktischRun", guiText[LanguageKeys.TaktischnRun], { sortable: false }),
    taktischPasses("row.taktischPasses", guiText[LanguageKeys.TaktischnPass], { sortable: false }),
    subjectiveOverall("row.subjectiveOverall", guiText[LanguageKeys.GesamtbewertungA], { sortable: false }),
    subjectivePotential("row.subjectivePotential", guiText[LanguageKeys.GesamtbewertungP], { sortable: false }),
    subjectiveGameRatingsOverall("row.subjectiveGameRatingsOverall", guiText[LanguageKeys.SpielbewertungA], {
      sortable: false,
    }),
    subjectiveGameRatingsPotential("row.subjectiveGameRatingsPotential", guiText[LanguageKeys.SpielbewertungP], {
      sortable: false,
    }),
    numberOfReviews("row.numberOfReviews", guiText[LanguageKeys.WatchlistAnzahlSichtungen], { sortable: false }),
    lastReview("row.lastReview", guiText[LanguageKeys.WatchlistLetzteSichtung], { sortable: false }),
    compare({ selectedPlayerIds, onSelect, props: { hideable: false } }),
    watch("row", { onOpenModal, props: { hideable: false } }),
    similarity("row.similarity", guiText[LanguageKeys.SpielerprofilAhnlichkeit], { sortable: false }),
  ];
};

interface Props {
  position: string;
  onOpenModal: () => void;
  onClickPlayer: (player: Player) => void;
}

const SimilarPlayersTable = ({
  position,
  onOpenModal,
  onClickPlayer,
}: Props) => {
  const { guiText } = useContext(LanguageContext);

  const { id } = useParams();
  const { players: visitedPlayerProfiles } = usePlayerProfileHistoryStore();

  const { data: referencePlayer, isLoading: referencePlayerIsLoading } = usePlayerWithDate(Number(id!), position);
  const { data: players, isLoading } = useSimilarPlayers(Number(id!), position);

  const pinnedRows: GridPinnedRowsProp = referencePlayerIsLoading
    ? {}
    : { top: [{ ...referencePlayer!, pinned: true }] };
  const pinnedCols: GridPinnedColumns = { right: ["similarity"] };

  const {
    players: comparisonPlayers,
    addPlayer,
    deletePlayer,
  } = useComparisonStore();

  const selectedPlayerIds = comparisonPlayers.map(
    (player) => player.id + "_" + player.positionsGruppe
  );

  const onSelect = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
    id: string
  ) => {
    const rowId = id.split("_");

    if (checked && players) {
      const playerToAdd = [...players, referencePlayer].find(
        (player) =>
          player?.id === Number(rowId[0]) && player.positionsGruppe === rowId[1]
      );
      if (playerToAdd) {
        addPlayer(playerToAdd);
      }
    } else {
      deletePlayer(Number(rowId[0]), rowId[1]);
    }
  };

  const renderTable = id !== undefined && !referencePlayerIsLoading && referencePlayer!.id === Number(id)
    && visitedPlayerProfiles.has(id) && Number(visitedPlayerProfiles.get(id)!.id) === referencePlayer!.id
    && visitedPlayerProfiles.get(id)!.tab === 6 && !isLoading;

  return (
    <Box flex="1" overflow="hidden" display="flex" paddingY={1}>
      {renderTable && <PlayerTable
        name="similarPlayers"
        columnDefinitions={columns(onClickPlayer, {
          onOpenModal,
          selectedPlayerIds,
          onSelect,
          guiText
        })}
        players={players ?? []}
        pinnedRows={pinnedRows}
        pinnedColumns={pinnedCols}
        getRowId={(player: Player) => playerId(player)}
        loading={isLoading}
        initialColumnVisibilitySettings={initColumnVisibilitySettings}
      />
      }
    </Box>
  );
};

export default SimilarPlayersTable;
