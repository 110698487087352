import { colorsPrimary, colorsSecondary, textColors } from "../colorStyles";
import "../fontStyles"; // Import the font styles
import { PolarChartComparisonData, PolarChartData } from "../generated";
import { getRatingColor } from "../util/utilFunctions";
import { GuiTextType, LanguageContext } from "../store/languageContext";
import { LanguageKeys } from "../language/LanguageKeys";
import { useContext } from "react";
import { useFilterSpecification } from "./useFilterSpecification";
import { borderColor } from "@mui/system";
import { ScriptableContext } from 'chart.js';
import zIndex from "@mui/material/styles/zIndex";
import { BsBorderWidth } from "react-icons/bs";
const prefixes = [
  "athletisch",
  "technischPs",
  "technischD",
  "taktischRun",
  "taktischPasses",
];

type InnerMappingType = {
  [key: string]: string[];
};

// Define a type for the nameMapping object
type NameMappingType = {
  [category: string]: InnerMappingType;
};

type ReverseMappingType = {
  [key: string]: string;
}

export const NameMapping = (guiText: GuiTextType): NameMappingType => {
  return {
    athletisch: {
      athletischDistanz: guiText[LanguageKeys.SpielerprofilDistanz].split("\n"),
      athletischAktivitaetInBallbesitz: guiText[LanguageKeys.SpielerprofilAktivitatInBallbesitz].split("\n"),
      athletischAktivitaetGegenDenBall: guiText[LanguageKeys.SpielerprofilAktivitatGegenDenBall].split("\n"),
      athletischIntensitaetMitBall: guiText[LanguageKeys.SpielerprofilIntensitatInBallbesitz].split("\n"),
      athletischIntensitaetGegenDenBall: guiText[LanguageKeys.SpielerprofilIntensitatGegenDenBall].split("\n"),
      athletischSprints: guiText[LanguageKeys.SpielerprofilSprints].split("\n"),
      athletischTempolaeufe: guiText[LanguageKeys.SpielerprofilTempolaufe].split("\n"),
      athletischExplosivitaet: guiText[LanguageKeys.SpielerprofilExplosivitat].split("\n"),
      athletischMaxSpeed: guiText[LanguageKeys.SpielerprofilMaxSpeed].split("\n"),
    },
    technischPs: {
      technischPsPaesseAnzahl: guiText[LanguageKeys.SpielerprofilAnzahlPasse].split("\n"),
      technischPsPaesse: guiText[LanguageKeys.SpielerprofilPassqualitat].split("\n"),
      technischPsPassenUnterMittleremDruck: guiText[LanguageKeys.SpielerprofilPasseeunterMittleremDruck].split("\n"),
      technischPsPassenUnterHohemDruck: guiText[LanguageKeys.SpielerprofilPasseeunterHohemDruck].split("\n"),
      technischPsFlanken: guiText[LanguageKeys.SpielerprofilFlankenqualitat].split("\n"),
      technischPsTorgefahrSchuesseAnzahl: guiText[LanguageKeys.SpielerprofilAnzahlSchusse].split("\n"),
      technischPsChancenqualitaet: guiText[LanguageKeys.SpielerprofilChancenqualitat].split("\n"),
      technischPsTorgefahrSchuesse: guiText[LanguageKeys.SpielerprofilAbschlussqualitat].split("\n"),
      technischPsBessermacher: guiText[LanguageKeys.SpielerprofilBessermacher].split("\n"),
    },
    technischD: {
      technischDDribbling: guiText[LanguageKeys.SpielerprofilDribbling].split("\n"),
      technischDBodenzweikampfInBallbesitz: guiText[LanguageKeys.SpielerprofilBodenzweikampfInBallbesitz].split("\n"),
      technischDLuftzweikampfInBallbesitz: guiText[LanguageKeys.SpielerprofilLuftzweikampfInBallbesitz].split("\n"),
      technischDBallbehauptungInBallbesitzMittlererDruck: guiText[LanguageKeys.SpielerprofilBallbehauptungUnterMittleremDruck].split("\n"),
      technischDBallbehauptungInBallbesitzHoherDruck: guiText[LanguageKeys.SpielerprofilBallbehauptungUnterHohemDruck].split("\n"),
      technischDAbwehrGgDribbler: guiText[LanguageKeys.SpielerprofilAbwehrGegenDribbling].split("\n"),
      technischDBodenzweikampfGegenDenBall: guiText[LanguageKeys.SpielerprofilBodenzweikampfGegenDenBall].split("\n"),
      technischDLuftzweikampfGegenDenBall: guiText[LanguageKeys.SpielerprofilLuftzweikampfGegenDenBall].split("\n"),
      technischD2Baelle: guiText[LanguageKeys.SpielerprofilZweiteBalle].split("\n"),
    },
    taktischRun: {
      taktischRunAbkippen: guiText[LanguageKeys.SpielerprofilAbkippen].split("\n"),
      taktischRunNachruecken: guiText[LanguageKeys.SpielerprofilNachrucken].split("\n"),
      taktischRunEntgegenkommen: guiText[LanguageKeys.SpielerprofilEntgegenkommen].split("\n"),
      taktischRunHinterlaufen: guiText[LanguageKeys.SpielerprofilHinterlaufen].split("\n"),
      taktischRunLaufInDieHalbspur: guiText[LanguageKeys.SpielerprofilLaufInDieHalbspur].split("\n"),
      taktischRunFreilaufenInDieBreite: guiText[LanguageKeys.SpielerprofilFreilaufenInDieBreite].split("\n"),
      taktischRunFreilaufenVorDerGegnKette: guiText[LanguageKeys.SpielerprofilFreilaufenVorDerGegnerischenKette].split("\n"),
      taktischRunTiefenlaeufeHinterDieKette: guiText[LanguageKeys.SpielerprofilTiefenlaufeHinterDieKette].split("\n"),
      taktischRunLaufInDieBox: guiText[LanguageKeys.SpielerprofilLaufInDieBox].split("\n"),
    },
    taktischPasses: {
      taktischPassesAbkippen: guiText[LanguageKeys.SpielerprofilPassZumAbkippenden].split("\n"),
      taktischPassesNachruecken: guiText[LanguageKeys.SpielerprofilPassZumNachruckenden].split("\n"),
      taktischPassesEntgegenkommen: guiText[LanguageKeys.SpielerprofilPassZumEntgegenkommenden].split("\n"),
      taktischPassesHinterlaufen: guiText[LanguageKeys.SpielerprofilPassZumHinterlaufenden].split("\n"),
      taktischPassesLaufInDieHalbspur: guiText[LanguageKeys.SpielerprofilPassInDieHalbspur].split("\n"),
      taktischPassesFreilaufenInDieBreite: guiText[LanguageKeys.SpielerprofilPassInDieBreite].split("\n"),
      taktischPassesFreilaufenVorDerGegnKette: guiText[LanguageKeys.SpielerprofilPassVorDerGegnerischenKette].split("\n"),
      taktischPassesTiefenlaeufeHinterDieKette: guiText[LanguageKeys.SpielerprofilPassHinterDieKette].split("\n"),
      taktischPassesLaufInDieBox: guiText[LanguageKeys.SpielerprofilFlankeInDieBox].split("\n"),
    },
  }
};

export const ReverseMapping = (guiText: GuiTextType): ReverseMappingType => {
  const reverseMapping: ReverseMappingType = {};
  const nameMapping = NameMapping(guiText)

  for (const category of Object.keys(nameMapping)) {
    for (const [originalKey, names] of Object.entries(nameMapping[category])) {
      names.forEach((name: string) => {
        if (names.length > 1) {
          reverseMapping[names.join(" ")] = originalKey;
          return;
        }
        reverseMapping[name] = originalKey;
      });
    }
  }
  return reverseMapping
};

const KpiMapping = (guiText: GuiTextType): any => {
  return {
    [guiText[LanguageKeys.SpielerprofilAthletisch]]: {
      "Central Defender": {
        green: [
          guiText[LanguageKeys.SpielerprofilExplosivitat].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilMaxSpeed].replace("\n", " "),
        ],
        grau: [],
      },

      "Full Back": {
        green: [
          guiText[LanguageKeys.SpielerprofilSprints].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilTempolaufe].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilExplosivitat].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilMaxSpeed].replace("\n", " "),
        ],
        grau: [
          guiText[LanguageKeys.SpielerprofilSprints].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilTempolaufe].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilExplosivitat].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilMaxSpeed].replace("\n", " "),
        ],
      },
      "Defensive Midfield": {
        green: [
          guiText[LanguageKeys.SpielerprofilAktivitatInBallbesitz].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilAktivitatGegenDenBall].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilIntensitatGegenDenBall].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilExplosivitat].replace("\n", " "),
        ],
        grau: [],
      },
      "Central Midfield": {
        green: [
          guiText[LanguageKeys.SpielerprofilDistanz].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilAktivitatInBallbesitz].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilAktivitatGegenDenBall].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilIntensitatInBallbesitz].replace("\n", " "),
        ],
        grau: [
          guiText[LanguageKeys.SpielerprofilSprints].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilExplosivitat].replace("\n", " "),
        ],
      },
      Winger: {
        green: [
          guiText[LanguageKeys.SpielerprofilSprints].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilExplosivitat].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilMaxSpeed].replace("\n", " "),
        ],
        grau: [],
      },
      Forward: {
        green: [
          guiText[LanguageKeys.SpielerprofilDistanz].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilAktivitatInBallbesitz].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilIntensitatGegenDenBall].replace("\n", " "),
        ],
        grau: [
          guiText[LanguageKeys.SpielerprofilSprints].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilExplosivitat].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilMaxSpeed].replace("\n", " ")
        ],
      },
      Goalkeeper: {
        green: [],
        grau: [],
      },
    },

    [guiText[LanguageKeys.SpielerprofilTechnischPassenScoren]]: {
      "Central Defender": {
        green: [
          guiText[LanguageKeys.SpielerprofilPassqualitat].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilBessermacher].replace("\n", " "),
        ],
        grau: [],
      },

      "Full Back": {
        green: [
          guiText[LanguageKeys.SpielerprofilPassqualitat].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilBessermacher].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilFlankenqualitat].replace("\n", " "),
        ],
        grau: [
          guiText[LanguageKeys.SpielerprofilPassqualitat].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilBessermacher].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilFlankenqualitat].replace("\n", " "),
        ],
      },
      "Defensive Midfield": {
        green: [
          guiText[LanguageKeys.SpielerprofilPassqualitat].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilAnzahlPasse].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilPasseeunterMittleremDruck].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilPasseeunterHohemDruck].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilBessermacher].replace("\n", " "),
        ],
        grau: [],
      },
      "Central Midfield": {
        green: [
          guiText[LanguageKeys.SpielerprofilPassqualitat].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilAnzahlPasse].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilPasseeunterMittleremDruck].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilPasseeunterHohemDruck].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilBessermacher].replace("\n", " "),
        ],
        grau: [
          guiText[LanguageKeys.SpielerprofilPassqualitat].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilAbschlussqualitat].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilAnzahlSchusse].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilBessermacher].replace("\n", " "),
        ],
      },
      Winger: {
        green: [
          guiText[LanguageKeys.SpielerprofilFlankenqualitat].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilAbschlussqualitat].replace("\n", " "),
        ],
        grau: [],
      },
      Forward: {
        green: [
          guiText[LanguageKeys.SpielerprofilAbschlussqualitat].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilAnzahlSchusse].replace("\n", " "),
        ],
        grau: [
          guiText[LanguageKeys.SpielerprofilAbschlussqualitat].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilAnzahlSchusse].replace("\n", " "),
        ],
      },
      Goalkeeper: {
        green: [],
        grau: [],
      },
    },
    [guiText[LanguageKeys.SpielerprofilTechnischDuelle]]: {
      "Central Defender": {
        green: [
          guiText[LanguageKeys.SpielerprofilAbwehrGegenDribbling].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilBodenzweikampfGegenDenBall].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilLuftzweikampfGegenDenBall].replace("\n", " "),
        ],
        grau: [],
      },

      "Full Back": {
        green: [
          guiText[LanguageKeys.SpielerprofilAbwehrGegenDribbling].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilBodenzweikampfGegenDenBall].replace("\n", " "),
        ],
        grau: [
          guiText[LanguageKeys.SpielerprofilAbwehrGegenDribbling].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilBodenzweikampfGegenDenBall].replace("\n", " "),
        ],
      },
      "Defensive Midfield": {
        green: [
          guiText[LanguageKeys.SpielerprofilBallbehauptungUnterMittleremDruck].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilAbwehrGegenDribbling].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilBodenzweikampfGegenDenBall].replace("\n", " "),
        ],
        grau: [],
      },
      "Central Midfield": {
        green: [
          guiText[LanguageKeys.SpielerprofilBallbehauptungUnterMittleremDruck].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilBallbehauptungUnterHohemDruck].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilBodenzweikampfGegenDenBall].replace("\n", " "),
          "2. Bälle",
        ],
        grau: [
          guiText[LanguageKeys.SpielerprofilDribbling].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilBodenzweikampfInBallbesitz].replace("\n", " "),
        ],
      },
      Winger: {
        green: [
          guiText[LanguageKeys.SpielerprofilDribbling].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilBodenzweikampfInBallbesitz].replace("\n", " "),
        ],
        grau: [],
      },
      Forward: {
        green: [
          guiText[LanguageKeys.SpielerprofilLuftzweikampfInBallbesitz].replace("\n", ""),
          guiText[LanguageKeys.SpielerprofilBodenzweikampfInBallbesitz].replace("\n", ""),
          guiText[LanguageKeys.SpielerprofilZweiteBalle].replace("\n", ""),
        ],
        grau: [
          guiText[LanguageKeys.SpielerprofilDribbling].replace("\n", ""),
          guiText[LanguageKeys.SpielerprofilBallbehauptungUnterHohemDruck].replace("\n", ""),
        ],
      },
      Goalkeeper: {
        green: [],
        grau: [],
      },
    },
    [guiText[LanguageKeys.SpielerprofilTaktischFreilaufverhalten]]: {
      "Central Defender": {
        green: [
          guiText[LanguageKeys.SpielerprofilAbkippen].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilNachrucken].replace("\n", " "),
        ],
        grau: [],
      },

      "Full Back": {
        green: [
          guiText[LanguageKeys.SpielerprofilNachrucken].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilHinterlaufen].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilFreilaufenVorDerGegnerischenKette].replace("\n", " "),
        ],
        grau: [
          guiText[LanguageKeys.SpielerprofilFreilaufenVorDerGegnerischenKette].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilTiefenlaufeHinterDieKette].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilLaufInDieBox].replace("\n", " "),
        ],
      },
      "Defensive Midfield": {
        green: [
          guiText[LanguageKeys.SpielerprofilNachrucken].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilEntgegenkommen].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilFreilaufenVorDerGegnerischenKette].replace("\n", " "),
        ],
        grau: [],
      },
      "Central Midfield": {
        green: [
          guiText[LanguageKeys.SpielerprofilNachrucken].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilEntgegenkommen].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilFreilaufenVorDerGegnerischenKette].replace("\n", " "),
        ],
        grau: [
          guiText[LanguageKeys.SpielerprofilFreilaufenVorDerGegnerischenKette].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilTiefenlaufeHinterDieKette].replace("\n", " "),
        ],
      },
      Winger: {
        green: [
          guiText[LanguageKeys.SpielerprofilFreilaufenVorDerGegnerischenKette].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilTiefenlaufeHinterDieKette].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilLaufInDieBox].replace("\n", " "),
        ],
        grau: [],
      },
      Forward: {
        green: [
          guiText[LanguageKeys.SpielerprofilLaufInDieBox].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilNachrucken].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilEntgegenkommen].replace("\n", " "),
        ],
        grau: [
          guiText[LanguageKeys.SpielerprofilFreilaufenVorDerGegnerischenKette].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilTiefenlaufeHinterDieKette].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilLaufInDieBox].replace("\n", " "),
        ],
      },
      Goalkeeper: {
        green: [],
        grau: [],
      },
    },
    [guiText[LanguageKeys.SpielerprofilTaktischPassverhalten]]: {
      "Central Defender": {
        green: [
          guiText[LanguageKeys.SpielerprofilPassZumAbkippenden].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilPassZumEntgegenkommenden].replace("\n", " "),
        ],
        grau: [],
      },

      "Full Back": {
        green: [
          guiText[LanguageKeys.SpielerprofilPassZumEntgegenkommenden].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilPassVorDerGegnerischenKette].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilPassHinterDieKette].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilFlankeInDieBox].replace("\n", " "),
        ],
        grau: [
          guiText[LanguageKeys.SpielerprofilPassZumNachruckenden].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilPassVorDerGegnerischenKette].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilFlankeInDieBox].replace("\n", " "),
        ],
      },
      "Defensive Midfield": {
        green: [
          guiText[LanguageKeys.SpielerprofilPassVorDerGegnerischenKette].replace("\n", " "),
        ],
        grau: [],
      },
      "Central Midfield": {
        green: [
          guiText[LanguageKeys.SpielerprofilPassVorDerGegnerischenKette].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilPassHinterDieKette].replace("\n", " "),
        ],
        grau: [
          guiText[LanguageKeys.SpielerprofilPassZumHinterlaufenden].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilPassHinterDieKette].replace("\n", " "),
        ],
      },
      Winger: {
        green: [
          guiText[LanguageKeys.SpielerprofilPassZumNachruckenden].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilPassVorDerGegnerischenKette].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilPassHinterDieKette].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilFlankeInDieBox].replace("\n", " "),
        ],
        grau: [],
      },
      Forward: {
        green: [
          guiText[LanguageKeys.SpielerprofilPassZumNachruckenden].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilPassVorDerGegnerischenKette].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilPassHinterDieKette].replace("\n", " "),
        ],
        grau: [
          guiText[LanguageKeys.SpielerprofilPassZumNachruckenden].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilPassVorDerGegnerischenKette].replace("\n", " "),
          guiText[LanguageKeys.SpielerprofilPassHinterDieKette].replace("\n", " "),
        ],
      },
      Goalkeeper: {
        green: [],
        grau: [],
      },
    },
  }
};

export const innerLabel = {
  id: "innerLabel",
  afterDraw(chart: any, args: any, pluginOptions: any) {
    const { ctx, chartArea: { top, bottom, left, right }, scales: { r } } = chart;
    const centerX = r.xCenter;
    const centerY = r.yCenter;

    const circleColor = pluginOptions.color || "white"; // Default to white if not provided
    const labelText = pluginOptions.value
      ? Math.round(pluginOptions.value)
      : "-";

    // Draw the circle
    const radius = 17; // Adjust the radius as needed
    ctx.save();
    ctx.beginPath();
    ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);
    ctx.fillStyle = circleColor; // Change the color to your preference
    ctx.fill();

    // Draw the text in the center of the circle
    ctx.fillStyle = "white"; // Text color
    ctx.textAlign = "center";
    ctx.textBaseline = "middle"; // This ensures the text is vertically centered
    ctx.font = "650 16px Text_Regular";

    ctx.fillText(labelText, centerX, centerY);
    ctx.restore();
  }
};

export const gradientPlugin = {
  id: "gradientPlugin",
  beforeDraw: (chart: any) => {
    const ctx = chart.ctx;
    // Define gradient at a higher scope
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, colorsSecondary.accentBabyBlue);
    gradient.addColorStop(1, "black");

    // Assign the created gradient to a property in the chart instance for later use
    chart.gradient = gradient;
  },
};

export const patternPlugin = {
  id: "patternPlugin",
  beforeDraw: (chart: any) => {
    const ctx = chart.ctx;
    // Create a canvas for the pattern
    const patternCanvas = document.createElement("canvas");
    const pctx = patternCanvas.getContext("2d");

    // Set the dimensions of the pattern
    patternCanvas.width = 20;
    patternCanvas.height = 20;

    // Define the style of your pattern
    // Draw vertical stripes
    pctx!.fillStyle = colorsSecondary.accentBabyBlue;
    pctx!.fillRect(0, 0, patternCanvas.width / 2, patternCanvas.height);
    pctx!.fillStyle = colorsSecondary.accentDarkBlue;
    pctx!.fillRect(
      patternCanvas.width / 2,
      0,
      patternCanvas.width / 2,
      patternCanvas.height
    );

    // Create the pattern and set it to repeat
    const pattern = ctx.createPattern(patternCanvas, "repeat");

    // Assign the pattern to a property on the chart instance
    chart.pattern = pattern;
  },
};

export const hoveredLabel = (event: any, chart: any) => {
  const { x, y } = event;
  let index = -1;

  for (let i = 0; i < chart.scales.r._pointLabelItems.length; i++) {
    const { bottom, top, left, right } = chart.scales.r._pointLabelItems[i];

    if (x >= left && x <= right && y >= top && y <= bottom) {
      index = i;
      break;
    }
  }

  if (index === -1) {
    return null;
  }

  return chart.scales.r._pointLabels[index];
}


const playIconCursor = `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1.5em' height='1.5em' viewBox='0 0 24 24'%3E%3Cpath fill='%23000' d='m9.5 15.5l7-4.5l-7-4.5zM8 21v-2H4q-.825 0-1.412-.587T2 17V5q0-.825.588-1.412T4 3h16q.825 0 1.413.588T22 5v12q0 .825-.587 1.413T20 19h-4v2zm-4-4h16V5H4zm0 0V5z'/%3E%3C/svg%3E")`
const optionPreset = (
  ratingOberkategorie: number,
  title: string,
  position: string,
  maintainAspectRatio: boolean,
  animate: boolean,
  kpiHasClip: (index: number) => boolean,
  guiText: GuiTextType
) => {
  const kpiMapping = KpiMapping(guiText);
  return {
    animation: {
      duration: animate ? 1000 : 0,
    },
    responsive: true,
    maintainAspectRatio: maintainAspectRatio,

    onHover: (event: any, chartElement: any[], chart: any) => {
      if (chartElement.length) {
        const x = event.native.offsetX - chart.scales["r"].xCenter;
        const y = event.native.offsetY - chart.scales["r"].yCenter;

        if (Math.pow(x, 2) + Math.pow(y, 2) < 324) {
          chart.canvas.style.cursor = "pointer";
          return;
        }

        if (kpiHasClip(chartElement[0].index)) {
          chart.canvas.style.cursor = `${playIconCursor}, pointer`;
          return;
        }

        chart.canvas.style.cursor = "default";
      } else {
        const label = hoveredLabel(event, chart);

        if (label === null) {
          chart.canvas.style.cursor = "default";
          return;
        }

        chart.canvas.style.cursor = "pointer";
      }
    },

    scales: {
      r: {
        grid: {
          color: colorsSecondary.accentDarkBlue,
          z: 3,
        },
        // startAngle: 150,
        min: 0, // Your desired minimum value
        max: 100, // Your desired maximum value
        pointLabels: {
          backdropColor: (context: any) => {
            const label = context.label
              .map((word: string) => word.trim())
              .join(" ");

            if (
              kpiMapping[title][position].green.includes(label) &&
              !kpiMapping[title][position].grau.includes(label)
            ) {
              return colorsSecondary.accentBabyBlue; // blue backdrop color
            }
            if (
              kpiMapping[title][position].grau.includes(label) &&
              !kpiMapping[title][position].green.includes(label)
            ) {
              return colorsSecondary.accentDarkBlue;
            }
            if (
              kpiMapping[title][position].grau.includes(label) &&
              kpiMapping[title][position].green.includes(label)
            ) {
              return context.chart.pattern; // blue backdrop color
            }
            return "transparent"; // transparent for others
          },
          backdropPadding: {
            x: 5, // horizontal padding
            y: 5, // vertical padding
          },
          display: true,
          centerPointLabels: true,
          font: {
            family: "Text_Regular",
            size: 12,
          },
          color: (context: any) => {
            const label = context.label
              .map((word: string) => word.trim())
              .join(" ");

            if (
              kpiMapping[title][position].grau.includes(label)
            ) {
              return "white"; // blue backdrop color
            }
            if (
              kpiMapping[title][position].green.includes(label)

            ) {
              return textColors.primary;
            }
            return textColors.primary; // transparent for others
          },
          padding: 10,
        },
        ticks: {
          display: false, // Hides the tick values on the radial scale
        },
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    plugins: {
      patternPlugin,
      innerLabel: {
        color: getRatingColor(ratingOberkategorie), // Custom color for the circle
        value: ratingOberkategorie, // Custom text for the label
      },
      legend: {
        // position: "top" as const,
        display: false,
      },
      title: {
        display: true,
        text: title,
        color: textColors.primary,
        font: {
          size: 16,
          family: "Text_Regular",
        },
      },
      tooltip: {
        displayColors: false, // This line prevents the color box from showing next to the label

        filter: function (tooltipItem: any) {
          return tooltipItem.datasetIndex === 0;
        },
        callbacks: {
          label: function (context: any) {
            const datasets = context.chart.data.datasets || [];
            const spieler =
              datasets[3]?.data?.[context.dataIndex];
            const bundesliga1 =
              datasets[1]?.data?.[context.dataIndex];
            const bundesliga2 =
              datasets[2]?.data?.[context.dataIndex];

            return [
              "Spieler: " + (spieler != undefined ? Math.round(spieler) : "-"),
              "Super League: " +
              (bundesliga1 == undefined ? "-" : Math.round(bundesliga1)),
              // "2. Fantasieliga: " +
              // (bundesliga2 == undefined ? "-" : Math.round(bundesliga2)),
            ];
          },
          title: function (context: any) {
            return context.label;
          },
        },
      },
    },
  };
};

const categorizeAttributes = (
  relevantKeys: string[],
  item: PolarChartData
): number[] => {
  // Then, map over these keys to get their values from the item, and replace null with 0
  return relevantKeys.map((key) =>
    item[key as keyof typeof item] === null
      ? 0
      : (item[key as keyof typeof item] as number)
  );
};

const kpiHasClip = (kpiLabels: string[], kpisWithClips: string[], guiText: GuiTextType) => {
  const reverseMapping = ReverseMapping(guiText);
  return (index: number) => {
    const kpiLabel = kpiLabels[index];
    const kpi = (kpiLabel in reverseMapping) && reverseMapping[kpiLabel];
    return kpi ? kpisWithClips.includes(kpi) : false;
  }
}

const usePreparePolarChartData = (
  data: PolarChartData | undefined,
  comparisonData: PolarChartComparisonData | undefined,
  position: string | undefined,
  maintainAspectRatio: boolean,
  animate: boolean,
  kpisWithClips: string[] | undefined
): any => {
  const { guiText } = useContext(LanguageContext);
  const nameMapping = NameMapping(guiText);

  // the order of the columns in the charts is taken from filter specification
  const { data: filter_data } = useFilterSpecification();

  if (data == undefined || position == undefined) return []; // Return early if no data

  const options = [
    optionPreset(
      data.athletisch!,
      guiText[LanguageKeys.SpielerprofilAthletisch],
      position,
      maintainAspectRatio,
      animate,
      kpiHasClip([...Object.values(nameMapping.athletisch).map(val => val.join(" "))], kpisWithClips ?? [], guiText),
      guiText
    ),
    optionPreset(
      data.technischPs!,
      guiText[LanguageKeys.SpielerprofilTechnischPassenScoren],
      position,
      maintainAspectRatio,
      animate,
      kpiHasClip([...Object.values(nameMapping.technischPs).map(val => val.join(" "))], kpisWithClips ?? [], guiText),
      guiText
    ),
    optionPreset(
      data.technischD!,
      guiText[LanguageKeys.SpielerprofilTechnischDuelle],
      position,
      maintainAspectRatio,
      animate,
      kpiHasClip([...Object.values(nameMapping.technischD).map(val => val.join(" "))], kpisWithClips ?? [], guiText),
      guiText
    ),
    optionPreset(
      data.taktischRun!,
      guiText[LanguageKeys.SpielerprofilTaktischFreilaufverhalten],
      position,
      maintainAspectRatio,
      animate,
      kpiHasClip([...Object.values(nameMapping.taktischRun).map(val => val.join(" "))], kpisWithClips ?? [], guiText),
      guiText
    ),
    optionPreset(
      data.taktischPasses!,
      guiText[LanguageKeys.SpielerprofilTaktischPassverhalten],
      position,
      maintainAspectRatio,
      animate,
      kpiHasClip([...Object.values(nameMapping.taktischPasses).map(val => val.join(" "))], kpisWithClips ?? [], guiText),
      guiText
    ),
  ];

  const getComparisonData = (relevantKeys: string[], suffix: string) => {
    if (!comparisonData) return [];
    const relevantKeysWithSuffix = relevantKeys.map(key => key + suffix);
    return relevantKeysWithSuffix.map(
      (key) => comparisonData[key as keyof typeof comparisonData] ?? 0
    );
  };
  function createRadialGradient(ctx: CanvasRenderingContext2D, chartArea: any, colorStart: string, colorEnd: string) {
    const centerX = (chartArea.left + chartArea.right) / 2;
    const centerY = (chartArea.top + chartArea.bottom) / 2;
    const radius = Math.min(chartArea.right - chartArea.left, chartArea.bottom - chartArea.top) / 2;

    const gradient = ctx.createRadialGradient(centerX, centerY, 0, centerX, centerY, radius);
    gradient.addColorStop(0, colorStart);
    gradient.addColorStop(1, colorEnd);
    return gradient;
  }
  // This will return an array of data objects for each prefix.
  const chartDatas = prefixes.map((prefix) => {
    const keyOrder: string[] = filter_data ? filter_data.map(item => item.dbColumn ? item.dbColumn : "") : [];
    const relevantKeys = keyOrder.filter(
      (key) => key.startsWith(prefix) && key != prefix
    );

    const labels = relevantKeys.map(
      (relevantKey: string) => nameMapping[prefix][relevantKey]
    );

    const categorizedAttributes = categorizeAttributes(relevantKeys, data).flat();

    const missingValues = categorizedAttributes.map((item) =>
      item === undefined ? 100 : undefined
    );

    const datasets = [

      {
        data: Array(categorizedAttributes.length).fill(100),
        backgroundColor: "transparent",
        borderWidth: 2,
        borderColor: colorsSecondary.accentDarkBlue,
        borderAlign: "inner",
        hoverBorderColor: colorsSecondary.accent,
      },
      {
        data: getComparisonData(relevantKeys, "1"),
        backgroundColor: "transparent",
        borderWidth: 2,
        borderColor: colorsSecondary.accent,
        hoverBorderColor: colorsSecondary.accent,
      },
      {
        data: getComparisonData(relevantKeys, "2"),
        backgroundColor: "transparent",
        borderWidth: 2,
        borderColor: colorsSecondary.accent,
        hoverBorderColor: colorsSecondary.accent,
      },


      {
        label:
          "removing or changing this label breaks the polarcharts, pls dont change :)",
        data: categorizedAttributes,
        backgroundColor: (ctx: ScriptableContext<'polarArea'>) => {
          const chart = ctx.chart;
          const { ctx: canvasCtx, chartArea } = chart;
          if (!chartArea) {
            // This case happens on initial chart load
            return null;
          }
          return createRadialGradient(canvasCtx, chartArea, colorsSecondary.accentPolarchartStart, colorsSecondary.accentBabyBlue);
        },
        borderWidth: 0,
      },
      {
        label:
          "removing or changing this label breaks the polarcharts, pls dont change :)",
        data: missingValues,
        backgroundColor: "rgba(125, 125, 125, 0.15)",
        borderAlign: "inner",
      },



    ];

    return {
      labels: labels,
      datasets: datasets,
    };
  });

  return { chartDatas, options };
};

export default usePreparePolarChartData;
