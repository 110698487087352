import { LanguageKeys } from "./LanguageKeys";

export const guiText: { [key in LanguageKeys]: string } = {
  [LanguageKeys.LanguageGerman]: "Deutsch",
  [LanguageKeys.LanguageEnglish]: "English",
  [LanguageKeys.LanguageSpanish]: "Español",
  [LanguageKeys.SidebarHome]: "Home",
  [LanguageKeys.SidebarSpielersuche]: "Spielersuche",
  [LanguageKeys.SidebarWatchlist]: "Watchlist",
  [LanguageKeys.SidebarSpielervergleich]: "Spielervergleich",
  [LanguageKeys.SidebarSchattenmannschaften]: "Schattenmannschaften",
  [LanguageKeys.WachlistButtonAdd]: "Add to Watchlist or ShadowTeam",
  [LanguageKeys.SearchBarSpielernamenEingeben]: "Gib einen Spielernamen ein...",
  [LanguageKeys.Greeting]: "Hallo, ",
  [LanguageKeys.HomePriorityPlayers]: "Priority Players",
  [LanguageKeys.HomeNewPlayers]: "New Players",
  [LanguageKeys.HomeMissingReviews]: "Missing Reviews",
  [LanguageKeys.KurzfristigerTrend]: "Kurzfristiger Trend",
  [LanguageKeys.LangfristigerTrend]: "Langfristiger Trend",
  [LanguageKeys.SearchBarFilter]: "Filter",
  [LanguageKeys.SearchBarFilterSpeichern]: "Filter speichern",
  [LanguageKeys.SearchBarZurucksetzen]: "Zurücksetzen",
  [LanguageKeys.SearchBarGespeicherteFilterungAuswahlen]: "Gespeicherte Filterung auswählen",
  [LanguageKeys.SearchBarFilterAusgewahlt]: "x Filter ausgewählt, x Spieler gefunden",
  [LanguageKeys.SearchBarVergleichen]: "Vergleichen",
  [LanguageKeys.SearchBarDieserSpielerIstNochNichtInDeinerWatchlist]: "Dieser Spieler ist noch nicht in deiner Watchlist",
  [LanguageKeys.SearchBarDieserSpielerIstNochInKeinerSchattenmannschaft]: "Dieser Spieler ist noch in keiner Schattenmannschaft",
  [LanguageKeys.SearchBarZurWatchlistHinzufugen]: "Zur Watchlist hinzufügen",
  [LanguageKeys.SearchBarZurSchattenmannschaftHinzufugen]: "Zur Schattenmannschaft hinzufügen",
  [LanguageKeys.SearchBarZurWatchlistOderSchattenmannschaftHinzufugen]: "Zur Watchlist oder Schattenmannschaft hinzufügen",
  [LanguageKeys.SearchBarSchliessen]: "Schließen",
  [LanguageKeys.SearchBarSpielerAktuellesRatingNurMitAktuellemRating]: "Spieler: Aktuelles Rating (Nur mit aktuellem Rating)",
  [LanguageKeys.SearchBarSpielerAlle]: "Spieler: Alle",
  [LanguageKeys.SearchBarSpielerKeinAktuellesRatingNurOhneAktuellesRating]: "Spieler: Kein aktuelles Rating (Nur ohne aktuelles Rating)",
  [LanguageKeys.SearchBarPosition]: "Position",
  [LanguageKeys.SearchBarAlter]: "Alter",
  [LanguageKeys.SearchBarVerein]: "Verein",
  [LanguageKeys.SearchBarLiga]: "Liga",
  [LanguageKeys.SearchBarNationalitat]: "Nationalität",
  [LanguageKeys.SearchBarKorpergrobe]: "Körpergröße",
  [LanguageKeys.SearchBarStarterFuß]: "Starker Fuß",
  [LanguageKeys.Links]: "Links",
  [LanguageKeys.Rechts]: "Rechts",
  [LanguageKeys.Beide]: "Beide",
  [LanguageKeys.SearchBarMarktwert]: "Marktwert",
  [LanguageKeys.SearchBarVertragsendeBis]: "Vertragsende bis",
  [LanguageKeys.SearchBarVertragsakquisationen]: "Vertragsoptionen",
  [LanguageKeys.SearchBarKeineKaufoption]: "Keine Kaufoption",
  [LanguageKeys.SearchBarKeineKaufpflichtMitBedingungen]: "Keine Kaufpflicht mit Bedingungen",
  [LanguageKeys.SearchBarLeihe]: "Leihe",
  [LanguageKeys.SearchBarNurVollstandigeRatings]: "Nur vollständige Ratings",
  [LanguageKeys.SearchBarSubjektiveRatings]: "Subjektive Ratings",
  [LanguageKeys.SearchBarGesamtbewertungAktuell]: "Gesamtbewertung Aktuell",
  [LanguageKeys.SearchBarGesamtbewertungPotential]: "Gesamtbewertung Potential",
  [LanguageKeys.SearchBarSpielbewertungAktuell]: "Spielbewertung Aktuell",
  [LanguageKeys.SearchBarSpielbewertungPotential]: "Spielbewertung Potential",
  [LanguageKeys.SearchBarRatingsOberkategorien]: "Ratings",
  [LanguageKeys.SearchBarAlleyesAktuell]: "Alleyes\nAktuell", // with linebreak
  [LanguageKeys.SearchBarAlleyesPotential]: "Alleyes\nPotential", // with linebreak
  [LanguageKeys.SearchBarAlleyesVorjahr]: "Alleyes Vorjahr",
  [LanguageKeys.SearchBarAlleyesVorjahr2]: "Alleyes Vorjahr 2",
  [LanguageKeys.SearchBarTrendKurzfristig]: "Trend kurzfristig",
  [LanguageKeys.SearchBarTrendLangfristig]: "Trend langfristig",
  [LanguageKeys.SearchBarSportlicheDatenRatingsDetailliert]: "Ratings detailliert",
  [LanguageKeys.SpielerprofilAthletisch]: "Athletisch",
  [LanguageKeys.SpielerprofilDistanz]: "Distanz",
  [LanguageKeys.SpielerprofilAktivitatInBallbesitz]: "Aktivität in\nBallbesitz",
  [LanguageKeys.SpielerprofilAktivitatGegenDenBall]: "Aktivität gegen\nden Ball",
  [LanguageKeys.SpielerprofilIntensitatInBallbesitz]: "Intensität in\nBallbesitz",
  [LanguageKeys.SpielerprofilIntensitatGegenDenBall]: "Intensität gegen\nden Ball",
  [LanguageKeys.SpielerprofilSprints]: "Sprints",
  [LanguageKeys.SpielerprofilTempolaufe]: "Tempoläufe",
  [LanguageKeys.SpielerprofilExplosivitat]: "Explosivität",
  [LanguageKeys.SpielerprofilMaxSpeed]: "Max Speed",
  [LanguageKeys.SpielerprofilTechnischPassenScoren]: "Technisch - Passen & Scoren",
  [LanguageKeys.TechnischnPS]: "Technisch\nP&S",
  [LanguageKeys.SpielerprofilAnzahlPasse]: "Anzahl\nPässe",
  [LanguageKeys.SpielerprofilPassqualitat]: "Passqualität",
  [LanguageKeys.SpielerprofilPasseeunterMittleremDruck]: "Pässe unter\nmittlerem Druck",
  [LanguageKeys.SpielerprofilPasseeunterHohemDruck]: "Pässe unter\nhohem Druck",
  [LanguageKeys.SpielerprofilFlankenqualitat]: "Flankenqualität",
  [LanguageKeys.SpielerprofilAnzahlSchusse]: "Anzahl\nSchüsse",
  [LanguageKeys.SpielerprofilChancenqualitat]: "Chancenqualität",
  [LanguageKeys.SpielerprofilAbschlussqualitat]: "Abschlussqualität",
  [LanguageKeys.SpielerprofilBessermacher]: "Bessermacher",
  [LanguageKeys.SpielerprofilTechnischDuelle]: "Technisch - Duelle",
  [LanguageKeys.TechnischnDuelle]: "Technisch\nDuelle",
  [LanguageKeys.SpielerprofilDribbling]: "Dribbling",
  [LanguageKeys.SpielerprofilBodenzweikampfInBallbesitz]: "Bodenzweikampf\nin Ballbesitz",
  [LanguageKeys.SpielerprofilLuftzweikampfInBallbesitz]: "Luftzweikampf\nin Ballbesitz",
  [LanguageKeys.SpielerprofilBallbehauptungUnterMittleremDruck]: "Ballbehauptung unter\nmittlerem Druck",
  [LanguageKeys.SpielerprofilBallbehauptungUnterHohemDruck]: "Ballbehauptung unter\nhohem Druck",
  [LanguageKeys.SpielerprofilAbwehrGegenDribbling]: "Abwehr\ngegen Dribbling",
  [LanguageKeys.SpielerprofilBodenzweikampfGegenDenBall]: "Bodenzweikampf\ngegen den Ball",
  [LanguageKeys.SpielerprofilLuftzweikampfGegenDenBall]: "Luftzweikampf\ngegen den Ball",
  [LanguageKeys.SpielerprofilZweiteBalle]: "2. Bälle",
  [LanguageKeys.SpielerprofilTaktischFreilaufverhalten]: "Taktisch - Freilaufverhalten",
  [LanguageKeys.TaktischnRun]: "Taktisch\nRun",
  [LanguageKeys.SpielerprofilAbkippen]: "Abkippen",
  [LanguageKeys.SpielerprofilNachrucken]: "Nachrücken",
  [LanguageKeys.SpielerprofilEntgegenkommen]: "Entgegen-\nkommen",
  [LanguageKeys.SpielerprofilHinterlaufen]: "Hinterlaufen",
  [LanguageKeys.SpielerprofilLaufInDieHalbspur]: "Lauf in\ndie Halbspur",
  [LanguageKeys.SpielerprofilFreilaufenInDieBreite]: "Freilaufen in\ndie Breite",
  [LanguageKeys.SpielerprofilFreilaufenVorDerGegnerischenKette]: "Freilaufen vor\nder gegn. Kette",
  [LanguageKeys.SpielerprofilTiefenlaufeHinterDieKette]: "Tiefenläufe\nhinter die Kette",
  [LanguageKeys.SpielerprofilLaufInDieBox]: "Lauf in die Box",
  [LanguageKeys.SpielerprofilTaktischPassverhalten]: "Taktisch - Passverhalten",
  [LanguageKeys.TaktischnPass]: "Taktisch\nPass",
  [LanguageKeys.SpielerprofilPassZumAbkippenden]: "Pass zum Abkippenden",
  [LanguageKeys.SpielerprofilPassZumNachruckenden]: "Pass zum\nNachrückenden",
  [LanguageKeys.SpielerprofilPassZumEntgegenkommenden]: "Pass zum\nEntgegen-\nkommenden",
  [LanguageKeys.SpielerprofilPassZumHinterlaufenden]: "Pass zum\nHinterlaufenden",
  [LanguageKeys.SpielerprofilPassInDieHalbspur]: "Pass in\ndie Halbspur",
  [LanguageKeys.SpielerprofilPassInDieBreite]: "Pass in\ndie Breite",
  [LanguageKeys.SpielerprofilPassVorDerGegnerischenKette]: "Pass vor der\ngegn. Kette",
  [LanguageKeys.SpielerprofilPassHinterDieKette]: "Pass hinter\ndie Kette",
  [LanguageKeys.SpielerprofilFlankeInDieBox]: "Flanke in die Box",
  [LanguageKeys.SpielerprofilPolarchartsRatings]: "Ratings",
  [LanguageKeys.SpielerprofilGeburtsdatum]: "Geburtsdatum",
  [LanguageKeys.SpielerprofilVerletzungstage]: "Verletzungstage",
  [LanguageKeys.SpielerprofilBerater]: "Berater",
  [LanguageKeys.SpielerprofilZumWyScoutProfil]: "Zum WyScout Profil",
  [LanguageKeys.SpielerprofilZum]: "Zum",
  [LanguageKeys.SpielerprofilProfil]: "Profil",
  [LanguageKeys.SpielerprofilMonateDeutsch]: "Monate deutsch (bspw. Okt)",
  [LanguageKeys.SpielerprofilSubjektiveBewertungen]: "Subjektive Bewertungen",
  [LanguageKeys.SpielerprofilSpieler]: "Spieler",
  [LanguageKeys.SpielerprofilEventdatum]: "Eventdatum",
  [LanguageKeys.SpielerprofilSpiel]: "Spiel",
  [LanguageKeys.SpielerprofilScout]: "Scout",
  [LanguageKeys.SpielerprofilOverallRating]: "Gesamtbewertung",
  [LanguageKeys.Gesamtrating]: "Gesamtrating",
  [LanguageKeys.SpielerprofilPotentialRating]: "Potential Rating",
  [LanguageKeys.SpielerprofilJahrgangstyp]: "Jahrgangstyp",
  [LanguageKeys.SpielerprofilZuletztGeandert]: "Zuletzt geändert",
  [LanguageKeys.SpielerprofilPositionImReport]: "Position im Report",
  [LanguageKeys.SpielerprofilSpiele]: "Spiele",
  [LanguageKeys.SpielerprofilMinuten]: "Minuten",
  [LanguageKeys.SpielerprofilTransferhistorie]: "Transferhistorie",
  [LanguageKeys.SpielerprofilTransferMarktwerthistorie]: "Transfer-/ Markthistorie",
  [LanguageKeys.SpielerprofilMarkthistorie]: "Markthistorie",
  [LanguageKeys.SpielerprofilAktuellerMarktwert]: "Aktueller Marktwert",
  [LanguageKeys.SpielerprofilMaximalerMarktwert]: "Maximaler Marktwert",
  [LanguageKeys.SpielerprofilSaison]: "Saison",
  [LanguageKeys.SpielerprofilDatum]: "Datum",
  [LanguageKeys.SpielerprofilAbgebenderVerein]: "Abgebender Verein",
  [LanguageKeys.SpielerprofilAufnehmenderVerein]: "Aufnehmender Verein",
  [LanguageKeys.SpielerprofilVerletzungshistorie]: "Verletzungshistorie",
  [LanguageKeys.SpielerprofilStartVerletzung]: "Start Verletzung",
  [LanguageKeys.SpielerprofilEndeVerletzung]: "Ende Verletzung",
  [LanguageKeys.SpielerprofilDauer]: "Dauer",
  [LanguageKeys.SpielerprofilVerletzung]: "Verletzung",
  [LanguageKeys.SpielerprofilTorschuss]: "Torschuss",
  [LanguageKeys.SpielerprofilHeatmap]: "Heatmap",
  [LanguageKeys.SpielerprofilFuß]: "Fuß",
  [LanguageKeys.SpielerprofilKopf]: "Kopf",
  [LanguageKeys.SpielerprofilTor]: "Tor",
  [LanguageKeys.SpielerprofilVersuch]: "Versuch",
  [LanguageKeys.SpielerprofilLowxG]: "low xG",
  [LanguageKeys.SpielerprofilHighxG]: "high xG",
  [LanguageKeys.SpielerprofilPreShot]: "Pre Shot",
  [LanguageKeys.SpielerprofilPostShot]: "Post Shot",
  [LanguageKeys.SpielerprofilKommentare]: "Kommentare",
  [LanguageKeys.SpielerprofilKeineKommentareVorhanden]: "Keine Kommentare vorhanden",
  [LanguageKeys.SpielerprofilSpeichern]: "Speichern",
  [LanguageKeys.SpielerprofilFugeEinenKommentarHinzu]: "Füge einen Kommentar hinzu...",
  [LanguageKeys.SpielerprofilAhnlicheSpieler]: "Ähnliche Spieler",
  [LanguageKeys.SpielerprofilGewichtung]: "Gewichtung",
  [LanguageKeys.SpielerprofilStammdaten]: "Stammdaten",
  [LanguageKeys.SpielerprofilAhnlichkeit]: "Ähnlichkeit",
  [LanguageKeys.SpielerprofilSpalten]: "Spalten",
  [LanguageKeys.SpielerprofilZeilenProSeite]: "Zeilen pro Seite",
  [LanguageKeys.SpielerprofilVideo]: "Video",
  [LanguageKeys.SpielerprofilVideoNichtVerfugbar]: "Video nicht verfügbar",
  [LanguageKeys.KeinVideovorhanden]: "Kein Video vorhanden",
  [LanguageKeys.WatchlistAnzahlSichtungen]: "Anzahl\nSichtungen", // with linebreak
  [LanguageKeys.WatchlistLetzteSichtung]: "Letzte\nSichtung", // with linebreak
  [LanguageKeys.WatchlistHinzugefugtAm]: "Hinzugefügt\nam", // with linebreak
  [LanguageKeys.SchattenmannschaftenFormation]: "Formation",
  [LanguageKeys.SchattenmannschaftenZumSpielerprofil]: "Zum Spielerprofil",
  [LanguageKeys.SchattenmannschaftenSpielerEntfernen]: "Spieler entfernen",
  [LanguageKeys.SchattenmannschaftenMarkierungWahlen]: "Markierung wählen",
  [LanguageKeys.SchattenmannschaftenAnzahlSpieler]: "Anzahl Spieler",
  [LanguageKeys.SchattenmannschaftenGeteiltMit]: "Geteilt mit",
  [LanguageKeys.SchattenmannschaftenBank]: "Bank",
  [LanguageKeys.SchattenmannschaftenAutor]: "Autor",
  [LanguageKeys.SpielervergleichWarenkorb]: "Warenkorb",
  [LanguageKeys.Glossar]: "Glossar",
  [LanguageKeys.KeinZugriff]: "Kein Zugriff",
  [LanguageKeys.DieseSchattenmannschaftExistiertNicht]: "Diese Schattenmannschaft existiert nicht",
  [LanguageKeys.OderDuHastKeinenZugriff]: "oder du hast keinen Zugriff.",
  [LanguageKeys.NeueSchattenmannschaftErstellen]: "Neue Schattenmannschaft erstellen.",
  [LanguageKeys.SpielerHinzufugen]: "Spieler hinzufügen",
  [LanguageKeys.JetztVergleichen]: "Zum Spielervergleich",
  [LanguageKeys.KeineSpielerimVergleich]: "Keine Spieler im Vergleich",
  [LanguageKeys.SpielerimVergleich]: "Spieler im Vergleich",
  [LanguageKeys.DatenWerdenGeladen]: "Daten werden geladen...",
  [LanguageKeys.Datenkonntennichtgeladenwerden]: "Daten konnten nicht geladen werden.",
  [LanguageKeys.Erstellungsdatum]: "Erstellungsdatum",
  [LanguageKeys.KeineTorschussdatenvorhanden]: "Keine Torschussdaten vorhanden",
  [LanguageKeys.KeineHeatmapdatenvorhanden]: "Keine Heatmapdaten vorhanden",
  [LanguageKeys.KeineVerletzungsdatenvorhanden]: "Keine Verletzungsdaten vorhanden",
  [LanguageKeys.KeineDatenvorhanden]: "Keine Daten vorhanden",
  [LanguageKeys.Gesamt]: "Gesamt",
  [LanguageKeys.WarnungVergleichePositionsbezogen]: "Du vergleichst Spieler auf verschiedenen Positionen. Vergiss nicht, dass alle Daten immer positionsbezogen sind!",
  [LanguageKeys.SpielerinSchattenmannschaftAufPositionOhneDaten]: "Der Spieler befindet sich in der Schattenmannschaft auf einer Position zu der keine Daten vorliegen!",
  [LanguageKeys.SpielerKannNichtHinzugefuegtWerden]: "Spieler kann nicht hinzugefügt werden",
  [LanguageKeys.SpielerbereitsinSchattenmannschaft]: "Dieser Spieler ist bereits in deiner Schattenmannschaft!",
  [LanguageKeys.Achtung]: "Achtung",
  [LanguageKeys.GesamtbewertungA]: "Gesamtbewertung (A)",
  [LanguageKeys.GesamtbewertungP]: "Gesamtbewertung (P)",
  [LanguageKeys.SpielbewertungA]: "Spielbewertung (A)",
  [LanguageKeys.SpielbewertungP]: "Spielbewertung (P)",
  [LanguageKeys.Loeschen]: "Löschen",
  [LanguageKeys.Vertragsende]: "Vertragsende",
  [LanguageKeys.Schattenmannschaft]: "Schattenmannschaft",
  [LanguageKeys.ExportKannMinutenDauern]: "Der Export kann einige Minuten dauern.",
  [LanguageKeys.GenerierePDF]: "Generiere PDF",
  [LanguageKeys.ExportFehlgeschlagen]: "Der Export ist fehlgeschlagen.",
  [LanguageKeys.KeineGesamtbewertungVorhanden]: "Keine Gesamtbewertung vorhanden.",
  [LanguageKeys.KeineSpielbewertungVorhanden]: "Keine Spielbewertung vorhanden.",
  [LanguageKeys.Gesamtbewertung]: "Gesamtbewertung",
  [LanguageKeys.Spielbewertung]: "Spielbewertung",
  [LanguageKeys.KeineMarktdatenVorhanden]: "Keine Marktdaten vorhanden.",
  [LanguageKeys.Abloese]: "Ablöse",
  [LanguageKeys.KeineTransferdatenVorhanden]: "Keine Transferdaten vorhanden.",
  [LanguageKeys.RatingsBasierenAufWenigerAls900Spielminuten]: "Die Ratings basieren auf weniger als 900 Spielminuten.",
  [LanguageKeys.Jahre]: "Jahre",
  [LanguageKeys.Wettbewerb]: "Wettbewerb",
  [LanguageKeys.Tage]: "Tage",
  [LanguageKeys.Karriere]: "Karriere",
  [LanguageKeys.Nein]: "Nein",
  [LanguageKeys.Ja]: "Ja",
  [LanguageKeys.DatenBeziehenSichAufDasLetzteJahr]: "Die Daten beziehen sich auf das letzte Jahr.",
  [LanguageKeys.Aktuell]: "Aktuell",
  [LanguageKeys.Potential]: "Potential",
  [LanguageKeys.Dreierkette]: "3er-Kette",
  [LanguageKeys.Viererkette]: "4er-Kette",
  [LanguageKeys.AlleSpielerzumVergleichHinzufuegen]: "Alle Spieler zum Vergleich hinzufügen",
  [LanguageKeys.Name]: "Name",
  [LanguageKeys.BitteWaehleEineFormationAus]: "Bitte wähle eine Formation aus.",
  [LanguageKeys.Benutzername]: "Benutzername",
  [LanguageKeys.Zugriff]: "Zugriff",
  [LanguageKeys.Lesen]: "Lesen",
  [LanguageKeys.Schreiben]: "Schreiben",
  [LanguageKeys.Erstellen]: "Erstellen",
  [LanguageKeys.Abbrechen]: "Abbrechen",
  [LanguageKeys.TeilenMit]: "Teilen mit",
  [LanguageKeys.SchattenmannschaftLoeschen]: "Schattenmannschaft löschen",
  [LanguageKeys.WillstDuDieSchattenmannschaft]: "Willst du die Schattenmannschaft", // part 1 of the phrase "Do you really want to delete the shadow team?", i.e. all words before shadow team
  [LanguageKeys.WirklichLoeschen]: "wirklich löschen?", // part 2 of the phrase "Do you really want to delete the shadow team?", i.e. all words after shadow team
  [LanguageKeys.GibEinenUsernamenEin]: "Gib einen Benutzernamen ein.",
  [LanguageKeys.KeinenBenutzerGefunden]: "Keinen Benutzer gefunden.",
  [LanguageKeys.Spielerdetails]: "Spielerdetails",
  [LanguageKeys.Login]: "Login",
  [LanguageKeys.Anmelden]: "Anmelden",
  [LanguageKeys.Abmelden]: "Abmelden",
  [LanguageKeys.WaehleEineSchattenmannschaft]: "Wähle eine Schattenmannschaft",
  [LanguageKeys.KeineSpielerGefunden]: "Keine Spieler gefunden",
  [LanguageKeys.Alle]: "Alle",
  [LanguageKeys.AktuellesRating]: "Aktuelles Rating",
  [LanguageKeys.AktuellesRatingShort]: "Aktuelles Rating",
  [LanguageKeys.KeinAktuellesRating]: "Kein aktuelles Rating",
  [LanguageKeys.KeinAktuellesRatingShort]: "Kein aktuelles Rating",
  [LanguageKeys.PDFExport]: "PDF Export",
  [LanguageKeys.KeineGespeichertenFilterVorhanden]: "Keine gespeicherten Filter vorhanden",
  [LanguageKeys.ExistiertBereitsDurchSpeichernWirdEsUeberschrieben]: "existiert bereits, durch Speichern wird es überschrieben.",
  [LanguageKeys.NameFilterung]: "Name Filterung",
  [LanguageKeys.MinMussKleinerAlsMaxWertSein]: "Min muss kleiner als Max Wert sein",
  [LanguageKeys.WerteMuessenZwischen]: "Werte müssen zwischen ",
  [LanguageKeys.Und]: "und",
  [LanguageKeys.Liegen]: "liegen.",
  [LanguageKeys.SpielerGefunden]: "Spieler gefunden",
  [LanguageKeys.FilterAusgewaehlt]: "Filter ausgewählt",
  [LanguageKeys.SchattenmannschaftBearbeiten]: "Schattenmannschaft bearbeiten",
  [LanguageKeys.Standardformation]: "Standardformation",
  [LanguageKeys.Fehler]: "Fehler",
  [LanguageKeys.Beim_BeimSpeichernFehlerAufgetreten]: "Beim ",
  [LanguageKeys.Speichern_BeimSpeichernFehlerAufgetreten]: "Speichern ",
  [LanguageKeys.Updaten_BeimUpdatenFehlerAufgetreten]: "Updaten ",
  [LanguageKeys.DeinesFiltersIstEinFehlerAufgetreten]: "deines Filters ist ein Fehler aufgetreten.",
  [LanguageKeys.DeinFilterWurdeErfolgreich]: "Dein Filter wurde erfolgreich",
  [LanguageKeys.Geupdatet]: "geupdatet",
  [LanguageKeys.Gespeichert]: "gespeichert",
  [LanguageKeys.WaehleEineSchattenmannschaftUndEinePositionAus]: "Wähle eine Schattenmannschaft und Position aus.",
  [LanguageKeys.DieserSpielerIstBereitsInFolgendenSchattenmannschaften]: "Dieser Spieler ist bereits in folgenden Schattenmannschaften:",
  [LanguageKeys.VonderWatchlistEntfernen]: "Von deiner Watchlist entfernen.",
  [LanguageKeys.DieserSpielerIstBereitsInDeinerWatchlist]: "Dieser Spieler ist bereits in deiner Watchlist.",
  [LanguageKeys.EsKoennenNurSpielerHinzugefuegtWerdenZuDenenPositionsdatenExistieren]: "Es können nur Spieler hinzugefügt werden, zu denen Positionsdaten existieren. Ziehe den Spieler zunächst auf eine Position, zu der es Daten gibt.",
  [LanguageKeys.DieserSpielerIstBereitsImWarenkorb]: "Dieser Spieler ist bereits im Warenkorb.",
  [LanguageKeys.SpielerErfolgreichZurWatchlistHinzugefuegt]: "Spieler erfolgreich zur Watchlist hinzugefügt.",
  [LanguageKeys.Keine]: "Keine",
  [LanguageKeys.EsIstKeinePositionAusgewaehlt]: "Es ist keine Position ausgewählt.",
  [LanguageKeys.SortierenNach]: "Sortieren nach",
  [LanguageKeys.WenigerAnzeigen]: "Weniger anzeigen",
  [LanguageKeys.MehrAnzeigen]: "Mehr anzeigen",
  [LanguageKeys.Bis]: "bis",
  [LanguageKeys.Ab]: "ab",
  [LanguageKeys.ErrorForbidden]: "Ihnen fehlt hierfür die Berechtigung.",
  [LanguageKeys.ErrorGeneral]: "Ein Fehler ist aufgetreten.",
};
