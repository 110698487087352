import { useParams } from "react-router-dom";
import ProfilePrintView from "../components/printViews/ProfilePrintView";
import React from "react";
import ShadowteamFormationPrintView from "../components/printViews/ShadowteamFormationPrintView";
import ShadowteamPlayersPrintView from "../components/printViews/ShadowteamPlayersPrintView";
import ShadowteamTitlepagePrintView from "../components/printViews/ShadowteamTitlepagePrintView";
import { Box, Center, Image } from "@chakra-ui/react";
import logo from "../assets/alleyesLogo.png";

const PrintView = () => {
  const { page } = useParams();

  let printView: React.ReactElement;
  switch (page) {
    case "profile":
      printView = <ProfilePrintView />;
      break;
    case "shadowteamTitlepage":
      printView = <ShadowteamTitlepagePrintView />;
      break;
    case "shadowteamFormation":
      printView = <ShadowteamFormationPrintView />;
      break;
    case "shadowteamPlayers":
      printView = <ShadowteamPlayersPrintView />;
      break;
    default:
      printView = <ProfilePrintView />;
  }

  return (
    <Box display="flex" flex={1} overflow="auto" height="100vh" width="100vw" p={6}>
      <Center height="100%" width="100%">
        {printView}
      </Center>
      <Image
        position="fixed"
        bottom={4}
        right={4}
        src={logo}
        boxSize="65px"
      />
    </Box>
  );
};

export default PrintView;
