import { useToast } from "@chakra-ui/react";
import { cloneDeep } from "lodash";
import { useCallback} from "react";
import {
  allPositions,
  shadowteamPositionMapping,
} from "../components/shadowteams/formation/FormationsAndPositions";
import { playerToBench } from "../components/shadowteams/shadowteamUtilFunctions";
import {
  Player,
  Shadowteam,
  ShadowteamEntry,
  WatchlistEntry,
} from "../generated";
import { useComparisonStore } from "../store/store";
import { fetchPlayer } from "./usePlayer";
import { useAddPlayerToWatchlist } from "./useWatchlist";
import { GuiTextType} from "../store/languageContext";
import { LanguageKeys } from "../language/LanguageKeys";

interface Props {
  shadowteam: Shadowteam | undefined;
  setShadowteam: (shadowteam: Shadowteam) => void;
  shadowteamAside: Shadowteam | undefined;
  watchlist: WatchlistEntry[] | undefined;
  selection: string;
  renderTrigger: boolean;
  setRenderTrigger: (renderTrigger: boolean) => void;
  guiText: GuiTextType
}

const useDragAndDropShadowteams = ({
  shadowteam,
  setShadowteam,
  shadowteamAside,
  watchlist,
  selection,
  renderTrigger,
  setRenderTrigger,
  guiText
}: Props) => {

  const toast = useToast();

  const {
    players: comparisonPlayers,
    setPlayers: setComparisonPlayers,
    addPlayer: addPlayerToComparison,
  } = useComparisonStore();

  const addPlayerToWatchlist = useAddPlayerToWatchlist();

  const handleDragEnd = useCallback(
    (result: any) => {

      const { destination, source, draggableId } = result;

      const key: string[] = draggableId.split("_");

      if (!destination) {
        return;
      }

      ///////////////////////////////////////////////// inner field change only
      if (
        allPositions.includes(destination.droppableId) &&
        allPositions.includes(source.droppableId)
      ) {
        const updatedPlayers = Array.from(shadowteam?.players ?? []);

        const draggedPlayerIdx = updatedPlayers.findIndex(
          (player) => player.player.id === Number(key[0])
        );
        const draggedPlayer = updatedPlayers[draggedPlayerIdx];

        ////////////////////////////////////// Reorder within same position
        if (source.droppableId === destination.droppableId) {
          if (draggedPlayerIdx !== -1 && source.index !== destination.index) {
            const filteredPlayers = updatedPlayers
              .filter(
                (player) =>
                  player.shadowteamPosition === draggedPlayer.shadowteamPosition
              )
              .sort((a, b) => a.prio - b.prio);

            const [removedPlayer] = filteredPlayers.splice(source.index, 1);

            filteredPlayers.splice(destination.index, 0, removedPlayer);

            filteredPlayers.forEach((player, index) => {
              player.prio = index + 1;
            });

            updatedPlayers.forEach((player) => {
              if (
                player.shadowteamPosition === draggedPlayer.shadowteamPosition
              ) {
                const indexInFiltered = filteredPlayers.findIndex(
                  (filteredPlayer) =>
                    filteredPlayer.player.id === player.player.id
                );
                updatedPlayers[updatedPlayers.indexOf(player)] =
                  filteredPlayers[indexInFiltered];
              }
            });

            setShadowteam({ ...shadowteam!, players: updatedPlayers });
          }
        }
        ////////////////////////////////////// Changing position
        else {
          const sourcePosition = shadowteamPositionMapping.get(
            source.droppableId
          );
          const destinationPosition = shadowteamPositionMapping.get(
            destination.droppableId
          );

          if (draggedPlayerIdx !== -1) {
            const filteredPlayersSource = updatedPlayers
              .filter(
                (player) => player.shadowteamPosition === source.droppableId
              )
              .sort((a, b) => a.prio - b.prio);

            const [removedPlayer] = filteredPlayersSource.splice(
              source.index,
              1
            );

            filteredPlayersSource.forEach((player, index) => {
              player.prio = index + 1;
            });

            const filteredPlayersDestination = updatedPlayers
              .filter(
                (player) =>
                  player.shadowteamPosition === destination.droppableId
              )
              .sort((a, b) => a.prio - b.prio);

            removedPlayer.shadowteamPosition = destination.droppableId;

            if (destination.droppableId === "BENCH") {
              removedPlayer.player = playerToBench(removedPlayer.player);
            } else if (sourcePosition !== destinationPosition) {
              fetchPlayer(Number(key[0]), destinationPosition!).then(
                (player) => {
                  removedPlayer.player = player;
                  setRenderTrigger(!renderTrigger);
                }
              );
            }

            filteredPlayersDestination.splice(
              destination.index,
              0,
              removedPlayer
            );

            filteredPlayersDestination.forEach((player, index) => {
              player.prio = index + 1;
            });

            updatedPlayers.forEach((player, idx) => {
              if (player.player.id === removedPlayer.player.id) {
                updatedPlayers[idx] = removedPlayer;
              } else if (player.shadowteamPosition === source.droppableId) {
                const indexInFiltered = filteredPlayersSource.findIndex(
                  (filteredPlayer) =>
                    filteredPlayer.player.id === player.player.id
                );
                updatedPlayers[updatedPlayers.indexOf(player)] =
                  filteredPlayersSource[indexInFiltered];
              } else if (
                player.shadowteamPosition === destination.droppableId
              ) {
                const indexInFiltered = filteredPlayersDestination.findIndex(
                  (filteredPlayer) =>
                    filteredPlayer.player.id === player.player.id
                );
                updatedPlayers[updatedPlayers.indexOf(player)] =
                  filteredPlayersDestination[indexInFiltered];
              }
            });

            setShadowteam({ ...shadowteam!, players: updatedPlayers });
          }
        }
      }
      //////////////////////////////////////// reorder comparison players
      else if (
        destination.droppableId === "comparisonList" &&
        destination.droppableId === source.droppableId &&
        selection === LanguageKeys.SpielervergleichWarenkorb
      ) {
        const newPlayers = Array.from(comparisonPlayers);
        const [reorderedItem] = newPlayers.splice(source.index, 1);
        newPlayers.splice(destination.index, 0, reorderedItem);

        setComparisonPlayers(newPlayers);
      }
      ////////////////////////////////////// drag players from aside onto field
      else if (
        source.droppableId === "comparisonList" &&
        allPositions.includes(destination.droppableId)
      ) {
        if (
          shadowteam?.players.findIndex(
            (entry) => entry.player.id === Number(key[0])
          ) !== -1
        ) {
          toast({
            title:  guiText[LanguageKeys.SpielerKannNichtHinzugefuegtWerden],
            description: guiText[LanguageKeys.SpielerbereitsinSchattenmannschaft],
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          return;
        }

        const updatedPlayers = Array.from(shadowteam?.players || []);

        let draggedPlayer: Player | undefined = undefined;

        switch (selection) {
          case LanguageKeys.SpielervergleichWarenkorb:
            draggedPlayer = comparisonPlayers.find(
              (player) =>
                player.id === Number(key[0]) &&
                player.positionsGruppe === key[1]
            );
            break;
          case LanguageKeys.SidebarSchattenmannschaften:
            draggedPlayer = shadowteamAside?.players.find(
              (entry) =>
                entry.player.id === Number(key[0]) &&
                entry.player.positionsGruppe === key[1]
            )?.player;
            break;
          case LanguageKeys.SidebarWatchlist:
            draggedPlayer = watchlist?.find(
              (entry) =>
                entry.player.id === Number(key[0]) &&
                entry.player.positionsGruppe === key[1]
            )?.player;
            break;
        }

        const destinationPosition = shadowteamPositionMapping.get(
          destination.droppableId
        );

        const filteredPlayersDestination = updatedPlayers
          .filter(
            (player) => player.shadowteamPosition === destination.droppableId
          )
          .sort((a, b) => a.prio - b.prio);

        const newEntry: ShadowteamEntry = {
          player: cloneDeep(draggedPlayer!),
          prio: -1,
          shadowteamPosition: destination.droppableId,
        };

        if (destination.droppableId === "BENCH") {
          newEntry.player = playerToBench(newEntry.player);
        }

        filteredPlayersDestination.splice(destination.index, 0, newEntry);

        filteredPlayersDestination.forEach((player, index) => {
          player.prio = index + 1;
        });

        updatedPlayers.forEach((player) => {
          if (player.shadowteamPosition === destination.droppableId) {
            const indexInFiltered = filteredPlayersDestination.findIndex(
              (filteredPlayer) => filteredPlayer.player.id === player.player.id
            );
            updatedPlayers[updatedPlayers.indexOf(player)] =
              filteredPlayersDestination[indexInFiltered];
          }
        });

        if (
          (newEntry.player.positionsGruppe !== destinationPosition ||
            selection === LanguageKeys.SpielervergleichWarenkorb) &&
          destination.droppableId !== "BENCH"
        ) {
          console.log(newEntry.player);

          console.log(newEntry.player.positionsGruppe);
          console.log(destinationPosition);
          fetchPlayer(Number(key[0]), destinationPosition!).then((player) => {
            newEntry.player = player;
            setRenderTrigger(!renderTrigger);
          });
        }

        setShadowteam({
          ...shadowteam,
          players: [...updatedPlayers, newEntry],
        });
      }
      /////////////////////////////////////////////////////drag players from field onto aside
      else if (
        destination.droppableId === "comparisonList" &&
        allPositions.includes(source.droppableId)
      ) {
        const draggedPlayerIdx = shadowteam?.players.findIndex(
          (player) => player.player.id === Number(key[0])
        );
        const draggedPlayer = shadowteam?.players[draggedPlayerIdx!];

        if (draggedPlayer?.player.positionsGruppe === "") {
          toast({
            title: guiText[LanguageKeys.SpielerKannNichtHinzugefuegtWerden],
            description:
              guiText[LanguageKeys.EsKoennenNurSpielerHinzugefuegtWerdenZuDenenPositionsdatenExistieren],
            status: "error",
            duration: 7000,
            isClosable: true,
          });
          return;
        }

        /////////////////////////////////////////////////////drag player from field into Warenkorb
        if (selection === LanguageKeys.SpielervergleichWarenkorb) {
          const idx = comparisonPlayers?.findIndex(
            (player) =>
              player.id === draggedPlayer?.player.id &&
              player.positionsGruppe === draggedPlayer.player.positionsGruppe
          );

          if (idx !== -1) {
            toast({
              title: guiText[LanguageKeys.SpielerKannNichtHinzugefuegtWerden],
              description: guiText[LanguageKeys.DieserSpielerIstBereitsImWarenkorb],
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          } else {
            addPlayerToComparison(draggedPlayer?.player!);
          }
        }
        /////////////////////////////////////////////////////drag player from field into Warenkorb
        else if (selection === LanguageKeys.SidebarWatchlist) {
          const idx = watchlist?.findIndex(
            (entry) =>
              entry.player.id === draggedPlayer?.player.id &&
              entry.player.positionsGruppe ===
                draggedPlayer.player.positionsGruppe
          );

          if (idx !== -1) {
            toast({
              title: guiText[LanguageKeys.SpielerKannNichtHinzugefuegtWerden],
              description: guiText[LanguageKeys.DieserSpielerIstBereitsInDeinerWatchlist],
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          } else {
            addPlayerToWatchlist({
              playerId: draggedPlayer?.player.id!,
              positionsGruppe: draggedPlayer?.player.positionsGruppe!,
              favorite: false,
            });
            toast({
              title: guiText[LanguageKeys.SpielerErfolgreichZurWatchlistHinzugefuegt],
              status: "success",
              duration: 2000,
              isClosable: true,
            });
          }
        }
      }
    },
    [
      shadowteam,
      setShadowteam,
      addPlayerToComparison,
      comparisonPlayers,
      shadowteamAside,
      watchlist,
      selection,
      toast,
    ]
  );

  return { handleDragEnd };
};

export default useDragAndDropShadowteams;
