/* tslint:disable */
/* eslint-disable */
/**
 * alleyes SCOUT BACKEND
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SortingInfo
 */
export interface SortingInfo {
    /**
     * 
     * @type {string}
     * @memberof SortingInfo
     */
    column?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SortingInfo
     */
    ascending?: boolean;
}

/**
 * Check if a given object implements the SortingInfo interface.
 */
export function instanceOfSortingInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SortingInfoFromJSON(json: any): SortingInfo {
    return SortingInfoFromJSONTyped(json, false);
}

export function SortingInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SortingInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'column': !exists(json, 'column') ? undefined : json['column'],
        'ascending': !exists(json, 'ascending') ? undefined : json['ascending'],
    };
}

export function SortingInfoToJSON(value?: SortingInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'column': value.column,
        'ascending': value.ascending,
    };
}

