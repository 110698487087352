import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import React from "react";
import { Player } from "../../../generated";
import { getRatingColor } from "../../../util/utilFunctions";
import RatingBadge from "../../general/RatingBadge";

interface Props {
  params: GridRenderCellParams;
  statKey: keyof Player;
  maxRating?: number;
}

const RatingCellRenderer = ({ params, statKey, maxRating = 100 }: Props) => {
  let rating = undefined;
  try {
    const rawRating: number | undefined =
      params.row.player && params.row.player[statKey]
        ? params.row.player[statKey]
        : params.row[statKey];

    // @ts-ignore
    rating =
      rawRating && maxRating == 100
        ? Math.round(rawRating)
        : rawRating
        ? parseFloat(rawRating.toFixed(1))
        : undefined;
  } catch (e) {}

  return (
    <RatingBadge
      rating={rating?.toString()}
      backgroundColor={getRatingColor(rating, 0, maxRating)}
      small
    />
  );
};
export default RatingCellRenderer;
