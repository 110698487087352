import { ButtonGroup, IconButton } from "@chakra-ui/react";
import { BiListUl } from "react-icons/bi";
import { GiSoccerField } from "react-icons/gi";
import { colorsPrimary, colorsSecondary } from "../../../colorStyles";

interface Props {
  view: string;
  setView: (view: string) => void;
}
const FootballFieldListSwitch = ({ view, setView }: Props) => {
  return (
    <ButtonGroup isAttached boxShadow="xm" h="35px">
      <IconButton
        onClick={() => setView("field")}
        icon={
          <GiSoccerField
            size={22}
            color={view === "field" ? "white" : "black"}
          />
        }
        // boxSize={8}
        h="100%"
        aria-label="Field View"
        backgroundColor={
          view === "field" ? colorsSecondary.accent : "white"
        }
        borderTopLeftRadius="md"
        borderBottomLeftRadius="md"
        borderTopRightRadius="0"
        borderBottomRightRadius="0"
      ></IconButton>
      <IconButton
        onClick={() => setView("table")}
        icon={
          <BiListUl size={25} color={view === "table" ? "white" : colorsSecondary.accent} />
        }
        // boxSize={8}
        h="100%"
        aria-label="Table View"
        backgroundColor={
          view === "table" ? colorsSecondary.accent : "white"
        }
        borderTopLeftRadius="0"
        borderBottomLeftRadius="0"
        borderTopRightRadius="md"
        borderBottomRightRadius="md"
      ></IconButton>
    </ButtonGroup>
  );
};

export default FootballFieldListSwitch;
