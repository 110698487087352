import { IconButton } from "@mui/material";
import React from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { Player } from "../../generated";
import { useDeletePlayerFromWatchlist } from "../../hooks/useWatchlist";
import { usePlayerModalStore } from "../../store/store";

interface Props {
  player: Player;
}

const DeleteIcon = ({ player }: Props) => {
  const deleteFromWatchlist = useDeletePlayerFromWatchlist();

  return (
    <IconButton
      aria-label="Add to Watchlist or ShadowTeam"
      onClick={() =>
        deleteFromWatchlist({
          playerId: player.id,
          position: player.positionsGruppe,
        })
      }
    >
      <AiFillCloseCircle size="20px" color="#8a8a8a" />
    </IconButton>
  );
};

export default DeleteIcon;
