import { format } from "date-fns";
import { FilterSpecification } from "../generated";
import { useContext } from "react";
import { LanguageContext } from "../store/languageContext";
import { LocaleKeys } from "../language/LocaleKeys";
import { LanguageKeys } from "../language/LanguageKeys";
import { getFilterSpecification } from "../components/search/filterComponents/ButtonGroupFilter";

interface Props {
  filterSpec: FilterSpecification;
  value: string;
}

const useFilterBadge = () => {
  const {guiText, language} = useContext(LanguageContext);
  const getFilterBadge = ({ filterSpec, value}: Props): string[] => {
    const filterSpecification = getFilterSpecification(filterSpec, language);
    const filterValue = JSON.parse(value);
    const badgelabel = language === LocaleKeys.DE? filterSpec.labelGerman: language==LocaleKeys.EN? filterSpec.labelEnglish: filterSpec.labelSpanish
    switch (filterSpec.type) {
      case "rangeSlider":
        const min = filterSpecification.min;
        const max = filterSpecification.max;
        const start = filterValue.min;
        const end = filterValue.max;
        const unit = filterSpecification.unit ? filterSpecification.unit : "";

        if (filterSpecification.hideNumbers) {
          return [badgelabel];
        }

        let badge = badgelabel + ": ";

        if (start > min && end < max) {
          badge += `${start.toLocaleString(
            "de-DE"
          )} ${unit} ${guiText[LanguageKeys.Bis]} ${end.toLocaleString("de-DE")} ${unit}`;
        } else if (start > min && end === max) {
          badge += `${guiText[LanguageKeys.Ab]} ${start.toLocaleString("de-DE")} ${unit}`;
        } else if (start === min && end < max) {
          badge += `${guiText[LanguageKeys.Bis]} ${end.toLocaleString("de-DE")} ${unit}`;
        }

        return [badge];

      case "menu":
      case "textMenu":
        return filterValue.values.map((val: any) => val.label);
      case "buttonGroup":
        return filterValue.values.map((val: string) => {
          const index = filterSpecification.options.indexOf(val);
          return filterSpecification.label[index];
        });

      case "date":
        return [
          badgelabel +
            ": " +
            format(new Date(filterValue.date), "dd.MM.yyyy"),
        ];
      case "checkbox":
        return [
          badgelabel +
            ": " +
            (filterValue.value === false ? "Nein" : "Ja"),
        ];

      default:
        return [""];
    }
  };

  return { getFilterBadge };
};

export default useFilterBadge;
