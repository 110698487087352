import {
  Badge,
  HStack,
  Icon,
  Text,
} from "@chakra-ui/react";
import { AiFillCloseCircle } from "react-icons/ai";
import { FilterSpecification, FilterValue } from "../../../generated";
import useFilterBadge from "../../../hooks/useFilterBadge";
import { hexToRgba } from "../../../util/utilFunctions";
import { colorsSecondary, textColors } from "../../../colorStyles";
interface Props {
  filterSpecifications?: FilterSpecification[];
  filterValues: (FilterValue | null)[];
  removeFilter: (id: number, badge?: string) => void;
}

const SelectedFilterBadges = ({ filterSpecifications, filterValues, removeFilter }: Props) => {

  const { getFilterBadge } = useFilterBadge();

  const getFilterSpec = (id: number): FilterSpecification | undefined => {
    return filterSpecifications?.find((filterSpec) => filterSpec.specId === id);
  };

  return (
    <HStack spacing={4} wrap="wrap">
      {filterValues.map((filterValue) => {
        if (filterValue == null) return;
        const filterSpec = getFilterSpec(filterValue.specId);
        if (filterSpec) {
          return getFilterBadge({
            filterSpec: filterSpec,
            value: filterValue.value,
          }).map((badge, index) => (
            <Badge
              key={index}
              color="white"
              pl={3}
              pr={3}
              pt={1}
              pb={1}
              borderRadius="xl"
              textTransform="none"
              fontSize="xs"
              textColor={textColors.lightgrey}
              backgroundColor={colorsSecondary.cardBackground}
              data-cy={"filterBadge"}
            >
              <HStack>
                <Text>{badge}</Text>
                <Icon
                  as={AiFillCloseCircle}
                  color="gray.500"
                  style={{ cursor: "pointer" }}
                  onClick={() => removeFilter(filterValue.specId, badge)}
                  boxSize={4}
                  data-cy={"removeButton"}
                ></Icon>
              </HStack>
            </Badge>
          ));
        }
        return null; // return null if filterSpec is not defined
      })}
    </HStack>
  );
};

export default SelectedFilterBadges;
