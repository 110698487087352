/* tslint:disable */
/* eslint-disable */
/**
 * alleyes SCOUT BACKEND
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FilterValue } from './FilterValue';
import {
    FilterValueFromJSON,
    FilterValueFromJSONTyped,
    FilterValueToJSON,
} from './FilterValue';

/**
 * 
 * @export
 * @interface FilterPreset
 */
export interface FilterPreset {
    /**
     * 
     * @type {number}
     * @memberof FilterPreset
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof FilterPreset
     */
    name: string;
    /**
     * 
     * @type {Array<FilterValue>}
     * @memberof FilterPreset
     */
    filterValues: Array<FilterValue>;
}

/**
 * Check if a given object implements the FilterPreset interface.
 */
export function instanceOfFilterPreset(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "filterValues" in value;

    return isInstance;
}

export function FilterPresetFromJSON(json: any): FilterPreset {
    return FilterPresetFromJSONTyped(json, false);
}

export function FilterPresetFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilterPreset {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'filterValues': ((json['filterValues'] as Array<any>).map(FilterValueFromJSON)),
    };
}

export function FilterPresetToJSON(value?: FilterPreset | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'filterValues': ((value.filterValues as Array<any>).map(FilterValueToJSON)),
    };
}

