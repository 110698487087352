import { Box, Divider, Flex, HStack, Text } from "@chakra-ui/react";
import React from "react";
import { colorsPrimary, colorsSecondary, textColors } from "../../../colorStyles";

interface Props {
  position: string;
}
const ColorExplanation = ({ position }: Props) => {
  return (
    <>
      {position == "Full Back" && (
        <Box flexDir="column" display="flex" w="100%">
          <Divider pt={2} mb={4} borderColor="gray.300" />

          <HStack w="100%" spacing={3} align="start" h="20px">
            <Flex
              w="40%"
              fontWeight="bold"
              alignItems="flex-end"
              flexDir="column"
              justifyContent="center"
              h="100%"
            >
              <Box bg={colorsSecondary.accentBabyBlue} h="50%" w="50%" />
            </Flex>
            <Flex w="60%" flexDir="column" h="100%" justifyContent="center">
              <Text color={textColors.primary}>Full Back</Text>
            </Flex>
          </HStack>
          <HStack w="100%" spacing={3} align="start" h="20px">
            <Flex
              w="40%"
              fontWeight="bold"
              alignItems="flex-end"
              flexDir="column"
              justifyContent="center"
              h="100%"
            >
              <Box bg={colorsSecondary.accentDarkBlue} h="50%" w="50%" />
            </Flex>
            <Flex w="60%" flexDir="column" h="100%" justifyContent="center">
              <Text color={textColors.primary}>Wingback</Text>
            </Flex>
          </HStack>
        </Box>
      )}

      {position == "Central Defender" && (
        <Box flexDir="column" display="flex" w="100%">
          <Divider pt={2} mb={4} borderColor="gray.300" />

          <HStack w="100%" spacing={3} align="start" h="20px">
            <Flex
              w="40%"
              fontWeight="bold"
              alignItems="flex-end"
              flexDir="column"
              justifyContent="center"
              h="100%"
            >
              <Box bg={colorsSecondary.accentBabyBlue} h="50%" w="50%" />
            </Flex>
            <Flex w="60%" flexDir="column" h="100%" justifyContent="center">
              <Text color={textColors.primary}>Center Back</Text>
            </Flex>
          </HStack>
        </Box>
      )}

      {position == "Winger" && (
        <Box flexDir="column" display="flex" w="100%">
          <Divider pt={2} mb={4} borderColor="gray.300" />

          <HStack w="100%" spacing={3} align="start" h="20px">
            <Flex
              w="40%"
              fontWeight="bold"
              alignItems="flex-end"
              flexDir="column"
              justifyContent="center"
              h="100%"
            >
              <Box bg={colorsSecondary.accentBabyBlue} h="50%" w="50%" />
            </Flex>
            <Flex w="60%" flexDir="column" h="100%" justifyContent="center">
              <Text color={textColors.primary}>Winger</Text>
            </Flex>
          </HStack>
        </Box>
      )}

      {position == "Defensive Midfield" && (
        <Box flexDir="column" display="flex" w="100%">
          <Divider pt={2} mb={4} borderColor="gray.300" />

          <HStack w="100%" spacing={3} align="start" h="20px">
            <Flex
              w="40%"
              fontWeight="bold"
              alignItems="flex-end"
              flexDir="column"
              justifyContent="center"
              h="100%"
            >
              <Box bg={colorsSecondary.accentBabyBlue} h="50%" w="50%" />
            </Flex>
            <Flex w="60%" flexDir="column" h="100%" justifyContent="center">
              <Text color={textColors.primary}>Defensive Midfielder</Text>
            </Flex>
          </HStack>
        </Box>
      )}

      {position == "Central Midfield" && (
        <Box flexDir="column" display="flex" w="100%">
          <Divider pt={2} mb={4} borderColor="gray.300" />

          <HStack w="100%" spacing={3} align="start" h="20px">
            <Flex
              w="40%"
              fontWeight="bold"
              alignItems="flex-end"
              flexDir="column"
              justifyContent="center"
              h="100%"
            >
              <Box bg={colorsSecondary.accentBabyBlue} h="50%" w="50%" />
            </Flex>
            <Flex w="60%" flexDir="column" h="100%" justifyContent="center">
              <Text color={textColors.primary}>Central Midfielder</Text>
            </Flex>
          </HStack>
          <HStack w="100%" spacing={3} align="start" h="20px">
            <Flex
              w="40%"
              fontWeight="bold"
              alignItems="flex-end"
              flexDir="column"
              justifyContent="center"
              h="100%"
            >
              <Box bg={colorsSecondary.accentDarkBlue} h="50%" w="50%" />
            </Flex>
            <Flex w="60%" flexDir="column" h="100%" justifyContent="center">
              <Text color={textColors.primary}>Offensive Midfielder</Text>
            </Flex>
          </HStack>
        </Box>
      )}

      {position == "Forward" && (
        <Box flexDir="column" display="flex" w="100%">
          <Divider pt={2} mb={4} borderColor="gray.300" />

          <HStack w="100%" spacing={3} align="start" h="20px">
            <Flex
              w="40%"
              fontWeight="bold"
              alignItems="flex-end"
              flexDir="column"
              justifyContent="center"
              h="100%"
            >
              <Box bg={colorsSecondary.accentBabyBlue} h="50%" w="50%" />
            </Flex>
            <Flex w="60%" flexDir="column" h="100%" justifyContent="center">
              <Text color={textColors.primary}>Target Player</Text>
            </Flex>
          </HStack>
          <HStack w="100%" spacing={3} align="start" h="20px">
            <Flex
              w="40%"
              fontWeight="bold"
              alignItems="flex-end"
              flexDir="column"
              justifyContent="center"
              h="100%"
            >
              <Box bg={colorsSecondary.accentDarkBlue} h="50%" w="50%" />
            </Flex>
            <Flex w="60%" flexDir="column" h="100%" justifyContent="center">
              <Text color={textColors.primary}>Striker</Text>
            </Flex>
          </HStack>
        </Box>
      )}
    </>
  );
};

export default ColorExplanation;
