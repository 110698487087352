import { BsExclamationCircleFill } from "react-icons/bs";
import { useCustomFilteredPlayers } from "../../hooks/useHome";
import Top5PlayersList from "./Top5PlayersList";
import { useContext } from "react";
import { LanguageContext } from "../../store/languageContext";
import { LanguageKeys } from "../../language/LanguageKeys";

const PriorityPlayers = () => {
  const { guiText } = useContext(LanguageContext);
  const customFilteredPlayers = useCustomFilteredPlayers();

  return (
    <Top5PlayersList
      title={guiText[LanguageKeys.HomePriorityPlayers]}
      players={customFilteredPlayers.data || []}
      icon={<BsExclamationCircleFill size={25} color="white" />}
    />
  );
};

export default PriorityPlayers;
