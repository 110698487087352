import { Box } from "@chakra-ui/react";
import { GridColDef, GridSortModel } from "@mui/x-data-grid-pro";
import { ChangeEvent, useContext, useEffect } from "react";
import { Player } from "../../../generated";
import { usePaginatedPlayers } from "../../../hooks/usePlayers";
import {
  useComparisonStore,
  usePlayerQueryStore,
  useSearchResultSize,
} from "../../../store/store";
import PlayerTable, { playerId } from "../../general/PlayerTable";
import {
  age,
  athletisch, compare,
  height, fieldToSortColumn, lastReview,
  marketValue, numberOfReviews,
  OnOpenModalProps,
  OnSelectProps,
  positionsGruppe,
  SelectedPlayersProps,
  stammdaten,
  alleyes,
  subjectiveGameRatingsOverall, subjectiveGameRatingsPotential,
  subjectiveOverall,
  subjectivePotential,
  taktischPasses,
  taktischRun,
  technischD,
  technischPs, watch,
  LanguageProps
} from "../../general/ColumnDefinitions";
import { LanguageKeys } from "../../../language/LanguageKeys";
import { LanguageContext } from "../../../store/languageContext";

const columns = ({ onOpenModal, onSelect, selectedPlayerIds, guiText }: OnOpenModalProps & OnSelectProps & SelectedPlayersProps & LanguageProps): GridColDef[] => {
  return [
    stammdaten("row", guiText[LanguageKeys.SpielerprofilStammdaten]),
    positionsGruppe("value", guiText[LanguageKeys.SearchBarPosition]),
    age("row.age", guiText[LanguageKeys.SearchBarAlter]),
    height("row.height", guiText[LanguageKeys.SearchBarKorpergrobe]),
    marketValue("row.marketValue", guiText[LanguageKeys.SearchBarMarktwert]),
    alleyes("row.alleyes", guiText[LanguageKeys.SearchBarAlleyesAktuell]),
    athletisch("row.athletisch", guiText[LanguageKeys.SpielerprofilAthletisch]),
    technischPs("row.technischPs", guiText[LanguageKeys.TechnischnPS]),
    technischD("row.technischD", guiText[LanguageKeys.TechnischnDuelle]),
    taktischRun("row.taktischRun", guiText[LanguageKeys.TaktischnRun]),
    taktischPasses("row.taktischPasses", guiText[LanguageKeys.TaktischnPass]),
    subjectiveOverall("row.subjectiveOverall", guiText[LanguageKeys.GesamtbewertungA]),
    subjectivePotential("row.subjectivePotential", guiText[LanguageKeys.GesamtbewertungP]),
    subjectiveGameRatingsOverall("row.subjectiveGameRatingsOverall", guiText[LanguageKeys.SpielbewertungA]),
    subjectiveGameRatingsPotential("row.subjectiveGameRatingsPotential", guiText[LanguageKeys.SpielbewertungP]),
    numberOfReviews("row.numberOfReviews", guiText[LanguageKeys.WatchlistAnzahlSichtungen]),
    lastReview("row.lastReview", guiText[LanguageKeys.WatchlistLetzteSichtung]),
    compare({ selectedPlayerIds, onSelect }),
    watch("row", { onOpenModal }),
  ]
};

interface Props {
  onOpenModal: () => void;
}

const SearchTable = ({ onOpenModal }: Props) => {
  const { guiText } = useContext(LanguageContext);

  const { data, isLoading } = usePaginatedPlayers();

  const { setSize } = useSearchResultSize();
  useEffect(() => {
    if (data?.totalResultSetSize !== undefined) {
      setSize(data?.totalResultSetSize);
    }
  }, [data]);

  const { playerQuery, setPlayerQuery } = usePlayerQueryStore();

  const {
    players: comparisonPlayers,
    addPlayer,
    deletePlayer,
  } = useComparisonStore();

  const selectedPlayerIds = comparisonPlayers.map(
    (player) => player.id + "_" + player.positionsGruppe
  );

  const onSelect = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
    id: string
  ) => {
    const rowId = id.split("_");

    if (checked) {
      const playerToAdd = data?.players?.find(
        (player) =>
          player.id === Number(rowId[0]) && player.positionsGruppe === rowId[1]
      );
      if (playerToAdd) {
        addPlayer(playerToAdd);
      }
    } else {
      deletePlayer(Number(rowId[0]), rowId[1]);
    }
  };

  const handlePaginationModelChange = (model: {
    page: number;
    pageSize: number;
  }) => {
    setPlayerQuery({
      ...playerQuery,
      paginationInfo: { page: model.page + 1, pageSize: model.pageSize },
    });
  };

  const handleSortModelChange = (model: GridSortModel) => {
    if (model.length === 0) {
      setPlayerQuery({
        ...playerQuery,
        sortingInfo: {
          column: "alleyes",
          ascending: false,
        },
      });
      return;
    }

    const field = model[0].field as keyof typeof fieldToSortColumn;
    const sort = model[0].sort;

    setPlayerQuery({
      ...playerQuery,
      sortingInfo: {
        column: fieldToSortColumn[field] ?? field,
        ascending: sort === "asc",
      },
    });
  };

  return (
    <Box flex="1" display="flex" pb={3} pt="15px">
      <PlayerTable
        name="search"
        columnDefinitions={columns({
          onOpenModal,
          selectedPlayerIds,
          onSelect,
          guiText,
        })}
        players={data?.players ?? []}
        rowCount={data?.totalResultSetSize ?? 0}
        getRowId={(player: Player) => playerId(player)}
        loading={isLoading}
        paginationMode="server"
        onPaginationModelChange={handlePaginationModelChange}
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
      />
    </Box>
  );
};

export default SearchTable;
