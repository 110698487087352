import { LanguageKeys } from "./LanguageKeys";

export const guiText: { [key in LanguageKeys]: string } = {
  [LanguageKeys.LanguageGerman]: "Deutsch",
  [LanguageKeys.LanguageEnglish]: "English",
  [LanguageKeys.LanguageSpanish]: "Español",
  [LanguageKeys.SidebarHome]: "Home",
  [LanguageKeys.SidebarSpielersuche]: "Búsqueda de jugadores",
  [LanguageKeys.SidebarWatchlist]: "Lista de Seguimiento",
  [LanguageKeys.SidebarSpielervergleich]: "Comparar jugadores",
  [LanguageKeys.SidebarSchattenmannschaften]: "Shadow team",
  [LanguageKeys.WachlistButtonAdd]: "Agregar a lista de seguimiento o a un shadow team",
  [LanguageKeys.SearchBarSpielernamenEingeben]: "Introduce el nombre de un jugador...",
  [LanguageKeys.Greeting]: "Hola, ",
  [LanguageKeys.HomePriorityPlayers]: "Jugadores prioritarios",
  [LanguageKeys.HomeNewPlayers]: "Nuevos jugadores",
  [LanguageKeys.HomeMissingReviews]: "Sin reseñas",
  [LanguageKeys.KurzfristigerTrend]: "Tendencia a corto plazo",
  [LanguageKeys.LangfristigerTrend]: "Tendencia a largo plazo",
  [LanguageKeys.SearchBarFilter]: "Filtros",
  [LanguageKeys.SearchBarFilterSpeichern]: "Guardar filtros",
  [LanguageKeys.SearchBarZurucksetzen]: "Resetear",
  [LanguageKeys.SearchBarGespeicherteFilterungAuswahlen]: "Seleccionar filtro guardado",
  [LanguageKeys.SearchBarFilterAusgewahlt]: "x filtros seleccionados, x jugadores encontrados",
  [LanguageKeys.SearchBarVergleichen]: "Comparar",
  [LanguageKeys.SearchBarDieserSpielerIstNochNichtInDeinerWatchlist]: "Este jugador aún no está en tu lista de seguimiento",
  [LanguageKeys.SearchBarDieserSpielerIstNochInKeinerSchattenmannschaft]: "Este jugador aún no está en ningún shadow team",
  [LanguageKeys.SearchBarZurWatchlistHinzufugen]: "Agregar a lista de seguimiento",
  [LanguageKeys.SearchBarZurSchattenmannschaftHinzufugen]: "Agregar a un shadow team",
  [LanguageKeys.SearchBarZurWatchlistOderSchattenmannschaftHinzufugen]: "Agregar a Lista de Seguimiento o a un shadow team",
  [LanguageKeys.SearchBarSchliessen]: "Cerrar",
  [LanguageKeys.SearchBarSpielerAktuellesRatingNurMitAktuellemRating]: "Jugador: valoración actual",
  [LanguageKeys.SearchBarSpielerAlle]: "Jugador: Todos",
  [LanguageKeys.SearchBarSpielerKeinAktuellesRatingNurOhneAktuellesRating]: "Jugador: sin valoración actual",
  [LanguageKeys.SearchBarPosition]: "Posición",
  [LanguageKeys.SearchBarAlter]: "Edad",
  [LanguageKeys.SearchBarVerein]: "Equipo",
  [LanguageKeys.SearchBarLiga]: "Liga",
  [LanguageKeys.SearchBarNationalitat]: "Nacionalidad",
  [LanguageKeys.SearchBarKorpergrobe]: "Altura",
  [LanguageKeys.SearchBarStarterFuß]: "Pie dominante",
  [LanguageKeys.Links]: "Izquierda",
  [LanguageKeys.Rechts]: "Derecha",
  [LanguageKeys.Beide]: "Ambos",
  [LanguageKeys.SearchBarMarktwert]: "Valor de mercado",
  [LanguageKeys.SearchBarVertragsendeBis]: "Rescisión del contrato",
  [LanguageKeys.SearchBarVertragsakquisationen]: "Posibilidad de contrato",
  [LanguageKeys.SearchBarKeineKaufoption]: "Sin opción de compra",
  [LanguageKeys.SearchBarKeineKaufpflichtMitBedingungen]: "Sin obligación de compra",
  [LanguageKeys.SearchBarLeihe]: "Cedido",
  [LanguageKeys.SearchBarNurVollstandigeRatings]: "Solo valoraciones completas",
  [LanguageKeys.SearchBarSubjektiveRatings]: "Valoraciones subjetivas",
  [LanguageKeys.SearchBarGesamtbewertungAktuell]: "Valoración general actual",
  [LanguageKeys.SearchBarGesamtbewertungPotential]: "Valoración general potencial",
  [LanguageKeys.SearchBarSpielbewertungAktuell]: "Valoración del partido actual",
  [LanguageKeys.SearchBarSpielbewertungPotential]: "Valoración del partido potencial",
  [LanguageKeys.SearchBarRatingsOberkategorien]: "Valoraciones",
  [LanguageKeys.SearchBarAlleyesAktuell]: "Alleyes\nActual",  // with linebreak
  [LanguageKeys.SearchBarAlleyesPotential]: "Alleyes\nPotencial",  // with linebreak 
  [LanguageKeys.SearchBarAlleyesVorjahr]: "Alleyes Año anterior",
  [LanguageKeys.SearchBarAlleyesVorjahr2]: "Alleyes Año -2",
  [LanguageKeys.SearchBarTrendKurzfristig]: "Tendencia a corto plazo",
  [LanguageKeys.SearchBarTrendLangfristig]: "Tendencia a largo plazo",
  [LanguageKeys.SearchBarSportlicheDatenRatingsDetailliert]: "Valoraciones detalladas",
  [LanguageKeys.SpielerprofilAthletisch]: "Físico",
  [LanguageKeys.SpielerprofilDistanz]: "Distancia",
  [LanguageKeys.SpielerprofilAktivitatInBallbesitz]: "Actividad con la\nposesión del balón",
  [LanguageKeys.SpielerprofilAktivitatGegenDenBall]: "Actividad sin\nel balón",
  [LanguageKeys.SpielerprofilIntensitatInBallbesitz]: "Intensidad con la\nposesión del balón",
  [LanguageKeys.SpielerprofilIntensitatGegenDenBall]: "Intensidad sin la posesión",
  [LanguageKeys.SpielerprofilSprints]: "Sprints",
  [LanguageKeys.SpielerprofilTempolaufe]: "Carreras rápidas",
  [LanguageKeys.SpielerprofilExplosivitat]: "Explosividad",
  [LanguageKeys.SpielerprofilMaxSpeed]: "Velocidad\n máxima",
  [LanguageKeys.SpielerprofilTechnischPassenScoren]: "Técnica - Pasando y anotando",
  [LanguageKeys.TechnischnPS]: "Técnica-\nPasando y anotando", // abbrevation not very understandable
  [LanguageKeys.SpielerprofilAnzahlPasse]: "Número\nde pases",
  [LanguageKeys.SpielerprofilPassqualitat]: "Calidad del pase",
  [LanguageKeys.SpielerprofilPasseeunterMittleremDruck]: "Pases bajo\npresión media",
  [LanguageKeys.SpielerprofilPasseeunterHohemDruck]: "Pases bajo\nalta presión",
  [LanguageKeys.SpielerprofilFlankenqualitat]: "Calidad de los centros",
  [LanguageKeys.SpielerprofilAnzahlSchusse]: "Número\nde tiros",
  [LanguageKeys.SpielerprofilChancenqualitat]: "Calidad de las\noportunidades",
  [LanguageKeys.SpielerprofilAbschlussqualitat]: "Calidad de la\ndefinición",
  [LanguageKeys.SpielerprofilBessermacher]: "Mejora con el jugador\n en el campo",
  [LanguageKeys.SpielerprofilTechnischDuelle]: "Técnica - Duelos",
  [LanguageKeys.TechnischnDuelle]: "Técnica-\nDuelos", // Technical duels with linebreak
  [LanguageKeys.SpielerprofilDribbling]: "Regates",
  [LanguageKeys.SpielerprofilBodenzweikampfInBallbesitz]: "Duelos con la\nposesión del balón",
  [LanguageKeys.SpielerprofilLuftzweikampfInBallbesitz]: "Duelos aéreo \ncon la posesión\ndel balón",
  [LanguageKeys.SpielerprofilBallbehauptungUnterMittleremDruck]: "Balones mantenidos bajo\npresión media",
  [LanguageKeys.SpielerprofilBallbehauptungUnterHohemDruck]: "Balones bajo\nalta presión",
  [LanguageKeys.SpielerprofilAbwehrGegenDribbling]: "Defensa\nante el regate",
  [LanguageKeys.SpielerprofilBodenzweikampfGegenDenBall]: "Duelos\ncontra el balón",
  [LanguageKeys.SpielerprofilLuftzweikampfGegenDenBall]: "Duelos aéreo\ncontra el balón",
  [LanguageKeys.SpielerprofilZweiteBalle]: "Pérdidas\nde balón",
  [LanguageKeys.SpielerprofilTaktischFreilaufverhalten]: "Táctica - Movimiento sin balón",
  [LanguageKeys.TaktischnRun]: "Táctica-\nSin balon", // Tactical runs with linebreak and abbreviated
  [LanguageKeys.SpielerprofilAbkippen]: "Incursiones a otras\nposiciones (retroceso)",
  [LanguageKeys.SpielerprofilNachrucken]: "Carreras de\nasistencia",
  [LanguageKeys.SpielerprofilEntgegenkommen]: "Carreras de\naproximación",
  [LanguageKeys.SpielerprofilHinterlaufen]: "Carreras desde atrás",
  [LanguageKeys.SpielerprofilLaufInDieHalbspur]: "Carrera hacia\nel medio campo",
  [LanguageKeys.SpielerprofilFreilaufenInDieBreite]: "Carrera hacia\nlas bandas",
  [LanguageKeys.SpielerprofilFreilaufenVorDerGegnerischenKette]: "Carreras por\ndelante del balón",
  [LanguageKeys.SpielerprofilTiefenlaufeHinterDieKette]: "Carreras por\ndetrás del balón",
  [LanguageKeys.SpielerprofilLaufInDieBox]: "Carreras hacia el área",
  [LanguageKeys.SpielerprofilTaktischPassverhalten]: "Táctica - Pases",
  [LanguageKeys.TaktischnPass]: "Táctica-\nPases", // Tactical Passing with linebreak and abbreviated
  [LanguageKeys.SpielerprofilPassZumAbkippenden]: "Pases al jugador\n que se retira",
  [LanguageKeys.SpielerprofilPassZumNachruckenden]: "Pases al jugador\nque avanza",
  [LanguageKeys.SpielerprofilPassZumEntgegenkommenden]: "Pases al jugador\nque se acerca",
  [LanguageKeys.SpielerprofilPassZumHinterlaufenden]: "Pases al jugador\nque se adelanta",
  [LanguageKeys.SpielerprofilPassInDieHalbspur]: "Pases hacia\nel medio campo",
  [LanguageKeys.SpielerprofilPassInDieBreite]: "Pases a\nlas bandas",
  [LanguageKeys.SpielerprofilPassVorDerGegnerischenKette]: "Pases frente a la\nlínea defensiva rival",
  [LanguageKeys.SpielerprofilPassHinterDieKette]: "Pases por detrás de\nla línea defensiva",
  [LanguageKeys.SpielerprofilFlankeInDieBox]: "Centro al área",
  [LanguageKeys.SpielerprofilPolarchartsRatings]: "Valoraciones",
  [LanguageKeys.SpielerprofilGeburtsdatum]: "Fecha nacimiento",
  [LanguageKeys.SpielerprofilVerletzungstage]: "Días lesionado",
  [LanguageKeys.SpielerprofilBerater]: "Agente",
  [LanguageKeys.SpielerprofilZumWyScoutProfil]: "Ver perfil WyScout",
  [LanguageKeys.SpielerprofilZum]: "Ver perfil",
  [LanguageKeys.SpielerprofilProfil]: "",
  [LanguageKeys.SpielerprofilMonateDeutsch]: "Meses en español (por ejemplo, Oct)",
  [LanguageKeys.SpielerprofilSubjektiveBewertungen]: "Valoraciones subjetivas",
  [LanguageKeys.SpielerprofilSpieler]: "Jugador",
  [LanguageKeys.SpielerprofilEventdatum]: "Fecha del evento",
  [LanguageKeys.SpielerprofilSpiel]: "Partido",
  [LanguageKeys.SpielerprofilScout]: "Ojeador",
  [LanguageKeys.SpielerprofilOverallRating]: "Valoración general",
  [LanguageKeys.SpielerprofilPotentialRating]: "Valoración potencial",
  [LanguageKeys.SpielerprofilJahrgangstyp]: "Tipo de quinta",
  [LanguageKeys.SpielerprofilZuletztGeandert]: "Última modificación",
  [LanguageKeys.SpielerprofilPositionImReport]: "Posición en el informe",
  [LanguageKeys.SpielerprofilSpiele]: "Partidos",
  [LanguageKeys.SpielerprofilMinuten]: "Minutos",
  [LanguageKeys.SpielerprofilTransferhistorie]: "Historial de traspasos",
  [LanguageKeys.SpielerprofilTransferMarktwerthistorie]: "Historial de mercado/traspasos",
  [LanguageKeys.SpielerprofilMarkthistorie]: "Historial de mercado",
  [LanguageKeys.SpielerprofilAktuellerMarktwert]: "Valor de mercado actual",
  [LanguageKeys.SpielerprofilMaximalerMarktwert]: "Valor de mercado máximo",
  [LanguageKeys.SpielerprofilSaison]: "Temporada",
  [LanguageKeys.SpielerprofilDatum]: "Fecha",
  [LanguageKeys.SpielerprofilAbgebenderVerein]: "Equipo de origen",
  [LanguageKeys.SpielerprofilAufnehmenderVerein]: "Equipo de destino",
  [LanguageKeys.SpielerprofilVerletzungshistorie]: "Historial de lesiones",
  [LanguageKeys.SpielerprofilStartVerletzung]: "Inicio de la lesión",
  [LanguageKeys.SpielerprofilEndeVerletzung]: "Fin de la lesión",
  [LanguageKeys.SpielerprofilDauer]: "Duración",
  [LanguageKeys.SpielerprofilVerletzung]: "Lesión",
  [LanguageKeys.SpielerprofilTorschuss]: "Tiros",
  [LanguageKeys.SpielerprofilHeatmap]: "Mapa de calor",
  [LanguageKeys.SpielerprofilFuß]: "Pie",
  [LanguageKeys.SpielerprofilKopf]: "Cabeza",
  [LanguageKeys.SpielerprofilTor]: "Gol",
  [LanguageKeys.SpielerprofilVersuch]: "Intento",
  [LanguageKeys.SpielerprofilLowxG]: "xG bajo",
  [LanguageKeys.SpielerprofilHighxG]: "xG alto",
  [LanguageKeys.SpielerprofilPreShot]: "Antes del tiro",
  [LanguageKeys.SpielerprofilPostShot]: "Después del tiro",
  [LanguageKeys.SpielerprofilKommentare]: "Comentarios",
  [LanguageKeys.SpielerprofilKeineKommentareVorhanden]: "No hay comentarios disponibles",
  [LanguageKeys.SpielerprofilSpeichern]: "Guardar",
  [LanguageKeys.SpielerprofilFugeEinenKommentarHinzu]: "Añadir un comentario...",
  [LanguageKeys.SpielerprofilAhnlicheSpieler]: "Jugadores similares",
  [LanguageKeys.SpielerprofilGewichtung]: "Peso",  // name Peso also used in FilterSpecificationService
  [LanguageKeys.SpielerprofilStammdaten]: "Datos generales", // name Datos generales also used in FilterSpecificationService
  [LanguageKeys.SpielerprofilAhnlichkeit]: "Similitud",
  [LanguageKeys.SpielerprofilSpalten]: "Columnas",
  [LanguageKeys.SpielerprofilZeilenProSeite]: "Filas por página",
  [LanguageKeys.SpielerprofilVideo]: "Video",
  [LanguageKeys.SpielerprofilVideoNichtVerfugbar]: "Video no disponible",
  [LanguageKeys.KeinVideovorhanden]: "No hay videos disponibles",
  [LanguageKeys.WatchlistAnzahlSichtungen]: "Número\nde vistas", // with linebreak
  [LanguageKeys.WatchlistLetzteSichtung]: "Última\nvista", // with linebreak 
  [LanguageKeys.WatchlistHinzugefugtAm]: "Añadido el",
  [LanguageKeys.SchattenmannschaftenFormation]: "Alineación",
  [LanguageKeys.SchattenmannschaftenZumSpielerprofil]: "Ver perfil del jugador",
  [LanguageKeys.SchattenmannschaftenSpielerEntfernen]: "Eliminar jugador",
  [LanguageKeys.SchattenmannschaftenMarkierungWahlen]: "Añadir etiqueta",
  [LanguageKeys.SchattenmannschaftenAnzahlSpieler]: "Número de jugadores",
  [LanguageKeys.SchattenmannschaftenGeteiltMit]: "Compartido con",
  [LanguageKeys.SchattenmannschaftenBank]: "Banquillo",
  [LanguageKeys.SchattenmannschaftenAutor]: "Autor",
  [LanguageKeys.SpielervergleichWarenkorb]: "Carrito",
  [LanguageKeys.Glossar]: "Glosario",
  [LanguageKeys.KeinZugriff]: "Sin acceso",
  [LanguageKeys.DieseSchattenmannschaftExistiertNicht]: "Este shadow team no existe",
  [LanguageKeys.OderDuHastKeinenZugriff]: "o no tienes acceso.",
  [LanguageKeys.NeueSchattenmannschaftErstellen]: "Crear nuevo shadow team",
  [LanguageKeys.SpielerHinzufugen]: "Agregar jugador",
  [LanguageKeys.JetztVergleichen]: "Comparación",
  [LanguageKeys.KeineSpielerimVergleich]: "No hay jugadores para comparar",
  [LanguageKeys.SpielerimVergleich]: "Jugadores comparados",
  [LanguageKeys.DatenWerdenGeladen]: "Cargando datos...",
  [LanguageKeys.Datenkonntennichtgeladenwerden]: "No se pudieron cargar los datos.",
  [LanguageKeys.Erstellungsdatum]: "Fecha de creación",
  [LanguageKeys.KeineTorschussdatenvorhanden]: "No hay datos de tiro disponibles",
  [LanguageKeys.KeineHeatmapdatenvorhanden]: "No hay mapas de calor disponibles",
  [LanguageKeys.KeineVerletzungsdatenvorhanden]: "No hay datos de lesiones disponibles",
  [LanguageKeys.KeineDatenvorhanden]: "No hay datos disponibles",
  [LanguageKeys.Gesamt]: "Total",
  [LanguageKeys.Gesamtrating]: "Valoración general",
  [LanguageKeys.WarnungVergleichePositionsbezogen]: "Estás comparando jugadores en diferentes posiciones. Recuerda que todas las valoraciones vienendeterminadas por la posición.",
  [LanguageKeys.SpielerinSchattenmannschaftAufPositionOhneDaten]: "¡El jugador está en a un shadow team en una posición para la cual no hay datos disponibles!",
  [LanguageKeys.SpielerKannNichtHinzugefuegtWerden]: "No se puede agregar el jugador",
  [LanguageKeys.SpielerbereitsinSchattenmannschaft]: "¡Este jugador ya está en tu shadow team!",
  [LanguageKeys.Achtung]: "Atención",
  [LanguageKeys.GesamtbewertungA]: "Valoración general (A)", // Valoración general (C), es decir, A de actual 
  [LanguageKeys.GesamtbewertungP]: "Valoración general (P)", // Valoración general (P), es decir, P de potencial 
  [LanguageKeys.SpielbewertungA]: "Valoración del partido (A)", // Valoración del juego (C), es decir, A de actual 
  [LanguageKeys.SpielbewertungP]: "Valoración del partido (P)", // Valoración del juego (P), es decir, P de potencial 
  [LanguageKeys.Loeschen]: "Eliminar",
  [LanguageKeys.Vertragsende]: "Fin de contrato",
  [LanguageKeys.Schattenmannschaft]: "Shadow team",
  [LanguageKeys.ExportKannMinutenDauern]: "La exportación puede tardar unos minutos.",
  [LanguageKeys.GenerierePDF]: "Generando PDF",
  [LanguageKeys.ExportFehlgeschlagen]: "La exportación ha fallado.",
  [LanguageKeys.KeineGesamtbewertungVorhanden]: "No hay valoraciones generales disponibles.",
  [LanguageKeys.KeineSpielbewertungVorhanden]: "No hay valoraciones de partidos disponibles.",
  [LanguageKeys.Gesamtbewertung]: "Valoraciones generales",
  [LanguageKeys.Spielbewertung]: "Valoraciones de partidos",
  [LanguageKeys.KeineMarktdatenVorhanden]: "No hay datos de mercado disponibles.",
  [LanguageKeys.Abloese]: "Cláusula de traspaso",
  [LanguageKeys.KeineTransferdatenVorhanden]: "No hay datos de traspasos disponibles.",
  [LanguageKeys.RatingsBasierenAufWenigerAls900Spielminuten]: "Las valoraciones se basan en menos de 900 minutos de tiempo de partido.",
  [LanguageKeys.Jahre]: "años",
  [LanguageKeys.Wettbewerb]: "Competición",
  [LanguageKeys.Tage]: "Días",
  [LanguageKeys.Karriere]: "Total",
  [LanguageKeys.Nein]: "No",
  [LanguageKeys.Ja]: "Sí",
  [LanguageKeys.DatenBeziehenSichAufDasLetzteJahr]: "Los datos hacen referencia al último año.",
  [LanguageKeys.Aktuell]: "Actual",
  [LanguageKeys.Potential]: "Potencial",
  [LanguageKeys.Dreierkette]: "Cadena de 3",
  [LanguageKeys.Viererkette]: "Cadena de 4",
  [LanguageKeys.AlleSpielerzumVergleichHinzufuegen]: "Añadir todos los jugadores a la comparación.",
  [LanguageKeys.Name]: "Nombre",
  [LanguageKeys.BitteWaehleEineFormationAus]: "Por favor seleccione una alineación.",
  [LanguageKeys.Benutzername]: "Nombre de usuario",
  [LanguageKeys.Zugriff]: "Acceder",
  [LanguageKeys.Lesen]: "Lectura",
  [LanguageKeys.Schreiben]: "Escritura",
  [LanguageKeys.Erstellen]: "Crear",
  [LanguageKeys.Abbrechen]: "Cancelar",
  [LanguageKeys.TeilenMit]: "Compartir con",
  [LanguageKeys.SchattenmannschaftLoeschen]: "Eliminar shadow team",
  [LanguageKeys.WillstDuDieSchattenmannschaft]: "¿Realmente quiere eliminar el shadow team", // part 1 of the phrase "Do you really want to delete the shadow team?", i.e. all words before shadow team
  [LanguageKeys.WirklichLoeschen]: "", // part 2 of the phrase "Do you really want to delete the shadow team?", i.e. all words after shadow team
  [LanguageKeys.GibEinenUsernamenEin]: "Por favor, introduzca un nombre de usuario.",
  [LanguageKeys.KeinenBenutzerGefunden]: "No se encontró ningún usuario.",
  [LanguageKeys.Spielerdetails]: "Detalles del jugador",
  [LanguageKeys.Login]: "Iniciar sesión",
  [LanguageKeys.Anmelden]: "Iniciar sesión",
  [LanguageKeys.Abmelden]: "Cerrar Sesión",
  [LanguageKeys.WaehleEineSchattenmannschaft]: "Elija un shadow team",
  [LanguageKeys.KeineSpielerGefunden]: "No se encontraron jugadores",
  [LanguageKeys.Alle]: "Todos",
  [LanguageKeys.AktuellesRating]: "Con clasificación actual",
  [LanguageKeys.AktuellesRatingShort]: "Con clasif. actual",
  [LanguageKeys.KeinAktuellesRating]: "Sin clasificación actual",
  [LanguageKeys.KeinAktuellesRatingShort]: "Sin clasif. actual",
  [LanguageKeys.PDFExport]: "Exportar PDF",
  [LanguageKeys.KeineGespeichertenFilterVorhanden]: "No hay filtros guardados disponibles",
  [LanguageKeys.ExistiertBereitsDurchSpeichernWirdEsUeberschrieben]: "ya existe, si lo guarda de nuevo se sobreescribirá.",
  [LanguageKeys.NameFilterung]: "Nombre del filtro",
  [LanguageKeys.MinMussKleinerAlsMaxWertSein]: "El mínimo debe ser inferior al máximo.",
  [LanguageKeys.WerteMuessenZwischen]: "Los valores deben estar comprendidos entre",
  [LanguageKeys.Und]: "y",
  [LanguageKeys.Liegen]: ".",
  [LanguageKeys.SpielerGefunden]: "jugadores encontrados",
  [LanguageKeys.FilterAusgewaehlt]: "Filtro seleccionado",
  [LanguageKeys.SchattenmannschaftBearbeiten]: "Editar shadow team",
  [LanguageKeys.Standardformation]: "Alineación por defecto",
  [LanguageKeys.Fehler]: "Error",
  [LanguageKeys.Beim_BeimSpeichernFehlerAufgetreten]: "Se ha producido un error al ",
  [LanguageKeys.Speichern_BeimSpeichernFehlerAufgetreten]: "guardar ",
  [LanguageKeys.Updaten_BeimUpdatenFehlerAufgetreten]: "actualizar ",
  [LanguageKeys.DeinesFiltersIstEinFehlerAufgetreten]: "el filtro.",
  [LanguageKeys.DeinFilterWurdeErfolgreich]: "Su filtro se ha ",
  [LanguageKeys.Geupdatet]: "actualizado correctamente",
  [LanguageKeys.Gespeichert]: "guardado correctamente",
  [LanguageKeys.WaehleEineSchattenmannschaftUndEinePositionAus]: "Seleccione un shadow team y una posición",
  [LanguageKeys.DieserSpielerIstBereitsInFolgendenSchattenmannschaften]: "El jugador se encuentra en los siguientes shadow teams:",
  [LanguageKeys.VonderWatchlistEntfernen]: "Eliminar de la lista de seguimiento",
  [LanguageKeys.DieserSpielerIstBereitsInDeinerWatchlist]: "Este jugador ya está en la lista de seguimiento",
  [LanguageKeys.EsKoennenNurSpielerHinzugefuegtWerdenZuDenenPositionsdatenExistieren]: "Solo se pueden añadir jugadores para los que existan datos en esta posición.",
  [LanguageKeys.DieserSpielerIstBereitsImWarenkorb]: "Este jugador ya está en la carrito de la compra",
  [LanguageKeys.SpielerErfolgreichZurWatchlistHinzugefuegt]: "Jugador añadido con éxito a la lista de seguimiento",
  [LanguageKeys.Keine]: "Ninguno",
  [LanguageKeys.EsIstKeinePositionAusgewaehlt]: "No se ha seleccionado ninguna posición",
  [LanguageKeys.SortierenNach]: "Ordenar por",
  [LanguageKeys.WenigerAnzeigen]: "Ver menos",
  [LanguageKeys.MehrAnzeigen]: "Ver más",
  [LanguageKeys.Bis]: "hasta",
  [LanguageKeys.Ab]: "de",
  [LanguageKeys.ErrorForbidden]: "Usted no tiene suficientes permisos.",
  [LanguageKeys.ErrorGeneral]: "Ocurrió un error.",

};
