import {
  Avatar,
  Box,
  Button,
  Flex,
  FlexProps,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
} from "@chakra-ui/react";
import { AiOutlineLogin } from "react-icons/ai";
import { FiChevronDown, FiGlobe } from "react-icons/fi";
import ComparisonNavbarMenu from "./comparison/ComparisonNavbarMenu";

import { useKeycloak } from "@react-keycloak/web";
import noProfilePicture from "../assets/no-profile-picture.png";
import { Loggedin, onClickLogout, onLogin } from "../util/keycloak";
import SearchBar from "./SearchBar";
import { useNavigate } from 'react-router-dom';
import LanguageSelection from "./general/LanguageSelection";
import { useContext } from "react";
import { LanguageContext } from "../store/languageContext";
import { LanguageKeys } from "../language/LanguageKeys";
import { colorsSecondary, textColors } from "../colorStyles";
interface MobileProps extends FlexProps {
  onOpen: () => void;
}

function GlossaryButton() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/glossary");
  };

  return (
    <IconButton
      onClick={handleClick}
      size="lg"
      variant="outline"
      borderColor={colorsSecondary.cardBackground}
      icon={<FiGlobe color={textColors.lightgrey} />}
      aria-label="open menu"
      data-cy={"glossaryButton"}
    />
  );
}

const NavBar = ({ onOpen, ...rest }: MobileProps) => {
  const { guiText } = useContext(LanguageContext);
  const { keycloak } = useKeycloak();

  const idToken = keycloak.idTokenParsed;

  return (
    <Flex
      ml={{ base: 0, md: 0 }}
      px={{ base: 4, md: 4 }}
      height="100%"
      width="100%"
      alignItems="center"
      bg={colorsSecondary.background}
      borderBottomWidth="1px"
      borderBottomColor={colorsSecondary.background}
      justifyContent={{ base: "space-between", md: "flex-end" }}
      {...rest}
      data-cy={"navBar"}
    >
      <SearchBar />
      <HStack spacing={{ base: "0", md: "6" }} ml={3}>
        <ComparisonNavbarMenu />
        <GlossaryButton />
        <LanguageSelection />

        {Loggedin() ? (
          <Flex alignItems={"center"}>
            <Menu id="navbar-user-menu">
              <MenuButton
                py={2}
                transition="all 0.3s"
                _focus={{ boxShadow: "none" }}
                data-cy={"userMenuButton"}
              >
                <HStack>
                  <Avatar size={"sm"} src={noProfilePicture} />
                  <VStack
                    display={{ base: "none", md: "flex" }}
                    alignItems="flex-start"
                    spacing="0px"
                    ml="2"
                    mr="5"
                  >
                    <Text fontSize="sm" whiteSpace="nowrap" data-cy={"fullName"} color={textColors.lightgrey}>
                      {idToken?.given_name + " " + idToken?.family_name}
                    </Text>
                    <Text fontSize="xs" color={textColors.lightgrey} data-cy={"username"}>
                      @{idToken?.preferred_username}
                    </Text>
                  </VStack>
                  <Box display={{ base: "none", md: "flex" }}>
                    <FiChevronDown />
                  </Box>
                </HStack>
              </MenuButton>
              <MenuList bg="white" borderColor="gray.200" zIndex={9999} data-cy={"userMenuDropdown"}>
                {/* <MenuItem>Profile</MenuItem>
                <MenuItem>Settings</MenuItem>
                <MenuItem>Billing</MenuItem>
                <MenuDivider /> */}
                <MenuItem onClick={onClickLogout}>{guiText[LanguageKeys.Abmelden]}</MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        ) : (
          <Button
            id="navbar-button-login"
            leftIcon={<AiOutlineLogin />}
            variant="outline"
            fontWeight="thin"
            onClick={() => {
              onLogin();
            }}
          >
            {guiText[LanguageKeys.Anmelden]}
          </Button>
        )}
      </HStack>
    </Flex>
  );
};

export default NavBar;
