import { IoIosTrendingUp } from "react-icons/io";
import { useTopTrendShortPlayers } from "../../hooks/useHome";
import Top5PlayersList from "./Top5PlayersList";
import { useContext } from "react";
import { LanguageContext } from "../../store/languageContext";
import { LanguageKeys } from "../../language/LanguageKeys";

const TopTrendShortPlayers = () => {
  const { guiText } = useContext(LanguageContext);

  const topTrendShortPlayers = useTopTrendShortPlayers();

  return (
    <Top5PlayersList
      title="Top alleyes Mover"
      subtitle={guiText[LanguageKeys.KurzfristigerTrend]}
      players={topTrendShortPlayers.data ?? []}
      icon={<IoIosTrendingUp size={40} color="white" />}
      trend="short"
    ></Top5PlayersList>
  );
};
export default TopTrendShortPlayers;
