import { CheckIcon, CloseIcon, EditIcon } from "@chakra-ui/icons";
import {
  Box,
  ButtonGroup,
  Editable,
  EditableInput,
  EditablePreview,
  EditableTextarea,
  Flex,
  IconButton,
  Input,
  Text,
  Textarea,
  Tooltip,
  useEditableControls,
} from "@chakra-ui/react";
import { useKeycloak } from "@react-keycloak/web";
import React, { useEffect, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import ResizeTextarea from "react-textarea-autosize";
import { Comment } from "../../../generated";
import { useDeleteComment, useEditComment } from "../../../hooks/usePlayer";
import { formatDateTime } from "../../../util/utilFunctions";
import { colorsSecondary, textColors } from "../../../colorStyles";

interface Props {
  comment: Comment;
  id: number;
}

interface EditableProps {
  isEditing: boolean;
  submit: () => void;
  cancel: () => void;
  edit: () => void;
  deletee: () => void;
}

const EditableControls = ({
  isEditing,
  submit,
  cancel,
  edit,
  deletee,
}: EditableProps) => {
  return isEditing ? (
    <ButtonGroup justifyContent="center" size="sm">
      <IconButton aria-label="submit" icon={<CheckIcon />} onClick={submit} />
      <IconButton aria-label="cancel" icon={<CloseIcon />} onClick={cancel} />
    </ButtonGroup>
  ) : (
    <ButtonGroup justifyContent="center" size="sm" spacing={1}>
      <IconButton
        aria-label="edit"
        size="sm"
        icon={<EditIcon />}
        onClick={edit}
      />
      <IconButton
        onClick={deletee}
        icon={<AiFillCloseCircle size={15} />}
        aria-label="delete"
        color="gray.500"
      />
    </ButtonGroup>
  );
};

const CommentComponent = ({ comment, id }: Props) => {
  const k = useKeycloak();
  const userId = k.keycloak.idTokenParsed?.sub;

  const canEdit = comment.authorId == userId;

  const [value, setValue] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  const deleteComment = useDeleteComment();
  const editComment = useEditComment();

  const edit = () => {
    setIsEditing(true);
  };

  const cancel = () => {
    setIsEditing(false);
    setValue(comment.text);
  };

  const submit = () => {
    setIsEditing(false);
    editComment({ playerId: id, comment: { ...comment, text: value } });
  };

  const deletee = () => {
    deleteComment({ playerId: id, commentId: comment.commentId });
    console.log("delete");
  };

  const handleChange = (event: any) => setValue(event.target.value);

  useEffect(() => {
    setValue(comment.text);
  }, [comment]);

  return (
    <Box display="flex" m={1} backgroundColor={colorsSecondary.background} color={textColors.primary}>
      <Editable
        value={value}
        fontSize="2xl"
        isPreviewFocusable={false}
        selectAllOnFocus={false}
        border="1px solid #E2E8F0"
        borderRadius={5}
        p={1}
        display="flex"
        flexDir="column"
        flex={1}
      >
        {!isEditing && (
          <EditablePreview py={2} px={4} fontSize={16} whiteSpace="pre-wrap" />
        )}
        {isEditing && (
          <Textarea
            as={ResizeTextarea}
            resize="none"
            onChange={handleChange}
            minH="unset"
            overflow="hidden"
            minRows={1}
            value={value}
            fontSize={16}
            _focus={{ borderColor: colorsSecondary.accent }}
          />
        )}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pl={4}

        >
          <Text fontSize={14} color="gray.400">
            {comment.authorName + " | " + formatDateTime(comment.added)}
          </Text>
          {canEdit && (
            <EditableControls
              isEditing={isEditing}
              submit={submit}
              cancel={cancel}
              edit={edit}
              deletee={deletee}
            />
          )}
        </Box>
      </Editable>
    </Box>
  );
};

export default CommentComponent;
