import {
  Box,
  GridItem,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
  useToast,
  useTab,
  TabProps,
} from "@chakra-ui/react";
import { useKeycloak } from "@react-keycloak/web";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Comments from "../components/profile/comments/Comments";
import DateRangeSelector from "../components/profile/DateRangeSelector";
import HeatmapGoals from "../components/profile/heatmapGoals/HeatmapGoals";
import InjuryHistory from "../components/profile/injuryHistory/InjuryHistory";
import PlayerBaseData from "../components/profile/PlayerBaseData";
import PolarCharts from "../components/profile/polarCharts/PolarCharts";
import { default as ScoutingReports } from "../components/profile/scoutingReports/ScoutingReports";
import SimilarPlayers from "../components/profile/similarPlayers/SimilarPlayers";
import TransferMarketHistory from "../components/profile/transferMarketHistory/TransferMarketHistory";
import PlayerVideos from "../components/profile/video/PlayerVideos";
import PlayerModal from "../components/search/playerGrid/PlayerModal";
import { usePlayerProfileHistoryStore, usePlayerProfileStore } from "../store/store";
import roles from "../util/roles";
import { Player } from "../generated";
import { LanguageContext } from "../store/languageContext";
import { LanguageKeys } from "../language/LanguageKeys";
import { colorsSecondary, textColors } from "../colorStyles";
import PieChartIcon from '@mui/icons-material/PieChart';
import EditNoteIcon from '@mui/icons-material/EditNote';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
interface CustomTabProps extends TabProps {
  icon: React.ElementType;
}

const CustomTab = ({ icon: Icon, ...props }: CustomTabProps) => {
  const tabProps = useTab(props);
  const isSelected = !!tabProps["aria-selected"];

  return (
    <Box
      as="button"
      {...tabProps}
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="row"
      p={2}
      borderRadius="md"
      _selected={{ color: colorsSecondary.accent }}
    >
      <Icon
        style={{
          fill: isSelected ? colorsSecondary.accent : textColors.lightgrey,
          marginRight: '8px', // Adjust the value as needed
        }}
      />
      <Box as="span" color={isSelected ? colorsSecondary.accent : textColors.lightgrey}>
        {props.children}
      </Box>
    </Box>
  );
};

const PlayerProfile = () => {
  const { guiText } = useContext(LanguageContext);

  const k = useKeycloak();
  const hasRoleWatchVideos = k.keycloak.hasResourceRole(roles.watch_videos);
  const hasRoleSearchPlayers = k.keycloak.hasResourceRole(roles.search_players);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { id } = useParams();

  const { date, setDate } = usePlayerProfileStore();

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const position = queryParams.get("position")!;


  const toast = useToast();
  useEffect(() => {
    if (position === "") {
      toast({
        title: guiText[LanguageKeys.Achtung] + "!",
        description: guiText[LanguageKeys.EsIstKeinePositionAusgewaehlt] + "!",
        status: "warning",
        duration: 5000,
        isClosable: true,
        variant: "subtle",
      });
    }
  }, []);

  // reset store on unmount
  useEffect(() => {
    if (id !== undefined && visitedPlayerProfiles.has(id)) {
      setDate(visitedPlayerProfiles.get(id)!.date);
      setTabIndex(visitedPlayerProfiles.get(id)!.tab);
    } else {
      setDate(new Date());
      setTabIndex(0);
    }
  }, []);

  const { players: visitedPlayerProfiles, pushPlayer } = usePlayerProfileHistoryStore();

  const [tabIndex, setTabIndex] = useState(
    id !== undefined && visitedPlayerProfiles.has(id) ? visitedPlayerProfiles.get(id)!.tab : 0
  );
  const navigate = useNavigate();
  const onClickPlayer = (player: Player) => {
    if (hasRoleSearchPlayers) {
      navigate(`/player/${player.id}?position=${player.positionsGruppe}&idTm=${player.idTm}`);
    }
  };

  useEffect(() => {
    if (id !== undefined && visitedPlayerProfiles.has(id)) {
      pushPlayer(visitedPlayerProfiles, { id: id, position: position, date: date, tab: tabIndex });
    } else {
      pushPlayer(visitedPlayerProfiles, { id: id!, position: position, date: date, tab: 0 });
    }
  }, [id, position, date, tabIndex]);

  return (
    <Box display="flex" pb={3} flex={1} overflow="auto">
      <SimpleGrid
        columns={2}
        backgroundColor={colorsSecondary.cardBackground}
        templateColumns={"310px 1fr"}
        borderRadius={5}
        flex={1}
        spacing={5}
        minH="800px"
        minW="1500px"
        overflow="hidden"
      >
        <GridItem
          p={4}
          height="100%"
          borderRight="2px solid #E2E8F0"
          overflow="hidden"
          display="flex"
        >
          <Box display="flex" flex={1} flexDir="column">
            <PlayerBaseData onOpen={onOpen} />
          </Box>
        </GridItem>

        <GridItem p={3} flex={1} display="flex" overflow="hidden">
          <Tabs
            index={tabIndex}
            onChange={(index) => setTabIndex(index)}
            isFitted
            variant="baseStyle"
            flex={1}
            isLazy
            lazyBehavior="unmount"
            overflow="hidden"
          >
            <TabList h="50px" display="flex" justifyContent="space-between">
              <CustomTab icon={PieChartIcon}>
                {guiText[LanguageKeys.SpielerprofilPolarchartsRatings]}
              </CustomTab>
              <CustomTab icon={EditNoteIcon}>
                {guiText[LanguageKeys.SpielerprofilSubjektiveBewertungen]}
              </CustomTab>
              <CustomTab icon={TransferWithinAStationIcon}>
                {guiText[LanguageKeys.SpielerprofilTransferMarktwerthistorie]}
              </CustomTab>
              <CustomTab icon={LocalHospitalIcon}>
                {guiText[LanguageKeys.SpielerprofilVerletzungshistorie]}
              </CustomTab>
              <CustomTab icon={SportsSoccerIcon}>
                {guiText[LanguageKeys.SpielerprofilTorschuss]}/ {guiText[LanguageKeys.SpielerprofilHeatmap]}
              </CustomTab>
              <CustomTab icon={ContentPasteIcon}>
                {guiText[LanguageKeys.SpielerprofilKommentare]}
              </CustomTab>
              <CustomTab icon={PersonSearchIcon}>
                {guiText[LanguageKeys.SpielerprofilAhnlicheSpieler]}
              </CustomTab>
              {hasRoleWatchVideos && <CustomTab icon={PlayArrowIcon}>Video</CustomTab>}

              <Box pl={3} w="200px">
                <DateRangeSelector usecase="profile" active={true} />
              </Box>
            </TabList>
            <TabPanels overflow="hidden" height="100%" display="flex" flex={1}>
              <TabPanel display="flex" flex={1}>
                <PolarCharts />
              </TabPanel>
              <TabPanel overflow="hidden" flex={1}>
                <ScoutingReports></ScoutingReports>
              </TabPanel>
              <TabPanel overflow="hidden" display="flex" flex={1}>
                <TransferMarketHistory />
              </TabPanel>
              <TabPanel overflow="hidden" display="flex" flex={1}>
                <InjuryHistory />
              </TabPanel>
              <TabPanel overflow="hidden" display="flex" flex={1}>
                <HeatmapGoals />
              </TabPanel>
              <TabPanel overflow="hidden" display="flex" flex={1}>
                <Comments useCase="profile" id={Number(id!)} />
              </TabPanel>
              <TabPanel overflow="hidden" display="flex" flex={1}>
                <SimilarPlayers position={position} onClickPlayer={onClickPlayer} />
              </TabPanel>
              {hasRoleWatchVideos && (
                <TabPanel overflow="hidden" display="flex" flex={1}>
                  <PlayerVideos id={Number(id!)} />
                </TabPanel>
              )}
            </TabPanels>
          </Tabs>
        </GridItem>
      </SimpleGrid>

      {isOpen && <PlayerModal isOpen={isOpen} onClose={onClose} location="profile" />}
    </Box>
  );
};

export default PlayerProfile;
