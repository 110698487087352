import { useToast } from "@chakra-ui/react";
import { useCallback, useContext } from "react";
import { Shadowteam, WatchlistEntry } from "../generated";
import { useComparisonStore } from "../store/store";
import { LanguageKeys } from "../language/LanguageKeys";
import { LanguageContext } from "../store/languageContext";

interface Props {
  shadowteamAside: Shadowteam | undefined;
  watchlist: WatchlistEntry[] | undefined;
  selection: string;
  droppableDestinations: string[];
}

const useDragAndDropComparison = ({
  shadowteamAside,
  watchlist,
  selection,
  droppableDestinations,
}: Props) => {
  const { guiText } = useContext(LanguageContext);

  const toast = useToast();

  const showWarningToast = (
    useCase: "differentPositions" | "noPositionData"
  ) => {
    let message = "";
    switch (useCase) {
      case "differentPositions":
        message = guiText[LanguageKeys.WarnungVergleichePositionsbezogen];
        break;
      case "noPositionData":
        message = guiText[LanguageKeys.SpielerinSchattenmannschaftAufPositionOhneDaten];
    }
    toast({
      title: guiText[LanguageKeys.Achtung] +"!",
      description: message,
      status: "error",
      duration: 5000,
      isClosable: true,
      variant: "subtle",
    });
  };

  const {
    players,
    setPlayers,
    player1,
    setPlayer1,
    player2,
    setPlayer2,
    player3,
    setPlayer3,
    clearPlayer1,
    clearPlayer2,
    clearPlayer3,
  } = useComparisonStore();

  const playerArray = [player1, player2, player3];
  const setPlayersArray = [setPlayer1, setPlayer2, setPlayer3];

  const handleDragEnd = useCallback(
    (result: any) => {
      const { destination, source, draggableId } = result;

      const key: string[] = draggableId.split("_");

      if (!destination) {
        return;
      }

      switch (destination.droppableId) {
        case "column1":
          clearPlayer1();
          break;
        case "column2":
          clearPlayer2();
          break;
        case "column3":
          clearPlayer3();
          break;
      }

      const playerList = (() => {
        switch (selection) {
          case LanguageKeys.SpielervergleichWarenkorb:
            return players;
          case LanguageKeys.SidebarWatchlist:
            return watchlist?.map((entry) => entry.player);
          case LanguageKeys.SidebarSchattenmannschaften:
            return shadowteamAside?.players.map(
              (shadowteamEntry) => shadowteamEntry.player
            );
          default:
            return [];
        }
      })();

      if (selection === LanguageKeys.SidebarSchattenmannschaften && key[1] === "") {
        showWarningToast("noPositionData");
        return;
      }

      const player = playerList?.find(
        (p) => p.id === Number(key[0]) && p.positionsGruppe === key[1]
      );

      droppableDestinations.forEach(function (destinations, columnIdx) {
        if (destinations.includes(destination.droppableId)) {
          setPlayersArray[columnIdx](player!);

          const otherPlayers = [0, 1, 2].filter(
            (playerIdx) => playerIdx !== columnIdx
          );
          for (const otherPlayer of otherPlayers) {
            if (
              playerArray[otherPlayer] &&
              player!.positionsGruppe !==
                playerArray[otherPlayer]!.positionsGruppe
            ) {
              showWarningToast("differentPositions");
              return;
            }
          }
        }
      });

      if (
        destination.droppableId === "comparisonList" &&
        destination.droppableId === source.droppableId &&
        selection === LanguageKeys.SpielervergleichWarenkorb
      ) {
        const newPlayers = Array.from(players);
        const [reorderedItem] = newPlayers.splice(source.index, 1);
        newPlayers.splice(destination.index, 0, reorderedItem);

        setPlayers(newPlayers);
      }
    },
    [players, shadowteamAside, watchlist, selection]
  );

  return { handleDragEnd };
};

export default useDragAndDropComparison;
