import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ms from "ms";
import { AddPlayerToWatchlistRequest, WatchlistEntry } from "../generated";
import { getWatchlistApi } from "../util/keycloak";

const fetchWatchlist = async (): Promise<WatchlistEntry[]> => {
  const api = getWatchlistApi();
  return api.getWatchlist();
};

export const useWatchlist = () => {
  return useQuery<WatchlistEntry[], Error>(
    ["watchlist"],
    () => fetchWatchlist(),
    {
      staleTime: ms("10h"),
    }
  );
};

//////////////////////////////////////////////////////////////

const addPlayerToWatchlist = async (request: AddPlayerToWatchlistRequest) => {
  const api = getWatchlistApi();
  await api.addPlayerToWatchlist(request);
};

export const useAddPlayerToWatchlist = () => {
  const queryClient = useQueryClient();
  // const showToast = useShowToast();

  const { mutate } = useMutation(addPlayerToWatchlist, {
    onSuccess: (_, context) => {
      queryClient.invalidateQueries({ queryKey: ["watchlist"] });
      queryClient.invalidateQueries({
        queryKey: ["isInWatchlist", context.playerId, context.positionsGruppe],
      });
      queryClient.invalidateQueries({
        queryKey: [
          "isInWatchlistOrShadowteam",
          context.playerId,
          context.positionsGruppe,
        ],
      });
    },
    onError: (error) => {
      console.error("Error adding player to watchlist:", error);
    },
  });

  return mutate;
};

//////////////////////////////////////////////////////////////

interface DeletePlayerRequest {
  playerId: number;
  position: string;
}

const deletePlayerFromWatchlist = async ({
  playerId,
  position,
}: DeletePlayerRequest) => {
  const api = getWatchlistApi();
  await api.deletePlayerFromWatchlist(playerId, position);
};

export const useDeletePlayerFromWatchlist = () => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation(deletePlayerFromWatchlist, {
    onSuccess: (_, context) => {
      queryClient.invalidateQueries({ queryKey: ["watchlist"] });
      queryClient.invalidateQueries({
        queryKey: ["isInWatchlist", context.playerId, context.position],
      });
      queryClient.invalidateQueries({
        queryKey: [
          "isInWatchlistOrShadowteam",
          context.playerId,
          context.position,
        ],
      });
    },

    onError: (error) => {
      console.error("Error deleting player from watchlist:", error);
    },
  });

  return mutate;
};

/////////////////////////////////////////////////////////////////

interface ToggleFavoriteRequest {
  playerId: number;
  position: string;
}

const toggleFavoriteStatus = async ({
  playerId,
  position,
}: ToggleFavoriteRequest) => {
  const api = getWatchlistApi();
  await api.toggleFavoriteStatus(playerId, position);
};

export const useToggleFavoriteStatus = () => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation(toggleFavoriteStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["watchlist"] });
    },
    onError: (error) => {
      console.error("Error toggling favorite status:", error);
    },
  });

  return mutate;
};
