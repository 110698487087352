/* tslint:disable */
/* eslint-disable */
/**
 * alleyes SCOUT BACKEND
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ShadowteamOverview
 */
export interface ShadowteamOverview {
    /**
     * 
     * @type {number}
     * @memberof ShadowteamOverview
     */
    shadowteamId: number;
    /**
     * 
     * @type {string}
     * @memberof ShadowteamOverview
     */
    shadowteamName?: string;
    /**
     * 
     * @type {string}
     * @memberof ShadowteamOverview
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof ShadowteamOverview
     */
    userName: string;
    /**
     * 
     * @type {string}
     * @memberof ShadowteamOverview
     */
    formation: string;
    /**
     * 
     * @type {Date}
     * @memberof ShadowteamOverview
     */
    lastChanged: Date;
    /**
     * 
     * @type {Date}
     * @memberof ShadowteamOverview
     */
    validFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ShadowteamOverview
     */
    validTo?: Date;
    /**
     * 
     * @type {number}
     * @memberof ShadowteamOverview
     */
    noOfPlayers: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ShadowteamOverview
     */
    sharedWith: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ShadowteamOverview
     */
    sharedWithNames?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ShadowteamOverview
     */
    sharedWithAccess?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ShadowteamOverview
     */
    favorite?: boolean;
}

/**
 * Check if a given object implements the ShadowteamOverview interface.
 */
export function instanceOfShadowteamOverview(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "shadowteamId" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "userName" in value;
    isInstance = isInstance && "formation" in value;
    isInstance = isInstance && "lastChanged" in value;
    isInstance = isInstance && "noOfPlayers" in value;
    isInstance = isInstance && "sharedWith" in value;

    return isInstance;
}

export function ShadowteamOverviewFromJSON(json: any): ShadowteamOverview {
    return ShadowteamOverviewFromJSONTyped(json, false);
}

export function ShadowteamOverviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShadowteamOverview {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shadowteamId': json['shadowteamId'],
        'shadowteamName': !exists(json, 'shadowteamName') ? undefined : json['shadowteamName'],
        'userId': json['userId'],
        'userName': json['userName'],
        'formation': json['formation'],
        'lastChanged': (new Date(json['lastChanged'])),
        'validFrom': !exists(json, 'valid_from') ? undefined : (new Date(json['valid_from'])),
        'validTo': !exists(json, 'valid_to') ? undefined : (new Date(json['valid_to'])),
        'noOfPlayers': json['noOfPlayers'],
        'sharedWith': json['sharedWith'],
        'sharedWithNames': !exists(json, 'sharedWithNames') ? undefined : json['sharedWithNames'],
        'sharedWithAccess': !exists(json, 'sharedWithAccess') ? undefined : json['sharedWithAccess'],
        'favorite': !exists(json, 'favorite') ? undefined : json['favorite'],
    };
}

export function ShadowteamOverviewToJSON(value?: ShadowteamOverview | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shadowteamId': value.shadowteamId,
        'shadowteamName': value.shadowteamName,
        'userId': value.userId,
        'userName': value.userName,
        'formation': value.formation,
        'lastChanged': (value.lastChanged.toISOString()),
        'valid_from': value.validFrom === undefined ? undefined : (value.validFrom.toISOString()),
        'valid_to': value.validTo === undefined ? undefined : (value.validTo.toISOString()),
        'noOfPlayers': value.noOfPlayers,
        'sharedWith': value.sharedWith,
        'sharedWithNames': value.sharedWithNames,
        'sharedWithAccess': value.sharedWithAccess,
        'favorite': value.favorite,
    };
}

