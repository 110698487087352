import {
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useShotHistory } from "../../../hooks/usePlayer";
import GoalsMap from "./GoalsMap";
import GoalsMapLegend from "./GoalsMapLegend";
import Heatmap from "./Heatmap";
import { LanguageContext } from "../../../store/languageContext";
import { LanguageKeys } from "../../../language/LanguageKeys";

const HeatmapGoals = () => {
  const { guiText } = useContext(LanguageContext);
  const { id } = useParams();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const pos = queryParams.get("position");

  const { data: shots, isLoading } = useShotHistory(Number(id!), pos!);

  return (
    <Tabs variant="baseStyle" mt={2} flex={1} display="flex">
      <TabList h="50px" alignSelf="end" pb={1}>
        <Tab>{guiText[LanguageKeys.SpielerprofilTorschuss]}</Tab>
        <Tab>{guiText[LanguageKeys.SpielerprofilHeatmap]}</Tab>
      </TabList>
      <TabPanels overflow="hidden" display="flex" flex={1} flexDir="column">
        <TabPanel display="flex" flex={1} flexDir="column">
          <HStack justifyContent="space-evenly" flex={1} display="flex">
            <GoalsMap shots={shots} isLoading={isLoading} postShot={false} />
            <GoalsMap shots={shots} isLoading={isLoading} postShot={true} />
          </HStack>
          {shots && shots.length > 0 && <GoalsMapLegend />}
        </TabPanel>
        <TabPanel display="flex" flex={1} flexDir="column">
          <Heatmap id={Number(id)} position={pos!}></Heatmap>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default HeatmapGoals;
