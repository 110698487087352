import { HStack } from "@chakra-ui/react";
import { colorsSecondary } from "../../colorStyles";
import DateRangeSelector from "../profile/DateRangeSelector";

interface Props {
  columns: string[];
  active: boolean[];
}
const DatePickerRow = ({ columns, active }: Props) => {
  return (
    <HStack
      pb={2}
      display="flex"
      spacing={5}
      height="55px"
      position="sticky"
      top="0"
      zIndex={1000}
      bg={colorsSecondary.background}
      _before={{
        content: `""`,
        position: "absolute",
        width: "100%",
        height: "100%",
        bg: colorsSecondary.background,
      }}
      width="100%"
    >
      {columns.map((column, idx) => (
        <DateRangeSelector
          usecase={column}
          active={active[idx]}
          height="45px"
          key={column}
        />
      ))}
    </HStack>
  );
};

export default DatePickerRow;
