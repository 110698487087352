/* tslint:disable */
/* eslint-disable */
/**
 * alleyes SCOUT BACKEND
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Player } from './Player';
import {
    PlayerFromJSON,
    PlayerFromJSONTyped,
    PlayerToJSON,
} from './Player';

/**
 * 
 * @export
 * @interface ShadowteamEntry
 */
export interface ShadowteamEntry {
    /**
     * 
     * @type {Player}
     * @memberof ShadowteamEntry
     */
    player: Player;
    /**
     * 
     * @type {string}
     * @memberof ShadowteamEntry
     */
    shadowteamPosition: string;
    /**
     * 
     * @type {string}
     * @memberof ShadowteamEntry
     */
    color?: string;
    /**
     * 
     * @type {number}
     * @memberof ShadowteamEntry
     */
    prio: number;
    /**
     * 
     * @type {Date}
     * @memberof ShadowteamEntry
     */
    validFrom?: Date;
    /**
     * 
     * @type {string}
     * @memberof ShadowteamEntry
     */
    addedBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof ShadowteamEntry
     */
    validTo?: Date;
    /**
     * 
     * @type {string}
     * @memberof ShadowteamEntry
     */
    deletedBy?: string;
}

/**
 * Check if a given object implements the ShadowteamEntry interface.
 */
export function instanceOfShadowteamEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "player" in value;
    isInstance = isInstance && "shadowteamPosition" in value;
    isInstance = isInstance && "prio" in value;

    return isInstance;
}

export function ShadowteamEntryFromJSON(json: any): ShadowteamEntry {
    return ShadowteamEntryFromJSONTyped(json, false);
}

export function ShadowteamEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShadowteamEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'player': PlayerFromJSON(json['player']),
        'shadowteamPosition': json['shadowteamPosition'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'prio': json['prio'],
        'validFrom': !exists(json, 'valid_from') ? undefined : (new Date(json['valid_from'])),
        'addedBy': !exists(json, 'added_by') ? undefined : json['added_by'],
        'validTo': !exists(json, 'valid_to') ? undefined : (new Date(json['valid_to'])),
        'deletedBy': !exists(json, 'deleted_by') ? undefined : json['deleted_by'],
    };
}

export function ShadowteamEntryToJSON(value?: ShadowteamEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'player': PlayerToJSON(value.player),
        'shadowteamPosition': value.shadowteamPosition,
        'color': value.color,
        'prio': value.prio,
        'valid_from': value.validFrom === undefined ? undefined : (value.validFrom.toISOString()),
        'added_by': value.addedBy,
        'valid_to': value.validTo === undefined ? undefined : (value.validTo.toISOString()),
        'deleted_by': value.deletedBy,
    };
}

