import {
  Badge,
  Box,
  Flex,
  HStack,
  Spacer,
  Stat,
  StatArrow,
  StatHelpText,
  Text,
} from "@chakra-ui/react";
import { Player } from "../../generated";
import { useNavigateToPlayer } from "../../hooks/usePlayer";
import { formatDate, positionAbbreviation } from "../../util/utilFunctions";
import RatingBadgeStack from "../general/RatingBadgeStack";
import StammdatenColumnView from "../search/playerTable/StammdatenColumnView";
import { LanguageKeys } from "../../language/LanguageKeys";
import { useContext } from "react";
import { LanguageContext } from "../../store/languageContext";
import { textColors } from "../../colorStyles";

interface Props {
  player: Player;
  trend?: number;
  small?: boolean;
}

const PlayerSummaryDetailed = ({ player, trend, small = false }: Props) => {
  const { guiText } = useContext(LanguageContext);
  const navigateToPlayer = useNavigateToPlayer();

  return (
    <Box
      onClick={() => navigateToPlayer(player)}
      cursor="pointer"
      width="100%"
      px={2}
      className="hover-highlight"
      zIndex={99999}
    >
      <Flex flexDir="row" alignItems="center">
        <Box width={"35%"} display="flex" alignItems="center" data-cy={"stammdaten"}>
          <StammdatenColumnView
            id={player.id}
            idTm={player.idTm}
            firstName={player.firstName}
            surName={player.surname}
            nationality={player.nationality}
            club={player.team}
            league={player.league}
            positionsGruppe={player.positionsGruppe}
            size="large"
          />
        </Box>
        <Spacer />
        <Badge
          variant="outline"
          colorScheme="gray"
          py={1}
          px={4}
          mr={2}
          alignContent="center"
          alignItems="center"
          display="flex"
          height="1.7rem"
          width="5%"
          justifyContent="center"
          data-cy={"position"}
        >
          {positionAbbreviation(player.positionsGruppe)}
        </Badge>

        {!small && (
          <>
            <Box width="15%" data-cy={"age"} color={textColors.primary}>
              <Text fontSize="medium" lineHeight={1}>
                {player.age}
              </Text>
              <Text fontSize="medium" lineHeight={1}>
                {guiText[LanguageKeys.Jahre]}
              </Text>
            </Box>
            <Text fontSize="medium" lineHeight={1} width="10%" data-cy={"marketValue"} color={textColors.primary}>
              {player.marketValue
                ? player.marketValue.toLocaleString("de-DE") + " €"
                : "-"}
            </Text>
            <Box width="15%" data-cy={"contractExpiryDate"} color={textColors.primary}>
              <Text fontSize="medium" lineHeight={1}>
                {guiText[LanguageKeys.Vertragsende]}:
              </Text>
              <Text fontSize="medium" >
                {player.contractExpiryDate
                  ? formatDate(player.contractExpiryDate)
                  : "-"}
              </Text>
            </Box>
          </>
        )}
        <Spacer />
        <HStack display="flex" flexShrink={0} justifyContent="flex-end">
          {trend && (
            <Stat
              alignItems="center"
              justifyContent="center"
              alignContent="center"
              display="flex"
              pt={2}
              size="md"
              data-cy={"trend"}
            >
              <StatHelpText
                alignItems="center"
                justifyContent="center"
                alignContent="center"
                display="flex"
                color={textColors.primary}
              >
                <StatArrow type={trend > 0 ? "increase" : "decrease"} />
                {(trend > 0 ? "+ " : "- ") + Math.round(Math.abs(trend))}
              </StatHelpText>
            </Stat>
          )}
          <RatingBadgeStack player={player} />
        </HStack>
      </Flex>
    </Box>
  );
};

export default PlayerSummaryDetailed;
