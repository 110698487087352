import { IconButton } from "@chakra-ui/react";
import { FaEye } from "react-icons/fa";
import { colorsPrimary, colorsSecondary } from "../../colorStyles";
import React, { useContext } from "react";
import { useIsInWatchlistOrShadowteam } from "../../hooks/usePlayer";
import { Player } from "../../generated";
import { usePlayerModalStore } from "../../store/store";
import { LanguageContext } from "../../store/languageContext";
import { LanguageKeys } from "../../language/LanguageKeys";

interface Props {
  player: Player;
  onClick: () => void;
}

const WatchlistButton = ({ player, onClick }: Props) => {
  const { guiText } = useContext(LanguageContext);

  const { data: isInWatchlistOrShadowteam } = useIsInWatchlistOrShadowteam(player.id, player.positionsGruppe);
  const isInWatchlistOrShadowteamBoolean = Number(isInWatchlistOrShadowteam) === 1;

  const { setPlayer } = usePlayerModalStore();

  const WatchlistIcon =
    <FaEye
      size="30px"
      color={isInWatchlistOrShadowteamBoolean ? colorsSecondary.accent : "#CBD5E0"}
    />;

  return (
    <IconButton
      icon={WatchlistIcon}
      aria-label={guiText[LanguageKeys.WachlistButtonAdd]}
      onClick={(event) => {
        event.stopPropagation();
        setPlayer(player);
        onClick();
      }}
    />
  );
};
export default WatchlistButton;
