import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  List,
  ListItem,
  Text,
} from "@chakra-ui/react";
import debounce from "lodash.debounce";
import { ChangeEvent, useContext, useRef, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import { User } from "../../../generated";
import { useUsersByName } from "../../../hooks/useUsers";
import { LanguageKeys } from "../../../language/LanguageKeys";
import { LanguageContext } from "../../../store/languageContext";
import { colorsSecondary } from "../../../colorStyles";

interface Props {
  addUser: (user: User) => void;
  excludeUsers: string[];
}

const UserSearch = ({ addUser, excludeUsers }: Props) => {
  const { guiText } = useContext(LanguageContext);
  const [name, setName] = useState("");
  const [debouncedName, setDebouncedName] = useState("");

  const { data: users, isLoading } = useUsersByName(
    debouncedName,
    excludeUsers
  );

  const [isResultsBoxOpen, setIsResultsBoxOpen] = useState(false);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    debouncedOnChange(e.target.value);
  };

  const debouncedOnChange = debounce((searchValue) => {
    setDebouncedName(searchValue);
  }, 500);

  const blurTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleBlur = () => {
    blurTimeoutRef.current = setTimeout(() => {
      setIsResultsBoxOpen(false);
    }, 150);
  };

  const handleFocus = () => {
    if (blurTimeoutRef.current) {
      clearTimeout(blurTimeoutRef.current);
      blurTimeoutRef.current = null;
    }
    setIsResultsBoxOpen(true);
  };

  return (
    <Box w="100%">
      <InputGroup w="100%">
        <InputLeftElement>
          <BsSearch />
        </InputLeftElement>
        {name && (
          <InputRightElement onClick={() => setName("")}>
            <AiFillCloseCircle fill="#718096" />
          </InputRightElement>
        )}
        <Input
          placeholder={guiText[LanguageKeys.GibEinenUsernamenEin] + "..."}
          _hover={{}}
          onChange={onChange}
          value={name}
          onBlur={handleBlur}
          onFocus={handleFocus}
          _focus={{ borderColor: colorsSecondary.accent }}
        />
      </InputGroup>
      {name && isResultsBoxOpen && (
        <Box
          mt={4}
          position="absolute"
          bg={colorsSecondary.background}
          borderRadius={15}
          minW="25%"
          pt={2}
          pb={2}
          zIndex={1600}
          boxShadow="md"
        >
          {isLoading ? (
            <Text p={2}>Loading...</Text>
          ) : users?.length === 0 ? (
            <Text p={2}>{guiText[LanguageKeys.KeinenBenutzerGefunden]}</Text>
          ) : (
            <List>
              {users?.map((user, idx) => (
                <ListItem
                  key={user.userId}
                  pb={idx === users.length - 1 ? 0 : 2}
                  onClick={() => {
                    setName("");
                    addUser(user);
                  }}
                  style={{ cursor: "pointer" }}
                  _hover={{ backgroundColor: "rgba(0, 75, 156,0.1)" }}
                  p={2}
                >
                  <Text>{user.firstName + " " + user.lastName}</Text>
                </ListItem>
              ))}
            </List>
          )}
        </Box>
      )}
    </Box>
  );
};

export default UserSearch;
