import { Box, Center, Text } from "@chakra-ui/react";
import { useKeycloak } from "@react-keycloak/web";
import { useContext, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { LanguageContext } from "../../../store/languageContext";
import { LanguageKeys } from "../../../language/LanguageKeys";
import { textColors } from "../../../colorStyles";

interface Props {
  id: number;
}

const PlayerVideos = ({ id }: Props) => {
  const { guiText } = useContext(LanguageContext);
  const [videoUrl, setVideoUrl] = useState("");
  const [error, setError] = useState(false);
  const { keycloak } = useKeycloak();

  useEffect(() => {
    const generateVideoUrl = () => {
      if (!keycloak?.token) {
        console.error("No Keycloak token found");
        setError(true);
        return;
      }

      setError(false);

      const url =
        window.REACT_APP_API_BASEURL + `/player/${id}/video/` + keycloak.token;
      setVideoUrl(url);
    };

    generateVideoUrl();
  }, [id, keycloak?.token]);

  useEffect(() => {
    setError(false);
  }, [id]);

  const handleVideoError = (e: any) => {
    setError(true);
  };

  return (
    <>
      {error ? (
        <Center h="100%" w="100%" color={textColors.primary}>
          <Text fontSize={22}>{guiText[LanguageKeys.SpielerprofilVideoNichtVerfugbar]}</Text>
        </Center>
      ) : (
        <Box
          flex={1}
          display="flex"
          p="20%"
          alignContent="center"
          alignItems="center"
        >
          <ReactPlayer
            url={videoUrl}
            controls
            width="100%"
            height="auto"
            onError={handleVideoError}
          />
        </Box>
      )}
    </>
  );
};

export default PlayerVideos;
