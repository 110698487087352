import lookup from "country-code-lookup";
import { FlagIcon, FlagIconCode } from "react-flag-kit";

interface Props {
  nationality: string;
  size?: number;
}

const CountryFlag = ({ nationality, size = 48 }: Props) => {
  var countryCode =
    lookup.byCountry(nationality)?.iso2 ||
    lookup.byFips(nationality)?.iso2 ||
    lookup.byInternet(nationality)?.iso2 ||
    "";
  if (countryCode == "") {
    switch (nationality) {
      case "UK":
        countryCode = "GB";
        break;
      case "USA":
        countryCode = "US";
        break;
      case "England":
        countryCode = "GB-ENG";
        break;
      case "Gambia":
        countryCode = "GM";
        break;
      case "Congo DR":
      case "Congo":
        countryCode = "CD";
        break;
      case "Côte d'Ivoire":
        countryCode = "CI";
        break;
      case "Wales":
        countryCode = "GB-WLS";
        break;
      case "Macedonia FYR":
        countryCode = "MK";
        break;
      case "Ireland Republic":
        countryCode = "IE";
        break;
      case "Scotland":
        countryCode = "GB-SCT";
        break;
      case "Northern Ireland":
        countryCode = "GB-NIR";
        break;
      case "Korea Republic":
        countryCode = "KR";
        break;
      case "China PR":
        countryCode = "CN";
        break;
      case "Cape Verde Islands":
        countryCode = "CV";
        break;
      case "Kosovo":
        countryCode = "XK";
        break;
      case "Palestine":
        countryCode = "PS";
        break;
      case "Bosnia-Herzegovina":
        countryCode = "BA";
        break;

      default:
    }
  }

  return (
    <FlagIcon
      code={countryCode as FlagIconCode}
      size={size}
      title={nationality}
    />
  );
};

export default CountryFlag;
