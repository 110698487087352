import { useContext } from "react";
import { Player } from "../../generated";
import { getRatingColor } from "../../util/utilFunctions";
import RatingBadge from "./RatingBadge";
import { LanguageContext } from "../../store/languageContext";
import { LanguageKeys } from "../../language/LanguageKeys";

interface Props {
  player: Player;
  overall?: boolean;
}

const RatingBadgeStack = ({ player, overall = true }: Props) => {
  const { guiText } = useContext(LanguageContext);

  const alleyesRating = player.alleyes;
  const totalRating = overall
    ? player.subjectiveOverall
    : player.subjectivePotential;
  const gameRating = overall
    ? player.subjectiveGameRatingsOverall
    : player.subjectiveGameRatingsPotential;

  return (
    <>
      <RatingBadge
        label="alleyes"
        rating={alleyesRating ? Math.round(alleyesRating).toString() : undefined}
        backgroundColor={getRatingColor(alleyesRating)}
        brackets={!player.completeRating}
        data-cy="rating-badge-alleyes"
      />
      <RatingBadge
        label={guiText[LanguageKeys.Gesamt]}
        rating={totalRating?.toFixed(1)}
        backgroundColor={getRatingColor(totalRating, 0, 10)}
        data-cy="rating-badge-total"
      />
      <RatingBadge
        label={guiText[LanguageKeys.SpielerprofilSpiel]}
        rating={gameRating?.toFixed(1)}
        backgroundColor={getRatingColor(gameRating, 0, 10)}
        data-cy="rating-badge-game"
      />
    </>
  );
};
export default RatingBadgeStack;
