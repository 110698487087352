// hooks/useShadowteamInteractions.ts
import { cloneDeep } from "lodash";
import { useCallback, useState } from "react";
import { Shadowteam, ShadowteamEntry } from "../generated"; // Adjust the import path to your types

interface UseShadowteamInteractionsProps {
  quickViewPlayer: ShadowteamEntry | undefined;
  setQuickViewPlayer: (player: ShadowteamEntry) => void;
  shadowteam: Shadowteam | undefined;
  setShadowteam: (shadowteam: Shadowteam) => void;
  onOpenPositionDetails: () => void;
  onClosePositionDetails: () => void;
  onOpenQuickView: () => void;
  renderTrigger: boolean;
  setRenderTrigger: (renderTrigger: boolean) => void;
}

const useShadowteamInteractions = ({
  shadowteam,
  setShadowteam,
  onOpenPositionDetails,
  onClosePositionDetails,
  onOpenQuickView,
  quickViewPlayer,
  setQuickViewPlayer,
  renderTrigger,
  setRenderTrigger,
}: UseShadowteamInteractionsProps) => {
  const [detailedPosition, setDetailedPosition] = useState<string>("");

  const handleOpenPositionDetails = useCallback((position: string) => {
    setDetailedPosition(position);
    onOpenPositionDetails();
  }, []);

  const handleClosePositionDetails = useCallback(() => {
    setDetailedPosition("");
    onClosePositionDetails();
  }, []);

  const handleColorChange = useCallback(
    (color: string | undefined) => {
      const playerToChange = shadowteam?.players.find(
        (entry) => entry.player.id === quickViewPlayer?.player.id
      )!;
      playerToChange.color = color;

      setShadowteam(cloneDeep(shadowteam!));

      setRenderTrigger(!renderTrigger);
    },
    [quickViewPlayer, shadowteam, setShadowteam]
  );

  const onPlayerClick = useCallback((player: ShadowteamEntry) => {
    setQuickViewPlayer(player);
    onOpenQuickView();
  }, []);

  const deletePlayer = useCallback(
    (playerId: number) => {
      if (!shadowteam) return;

      const playerToDelete = shadowteam.players.find(
        (p) => p.player.id === playerId
      );

      if (!playerToDelete) return;

      const positionOfDeletedPlayer = playerToDelete.shadowteamPosition;

      const updatedPlayers = shadowteam.players.filter(
        (p) => p.player.id !== playerId
      );

      updatedPlayers
        .filter((p) => p.shadowteamPosition === positionOfDeletedPlayer)
        .sort((a, b) => a.prio - b.prio)
        .forEach((p, index) => {
          p.prio = index + 1;
        });

      setShadowteam({ ...shadowteam, players: updatedPlayers });
    },
    [shadowteam, setShadowteam]
  );

  return {
    detailedPosition,
    handleOpenPositionDetails,
    handleClosePositionDetails,
    handleColorChange,
    onPlayerClick,
    deletePlayer,
  };
};

export default useShadowteamInteractions;
