/* tslint:disable */
/* eslint-disable */
/**
 * alleyes SCOUT BACKEND
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FilterSpecification
 */
export interface FilterSpecification {
    /**
     * 
     * @type {number}
     * @memberof FilterSpecification
     */
    specId: number;
    /**
     * 
     * @type {string}
     * @memberof FilterSpecification
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof FilterSpecification
     */
    group: string;
    /**
     * 
     * @type {string}
     * @memberof FilterSpecification
     */
    subgroupGerman: string;
    /**
     * 
     * @type {string}
     * @memberof FilterSpecification
     */
    dbColumn?: string;
    /**
     * 
     * @type {string}
     * @memberof FilterSpecification
     */
    labelGerman: string;
    /**
     * 
     * @type {string}
     * @memberof FilterSpecification
     */
    specification: string;
    /**
     * 
     * @type {string}
     * @memberof FilterSpecification
     */
    labelEnglish: string;
    /**
     * 
     * @type {string}
     * @memberof FilterSpecification
     */
    labelSpanish: string;
    /**
     * 
     * @type {string}
     * @memberof FilterSpecification
     */
    subgroupEnglish: string;
    /**
     * 
     * @type {string}
     * @memberof FilterSpecification
     */
    subgroupSpanish: string;
}

/**
 * Check if a given object implements the FilterSpecification interface.
 */
export function instanceOfFilterSpecification(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "specId" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "group" in value;
    isInstance = isInstance && "subgroupGerman" in value;
    isInstance = isInstance && "labelGerman" in value;
    isInstance = isInstance && "specification" in value;
    isInstance = isInstance && "labelEnglish" in value;
    isInstance = isInstance && "labelSpanish" in value;
    isInstance = isInstance && "subgroupEnglish" in value;
    isInstance = isInstance && "subgroupSpanish" in value;

    return isInstance;
}

export function FilterSpecificationFromJSON(json: any): FilterSpecification {
    return FilterSpecificationFromJSONTyped(json, false);
}

export function FilterSpecificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilterSpecification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'specId': json['specId'],
        'type': json['type'],
        'group': json['group'],
        'subgroupGerman': json['subgroup_german'],
        'dbColumn': !exists(json, 'dbColumn') ? undefined : json['dbColumn'],
        'labelGerman': json['label_german'],
        'specification': json['specification'],
        'labelEnglish': json['label_english'],
        'labelSpanish': json['label_spanish'],
        'subgroupEnglish': json['subgroup_english'],
        'subgroupSpanish': json['subgroup_spanish'],
    };
}

export function FilterSpecificationToJSON(value?: FilterSpecification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'specId': value.specId,
        'type': value.type,
        'group': value.group,
        'subgroup_german': value.subgroupGerman,
        'dbColumn': value.dbColumn,
        'label_german': value.labelGerman,
        'specification': value.specification,
        'label_english': value.labelEnglish,
        'label_spanish': value.labelSpanish,
        'subgroup_english': value.subgroupEnglish,
        'subgroup_spanish': value.subgroupSpanish,
    };
}

