import {
  ChevronDownIcon,
  DeleteIcon,
} from "@chakra-ui/icons";
import {
  Menu,
  MenuButton,
  Text,
  MenuList,
  MenuItem,
  Button,
  Box,
  HStack,
  Stack,
  IconButton,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { LiaSave } from "react-icons/lia";
import { AiOutlineClear } from "react-icons/ai";

import { colorsPrimary, colorsSecondary, textColors } from "../../../colorStyles";
import {
  useDeleteFilterPreset,
  useFilterPresets,
} from "../../../hooks/usePresets";
import { useFilterPresetStore, useFilterValueStore } from "../../../store/store";
import FilterSavingModal from "./FilterSavingModal";
import { FilterPreset } from "../../../generated";
import { LanguageKeys } from "../../../language/LanguageKeys";
import { useContext } from "react";
import { LanguageContext } from "../../../store/languageContext";

const FilterPresets = () => {
  const { guiText } = useContext(LanguageContext);
  const { data, isLoading, isError } = useFilterPresets();
  const { getActivePreset, setActivePreset, clearActivePreset } =
    useFilterPresetStore();
  const { filterValues, clearFilters } = useFilterValueStore();

  const activePreset = getActivePreset();

  const deleteFilterPreset = useDeleteFilterPreset();

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box pb={3}>
      <Menu autoSelect={false}>
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon color={textColors.primary} />}
          backgroundColor={colorsSecondary.cardBackground}
          width="100%"
          boxShadow="sm"
        >
          <Text
            align="left"
            color={activePreset ? colorsSecondary.accent : textColors.lightgrey}
            fontWeight="thin"
          >
            {activePreset?.name || guiText[LanguageKeys.SearchBarGespeicherteFilterungAuswahlen]}
          </Text>
        </MenuButton>
        <MenuList zIndex={999} backgroundColor={colorsSecondary.cardBackground}>
          {data && data.length > 0 ? (
            data?.map((filterPreset: FilterPreset) => (
              <MenuItem
                key={filterPreset.id}
                onClick={() => setActivePreset(filterPreset)}
                as="div"
                role="menuitem"
                cursor="pointer"
                backgroundColor={colorsSecondary.cardBackground}
              >
                <HStack justifyContent="space-between" width="100%">
                  <Text>{filterPreset.name}</Text>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteFilterPreset(filterPreset.id);
                    }}
                    icon={<DeleteIcon />}
                    boxSize={8}
                    aria-label="Delete"
                    // outline={"1px solid " + colorsPrimary.dunkelblau40}
                    // background="white"
                    _hover={{
                      color: colorsSecondary.highlight1,
                    }}
                    color={colorsPrimary.dunkelblau40}
                  ></IconButton>
                </HStack>
              </MenuItem>
            ))
          ) : (
            <MenuItem isDisabled
              backgroundColor={colorsSecondary.cardBackground}
              color={textColors.primary}
            >{guiText[LanguageKeys.KeineGespeichertenFilterVorhanden]}</MenuItem>
          )}
        </MenuList>
      </Menu>
      <Stack direction="row" spacing={2} mt={2} width="100%">
        <Button
          leftIcon={<LiaSave fill={colorsSecondary.background} size={18} />}
          backgroundColor={colorsSecondary.accent}
          textColor={colorsSecondary.background}
          fontWeight="thin"
          fontSize="sm"
          width="50%"
          textAlign="center"
          boxShadow="sm"
          onClick={onOpen}
          isDisabled={filterValues.length === 0}
          _hover={{ bg: "" }}
        >
          {guiText[LanguageKeys.SearchBarFilterSpeichern]}
        </Button>
        <Button
          leftIcon={<AiOutlineClear size={18} color={textColors.lightgrey} />}
          color={textColors.lightgrey}
          // outline={"1px solid " + colorsPrimary.dunkelblau40}
          backgroundColor={colorsSecondary.cardBackground}
          fontWeight="thin"
          fontSize="sm"
          width="50%"
          onClick={() => {
            clearFilters();
            clearActivePreset();
          }}
          alignItems="center"
          boxShadow="sm"
        >
          {guiText[LanguageKeys.SearchBarZurucksetzen]}
        </Button>
      </Stack>
      <FilterSavingModal isOpen={isOpen} onClose={onClose} />
    </Box>
  );
};

export default FilterPresets;
