import { Box, Text, useDisclosure } from "@chakra-ui/react";
import { useKeycloak } from "@react-keycloak/web";
import { cloneDeep, isEqual } from "lodash";
import { useContext, useEffect, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { useLocation, useParams } from "react-router-dom";
import PlayerSelectAside from "../components/PlayerSelectAside";
import QuickViewModal from "../components/shadowteams/QuickViewModal";
import PositionDetailsModal from "../components/shadowteams/formation/PositionDetailsModal";
import ShadowteamFootballField from "../components/shadowteams/mainContent/ShadowteamFootballField";
import ShadowteamHeader from "../components/shadowteams/mainContent/ShadowteamHeader";
import { groupByPosition } from "../components/shadowteams/shadowteamUtilFunctions";
import ShadowteamTable from "../components/shadowteams/table/ShadowteamTable";
import { Shadowteam, ShadowteamEntry, ShadowteamOverview } from "../generated";
import useDragAndDropShadowteams from "../hooks/useDragAndDropShadowteams";
import useShadowteamInteractions from "../hooks/useShadowteamInteractions";
import { useGetShadowteam, useSaveShadowteam } from "../hooks/useShadowteams";
import { useWatchlist } from "../hooks/useWatchlist";
import { useShadowteamOverviewStore } from "../store/store";
import { LanguageContext } from "../store/languageContext";
import { LanguageKeys } from "../language/LanguageKeys";

const ShadowteamDetails = () => {
  const { guiText } = useContext(LanguageContext);

  const { clickedOnShadowteamName, setclickedOnShadowteamName } =
    useShadowteamOverviewStore();
  const [renderTrigger, setRenderTrigger] = useState(false);

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const formation = queryParams.get("formation") ?? undefined;

  const { id } = useParams();

  const saveShadowteam = useSaveShadowteam();

  const { data, error } = useGetShadowteam(Number(id!), formation);

  const [shadowteam, setShadowteam] = useState<Shadowteam>();

  const players: Map<string, ShadowteamEntry[]> = groupByPosition(
    shadowteam?.players
  );

  const [quickViewPlayer, setQuickViewPlayer] = useState<
    ShadowteamEntry | undefined
  >(undefined);

  const [selection, setSelection] = useState(LanguageKeys.SpielervergleichWarenkorb);
  const [shadowteamSelection, setShadowteamSelection] = useState<
    ShadowteamOverview | undefined
  >(undefined);

  const { data: shadowteamAside } = useGetShadowteam(
    shadowteamSelection?.shadowteamId ?? -1
  );

  const [view, setView] = useState("field");

  const { data: watchlist } = useWatchlist();

  const {
    isOpen: isOpenQuickView,
    onOpen: onOpenQuickView,
    onClose: onCloseQuickView,
  } = useDisclosure();

  const {
    isOpen: isOpenPositinDetails,
    onOpen: onOpenPositionDetails,
    onClose: onClosePositionDetails,
  } = useDisclosure();

  const {
    detailedPosition,
    handleOpenPositionDetails,
    handleClosePositionDetails,
    handleColorChange,
    onPlayerClick,
    deletePlayer,
  } = useShadowteamInteractions({
    quickViewPlayer,
    setQuickViewPlayer,
    shadowteam,
    setShadowteam,
    onOpenPositionDetails,
    onClosePositionDetails,
    onOpenQuickView,
    renderTrigger,
    setRenderTrigger,
  });

  const k = useKeycloak();
  const userId = k.keycloak.idTokenParsed?.sub;

  const writeAccess =
    data?.userId === userId ||
    (data?.sharedWithAccess &&
      data?.sharedWithAccess[
      data.sharedWith.findIndex((id) => id === userId)
      ] === "write");

  useEffect(() => {
    if (clickedOnShadowteamName === undefined) {
      setclickedOnShadowteamName(data?.shadowteamName);
    }
    if (data && shadowteam?.formation !== data.formation) {
      setShadowteam(cloneDeep(data));
    }
  }, [data]);

  useEffect(() => {
    return () => {
      setclickedOnShadowteamName(undefined);
    };
  }, []);

  useEffect(() => {
    if (
      writeAccess &&
      shadowteam &&
      !isEqual(data?.players, shadowteam?.players)
    ) {
      saveShadowteam(shadowteam);
    }
  }, [shadowteam]);

  const { handleDragEnd } = useDragAndDropShadowteams({
    shadowteam,
    setShadowteam,
    shadowteamAside,
    watchlist,
    selection,
    renderTrigger,
    setRenderTrigger,
    guiText
  });

  return (
    <Box display="flex" flex={1} pb={2} overflow="hidden">
      <DragDropContext onDragEnd={handleDragEnd}>
        {/* aside */}
        <PlayerSelectAside
          title={guiText[LanguageKeys.Schattenmannschaft]}
          selection={selection}
          setSelection={setSelection}
          shadowteamSelection={shadowteamSelection}
          setShadowteamSelection={setShadowteamSelection}
          shadowteamAside={shadowteamAside}
          watchlist={watchlist}
        />
        {/* main content */}
        {error ? (
          <Box
            flex={1}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Box textAlign="center">
              <Text fontWeight="semibold" fontSize={20}>
                {guiText[LanguageKeys.DieseSchattenmannschaftExistiertNicht]}
              </Text>
            </Box>
            <Box textAlign="center">
              <Text fontWeight="semibold" fontSize={20}>
                {guiText[LanguageKeys.OderDuHastKeinenZugriff]}
              </Text>
            </Box>
          </Box>
        ) : (
          <Box flex={1} display="flex" flexDir="column" overflow="hidden">
            <ShadowteamHeader
              shadowteam={shadowteam}
              setShadowteam={setShadowteam}
              writeAccess={writeAccess || false}
              view={view}
              setView={setView}
            />
            {view === "field" && (
              <ShadowteamFootballField
                shadowteam={shadowteam}
                players={players}
                writeAccess={writeAccess || false}
                deletePlayer={deletePlayer}
                onPlayerClick={onPlayerClick}
                handleOpenPositionDetails={handleOpenPositionDetails}
              />
            )}
            {view === "table" && shadowteam && (
              <ShadowteamTable
                data={shadowteam?.players}
                deletePlayerLocal={deletePlayer}
                onOpenQuickView={onPlayerClick}
              />
            )}
          </Box>
        )}
      </DragDropContext>
      <QuickViewModal
        onClose={onCloseQuickView}
        isOpen={isOpenQuickView}
        player={quickViewPlayer}
        handleColorChange={handleColorChange}
        deletePlayerLocal={deletePlayer}
      />
      <PositionDetailsModal
        onClose={handleClosePositionDetails}
        isOpen={isOpenPositinDetails}
        shadowteam={shadowteam}
        position={detailedPosition}
        onOpenQuickView={onPlayerClick}
        deletePlayer={deletePlayer}
        saveShadowteam={saveShadowteam}
        guiText={guiText}
      />
    </Box>
  );
};

export default ShadowteamDetails;
