import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Heading,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { colorsPrimary, colorsSecondary, textColors } from "../../../colorStyles";
import { ShadowteamOverview, User } from "../../../generated";
import {
  useEditShadowteam,
  useGetShadowteamFormations,
} from "../../../hooks/useShadowteams";
import { useShadowteamOverviewStore } from "../../../store/store";
import Formations from "../formation/FormationsAndPositions";
import UserSearch from "./UserSearch";
import { LanguageContext } from "../../../store/languageContext";
import { LanguageKeys } from "../../../language/LanguageKeys";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const EditShadowteamModal = ({ isOpen, onClose }: Props) => {
  const { guiText } = useContext(LanguageContext);
  const { shadowteam } = useShadowteamOverviewStore();
  const editShadowTeam = useEditShadowteam();

  const formations = Formations();


  const { data: existingFormations } = useGetShadowteamFormations(
    shadowteam?.shadowteamId!
  );

  const [name, setName] = useState("");
  const [sharedWithAccess, setSharedWithAccess] = useState<string[]>([]);
  const [sharedWithNames, setSharedWithNames] = useState<string[]>([]);
  const [sharedWith, setSharedWith] = useState<string[]>([]);
  const [formation, setFormation] = useState("");

  const handleRadioChange = (idx: number, value: string) => {
    const updatedAccess = [...sharedWithAccess];
    updatedAccess[idx] = value;
    setSharedWithAccess(updatedAccess);
  };

  const handleSave = () => {
    const newShadowteam: ShadowteamOverview = { ...shadowteam! };
    newShadowteam.sharedWithAccess = sharedWithAccess;
    newShadowteam.sharedWith = sharedWith;
    newShadowteam.lastChanged = new Date();
    newShadowteam.shadowteamName = name;
    newShadowteam.formation = formation;

    console.log(newShadowteam);

    editShadowTeam({
      shadowteamId: newShadowteam.shadowteamId,
      shadowteamOverview: newShadowteam,
    });

    onClose();
  };

  const handleAddUser = (user: User) => {
    setSharedWithAccess([...sharedWithAccess, "read"]);
    setSharedWithNames([
      ...sharedWithNames,
      user.firstName + " " + user.lastName,
    ]);
    setSharedWith([...sharedWith, user.userId!]);
  };

  const handleDeleteUser = (idx: number) => {
    const newSharedWithAccess = [...sharedWithAccess];
    const newSharedWithNames = [...sharedWithNames];
    const newSharedWith = [...sharedWith];

    newSharedWithAccess.splice(idx, 1);
    newSharedWithNames.splice(idx, 1);
    newSharedWith.splice(idx, 1);

    setSharedWithAccess(newSharedWithAccess);
    setSharedWithNames(newSharedWithNames);
    setSharedWith(newSharedWith);
  };

  useEffect(() => {
    setName(shadowteam?.shadowteamName ?? "");
    setSharedWithAccess(shadowteam?.sharedWithAccess ?? []);
    setSharedWithNames(shadowteam?.sharedWithNames ?? []);
    setSharedWith(shadowteam?.sharedWith ?? []);
    setFormation(shadowteam?.formation ?? "");
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent backgroundColor={colorsSecondary.cardBackground} color={textColors.primary}>
        <ModalHeader borderBottom="2px solid #CBD5E0">
          {guiText[LanguageKeys.SchattenmannschaftBearbeiten]}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6} pt={4}>
          <Box pb={4}>
            <FormControl>
              <Heading size="sm" pb={2}>
                Name
              </Heading>
              <Input
                placeholder={guiText[LanguageKeys.Name]}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormControl>
          </Box>
          <Box pb={4}>
            <Heading size="sm" pb={2}>
              {guiText[LanguageKeys.Standardformation]}
            </Heading>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                textAlign="start"
                size="sm"
                border="1px solid #E2E8F0"
                fontWeight="normal"
                w="30%"
              >
                <Text lineHeight={0}>{formation}</Text>
              </MenuButton>
              <MenuList maxH="300px" overflow="auto">
                {Array.from(formations.entries()).map(
                  formationGroups => (
                    <MenuGroup title={formationGroups[0]} key={formationGroups[0]}>
                      {formationGroups[1].map(formation => (
                        <MenuItem
                          _hover={{ bg: "gray.200" }}
                          onClick={() => setFormation(formation)}
                          key={formation}
                          opacity={
                            existingFormations?.some(
                              (existingFormation) =>
                                existingFormation.name === formation
                            )
                              ? 1
                              : 0.4
                          }
                          isDisabled={
                            !existingFormations?.some(
                              (existingFormation) =>
                                existingFormation.name === formation
                            )
                          }
                        >
                          {formation}
                        </MenuItem>
                      ))}
                    </MenuGroup>
                  )
                )}
              </MenuList>
            </Menu>
          </Box>
          <Divider borderColor="gray.300" />
          <Box pt={4}>
            <Heading size="sm" pb={2}>
              {guiText[LanguageKeys.SchattenmannschaftenGeteiltMit]}
            </Heading>
            <UserSearch
              addUser={handleAddUser}
              excludeUsers={sharedWith}
            ></UserSearch>
            <TableContainer>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th pl={1} w="60%" color={textColors.primary}>
                      {guiText[LanguageKeys.Benutzername]}
                    </Th>
                    <Th pl={1} w="30%" color={textColors.primary}>
                      {guiText[LanguageKeys.Zugriff]}
                    </Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {sharedWithNames.length > 0 ? (
                    sharedWithNames.map((name, idx) => (
                      <Tr key={idx}>
                        <Td pl={1}>{name}</Td>
                        <Td pl={1}>
                          <RadioGroup
                            onChange={(value) => handleRadioChange(idx, value)}
                            value={sharedWithAccess[idx]}
                          >
                            <Stack direction="row">
                              <Radio value="read">{guiText[LanguageKeys.Lesen]}</Radio>
                              <Radio value="write">{guiText[LanguageKeys.Schreiben]}</Radio>
                            </Stack>
                          </RadioGroup>
                        </Td>
                        <Td>
                          <IconButton
                            icon={
                              <AiFillCloseCircle size="15px" color="#8a8a8a" />
                            }
                            aria-label={"Löschen"}
                            onClick={() => handleDeleteUser(idx)}
                          ></IconButton>
                        </Td>
                      </Tr>
                    ))
                  ) : (
                    <Tr>
                      <Td pl={1}>-</Td>
                      <Td pl={1}>-</Td>
                    </Tr>
                  )}
                </Tbody>
                <Tfoot></Tfoot>
              </Table>
            </TableContainer>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button
            mr={3}
            backgroundColor={colorsSecondary.accent}
            textColor={colorsSecondary.background}
            boxShadow="sm"
            onClick={handleSave}
          >
            {guiText[LanguageKeys.SearchBarFilterSpeichern]}
          </Button>
          <Button
            onClick={onClose}
            variant="outline"
            color={colorsSecondary.accent}
          >
            {guiText[LanguageKeys.Abbrechen]}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditShadowteamModal;
