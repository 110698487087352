import { createTheme } from "@mui/material";
// noinspection ES6UnusedImports
import type { } from '@mui/x-data-grid-pro/themeAugmentation';

import { colorsPrimary, colorsSecondary, textColors } from "../colorStyles";
import { hexToRgba } from "../util/utilFunctions";

const theme = createTheme({
  typography: {
    fontFamily: "Text_Regular, Arial",
  },
  palette: {
    primary: {
      main: colorsSecondary.accent,
    },
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          backgroundColor: colorsSecondary.cardBackground,
          zIndex: 1000,
        },
        cell: {
          color: textColors.primary,
          "&.hoverSelect:hover": {
            textDecoration: "underline",
            cursor: "pointer",
          },

        },
        row: {
          "&.even": {
            backgroundColor: colorsSecondary.cardBackground, // Light gray for even rows
          },
          "&.odd": {
            backgroundColor: colorsSecondary.background, // White for odd rows
          },
          "&.sharedShadowteam": {
            backgroundColor: colorsSecondary.background,
          },
          "&.ownShadowteam": {
            backgroundColor: colorsSecondary.background,
          },
          "&:hover": {
            backgroundColor: hexToRgba(colorsSecondary.accentHover, 1),
          },
          "&.Mui-selected": {
            backgroundColor: hexToRgba(colorsSecondary.highlight1, 0.2),
          },

        },
        columnHeader: {
          fontWeight: "bold",
          fontFamily: "Text_Bold, Arial",
          color: textColors.primary,
          "&.MuiDataGrid-columnHeader[data-field='similarity']": {
            backgroundColor: colorsSecondary.cardBackground, // Change this to your desired color
          },
        },
        columnHeaderTitle: {
          lineHeight: "1.1 !important",
        },
        virtualScroller: {
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          "& .MuiDataGrid-virtualScrollerContent": {
            backgroundColor: colorsSecondary.cardBackground, // Ensure virtual scroller content has the same background color
          },

        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          color: textColors.lightgrey, // Change this to your desired color
        },
        toolbar: {
          color: textColors.lightgrey, // Change this to your desired color
        },
        selectLabel: {
          color: textColors.lightgrey, // Change this to your desired color
        },
        displayedRows: {
          color: textColors.lightgrey, // Change this to your desired color
        },
        actions: {
          color: textColors.lightgrey, // Change this to your desired color
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: textColors.lightgrey, // Change this to your desired color
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: textColors.primary, // Change this to your desired text color
        },
        input: {
          color: textColors.primary, // Change this to your desired text color
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          "&:before": {
            borderBottomColor: textColors.primary, // Change this to your desired underline color
          },
          "&:hover:not(.Mui-disabled):before": {
            borderBottomColor: textColors.primary, // Change this to your desired underline color
          },
          "&:after": {
            borderBottomColor: textColors.primary, // Change this to your desired underline color
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: textColors.primary, // Change this to your desired icon color
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          zIndex: 9000,
        },
      },
    },
    MuiPopper: {
      defaultProps: {
        disablePortal: true,
        style: { zIndex: 9000 },
      },
    },
    MuiSwitch: {
      defaultProps: {
        color: "primary",
      },
    },
  },
});

export default theme;
