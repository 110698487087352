import {Center, Text, VStack} from "@chakra-ui/react";
import {useLocation} from "react-router-dom";
import {useGetShadowteam} from "../../hooks/useShadowteams";
import {ShadowteamEntry} from "../../generated";
import {groupByPosition} from "../shadowteams/shadowteamUtilFunctions";
import {DragDropContext} from "react-beautiful-dnd";
import ShadowteamFootballField from "../shadowteams/mainContent/ShadowteamFootballField";
import ShadowteamHeaderPrintView from "./ShadowteamHeaderPrintView";
import {useContext, useEffect} from "react";
import { LanguageContext } from "../../store/languageContext";
import { LanguageKeys } from "../../language/LanguageKeys";
import {LocaleKeys} from "../../language/LocaleKeys";

const ShadowteamFormationPrintView = () => {
  const { setLanguage, guiText } = useContext(LanguageContext);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = Number(queryParams.get("id")!);
  const formation = queryParams.get("formation") ?? undefined;

  const { data, isLoading, error } = useGetShadowteam(id, formation);

  const players: Map<string, ShadowteamEntry[]> = groupByPosition(
    data?.players
  );

  const language = queryParams.get("language");
  useEffect(() => {
      switch (language) {
        case LocaleKeys.DE:
          setLanguage(LocaleKeys.DE);
          break;
        case LocaleKeys.EN:
          setLanguage(LocaleKeys.EN);
          break;
        case LocaleKeys.ES:
          setLanguage(LocaleKeys.ES);
          break;
        default:
          setLanguage(LocaleKeys.DE);
      }
  }, []);

  return (
    <>
      {isLoading && !error &&
          <Center h="100%" w="100%">
              <Text fontSize={20}>{guiText[LanguageKeys.DatenWerdenGeladen]}</Text>
          </Center>
      }
      {!isLoading && error &&
          <Center h="100%" w="100%">
              <Text fontSize={20}>{guiText[LanguageKeys.Datenkonntennichtgeladenwerden]}</Text>
          </Center>
      }
      {!isLoading && data &&
        <VStack id="shadowteam-formation-print-view-main-content" w="100%" h="100%">
          <ShadowteamHeaderPrintView shadowteam={data} />
          <DragDropContext onDragEnd={() => void 0}>
            <ShadowteamFootballField
              shadowteam={data}
              players={players}
              writeAccess={false}
              deletePlayer={() => void 0}
              onPlayerClick={() => void 0}
              handleOpenPositionDetails={() => void 0}
            />
          </DragDropContext>
        </VStack>
      }
    </>
  );
};

export default ShadowteamFormationPrintView;
