import {Button, Tooltip} from "@chakra-ui/react";
import React, {PropsWithChildren, ReactNode, useContext, useState} from "react";
import {specialColors} from "../../colorStyles";
import {CheckIcon, WarningIcon} from "@chakra-ui/icons";
import {PdfIcon} from "../../assets/PdfIcon";
import { LanguageContext } from "../../store/languageContext";
import { LanguageKeys } from "../../language/LanguageKeys";

interface Props {
  onClick: () => Promise<Blob>;
  filename: string;
  [x: string]: any;
}

const ExportButton = ({
  onClick,
  filename,
  children,
  ...rest
}: PropsWithChildren<Props>) => {
  const { guiText } = useContext(LanguageContext);

  const [pending, setPending] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  let icon: ReactNode;
  let tooltipText = guiText[LanguageKeys.ExportKannMinutenDauern];
  switch (true) {
    case error:
      icon = <WarningIcon />;
      tooltipText = guiText[LanguageKeys.ExportFehlgeschlagen];
      break;
    case success:
      icon = <CheckIcon />;
      break;
    default:
      icon = <PdfIcon />
  }

  const handleExport = () => {
    setPending(true);

    onClick()
      .then((blob: Blob) => {
        setPending(false);
        setSuccess(true);

        if (blob.size > 0) {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
        }
      })
      .catch((reason: any) => {
        setPending(false);
        setError(true);

        console.error("Error downloading the PDF: ", reason);
      });
  }

  return (
    <Tooltip
      borderRadius={5}
      label={tooltipText}
    >
      <Button
        leftIcon={icon}
        color="white"
        backgroundColor={specialColors.pdfRed}
        fontWeight="thin"
        fontSize="sm"
        alignItems="center"
        loadingText={guiText[LanguageKeys.GenerierePDF]}
        isLoading={pending}
        onClick={handleExport}
        _hover={{ backgroundColor: specialColors.pdfRed }}
        {...rest}
      >
        {children}
      </Button>
    </Tooltip>
  );
};

export default ExportButton;
