import { Box, HStack } from "@chakra-ui/react";
import { StrictModeDroppable } from "./StrictModeDroppable";

interface Props {
  columns: string[];
  childHeight: string;
}
const DroppableColumns = ({ columns, childHeight }: Props) => {
  return (
    <HStack
      position="absolute"
      top={0}
      left={0}
      height={childHeight}
      width="100%"
      zIndex={-3}
      display="flex"
      justifyContent="space-between"
    >
      {columns.map((column) => (
        <StrictModeDroppable droppableId={column} key={column}>
          {(provided) => (
            <Box
              flex={1}
              w="32%"
              h="100%"
              ref={provided.innerRef}
              {...provided.droppableProps}
            ></Box>
          )}
        </StrictModeDroppable>
      ))}
    </HStack>
  );
};

export default DroppableColumns;
