/* tslint:disable */
/* eslint-disable */
/**
 * alleyes SCOUT BACKEND
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PlayerBaseData
 */
export interface PlayerBaseData {
    /**
     * 
     * @type {number}
     * @memberof PlayerBaseData
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof PlayerBaseData
     */
    idTm?: number;
    /**
     * 
     * @type {string}
     * @memberof PlayerBaseData
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof PlayerBaseData
     */
    surname: string;
    /**
     * 
     * @type {Date}
     * @memberof PlayerBaseData
     */
    birthday?: Date;
    /**
     * 
     * @type {number}
     * @memberof PlayerBaseData
     */
    age?: number;
    /**
     * 
     * @type {string}
     * @memberof PlayerBaseData
     */
    nationality?: string;
    /**
     * 
     * @type {string}
     * @memberof PlayerBaseData
     */
    strongFoot?: string;
    /**
     * 
     * @type {number}
     * @memberof PlayerBaseData
     */
    height?: number;
    /**
     * 
     * @type {number}
     * @memberof PlayerBaseData
     */
    alleyes?: number;
    /**
     * 
     * @type {number}
     * @memberof PlayerBaseData
     */
    subjectiveOverall?: number;
    /**
     * 
     * @type {number}
     * @memberof PlayerBaseData
     */
    subjectivePotential?: number;
    /**
     * 
     * @type {number}
     * @memberof PlayerBaseData
     */
    subjectiveGameRatingsOverall?: number;
    /**
     * 
     * @type {number}
     * @memberof PlayerBaseData
     */
    subjectiveGameRatingsPotential?: number;
    /**
     * 
     * @type {string}
     * @memberof PlayerBaseData
     */
    league?: string;
    /**
     * 
     * @type {string}
     * @memberof PlayerBaseData
     */
    team?: string;
    /**
     * 
     * @type {number}
     * @memberof PlayerBaseData
     */
    teamId?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PlayerBaseData
     */
    positionsGruppen?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof PlayerBaseData
     */
    marketValue?: number;
    /**
     * 
     * @type {Date}
     * @memberof PlayerBaseData
     */
    contractExpiryDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof PlayerBaseData
     */
    loan?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PlayerBaseData
     */
    agent?: string;
    /**
     * 
     * @type {number}
     * @memberof PlayerBaseData
     */
    totalInjuryDays?: number;
}

/**
 * Check if a given object implements the PlayerBaseData interface.
 */
export function instanceOfPlayerBaseData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "surname" in value;

    return isInstance;
}

export function PlayerBaseDataFromJSON(json: any): PlayerBaseData {
    return PlayerBaseDataFromJSONTyped(json, false);
}

export function PlayerBaseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlayerBaseData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'idTm': !exists(json, 'idTm') ? undefined : json['idTm'],
        'firstName': json['firstName'],
        'surname': json['surname'],
        'birthday': !exists(json, 'birthday') ? undefined : (new Date(json['birthday'])),
        'age': !exists(json, 'age') ? undefined : json['age'],
        'nationality': !exists(json, 'nationality') ? undefined : json['nationality'],
        'strongFoot': !exists(json, 'strongFoot') ? undefined : json['strongFoot'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'alleyes': !exists(json, 'alleyes') ? undefined : json['alleyes'],
        'subjectiveOverall': !exists(json, 'subjectiveOverall') ? undefined : json['subjectiveOverall'],
        'subjectivePotential': !exists(json, 'subjectivePotential') ? undefined : json['subjectivePotential'],
        'subjectiveGameRatingsOverall': !exists(json, 'subjectiveGameRatingsOverall') ? undefined : json['subjectiveGameRatingsOverall'],
        'subjectiveGameRatingsPotential': !exists(json, 'subjectiveGameRatingsPotential') ? undefined : json['subjectiveGameRatingsPotential'],
        'league': !exists(json, 'league') ? undefined : json['league'],
        'team': !exists(json, 'team') ? undefined : json['team'],
        'teamId': !exists(json, 'teamId') ? undefined : json['teamId'],
        'positionsGruppen': !exists(json, 'positionsGruppen') ? undefined : json['positionsGruppen'],
        'marketValue': !exists(json, 'marketValue') ? undefined : json['marketValue'],
        'contractExpiryDate': !exists(json, 'contractExpiryDate') ? undefined : (new Date(json['contractExpiryDate'])),
        'loan': !exists(json, 'loan') ? undefined : json['loan'],
        'agent': !exists(json, 'agent') ? undefined : json['agent'],
        'totalInjuryDays': !exists(json, 'totalInjuryDays') ? undefined : json['totalInjuryDays'],
    };
}

export function PlayerBaseDataToJSON(value?: PlayerBaseData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'idTm': value.idTm,
        'firstName': value.firstName,
        'surname': value.surname,
        'birthday': value.birthday === undefined ? undefined : (value.birthday.toISOString().substr(0,10)),
        'age': value.age,
        'nationality': value.nationality,
        'strongFoot': value.strongFoot,
        'height': value.height,
        'alleyes': value.alleyes,
        'subjectiveOverall': value.subjectiveOverall,
        'subjectivePotential': value.subjectivePotential,
        'subjectiveGameRatingsOverall': value.subjectiveGameRatingsOverall,
        'subjectiveGameRatingsPotential': value.subjectiveGameRatingsPotential,
        'league': value.league,
        'team': value.team,
        'teamId': value.teamId,
        'positionsGruppen': value.positionsGruppen,
        'marketValue': value.marketValue,
        'contractExpiryDate': value.contractExpiryDate === undefined ? undefined : (value.contractExpiryDate.toISOString().substr(0,10)),
        'loan': value.loan,
        'agent': value.agent,
        'totalInjuryDays': value.totalInjuryDays,
    };
}

