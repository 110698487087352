import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  Button,
  Text,
} from "@chakra-ui/react";
import { useContext, useEffect, useRef, useState } from "react";
import { colorsPrimary, colorsSecondary, textColors } from "../../../colorStyles";
import { FilterPreset, FilterValue } from "../../../generated";
import {
  useAddFilterPreset,
  useFilterPresets,
  useEditFilterPreset,
} from "../../../hooks/usePresets";
import { useFilterPresetStore, useFilterValueStore } from "../../../store/store";
import { LanguageKeys } from "../../../language/LanguageKeys";
import { LanguageContext } from "../../../store/languageContext";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const FilterSavingModal = ({ isOpen, onClose }: Props) => {
  const { guiText } = useContext(LanguageContext);
  const initialRef = useRef(null);

  const { setActivePreset, getActivePreset } = useFilterPresetStore();
  const { data } = useFilterPresets();

  const { filterValues } = useFilterValueStore();

  const [inputValue, setInputValue] = useState("");

  const [showWarning, setShowWarning] = useState(false);

  const addPreset = useAddFilterPreset();

  const editPreset = useEditFilterPreset();

  const handleClose = () => {
    setInputValue(getActivePreset()?.name || "");
    onClose();
  };

  const handleSave = () => {
    if (showWarning) {
      const existingPreset = data?.find((preset) => preset.name === inputValue);
      if (existingPreset) {
        editPreset({
          presetId: existingPreset.id,
          filterPreset: {
            ...existingPreset,
            filterValues: filterValues.filter(
              (filter) => filter != null
            ) as FilterValue[],
          },
        });
      }
    } else {
      const preset: FilterPreset = {
        id: 0, //ID is automatically set by DB
        name: inputValue,
        filterValues: filterValues.filter(
          (filter) => filter != null
        ) as FilterValue[],
      };
      addPreset(preset);
    }
    onClose();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);

    const presetAlreadExists = data?.some((preset) => preset.name === value);
    setShowWarning(presetAlreadExists || false);
  };

  useEffect(() => {
    const activePresetName = getActivePreset()?.name || "";
    setInputValue(activePresetName);
    const presetAlreadyExists = data?.some(
      (preset) => preset.name === activePresetName
    );

    setShowWarning(presetAlreadyExists || false);
  }, [isOpen]);

  return (
    <Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent backgroundColor={colorsSecondary.cardBackground} color={textColors.primary}>
        <ModalHeader>{guiText[LanguageKeys.SearchBarFilterSpeichern]}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <FormControl>
            <FormLabel>{guiText[LanguageKeys.NameFilterung]}</FormLabel>
            <Input
              ref={initialRef}
              placeholder={guiText[LanguageKeys.NameFilterung]}
              value={inputValue}
              onChange={handleInputChange}
              focusBorderColor={colorsSecondary.accent} // Change this to your desired color
            />
            {showWarning && (
              <Text color="red.500" mt={2} fontSize="sm">
                {inputValue +
                  guiText[LanguageKeys.ExistiertBereitsDurchSpeichernWirdEsUeberschrieben]}
              </Text>
            )}
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            mr={3}
            backgroundColor={colorsSecondary.accent}
            textColor={colorsSecondary.background}
            boxShadow="sm"
            onClick={handleSave}
          >
            {guiText[LanguageKeys.SpielerprofilSpeichern]}
          </Button>
          <Button
            onClick={handleClose}
            variant="outline"
            color={colorsSecondary.accent}
          >
            {guiText[LanguageKeys.Abbrechen]}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FilterSavingModal;
