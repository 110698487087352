/* tslint:disable */
/* eslint-disable */
/**
 * alleyes SCOUT BACKEND
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SimilarPlayer
 */
export interface SimilarPlayer {
    /**
     * 
     * @type {number}
     * @memberof SimilarPlayer
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof SimilarPlayer
     */
    idTm?: number;
    /**
     * 
     * @type {string}
     * @memberof SimilarPlayer
     */
    playerName?: string;
    /**
     * 
     * @type {string}
     * @memberof SimilarPlayer
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof SimilarPlayer
     */
    surname: string;
    /**
     * 
     * @type {Date}
     * @memberof SimilarPlayer
     */
    birthday?: Date;
    /**
     * 
     * @type {number}
     * @memberof SimilarPlayer
     */
    age?: number;
    /**
     * 
     * @type {string}
     * @memberof SimilarPlayer
     */
    nationality?: string;
    /**
     * 
     * @type {string}
     * @memberof SimilarPlayer
     */
    strongFoot?: string;
    /**
     * 
     * @type {number}
     * @memberof SimilarPlayer
     */
    height?: number;
    /**
     * 
     * @type {number}
     * @memberof SimilarPlayer
     */
    alleyes?: number;
    /**
     * 
     * @type {number}
     * @memberof SimilarPlayer
     */
    alleyesPotential?: number;
    /**
     * 
     * @type {number}
     * @memberof SimilarPlayer
     */
    subjectiveOverall?: number;
    /**
     * 
     * @type {number}
     * @memberof SimilarPlayer
     */
    subjectivePotential?: number;
    /**
     * 
     * @type {number}
     * @memberof SimilarPlayer
     */
    subjectiveGameRatingsOverall?: number;
    /**
     * 
     * @type {number}
     * @memberof SimilarPlayer
     */
    subjectiveGameRatingsPotential?: number;
    /**
     * 
     * @type {string}
     * @memberof SimilarPlayer
     */
    league?: string;
    /**
     * 
     * @type {string}
     * @memberof SimilarPlayer
     */
    team?: string;
    /**
     * 
     * @type {number}
     * @memberof SimilarPlayer
     */
    teamId?: number;
    /**
     * 
     * @type {string}
     * @memberof SimilarPlayer
     */
    positionsGruppe: string;
    /**
     * 
     * @type {number}
     * @memberof SimilarPlayer
     */
    technischPs?: number;
    /**
     * 
     * @type {number}
     * @memberof SimilarPlayer
     */
    athletisch?: number;
    /**
     * 
     * @type {number}
     * @memberof SimilarPlayer
     */
    technischD?: number;
    /**
     * 
     * @type {number}
     * @memberof SimilarPlayer
     */
    taktischRun?: number;
    /**
     * 
     * @type {number}
     * @memberof SimilarPlayer
     */
    taktischPasses?: number;
    /**
     * 
     * @type {number}
     * @memberof SimilarPlayer
     */
    numberOfReviews?: number;
    /**
     * 
     * @type {Date}
     * @memberof SimilarPlayer
     */
    lastReview?: Date;
    /**
     * 
     * @type {number}
     * @memberof SimilarPlayer
     */
    marketValue?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SimilarPlayer
     */
    completeRating?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SimilarPlayer
     */
    trendShort?: number;
    /**
     * 
     * @type {number}
     * @memberof SimilarPlayer
     */
    trendLong?: number;
    /**
     * 
     * @type {Date}
     * @memberof SimilarPlayer
     */
    contractExpiryDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof SimilarPlayer
     */
    similarity: number;
}

/**
 * Check if a given object implements the SimilarPlayer interface.
 */
export function instanceOfSimilarPlayer(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "surname" in value;
    isInstance = isInstance && "positionsGruppe" in value;
    isInstance = isInstance && "similarity" in value;

    return isInstance;
}

export function SimilarPlayerFromJSON(json: any): SimilarPlayer {
    return SimilarPlayerFromJSONTyped(json, false);
}

export function SimilarPlayerFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimilarPlayer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'idTm': !exists(json, 'idTm') ? undefined : json['idTm'],
        'playerName': !exists(json, 'playerName') ? undefined : json['playerName'],
        'firstName': json['firstName'],
        'surname': json['surname'],
        'birthday': !exists(json, 'birthday') ? undefined : (new Date(json['birthday'])),
        'age': !exists(json, 'age') ? undefined : json['age'],
        'nationality': !exists(json, 'nationality') ? undefined : json['nationality'],
        'strongFoot': !exists(json, 'strongFoot') ? undefined : json['strongFoot'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'alleyes': !exists(json, 'alleyes') ? undefined : json['alleyes'],
        'alleyesPotential': !exists(json, 'alleyesPotential') ? undefined : json['alleyesPotential'],
        'subjectiveOverall': !exists(json, 'subjectiveOverall') ? undefined : json['subjectiveOverall'],
        'subjectivePotential': !exists(json, 'subjectivePotential') ? undefined : json['subjectivePotential'],
        'subjectiveGameRatingsOverall': !exists(json, 'subjectiveGameRatingsOverall') ? undefined : json['subjectiveGameRatingsOverall'],
        'subjectiveGameRatingsPotential': !exists(json, 'subjectiveGameRatingsPotential') ? undefined : json['subjectiveGameRatingsPotential'],
        'league': !exists(json, 'league') ? undefined : json['league'],
        'team': !exists(json, 'team') ? undefined : json['team'],
        'teamId': !exists(json, 'teamId') ? undefined : json['teamId'],
        'positionsGruppe': json['positionsGruppe'],
        'technischPs': !exists(json, 'technischPs') ? undefined : json['technischPs'],
        'athletisch': !exists(json, 'athletisch') ? undefined : json['athletisch'],
        'technischD': !exists(json, 'technischD') ? undefined : json['technischD'],
        'taktischRun': !exists(json, 'taktischRun') ? undefined : json['taktischRun'],
        'taktischPasses': !exists(json, 'taktischPasses') ? undefined : json['taktischPasses'],
        'numberOfReviews': !exists(json, 'numberOfReviews') ? undefined : json['numberOfReviews'],
        'lastReview': !exists(json, 'lastReview') ? undefined : (new Date(json['lastReview'])),
        'marketValue': !exists(json, 'marketValue') ? undefined : json['marketValue'],
        'completeRating': !exists(json, 'completeRating') ? undefined : json['completeRating'],
        'trendShort': !exists(json, 'trendShort') ? undefined : json['trendShort'],
        'trendLong': !exists(json, 'trendLong') ? undefined : json['trendLong'],
        'contractExpiryDate': !exists(json, 'contractExpiryDate') ? undefined : (new Date(json['contractExpiryDate'])),
        'similarity': json['similarity'],
    };
}

export function SimilarPlayerToJSON(value?: SimilarPlayer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'idTm': value.idTm,
        'playerName': value.playerName,
        'firstName': value.firstName,
        'surname': value.surname,
        'birthday': value.birthday === undefined ? undefined : (value.birthday.toISOString().substr(0,10)),
        'age': value.age,
        'nationality': value.nationality,
        'strongFoot': value.strongFoot,
        'height': value.height,
        'alleyes': value.alleyes,
        'alleyesPotential': value.alleyesPotential,
        'subjectiveOverall': value.subjectiveOverall,
        'subjectivePotential': value.subjectivePotential,
        'subjectiveGameRatingsOverall': value.subjectiveGameRatingsOverall,
        'subjectiveGameRatingsPotential': value.subjectiveGameRatingsPotential,
        'league': value.league,
        'team': value.team,
        'teamId': value.teamId,
        'positionsGruppe': value.positionsGruppe,
        'technischPs': value.technischPs,
        'athletisch': value.athletisch,
        'technischD': value.technischD,
        'taktischRun': value.taktischRun,
        'taktischPasses': value.taktischPasses,
        'numberOfReviews': value.numberOfReviews,
        'lastReview': value.lastReview === undefined ? undefined : (value.lastReview.toISOString().substr(0,10)),
        'marketValue': value.marketValue,
        'completeRating': value.completeRating,
        'trendShort': value.trendShort,
        'trendLong': value.trendLong,
        'contractExpiryDate': value.contractExpiryDate === undefined ? undefined : (value.contractExpiryDate.toISOString().substr(0,10)),
        'similarity': value.similarity,
    };
}

