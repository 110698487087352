import { ChakraProvider } from "@chakra-ui/react";
import { LicenseInfo } from "@mui/x-license-pro";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import ComponentStyles from "./componentStyles";
import FontStyles from "./fontStyles";
import reportWebVitals from "./reportWebVitals";
import router from "./routes";
import theme from "./themes/theme";
import keycloak from "./util/keycloak";
import {LanguageProvider} from "./store/languageContext";

const queryClient = new QueryClient();

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_PRO_LICENSE_KEY ?? "");

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <ReactKeycloakProvider
    authClient={keycloak}
    initOptions={{ onload: "check-sso" }}
    enable-pkce={true}
  >
    <React.StrictMode>
      <ChakraProvider theme={theme}>
        <LanguageProvider>
          <FontStyles />
          <ComponentStyles />
          <QueryClientProvider client={queryClient}>
            <RouterProvider router={router} />
            {/* <ReactQueryDevtools /> */}
          </QueryClientProvider>
        </LanguageProvider>
      </ChakraProvider>
    </React.StrictMode>
  </ReactKeycloakProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
