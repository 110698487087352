import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Circle,
  Divider,
  Heading,
  HStack,
} from "@chakra-ui/react";
import { colorsPrimary, colorsSecondary, textColors } from "../../../colorStyles";

import { FilterSpecification } from "../../../generated";
import useFilterType from "../../../hooks/useFilterType";
import { useFilterValueStore } from "../../../store/store";
import { useContext } from "react";
import { LanguageContext } from "../../../store/languageContext";
import { LanguageKeys } from "../../../language/LanguageKeys";

interface Props {
  label: string;
  filters: FilterSpecification[];
}

const FilterAccordion = ({ label, filters }: Props) => {
  const { getFilter } = useFilterType();
  const { guiText } = useContext(LanguageContext);
  // select store slice
  const filterValueFunctionality = useFilterValueStore();

  const { getActiveFilterGroupCount } = useFilterValueStore();

  const activeFilterCount = getActiveFilterGroupCount(filters[0].subgroupGerman);

  return (
    <Accordion
      defaultIndex={label == guiText[LanguageKeys.SpielerprofilStammdaten] ? [0] : []}
      allowMultiple
      marginBottom={2}

    >
      <AccordionItem key={label} backgroundColor={colorsSecondary.background} borderColor={colorsSecondary.cardBackground}>
        <AccordionButton backgroundColor={colorsSecondary.cardBackground} borderRadius={5}>
          <HStack flex="1">
            <Heading
              fontFamily="Text_Regular"
              fontSize={15}
              mt={2}
              mb={2}
              color={textColors.primary}
            >
              {label}
            </Heading>
            {activeFilterCount > 0 && (
              <Circle
                size="15px"
                bg={colorsSecondary.accent}
                color="white"
                alignContent="center"
                fontSize="10"
              >
                <Center>{activeFilterCount}</Center>
              </Circle>
            )}
          </HStack>
          <AccordionIcon color={textColors.primary} />
        </AccordionButton>

        <AccordionPanel pb={4} backgroundColor={colorsSecondary.cardBackground}>
          {filters.map((filter, index) => {
            const FilterComponent = getFilter(filter);
            return (
              <Box mb={3} key={index} backgroundColor={colorsSecondary.cardBackground}>
                <FilterComponent
                  filter={filter}
                  filterValueFunc={filterValueFunctionality}
                />
                {index < filters.length - 1 ? (
                  <Divider mt={2} borderColor="gray.300" />
                ) : null}
              </Box>
            );
          })}
        </AccordionPanel>
      </AccordionItem>
    </Accordion >
  );
};

export default FilterAccordion;
