import { Heading } from "@chakra-ui/react";
import React from "react";
import { FilterSpecification } from "../../../generated";
import FilterAccordion from "./FilterAccordion";
import { colorsSecondary, textColors } from "../../../colorStyles";

interface Props {
  group: string;
  subgroups: { [subgroup: string]: FilterSpecification[] };
}

const FilterGroup = React.memo(({ group, subgroups }: Props) => {
  return (
    <>
      {group != "Primary" && (
        <Heading
          fontSize={18}
          mb={2}
          mt={3}
          color={textColors.primary}
        >
          {group}
        </Heading>
      )}
      {Object.keys(subgroups).map((subgroup, index) => (
        <FilterAccordion
          key={index}
          label={subgroup}
          filters={subgroups[subgroup]}


        />
      ))}
    </>
  );
});
export default FilterGroup;
