import { AddIcon } from "@chakra-ui/icons";
import { Box, Button, Heading, useDisclosure } from "@chakra-ui/react";
import { LinearProgress } from "@mui/material";
import { GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid-pro";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";
import { colorsPrimary, colorsSecondary, textColors } from "../colorStyles";
import Table from "../components/general/Table";
import CreateShadowteamModal from "../components/shadowteams/overview/CreateShadowteamModal";
import DeleteShadowteamModal from "../components/shadowteams/overview/DeleteShadowteamModal";
import EditShadowteamModal from "../components/shadowteams/overview/EditShadowteamModal";
import columns from "../components/shadowteams/overview/ShadowteamsOverviewColumns";
import { useGetAllShadowteams } from "../hooks/useShadowteams";
import theme from "../themes/playerTableTheme";
import { useContext } from "react";
import { LanguageContext } from "../store/languageContext";
import { LanguageKeys } from "../language/LanguageKeys";

const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarQuickFilter></GridToolbarQuickFilter>
    </GridToolbarContainer>
  );
};

const ShadowTeams = () => {
  const { guiText } = useContext(LanguageContext);

  const { data, isLoading } = useGetAllShadowteams();
  const navigate = useNavigate();

  const handleShadowteamClick = (shadowteamId: number) => {
    navigate(`/shadowTeams/${shadowteamId}`);
  };

  const {
    isOpen: isOpenEdit,
    onOpen: onOpenEdit,
    onClose: onCloseEdit,
  } = useDisclosure();
  const {
    isOpen: isOpenCreate,
    onOpen: onOpenCreate,
    onClose: onCloseCreate,
  } = useDisclosure();
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();

  const { keycloak } = useKeycloak();

  const userId = keycloak.idTokenParsed?.sub;

  return (
    <Box flex={1} pb={3} display="flex" flexDir="column" overflow="auto">
      <Box
        display="flex"
        flexDir="row"
        justifyContent="space-between"
        alignItems="center"
        pb={2}
      >
        <Heading fontSize="24" color={textColors.primary} fontFamily={"Text_Regular"}>{guiText[LanguageKeys.SidebarSchattenmannschaften]}</Heading>
        <Button
          leftIcon={<AddIcon fill="white" />}
          backgroundColor={colorsSecondary.accent}
          textColor={colorsSecondary.background}
          fontWeight="thin"
          fontSize="sm"
          textAlign="center"
          boxShadow="sm"
          size="sm"
          _hover={{ bg: "" }}
          onClick={onOpenCreate}
        >
          {guiText[LanguageKeys.NeueSchattenmannschaftErstellen]}
        </Button>
      </Box>
      <Box minW="1500px" display="flex" flex={1} overflow="hidden">
        <Table
          rows={data ?? []}
          columnDefinitions={columns({
            onOpenEdit: onOpenEdit,
            onOpenDelete: onOpenDelete,
            handleClick: handleShadowteamClick,
            guiText: guiText,
          })}
          theme={theme}
          getRowId={(row: { shadowteamId: any }) => row.shadowteamId}
          loading={isLoading}
          getRowClassName={(params: { row: { userId: string | undefined } }) =>
            params.row.userId == userId ? "ownShadowteam" : "sharedShadowteam"
          }
          slots={{
            loadingOverlay: LinearProgress,
            toolbar: CustomToolbar,
          }}
        />
      </Box>
      <EditShadowteamModal isOpen={isOpenEdit} onClose={onCloseEdit} />
      <CreateShadowteamModal isOpen={isOpenCreate} onClose={onCloseCreate} />
      <DeleteShadowteamModal isOpen={isOpenDelete} onClose={onCloseDelete} />
    </Box>
  );
};

export default ShadowTeams;
