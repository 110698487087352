import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { colorsPrimary, colorsSecondary, textColors } from "../../../colorStyles";
import { useDeleteShadowteam } from "../../../hooks/useShadowteams";
import { useShadowteamOverviewStore } from "../../../store/store";
import { useContext } from "react";
import { LanguageContext } from "../../../store/languageContext";
import { LanguageKeys } from "../../../language/LanguageKeys";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const DeleteShadowteamModal = ({ isOpen, onClose }: Props) => {
  const { guiText } = useContext(LanguageContext);
  const { shadowteam } = useShadowteamOverviewStore();

  const deleteShadowteam = useDeleteShadowteam();

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent backgroundColor={colorsSecondary.cardBackground} color={textColors.primary}>
        <ModalHeader borderBottom="2px solid #CBD5E0">
          {guiText[LanguageKeys.SchattenmannschaftLoeschen]}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={2} pt={4}>
          <Text as="span">{guiText[LanguageKeys.WillstDuDieSchattenmannschaft]} </Text>
          <Text as="span" fontWeight="bold">
            {'"' + shadowteam?.shadowteamName + '"'}
          </Text>
          <Text as="span"> {guiText[LanguageKeys.WirklichLoeschen]}</Text>
        </ModalBody>

        <ModalFooter>
          <Button
            mr={3}
            backgroundColor={colorsSecondary.accent}
            textColor={colorsSecondary.background}
            boxShadow="sm"
            onClick={() => {
              deleteShadowteam(shadowteam?.shadowteamId!);
              onClose();
            }}
          >
            {guiText[LanguageKeys.Loeschen]}
          </Button>
          <Button
            onClick={onClose}
            variant="outline"
            color={colorsSecondary.accent}
          >
            {guiText[LanguageKeys.Abbrechen]}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteShadowteamModal;
