/* tslint:disable */
/* eslint-disable */
/**
 * alleyes SCOUT BACKEND
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Shot
 */
export interface Shot {
    /**
     * 
     * @type {number}
     * @memberof Shot
     */
    xPos: number;
    /**
     * 
     * @type {number}
     * @memberof Shot
     */
    yPos: number;
    /**
     * 
     * @type {boolean}
     * @memberof Shot
     */
    kopfball: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Shot
     */
    tor: boolean;
    /**
     * 
     * @type {number}
     * @memberof Shot
     */
    xgSchuss?: number;
    /**
     * 
     * @type {number}
     * @memberof Shot
     */
    postShotXgSchuss?: number;
}

/**
 * Check if a given object implements the Shot interface.
 */
export function instanceOfShot(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "xPos" in value;
    isInstance = isInstance && "yPos" in value;
    isInstance = isInstance && "kopfball" in value;
    isInstance = isInstance && "tor" in value;

    return isInstance;
}

export function ShotFromJSON(json: any): Shot {
    return ShotFromJSONTyped(json, false);
}

export function ShotFromJSONTyped(json: any, ignoreDiscriminator: boolean): Shot {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'xPos': json['xPos'],
        'yPos': json['yPos'],
        'kopfball': json['kopfball'],
        'tor': json['tor'],
        'xgSchuss': !exists(json, 'xgSchuss') ? undefined : json['xgSchuss'],
        'postShotXgSchuss': !exists(json, 'postShotXgSchuss') ? undefined : json['postShotXgSchuss'],
    };
}

export function ShotToJSON(value?: Shot | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'xPos': value.xPos,
        'yPos': value.yPos,
        'kopfball': value.kopfball,
        'tor': value.tor,
        'xgSchuss': value.xgSchuss,
        'postShotXgSchuss': value.postShotXgSchuss,
    };
}

