import { useContext, useEffect, useState } from "react";
import { FilterSpecification } from "../generated";
import { LanguageContext } from "../store/languageContext";
import { LocaleKeys } from "../language/LocaleKeys";

const useGroupFilterSpecs = (data: FilterSpecification[] | undefined) => {
  const {language} = useContext(LanguageContext);
  const [groupedFilterSpecs, setGroupedFilterSpecs] = useState<{
    [group: string]: { [subgroup: string]: FilterSpecification[] };
  }>({});

  useEffect(() => {
    const tempGroupedFilterSpecs: {
      [group: string]: { [subgroup: string]: FilterSpecification[] };
    } = {};

    data?.forEach((spec) => {
      const subgroupName = language == LocaleKeys.DE? spec.subgroupGerman: language==LocaleKeys.EN? spec.subgroupEnglish: spec.subgroupSpanish
      if (!tempGroupedFilterSpecs[spec.group]) {
        tempGroupedFilterSpecs[spec.group] = {};
      }
      if (!tempGroupedFilterSpecs[spec.group][subgroupName]) {
        tempGroupedFilterSpecs[spec.group][subgroupName] = [];
      }
      tempGroupedFilterSpecs[spec.group][subgroupName].push(spec);
    });

    setGroupedFilterSpecs(tempGroupedFilterSpecs);
  }, [data, language]);

  return { groupedFilterSpecs };
};

export default useGroupFilterSpecs;
