import {
  Card,
  CardBody,
  Center,
  SkeletonCircle,
  SkeletonText,
} from "@chakra-ui/react";
import React from "react";
import { colorsSecondary } from "../../../colorStyles";
const PlayerCardSkeleton = () => {
  return (
    <Card backgroundColor={colorsSecondary.cardBackground}>
      <CardBody>
        <Center>
          <SkeletonCircle boxSize="100px" />
        </Center>
        <SkeletonText mt="4" noOfLines={4} spacing="4" skeletonHeight="3" />
      </CardBody>
    </Card>
  );
};

export default PlayerCardSkeleton;
