import { cloneDeep } from "lodash";
import { Player, Shadowteam, ShadowteamEntry } from "../../generated";
import { formationMapping } from "./formation/FormationsAndPositions";

export const groupByPosition = (
  players: ShadowteamEntry[] | undefined
): Map<string, ShadowteamEntry[]> => {
  const map = new Map<string, ShadowteamEntry[]>();

  if (players == undefined) {
    return map;
  }

  players.forEach((playerEntry) => {
    if (!playerEntry.shadowteamPosition) {
      return;
    }

    const currentEntries = map.get(playerEntry.shadowteamPosition) ?? [];

    currentEntries.push(playerEntry);

    currentEntries.sort((a, b) => a.prio - b.prio);

    map.set(playerEntry.shadowteamPosition, currentEntries);
  });

  return map;
};


const positionsBackToFront = [
  "GK", // goalkeeper
  "CB", "LCB", "RCB",  // central defender
  "LFB", "RFB", "LWB", "RWB", // full back
  "DM", "LDM", "RDM", // defensive midfield
  "CM", "LCM", "RCM", "AM", "RAM", "LAM", // central midfield
  "LWIN", "RWIN", "LM", "RM", // winger
  "CFW", "LFW", "RFW", // forward
  "BENCH",
];
export const sortByPositionBackToFront = (
  players: ShadowteamEntry[] | undefined
): ShadowteamEntry[] => {
  if (players == undefined) {
    return [];
  }

  const positionToPlayersMap: Map<string, ShadowteamEntry[]> = groupByPosition(players);
  const sortedMapKeys: string[] = [...positionToPlayersMap.keys()].sort((pos1: string, pos2: string) => {
    return positionsBackToFront.indexOf(pos1) - positionsBackToFront.indexOf(pos2);
  });

  return sortedMapKeys.flatMap((key: string) => positionToPlayersMap.get(key) ?? []);
};

export const playerToBench = (player: Player): Player => {
  player.positionsGruppe = "";
  player.alleyes = undefined;
  player.alleyesPotential = undefined;
  player.technischPs = undefined;
  player.athletisch = undefined;
  player.technischD = undefined;
  player.taktischRun = undefined;
  player.taktischPasses = undefined;

  return player;
};

export const toNewFormation = (
  shadowteam: Shadowteam,
  formation: string
): Shadowteam => {
  const copy = cloneDeep(shadowteam);
  copy.formation = formation;
  let maxBenchPrio = 0;
  copy.players.forEach((entry) => {
    const idx = formationMapping
      .get(formation)
      ?.findIndex((position) => position.name == entry.shadowteamPosition);

    if (entry.shadowteamPosition == "BENCH" && entry.prio > maxBenchPrio) {
      maxBenchPrio = entry.prio;
    }
    if (entry.shadowteamPosition != "BENCH" && idx == -1) {
      entry.shadowteamPosition = "BENCH";
      entry.player = playerToBench(entry.player);
      entry.prio = -1;
    }
  });

  copy.players.forEach((entry) => {
    if (entry.shadowteamPosition == "BENCH" && entry.prio == -1) {
      entry.prio = maxBenchPrio + 1;
      maxBenchPrio++;
    }
  });

  return copy;
};
