/* tslint:disable */
/* eslint-disable */
/**
 * alleyes SCOUT BACKEND
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Injury
 */
export interface Injury {
    /**
     * Identifier of the player.
     * @type {number}
     * @memberof Injury
     */
    spielerIdTm: number;
    /**
     * 
     * @type {Date}
     * @memberof Injury
     */
    verletzungStart: Date;
    /**
     * 
     * @type {Date}
     * @memberof Injury
     */
    verletzungEnde?: Date;
    /**
     * 
     * @type {string}
     * @memberof Injury
     */
    verletzungTage?: string;
    /**
     * 
     * @type {number}
     * @memberof Injury
     */
    verletzungMissedMatches?: number;
    /**
     * 
     * @type {string}
     * @memberof Injury
     */
    verletzung: string;
}

/**
 * Check if a given object implements the Injury interface.
 */
export function instanceOfInjury(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "spielerIdTm" in value;
    isInstance = isInstance && "verletzungStart" in value;
    isInstance = isInstance && "verletzung" in value;

    return isInstance;
}

export function InjuryFromJSON(json: any): Injury {
    return InjuryFromJSONTyped(json, false);
}

export function InjuryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Injury {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'spielerIdTm': json['spielerIdTm'],
        'verletzungStart': (new Date(json['verletzungStart'])),
        'verletzungEnde': !exists(json, 'verletzungEnde') ? undefined : (new Date(json['verletzungEnde'])),
        'verletzungTage': !exists(json, 'verletzungTage') ? undefined : json['verletzungTage'],
        'verletzungMissedMatches': !exists(json, 'verletzungMissedMatches') ? undefined : json['verletzungMissedMatches'],
        'verletzung': json['verletzung'],
    };
}

export function InjuryToJSON(value?: Injury | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'spielerIdTm': value.spielerIdTm,
        'verletzungStart': (value.verletzungStart.toISOString().substr(0,10)),
        'verletzungEnde': value.verletzungEnde === undefined ? undefined : (value.verletzungEnde.toISOString().substr(0,10)),
        'verletzungTage': value.verletzungTage,
        'verletzungMissedMatches': value.verletzungMissedMatches,
        'verletzung': value.verletzung,
    };
}

