import { useToast } from "@chakra-ui/react";
import { useContext } from "react";
import { LanguageContext } from "../store/languageContext";
import { LanguageKeys } from "../language/LanguageKeys";

const useShowToast = () => {
  const {guiText} = useContext(LanguageContext);
  const toast = useToast();

  return (operation: "add" | "edit", isError: boolean) => {
    if (isError) {
      toast({
        title: guiText[LanguageKeys.Fehler] + ".",
        description: guiText[LanguageKeys.Beim_BeimSpeichernFehlerAufgetreten] +
          (operation == "add" ? guiText[LanguageKeys.Speichern_BeimSpeichernFehlerAufgetreten] : guiText[LanguageKeys.Updaten_BeimUpdatenFehlerAufgetreten] ) +
          guiText[LanguageKeys.DeinesFiltersIstEinFehlerAufgetreten],
        status: "error",
        duration: 4000,
        variant: "subtle",
      });
    } else {
      toast({
        title:
          guiText[LanguageKeys.SearchBarFilter] + " " + (operation === "edit" ? guiText[LanguageKeys.Geupdatet] : guiText[LanguageKeys.Gespeichert]),
        description:
           guiText[LanguageKeys.DeinFilterWurdeErfolgreich] + " " +
          (operation === "edit" ? guiText[LanguageKeys.Geupdatet] + ".": guiText[LanguageKeys.Gespeichert] + "."),
        status: "success",
        duration: 4000,
        variant: "subtle",
      });
    }
  };
};

export default useShowToast;
