/* tslint:disable */
/* eslint-disable */
/**
 * alleyes SCOUT BACKEND
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorBody,
  User,
} from '../models';
import {
    ErrorBodyFromJSON,
    ErrorBodyToJSON,
    UserFromJSON,
    UserToJSON,
} from '../models';

export interface GetUsersByNameRequest {
    userName: string;
    excludedUsers?: Array<string>;
}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI {

    /**
     * Get all effective realm roles that the user is endowed with in the systems default Keycloak realm (locally the <local> realm).
     */
    async getUserEffectiveRolesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/keycloak/roles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get all effective realm roles that the user is endowed with in the systems default Keycloak realm (locally the <local> realm).
     */
    async getUserEffectiveRoles(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getUserEffectiveRolesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get all users of.
     */
    async getUsersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<User>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/keycloak/user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserFromJSON));
    }

    /**
     * Get all users of.
     */
    async getUsers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<User>> {
        const response = await this.getUsersRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get users based on their name.
     */
    async getUsersByNameRaw(requestParameters: GetUsersByNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<User>>> {
        if (requestParameters.userName === null || requestParameters.userName === undefined) {
            throw new runtime.RequiredError('userName','Required parameter requestParameters.userName was null or undefined when calling getUsersByName.');
        }

        const queryParameters: any = {};

        if (requestParameters.excludedUsers) {
            queryParameters['excludedUsers'] = requestParameters.excludedUsers;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/keycloak/user/name/{userName}`.replace(`{${"userName"}}`, encodeURIComponent(String(requestParameters.userName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserFromJSON));
    }

    /**
     * Get users based on their name.
     */
    async getUsersByName(userName: string, excludedUsers?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<User>> {
        const response = await this.getUsersByNameRaw({ userName: userName, excludedUsers: excludedUsers }, initOverrides);
        return await response.value();
    }

}
