/* tslint:disable */
/* eslint-disable */
/**
 * alleyes SCOUT BACKEND
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorBody,
  FilterSpecification,
} from '../models';
import {
    ErrorBodyFromJSON,
    ErrorBodyToJSON,
    FilterSpecificationFromJSON,
    FilterSpecificationToJSON,
} from '../models';

export interface GetLeagueRequest {
    league: string;
}

export interface GetNationalityRequest {
    nationality: string;
}

export interface GetSimilarPlayersFilterSpecificationRequest {
    playerId: number;
    position: string;
    date: Date;
}

export interface GetTeamRequest {
    team: string;
}

/**
 * 
 */
export class FilterSpecificationApi extends runtime.BaseAPI {

    /**
     * Get the specification of all available filters
     */
    async getFilterSpecificationRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterSpecification>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/filterSpecification`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterSpecificationFromJSON));
    }

    /**
     * Get the specification of all available filters
     */
    async getFilterSpecification(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterSpecification>> {
        const response = await this.getFilterSpecificationRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get the leagues of players.
     */
    async getLeagueRaw(requestParameters: GetLeagueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.league === null || requestParameters.league === undefined) {
            throw new runtime.RequiredError('league','Required parameter requestParameters.league was null or undefined when calling getLeague.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/filterSpecification/league/{league}`.replace(`{${"league"}}`, encodeURIComponent(String(requestParameters.league))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get the leagues of players.
     */
    async getLeague(league: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getLeagueRaw({ league: league }, initOverrides);
        return await response.value();
    }

    /**
     * Get the nationalities of players.
     */
    async getNationalityRaw(requestParameters: GetNationalityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.nationality === null || requestParameters.nationality === undefined) {
            throw new runtime.RequiredError('nationality','Required parameter requestParameters.nationality was null or undefined when calling getNationality.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/filterSpecification/nationality/{nationality}`.replace(`{${"nationality"}}`, encodeURIComponent(String(requestParameters.nationality))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get the nationalities of players.
     */
    async getNationality(nationality: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getNationalityRaw({ nationality: nationality }, initOverrides);
        return await response.value();
    }

    /**
     * Get the specification of all available similar players filters
     */
    async getSimilarPlayersFilterSpecificationRaw(requestParameters: GetSimilarPlayersFilterSpecificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterSpecification>>> {
        if (requestParameters.playerId === null || requestParameters.playerId === undefined) {
            throw new runtime.RequiredError('playerId','Required parameter requestParameters.playerId was null or undefined when calling getSimilarPlayersFilterSpecification.');
        }

        if (requestParameters.position === null || requestParameters.position === undefined) {
            throw new runtime.RequiredError('position','Required parameter requestParameters.position was null or undefined when calling getSimilarPlayersFilterSpecification.');
        }

        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling getSimilarPlayersFilterSpecification.');
        }

        const queryParameters: any = {};

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = (requestParameters.date as any).toISOString().substr(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/filterSpecification/similarPlayers/{playerId}/{position}`.replace(`{${"playerId"}}`, encodeURIComponent(String(requestParameters.playerId))).replace(`{${"position"}}`, encodeURIComponent(String(requestParameters.position))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterSpecificationFromJSON));
    }

    /**
     * Get the specification of all available similar players filters
     */
    async getSimilarPlayersFilterSpecification(playerId: number, position: string, date: Date, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterSpecification>> {
        const response = await this.getSimilarPlayersFilterSpecificationRaw({ playerId: playerId, position: position, date: date }, initOverrides);
        return await response.value();
    }

    /**
     * Get the teams of players.
     */
    async getTeamRaw(requestParameters: GetTeamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.team === null || requestParameters.team === undefined) {
            throw new runtime.RequiredError('team','Required parameter requestParameters.team was null or undefined when calling getTeam.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/filterSpecification/team/{team}`.replace(`{${"team"}}`, encodeURIComponent(String(requestParameters.team))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get the teams of players.
     */
    async getTeam(team: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getTeamRaw({ team: team }, initOverrides);
        return await response.value();
    }

}
