import { Box, Center, Divider, GridItem, HStack, Image, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import React, { useContext, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import logo from "../../assets/alleyesLogo.png";
import PlayerBaseData from "../../components/profile/PlayerBaseData";
import { usePlayerProfileStore } from "../../store/store";
import Heatmap from "../profile/heatmapGoals/Heatmap";
import GoalsMap from "../profile/heatmapGoals/GoalsMap";
import { useDataAvailability, usePolarChartComparisonData, usePolarChartData, useShotHistory } from "../../hooks/usePlayer";
import { LanguageContext } from "../../store/languageContext";
import usePreparePolarChartData, { innerLabel, patternPlugin } from "../../hooks/usePreparePolarChartData";
import { PolarArea } from "react-chartjs-2";
import AlleyesScores from "../profile/AlleyesScores";
import { LanguageKeys } from "../../language/LanguageKeys";
import { ArcElement, Chart as ChartJS, Legend, RadialLinearScale, Title, Tooltip } from "chart.js";
import styled from "styled-components";
import { LocaleKeys } from "../../language/LocaleKeys";

ChartJS.register(
  RadialLinearScale,
  ArcElement,
  Legend,
  Title,
  Tooltip,
  innerLabel,
  patternPlugin
);

const StyledPolarArea = styled(PolarArea)`
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;
  margin: 0 auto;
`;

const ProfilePrintView = () => {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const id = Number(queryParams.get("id")!);
  const date = queryParams.get("date");
  const position = queryParams.get("position");
  const { setDate } = usePlayerProfileStore();

  const { data: shots, isLoading: isLoadingShots } = useShotHistory(Number(id!), position!);

  const language = queryParams.get("language");
  const { setLanguage } = useContext(LanguageContext);

  useEffect(() => {
    setDate(new Date(date!));
  }, []);

  useEffect(() => {
    switch (language) {
      case LocaleKeys.DE:
        setLanguage(LocaleKeys.DE);
        break;
      case LocaleKeys.EN:
        setLanguage(LocaleKeys.EN);
        break;
      case LocaleKeys.ES:
        setLanguage(LocaleKeys.ES);
        break;
      default:
        setLanguage(LocaleKeys.DE);
    }
  }, []);

  const { data: dataAvailability, isLoading: isLoadingDataAvailability } = useDataAvailability(
    id,
    position!
  );

  useEffect(() => {
    if (!isLoadingDataAvailability && dataAvailability && date == null) {
      const sortedDates = dataAvailability.sort();
      setDate(new Date(sortedDates[sortedDates.length - 1]));
    }
  }, [isLoadingDataAvailability]);

  const { guiText } = useContext(LanguageContext);

  const chartRefs = useRef<Record<string, any>>({}); // Using Record type to define string keys and any values

  const { data: polarChartData, isLoading } = usePolarChartData(id!);

  const activePlayer = polarChartData?.find(
    (data) => data.positionsGruppe === position
  );

  const { data: polarChartComparisonData } =
    usePolarChartComparisonData(position!);

  const { chartDatas, options } = usePreparePolarChartData(
    activePlayer,
    polarChartComparisonData,
    position!,
    false,
    false,
    []
  );

  const minChartSize = "330px";

  return (
    <Box display="flex" flex={1} overflow="auto" p={6}>
      <SimpleGrid
        columns={2}
        backgroundColor="white"
        templateColumns={"310px 1fr"}
        borderRadius={5}
        flex={1}
        spacing={5}
        minH="800px"
        minW="1500px"
        overflow="hidden"
      >
        <GridItem
          p={4}
          height="100%"
          borderRight="2px solid #E2E8F0"
          overflow="hidden"
          display="flex"
        >
          <Box display="flex" flex={1} flexDir="column" overflow="hidden">
            <PlayerBaseData onOpen={() => void 0} playerId={id} printView />
          </Box>
        </GridItem>

        <GridItem p={4} flex={1} display="flex" overflow="hidden">
          {polarChartData && chartDatas && options ? (
            <VStack flex={1} display="flex" spacing={1} overflow="hidden">
              <HStack
                flex={1}
                justifyContent="space-around"
                divider={
                  <Divider
                    border="1px solid"
                    borderColor="#CBD5E0"
                    orientation="vertical"
                  />
                }
                display="flex"
                w="100%"
                overflow="hidden"
              >
                <Box
                  flex={1}
                  pb={3}
                  overflow="hidden"
                  minW={minChartSize}
                  minH={minChartSize}
                  h="99%"
                >
                  <StyledPolarArea
                    id="polar-charts-athletisch"
                    ref={(el) => (chartRefs.current["athletisch"] = el)}
                    data={chartDatas[0]}
                    options={options[0]}
                    onClick={() => void 0}
                  />
                </Box>

                <Box
                  flex={1}
                  display="flex"
                  minW={minChartSize}
                  h="99%"
                  overflow="hidden"
                >
                  <AlleyesScores
                    id="polar-charts-s04-scores"
                    playerId={id!}
                    position={position!}
                    useCase="profile"
                  ></AlleyesScores>
                </Box>

                <Box
                  flex={1}
                  pb={3}
                  overflow="hidden"
                  minW={minChartSize}
                  minH={minChartSize}
                  h="99%"
                >
                  <StyledPolarArea
                    id="polar-charts-technischPs"
                    ref={(el) => (chartRefs.current["technischPs"] = el)}
                    onClick={() => void 0}
                    data={chartDatas[1]}
                    options={options[1]}
                  />
                </Box>
              </HStack>
              <Divider
                border="1px solid"
                borderColor="#CBD5E0"
                mt={1}
                mb={1}
              ></Divider>
              <HStack
                flex={1}
                display="flex"
                justifyContent="space-around"
                divider={
                  <Divider
                    border="1px solid"
                    borderColor="#CBD5E0"
                    orientation="vertical"
                  />
                }
                w="100%"
                overflow="hidden"
              >
                <Box
                  flex={1}
                  overflow="hidden"
                  minW={minChartSize}
                  minH={minChartSize}
                  h="99%"
                >
                  <StyledPolarArea
                    id="polar-charts-technischD"
                    ref={(el) => (chartRefs.current["technischD"] = el)}
                    onClick={() => void 0}
                    data={chartDatas[2]}
                    options={options[2]}
                  />
                </Box>
                <Box
                  flex={1}
                  overflow="hidden"
                  minW={minChartSize}
                  minH={minChartSize}
                  h="99%"
                >
                  <StyledPolarArea
                    id="polar-charts-taktischRun"
                    ref={(el) => (chartRefs.current["taktischRun"] = el)}
                    onClick={() => void 0}
                    data={chartDatas[3]}
                    options={options[3]}
                  />
                </Box>
                <Box
                  flex={1}
                  overflow="hidden"
                  minW={minChartSize}
                  minH={minChartSize}
                  h="99%"
                >
                  <StyledPolarArea
                    id="polar-charts-taktischPasses"
                    ref={(el) => (chartRefs.current["taktischPasses"] = el)}
                    onClick={() => void 0}
                    data={chartDatas[4]}
                    options={options[4]}
                  />
                </Box>
              </HStack>
              <Divider
                border="1px solid"
                borderColor="#CBD5E0"
                mt={1}
                mb={1}
              ></Divider>
              <HStack
                flex={1}
                display="flex"
                justifyContent="space-around"
                divider={
                  <Divider
                    border="1px solid"
                    borderColor="#CBD5E0"
                    orientation="vertical"
                  />
                }
                w="100%"
                maxH="380px"
                overflow="hidden"
              >
                <Box
                  flex={1}
                  overflow="hidden"
                  minW={minChartSize}
                  minH={minChartSize}
                  h="99%"
                >
                  <Heatmap id={Number(id)} position={position!} maxH="350px" minW={minChartSize} h="99%" />
                </Box>
                <Box
                  flex={1}
                  overflow="hidden"
                  minW={minChartSize}
                  h="99%"
                >
                  <GoalsMap shots={shots} isLoading={isLoadingShots} postShot={false} />
                </Box>
                <Box
                  flex={1}
                  minW={minChartSize}
                  overflow="hidden"
                  h="99%"
                >
                  <GoalsMap shots={shots} isLoading={isLoadingShots} postShot={true} />
                </Box>
              </HStack>
            </VStack>
          ) : isLoading || isLoadingShots ? (
            <Center h="100%" w="100%">
              <Text fontSize={22}>Loading...</Text>
            </Center>
          ) : (
            <Center id="player-profile-no-data" h="100%" w="100%">
              <Text fontSize={20}>{guiText[LanguageKeys.KeineDatenvorhanden]}</Text>
            </Center>
          )}
        </GridItem>
      </SimpleGrid>
      <Image
        position="fixed"
        bottom={4}
        right={4}
        src={logo}
        boxSize="65px"
      ></Image>
    </Box>
  );
};

export default ProfilePrintView;
