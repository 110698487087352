/* tslint:disable */
/* eslint-disable */
/**
 * alleyes SCOUT BACKEND
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddPlayerToWatchlistRequest,
  ErrorBody,
  WatchlistEntry,
} from '../models';
import {
    AddPlayerToWatchlistRequestFromJSON,
    AddPlayerToWatchlistRequestToJSON,
    ErrorBodyFromJSON,
    ErrorBodyToJSON,
    WatchlistEntryFromJSON,
    WatchlistEntryToJSON,
} from '../models';

export interface AddPlayerToWatchlistOperationRequest {
    addPlayerToWatchlistRequest: AddPlayerToWatchlistRequest;
}

export interface DeletePlayerFromWatchlistRequest {
    playerId: number;
    position: string;
}

export interface ToggleFavoriteStatusRequest {
    playerId: number;
    position: string;
}

/**
 * 
 */
export class WatchlistApi extends runtime.BaseAPI {

    /**
     * Add a player to the scout\'s watchlist.
     */
    async addPlayerToWatchlistRaw(requestParameters: AddPlayerToWatchlistOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.addPlayerToWatchlistRequest === null || requestParameters.addPlayerToWatchlistRequest === undefined) {
            throw new runtime.RequiredError('addPlayerToWatchlistRequest','Required parameter requestParameters.addPlayerToWatchlistRequest was null or undefined when calling addPlayerToWatchlist.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/watchlist`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddPlayerToWatchlistRequestToJSON(requestParameters.addPlayerToWatchlistRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add a player to the scout\'s watchlist.
     */
    async addPlayerToWatchlist(addPlayerToWatchlistRequest: AddPlayerToWatchlistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addPlayerToWatchlistRaw({ addPlayerToWatchlistRequest: addPlayerToWatchlistRequest }, initOverrides);
    }

    /**
     * Delete a player from the scout\'s watchlist.
     */
    async deletePlayerFromWatchlistRaw(requestParameters: DeletePlayerFromWatchlistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.playerId === null || requestParameters.playerId === undefined) {
            throw new runtime.RequiredError('playerId','Required parameter requestParameters.playerId was null or undefined when calling deletePlayerFromWatchlist.');
        }

        if (requestParameters.position === null || requestParameters.position === undefined) {
            throw new runtime.RequiredError('position','Required parameter requestParameters.position was null or undefined when calling deletePlayerFromWatchlist.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/watchlist/deleteFromWatchlist/{playerId}/{position}`.replace(`{${"playerId"}}`, encodeURIComponent(String(requestParameters.playerId))).replace(`{${"position"}}`, encodeURIComponent(String(requestParameters.position))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a player from the scout\'s watchlist.
     */
    async deletePlayerFromWatchlist(playerId: number, position: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deletePlayerFromWatchlistRaw({ playerId: playerId, position: position }, initOverrides);
    }

    /**
     * Get all players from the watchlist of a scout.
     */
    async getWatchlistRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<WatchlistEntry>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/watchlist`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WatchlistEntryFromJSON));
    }

    /**
     * Get all players from the watchlist of a scout.
     */
    async getWatchlist(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<WatchlistEntry>> {
        const response = await this.getWatchlistRaw(initOverrides);
        return await response.value();
    }

    /**
     * Toggle a player\'s favorite status on the watchlist.
     */
    async toggleFavoriteStatusRaw(requestParameters: ToggleFavoriteStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.playerId === null || requestParameters.playerId === undefined) {
            throw new runtime.RequiredError('playerId','Required parameter requestParameters.playerId was null or undefined when calling toggleFavoriteStatus.');
        }

        if (requestParameters.position === null || requestParameters.position === undefined) {
            throw new runtime.RequiredError('position','Required parameter requestParameters.position was null or undefined when calling toggleFavoriteStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/watchlist/toggleFavoriteStatus/{playerId}/{position}`.replace(`{${"playerId"}}`, encodeURIComponent(String(requestParameters.playerId))).replace(`{${"position"}}`, encodeURIComponent(String(requestParameters.position))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Toggle a player\'s favorite status on the watchlist.
     */
    async toggleFavoriteStatus(playerId: number, position: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.toggleFavoriteStatusRaw({ playerId: playerId, position: position }, initOverrides);
    }

}
