import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
} from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import {
  useLastMainPageStore,
  usePlayerProfileStore,
  useShadowteamOverviewStore,
} from "../store/store";
import { useContext } from "react";
import { LanguageContext } from "../store/languageContext";
import { LanguageKeys } from "../language/LanguageKeys";
import { textColors } from "../colorStyles";
const BreadcrumbNavigation = () => {
  const { guiText } = useContext(LanguageContext);
  const location = useLocation();

  const breadcrumbLabels: { [key: string]: string } = {
    player: guiText[LanguageKeys.Spielerdetails],
    shadowTeams: guiText[LanguageKeys.SidebarSchattenmannschaften],
    watchlist: guiText[LanguageKeys.SidebarWatchlist],
    compare: guiText[LanguageKeys.SidebarSpielervergleich],
    search: guiText[LanguageKeys.SidebarSpielersuche],
    login: guiText[LanguageKeys.Login],
    glossary: guiText[LanguageKeys.Glossar],
  };

  const { lastMainPage } = useLastMainPageStore();

  // Check if playerName is available in the state
  const { playerName } = usePlayerProfileStore();
  const { clickedOnShadowteamName } = useShadowteamOverviewStore();

  return (
    <Breadcrumb separator={<ChevronRightIcon color={textColors.lightgrey} />}>
      <BreadcrumbItem>
        <BreadcrumbLink as={Link} to="/" color={textColors.lightgrey}>
          {guiText[LanguageKeys.SidebarHome]}
        </BreadcrumbLink>
      </BreadcrumbItem>

      {lastMainPage != "Home" && (
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to={`/${lastMainPage}`} color={textColors.lightgrey}>
            {breadcrumbLabels[lastMainPage] || lastMainPage}
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}

      {playerName && (
        <BreadcrumbItem>
          <Text color={textColors.lightgrey}>{playerName}</Text>
        </BreadcrumbItem>
      )}

      {clickedOnShadowteamName && (
        <BreadcrumbItem>
          <Text color={textColors.lightgrey}>{clickedOnShadowteamName}</Text>
        </BreadcrumbItem>
      )}
    </Breadcrumb>
  );
};

export default BreadcrumbNavigation;
