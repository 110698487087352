import { Box, Heading, HStack, Spinner, Text, VStack } from "@chakra-ui/react";
import { colorsPrimary, colorsSecondary, textColors } from "../../../colorStyles";
import { useFilterSpecification } from "../../../hooks/useFilterSpecification";
import useGroupFilterSpecs from "../../../hooks/useGroupFilterSpecs";
import { useFilterValueStore, useSearchResultSize } from "../../../store/store";
import FilterGroup from "./FilterGroup";
import FilterPresets from "./FilterPresets";
import { useContext } from "react";
import { LanguageContext } from "../../../store/languageContext";
import { LanguageKeys } from "../../../language/LanguageKeys";
import { LocaleKeys } from "../../../language/LocaleKeys";

const FilterAside = () => {
  const { guiText, language } = useContext(LanguageContext);
  const { data, error, isLoading } = useFilterSpecification();
  const noOfFiltersSelected = useFilterValueStore(
    (state) => state.filterValues.filter((filter) => filter != null).length
  );

  const { groupedFilterSpecs } = useGroupFilterSpecs(data);

  const { size } = useSearchResultSize();

  if (error) return <Text>{error.message}</Text>;

  return (
    <Box height="100%" width="100%" display="flex" flexDir="column">
      <HStack align="flex-end" mb={3}>
        <Heading size="md" color={textColors.primary}>{guiText[LanguageKeys.SearchBarFilter]}</Heading>
        <Text color={textColors.lightgrey} fontSize="xs">
          {noOfFiltersSelected} {guiText[LanguageKeys.FilterAusgewaehlt]}, {size} {guiText[LanguageKeys.SpielerGefunden]}
        </Text>
      </HStack>
      <Box>
        <FilterPresets />
      </Box>
      <Box
        flex={1}
        overflow="auto"
        sx={{
          "::-webkit-scrollbar": {
            display: "none",
          },
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
      >
        {isLoading && (
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color={colorsSecondary.accent}
            size="xl"
          />
        )}
        {groupedFilterSpecs &&
          Object.keys(groupedFilterSpecs).map((group, index) => (
            <FilterGroup
              key={index}
              group={language == LocaleKeys.DE || group == "Primary" ? group : language == LocaleKeys.EN ? "Other data" : "Otros datos"}
              subgroups={groupedFilterSpecs[group]}
            />
          ))}
      </Box>
    </Box>
  );
};

export default FilterAside;
