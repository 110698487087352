import { useQuery } from "@tanstack/react-query";
import ms from "ms";
import { Player } from "../generated";
import { getHomeApi } from "../util/keycloak";

type QueryFn = () => Promise<Player[]>;

const useFetchPlayers = (query: QueryFn, key: string, staleTime = "10h") =>
  useQuery<Player[], Error>([key], query, {
    staleTime: ms(staleTime),
    refetchOnWindowFocus: false,
  });

export const useTopTrendShortPlayers = () => {
  const query = async (): Promise<Player[]> =>
    await getHomeApi().getTopTrendShortPlayers();
  return useFetchPlayers(query, "topTrendShortPlayers", "0h");
};

export const useTopTrendLongPlayers = () => {
  const query = async (): Promise<Player[]> =>
    await getHomeApi().getTopTrendLongPlayers();
  return useFetchPlayers(query, "topTrendLongPlayers", "0h");
};

export const useMissingReviewPlayers = () => {
  const query = async (): Promise<Player[]> =>
    await getHomeApi().getMissingReviewPlayers();
  return useFetchPlayers(query, "missingReviewPlayers", "10s");
};

export const useNewPlayers = () => {
  const query = async (): Promise<Player[]> =>
    await getHomeApi().getNewPlayers();
  return useFetchPlayers(query, "newPlayers");
};

export const useCustomFilteredPlayers = () => {
  const query = async (): Promise<Player[]> =>
    await getHomeApi().getCustomFilteredPlayers();
  return useFetchPlayers(query, "customFilteredPlayers", "0h");
};
