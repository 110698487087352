import { IconButton } from "@mui/material";
import { FaEye } from "react-icons/fa";
import { colorsPrimary, colorsSecondary } from "../../../colorStyles";
import { Player } from "../../../generated";
import { useIsInWatchlistOrShadowteam } from "../../../hooks/usePlayer";
import { usePlayerModalStore } from "../../../store/store";

interface Props {
  onOpenModal: () => void;
  player: Player;
}

const EyeIconColumn = ({ onOpenModal, player }: Props) => {
  const { setPlayer } = usePlayerModalStore();
  const { data: isInWatchlistOrShadowteam } = useIsInWatchlistOrShadowteam(
    player.id,
    player.positionsGruppe
  );

  const isInWatchlistOrShadowteamBoolean =
    isInWatchlistOrShadowteam != undefined &&
    parseInt(isInWatchlistOrShadowteam + "") == 1;

  const handleOnClick = () => {
    setPlayer(player);
    onOpenModal();
  };

  return (
    <IconButton
      aria-label="Add to Watchlist or ShadowTeam"
      onClick={handleOnClick}
    >
      <FaEye
        size="25px"
        color={
          isInWatchlistOrShadowteamBoolean
            ? colorsSecondary.accent
            : "#8a8a8a"
        }
      />
    </IconButton>
  );
};

export default EyeIconColumn;
