import {isNumber} from "../../../util/utilFunctions";

const NumberCellRenderer = (value: number, fractionDigits: number = 0): string => {
  let renderValue = "-";
  try {
    if (isNumber(value)) {
      renderValue = value.toFixed(fractionDigits).toString();
    }
  } catch (e) {}
  return renderValue;
};
export default NumberCellRenderer;
