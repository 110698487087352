import { IconButton } from "@mui/material";
import { FaRegStar, FaStar } from "react-icons/fa";
import { useToggleFavoriteStatus } from "../../../hooks/useShadowteams";

interface Props {
  shadowteamId: number;
  favorite: boolean;
}

const FavoriteIconShadowteams = ({ shadowteamId, favorite }: Props) => {
  const toggleFavorite = useToggleFavoriteStatus(shadowteamId);

  return (
    <IconButton
      aria-label="Add to Watchlist or ShadowTeam"
      onClick={() => toggleFavorite()}
    >
      {favorite ? (
        <FaStar size="20px" color="white" />
      ) : (
        <FaRegStar size="20px" color="white" />
      )}
    </IconButton>
  );
};

export default FavoriteIconShadowteams;
