import { Card, CardBody, CardHeader } from "@chakra-ui/react";
import React, { PropsWithChildren, ReactNode } from "react";
import { colorsSecondary } from "../../colorStyles";
interface Props {
  header?: ReactNode;
  body?: ReactNode;
  [x: string]: any;
}

const Tile = ({
  header,
  body,
  children,
  ...rest
}: PropsWithChildren<Props>) => {
  return (
    <Card
      variant="outlined"
      display="flex"
      flexDir="column"
      flex={1}
      bgColor={colorsSecondary.cardBackground}
      borderRadius={5}
      p={2}
      data-cy="tile-root-container"
      {...rest}
    >
      {header && (
        <CardHeader
          display="flex"
          justifyContent="center"
          alignItems="center"
          pt={2}
          pb={3}
          data-cy="tile-header-container"
        >
          {header}
        </CardHeader>
      )}
      {body && (
        <CardBody
          display="flex"
          flex={1}
          data-cy="tile-body-container"
        >
          {body}
        </CardBody>
      )}
      {children}
    </Card>
  );
};

export default Tile;
