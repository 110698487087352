import { GridColDef } from "@mui/x-data-grid-pro";
import { ChangeEvent } from "react";
import { WatchlistEntry } from "../../generated";
import {
  added, age,
  athletisch,
  comment,
  compare, deleteFromWatchlist,
  favorite,
  height, lastReview, marketValue, numberOfReviews, positionsGruppe, stammdaten,
  alleyes,
  subjectiveGameRatingsOverall,
  subjectiveGameRatingsPotential,
  subjectiveOverall,
  subjectivePotential,
  taktischPasses,
  taktischRun,
  technischD,
  technischPs, watch
} from "../general/ColumnDefinitions";
import { GuiTextType } from "../../store/languageContext";
import { LanguageKeys } from "../../language/LanguageKeys";

interface Props {
  onOpenModal: () => void;
  onCommentClick: (entry: WatchlistEntry) => void;
  selectedPlayerIds: string[];
  onSelect: (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
    id: string
  ) => void;
  guiText: GuiTextType
}

const columns = ({
  onOpenModal,
  onCommentClick,
  selectedPlayerIds,
  onSelect,
  guiText
}: Props): GridColDef[] => {
  return [
    favorite("row.player"),
    stammdaten("row.player", guiText[LanguageKeys.SpielerprofilStammdaten], {
      sortComparator: (v1: string, v2: string) => {
        const surname1 = v1.split(" ").pop()!;
        const surname2 = v2.split(" ").pop()!;
        return surname1.localeCompare(surname2);
      },
    }),
    positionsGruppe("row.player.positionsGruppe", guiText[LanguageKeys.SearchBarPosition]),
    age("row.player.age", guiText[LanguageKeys.SearchBarAlter]),
    height("row.player.height", guiText[LanguageKeys.SearchBarKorpergrobe]),
    marketValue("row.player.marketValue", guiText[LanguageKeys.SearchBarMarktwert]),
    alleyes("row.player.alleyes", guiText[LanguageKeys.SearchBarAlleyesAktuell]),
    athletisch("row.player.athletisch", guiText[LanguageKeys.SpielerprofilAthletisch]),
    technischPs("row.player.technischPs", guiText[LanguageKeys.TechnischnPS]),
    technischD("row.player.technischD", guiText[LanguageKeys.TechnischnDuelle]),
    taktischRun("row.player.taktischRun", guiText[LanguageKeys.TaktischnRun]),
    taktischPasses("row.player.taktischPasses", guiText[LanguageKeys.TaktischnPass]),
    subjectiveOverall("row.player.subjectiveOverall", guiText.GesamtbewertungA),
    subjectivePotential("row.player.subjectivePotential", guiText[LanguageKeys.GesamtbewertungP]),
    subjectiveGameRatingsOverall("row.player.subjectiveGameRatingsOverall", guiText[LanguageKeys.SpielbewertungA]),
    subjectiveGameRatingsPotential("row.player.subjectiveGameRatingsPotential", guiText[LanguageKeys.SpielbewertungP]),
    numberOfReviews("row.player.numberOfReviews", guiText[LanguageKeys.WatchlistAnzahlSichtungen]),
    lastReview("row.player.lastReview", guiText[LanguageKeys.WatchlistLetzteSichtung]),
    added("row.added", guiText[LanguageKeys.WatchlistHinzugefugtAm]),
    compare({ selectedPlayerIds, onSelect }),
    watch("row.player", { onOpenModal }),
    comment("row.player.id", { onClick: onCommentClick }),
    deleteFromWatchlist("row.player"),
  ];
};

export default columns;
