import { Box } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { Player } from "../../generated";
import useChartClickComparison from "../../hooks/useChartClickComparison";
import {
  useAvailableKpiClips,
  usePolarChartComparisonData,
  usePolarChartData,
} from "../../hooks/usePlayer";
import usePreparePolarChartData, { ReverseMapping } from "../../hooks/usePreparePolarChartData";
import { useComparisonStore } from "../../store/store";
import { formatDate } from "../../util/utilFunctions";
import PolarChart from "./PolarChart";
import KpiClips from "../profile/polarCharts/KpiClips";
import { LanguageContext } from "../../store/languageContext";
import { colorsSecondary } from "../../colorStyles";

interface Props {
  player: Player | undefined;
  date: Date;
  columnNo: number;
  onOpenRH: () => void;
}

const categories = [
  "athletisch",
  "technischPs",
  "technischD",
  "taktischRun",
  "taktischPasses",
];

const PolarChartsColumn = ({ player, date, columnNo, onOpenRH }: Props) => {
  const { guiText } = useContext(LanguageContext);
  const reverseMapping = ReverseMapping(guiText);
  const { data: polarChartData } = usePolarChartData(
    player?.id,
    player?.positionsGruppe,
    date
  );

  const { data: kpiClips } = useAvailableKpiClips(player?.id, player?.positionsGruppe);
  const kpisWithClips = [...new Set(kpiClips?.map(clip => clip.kpi))];

  const { setDate1, setDate2, setDate3 } = useComparisonStore();

  const activePlayer = polarChartData ? polarChartData[0] : undefined;

  useEffect(() => {
    if (activePlayer && formatDate(date) === formatDate(new Date())) {
      switch (columnNo) {
        case 1:
          setDate1(activePlayer.datum);
          break;
        case 2:
          setDate2(activePlayer.datum);
          break;
        case 3:
          setDate3(activePlayer.datum);
          break;
      }
    }
  }, [activePlayer]);

  const { data: polarChartComparisonData } = usePolarChartComparisonData(
    player?.positionsGruppe,
    date
  );

  const [selectedKpi, setSelectedKpi] = useState("");

  const { chartDatas, options } = usePreparePolarChartData(
    activePlayer,
    polarChartComparisonData,
    player?.positionsGruppe,
    true,
    true,
    kpisWithClips
  );

  const handleSelectKpi = (kpiLabel: string) => {
    const kpi = (kpiLabel in reverseMapping) && reverseMapping[kpiLabel];
    if (kpi && kpisWithClips.includes(kpi)) {
      setSelectedKpi(kpi);
    }
  };

  const { chartRefs, handleChartClick } = useChartClickComparison(
    player,
    date,
    chartDatas,
    onOpenRH,
    handleSelectKpi
  );

  return (
    <Box w="100%">
      {categories.map((category: string, idx: number) => (
        <Box
          id={category}
          key={category}
          display="flex"
          flex={1}
          mb={3}
          style={{ scrollMargin: "215px" }}
        >
          {chartDatas && (
            <PolarChart
              chartData={chartDatas[idx]}
              options={options[idx]}
              category={category}
              categoryIdx={idx}
              chartRefs={chartRefs}
              columnNo={columnNo}
              handleChartClick={handleChartClick}
            />
          )}
        </Box>
      ))}
      {selectedKpi != "" &&
        <KpiClips
          id={player!.id}
          position={player!.positionsGruppe}
          kpi={selectedKpi}
          data={kpiClips?.filter(clip => clip.kpi === selectedKpi)}
          onClose={() => setSelectedKpi("")}
        />
      }
    </Box>
  );
};

export default PolarChartsColumn;
