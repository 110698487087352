import { Box, Flex, HStack, Image, Text } from "@chakra-ui/react";
import React from "react";
import { colorsSecondary, textColors } from "../../../colorStyles";
import { Transfer } from "../../../generated";
import { formatDate } from "../../../util/utilFunctions";

interface Props {
  transfer: Transfer;
}
const TransferBar = ({ transfer }: Props) => {
  return (
    <HStack
      width="100%"
      // bg={colorsSecondary.background}
      bg={colorsSecondary.cardBackground}
      spacing={0}
      pl={2}
      boxShadow="md"
    >
      <Box w="10%">
        <Text color={textColors.primary}>{transfer.transferSaison}</Text>
      </Box>
      <Box w="15%">
        <Text color={textColors.primary}>{formatDate(transfer.transferDatum)}</Text>
      </Box>
      <Flex w="30%" flexDir="row" alignItems="center">
        <Image p={1} src={transfer.teamAltImageTm} h={14} />
        <Text pl={2} color={textColors.primary}>{transfer.teamAltNameTm}</Text>
      </Flex>
      <Flex w="30%" flexDir="row" alignItems="center">
        <Image p={1} src={transfer.teamNeuImageTm} h={14} />
        <Text pl={2} color={textColors.primary}>{transfer.teamNeuNameTm}</Text>
      </Flex>

      <Box w="15%">
        <Text color={textColors.primary}>
          {(transfer.transferwert || "") +
            " " +
            (transfer.transferwertEinheit || "") +
            " " +
            (transfer.transferwertWaehrung || "")}
        </Text>
      </Box>
    </HStack>
  );
};

export default TransferBar;
