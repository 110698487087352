import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Collapse,
  Divider,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { MdOutlineCompareArrows } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { colorsPrimary, colorsSecondary, textColors } from "../../colorStyles";
import { useComparisonStore } from "../../store/store";
import ComparisonNavbarIcon from "./ComparisonNavbarIcon";
import PlayerViewSmall from "./PlayerViewSmall";
import { LanguageContext } from "../../store/languageContext";
import { LanguageKeys } from "../../language/LanguageKeys";
import { text } from "d3";

const ComparisonNavbarMenu = () => {
  const { guiText } = useContext(LanguageContext);

  const [show, setShow] = useState(false);
  const { players, clearComparison } = useComparisonStore();
  const navigate = useNavigate();
  const [closeOnBlur, setCloseOnBlur] = useState(true);

  return (
    <Menu offset={[0, 12]} autoSelect={false} closeOnBlur={closeOnBlur}>
      <MenuButton
        onClick={() => setShow(!show)}
        as={IconButton}
        aria-label={guiText[LanguageKeys.SpielerimVergleich]}
        icon={<ComparisonNavbarIcon comparisonCount={players.length} />}
        variant="outline"
        borderColor={colorsSecondary.cardBackground}
        size="lg"
        data-cy={"comparisonButton"}
      />
      <MenuList zIndex={1700} data-cy={"comparisonDropdown"} backgroundColor={colorsSecondary.cardBackground}>
        {players.length > 0 ? (
          <>
            <MenuGroup>
              {players.map((player, index) => (
                <MenuItem
                  key={index}
                  onClick={() =>
                    navigate(
                      `/player/${player.id}?position=${player.positionsGruppe}&idTm=${player.idTm}`
                    )
                  }
                  backgroundColor={colorsSecondary.cardBackground}
                  data-cy={`player-${player.id}`}
                >
                  <PlayerViewSmall player={player} useCase={"comparisonCart"} />
                </MenuItem>
              ))},
            </MenuGroup>
            <MenuDivider />
            <MenuGroup>
              <HStack display="flex" pr={2} pl={2}>
                <Button
                  color={colorsSecondary.accent}
                  outline={"1px solid " + colorsSecondary.accent}
                  backgroundColor="white"
                  fontWeight="thin"
                  fontSize="sm"
                  // onClick={() => clearFilters()}
                  alignItems="center"
                  boxShadow="sm"
                  onClick={() => clearComparison()}
                  padding={2}
                  height="auto"
                  data-cy={"clearButton"}
                >
                  Liste leeren
                </Button>
                <Button
                  backgroundColor={colorsSecondary.accent}
                  textColor="white"
                  fontWeight="thin"
                  fontSize="sm"
                  textAlign="center"
                  boxShadow="sm"
                  flex={1}
                  height="auto"
                  padding={2}
                  onClick={() => navigate("/compare")}
                  data-cy={"compareButton"}
                >
                  {guiText[LanguageKeys.JetztVergleichen]}
                </Button>
              </HStack>
            </MenuGroup>
          </>
        ) : (
          <MenuItem isDisabled backgroundColor={colorsSecondary.cardBackground} color={textColors.primary}>{guiText[LanguageKeys.KeineSpielerimVergleich]}</MenuItem>
        )}
      </MenuList>
    </Menu>
  );
};

export default ComparisonNavbarMenu;
