import debounce from "lodash.debounce";
import { create, StoreApi, UseBoundStore } from "zustand";
import {
  FilterPreset,
  FilterSpecification,
  FilterValue,
  Player,
  PlayerQuery,
  ShadowteamOverview,
} from "../generated";

///////////////////////////////////////////////////////////////////////
interface CollapsedStore {
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
}

export const useSidebarCollapsedStore = create<CollapsedStore>((set) => ({
  collapsed: true,
  setCollapsed: (collapsed: boolean) => set(() => ({ collapsed: collapsed })),
}));
///////////////////////////////////////////////////////////////////////

interface SearchViewStore {
  view: "table" | "card";
  setView: (view: "table" | "card") => void;
}

export const useSearchViewStore = create<SearchViewStore>((set) => ({
  view: "card",
  setView: (view: "table" | "card") => {
    usePlayerQueryStore.getState().resetPlayerQuery();
    useSortingStore.getState().resetSorting();
    set(() => ({ view }));
  },
}));
////////////////////////////////////////////////////////////////////////

interface FilterSpecificationStore {
  filterSpecifications: FilterSpecification[];
  setFilterSpecifications: (
    filterSpecifications: FilterSpecification[]
  ) => void;
  getFilterSpecification: (id: number) => FilterSpecification | null;
}

const createFilterSpecificationStore = () =>
  create<FilterSpecificationStore>((set, get) => ({
    filterSpecifications: [],

    setFilterSpecifications: (filterSpecifications: FilterSpecification[]) => {
      set({ filterSpecifications: filterSpecifications });
    },

    getFilterSpecification: (id: number) => {
      const specifications = get().filterSpecifications;

      const existingSpecificationIndex = specifications.findIndex(
        (f) => f.specId === id
      );

      if (existingSpecificationIndex > -1) {
        return specifications[existingSpecificationIndex];
      } else {
        return null;
      }
    },
  }));

export const useFilterSpecificationStore = createFilterSpecificationStore();
export const useSimilarPlayersFilterSpecificationStore =
  createFilterSpecificationStore();
///////////////////////////////////////////////////////////////////////

export interface FilterValueStore {
  filterValues: (FilterValue | null)[];
  addFilter: (filter: FilterValue) => void;
  removeFilter: (id: number, badge?: string) => void;
  clearFilters: () => void;
  setFilters: (filters: FilterValue[]) => void;
  activeFilterGroups: Map<string, number[]>;
  getActiveFilterGroupCount: (filterGroup: string) => number;
  removeActiveFilterGroup: (filterGroup: string, filterId: number) => void;
}

export type FilterValueStoreCore = Pick<
  FilterValueStore,
  "addFilter" | "removeFilter" | "filterValues"
>;

const createFilterValueStore = (
  filterSpecificationStore: UseBoundStore<StoreApi<FilterSpecificationStore>>
) =>
  create<FilterValueStore>((set, get) => ({
    filterValues: new Array(150).fill(null),
    addFilter: (filter) => {
      const filters = [...get().filterValues];
      filters[filter.specId - 1] = filter;

      const filterSpec = filterSpecificationStore
        .getState()
        .getFilterSpecification(filter.specId);

      const activeGroups = get().activeFilterGroups;

      if (activeGroups.has(filterSpec!.subgroupGerman)) {
        if (!activeGroups.get(filterSpec!.subgroupGerman)?.includes(filter.specId)) {
          activeGroups.get(filterSpec!.subgroupGerman)?.push(filter.specId);
        }
      } else {
        activeGroups.set(filterSpec!.subgroupGerman, [filter.specId]);
      }

      set(() => ({
        filterValues: filters,
        activeFilterGroups: activeGroups,
      }));
    },
    removeFilter: (id: number, value?: string) => {
      const filters = [...get().filterValues];

      const filterSpec = filterSpecificationStore
        .getState()
        .getFilterSpecification(id);

      if (value && filterSpec && filters) {
        const filterToUpdate = filters[id - 1]!;
        let updatedFilter: FilterValue;

        switch (filterSpec.type) {
          case "textMenu":
          case "menu":
            updatedFilter = {
              ...filterToUpdate,
              value: `{"values":${JSON.stringify(
                JSON.parse(filterToUpdate.value).values.filter(
                  (val: any) => val.label !== value
                )
              )}}`,
            };
            break;
          default:
            filters[id - 1] = null;
            set({ filterValues: filters });
            get().removeActiveFilterGroup(filterSpec.subgroupGerman, id);

            return;
        }

        filters[id - 1] = updatedFilter;

        if (JSON.parse(updatedFilter.value).values.length === 0) {
          filters[id - 1] = null;
          set({ filterValues: filters });
          get().removeActiveFilterGroup(filterSpec.subgroupGerman, id);
          return;
        }

        set(() => ({ filterValues: filters }));
        return;
      }

      filters[id - 1] = null;
      set({ filterValues: filters });
      get().removeActiveFilterGroup(filterSpec!.subgroupGerman, id);
    },
    clearFilters: () => {
      set({
        filterValues: new Array(
          filterSpecificationStore.getState().filterSpecifications.length
        ).fill(null),
        activeFilterGroups: new Map<string, number[]>(),
      });
    },
    setFilters: (filters: FilterValue[]) => {
      get().clearFilters();

      const newFilterValues = [...get().filterValues]; // Copy the current filterValues

      filters.forEach((filter) => {
        newFilterValues[filter.specId - 1] = filter;
      });

      set({ filterValues: newFilterValues });
    },
    activeFilterGroups: new Map<string, number[]>(),
    getActiveFilterGroupCount: (filterGroup: string) => {
      return get().activeFilterGroups.get(filterGroup)?.length ?? 0;
    },
    removeActiveFilterGroup: (filterGroup: string, filterId: number) => {
      const activeGroups = get().activeFilterGroups;
      if (activeGroups.has(filterGroup)) {
        let currentActiveFilters = activeGroups.get(filterGroup);
        currentActiveFilters = currentActiveFilters?.filter(
          (id) => id !== filterId
        );
        if (currentActiveFilters?.length === 0) {
          activeGroups.delete(filterGroup);
        } else {
          activeGroups.set(filterGroup, currentActiveFilters!);
        }
        set({ activeFilterGroups: activeGroups });
      }
    },
  }));

export const useFilterValueStore = createFilterValueStore(
  useFilterSpecificationStore
);
export const useSimilarPlayersFilterValueStore = createFilterValueStore(
  useSimilarPlayersFilterSpecificationStore
);

///////////////////////////////////////////////////////////////////////

interface FilterPresetStore {
  activePreset: FilterPreset | null;
  setActivePreset: (filterPreset: FilterPreset) => void;
  getActivePreset: () => FilterPreset | null;
  clearActivePreset: () => void;
}

export const useFilterPresetStore = create<FilterPresetStore>((set, get) => ({
  activePreset: null,
  setActivePreset: (filterPreset: FilterPreset) => {
    set({ activePreset: filterPreset });
    useFilterValueStore.getState().setFilters(filterPreset.filterValues);
  },
  getActivePreset: () => {
    return get().activePreset;
  },
  clearActivePreset: () => {
    set({ activePreset: null });
  },
}));

///////////////////////////////////////////////////////////////////////

export interface ComparisonStore {
  players: Player[];
  addPlayer: (player: Player) => void;
  deletePlayer: (id: number, position: string) => void;
  clearComparison: () => void;
  isInComparison: (id: number, position: string) => boolean;
  setPlayers: (players: Player[]) => void;
  player1: Player | undefined;
  setPlayer1: (player: Player) => void;
  clearPlayer1: () => void;
  player2: Player | undefined;
  setPlayer2: (player: Player) => void;
  clearPlayer2: () => void;
  player3: Player | undefined;
  setPlayer3: (player: Player) => void;
  clearPlayer3: () => void;
  date1: Date;
  setDate1: (date: Date) => void;
  clearDate1: () => void;
  date2: Date;
  setDate2: (date: Date) => void;
  clearDate2: () => void;
  date3: Date;
  setDate3: (date: Date) => void;
  clearDate3: () => void;
  getComparisonPlayers: () => (Player | undefined)[];
  getComparisonDates: () => Date[];
  addToComp1: boolean;
  addToComp2: boolean;
  addToComp3: boolean;
  setAddToComp1: (value: boolean) => void;
  setAddToComp2: (value: boolean) => void;
  setAddToComp3: (value: boolean) => void;
  clearAddToComp: () => void;
}

export const useComparisonStore = create<ComparisonStore>((set, get) => ({
  players: [],
  player1: undefined,
  player2: undefined,
  player3: undefined,
  date1: new Date(),
  date2: new Date(),
  date3: new Date(),
  addToComp1: false,
  addToComp2: false,
  addToComp3: false,
  addPlayer: (newPlayer: Player) => {
    set((state) => {
      const isPlayerExist = state.players.some(
        (player) =>
          player.id === newPlayer.id &&
          player.positionsGruppe === newPlayer.positionsGruppe
      );

      if (!isPlayerExist) {
        return { players: [...state.players, newPlayer] };
      } else {
        console.warn("Player is already added.");
        return {};
      }
    });
  },

  deletePlayer: (id: number, position: string) => {
    set((state) => ({
      players: state.players.filter(
        (player) => player.id !== id || player.positionsGruppe !== position
      ),
    }));

    if (
      get().player1?.id === id &&
      get().player1?.positionsGruppe === position
    ) {
      get().clearPlayer1();
    }
    if (
      get().player2?.id === id &&
      get().player2?.positionsGruppe === position
    ) {
      get().clearPlayer2();
    }
    if (
      get().player3?.id === id &&
      get().player3?.positionsGruppe === position
    ) {
      get().clearPlayer3();
    }
  },

  clearComparison: () => {
    set({ players: [] });
  },

  isInComparison: (id: number, position: string) => {
    return get().players.some(
      (player) => player.id === id && player.positionsGruppe === position
    );
  },
  getComparisonPlayers: () => {
    return [get().player1, get().player2, get().player3];
  },
  getComparisonDates: () => {
    return [get().date1, get().date2, get().date3];
  },
  setPlayers: (players: Player[]) => {
    set({ players: players });
  },
  setPlayer1: (player: Player) => {
    set({ player1: player });
  },

  setPlayer2: (player: Player) => {
    set({ player2: player });
  },

  setPlayer3: (player: Player) => {
    set({ player3: player });
  },
  clearPlayer1: () => {
    set({ player1: undefined, date1: new Date() });
  },
  clearPlayer2: () => {
    set({ player2: undefined, date2: new Date() });
  },
  clearPlayer3: () => {
    set({ player3: undefined, date3: new Date() });
  },
  setDate1: (date: Date) => {
    set({ date1: date });
  },
  setDate2: (date: Date) => {
    set({ date2: date });
  },
  setDate3: (date: Date) => {
    set({ date3: date });
  },
  clearDate1: () => {
    set({ date1: new Date() });
  },
  clearDate2: () => {
    set({ date1: new Date() });
  },
  clearDate3: () => {
    set({ date1: new Date() });
  },
  setAddToComp1: (value: boolean) => {
    set({ addToComp1: value });
  },
  setAddToComp2: (value: boolean) => {
    set({ addToComp2: value });
  },
  setAddToComp3: (value: boolean) => {
    set({ addToComp3: value });
  },
  clearAddToComp: () => {
    set({ addToComp1: false, addToComp2: false, addToComp3: false });
  },
}));
///////////////////////////////////////////////////////////////////////

interface SortingStore {
  sortBy: string;
  ascending: boolean;
  setSortBy: (sortBy: string) => void;
  setAscending: (ascending: boolean) => void;
  resetSorting: () => void;
}

export const useSortingStore = create<SortingStore>((set, get) => ({
  sortBy: "alleyes",
  ascending: false,
  setSortBy: (sortBy: string) => set(() => ({ sortBy })),
  setAscending: (ascending: boolean) => set(() => ({ ascending })),
  resetSorting: () => set(() => ({ sortBy: "alleyes", ascending: false })),
}));

///////////////////////////////////////////////////////////////////////
type PlayerRatingFilter = "all" | "latest" | "notLatest";

interface PlayerBaseSelectionStore {
  playerBase: PlayerRatingFilter;
  setPlayerBase: (playerBase: PlayerRatingFilter) => void;
}

export const usePlayerBaseSelectionStore = create<PlayerBaseSelectionStore>(
  (set) => ({
    playerBase: "latest",
    setPlayerBase: (playerBase: PlayerRatingFilter) =>
      set({ playerBase: playerBase }),
  })
);

////////////////////////////////////////////////////////////////////

interface PlayerQueryStore {
  playerQuery: PlayerQuery;
  setPlayerQuery: (query: PlayerQuery) => void;
  resetPlayerQuery: () => void;
}

export const usePlayerQueryStore = create<PlayerQueryStore>((set) => ({
  playerQuery: {
    paginationInfo: { pageSize: 20, page: 1 },
    sortingInfo: { column: "alleyes", ascending: false },
    playerBase: "latest",
    filters: [],
  },
  setPlayerQuery: (query) => set(() => ({ playerQuery: query })),
  resetPlayerQuery: () =>
    set((state) => ({
      playerQuery: {
        ...state.playerQuery,
        paginationInfo: { pageSize: 20, page: 1 },
        sortingInfo: { column: "alleyes", ascending: false },
      },
    })),
}));

export const useSimilarPlayersQueryStore = create<PlayerQueryStore>((set) => ({
  playerQuery: {
    playerBase: "latest",
    filters: [
      { specId: 149, value: '{"values":["Keine"]}' },
    ],
  },
  setPlayerQuery: (query) => set(() => ({ playerQuery: query })),
  resetPlayerQuery: () =>
    set((state) => ({
      playerQuery: {
        ...state.playerQuery,
      },
    })),
}));

// Subscribe to FilterValueStore changes
useFilterValueStore.subscribe((filterState) => {
  const debouncedSetPlayerQuery = debounce(
    usePlayerQueryStore.getState().setPlayerQuery,
    500
  );
  const currentQuery = usePlayerQueryStore.getState().playerQuery;
  const updatedQuery = {
    ...currentQuery,
    filters: filterState.filterValues.filter(
      (filter) => filter != null
    ) as FilterValue[],
  };
  debouncedSetPlayerQuery(updatedQuery);
});

useSimilarPlayersFilterValueStore.subscribe((filterState) => {
  const debouncedSetPlayerQuery = debounce(
    useSimilarPlayersQueryStore.getState().setPlayerQuery,
    500
  );
  const currentQuery = useSimilarPlayersQueryStore.getState().playerQuery;
  const updatedQuery = {
    ...currentQuery,
    filters: filterState.filterValues.filter(
      (filter) => filter != null
    ) as FilterValue[],
  };
  debouncedSetPlayerQuery(updatedQuery);
});

// Subscribe to SortingStore changes
useSortingStore.subscribe((sortState) => {
  const currentQuery = usePlayerQueryStore.getState().playerQuery;
  const updatedQuery = {
    ...currentQuery,
    sortingInfo: { column: sortState.sortBy, ascending: sortState.ascending },
  };
  usePlayerQueryStore.getState().setPlayerQuery(updatedQuery);
});

// Subscribe to SortingStore changes
usePlayerBaseSelectionStore.subscribe((state) => {
  const currentQuery = usePlayerQueryStore.getState().playerQuery;
  const updatedQuery = {
    ...currentQuery,
    playerBase: state.playerBase,
  };
  usePlayerQueryStore.getState().setPlayerQuery(updatedQuery);
});

usePlayerBaseSelectionStore.subscribe((state) => {
  const currentQuery = useSimilarPlayersQueryStore.getState().playerQuery;
  const updatedQuery = {
    ...currentQuery,
    playerBase: state.playerBase,
  };
  useSimilarPlayersQueryStore.getState().setPlayerQuery(updatedQuery);
});

////////////////////////////////////////////////////////////////

interface OverallPotentialStore {
  overall: boolean;
  setOverall: (overall: boolean) => void;
  overallShadowteams: boolean;
  setOverallShadowteams: (overallShadowteams: boolean) => void;
}

export const useOverallPotentialStore = create<OverallPotentialStore>(
  (set) => ({
    overall: true,
    setOverall: (overall: boolean) => set((store) => ({ overall: overall })),
    overallShadowteams: true,
    setOverallShadowteams: (overallShadowteams: boolean) =>
      set((store) => ({ overallShadowteams: overallShadowteams })),
  })
);

///////////////////////////////////////////////////////////////

interface LastMainPageStore {
  lastMainPage: string;
  setLastMainPage: (page: string) => void;
}

export const useLastMainPageStore = create<LastMainPageStore>((set) => ({
  lastMainPage: "Home",
  setLastMainPage: (page) => set({ lastMainPage: page }),
}));

////////////////////////////////////////////////////////////

interface PlayerProfileStore {
  playerName: string;
  setPlayerName: (name: string) => void;
  date: Date;
  setDate: (date: Date) => void;
}

export const usePlayerProfileStore = create<PlayerProfileStore>((set) => ({
  playerName: "",
  setPlayerName: (currentName) => set({ playerName: currentName }),
  date: new Date(),
  setDate: (date) => {
    set({ date: date });
  },
}));

////////////////////////////////////////////////////////////
interface PlayerProfileState {
  id: string;
  position: string;
  date: Date;
  tab: number;
}

interface PlayerProfileHistoryStore {
  players: Map<string, PlayerProfileState>;
  setPlayers: (players: Map<string, PlayerProfileState>) => void;
  pushPlayer: (players: Map<string, PlayerProfileState>, profileState: PlayerProfileState) => void;
}

export const usePlayerProfileHistoryStore = create<PlayerProfileHistoryStore>((set) => ({
  players: new Map<string, PlayerProfileState>(),
  setPlayers: (players: Map<string, PlayerProfileState>) => {
    set({ players: players });
  },
  pushPlayer: (players: Map<string, PlayerProfileState>, profileState: PlayerProfileState) => {
    set({ players: players.set(profileState.id, profileState) });
  }
}));

////////////////////////////////////////////////////////////
interface PlayerModalStore {
  player: Player | undefined;
  setPlayer: (player: Player) => void;
  clearPlayer: () => void;
}

export const usePlayerModalStore = create<PlayerModalStore>((set) => ({
  player: undefined,
  setPlayer: (player) => set({ player: player }),
  clearPlayer: () => set({ player: undefined }),
}));

////////////////////////////////////////////////////////////

interface SearchResultSize {
  size: number;
  setSize: (size: number) => void;
}

export const useSearchResultSize = create<SearchResultSize>((set) => ({
  size: 0,
  setSize: (size) => set({ size: size }),
}));

////////////////////////////////////////////////////////////

interface ShadowteamOverviewStore {
  shadowteam: ShadowteamOverview | undefined;
  setShadowteam: (shadowteam: ShadowteamOverview) => void;
  clearShadowteam: () => void;
  clickedOnShadowteamName: string | undefined;
  setclickedOnShadowteamName: (
    clickedOnShadowteamName: string | undefined
  ) => void;
}

export const useShadowteamOverviewStore = create<ShadowteamOverviewStore>(
  (set) => ({
    shadowteam: undefined,
    clickedOnShadowteamName: undefined,
    setclickedOnShadowteamName: (clickedOnShadowteamName: string | undefined) =>
      set({ clickedOnShadowteamName: clickedOnShadowteamName }),
    setShadowteam: (shadowteam) => set({ shadowteam: shadowteam }),
    clearShadowteam: () => set({ shadowteam: undefined }),
  })
);

////////////////////////////////////////////////////////////

export interface RatingHistoryModalStore {
  chart: string | undefined;
  rating: string | string[] | undefined;
  id: number | undefined;
  position: string | undefined;
  date: Date | undefined;
  setRatingHistoryModalData: (data: RatingHistoryModalStore) => void;
  clearRatingHistoryModalData: () => void;
}

export const useRatingHistoryModalStore = create<RatingHistoryModalStore>(
  (set) => ({
    chart: undefined,
    rating: undefined,
    id: undefined,
    position: undefined,
    date: undefined,

    setRatingHistoryModalData: (data) =>
      set((state) => ({ ...state, ...data })),
    clearRatingHistoryModalData: () =>
      set(() => ({
        chart: undefined,
        rating: undefined,
        id: undefined,
        position: undefined,
        date: undefined,
      })),
  })
);

interface ScrollPositionStore {
  scrollPosition: number;
  setScrollPosition: (scrollPosition: number) => void;
}

export const useScrollPositionStore = create<ScrollPositionStore>((set) => ({
  scrollPosition: 0,
  setScrollPosition: (scrollPosition: number) =>
    set(() => ({ scrollPosition: scrollPosition })),
}));
