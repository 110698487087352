import { Box } from "@chakra-ui/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import { Autoplay, Navigation } from "swiper/modules";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import PriorityPlayers from "./PriortiyPlayers";
import "./sliderStyles.css";
import TopTrendLongPlayers from "./TopTrendLongPlayers";
import TopTrendShortPlayers from "./TopTrendShortPlayers";
import { colorsSecondary } from "../../colorStyles";
const SliderPanel = () => {
  return (
    <Box flex={1} display="flex" overflow="hidden" maxH="100%">
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 8000,
          disableOnInteraction: true,
        }}
        loop={true}
        navigation={true}
        modules={[Autoplay, Navigation]}
        className="swiper"
        style={{ height: "auto", background: colorsSecondary.cardBackground, borderRadius: "5px" }}
      >
        <SwiperSlide className="swiper-slide">
          <PriorityPlayers />
        </SwiperSlide>
        <SwiperSlide className="swiper-slide">
          <TopTrendShortPlayers />
        </SwiperSlide>
        <SwiperSlide className="swiper-slide">
          <TopTrendLongPlayers />
        </SwiperSlide>
      </Swiper>
    </Box>
  );
};

export default SliderPanel;
