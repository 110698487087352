import { useContext } from "react";
import { LanguageContext } from "../../../store/languageContext";
import { LanguageKeys } from "../../../language/LanguageKeys";

const Formations= () => {
  const {guiText} = useContext(LanguageContext)
  const formations = new Map<string, string[]>();
  formations.set(guiText[LanguageKeys.Viererkette], [
    "4-2-3-1",
    "4-2-3-1 Double-6",
    "4-3-3",
    "4-3-3 Double-6",
    "4-1-3-2",
    "4-4-2",
    "4-4-2 Double-6",
    "4-4-2 Diamond",
    "4-2-2-2",
  ]);
  
  formations.set(guiText[LanguageKeys.Dreierkette], [
    "3-5-2",
    "3-5-2 Double-6",
    "3-4-3",
    "3-6-1",
    "5-4-1",
  ]);
  return formations
}


////////////////////////////////////////////////////////////////////////

interface Position {
  top: string;
  left: string;
  name: string;
}

export const allPositions = [
  "CFW",
  "LWIN",
  "RWIN",
  "AM",
  "CM",
  "DM",
  "LFB",
  "LCB",
  "RCB",
  "RFB",
  "GK",
  "BENCH",
  "LDM",
  "RDM",
  "LCM",
  "RCM",
  "LFW",
  "RFW",
  "LM",
  "RM",
  "LWB",
  "RWB",
  "CB",
  "RAM",
  "LAM",
];

////////////////////////////////////////////////////////////////////////

export const formationMapping = new Map<string, Position[]>();

formationMapping.set("4-2-3-1", [
  { top: "2%", left: "40%", name: "CFW" },
  { top: "22%", left: "40%", name: "AM" },
  { top: "19%", left: "2%", name: "LWIN" },
  { top: "19%", left: "78%", name: "RWIN" },
  { top: "43%", left: "52%", name: "CM" },
  { top: "47%", left: "28%", name: "DM" },
  { top: "60%", left: "2%", name: "LFB" },
  { top: "60%", left: "78%", name: "RFB" },
  { top: "67%", left: "25%", name: "LCB" },
  { top: "67%", left: "55%", name: "RCB" },
]);

formationMapping.set("4-2-3-1 Double-6", [
  { top: "2%", left: "40%", name: "CFW" },
  { top: "22%", left: "40%", name: "AM" },
  { top: "19%", left: "2%", name: "LWIN" },
  { top: "19%", left: "78%", name: "RWIN" },
  { top: "45%", left: "52%", name: "RDM" },
  { top: "45%", left: "28%", name: "LDM" },
  { top: "60%", left: "2%", name: "LFB" },
  { top: "60%", left: "78%", name: "RFB" },
  { top: "67%", left: "25%", name: "LCB" },
  { top: "67%", left: "55%", name: "RCB" },
]);

formationMapping.set("4-3-3", [
  { top: "2%", left: "40%", name: "CFW" },
  { top: "19%", left: "2%", name: "LWIN" },
  { top: "19%", left: "78%", name: "RWIN" },
  { top: "25%", left: "52%", name: "RCM" },
  { top: "25%", left: "28%", name: "LCM" },
  { top: "46%", left: "40%", name: "DM" },
  { top: "60%", left: "2%", name: "LFB" },
  { top: "60%", left: "78%", name: "RFB" },
  { top: "67%", left: "25%", name: "LCB" },
  { top: "67%", left: "55%", name: "RCB" },
]);

formationMapping.set("4-3-3 Double-6", [
  { top: "2%", left: "40%", name: "CFW" },
  { top: "19%", left: "2%", name: "LWIN" },
  { top: "19%", left: "78%", name: "RWIN" },
  { top: "46%", left: "52%", name: "RDM" },
  { top: "46%", left: "28%", name: "LDM" },
  { top: "25%", left: "40%", name: "CM" },
  { top: "60%", left: "2%", name: "LFB" },
  { top: "60%", left: "78%", name: "RFB" },
  { top: "67%", left: "25%", name: "LCB" },
  { top: "67%", left: "55%", name: "RCB" },
]);

formationMapping.set("4-1-3-2", [
  { top: "2%", left: "25%", name: "LFW" },
  { top: "2%", left: "55%", name: "RFW" },
  { top: "30%", left: "2%", name: "RM" },
  { top: "30%", left: "78%", name: "LM" },
  { top: "26%", left: "40%", name: "CM" },
  { top: "48%", left: "40%", name: "DM" },
  { top: "60%", left: "2%", name: "LFB" },
  { top: "60%", left: "78%", name: "RFB" },
  { top: "67%", left: "25%", name: "LCB" },
  { top: "67%", left: "55%", name: "RCB" },
]);

formationMapping.set("4-4-2", [
  { top: "2%", left: "25%", name: "LFW" },
  { top: "2%", left: "55%", name: "RFW" },
  { top: "30%", left: "2%", name: "LM" },
  { top: "30%", left: "78%", name: "RM" },
  { top: "38%", left: "55%", name: "RCM" },
  { top: "38%", left: "25%", name: "LCM" },
  { top: "60%", left: "2%", name: "LFB" },
  { top: "60%", left: "78%", name: "RFB" },
  { top: "67%", left: "25%", name: "LCB" },
  { top: "67%", left: "55%", name: "RCB" },
]);

formationMapping.set("4-4-2 Double-6", [
  { top: "2%", left: "25%", name: "LFW" },
  { top: "2%", left: "55%", name: "RFW" },
  { top: "30%", left: "2%", name: "RM" },
  { top: "30%", left: "78%", name: "LM" },
  { top: "38%", left: "55%", name: "RDM" },
  { top: "38%", left: "25%", name: "LDM" },
  { top: "60%", left: "2%", name: "LFB" },
  { top: "60%", left: "78%", name: "RFB" },
  { top: "67%", left: "25%", name: "LCB" },
  { top: "67%", left: "55%", name: "RCB" },
]);

formationMapping.set("4-4-2 Diamond", [
  { top: "2%", left: "25%", name: "LFW" },
  { top: "2%", left: "55%", name: "RFW" },
  { top: "30%", left: "71%", name: "RM" },
  { top: "30%", left: "9%", name: "LM" },
  { top: "25%", left: "40%", name: "AM" },
  { top: "48%", left: "40%", name: "DM" },
  { top: "60%", left: "2%", name: "LFB" },
  { top: "60%", left: "78%", name: "RFB" },
  { top: "67%", left: "25%", name: "LCB" },
  { top: "67%", left: "55%", name: "RCB" },
]);

formationMapping.set("4-2-2-2", [
  { top: "2%", left: "25%", name: "LFW" },
  { top: "2%", left: "55%", name: "RFW" },
  { top: "24%", left: "20%", name: "RCM" },
  { top: "24%", left: "60%", name: "LCM" },
  { top: "47%", left: "25%", name: "LDM" },
  { top: "47%", left: "55%", name: "RDM" },
  { top: "60%", left: "2%", name: "LFB" },
  { top: "60%", left: "78%", name: "RFB" },
  { top: "67%", left: "25%", name: "LCB" },
  { top: "67%", left: "55%", name: "RCB" },
]);

formationMapping.set("3-5-2", [
  { top: "3%", left: "25%", name: "LFW" },
  { top: "3%", left: "55%", name: "RFW" },
  { top: "25%", left: "25%", name: "LCM" },
  { top: "25%", left: "55%", name: "RCM" },
  { top: "30%", left: "2%", name: "LWB" },
  { top: "30%", left: "78%", name: "RWB" },
  { top: "48%", left: "40%", name: "DM" },
  { top: "67%", left: "10%", name: "LCB" },
  { top: "67%", left: "40%", name: "CB" },
  { top: "67%", left: "70%", name: "RCB" },
]);

formationMapping.set("3-5-2 Double-6", [
  { top: "3%", left: "25%", name: "LFW" },
  { top: "3%", left: "55%", name: "RFW" },
  { top: "48%", left: "25%", name: "LDM" },
  { top: "48%", left: "55%", name: "RDM" },
  { top: "30%", left: "2%", name: "LWB" },
  { top: "30%", left: "78%", name: "RWB" },
  { top: "25%", left: "40%", name: "CM" },
  { top: "67%", left: "10%", name: "LCB" },
  { top: "67%", left: "40%", name: "CB" },
  { top: "67%", left: "70%", name: "RCB" },
]);

formationMapping.set("3-4-3", [
  { top: "25%", left: "25%", name: "LAM" },
  { top: "25%", left: "55%", name: "RAM" },
  { top: "48%", left: "25%", name: "LDM" },
  { top: "48%", left: "55%", name: "RDM" },
  { top: "40%", left: "2%", name: "LWB" },
  { top: "40%", left: "78%", name: "RWB" },
  { top: "3%", left: "40%", name: "CFW" },
  { top: "67%", left: "10%", name: "LCB" },
  { top: "67%", left: "40%", name: "CB" },
  { top: "67%", left: "70%", name: "RCB" },
]);

formationMapping.set("3-6-1", [
  { top: "20%", left: "40%", name: "AM" },
  { top: "25%", left: "17.5%", name: "LCM" },
  { top: "48%", left: "40%", name: "DM" },
  { top: "25%", left: "62.5%", name: "RCM" },
  { top: "45%", left: "2%", name: "LWB" },
  { top: "45%", left: "78%", name: "RWB" },
  { top: "1%", left: "40%", name: "CFW" },
  { top: "67%", left: "10%", name: "LCB" },
  { top: "67%", left: "40%", name: "CB" },
  { top: "67%", left: "70%", name: "RCB" },
]);

formationMapping.set("5-4-1", [
  { top: "25%", left: "7%", name: "LM" },
  { top: "39%", left: "51%", name: "DM" },
  { top: "36%", left: "29%", name: "CM" },
  { top: "25%", left: "73%", name: "RM" },
  { top: "47%", left: "2%", name: "LWB" },
  { top: "47%", left: "78%", name: "RWB" },
  { top: "1%", left: "40%", name: "CFW" },
  { top: "67%", left: "18%", name: "LCB" },
  { top: "67%", left: "40%", name: "CB" },
  { top: "67%", left: "62%", name: "RCB" },
]);

//add goalkeeper
const goalkeeper: Position = { top: "86%", left: "40%", name: "GK" };

formationMapping.forEach((positions, formation) => {
  formationMapping.set(formation, [...positions, goalkeeper]);
});

////////////////////////////////////////////////////////////////////////

export const shadowteamPositionMapping = new Map<string, string>();
shadowteamPositionMapping.set("CFW", "Forward");
shadowteamPositionMapping.set("LWIN", "Winger");
shadowteamPositionMapping.set("RWIN", "Winger");
shadowteamPositionMapping.set("AM", "Central Midfield");
shadowteamPositionMapping.set("CM", "Central Midfield");
shadowteamPositionMapping.set("DM", "Defensive Midfield");
shadowteamPositionMapping.set("LFB", "Full Back");
shadowteamPositionMapping.set("LCB", "Central Defender");
shadowteamPositionMapping.set("RCB", "Central Defender");
shadowteamPositionMapping.set("RFB", "Full Back");
shadowteamPositionMapping.set("GK", "Goalkeeper");
shadowteamPositionMapping.set("BENCH", "");
shadowteamPositionMapping.set("LDM", "Defensive Midfield");
shadowteamPositionMapping.set("RDM", "Defensive Midfield");
shadowteamPositionMapping.set("LCM", "Central Midfield");
shadowteamPositionMapping.set("RCM", "Central Midfield");
shadowteamPositionMapping.set("LFW", "Forward");
shadowteamPositionMapping.set("RFW", "Forward");
shadowteamPositionMapping.set("LM", "Winger");
shadowteamPositionMapping.set("RM", "Winger");
shadowteamPositionMapping.set("LWB", "Full Back");
shadowteamPositionMapping.set("RWB", "Full Back");
shadowteamPositionMapping.set("CB", "Central Defender");
shadowteamPositionMapping.set("RAM", "Central Midfield");
shadowteamPositionMapping.set("LAM", "Central Midfield");


export default Formations
