import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button, HStack,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup, SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { usePlayerBaseSelectionStore } from "../../../store/store";
import { colorsPrimary, colorsSecondary, textColors } from "../../../colorStyles";
import { useContext } from "react";
import { LanguageContext } from "../../../store/languageContext";
import { LanguageKeys } from "../../../language/LanguageKeys";

interface Props {
  outline?: boolean;
  mode?: "dropdown" | "buttonGroup";
  [x: string]: any;
}

const PlayerBaseMenu = ({ outline = false, mode = "dropdown", ...rest }: Props) => {
  const { guiText } = useContext(LanguageContext);
  const { playerBase, setPlayerBase } = usePlayerBaseSelectionStore();

  const menuOptionsShort = {
    all: guiText[LanguageKeys.Alle],
    latest: guiText[LanguageKeys.AktuellesRatingShort],
    notLatest: guiText[LanguageKeys.KeinAktuellesRatingShort],
  };

  const menuOptions = {
    all: guiText[LanguageKeys.Alle],
    latest: guiText[LanguageKeys.AktuellesRating],
    notLatest: guiText[LanguageKeys.KeinAktuellesRating],
  };

  return (
    mode === "dropdown" ?
      <Menu closeOnSelect={false} autoSelect={false} {...rest}>
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon color={textColors.primary} />}
          backgroundColor={colorsSecondary.cardBackground}
          boxShadow="sm"
          h="35px"
          outline={outline ? "2px solid #E2E8F0" : ""}
        >
          <Text align="left" fontWeight="medium" color={textColors.primary}>
            <strong>{guiText[LanguageKeys.SpielerprofilSpieler]}:</strong>{" "}
            {menuOptions[playerBase as keyof typeof menuOptions]}
          </Text>
        </MenuButton>
        <MenuList zIndex={9999} backgroundColor={colorsSecondary.cardBackground}>
          <MenuOptionGroup type="radio" value={playerBase} backgroundColor={colorsSecondary.cardBackground}>
            {Object.entries(menuOptions).map(([key, value]) => (
              <MenuItemOption
                key={key}
                value={key}
                onClick={() => setPlayerBase(key as keyof typeof menuOptions)}
                backgroundColor={colorsSecondary.cardBackground}
                color={textColors.primary}
              >
                {value}
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        </MenuList>
      </Menu >
      : <Box pb={3} {...rest}>
        <HStack marginBottom={2} justifyContent="space-between">
          <Text fontSize="md" color={textColors.lightgrey}>{guiText[LanguageKeys.SpielerprofilSpieler]}</Text>
        </HStack>
        <SimpleGrid columns={1} spacing={2}>
          {Object.entries(menuOptionsShort).map(([key, value]) => (
            <Button
              key={key}
              onClick={() => setPlayerBase(key as keyof typeof menuOptionsShort)}
              backgroundColor={playerBase === key ? colorsSecondary.accent : ""}
              color={playerBase === key ? colorsSecondary.background : textColors.primary}
              variant="outline"
              fontWeight="thin"
            >
              {value}
            </Button>
          ))}
        </SimpleGrid>
      </Box>
  );
};

export default PlayerBaseMenu;
