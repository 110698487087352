import { Box, Center, HStack, Text, VStack } from "@chakra-ui/react";
import { useLocation, useParams } from "react-router-dom";
import { useInjuryHistory } from "../../../hooks/usePlayer";
import InjuryBar from "./InjuryBar";
import { useContext } from "react";
import { LanguageContext } from "../../../store/languageContext";
import { LanguageKeys } from "../../../language/LanguageKeys";
import { colorsSecondary, textColors } from "../../../colorStyles";

const InjuryHistory = () => {
  const { guiText } = useContext(LanguageContext);

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const idTm = Number(queryParams.get("idTm"));

  const { data: injuries, isLoading, error } = useInjuryHistory(idTm);

  return (
    <Box h="100%" w="100%" bg={colorsSecondary.background} p={2}>
      {injuries && injuries.length > 0 ? (
        <Box h="100%" w="100%" display="flex" flexDir="column">
          <HStack width="100%" pb={4} spacing={0} pt={3}>
            <Box w="20%">
              <Text fontWeight="semibold" color={textColors.primary}>{guiText[LanguageKeys.SpielerprofilStartVerletzung]}</Text>
            </Box>
            <Box w="20%">
              <Text fontWeight="semibold" color={textColors.primary}>{guiText[LanguageKeys.SpielerprofilEndeVerletzung]}</Text>
            </Box>
            <Box w="30%">
              <Text fontWeight="semibold" color={textColors.primary}>{guiText[LanguageKeys.SpielerprofilDauer]}</Text>
            </Box>
            <Box w="30%">
              <Text fontWeight="semibold" color={textColors.primary}>{guiText[LanguageKeys.SpielerprofilVerletzung]}</Text>
            </Box>
          </HStack>
          <Box flex={1} overflow="hidden">
            <VStack overflow="auto" spacing={3} w="100%" h="100%">
              {injuries.map((injury, idx) => (
                <InjuryBar key={idx} injury={injury} guiText={guiText} />
              ))}
            </VStack>
          </Box>
        </Box>
      ) : (
        <Center h="100%" w="100%" color={colorsSecondary.background}>
          <Text fontSize={20} color={textColors.primary}>{guiText[LanguageKeys.KeineVerletzungsdatenvorhanden]}</Text>
        </Center>
      )}
    </Box>
  );
};

export default InjuryHistory;
