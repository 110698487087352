import {
  HStack,
  Spacer,
  Text,
} from "@chakra-ui/react";
import {Shadowteam} from "../../generated";
import {useContext, useEffect} from "react";
import { LanguageContext } from "../../store/languageContext";
import { LanguageKeys } from "../../language/LanguageKeys";
import {LocaleKeys} from "../../language/LocaleKeys";
import {useLocation} from "react-router-dom";

interface Props {
  shadowteam: Shadowteam;
}
const ShadowteamHeaderPrintView = ({shadowteam}: Props) => {
  const { setLanguage, guiText } = useContext(LanguageContext);

  const today = new Date().toLocaleDateString("de-DE");

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const language = queryParams.get("language");
  useEffect(() => {
    switch (language) {
      case LocaleKeys.DE:
        setLanguage(LocaleKeys.DE);
        break;
      case LocaleKeys.EN:
        setLanguage(LocaleKeys.EN);
        break;
      case LocaleKeys.ES:
        setLanguage(LocaleKeys.ES);
        break;
      default:
        setLanguage(LocaleKeys.DE);
    }
  }, []);

  return (
    <HStack spacing={3} w="100%" fontSize="20">
      <Text>{shadowteam.shadowteamName}</Text>
      <Text>{shadowteam.formation}</Text>
      <Spacer />
      <Text>{guiText[LanguageKeys.Erstellungsdatum]}: {today}</Text>
    </HStack>
  );
};

export default ShadowteamHeaderPrintView;
