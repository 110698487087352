import { text, ticks } from "d3";
import { MarketValueEntry } from "../generated";
import { germanMonths, resizeImage } from "../util/utilFunctions";
import { textColors } from "../colorStyles";

export const imageOnTopPlugin = {
  id: "imageOnTop",
  afterDatasetsDraw: function (
    chart: {
      ctx: any;
      data: { datasets: any[] };
      getDatasetMeta: (arg0: any) => any;
    },
    _: any,
    options: any
  ) {
    const ctx = chart.ctx;
    chart.data.datasets.forEach(
      (dataset: { pointStyle: { [x: string]: any } }, i: any) => {
        const meta = chart.getDatasetMeta(i);
        if (!meta.hidden) {
          meta.data.forEach(
            (point: { x: number; y: number }, index: string | number) => {
              const img = dataset.pointStyle && dataset.pointStyle[index];
              if (img) {
                ctx.drawImage(
                  img,
                  point.x - img.width / 2,
                  point.y - img.height / 2,
                  img.width,
                  img.height
                );
              }
            }
          );
        }
      }
    );
  },
};

export const createOptions = (maxValue: number) => ({
  responsive: true,
  maintainAspectRatio: false,

  interaction: {
    mode: "nearest" as "nearest",
    intersect: false,
  },

  plugins: {
    innerLabel: false,

    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      displayColors: false, // This line prevents the color box from showing next to the label

      callbacks: {
        label: function (context: any) {
          const teamName = context.raw.teamName;

          return [
            context.dataset.label + ": " + context.formattedValue,
            "Team: " + context.raw.teamName,
          ];
        },
      },
    },
  },

  scales: {
    y: {
      min: 0,
      max: maxValue,
      ticks: {
        color: textColors.primary,
        font: {
          size: 14,
        },
      },
      grid: {
        drawOnChartArea: true,
      },
    },

    x: {
      grid: {
        drawOnChartArea: false,
      },
      ticks: {
        color: textColors.primary,
        font: {
          size: 12,
        },
      },
    },
  },
});

function getMonthsBetweenDates(startDate: Date, endDate: Date): string[] {
  const result: string[] = [];
  while (startDate <= endDate) {
    const monthName = germanMonths[startDate.getMonth()];
    const year = startDate.getFullYear();
    result.push(`${monthName} ${year}`);
    startDate.setMonth(startDate.getMonth() + 1);
  }
  const endMonthName = germanMonths[endDate.getMonth()];
  const endYear = endDate.getFullYear();
  result.push(`${endMonthName} ${endYear}`);

  return result;
}

const transformMarketValueData = async (
  entries: MarketValueEntry[]
): Promise<any> => {
  const firstEntryDate = new Date(entries[0].datum);
  const lastEntryDate = new Date(entries[entries.length - 1].datum);

  const labels = getMonthsBetweenDates(firstEntryDate, lastEntryDate);

  // Create a mapping of the formatted dates to their values
  const dateToValueMap: { [date: string]: { y: number; teamName: string } } =
    {};

  entries.forEach((entry) => {
    const date = new Date(entry.datum);
    const month = germanMonths[date.getMonth()];
    const year = date.getFullYear();
    const formattedDate = `${month} ${year}`;

    dateToValueMap[formattedDate] = {
      y: entry.marktwert!,
      teamName: entry.teamNameTm!,
    };
  });

  const values = labels.map((label) => {
    const value = dateToValueMap[label];
    if (value) {
      return { x: label, y: value.y, teamName: value.teamName };
    } else {
      return { x: label, y: null, teamName: "" };
    }
  });

  const pointStyles: (HTMLImageElement | undefined)[] = [];
  for (let idx = 0; idx < entries.length; idx++) {
    const entry = entries[idx];
    const date = new Date(entry.datum);
    const month = germanMonths[date.getMonth()];
    const year = date.getFullYear();
    const formattedDate = `${month} ${year}`;
    const index = labels.indexOf(formattedDate);
    if (index !== -1) {
      if (idx === 0 || entry.teamIdTm !== entries[idx - 1].teamIdTm) {
        const img = await resizeImage(entry.teamImageTm!, 40);
        pointStyles[index] = img;
      } else {
        pointStyles[index] = undefined;
      }
    }
  }

  return {
    labels: labels,
    datasets: [
      {
        label: "Market Value",
        data: values,
        borderColor: textColors.primary,
        backgroundColor: textColors.primary,
        spanGaps: true,
        pointStyle: pointStyles,
        cubicInterpolationMode: "monotone",
      },
    ],
  };
};

export default transformMarketValueData;
