import {
  Box,
  Center,
  Divider,
  HStack,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import {
  ArcElement,
  Chart as ChartJS,
  Legend,
  RadialLinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { useContext, useEffect, useRef, useState } from "react";
import { getElementAtEvent, PolarArea } from "react-chartjs-2";

import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  useAvailableKpiClips,
  usePolarChartComparisonData,
  usePolarChartData,
} from "../../../hooks/usePlayer";
import usePreparePolarChartData, {
  innerLabel,
  patternPlugin,
  hoveredLabel,
  ReverseMapping,
} from "../../../hooks/usePreparePolarChartData";
import {
  RatingHistoryModalStore,
  usePlayerProfileStore,
  useRatingHistoryModalStore,
} from "../../../store/store";
import { formatDate } from "../../../util/utilFunctions";
import AlleyesScores from "../AlleyesScores";
import RatingHistoryModal from "./RatingHistoryModal";
import KpiClips from "./KpiClips";
import { LanguageContext } from "../../../store/languageContext";
import { LanguageKeys } from "../../../language/LanguageKeys";
import { colorsSecondary, textColors } from "../../../colorStyles";

ChartJS.register(
  RadialLinearScale,
  ArcElement,
  Legend,
  Title,
  Tooltip,
  innerLabel,
  patternPlugin
);

const StyledPolarArea = styled(PolarArea)`
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;
  margin: 0 auto;
`;

interface Props {
  playerId?: number;
  animate?: boolean;
}

const PolarCharts = ({ playerId, animate = true }: Props) => {
  const { guiText } = useContext(LanguageContext);
  const reverseMapping = ReverseMapping(guiText);

  const chartRefs = useRef<Record<string, any>>({}); // Using Record type to define string keys and any values

  const location = useLocation();

  const { id } = useParams();

  if (playerId == undefined) {
    playerId = Number(id);
  }

  const queryParams = new URLSearchParams(location.search);
  const position = queryParams.get("position")!;

  const { data: polarChartData, isLoading } = usePolarChartData(playerId);

  const { data: kpiClips } = useAvailableKpiClips(playerId, position);
  const kpisWithClips = [...new Set(kpiClips?.map(clip => clip.kpi))];

  const activePlayer = polarChartData?.find(
    (data) => data.positionsGruppe === position
  );

  const { data: polarChartComparisonData } =
    usePolarChartComparisonData(position);

  const [selectedKpi, setSelectedKpi] = useState("");

  const { chartDatas, options } = usePreparePolarChartData(
    activePlayer,
    polarChartComparisonData,
    position,
    false,
    animate,
    kpisWithClips
  );



  const { date, setDate } = usePlayerProfileStore();

  useEffect(() => {
    if (activePlayer && formatDate(date) === formatDate(new Date())) {
      setDate(activePlayer.datum);
    }
  }, [activePlayer, date, position]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { setRatingHistoryModalData } = useRatingHistoryModalStore();

  const handleChartClick = (chartKey: string, event: any, index: number) => {
    const chart = chartRefs.current[chartKey];
    const clickedElements = getElementAtEvent(
      chart,
      event
    );

    if (clickedElements.length > 0) {
      const x = event.nativeEvent.offsetX - chart.scales["r"].xCenter;
      const y = event.nativeEvent.offsetY - chart.scales["r"].yCenter;

      if (Math.pow(x, 2) + Math.pow(y, 2) < 324) {
        const title = chart.titleBlock.options.text;

        setRatingHistoryModalData({
          chart: title,
          rating: chartKey,
          id: id,
          position: position,
          date: date,
        } as RatingHistoryModalStore);
        onOpen();
        return;
      } else {
        const kpiLabel = chartDatas[index].labels[clickedElements[0].index].join(" ");
        const kpi = (kpiLabel in reverseMapping) && reverseMapping[kpiLabel];
        if (kpi && kpisWithClips.includes(kpi)) {
          setSelectedKpi(kpi);
        }
      }
    } else {
      const kpiLabel = hoveredLabel({ x: event.nativeEvent.offsetX, y: event.nativeEvent.offsetY }, chart);
      if (kpiLabel !== null) {
        const title = chart.titleBlock.options.text;

        setRatingHistoryModalData({
          chart: title,
          rating: kpiLabel,
          id: id,
          position: position,
          date: date,
        } as RatingHistoryModalStore);
        onOpen();
        return;
      }
    }
  };

  const minChartSize = "330px";

  return (
    <Box flex={1} display="flex" overflow="hidden">
      {polarChartData && chartDatas && options ? (
        <VStack flex={1} display="flex" spacing={1} overflow="hidden">
          <HStack
            flex={1}
            justifyContent="space-around"
            divider={
              <Divider
                border="1px solid"
                borderColor={colorsSecondary.background}
                orientation="vertical"
              />
            }
            display="flex"
            w="100%"
            overflow="hidden"
          >
            <Box
              flex={1}
              pb={3}
              overflow="hidden"
              minW={minChartSize}
              minH={minChartSize}
              h="99%"
            >
              <StyledPolarArea
                id="polar-charts-athletisch"
                ref={(el) => (chartRefs.current["athletisch"] = el)}
                data={chartDatas[0]}
                options={options[0]}
                onClick={(e) => handleChartClick("athletisch", e, 0)}
              />
            </Box>

            <Box
              flex={1}
              display="flex"
              minW={minChartSize}
              h="99%"
              overflow="hidden"
            >
              <AlleyesScores
                id="polar-charts-s04-scores"
                playerId={playerId}
                position={position}
                useCase="profile"
              ></AlleyesScores>
            </Box>

            <Box
              flex={1}
              pb={3}
              overflow="hidden"
              minW={minChartSize}
              minH={minChartSize}
              h="99%"
            >
              <StyledPolarArea
                id="polar-charts-technischPs"
                ref={(el) => (chartRefs.current["technischPs"] = el)}
                onClick={(e) => handleChartClick("technischPs", e, 1)}
                data={chartDatas[1]}
                options={options[1]}
              />
            </Box>
          </HStack>
          <Divider
            border="1px solid"
            borderColor={colorsSecondary.background}
            mt={1}
            mb={1}
          ></Divider>
          <HStack
            flex={1}
            display="flex"
            justifyContent="space-around"
            divider={
              <Divider
                border="1px solid"
                borderColor={colorsSecondary.background}
                orientation="vertical"
              />
            }
            w="100%"
            overflow="hidden"
          >
            <Box
              flex={1}
              overflow="hidden"
              minW={minChartSize}
              minH={minChartSize}
              h="99%"
            >
              <StyledPolarArea
                id="polar-charts-technischD"
                ref={(el) => (chartRefs.current["technischD"] = el)}
                onClick={(e) => handleChartClick("technischD", e, 2)}
                data={chartDatas[2]}
                options={options[2]}
              />
            </Box>
            <Box
              flex={1}
              overflow="hidden"
              minW={minChartSize}
              minH={minChartSize}
              h="99%"
            >
              <StyledPolarArea
                id="polar-charts-taktischRun"
                ref={(el) => (chartRefs.current["taktischRun"] = el)}
                onClick={(e) => handleChartClick("taktischRun", e, 3)}
                data={chartDatas[3]}
                options={options[3]}
              />
            </Box>
            <Box
              flex={1}
              overflow="hidden"
              minW={minChartSize}
              minH={minChartSize}
              h="99%"
            >
              <StyledPolarArea
                id="polar-charts-taktischPasses"
                ref={(el) => (chartRefs.current["taktischPasses"] = el)}
                onClick={(e) => handleChartClick("taktischPasses", e, 4)}
                data={chartDatas[4]}
                options={options[4]}
              />
            </Box>
          </HStack>
        </VStack>
      ) : isLoading ? (
        <Center h="100%" w="100%">
          <Text fontSize={22}>Loading...</Text>
        </Center>
      ) : (
        <Center id="polar-charts-no-data" h="100%" w="100%">
          <Text fontSize={20}>{guiText[LanguageKeys.KeineDatenvorhanden]}</Text>
        </Center>
      )}
      <RatingHistoryModal isOpen={isOpen} onClose={onClose} />
      {selectedKpi != "" &&
        <KpiClips
          id={playerId}
          position={position}
          kpi={selectedKpi}
          data={kpiClips?.filter(clip => clip.kpi === selectedKpi)}
          onClose={() => setSelectedKpi("")}
        />
      }
    </Box>
  );
};

export default PolarCharts;
