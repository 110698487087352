import { LanguageKeys } from "../../language/LanguageKeys";
import { guiText } from "../../language/germanLocale";

export const accordionPanelContentGerman = new Map<string, string>();

accordionPanelContentGerman.set(
  "Allgemeine Infos",
  `
* Positionsspezifische Berechnung
* Mindestens 450 Minuten Spielzeit auf einer Position für ein Rating
* Betrachtungszeitraum immer rückwirkend 12 Monate (z.B. Rating von Nov 23 bedeutet von Dez 22 bis Nov 23)
* ${guiText[LanguageKeys.SearchBarAlleyesVorjahr]}: Höchstes Rating aus dem Zeitraum 3 bis 15 Monaten vor dem aktuellen Rating
* ${guiText[LanguageKeys.SearchBarAlleyesVorjahr2]}: Höchstes Rating aus dem Zeitraum 15 bis 27 Monaten vor dem aktuellen Rating
* "${guiText[LanguageKeys.SearchBarSpielerAlle]}": Alle Spieler werden angezeigt, unabhängig davon, ob sie ein aktuelles Rating haben oder nicht
* "${guiText[LanguageKeys.SearchBarSpielerAktuellesRatingNurMitAktuellemRating]}": Es werden nur Spieler angezeigt, die ein aktuelles Rating haben (nicht älter als 3 Monate)
* "${guiText[LanguageKeys.SearchBarSpielerKeinAktuellesRatingNurOhneAktuellesRating]}": Es werden nur Spieler angezeigt, die KEIN aktuelles Rating haben

`
);

accordionPanelContentGerman.set(
  "Ranking System",
  `
  <table>
  <tr>
    <th>Spielsichtung</th>
    <th>Ranking</th>
    <th>Endbewertung</th>
  </tr>
  <tr>
    <td>Sehr gut</td>
    <td style="width: 80px; padding: 0px 24px;">
      <table >
        <tr ><td>10</td></tr>
        <tr><td style="border-bottom: none">9</td></tr>
      </table>
    </td>
    <td>Weltklassespieler</td>
  </tr>
  <tr>
  <td>Gut</td>
  <td style="width: 80px; padding: 0px 24px;">
  <table>
      <tr><td>8</td></tr>
      <tr><td style="border-bottom: none">7</td></tr>
    </table>
  </td>
  <td>Bundesliga Spitzenspieler</td>
</tr>
<tr>
<td>Durchschnitt</td>
<td style="width: 80px; padding: 0px 24px;">
<table>
    <tr><td>6</td></tr>
    <tr><td style="border-bottom: none">5</td></tr>
  </table>
</td>
<td>1. Bundesliga Spieler</td>
</tr>
<tr>
<td>Ausreichend</td>
<td style="width: 80px; padding: 0px 24px;">
<table>
    <tr><td>4</td></tr>
    <tr><td style="border-bottom: none">3</td></tr>
  </table>
</td>
<td>2. Bundesliga Spieler</td>
</tr>
<tr>
<td>Mangelhaft</td>
<td style="width: 80px; padding: 0px 24px;">
<table>
    <tr><td>2</td></tr>
    <tr><td style="border-bottom: none">1</td></tr>
  </table>
</td>
<td>Keine Alternative</td>
</tr>

</table>

`
);

accordionPanelContentGerman.set(
  guiText[LanguageKeys.SpielerprofilAhnlicheSpieler],
  `
Basierend auf den Kennzahlen des Spielers werden ähnliche Spieler vorgeschlagen. 
Diese Vorschläge sollen dazu dienen, alternative Kandidaten für Abgänge oder (bspw. aufgrund finanzieller Rahmenbedigungen) nicht verfügbarer Spieler zu generieren.

<br>\n

**Filter**\n
Die Filter sollen dazu dienen die Liste der Spieler gegeben eigener Präferenzen einzugrenzen. Dazu stehen die folgenden Filter zur Verfügung:\n
${guiText[LanguageKeys.SearchBarMarktwert]},
${guiText[LanguageKeys.SearchBarAlter]},
${guiText[LanguageKeys.SearchBarVertragsendeBis]},
${guiText[LanguageKeys.SearchBarStarterFuß]},
${guiText[LanguageKeys.SpielerprofilGewichtung]} (die Gewichtung der Kennzahlen aufgrund eines zugrundeliegenden Spielerprofils),
${guiText[LanguageKeys.SpielerprofilSpieler]} (Vergleich aufgrund aktueller oder auch historischer Ratings)

<br>\n

**Liste an Vorschlägen** \n
Die Liste der Vorschläge wird durch den Algorithmus unter oben durch die Filter bestimmten Rahmenbedingungen generiert. Die Liste wird dabei nach Ähnlichkeit (siehe letzte Spalte) sortiert.
`
);

accordionPanelContentGerman.set(
  guiText[LanguageKeys.SidebarHome],
  `
**Top Alleyes Mover, ${guiText[LanguageKeys.KurzfristigerTrend]}**  
Die Spieler im Markt des eingeloggten Nutzers mit der größten positiven Veränderung des Alleyes-Ratings im kurzfristigen Trend
- mit vollständigem Alleyes Rating
- Alter < 30
- Marktwert <= 10 Mio.  

&nbsp;  
Die Veränderung im kurzfristigen Trend berechnet sich als Differenz aus dem aktuellen Alleyes-Rating und dem Alleyes-Rating des Vormonats, vorausgesetzt sie basieren auf der gleichen Datengrundlage.

&nbsp;  

**Top Alleyes Mover, ${guiText[LanguageKeys.LangfristigerTrend]}**  
Die Spieler im Markt des eingeloggten Nutzers mit der größten positiven Veränderung des Alleyes-Ratings im langfristigen Trend
- mit vollständigem Alleyes Rating
- Alter < 30
- Marktwert <= 10 Mio.  

&nbsp;  
Die Veränderung im langfristigen Trend berechnet sich durch eine lineare Regression über die letzten 8 Monate des Alleyes-Ratings, vorausgesetzt es sind mindestens 4 Datenpunkte vorhanden, die auf der gleichen Datengrundlage basieren wie das aktuellste Alleyes-Rating.

&nbsp;  

**${guiText[LanguageKeys.HomeNewPlayers]}**  
Die Spieler im Markt des eingeloggten Nutzers
- mit erstmalig vollständigem Alleyes Rating innerhalb der letzten 60 Kalendertage
- Marktwert <= 10 Mio.  
&nbsp;  

**${guiText[LanguageKeys.HomePriorityPlayers]}**  
Die Spieler im Markt des eingeloggten Nutzers, die folgende Kriterien erfüllen:
- vertragslos oder auslaufender Vertrag bis zum folgenden Juni
- letztes Alleyes Rating >= 70
- Marktwert <= 5 Mio.
- Alter <= 26
- keine Kaufoption, keine Kaufpflicht mit Bedingung, keine Leihe  
&nbsp;  

**${guiText[LanguageKeys.HomeMissingReviews]}**  
Die Spieler in einer der eigenen Schattenmannschaften des eingeloggten Nutzers, für die 
- ein Rating des Nutzers fehlt
- die nicht bereits für Schalke spielen 
`
);

accordionPanelContentGerman.set(
  guiText[LanguageKeys.SpielerprofilAthletisch].replace('\n', ' '),
  `
| | |
| :- | :- |
| ${guiText[LanguageKeys.SpielerprofilDistanz].replace('\n', ' ')} | Gesamtlaufdistanz über das gesamte Spiel (mit Ball, gegen den Ball und Ball im Aus) |
| ${guiText[LanguageKeys.SpielerprofilAktivitatInBallbesitz].replace('\n', ' ')} | Verhältnis der Distanz über 11 km/h zu Gesamtlaufdistanz in Ballbesitz |
| ${guiText[LanguageKeys.SpielerprofilAktivitatGegenDenBall].replace('\n', ' ')} | Verhältnis der Distanz über 11 km/h zu Gesamtlaufdistanz gegen den Ball |
| ${guiText[LanguageKeys.SpielerprofilIntensitatInBallbesitz].replace('\n', ' ')} | Verhältnis der Distanz über 17 km/h zu Gesamtlaufdistanz in Ballbesitz |
| ${guiText[LanguageKeys.SpielerprofilIntensitatGegenDenBall].replace('\n', ' ')} | Verhältnis der Distanz über 17 km/h zu Gesamtlaufdistanz gegen den Ball |
| ${guiText[LanguageKeys.SpielerprofilSprints].replace('\n', ' ')} | Zurückgelegte Distanz über 25 km/h |
| ${guiText[LanguageKeys.SpielerprofilTempolaufe].replace('\n', ' ')} | Zurückgelegte Distanz über 20 km/h bis 25 km/h |
| ${guiText[LanguageKeys.SpielerprofilExplosivitat].replace('\n', ' ')} | Summe der maximalen Beschleunigungen im Geschwindigkeitsbereich 8 km/h bis 15 km/h |
| ${guiText[LanguageKeys.SpielerprofilMaxSpeed].replace('\n', ' ')} | 90 % Quantil der Maxspeed aus allen Begegnungen |
`
);

accordionPanelContentGerman.set(
  guiText[LanguageKeys.SpielerprofilTechnischPassenScoren].replace('\n', ' '),
  `
| | |
| :- | :- |
| ${guiText[LanguageKeys.SpielerprofilAnzahlPasse].replace('\n', ' ')} (100) | |
| ${guiText[LanguageKeys.SpielerprofilPassqualitat].replace('\n', ' ')} (Mindestzahl: 100) | Bewertung der Pässe anhand von Pass- und Zielort |
| ${guiText[LanguageKeys.SpielerprofilPasseeunterMittleremDruck].replace('\n', ' ')} (Mindestens 200 min. in Ballbesitz) | Passquote bei Pässen unter mittlerem Druck |
| ${guiText[LanguageKeys.SpielerprofilPasseeunterHohemDruck].replace('\n', ' ')} (Mindestens 200 min. in Ballbesitz) | Passquote bei Pässen unter hohem Druck |
| ${guiText[LanguageKeys.SpielerprofilFlankenqualitat].replace('\n', ' ')} (15) | Verhältnis von Flanken aus denen xGoal generiert wird zu Gesamtanzahl der Flanken |
| ${guiText[LanguageKeys.SpielerprofilAnzahlSchusse].replace('\n', ' ')} (15) | |
| ${guiText[LanguageKeys.SpielerprofilChancenqualitat].replace('\n', ' ')} | |
| ${guiText[LanguageKeys.SpielerprofilAbschlussqualitat].replace('\n', ' ')} (15) | Verhältnis aus postShot und preShot xGoalwert |
| ${guiText[LanguageKeys.SpielerprofilBessermacher].replace('\n', ' ')} (300 Ballbesitzphasen) | Beteiligung an Ballbesitzphasen aus denen ein xGoal entsteht (ohne den Schützen und den Assistgeber) |
`
);

accordionPanelContentGerman.set(
  guiText[LanguageKeys.SpielerprofilTechnischDuelle].replace('\n', ' '),
  `
| | |
| :- | :- |
| ${guiText[LanguageKeys.SpielerprofilDribbling].replace('\n', ' ')} (20) | Verhältnis von erfolgreichen und nicht erfolgreichen offensiven 1-gegen-1-Situationen |
| ${guiText[LanguageKeys.SpielerprofilBodenzweikampfInBallbesitz].replace('\n', ' ')} (20) | Verhältnis von erfolgreichen und nicht erfolgreichen Bodenzweikämpfen in Ballbesitz |
| ${guiText[LanguageKeys.SpielerprofilLuftzweikampfInBallbesitz].replace('\n', ' ')} (15) | Verhältnis von erfolgreichen und nicht erfolgreichen Luftzweikämpfen in Ballbesitz |
| ${guiText[LanguageKeys.SpielerprofilBallbehauptungUnterMittleremDruck].replace('\n', ' ')} (200 Minuten Spielzeit in Ballbesitz) | Quote der Ballbehauptung unter mittlerem Druck |
| ${guiText[LanguageKeys.SpielerprofilBallbehauptungUnterHohemDruck].replace('\n', ' ')} (200 Minuten Spielzeit in Ballbesitz) | Quote der Ballbehauptung unter hohem Druck |
| ${guiText[LanguageKeys.SpielerprofilAbwehrGegenDribbling].replace('\n', ' ')} (10) | Verhältnis von erfolgreichen und nicht erfolgreichen defensiven 1-gegen-1-Situationen |
| ${guiText[LanguageKeys.SpielerprofilBodenzweikampfGegenDenBall].replace('\n', ' ')} (20) | Verhältnis von erfolgreichen und nicht erfolgreichen Bodenzweikämpfen gegen den Ball |
| ${guiText[LanguageKeys.SpielerprofilLuftzweikampfGegenDenBall].replace('\n', ' ')} (15) | Verhältnis von erfolgreichen und nicht erfolgreichen Luftzweikämpfen gegen den Ball |
| ${guiText[LanguageKeys.SpielerprofilZweiteBalle].replace('\n', ' ')} (20) | Verhältnis von erfolgreichen und nicht erfolgreichen Zweikämpfen um den 2. Ball |
`
);

accordionPanelContentGerman.set(
  guiText[LanguageKeys.SpielerprofilTaktischFreilaufverhalten].replace('\n', ' ') + " (200 Minuten Spielzeit in Ballbesitz)",
  `
| | |
| :- | :- |
| Anzahl der Läufe in den verschiedenen Lauftypen | |
`
);

accordionPanelContentGerman.set(
  guiText[LanguageKeys.SpielerprofilTaktischPassverhalten].replace('\n', ' ') + " (200 Minuten Spielzeit in Ballbesitz)",
  `
| | |
| :- | :- |
| Anzahl der Pässe zu den verschiedenen Lauftypen | |
`
);
