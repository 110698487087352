import { LanguageKeys } from "./LanguageKeys";

export const guiText: { [key in LanguageKeys]: string } = {
    [LanguageKeys.LanguageGerman]: "Deutsch",
    [LanguageKeys.LanguageEnglish]: "English",
    [LanguageKeys.LanguageSpanish]: "Español",
    [LanguageKeys.SidebarHome]: "Home",
    [LanguageKeys.SidebarSpielersuche]: "Player search",
    [LanguageKeys.SidebarWatchlist]: "Watchlist",
    [LanguageKeys.SidebarSpielervergleich]: "Player comparison",
    [LanguageKeys.SidebarSchattenmannschaften]: "Shadow teams",
    [LanguageKeys.WachlistButtonAdd]: "Add to Watchlist or ShadowTeam",
    [LanguageKeys.SearchBarSpielernamenEingeben]: "Enter a player name...",
    [LanguageKeys.Greeting]: "Hello, ",
    [LanguageKeys.HomePriorityPlayers]: "Priority Players",
    [LanguageKeys.HomeNewPlayers]: "New Players",
    [LanguageKeys.HomeMissingReviews]: "Missing Reviews",
    [LanguageKeys.KurzfristigerTrend]: "Short-term Trend",
    [LanguageKeys.LangfristigerTrend]: "Long-term Trend",
    [LanguageKeys.SearchBarFilter]: "Filters",
    [LanguageKeys.SearchBarFilterSpeichern]: "Save filters",
    [LanguageKeys.SearchBarZurucksetzen]: "Reset",
    [LanguageKeys.SearchBarGespeicherteFilterungAuswahlen]: "Select saved filtering",
    [LanguageKeys.SearchBarFilterAusgewahlt]: "x filters selected, x players found",
    [LanguageKeys.SearchBarVergleichen]: "Compare",
    [LanguageKeys.SearchBarDieserSpielerIstNochNichtInDeinerWatchlist]: "This player is not yet in your watchlist",
    [LanguageKeys.SearchBarDieserSpielerIstNochInKeinerSchattenmannschaft]: "This player is not yet in any shadow team",
    [LanguageKeys.SearchBarZurWatchlistHinzufugen]: "Add to watchlist",
    [LanguageKeys.SearchBarZurSchattenmannschaftHinzufugen]: "Add to shadow team",
    [LanguageKeys.SearchBarZurWatchlistOderSchattenmannschaftHinzufugen]: "Add to watchlist or shadow team",
    [LanguageKeys.SearchBarSchliessen]: "Close",
    [LanguageKeys.SearchBarSpielerAktuellesRatingNurMitAktuellemRating]: "Player: Current ratings only",
    [LanguageKeys.SearchBarSpielerAlle]: "Player: All",
    [LanguageKeys.SearchBarSpielerKeinAktuellesRatingNurOhneAktuellesRating]: "Player: Without current ratings only",
    [LanguageKeys.SearchBarPosition]: "Position",
    [LanguageKeys.SearchBarAlter]: "Age",
    [LanguageKeys.SearchBarVerein]: "Team",
    [LanguageKeys.SearchBarLiga]: "League",
    [LanguageKeys.SearchBarNationalitat]: "Nationality",
    [LanguageKeys.SearchBarKorpergrobe]: "Height",
    [LanguageKeys.SearchBarStarterFuß]: "Strong foot",
    [LanguageKeys.Links]: "Left",
    [LanguageKeys.Rechts]: "Right",
    [LanguageKeys.Beide]: "Both",
    [LanguageKeys.SearchBarMarktwert]: "Market value",
    [LanguageKeys.SearchBarVertragsendeBis]: "Contract end until",
    [LanguageKeys.SearchBarVertragsakquisationen]: "Contract options",
    [LanguageKeys.SearchBarKeineKaufoption]: "No buy options",
    [LanguageKeys.SearchBarKeineKaufpflichtMitBedingungen]: "No buy-clause with conditions",
    [LanguageKeys.SearchBarLeihe]: "Loan",
    [LanguageKeys.SearchBarNurVollstandigeRatings]: "Only complete ratings",
    [LanguageKeys.SearchBarSubjektiveRatings]: "Subjective ratings",
    [LanguageKeys.SearchBarGesamtbewertungAktuell]: "Overall rating current",
    [LanguageKeys.SearchBarGesamtbewertungPotential]: "Overall rating potential",
    [LanguageKeys.SearchBarSpielbewertungAktuell]: "Match rating current",
    [LanguageKeys.SearchBarSpielbewertungPotential]: "Match rating potential",
    [LanguageKeys.SearchBarRatingsOberkategorien]: "Ratings",
    [LanguageKeys.SearchBarAlleyesAktuell]: "Alleyes\ncurrent",  // with linebreak
    [LanguageKeys.SearchBarAlleyesPotential]: "Alleyes\npotential",  // with linebreak
    [LanguageKeys.SearchBarAlleyesVorjahr]: "Alleyes Year -1",
    [LanguageKeys.SearchBarAlleyesVorjahr2]: "Alleyes Year -2",
    [LanguageKeys.SearchBarTrendKurzfristig]: "Trend short-term",
    [LanguageKeys.SearchBarTrendLangfristig]: "Trend long-term",
    [LanguageKeys.SearchBarSportlicheDatenRatingsDetailliert]: "Ratings detailed",
    [LanguageKeys.SpielerprofilAthletisch]: "Athletics",
    [LanguageKeys.SpielerprofilDistanz]: "Distance",
    [LanguageKeys.SpielerprofilAktivitatInBallbesitz]: "Activity in\npossession",
    [LanguageKeys.SpielerprofilAktivitatGegenDenBall]: "Activity out\nof possession",
    [LanguageKeys.SpielerprofilIntensitatInBallbesitz]: "Intensity in\npossession",
    [LanguageKeys.SpielerprofilIntensitatGegenDenBall]: "Intensity out\nof possession",
    [LanguageKeys.SpielerprofilSprints]: "Sprints",
    [LanguageKeys.SpielerprofilTempolaufe]: "     Highspeed runs", // spaces for better positioning
    [LanguageKeys.SpielerprofilExplosivitat]: "Explosiveness",
    [LanguageKeys.SpielerprofilMaxSpeed]: "Max Speed",
    [LanguageKeys.SpielerprofilTechnischPassenScoren]: "Technical - Passing & Scoring",
    [LanguageKeys.TechnischnPS]: "Technical\nP&S", // "Technical - Passing & Scoring", with linebreak and abbrevation
    [LanguageKeys.SpielerprofilAnzahlPasse]: "Number of passes",
    [LanguageKeys.SpielerprofilPassqualitat]: "Quality of passes",
    [LanguageKeys.SpielerprofilPasseeunterMittleremDruck]: "Passing under\nmedium pressure",
    [LanguageKeys.SpielerprofilPasseeunterHohemDruck]: "Passing under\nhigh pressure",
    [LanguageKeys.SpielerprofilFlankenqualitat]: "Quality of\n crosses",
    [LanguageKeys.SpielerprofilAnzahlSchusse]: "Number\nof shots",
    [LanguageKeys.SpielerprofilChancenqualitat]: "Quality of chances",
    [LanguageKeys.SpielerprofilAbschlussqualitat]: "Quality of finishing",
    [LanguageKeys.SpielerprofilBessermacher]: "Game improver",
    [LanguageKeys.SpielerprofilTechnischDuelle]: "Technical - Duels",
    [LanguageKeys.TechnischnDuelle]: "Technical\nduels", // Technical duels with linebreak
    [LanguageKeys.SpielerprofilDribbling]: "Dribblings",
    [LanguageKeys.SpielerprofilBodenzweikampfInBallbesitz]: "Ground duels\nin possession",
    [LanguageKeys.SpielerprofilLuftzweikampfInBallbesitz]: "Aerial duels\nin possession",
    [LanguageKeys.SpielerprofilBallbehauptungUnterMittleremDruck]: "Ball retention under\nmedium pressure",
    [LanguageKeys.SpielerprofilBallbehauptungUnterHohemDruck]: "Ball retention under\nhigh pressure",
    [LanguageKeys.SpielerprofilAbwehrGegenDribbling]: "Defending\nagainst dribblings",
    [LanguageKeys.SpielerprofilBodenzweikampfGegenDenBall]: "Ground duels\nout of possession",
    [LanguageKeys.SpielerprofilLuftzweikampfGegenDenBall]: "Aerial duels\nout of possession",
    [LanguageKeys.SpielerprofilZweiteBalle]: "Loose ball duels",
    [LanguageKeys.SpielerprofilTaktischFreilaufverhalten]: "Tactical - Off-ball runs",
    [LanguageKeys.TaktischnRun]: "Tactical\nRun", // Tactical runs with linebreak and abbreviated
    [LanguageKeys.SpielerprofilAbkippen]: "Dropping off run",
    [LanguageKeys.SpielerprofilNachrucken]: "Support run",
    [LanguageKeys.SpielerprofilEntgegenkommen]: "Coming\nShort run",
    [LanguageKeys.SpielerprofilHinterlaufen]: "Overlap run",
    [LanguageKeys.SpielerprofilLaufInDieHalbspur]: "Pulling\nhalf space run",
    [LanguageKeys.SpielerprofilFreilaufenInDieBreite]: "Pulling wide\nrun",
    [LanguageKeys.SpielerprofilFreilaufenVorDerGegnerischenKette]: "Run ahead of\nthe defensive line",
    [LanguageKeys.SpielerprofilTiefenlaufeHinterDieKette]: "Run in\nbehind",
    [LanguageKeys.SpielerprofilLaufInDieBox]: "Cross receiver run",
    [LanguageKeys.SpielerprofilTaktischPassverhalten]: "Tactical - Passing behaviour",
    [LanguageKeys.TaktischnPass]: "Tactical\nPass", // Tactical Passing with linebreak and abbreviated
    [LanguageKeys.SpielerprofilPassZumAbkippenden]: "Pass to dropping off runner",
    [LanguageKeys.SpielerprofilPassZumNachruckenden]: "Pass to\nsupport runner",
    [LanguageKeys.SpielerprofilPassZumEntgegenkommenden]: "Pass to coming\nshort runner",
    [LanguageKeys.SpielerprofilPassZumHinterlaufenden]: "Pass to\noverlap runner",
    [LanguageKeys.SpielerprofilPassInDieHalbspur]: "Pass to\nhalf space",
    [LanguageKeys.SpielerprofilPassInDieBreite]: "Pass to\nwide space",
    [LanguageKeys.SpielerprofilPassVorDerGegnerischenKette]: "Pass ahead of\nthe defensive line",
    [LanguageKeys.SpielerprofilPassHinterDieKette]: "Pass behind\nthe defensive line",
    [LanguageKeys.SpielerprofilFlankeInDieBox]: "Cross into box",
    [LanguageKeys.SpielerprofilPolarchartsRatings]: "Ratings",
    [LanguageKeys.SpielerprofilGeburtsdatum]: "Date of birth",
    [LanguageKeys.SpielerprofilVerletzungstage]: "Days of injury",
    [LanguageKeys.SpielerprofilBerater]: "Agent",
    [LanguageKeys.SpielerprofilZumWyScoutProfil]: "Go to WyScout profile",
    [LanguageKeys.SpielerprofilZum]: "Go to ",
    [LanguageKeys.SpielerprofilProfil]: "profile",
    [LanguageKeys.SpielerprofilMonateDeutsch]: "Months english (e.g., Oct)",
    [LanguageKeys.SpielerprofilSubjektiveBewertungen]: "Subjective ratings",
    [LanguageKeys.SpielerprofilSpieler]: "Player",
    [LanguageKeys.SpielerprofilEventdatum]: "Event Date",
    [LanguageKeys.SpielerprofilSpiel]: "Match",
    [LanguageKeys.SpielerprofilScout]: "Scout",
    [LanguageKeys.SpielerprofilOverallRating]: "Overall rating",
    [LanguageKeys.SpielerprofilPotentialRating]: "Potential rating",
    [LanguageKeys.SpielerprofilJahrgangstyp]: "Cohort type",
    [LanguageKeys.SpielerprofilZuletztGeandert]: "Last edited",
    [LanguageKeys.SpielerprofilPositionImReport]: "Position in report",
    [LanguageKeys.SpielerprofilSpiele]: "Matches",
    [LanguageKeys.SpielerprofilMinuten]: "Minutes",
    [LanguageKeys.SpielerprofilTransferhistorie]: "Transfer History",
    [LanguageKeys.SpielerprofilTransferMarktwerthistorie]: "Transfer/ Market history",
    [LanguageKeys.SpielerprofilMarkthistorie]: "Market value history",
    [LanguageKeys.SpielerprofilAktuellerMarktwert]: "Current market value",
    [LanguageKeys.SpielerprofilMaximalerMarktwert]: "Highest market value",
    [LanguageKeys.SpielerprofilSaison]: "Season",
    [LanguageKeys.SpielerprofilDatum]: "Date",
    [LanguageKeys.SpielerprofilAbgebenderVerein]: "Selling club",
    [LanguageKeys.SpielerprofilAufnehmenderVerein]: "Buying club",
    [LanguageKeys.SpielerprofilVerletzungshistorie]: "Injury history",
    [LanguageKeys.SpielerprofilStartVerletzung]: "Start of injury",
    [LanguageKeys.SpielerprofilEndeVerletzung]: "End of injury",
    [LanguageKeys.SpielerprofilDauer]: "Duration",
    [LanguageKeys.SpielerprofilVerletzung]: "Injury",
    [LanguageKeys.SpielerprofilTorschuss]: "Shots",
    [LanguageKeys.SpielerprofilHeatmap]: "Heatmap",
    [LanguageKeys.SpielerprofilFuß]: "Foot",
    [LanguageKeys.SpielerprofilKopf]: "Header",
    [LanguageKeys.SpielerprofilTor]: "Goal",
    [LanguageKeys.SpielerprofilVersuch]: "Attempt",
    [LanguageKeys.SpielerprofilLowxG]: "low xG",
    [LanguageKeys.SpielerprofilHighxG]: "high xG",
    [LanguageKeys.SpielerprofilPreShot]: "Pre Shot",
    [LanguageKeys.SpielerprofilPostShot]: "Post Shot",
    [LanguageKeys.SpielerprofilKommentare]: "Comments",
    [LanguageKeys.SpielerprofilKeineKommentareVorhanden]: "No comments available",
    [LanguageKeys.SpielerprofilSpeichern]: "Save",
    [LanguageKeys.SpielerprofilFugeEinenKommentarHinzu]: "Add a comment...",
    [LanguageKeys.SpielerprofilAhnlicheSpieler]: "Similar players",
    [LanguageKeys.SpielerprofilGewichtung]: "Weighting",  // name Weighting also used in FilterSpecificationService
    [LanguageKeys.SpielerprofilStammdaten]: "Basic data",  // name Basic data also used in FilterSpecificationService
    [LanguageKeys.SpielerprofilAhnlichkeit]: "Similarity",
    [LanguageKeys.SpielerprofilSpalten]: "Columns",
    [LanguageKeys.SpielerprofilZeilenProSeite]: "Rows per page",
    [LanguageKeys.SpielerprofilVideo]: "Video",
    [LanguageKeys.SpielerprofilVideoNichtVerfugbar]: "Video not available",
    [LanguageKeys.KeinVideovorhanden]: "No video exists",
    [LanguageKeys.WatchlistAnzahlSichtungen]: "Number of\nsightings", // with linebreak 
    [LanguageKeys.WatchlistLetzteSichtung]: "Last\nsighting", // with linebreak
    [LanguageKeys.WatchlistHinzugefugtAm]: "Added On",
    [LanguageKeys.SchattenmannschaftenFormation]: "Line-Up",
    [LanguageKeys.SchattenmannschaftenZumSpielerprofil]: "To Player Profile",
    [LanguageKeys.SchattenmannschaftenSpielerEntfernen]: "Remove Player",
    [LanguageKeys.SchattenmannschaftenMarkierungWahlen]: "Select marking",
    [LanguageKeys.SchattenmannschaftenAnzahlSpieler]: "Number of players",
    [LanguageKeys.SchattenmannschaftenGeteiltMit]: "Shared with",
    [LanguageKeys.SchattenmannschaftenBank]: "Substitutes",
    [LanguageKeys.SchattenmannschaftenAutor]: "Author",
    [LanguageKeys.SpielervergleichWarenkorb]: "Cart",
    [LanguageKeys.Glossar]: "Glossary",
    [LanguageKeys.KeinZugriff]: "No access",
    [LanguageKeys.DieseSchattenmannschaftExistiertNicht]: "This shadow team does not exist",
    [LanguageKeys.OderDuHastKeinenZugriff]: "or you do not have access.",
    [LanguageKeys.NeueSchattenmannschaftErstellen]: "Create new shadow team",
    [LanguageKeys.SpielerHinzufugen]: "Add player",
    [LanguageKeys.JetztVergleichen]: "View player comparison",
    [LanguageKeys.KeineSpielerimVergleich]: "No players to compare",
    [LanguageKeys.SpielerimVergleich]: "Players in comparison",
    [LanguageKeys.DatenWerdenGeladen]: "Data is loaded...",
    [LanguageKeys.Datenkonntennichtgeladenwerden]: "Data could not be loaded.",
    [LanguageKeys.Erstellungsdatum]: "Creation date",
    [LanguageKeys.KeineTorschussdatenvorhanden]: "No shots available",
    [LanguageKeys.KeineHeatmapdatenvorhanden]: "No heatmap available",
    [LanguageKeys.KeineVerletzungsdatenvorhanden]: "No injury data available",
    [LanguageKeys.KeineDatenvorhanden]: "No data available",
    [LanguageKeys.Gesamt]: "Overall",
    [LanguageKeys.Gesamtrating]: "Overall rating",
    [LanguageKeys.WarnungVergleichePositionsbezogen]: "You are comparing players in different positions. Remember that all ratings are position-specific.",
    [LanguageKeys.SpielerinSchattenmannschaftAufPositionOhneDaten]: "The player is in the shadow team in a position for which no data is available!",
    [LanguageKeys.SpielerKannNichtHinzugefuegtWerden]: "Player can not be added",
    [LanguageKeys.SpielerbereitsinSchattenmannschaft]: "This player is already in your shadow team!",
    [LanguageKeys.Achtung]: "Caution",
    [LanguageKeys.GesamtbewertungA]: "Overall rating (C)",
    [LanguageKeys.GesamtbewertungP]: "Overall rating (P)",
    [LanguageKeys.SpielbewertungA]: "Match rating (A)",
    [LanguageKeys.SpielbewertungP]: "Match rating (P)",
    [LanguageKeys.Loeschen]: "Delete",
    [LanguageKeys.Vertragsende]: "Contract end",
    [LanguageKeys.Schattenmannschaft]: "Shadow team",
    [LanguageKeys.ExportKannMinutenDauern]: "The export may take a few minutes.",
    [LanguageKeys.GenerierePDF]: "Generating PDF",
    [LanguageKeys.ExportFehlgeschlagen]: "The export failed.",
    [LanguageKeys.KeineGesamtbewertungVorhanden]: "No overall ratings available.",
    [LanguageKeys.KeineSpielbewertungVorhanden]: "No match ratings available.",
    [LanguageKeys.Gesamtbewertung]: "Overall ratings",
    [LanguageKeys.Spielbewertung]: "Match ratings",
    [LanguageKeys.KeineMarktdatenVorhanden]: "No market data available.",
    [LanguageKeys.Abloese]: "Transfer fee",
    [LanguageKeys.KeineTransferdatenVorhanden]: "No transfer data available.",
    [LanguageKeys.RatingsBasierenAufWenigerAls900Spielminuten]: "Ratings are based on less than 900 minutes of playing time.",
    [LanguageKeys.Jahre]: "years",
    [LanguageKeys.Wettbewerb]: "Competition",
    [LanguageKeys.Tage]: "Days",
    [LanguageKeys.Karriere]: "Career",
    [LanguageKeys.Nein]: "No",
    [LanguageKeys.Ja]: "Yes",
    [LanguageKeys.DatenBeziehenSichAufDasLetzteJahr]: "The data refer to the last year.",
    [LanguageKeys.Aktuell]: "Current",
    [LanguageKeys.Potential]: "Potential",
    [LanguageKeys.Dreierkette]: "Chain of 3",
    [LanguageKeys.Viererkette]: "Chain of 4",
    [LanguageKeys.AlleSpielerzumVergleichHinzufuegen]: "Add all players to the comparison.",
    [LanguageKeys.Name]: "Name",
    [LanguageKeys.BitteWaehleEineFormationAus]: "Please select a formation.",
    [LanguageKeys.Benutzername]: "User Name",
    [LanguageKeys.Zugriff]: "Access",
    [LanguageKeys.Lesen]: "Read",
    [LanguageKeys.Schreiben]: "Write",
    [LanguageKeys.Erstellen]: "Create",
    [LanguageKeys.Abbrechen]: "Cancel",
    [LanguageKeys.TeilenMit]: "Share with",
    [LanguageKeys.SchattenmannschaftLoeschen]: "Delete shadow team",
    [LanguageKeys.WillstDuDieSchattenmannschaft]: "Do you really want to delete the shadow team", // part 1 of the phrase "Do you really want to delete the shadow team?", i.e. all words before shadow team
    [LanguageKeys.WirklichLoeschen]: "?", // part 2 of the phrase "Do you really want to delete the shadow team?", i.e. all words after shadow team
    [LanguageKeys.GibEinenUsernamenEin]: "Please enter a username.",
    [LanguageKeys.KeinenBenutzerGefunden]: "No user found.",
    [LanguageKeys.Spielerdetails]: "Player details",
    [LanguageKeys.Login]: "Login",
    [LanguageKeys.Anmelden]: "Login",
    [LanguageKeys.Abmelden]: "Logout",
    [LanguageKeys.WaehleEineSchattenmannschaft]: "Choose a shadow team",
    [LanguageKeys.KeineSpielerGefunden]: "No players found",
    [LanguageKeys.Alle]: "All",
    [LanguageKeys.AktuellesRating]: "With a current rating",
    [LanguageKeys.AktuellesRatingShort]: "With current rating",
    [LanguageKeys.KeinAktuellesRatingShort]: "No current rating",
    [LanguageKeys.KeinAktuellesRating]: "Without a current rating",
    [LanguageKeys.PDFExport]: "PDF Export",
    [LanguageKeys.KeineGespeichertenFilterVorhanden]: "No stored filters available",
    [LanguageKeys.ExistiertBereitsDurchSpeichernWirdEsUeberschrieben]: "already exists, saving overwrites it.",
    [LanguageKeys.NameFilterung]: "Name Filtering",
    [LanguageKeys.MinMussKleinerAlsMaxWertSein]: "Minimum must be less than maximum value.",
    [LanguageKeys.WerteMuessenZwischen]: "Values must be between ",
    [LanguageKeys.Und]: "and",
    [LanguageKeys.Liegen]: ".",
    [LanguageKeys.SpielerGefunden]: "players found",
    [LanguageKeys.FilterAusgewaehlt]: "filters selected",
    [LanguageKeys.SchattenmannschaftBearbeiten]: "Edit shadow team",
    [LanguageKeys.Standardformation]: "default formation",
    [LanguageKeys.Fehler]: "Error",
    [LanguageKeys.Beim_BeimSpeichernFehlerAufgetreten]: "An error occured when ",
    [LanguageKeys.Speichern_BeimSpeichernFehlerAufgetreten]: "saving ",
    [LanguageKeys.Updaten_BeimUpdatenFehlerAufgetreten]: "updating ",
    [LanguageKeys.DeinesFiltersIstEinFehlerAufgetreten]: "your filter.",
    [LanguageKeys.DeinFilterWurdeErfolgreich]: "Your filter was successfully ",
    [LanguageKeys.Geupdatet]: "updated",
    [LanguageKeys.Gespeichert]: "saved",
    [LanguageKeys.WaehleEineSchattenmannschaftUndEinePositionAus]: "Select a shadow team and position.",
    [LanguageKeys.DieserSpielerIstBereitsInFolgendenSchattenmannschaften]: "This player is already in the following shadow teams:",
    [LanguageKeys.VonderWatchlistEntfernen]: "Remove from your watchlist.",
    [LanguageKeys.DieserSpielerIstBereitsInDeinerWatchlist]: "This player is already in your watchlist.",
    [LanguageKeys.EsKoennenNurSpielerHinzugefuegtWerdenZuDenenPositionsdatenExistieren]: "Only players for whom position data exists can be added. First drag the player to a position for which there is data.",
    [LanguageKeys.DieserSpielerIstBereitsImWarenkorb]: "This player is already in the cart.",
    [LanguageKeys.SpielerErfolgreichZurWatchlistHinzugefuegt]: "Player successfully added to watchlist.",
    [LanguageKeys.Keine]: "None",
    [LanguageKeys.EsIstKeinePositionAusgewaehlt]: "No position is selected.",
    [LanguageKeys.SortierenNach]: "Sort by",
    [LanguageKeys.WenigerAnzeigen]: "Collapse",
    [LanguageKeys.MehrAnzeigen]: "Expand",
    [LanguageKeys.Bis]: "to",
    [LanguageKeys.Ab]: "from",
    [LanguageKeys.ErrorForbidden]: "You do not have sufficient permissions.",
    [LanguageKeys.ErrorGeneral]: "An error occured.",
};
