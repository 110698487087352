import { TooltipItem } from "chart.js";
import { some, text } from "d3";
import { lastDayOfMonth } from "date-fns";
import { PolarChartData, Rating, AlleyesRating } from "../generated";
import { useComparisonStore, usePlayerProfileStore } from "../store/store";
import { convertToDate, germanMonths } from "../util/utilFunctions";
import { colorsSecondary, textColors } from "../colorStyles";
import { color } from "framer-motion";
import { colors } from "@mui/material";

interface Props {
  useCase: string;
}
export const useAlleyesRatingHistoryOptions = ({ useCase }: Props) => {
  const { setDate } = usePlayerProfileStore();
  const { setDate1, setDate2, setDate3 } = useComparisonStore();

  return {
    responsive: true,
    maintainAspectRatio: false,

    interaction: {
      mode: "nearest" as "nearest",
      intersect: false,
    },
    layout: {
      padding: {
        top: 0,
      },
    },
    onClick: (event: any, elements: any, chart: any) => {
      if (elements.length > 0) {
        const firstElement = elements[0];
        const dataIndex = firstElement.index;
        const xValue = chart.data.labels[dataIndex];
        switch (useCase) {
          case "profile":
            setDate(convertToDate(xValue));
            break;
          case "comparisonColumn1":
            setDate1(convertToDate(xValue));
            break;
          case "comparisonColumn2":
            setDate2(convertToDate(xValue));
            break;
          case "comparisonColumn3":
            setDate3(convertToDate(xValue));
            break;
        }
      }
    },

    plugins: {
      innerLabel: false,
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        displayColors: true, // This line prevents the color box from showing next to the label

        callbacks: {
          label: function (context: any) {
            return context.dataset.label + ": " + context.formattedValue;
          },
          // title: function (context: any) {
          //   return context.label;
          // },
        },
      },
    },
    scales: {
      y: {
        min: 0,

        max: 110,

        grid: {
          drawOnChartArea: true,
          color: textColors.lightgrey,
        },
        ticks: {
          font: {
            size: 10,

          },
          color: textColors.lightgrey,
          callback: function (val: any, index: any) {
            const ticks = [0, 20, 40, 60, 80, 100];
            if (ticks.includes(val)) {
              return val;
            }
          },
          stepSize: 5,
        },
      },

      x: {
        grid: {
          drawOnChartArea: false,
        },

        ticks: {
          font: {
            size: 10, // Decrease the font size as needed
          },
          color: textColors.lightgrey,
        },
      },
    },
  };
};

const generateMonthSequence = (startDate: Date, endDate: Date) => {
  let dates = [];

  let currentDate = new Date(startDate.getTime());
  while (currentDate < endDate) {
    dates.push(new Date(currentDate));

    currentDate.setDate(1); // Temporarily set to first day of the month to prevent skipping
    currentDate.setMonth(currentDate.getMonth() + 1);
    currentDate = lastDayOfMonth(currentDate);
  }
  if (dates[dates.length - 1].getMonth() != endDate.getMonth()) {
    dates.push(endDate);
  }

  return dates;
};

const usePrepareAlleyesRatingsHistoryData = (
  alleyeshistory: AlleyesRating[],
  date: Date
): any => {
  if (alleyeshistory == undefined) {
    return { labels: [], datasets: [] };
  }

  const fullMonthSequence =
    alleyeshistory.length == 1
      ? [alleyeshistory[0].date]
      : generateMonthSequence(
        alleyeshistory[0].date,
        alleyeshistory[alleyeshistory.length - 1].date
      );

  // Extract dates and ratings
  const labels = fullMonthSequence.map(
    (date) => germanMonths[date.getMonth()] + " " + (date.getFullYear() % 100)
  );

  const ratings: (number | undefined)[] = [];
  const completeRatings: (boolean | undefined)[] = [];

  fullMonthSequence.forEach((month) => {
    const historyEntry = alleyeshistory.find((entry) => {
      const entryDate = new Date(entry.date);
      return (
        entryDate.getMonth() === month.getMonth() &&
        entryDate.getFullYear() === month.getFullYear()
      );
    });

    ratings.push(historyEntry ? historyEntry.ratingCurrent : undefined);
    completeRatings.push(historyEntry ? historyEntry.completeRating : true); // Assuming true as default
  });

  const labelToHighlight =
    germanMonths[date.getMonth()] + " " + (date.getFullYear() % 100);

  return {
    labels: labels,
    datasets: [
      {
        label: "Alleyes Rating History",
        data: ratings,
        borderColor: textColors.primary,
        backgroundColor: "transparent",
        spanGaps: true,
        cubicInterpolationMode: "monotone",
        fill: true,
        borderWidth: 1.5,
        pointBorderWidth: 1,

        pointRadius: (context: any) =>
          context.chart.data.labels[context.dataIndex] === labelToHighlight ||
            completeRatings[context.dataIndex]
            ? 4
            : 3,
        pointBackgroundColor: (context: any) =>
          context.chart.data.labels[context.dataIndex] === labelToHighlight
            ? colorsSecondary.accent
            : completeRatings[context.dataIndex]
              ? textColors.primary
              : "rgba(0, 0, 0,0.0)",
        pointBorderColor: (context: any) =>
          context.chart.data.labels[context.dataIndex] === labelToHighlight
            ? colorsSecondary.accent
            : completeRatings[context.dataIndex]
              ? textColors.primary
              : textColors.primary,

        animation: {
          duration: 0,
        },
      },
    ],
  };
};

export default usePrepareAlleyesRatingsHistoryData;
