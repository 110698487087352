import {
  defineStyle,
  extendTheme,
  createMultiStyleConfigHelpers,
} from "@chakra-ui/react";
import { colorsPrimary, colorsSecondary, textColors } from "../colorStyles";
import { accordionTheme } from "../components/search/filterComponents/AccordionTheme";
import { colors } from "@mui/material";
import { tabsAnatomy, checkboxAnatomy } from "@chakra-ui/anatomy";

// Tabs Theme
const { definePartsStyle: definePartsStyleTabs, defineMultiStyleConfig: defineMultiStyleConfigTabs } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

const baseStyleTabs = definePartsStyleTabs({
  root: {
    display: "flex",
    flexDir: "column",
  },
  tab: {
    border: "2px solid",
    borderColor: "transparent",
    fontWeight: "semibold",
    color: textColors.primary,
    _selected: {
      color: colorsSecondary.accent,
      bg: colorsSecondary.cardBackground,
      borderColor: "transparent",
    },
    mr: "2px",
  },
  tabpanel: {
    pr: 0,
    pl: 0,
    pb: 0,
    pt: 3,
  },
  tablist: {
    borderBottom: "none",
    borderColor: "inherit",
  },
});

const tabsTheme = defineMultiStyleConfigTabs({ baseStyle: baseStyleTabs });

const {
  definePartsStyle: definePartsStyleCheckbox,
  defineMultiStyleConfig: defineMultiStyleConfigCheckbox,
} = createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const checkboxBaseStyle = definePartsStyleCheckbox({
  control: defineStyle({
    _checked: {
      bg: colorsSecondary.accent,
      borderColor: colorsSecondary.accent,
    },
    _hover: {
      _checked: {
        bg: colorsSecondary.accent,
        borderColor: colorsSecondary.accent,
      },
    },
  }),
});

const checkboxTheme = defineMultiStyleConfigCheckbox({
  baseStyle: checkboxBaseStyle,
});

const {
  definePartsStyle: definePartsStyleRadio,
  defineMultiStyleConfig: defineMultiStyleConfigRadio,
} = createMultiStyleConfigHelpers(["container", "control", "label"]);

const radioBaseStyle = definePartsStyleRadio({
  control: defineStyle({
    _checked: {
      bg: colorsSecondary.accent,
      borderColor: colorsSecondary.accent,
      _hover: {
        bg: colorsSecondary.accent,
        borderColor: colorsSecondary.accent,
      },
    },

  }),
});

const radioTheme = defineMultiStyleConfigRadio({
  baseStyle: radioBaseStyle,
});

const theme = extendTheme({
  fonts: {
    heading: "Text_Bold",
    body: "Text_Regular",
  },
  styles: {
    global: {
      body: {
        fontFamily: "Text_Regular",
      },
    },
  },
  colors: {
    custom: {
      50: "#e3f2f9",
      100: "#c5e4f3",
      200: "#a2d4ec",
      300: "#7ac1e4",
      400: "#47a9da",
      500: "#0088cc",
      600: "#007ab8",
      700: "#006ba1",
      800: "#005885",
      900: "#003f5e",
    },
  },
  components: {
    Checkbox: checkboxTheme,
    Radio: radioTheme,
    Tabs: tabsTheme,
    Accordion: accordionTheme,
    Button: {
      baseStyle: {},
      variants: {
        base: {},
        secondary: {},
        outline: {
          _hover: {
            bg: "",
          },
        },
      },
      defaultProps: {
        variant: "base",
      },
    },
  },
});

export default theme;