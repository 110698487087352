import { PhoneIcon } from "@chakra-ui/icons";
import { Badge, Box, Circle, Icon, Text } from "@chakra-ui/react";
import { MdOutlineCompareArrows } from "react-icons/md";
import { ReactComponent as SpielervergleichIcon } from "../../assets/spielervergleich_white.svg";
import { colorsSecondary } from "../../colorStyles";

interface Props {
  comparisonCount: number;
}
const ComparisonNavbarIcon = ({ comparisonCount }: Props) => {
  return (
    <Box
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center" // This centers horizontally.
      height="100%"
    >
      <Icon
        as={SpielervergleichIcon}
        style={{ width: "29px", height: "auto" }}
      />

      {comparisonCount > 0 && (
        <Circle
          size="23px"
          bg={colorsSecondary.accent}
          color="white"
          position="absolute"
          top={-2}
          right={-6}
        >
          <Text fontSize="sm">{comparisonCount}</Text>
        </Circle>
      )}
    </Box>
  );
};

export default ComparisonNavbarIcon;
