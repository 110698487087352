import { colors } from "@mui/material";
import { colorsSecondary, textColors } from "../colorStyles";
import { Rating } from "../generated";
import { germanMonths } from "../util/utilFunctions";
import { text } from "d3";
import { borderColor } from "@mui/system";

export const options = {
  responsive: true,
  interaction: {
    mode: "nearest" as "nearest",
    intersect: false,
  },

  plugins: {
    innerLabel: false,

    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      displayColors: true, // This line prevents the color box from showing next to the label

      callbacks: {
        label: function (context: any) {
          return context.dataset.label + ": " + context.formattedValue;
        },
        // title: function (context: any) {
        //   return context.label;
        // },
      },
    },
  },
  scales: {
    y: {
      min: 0,
      max: 100,
      grid: {
        drawOnChartArea: true,
        color: textColors.lightgrey,
      },
      ticks: {
        color: textColors.lightgrey,
      }
    },

    x: {
      grid: {
        drawOnChartArea: false,
      },

      ticks: {
        color: textColors.lightgrey,
      }
    },
  },
};

function getMonthsThreeYearsBack(fromDate: Date): string[] {
  const result: string[] = [];
  for (let i = 0; i < 36; i++) {
    // 36 months for 3 years
    const monthName = germanMonths[fromDate.getMonth()];
    const year = fromDate.getFullYear() % 100;
    result.push(`${monthName} ${year}`);
    fromDate.setMonth(fromDate.getMonth() - 1);
  }
  return result.reverse(); // we want it from oldest to newest
}

const transformData = (ratings: Rating[], label: string, date: Date): any => {
  const labels = getMonthsThreeYearsBack(new Date()); // get the months for the last 3 years
  const values: (number | null)[] = new Array(36).fill(null); // Initialize array with nulls

  ratings.forEach((entry) => {
    const date = new Date(entry.date);
    const month = germanMonths[date.getMonth()];
    const year = date.getFullYear() % 100;
    const formattedDate = `${month} ${year}`;
    const index = labels.indexOf(formattedDate);
    if (index !== -1) {
      values[index] = entry.value; // replace null with value if exists
    }
  });

  const labelToHighlight =
    germanMonths[date.getMonth()] + " " + (date.getFullYear() % 100);

  return {
    labels,
    datasets: [
      {
        label: label,
        data: values,
        borderColor: colorsSecondary.accent,
        // backgroundColor: colorsSecondary.accent,
        spanGaps: true,
        cubicInterpolationMode: "monotone",
        borderWidth: 2,
        pointBorderWidth: 1,

        pointRadius: (context: any) =>
          context.chart.data.labels[context.dataIndex] === labelToHighlight
            ? 4
            : 3,
        pointBackgroundColor: (context: any) =>
          context.chart.data.labels[context.dataIndex] === labelToHighlight
            ? colorsSecondary.accentOrange
            : colorsSecondary.accent,
        pointBorderColor: (context: any) =>
          context.chart.data.labels[context.dataIndex] === labelToHighlight
            ? colorsSecondary.accentOrange
            : colorsSecondary.accent,
      },
    ],
  };
};

const usePrepareRatingHistoryData = (
  data: Rating[] | undefined,
  rating: string | undefined,
  date: Date | undefined
) => {
  if (data === undefined || rating === undefined || date === undefined)
    return null;

  return transformData(data, rating, date);
};

export default usePrepareRatingHistoryData;
