import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid-pro";
import { formatDateTime } from "../../../util/utilFunctions";
import DeleteIconShadowteams from "./DeleteIconShadowteamsOverview";
import DuplicateIcon from "./DuplicateIcon";
import EditIcon from "./EditIcon";
import FavoriteIconShadowteams from "./FavoriteIconShadowteams";
import { GuiTextType } from "../../../store/languageContext";
import { LanguageKeys } from "../../../language/LanguageKeys";

export const fieldToSortColumn = {
  stammdaten: "surname",
};

interface Props {
  onOpenEdit: () => void;
  onOpenDelete: () => void;
  handleClick: (shadowteamId: number) => void;
  guiText: GuiTextType;
}

const columns = ({
  onOpenEdit,
  onOpenDelete,
  handleClick,
  guiText
}: Props): GridColDef[] => {
  return [
    {
      field: "favorite",
      headerName: "",
      renderCell: (params) => (
        <FavoriteIconShadowteams
          shadowteamId={params.row.shadowteamId}
          favorite={params.row.favorite}
        />
      ),

      align: "center",
      disableColumnMenu: true,
      sortable: true,
      flex: 0.1,
      valueGetter: (params: GridValueGetterParams) => params.row.favorite,
    },
    {
      field: "shadowteamName",
      headerName: guiText[LanguageKeys.Name],
      align: "left",
      disableColumnMenu: true,
      sortable: true,
      flex: 1.5,
      cellClassName: "hoverSelect",
      renderCell: (params) => (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => handleClick(params.row.shadowteamId)}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "formation",
      headerName: guiText[LanguageKeys.SchattenmannschaftenFormation],
      align: "left",
      disableColumnMenu: true,
      sortable: true,
      flex: 0.6,
    },

    {
      field: "lastChanged",
      headerName: guiText[LanguageKeys.SpielerprofilZuletztGeandert],
      // type: "date",
      // valueGetter: (params: GridValueGetterParams) =>
      //   new Date(params.row.lastChanged),
      flex: 1,
      // align: "center",
      // headerAlign: "center",
      disableColumnMenu: true,
      renderCell: (params) => {
        return formatDateTime(new Date(params.value));
      },
    },
    {
      field: "noOfPlayers",
      headerName: guiText[LanguageKeys.SchattenmannschaftenAnzahlSpieler],
      align: "center",
      headerAlign: "center",

      disableColumnMenu: true,
      sortable: true,
      flex: 0.8,
    },
    {
      field: "sharedWithNames",
      headerName: guiText[LanguageKeys.SchattenmannschaftenGeteiltMit],
      valueGetter: (params) =>
        params.row.sharedWithNames.length == 0
          ? "-"
          : params.row.sharedWithNames.join(", "),
      flex: 3,
      align: "left",
      headerAlign: "left",
      disableColumnMenu: true,
    },

    {
      field: "userName",
      headerName: guiText[LanguageKeys.SchattenmannschaftenAutor],
      align: "left",
      disableColumnMenu: true,
      sortable: true,
      flex: 1,
    },

    {
      field: "edit",
      headerName: "",
      renderCell: (params) => (
        <EditIcon shadowteamOverview={params.row} onOpenModal={onOpenEdit} />
      ),

      align: "center",
      disableColumnMenu: true,
      sortable: false,
      flex: 0.1,
    },

    {
      field: "duplicate",
      headerName: "",
      renderCell: (params) => <DuplicateIcon shadowteam={params.row} />,

      align: "center",
      disableColumnMenu: true,
      sortable: false,
      flex: 0.1,
    },
    {
      field: "delete",
      headerName: "",
      renderCell: (params) => (
        <DeleteIconShadowteams
          shadowteam={params.row}
          onOpenModal={onOpenDelete}
        />
      ),

      align: "center",
      disableColumnMenu: true,
      sortable: false,
      flex: 0.1,
    },
  ];
};

export default columns;
