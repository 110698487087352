export enum LanguageKeys {
  LanguageGerman = "languageGerman",
  LanguageEnglish = "languageEnglish",
  LanguageSpanish = "languageSpanish",
  WachlistButtonAdd = "WachlistButtonAdd",
  SidebarHome = "sidebarHome",
  SidebarSpielersuche = "sidebarSpielersuche",
  SidebarWatchlist = "sidebarWatchlist",
  SidebarSchattenmannschaften = "sidebarSchattenmannschaften",
  SidebarSpielervergleich = "sidebarSpielervergleich",
  SearchBarSpielernamenEingeben = "SearchBarSpielernamenEingeben",
  Greeting = "Glückauf,..!",
  HomePriorityPlayers = "PriorityPlayers",
  HomeNewPlayers = "NewPlayers",
  HomeMissingReviews = "MissingReviews",
  KurzfristigerTrend = "KurzfristigerTrend",
  LangfristigerTrend = "LangfristigerTrend",
  SearchBarFilter = "Filter",
  SearchBarFilterSpeichern = "Filterspeichern",
  SearchBarZurucksetzen = "Zurücksetzen",
  SearchBarGespeicherteFilterungAuswahlen = "GespeicherteFilterungauswählen",
  SearchBarFilterAusgewahlt = "{0}Filterausgewählt,{1}Spielergefunden",
  SearchBarVergleichen = "Vergleichen",
  SearchBarDieserSpielerIstNochNichtInDeinerWatchlist = "DieserSpieleristnochnichtinderWatchlist",
  SearchBarDieserSpielerIstNochInKeinerSchattenmannschaft = "DieserSpieleristnochinkeinerSchattenmannschaft",
  SearchBarZurWatchlistHinzufugen = "ZurWatchlisthinzufügen",
  SearchBarZurSchattenmannschaftHinzufugen = "ZurSchattenmannschafthinzufügen",
  SearchBarZurWatchlistOderSchattenmannschaftHinzufugen = "ZurWatchlistoderSchattenmannschafthinzufügen",
  SearchBarSchliessen = "Schließen",
  SearchBarSpielerAktuellesRatingNurMitAktuellemRating = "Spieler:AktuellesRating(NurmitaktuellemRating)",
  SearchBarSpielerAlle = "Spieler:Alle",
  SearchBarSpielerKeinAktuellesRatingNurOhneAktuellesRating = "Spieler:KeinaktuellesRating(NurohneaktuellesRating)",
  SearchBarPosition = "Position",
  SearchBarAlter = "Alter",
  SearchBarVerein = "Verein",
  SearchBarLiga = "Liga",
  SearchBarNationalitat = "Nationalität",
  SearchBarKorpergrobe = "Körpergröße",
  SearchBarStarterFuß = "StarkerFuß",
  Links = "Links",
  Rechts = "Rechts",
  Beide = "Beide",
  SearchBarMarktwert = "Marktwert",
  SearchBarVertragsendeBis = "Vertragsendebis",
  SearchBarVertragsakquisationen = "Vertragsoptionen",
  SearchBarKeineKaufoption = "KeineKaufoption",
  SearchBarKeineKaufpflichtMitBedingungen = "KeineKaufpflichtmitBedingungen",
  SearchBarLeihe = "Leihe",
  SearchBarNurVollstandigeRatings = "NurvollständigeRatings",
  SearchBarSubjektiveRatings = "SubjektiveRatings",
  SearchBarGesamtbewertungAktuell = "GesamtbewertungAktuell",
  SearchBarGesamtbewertungPotential = "GesamtbewertungPotential",
  SearchBarSpielbewertungAktuell = "SpielbewertungAktuell",
  SearchBarSpielbewertungPotential = "SpielbewertungPotential",
  SearchBarRatingsOberkategorien = "RatingsOberkategorien",
  SearchBarAlleyesAktuell = "AlleyesAktuell",
  SearchBarAlleyesPotential = "AlleyesPotential",
  SearchBarAlleyesVorjahr = "AlleyesVorjahr",
  SearchBarAlleyesVorjahr2 = "AlleyesVorjahr2",
  SearchBarTrendKurzfristig = "Trendkurzfristig",
  SearchBarTrendLangfristig = "Trendlangfristig",
  SearchBarSportlicheDatenRatingsDetailliert = "SportlicheDatenRatingsdetailliert",
  SpielerprofilAthletisch = "Athletisch",
  SpielerprofilDistanz = "Distanz",
  SpielerprofilAktivitatInBallbesitz = "AktivitätinBallbesitz",
  SpielerprofilAktivitatGegenDenBall = "AktivitätgegendenBall",
  SpielerprofilIntensitatInBallbesitz = "IntensitätinBallbesitz",
  SpielerprofilIntensitatGegenDenBall = "IntensitätgegendenBall",
  SpielerprofilSprints = "Sprints",
  SpielerprofilTempolaufe = "Tempoläufe",
  SpielerprofilExplosivitat = "Explosivität",
  SpielerprofilMaxSpeed = "MaxSpeed",
  SpielerprofilTechnischPassenScoren = "TechnischPassenScoren",
  TechnischnPS = "TechnischnPS",
  SpielerprofilAnzahlPasse = "AnzahlPässe",
  SpielerprofilPassqualitat = "Passqualität",
  SpielerprofilPasseeunterMittleremDruck = "PasseeinuntermittleremDruck",
  SpielerprofilPasseeunterHohemDruck = "PasseeinunterhohemDruck",
  SpielerprofilFlankenqualitat = "Flankenqualität",
  SpielerprofilAnzahlSchusse = "AnzahlSchüsse",
  SpielerprofilChancenqualitat = "Chancenqualität",
  SpielerprofilAbschlussqualitat = "Abschlussqualität",
  SpielerprofilBessermacher = "Bessermacher",
  SpielerprofilTechnischDuelle = "Technisch-Duelle",
  TechnischnDuelle = "TechnischnDuelle",
  SpielerprofilDribbling = "Dribbling",
  SpielerprofilBodenzweikampfInBallbesitz = "BodenzweikampfinBallbesitz",
  SpielerprofilLuftzweikampfInBallbesitz = "LuftzweikampfinBallbesitz",
  SpielerprofilBallbehauptungUnterMittleremDruck = "BallbehauptunguntermittleremDruck",
  SpielerprofilBallbehauptungUnterHohemDruck = "BallbehauptungunterhohemDruck",
  SpielerprofilAbwehrGegenDribbling = "AbwehrgegenDribbling",
  SpielerprofilBodenzweikampfGegenDenBall = "BodenzweikampfgegendemBall",
  SpielerprofilLuftzweikampfGegenDenBall = "LuftzweikampfgegendemBall",
  SpielerprofilZweiteBalle = "2.Bälle",
  SpielerprofilTaktischFreilaufverhalten = "Taktisch-Freilaufverhalten",
  TaktischnRun = "TaktischnRun",
  SpielerprofilAbkippen = "Abkippen",
  SpielerprofilNachrucken = "Nachrücken",
  SpielerprofilEntgegenkommen = "Entgegenkommen",
  SpielerprofilHinterlaufen = "Hinterlaufen",
  SpielerprofilLaufInDieHalbspur = "LaufindieHalbspur",
  SpielerprofilFreilaufenInDieBreite = "FreilaufenindieBreite",
  SpielerprofilFreilaufenVorDerGegnerischenKette = "FreilaufenvordergegnerischenKette",
  SpielerprofilTiefenlaufeHinterDieKette = "TiefenläufehinterdieKette",
  SpielerprofilLaufInDieBox = "LaufindieBox",
  SpielerprofilTaktischPassverhalten = "Taktisch-Passverhalten",
  TaktischnPass = "TaktischnPass",
  SpielerprofilPassZumAbkippenden = "PasszumAbkippenden",
  SpielerprofilPassZumNachruckenden = "PasszumNachrückenden",
  SpielerprofilPassZumEntgegenkommenden = "PasszumEntgegenkommenden",
  SpielerprofilPassZumHinterlaufenden = "PasszumHinterlaufenden",
  SpielerprofilPassInDieHalbspur = "PassindieHalbspur",
  SpielerprofilPassInDieBreite = "PassindieBreite",
  SpielerprofilPassVorDerGegnerischenKette = "PassvordergegnerischenKette",
  SpielerprofilPassHinterDieKette = "PasshinterdieKette",
  SpielerprofilFlankeInDieBox = "FlankeindieBox",
  SpielerprofilPolarchartsRatings = "Ratings",
  SpielerprofilGeburtsdatum = "Geburtsdatum",
  SpielerprofilVerletzungstage = "Verletzungstage",
  SpielerprofilBerater = "Berater",
  SpielerprofilZumWyScoutProfil = "ZumWyScoutProfil",
  SpielerprofilZum = "Zum",
  SpielerprofilProfil = "Profil",
  SpielerprofilMonateDeutsch = "Monatedeutsch(bspw.Okt)",
  SpielerprofilSubjektiveBewertungen = "SubjektiveBewertungen",
  SpielerprofilSpieler = "Spieler",
  SpielerprofilEventdatum = "Eventdatum",
  SpielerprofilSpiel = "Spiel",
  SpielerprofilScout = "Scout",
  SpielerprofilOverallRating = "OverallRating",
  SpielerprofilPotentialRating = "PotentialRating",
  SpielerprofilJahrgangstyp = "Jahrgangstyp",
  SpielerprofilZuletztGeandert = "Zuletztgeändert",
  SpielerprofilPositionImReport = "PositionimReport",
  SpielerprofilSpiele = "Spiele",
  SpielerprofilMinuten = "Minuten",
  SpielerprofilTransferhistorie = "Transferhistorie",
  SpielerprofilTransferMarktwerthistorie = "Transfer-/Marktwerthistorie",
  SpielerprofilMarkthistorie = "Markthistorie",
  SpielerprofilAktuellerMarktwert = "AktuellerMarktwert",
  SpielerprofilMaximalerMarktwert = "MaximalerMarktwert",
  SpielerprofilSaison = "Saison",
  SpielerprofilDatum = "Datum",
  SpielerprofilAbgebenderVerein = "AbgebenderVerein",
  SpielerprofilAufnehmenderVerein = "AufnehmenderVerein",
  SpielerprofilVerletzungshistorie = "Verletzungshistorie",
  SpielerprofilStartVerletzung = "StartVerletzung",
  SpielerprofilEndeVerletzung = "EndeVerletzung",
  SpielerprofilDauer = "Dauer",
  SpielerprofilVerletzung = "Verletzung",
  SpielerprofilTorschuss = "Torschuss",
  SpielerprofilHeatmap = "Heatmap",
  SpielerprofilFuß = "Fuß",
  SpielerprofilKopf = "Kopf",
  SpielerprofilTor = "Tor",
  SpielerprofilVersuch = "Versuch",
  SpielerprofilLowxG = "lowxG",
  SpielerprofilHighxG = "highxG",
  SpielerprofilPreShot = "PreShot",
  SpielerprofilPostShot = "PostShot",
  SpielerprofilKommentare = "Kommentare",
  SpielerprofilKeineKommentareVorhanden = "KeineKommentarevorhanden",
  SpielerprofilSpeichern = "Speichern",
  SpielerprofilFugeEinenKommentarHinzu = "FügeeinenKommentarhinzu...",
  SpielerprofilAhnlicheSpieler = "ÄhnlicheSpieler",
  SpielerprofilGewichtung = "Gewichtung",
  SpielerprofilStammdaten = "Stammdaten",
  SpielerprofilAhnlichkeit = "Ähnlichkeit",
  SpielerprofilSpalten = "Spalten",
  SpielerprofilZeilenProSeite = "ZeilenproSeite",
  SpielerprofilVideo = "Video",
  SpielerprofilVideoNichtVerfugbar = "Videonichtverfügbar",
  KeinVideovorhanden = "KeinVideovorhanden",
  WatchlistAnzahlSichtungen = "AnzahlSichtungen",
  WatchlistLetzteSichtung = "LetzteSichtung",
  WatchlistHinzugefugtAm = "Hinzugefügtam",
  SchattenmannschaftenFormation = "Formation",
  SchattenmannschaftenZumSpielerprofil = "ZumSpielerprofil",
  SchattenmannschaftenSpielerEntfernen = "Spielerentfernen",
  SchattenmannschaftenMarkierungWahlen = "Markierungwählen",
  SchattenmannschaftenAnzahlSpieler = "AnzahlSpieler",
  SchattenmannschaftenGeteiltMit = "Geteiltmit",
  SchattenmannschaftenBank = "Bank",
  SchattenmannschaftenAutor = "Autor",
  SpielervergleichWarenkorb = "Warenkorb",
  Glossar = "Glossar",
  KeinZugriff = "KeinZugriff",
  DieseSchattenmannschaftExistiertNicht = "DieseSchattenmannschaftExistiertNicht",
  OderDuHastKeinenZugriff = "OderDuHastKeinenZugriff",
  NeueSchattenmannschaftErstellen = "NeueSchattenmannschaftErstellen",
  SpielerHinzufugen = "Spieler hinzufügen",
  JetztVergleichen = "JetztVergleichen",
  KeineSpielerimVergleich = "KeineSpielerimVergleich",
  SpielerimVergleich = "SpielerimVergleich",
  DatenWerdenGeladen = "DatenWerdenGeladen",
  Datenkonntennichtgeladenwerden = "Datenkonntennichtgeladenwerden",
  Erstellungsdatum = "Erstellungsdatum",
  KeineTorschussdatenvorhanden = "KeineTorschussdatenvorhanden",
  KeineHeatmapdatenvorhanden = "KeineHeatmapdatenvorhanden",
  KeineVerletzungsdatenvorhanden = "KeineVerletzungsdatenvorhanden",
  KeineDatenvorhanden = "KeineDatenvorhanden",
  Gesamt = "Gesamt",
  Gesamtrating = "Gesamtrating",
  WarnungVergleichePositionsbezogen = "WarnungVergleichePositionsbezogen",
  SpielerinSchattenmannschaftAufPositionOhneDaten = "SpielerinSchattenmannschaftAufPositionOhneDaten",
  SpielerKannNichtHinzugefuegtWerden = "SpielerKannNichtHinzugefuegtWerden",
  SpielerbereitsinSchattenmannschaft = "SpielerbereitsinSchattenmannschaft",
  Achtung = "Achtung",
  GesamtbewertungA = "GesamtbewertungA",
  GesamtbewertungP = "GesamtbewertungP",
  SpielbewertungA = "SpielbewertungA",
  SpielbewertungP = "SpielbewertungP",
  Loeschen = "Loeschen",
  Vertragsende = "Vertragsende",
  Schattenmannschaft = "Schattenmannschaft",
  ExportKannMinutenDauern = "ExportKannMinutenDauern",
  GenerierePDF = "GenerierePDF",
  ExportFehlgeschlagen = "ExportFehlgeschlagen",
  KeineGesamtbewertungVorhanden = "KeineGesamtbewertungVorhanden",
  KeineSpielbewertungVorhanden = "KeineSpielbewertungVorhanden",
  Gesamtbewertung = "Gesamtbewertung",
  Spielbewertung = "Spielbewertung",
  KeineMarktdatenVorhanden = "KeineMarktdatenVorhanden",
  Abloese = "Abloese",
  KeineTransferdatenVorhanden = "KeineTransferdatenVorhanden",
  RatingsBasierenAufWenigerAls900Spielminuten = "RatingsBasierenAufWenigerAls900Spielminuten",
  Jahre = "Jahre",
  Wettbewerb = "Wettbewerb",
  Tage = "Tage",
  Karriere = "Karriere",
  Ja = "Ja",
  Nein = "Nein",
  DatenBeziehenSichAufDasLetzteJahr = "DatenBeziehenSichAufDasLetzteJahr",
  Aktuell = "Aktuell",
  Potential = "Potential",
  Viererkette = "Viererkette",
  Dreierkette = "Dreierkette",
  AlleSpielerzumVergleichHinzufuegen = "AlleSpielerzumVergleichHinzufuegen",
  Name = "Name",
  BitteWaehleEineFormationAus = "BitteWaehleEineFormationAus",
  Benutzername = "Benutzername",
  Zugriff = "Zugriff",
  Lesen = "Lesen",
  Schreiben = "Schreiben",
  Erstellen = "Erstellen",
  Abbrechen = "Abbrechen",
  TeilenMit = "TeilenMit",
  SchattenmannschaftLoeschen = "SchattenmannschaftLoeschen",
  WillstDuDieSchattenmannschaft = "WillstDuDieSchattenmannschaft",
  WirklichLoeschen = "WirklichLoeschen",
  GibEinenUsernamenEin = "GibEinenUsernamenEin",
  KeinenBenutzerGefunden = "KeinenBenutzerGefunden",
  Spielerdetails = "Spielerdetails",
  Login = "Login",
  Anmelden = "Anmelden",
  Abmelden = "Abmelden",
  WaehleEineSchattenmannschaft = "WaehleEineSchattenmannschaft",
  KeineSpielerGefunden = "KeineSpielerGefunden",
  Alle = "Alle",
  AktuellesRating = "AktuellesRating",
  AktuellesRatingShort = "AktuellesRatingShort",
  KeinAktuellesRating = "KeinAktuellesRating",
  KeinAktuellesRatingShort = "KeinAktuellesRatingShort",
  PDFExport = "PDFExport",
  KeineGespeichertenFilterVorhanden = "KeineGespeichertenFilterVorhanden",
  NameFilterung = "NameFilterung",
  ExistiertBereitsDurchSpeichernWirdEsUeberschrieben = "ExistiertBereitsDurchSpeichernWirdEsUeberschrieben",
  MinMussKleinerAlsMaxWertSein = "MinMussKleinerAlsMaxWertSein",
  WerteMuessenZwischen = "WerteMuessenZwischen",
  Und = "Und",
  Liegen = "Liegen",
  SpielerGefunden = "SpielerGefunden",
  FilterAusgewaehlt = "Filter ausgewählt",
  SchattenmannschaftBearbeiten = "SchattenmannschaftBearbeiten",
  Standardformation = "Standardformation",
  Fehler = "Fehler",
  Beim_BeimSpeichernFehlerAufgetreten = "Beim_BeimSpeichernFehlerAufgetreten",
  Speichern_BeimSpeichernFehlerAufgetreten = "Speichern_BeimSpeichernFehlerAufgetreten",
  Updaten_BeimUpdatenFehlerAufgetreten = "Updaten_BeimUpdatenFehlerAufgetreten",
  DeinesFiltersIstEinFehlerAufgetreten = "DeinesFehlersIstEinFehlerAufgetreten",
  DeinFilterWurdeErfolgreich = "DeinFilterWurdeErfolgreich",
  Geupdatet = "Geupdatet",
  Gespeichert = "Gespeichert",
  WaehleEineSchattenmannschaftUndEinePositionAus = "WaehleEineSchattenmannschaftUndEinePositionAus",
  DieserSpielerIstBereitsInFolgendenSchattenmannschaften = "DieserSpielerIstBereitsInFolgendenSchattenmannschaften",
  VonderWatchlistEntfernen = "VonderWatchlistEntfernen",
  DieserSpielerIstBereitsInDeinerWatchlist = "DieserSpielerIstBereitsInDeinerWatchlist",
  EsKoennenNurSpielerHinzugefuegtWerdenZuDenenPositionsdatenExistieren = "EsKoennenNurSpielerHinzugefuegtWerdenZuDenenPositionsdatenExistieren",
  DieserSpielerIstBereitsImWarenkorb = "DieserSpielerIstBereitsImWarenkorb",
  SpielerErfolgreichZurWatchlistHinzugefuegt = "SpielerErfolgreichZurWatchlistHinzugefuegt",
  Keine = "Keine",
  EsIstKeinePositionAusgewaehlt = "EsIstKeinePositionAusgewaehlt",
  SortierenNach = "SortierenNach",
  WenigerAnzeigen = "WenigerAnzeigen",
  MehrAnzeigen = "MehrAnzeigen",
  Bis = "bis",
  Ab = "ab",
  ErrorForbidden = "ErrorForbidden",
  ErrorGeneral = "ErrorGeneral",
}
