import {
  Center,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { useScoutingReports } from "../../../hooks/useScoutingReports";
import Report from "./Report";
import { LanguageContext } from "../../../store/languageContext";
import { LanguageKeys } from "../../../language/LanguageKeys";
import { textColors } from "../../../colorStyles";

const ScoutingReports = () => {
  const { guiText } = useContext(LanguageContext);

  const { id } = useParams();

  const {
    data: scoutingReports,
    isLoading,
    error,
  } = useScoutingReports(Number(id));

  const gameReports =
    scoutingReports?.filter(
      (report) => report.kategorie === "Spielbewertung"
    ) || [];
  const overallReports =
    scoutingReports?.filter(
      (report) => report.kategorie === "Gesamtbewertung"
    ) || [];

  return (
    <Tabs variant="baseStyle" mt={2} height="100%" color={textColors.primary}>
      <TabList h="50px" alignSelf="end" pb={1}>
        <Tab>{guiText[LanguageKeys.Gesamtbewertung]}</Tab>
        <Tab>{guiText[LanguageKeys.Spielbewertung]}</Tab>
      </TabList>
      <TabPanels flex={1} display="flex" overflow="auto" mb={1}>
        <TabPanel h="100%" w="100%">
          {overallReports.length > 0 ? (
            overallReports.map((report) => (
              <Report key={report.reportId} report={report}></Report>
            ))
          ) : (
            <Center h="100%" w="100%">
              <Text fontSize={20}>{guiText[LanguageKeys.KeineGesamtbewertungVorhanden]}</Text>
            </Center>
          )}
        </TabPanel>
        <TabPanel h="100%" w="100%">
          {gameReports.length > 0 ? (
            gameReports.map((report) => (
              <Report key={report.reportId} report={report}></Report>
            ))
          ) : (
            <Center h="100%" w="100%">
              <Text fontSize={20}>{guiText[LanguageKeys.KeineSpielbewertungVorhanden]}</Text>
            </Center>
          )}
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default ScoutingReports;
