import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  Icon,
  Input,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  Spacer,
  Text,
} from "@chakra-ui/react";
import React, { memo, useContext, useEffect, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { colorsPrimary, colorsSecondary, textColors } from "../../../colorStyles";
import { FilterSpecification } from "../../../generated";
import { filterOpacity } from "../searchStyles";
import { FilterValueStoreCore } from "../../../store/store";
import { LanguageContext } from "../../../store/languageContext";
import { LanguageKeys } from "../../../language/LanguageKeys";
import { LocaleKeys } from "../../../language/LocaleKeys";
import { getFilterSpecification } from "./ButtonGroupFilter";

interface Props {
  filter: FilterSpecification;
  filterValueFunc: FilterValueStoreCore;
}


interface Value {
  min: number;
  max: number;
}

const RangeSliderFilter = React.memo(({ filter, filterValueFunc }: Props) => {
  const { guiText, language } = useContext(LanguageContext);
  const filterSpecification = getFilterSpecification(filter, language);

  const parseStoredFilterValue = () => {
    const currentFilterValue = filterValueFunc.filterValues[filter.specId - 1];
    return [currentFilterValue != null
      ? (JSON.parse(currentFilterValue.value) as Value).min
      : filterSpecification.min,
    currentFilterValue != null
      ? (JSON.parse(currentFilterValue.value) as Value).max
      : filterSpecification.max,
    ];
  }

  const [filterValue, setFilterValue] = useState<any[]>(parseStoredFilterValue());

  const [editing, setEditing] = useState(false);
  useEffect(() => {
    if (!editing) {
      setFilterValue(parseStoredFilterValue());
    }
  }, [filterValueFunc.filterValues[filter.specId - 1]]);

  const [error, setError] = useState(false);
  const [change, setChange] = useState(false);

  useEffect(() => {
    if (
      filterValue[0] === filterSpecification.min &&
      filterValue[1] === filterSpecification.max
    ) {
      filterValueFunc.removeFilter(filter.specId);
    } else if (!error) {
      filterValueFunc.addFilter({
        specId: filter.specId,
        value: `{"min":${filterValue[0]}, "max":${filterValue[1]}}`,
      });
    }
  }, [change]);

  const handleChangeEnd = () => {
    setEditing(false);
    setChange(!change);
  };

  const handleInputChange = (index: number, value: string) => {
    const newValue = value === "" ? "" : parseInt(value.replace(/\./g, ""));

    if (value === "" || !isNaN(newValue as number)) {
      const newFilterValues = [...filterValue];
      newFilterValues[index] = newValue;

      if (index === 0) {
        if (
          typeof newValue === "number" &&
          (newValue > filterValue[1] || newValue < filterSpecification.min)
        ) {
          setError(true);
        } else {
          setError(false);
        }
      } else {
        if (
          typeof newValue === "number" &&
          (newValue < filterValue[0] || newValue > filterSpecification.max)
        ) {
          setError(true);
        } else {
          setError(false);
        }
      }

      setFilterValue(newFilterValues);
      if (value !== "") {
        setChange(!change);
      }
    }
  };

  const active =
    filterValue[0] !== filterSpecification.min ||
    filterValue[1] !== filterSpecification.max;

  return (
    <Box opacity={active ? filterOpacity.active : filterOpacity.notActive} data-cy={"rangeSliderFilter"}>
      <HStack marginBottom={2} justifyContent="space-between">
        <Text
          fontSize="md"
          color={textColors.primary}
        >
          {language == LocaleKeys.DE ? filter.labelGerman : language == LocaleKeys.EN ? filter.labelEnglish : filter.labelSpanish}
        </Text>
        <Icon
          as={AiFillCloseCircle}
          color="gray.500"
          style={{ cursor: "pointer" }}
          onClick={() => {
            filterValueFunc.removeFilter(filter.specId);
            setFilterValue([filterSpecification.min, filterSpecification.max]);
            setError(false);
          }}
          boxSize={4}
          data-cy={"resetButton"}
        ></Icon>
      </HStack>
      <RangeSlider
        aria-label={["min", "max"]}
        min={
          filterSpecification.max - filterSpecification.min > 1000000
            ? Math.log(filterSpecification.min)
            : filterSpecification.min
        }
        max={
          filterSpecification.max - filterSpecification.min > 1000000
            ? Math.log(filterSpecification.max)
            : filterSpecification.max
        }
        step={
          filterSpecification.step === -1
            ? (filterSpecification.max - filterSpecification.min) / 100
            : filterSpecification.max - filterSpecification.min > 1000000
              ? Math.log(filterSpecification.max - filterSpecification.min) / 100
              : filterSpecification.step
        }
        onChangeEnd={handleChangeEnd}
        onChange={(value) => {
          setError(false);
          setEditing(true);
          filterSpecification.max - filterSpecification.min > 1000000
            ? setFilterValue([
              Math.round(Math.exp(value[0])),
              Math.round(Math.exp(value[1])),
            ])
            : setFilterValue(value);
        }}
        value={
          filterSpecification.max - filterSpecification.min > 1000000
            ? [Math.log(filterValue[0]), Math.log(filterValue[1])]
            : filterValue
        }
      >
        <RangeSliderThumb boxSize={6} index={0} data-cy={"lowerHandle"}>
          <Box
            color={colorsSecondary.accent}
            as={ChevronLeftIcon}
            boxSize={5}
          />
        </RangeSliderThumb>

        <RangeSliderTrack bg="gray.200">
          <RangeSliderFilledTrack bg={colorsSecondary.accent} />
        </RangeSliderTrack>
        <RangeSliderThumb boxSize={6} index={1} data-cy={"upperHandle"}>
          <Box
            color={colorsSecondary.accent}
            as={ChevronRightIcon}
            boxSize={5}
          />
        </RangeSliderThumb>
      </RangeSlider>
      <FormControl isInvalid={error}>
        {!filterSpecification.hideNumbers && (
          <Flex>
            <Input
              variant="outline"
              value={filterValue[0].toLocaleString("de-DE")}
              onChange={(e) => handleInputChange(0, e.target.value)}
              size="sm"
              maxW="40%"
              textAlign="center"
              borderColor={error ? "red.500" : "inherit"}
              focusBorderColor={error ? "red.500" : "gray.300"}
              data-cy={"lowerInput"}
              color={textColors.primary}
            />
            <Spacer />

            <Input
              variant="outline"
              value={filterValue[1].toLocaleString("de-DE")}
              onChange={(e) => handleInputChange(1, e.target.value)}
              size="sm"
              maxW="40%"
              textAlign="center"
              borderColor={error ? "red.500" : "inherit"}
              focusBorderColor={error ? "red.500" : "gray.300"}
              data-cy={"upperInput"}
              color={textColors.primary}
            />
          </Flex>
        )}

        {filterSpecification.startLabel && filterSpecification.endLabel && (
          <Flex>
            <Text fontSize="sm">{filterSpecification.startLabel}</Text>
            <Spacer />

            <Text fontSize="sm">{filterSpecification.endLabel}</Text>
          </Flex>
        )}

        <FormErrorMessage>
          {filterValue[0] > filterValue[1]
            ? guiText[LanguageKeys.MinMussKleinerAlsMaxWertSein]
            : guiText[LanguageKeys.WerteMuessenZwischen] +
            filterSpecification.min.toLocaleString("de-DE") + " " +
            guiText[LanguageKeys.Und] + " " +
            filterSpecification.max.toLocaleString("de-DE") +
            guiText[LanguageKeys.Liegen]}
        </FormErrorMessage>
      </FormControl>
    </Box>
  );
});

export default memo(RangeSliderFilter);
