/* tslint:disable */
/* eslint-disable */
/**
 * alleyes SCOUT BACKEND
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AlleyesRating } from './AlleyesRating';
import {
    AlleyesRatingFromJSON,
    AlleyesRatingFromJSONTyped,
    AlleyesRatingToJSON,
} from './AlleyesRating';

/**
 * 
 * @export
 * @interface AlleyesRatingHistory
 */
export interface AlleyesRatingHistory {
    /**
     * 
     * @type {number}
     * @memberof AlleyesRatingHistory
     */
    subjectiveOverallRating?: number;
    /**
     * 
     * @type {number}
     * @memberof AlleyesRatingHistory
     */
    subjectiveOverallPotential?: number;
    /**
     * 
     * @type {number}
     * @memberof AlleyesRatingHistory
     */
    subjectiveGameRating?: number;
    /**
     * 
     * @type {number}
     * @memberof AlleyesRatingHistory
     */
    subjectiveGamePotential?: number;
    /**
     * 
     * @type {Array<AlleyesRating>}
     * @memberof AlleyesRatingHistory
     */
    alleyeshistory?: Array<AlleyesRating>;
}

/**
 * Check if a given object implements the AlleyesRatingHistory interface.
 */
export function instanceOfAlleyesRatingHistory(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AlleyesRatingHistoryFromJSON(json: any): AlleyesRatingHistory {
    return AlleyesRatingHistoryFromJSONTyped(json, false);
}

export function AlleyesRatingHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlleyesRatingHistory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subjectiveOverallRating': !exists(json, 'subjectiveOverallRating') ? undefined : json['subjectiveOverallRating'],
        'subjectiveOverallPotential': !exists(json, 'subjectiveOverallPotential') ? undefined : json['subjectiveOverallPotential'],
        'subjectiveGameRating': !exists(json, 'subjectiveGameRating') ? undefined : json['subjectiveGameRating'],
        'subjectiveGamePotential': !exists(json, 'subjectiveGamePotential') ? undefined : json['subjectiveGamePotential'],
        'alleyeshistory': !exists(json, 'alleyeshistory') ? undefined : ((json['alleyeshistory'] as Array<any>).map(AlleyesRatingFromJSON)),
    };
}

export function AlleyesRatingHistoryToJSON(value?: AlleyesRatingHistory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subjectiveOverallRating': value.subjectiveOverallRating,
        'subjectiveOverallPotential': value.subjectiveOverallPotential,
        'subjectiveGameRating': value.subjectiveGameRating,
        'subjectiveGamePotential': value.subjectiveGamePotential,
        'alleyeshistory': value.alleyeshistory === undefined ? undefined : ((value.alleyeshistory as Array<any>).map(AlleyesRatingToJSON)),
    };
}

