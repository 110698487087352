import { Box, Image, Text } from "@chakra-ui/react";
import { useKeycloak } from "@react-keycloak/web";
import React, { useContext } from "react";
import { AiOutlineHome, AiOutlineSearch } from "react-icons/ai";
import { FaEye } from "react-icons/fa";
import {
  Menu,
  menuClasses,
  MenuItem,
  MenuItemStyles,
  Sidebar,
} from "react-pro-sidebar";
import { NavLink, useLocation } from "react-router-dom";
import { ReactComponent as SchattenmannschaftIcon } from "../../assets/schattenmannschaft.svg";
import { ReactComponent as SpielervergleichIconGrey } from "../../assets/spielervergleich_highlight.svg";
import { ReactComponent as SpielervergleichIconBackground } from "../../assets/spielervergleich_background.svg";
import alleyesLogo from "../../assets/alleyes_Logo.svg";
import { colorsPrimary, colorsSecondary, textColors } from "../../colorStyles";
import { useSidebarCollapsedStore } from "../../store/store";
import roles from "../../util/roles";
import { hexToRgba } from "../../util/utilFunctions";
import { LanguageContext } from "../../store/languageContext";
import { LanguageKeys } from "../../language/LanguageKeys";
import { color } from "framer-motion";
import { text } from "d3";
import { is } from "cypress/types/bluebird";
const AllEyesSidebar: React.FC = () => {
  const { guiText } = useContext(LanguageContext);

  const location = useLocation();
  const isActive = (path: string) => location.pathname === path;

  const { collapsed, setCollapsed } = useSidebarCollapsedStore();
  const k = useKeycloak();
  const hasRoleSearchPlayers = k.keycloak.hasResourceRole(roles.search_players);

  const menuItemStyles: MenuItemStyles = {
    root: {
      fontSize: "15px",
      fontWeight: 400,
      textAlign: "left",
    },
    icon: {
      color: "white",
      [`&.${menuClasses.disabled}`]: {
        color: "white",
      },
      width: "40px",
      height: "40px",
      overflow: "visible",
      flexShrink: 0,
    },
    SubMenuExpandIcon: {
      color: "#b6b7b9",
    },

    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0
          ? hexToRgba("#FFFFFF", !collapsed ? 0.4 : 1)
          : "transparent",
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: "gray",
      },
      "&:hover": {
        backgroundColor: colorsSecondary.hover,
      },

      [`&.active`]: {
        backgroundColor: colorsSecondary.accent,
        color: "#FFFFFF",
        borderLeft: `3px solid ${colorsSecondary.cardBackground}`,
      },
      background: colorsSecondary.cardBackground,
      marginBottom: "5px",
      display: "flex",
    },
    label: ({ open, active }) => ({
      overflow: "visible",
      opacity: collapsed ? 0 : 1,
      visibility: collapsed ? "hidden" : "visible",
      transition: "opacity 0.5s ease, visibility 0.5s",
      whiteSpace: "nowrap",
      color: active ? colorsSecondary.background : textColors.primary,
    }),
  };

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        direction: "ltr",
        position: "fixed",
      }}
      onMouseEnter={() => setCollapsed(false)}
      onMouseLeave={() => setCollapsed(true)}
    >
      <Sidebar
        collapsed={collapsed}
        backgroundColor={hexToRgba(colorsSecondary.background, 1)}
        rootStyles={{
          color: "white",
          minWidth: "200px",
          width: "230px",
        }}
        collapsedWidth="80px"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            textAlign: "center",
          }}
        >
          <Box
            height="180px"
            display="flex"
            alignItems="flex-start"
            justifyContent="center"
          >
            <Image
              boxSize={collapsed ? "80px" : "170px"}
              objectFit="cover"
              src={alleyesLogo}
              alt="Alleyes"
              marginTop={5}
              transition="0.3s"
              style={{ shapeRendering: "crispEdges" }}
              data-cy={"logo"}
            />
          </Box>
          <div style={{ flex: 1, marginBottom: "32px" }}>
            <div style={{ padding: "0 24px", marginBottom: "8px" }}></div>
            <Menu menuItemStyles={menuItemStyles}>
              <MenuItem
                icon={
                  <AiOutlineHome
                    color={isActive("/") ? colorsSecondary.background : colorsSecondary.highlight3}
                    size={20}
                  />
                }
                component={<NavLink to="/" />}
                active={isActive("/")}
              >
                {guiText[LanguageKeys.SidebarHome]}
              </MenuItem>
              <MenuItem
                disabled={!hasRoleSearchPlayers}
                icon={
                  <AiOutlineSearch
                    size={20}
                    color={
                      isActive("/search") ? colorsSecondary.background : colorsSecondary.highlight3
                    }
                  />
                }
                component={<NavLink to="/search" />}
                active={isActive("/search")}
              >
                {guiText[LanguageKeys.SidebarSpielersuche]}
              </MenuItem>
              <MenuItem
                icon={
                  <FaEye
                    size={20}
                    color={
                      isActive("/watchlist")
                        ? colorsSecondary.background
                        : colorsSecondary.highlight3
                    }
                  />
                }
                component={<NavLink to="/watchlist" />}
                active={isActive("/watchlist")}
              >
                {guiText[LanguageKeys.SidebarWatchlist]}
              </MenuItem>

              <MenuItem
                icon={
                  <SchattenmannschaftIcon
                    style={{
                      stroke: isActive("/shadowTeams")
                        ? colorsSecondary.background
                        : colorsSecondary.highlight3,
                      fill: "none",
                      width: "14px",
                      height: "auto",
                    }}
                  />
                  // <RiTeamLine
                  //   size={20}
                  //   color={
                  //     isActive("/shadowTeams")
                  //       ? colorsSecondary.highlight1
                  //       : "white"
                  //   }
                  // />
                }
                component={<NavLink to="/shadowTeams" />}
                active={isActive("/shadowTeams")}
              >
                {guiText[LanguageKeys.SidebarSchattenmannschaften]}
              </MenuItem>

              <MenuItem
                icon={
                  // <MdOutlineCompareArrows
                  //   size={20}
                  //   color={
                  //     isActive("/compare")
                  //       ? colorsSecondary.highlight1
                  //       : "white"
                  //   }
                  // />
                  isActive("/compare") ? (
                    <SpielervergleichIconBackground
                      style={{
                        width: "30px",
                        height: "auto",
                      }}
                    />
                  ) : (
                    <SpielervergleichIconGrey
                      style={{
                        width: "30px",
                        height: "auto",
                      }}
                    />

                  )
                }
                component={<NavLink to="/compare" />}
                active={isActive("/compare")}
              >
                {guiText[LanguageKeys.SidebarSpielervergleich]}
              </MenuItem>
            </Menu>
          </div>
          <Text data-cy={"app-version"} pb={2}>V {window.REACT_APP_VERSION}</Text>
        </div>
      </Sidebar>
    </div>
  );
};

export default AllEyesSidebar;
