import { Player } from "../../generated";
import { Heading, Skeleton, Stack, Text } from "@chakra-ui/react";
import FontStyles from "../../fontStyles"; // Ensure FontStyles is correctly imported

// If FontStyles.Title_Bold is not a valid property, replace it with the correct one
// const correctFontStyle = "Title_Bold"; // Replace with the actual valid font family
import React from "react";
import { textColors } from "../../colorStyles";

interface Props {
  player?: Player;
  [x: string]: any;
}

const PlayerText = ({ player, ...rest }: Props) => {
  return (
    <Stack
      spacing="1"
      data-cy="player-text-root-container"
      {...rest}
    >
      <Skeleton isLoaded={player !== undefined}>
        <Heading
          fontFamily={"Text_Regular"}
          size="sm"
          data-cy="player-text-name-and-position"
          color={textColors.primary}

        >
          {player?.firstName +
            " " +
            player?.surname +
            "  |  " +
            player?.positionsGruppe}
        </Heading>

        <Text
          color={textColors.lightgrey}
          fontSize="sm"
          data-cy="player-text-league-and-team"
          fontStyle={"Text_Regular"}
        >
          {(player?.league ? player?.league.replace(/_/g, " ") : "") +
            (player?.league && player?.team ? " | " : "") +
            (player?.team ?? "")
          }
        </Text>

        <Text
          fontSize="sm"
          color={textColors.lightgrey}
          data-cy="player-text-age-and-height"
        >
          {(player?.age ? player?.age + " y.o." : "") +
            (player?.height && player?.age ? " | " : "") +
            (player?.height ? player?.height + " cm" : "")
          }
        </Text>
      </Skeleton>
    </Stack >
  );
};

export default PlayerText;
