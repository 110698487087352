import { VStack } from "@chakra-ui/react";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import { useMissingReviewPlayers } from "../../hooks/useHome";
import PlayerSummaryCompact from "../general/PlayerSummaryCompact";
import Tile from "../general/Tile";
import TileHeader from "../general/TileHeader";
import { useWindowSize } from "../../hooks/useWindowSize";
import { useContext } from "react";
import { LanguageContext } from "../../store/languageContext";
import { LanguageKeys } from "../../language/LanguageKeys";

const MissingReviews = () => {
  const { guiText } = useContext(LanguageContext);
  const windowSize = useWindowSize();
  const missingReviewPlayers = useMissingReviewPlayers();
  const Header = (
    <TileHeader
      title={guiText[LanguageKeys.HomeMissingReviews]}
      icon={<BsFillQuestionCircleFill size={18} color="white" />}
    />
  );

  return (
    <Tile header={Header} overflow="hidden">
      <VStack spacing={0} flex={1} className="scroll-portal scroll-y">
        {missingReviewPlayers.data?.map((player) => (
          <PlayerSummaryCompact
            player={player}
            key={"missing-review-player-" + player.id}
            small={windowSize.width ? windowSize.width < 1400 : false}
          />
        ))}
      </VStack>
    </Tile>
  );
};
export default MissingReviews;
