/* tslint:disable */
/* eslint-disable */
/**
 * alleyes SCOUT BACKEND
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorBody,
  FilterPreset,
} from '../models';
import {
    ErrorBodyFromJSON,
    ErrorBodyToJSON,
    FilterPresetFromJSON,
    FilterPresetToJSON,
} from '../models';

export interface DeleteFilterPresetRequest {
    presetId: number;
}

export interface EditFilterPresetRequest {
    presetId: number;
    filterPreset: FilterPreset;
}

export interface SaveFilterPresetRequest {
    filterPreset: FilterPreset;
}

/**
 * 
 */
export class FilterPresetApi extends runtime.BaseAPI {

    /**
     * Delete an existing filter preset.
     */
    async deleteFilterPresetRaw(requestParameters: DeleteFilterPresetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.presetId === null || requestParameters.presetId === undefined) {
            throw new runtime.RequiredError('presetId','Required parameter requestParameters.presetId was null or undefined when calling deleteFilterPreset.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/filterPresets/{presetId}`.replace(`{${"presetId"}}`, encodeURIComponent(String(requestParameters.presetId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an existing filter preset.
     */
    async deleteFilterPreset(presetId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteFilterPresetRaw({ presetId: presetId }, initOverrides);
    }

    /**
     * Edit an existing filter preset.
     */
    async editFilterPresetRaw(requestParameters: EditFilterPresetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.presetId === null || requestParameters.presetId === undefined) {
            throw new runtime.RequiredError('presetId','Required parameter requestParameters.presetId was null or undefined when calling editFilterPreset.');
        }

        if (requestParameters.filterPreset === null || requestParameters.filterPreset === undefined) {
            throw new runtime.RequiredError('filterPreset','Required parameter requestParameters.filterPreset was null or undefined when calling editFilterPreset.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/filterPresets/{presetId}`.replace(`{${"presetId"}}`, encodeURIComponent(String(requestParameters.presetId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FilterPresetToJSON(requestParameters.filterPreset),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Edit an existing filter preset.
     */
    async editFilterPreset(presetId: number, filterPreset: FilterPreset, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.editFilterPresetRaw({ presetId: presetId, filterPreset: filterPreset }, initOverrides);
        return await response.value();
    }

    /**
     * Get all filter presets.
     */
    async getFilterPresetsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterPreset>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/filterPresets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterPresetFromJSON));
    }

    /**
     * Get all filter presets.
     */
    async getFilterPresets(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterPreset>> {
        const response = await this.getFilterPresetsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Save a new filter preset.
     */
    async saveFilterPresetRaw(requestParameters: SaveFilterPresetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterPreset === null || requestParameters.filterPreset === undefined) {
            throw new runtime.RequiredError('filterPreset','Required parameter requestParameters.filterPreset was null or undefined when calling saveFilterPreset.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/filterPresets`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FilterPresetToJSON(requestParameters.filterPreset),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Save a new filter preset.
     */
    async saveFilterPreset(filterPreset: FilterPreset, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.saveFilterPresetRaw({ filterPreset: filterPreset }, initOverrides);
        return await response.value();
    }

}
