import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useContext, useRef } from "react";

import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useRatingHistory } from "../../../hooks/usePlayer";
import { ReverseMapping } from "../../../hooks/usePreparePolarChartData";
import usePrepareRatingHistoryData, {
  options,
} from "../../../hooks/usePrepareRatingHistoryData";
import { useRatingHistoryModalStore } from "../../../store/store";
import { LanguageContext } from "../../../store/languageContext";
import { colorsSecondary, textColors } from "../../../colorStyles";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const mainCategories: string[] = [
  "technischPs",
  "athletisch",
  "technischD",
  "taktischRun",
  "taktischPasses",
];

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const RatingHistoryModal = ({ isOpen, onClose }: Props) => {
  const { guiText } = useContext(LanguageContext);
  const reverseMapping = ReverseMapping(guiText);
  const data = useRatingHistoryModalStore();

  const initialRef = useRef(null);

  const displayRating = Array.isArray(data.rating)
    ? data.rating.join(" ")
    : data.rating;

  const isMainCategory =
    displayRating && mainCategories.includes(displayRating);

  const column = isMainCategory
    ? displayRating
    : displayRating
      ? reverseMapping[displayRating]
      : undefined;

  const { data: ratings } = useRatingHistory(data.id, data.position, column);

  const transformedData = usePrepareRatingHistoryData(
    ratings,
    displayRating,
    data.date
  );

  return (
    <Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="50%" height="60%" marginTop="200px" backgroundColor={colorsSecondary.cardBackground}>
        <ModalHeader borderBottom="2px solid #CBD5E0" color={textColors.primary}>
          {isMainCategory ? data.chart : data.chart + ": " + displayRating}
        </ModalHeader>
        <ModalCloseButton color={textColors.primary} />
        <ModalBody pb={6} pt={4}>
          <Box w="100%" h="100%" overflow="hidden">
            {transformedData && (
              <Line
                style={{ maxWidth: "100%" }}
                options={options}
                data={transformedData}
              />
            )}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default RatingHistoryModal;
