import { Box, Heading, HStack, useDisclosure } from "@chakra-ui/react";

import {
  ArcElement,
  Chart as ChartJS,
  Legend,
  RadialLinearScale,
  Title,
  Tooltip,
} from "chart.js";
import debounce from "lodash.debounce";
import { useContext, useEffect, useRef, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { colorsSecondary, textColors } from "../colorStyles";
import CategoryButtonsRow from "../components/comparison/CategoryButtonsRow";
import ComparisonColumn from "../components/comparison/ComparisonColumn";
import DatePickerRow from "../components/comparison/DatePickerRow";
import DroppableColumns from "../components/comparison/DroppableColumns";
import PlayerBaseDataText from "../components/comparison/PlayerBaseDataText";
import PolarChartsColumn from "../components/comparison/PolarChartsColumn";
import AlleyesScoresRow from "../components/comparison/AlleyesScoresRow";
import PlayerSelectAside from "../components/PlayerSelectAside";
import RatingHistoryModal from "../components/profile/polarCharts/RatingHistoryModal";
import PlayerModal from "../components/search/playerGrid/PlayerModal";
import QuickViewModal from "../components/shadowteams/QuickViewModal";
import { Player, ShadowteamOverview } from "../generated";
import useDragAndDropComparison from "../hooks/useDragAndDropComparison";
import { innerLabel } from "../hooks/usePreparePolarChartData";
import { useGetShadowteam } from "../hooks/useShadowteams";
import { useWatchlist } from "../hooks/useWatchlist";
import { useComparisonStore } from "../store/store";
import { LanguageKeys } from "../language/LanguageKeys";
import { LanguageContext } from "../store/languageContext";

ChartJS.register(
  RadialLinearScale,
  ArcElement,
  Legend,
  Title,
  Tooltip,
  innerLabel
);

const droppableDestinations = ["column1", "column2", "column3"];

const Compare = () => {
  const { guiText } = useContext(LanguageContext);

  const categories = {
    athletisch: guiText[LanguageKeys.SpielerprofilAthletisch],
    technischPs: guiText[LanguageKeys.SpielerprofilTechnischPassenScoren],
    technischD: guiText[LanguageKeys.SpielerprofilTechnischDuelle],
    taktischRun: guiText[LanguageKeys.SpielerprofilTaktischFreilaufverhalten],
    taktischPasses: guiText[LanguageKeys.SpielerprofilTaktischPassverhalten],
  };

  const [selection, setSelection] = useState(LanguageKeys.SpielervergleichWarenkorb);
  const [shadowteamSelection, setShadowteamSelection] = useState<
    ShadowteamOverview | undefined
  >(undefined);

  const { data: shadowteamAside } = useGetShadowteam(
    shadowteamSelection?.shadowteamId ?? -1
  );

  const { getComparisonDates, getComparisonPlayers } = useComparisonStore();

  const comparisonPlayers = getComparisonPlayers();
  const comparisonDates = getComparisonDates();

  const { data: watchlist } = useWatchlist();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    isOpen: isOpenRH,
    onOpen: onOpenRH,
    onClose: onCloseRH,
  } = useDisclosure();

  const { handleDragEnd } = useDragAndDropComparison({
    shadowteamAside,
    watchlist,
    selection,
    droppableDestinations,
  });

  const {
    isOpen: isOpenQuickView,
    onOpen: onOpenQuickView,
    onClose: onCloseQuickView,
  } = useDisclosure();

  const [quickViewPlayer, setQuickViewPlayer] = useState<Player | undefined>(
    undefined
  );

  const onCommentClick = (player: Player) => {
    setQuickViewPlayer(player);
    onOpenQuickView();
  };

  const parentRef = useRef<HTMLDivElement>(null);
  const [childHeight, setChildHeight] = useState("auto");

  const updateHeight = () => {
    if (parentRef.current) {
      if (
        !comparisonPlayers[0] &&
        !comparisonPlayers[1] &&
        !comparisonPlayers[2]
      ) {
        setChildHeight(`${parentRef.current.clientHeight}px`);
      } else {
        setChildHeight(`${parentRef.current.scrollHeight}px`);
      }
    }
  };

  const updateHeightDebounced = debounce(updateHeight, 500);

  useEffect(() => {
    updateHeightDebounced();
    return () => updateHeightDebounced.cancel();
  }, [comparisonPlayers]);

  return (
    <DragDropContext onDragEnd={handleDragEnd} >
      <Box flex={1} display="flex" pb={2} flexDir="column" overflow="auto">
        <Heading pb={3} fontSize="24" color={textColors.primary} fontFamily={"Text_Regular"}>
          {guiText[LanguageKeys.SidebarSpielervergleich]}
        </Heading>
        <Box flex={1} display="flex" overflow="auto">
          <PlayerSelectAside
            selection={selection}
            setSelection={setSelection}
            shadowteamSelection={shadowteamSelection}
            setShadowteamSelection={setShadowteamSelection}
            shadowteamAside={shadowteamAside}
            watchlist={watchlist}
          />
          <Box
            flex={1}
            display="flex"
            flexDir="column"
            overflow="auto"
            className="scroll-portal"
            id="container"
            minW="1200px"
            ref={parentRef}
            position="relative"
            data-cy="comparisonContainer"
          >
            <DroppableColumns
              columns={droppableDestinations}
              childHeight={childHeight}
            />
            <DatePickerRow
              columns={droppableDestinations}
              active={[
                !!comparisonPlayers[0],
                !!comparisonPlayers[1],
                !!comparisonPlayers[2],
              ]}
            />
            <HStack flex={1} display="flex" spacing={5} maxH="200px">
              {comparisonPlayers.map((player, idx) => (
                <ComparisonColumn
                  player={player}
                  onOpenModal={onOpen}
                  columnNo={idx + 1}
                  onCommentClick={onCommentClick}
                  key={idx}
                />
              ))}
            </HStack>
            <HStack
              zIndex={1000}
              pb={2}
              display="flex"
              spacing={5}
              position="sticky"
              top="53px"
              bg={colorsSecondary.background}
            >
              {comparisonPlayers.map((player, idx) => (
                <PlayerBaseDataText
                  player={player}
                  columnNo={idx + 1}
                  key={idx}
                />
              ))}
            </HStack>

            {(comparisonPlayers[0] ||
              comparisonPlayers[1] ||
              comparisonPlayers[2]) && (
                <CategoryButtonsRow categories={categories} />
              )}
            <AlleyesScoresRow players={comparisonPlayers} dates={comparisonDates} />

            <HStack spacing={5} display="flex" flex={1} w="100%">
              {droppableDestinations.map((_, idx) => (
                <PolarChartsColumn
                  player={comparisonPlayers[idx]}
                  date={comparisonDates[idx]}
                  key={idx}
                  columnNo={idx + 1}
                  onOpenRH={onOpenRH}
                />
              ))}
            </HStack>
          </Box>
        </Box>
      </Box>
      {isOpen && <PlayerModal isOpen={isOpen} onClose={onClose} location="comparison" />}
      <RatingHistoryModal isOpen={isOpenRH} onClose={onCloseRH} />
      <QuickViewModal
        onClose={onCloseQuickView}
        isOpen={isOpenQuickView}
        player={quickViewPlayer}
        deletePlayerLocal={() => {
          return;
        }}
      />
    </DragDropContext>
  );
};

export default Compare;
