/* tslint:disable */
/* eslint-disable */
/**
 * alleyes SCOUT BACKEND
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ColumnSetting
 */
export interface ColumnSetting {
    /**
     * 
     * @type {string}
     * @memberof ColumnSetting
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof ColumnSetting
     */
    page: string;
    /**
     * 
     * @type {string}
     * @memberof ColumnSetting
     */
    setting: string;
}

/**
 * Check if a given object implements the ColumnSetting interface.
 */
export function instanceOfColumnSetting(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "page" in value;
    isInstance = isInstance && "setting" in value;

    return isInstance;
}

export function ColumnSettingFromJSON(json: any): ColumnSetting {
    return ColumnSettingFromJSONTyped(json, false);
}

export function ColumnSettingFromJSONTyped(json: any, ignoreDiscriminator: boolean): ColumnSetting {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'page': json['page'],
        'setting': json['setting'],
    };
}

export function ColumnSettingToJSON(value?: ColumnSetting | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'page': value.page,
        'setting': value.setting,
    };
}

