import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
  Box,
  Divider,
  Flex,
  IconButton,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { BiListUl } from "react-icons/bi";
import { ShadowteamEntry } from "../../../generated";
import { StrictModeDroppable } from "../../comparison/StrictModeDroppable";
import DnDShadowteamEntry from "./DnDShadowteamEntry";
import { colorsSecondary, textColors } from "../../../colorStyles";

interface Props {
  players: ShadowteamEntry[];
  position: string;
  top: string;
  left: string;
  onClickEntry: (player: ShadowteamEntry) => void;
  isDropDisabled: boolean;
  onExpand: (position: string) => void;
  onCollapse: () => void;
  onClickPositionDetails: (position: string) => void;
  expanded?: boolean;
}

const DnDPosition = ({
  players,
  position,
  top,
  left,
  onClickEntry,
  isDropDisabled,
  onExpand,
  onCollapse,
  onClickPositionDetails,
  expanded = false,
}: Props) => {
  const MIN_HEIGHT: string = "18%";
  const MIN_HEIGHT_GK: string = "13%";

  const maxHeight = expanded ? `${95 - parseFloat(top)}%` : MIN_HEIGHT;

  return (
    <Box
      position="absolute"
      top={top}
      left={left}
      width="20%"
      height={expanded ? "40%" : MIN_HEIGHT}
      minHeight={position === "GK" ? MIN_HEIGHT_GK : MIN_HEIGHT}
      maxHeight={position === "GK" ? MIN_HEIGHT_GK : maxHeight}
      bg={colorsSecondary.cardBackground}
      borderRadius={4}
      display="flex"
      flexDir="column"
      userSelect="none"
      zIndex={expanded ? 100 : 20}
      color={textColors.primary}
    >
      <Flex px={2}>
        <Text fontWeight="semibold">{position}</Text>
        <Spacer />
        <IconButton
          aria-label="Position Details"
          icon={<BiListUl />}
          onClick={() => onClickPositionDetails(position)}
          size="s"
          mr={2}
        />
        {expanded ? (
          <IconButton
            aria-label="Collapse"
            icon={<ChevronUpIcon />}
            onClick={onCollapse}
            size="xs"
          />
        ) : (
          <IconButton
            aria-label="Expand"
            icon={<ChevronDownIcon />}
            onClick={() => onExpand(position)}
            size="xs"
          />
        )}
      </Flex>
      <Divider borderColor="gray.300"></Divider>
      <StrictModeDroppable
        droppableId={position}
        isDropDisabled={isDropDisabled}
      >
        {(provided) => (
          <Box
            flex={1}
            overflow="hidden"
            ref={provided.innerRef}
            {...provided.droppableProps}
            py="0.1rem"
            data-cy={`positionPlayerDroppable-${position}`}
          >
            <VStack
              w="100%"
              h="100%"
              spacing="0.1rem"
              className="scroll-portal scroll-y"
            >
              {players.map((playerEntry, idx) => (
                <DnDShadowteamEntry
                  key={playerEntry.player.id}
                  playerEntry={playerEntry}
                  onClick={onClickEntry}
                  isDragDisabled={false}
                ></DnDShadowteamEntry>
              ))}
            </VStack>
            {provided.placeholder}
          </Box>
        )}
      </StrictModeDroppable>
    </Box>
  );
};

export default DnDPosition;
