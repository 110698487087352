import { Badge, Box, Flex, HStack, Spacer } from "@chakra-ui/react";
import React from "react";
import { Player } from "../../generated";
import { useNavigateToPlayer } from "../../hooks/usePlayer";
import { positionAbbreviation } from "../../util/utilFunctions";
import StammdatenColumnView from "../search/playerTable/StammdatenColumnView";
import RatingBadgeStack from "./RatingBadgeStack";

interface Props {
  player: Player;
  small?: boolean;
  [x: string]: any;
}

const PlayerSummaryCompact = React.memo(({ player, small = false, ...rest }: Props) => {
  const navigateToPlayer = useNavigateToPlayer();

  return (
    <Box
      onClick={() => navigateToPlayer(player)}
      cursor="pointer"
      width="100%"
      px={2}
      className="hover-highlight"
      data-cy="player-summary-compact-root-container"
      {...rest}
    >
      <Flex flexDir="row">
        <HStack spacing={5} flexGrow={1} data-cy={"stammdaten"}>
          <StammdatenColumnView
            id={player.id}
            idTm={player.idTm}
            firstName={player.firstName}
            surName={player.surname}
            nationality={player.nationality}
            club={player.team}
            league={player.league}
            positionsGruppe={player.positionsGruppe}
            size={small ? "small" : "medium"}
          />
        </HStack>
        <Spacer />
        <HStack display="flex" flexShrink={0}>
          {!small &&
            <Badge
              variant="outline"
              colorScheme="gray"
              py={1}
              px={4}
              mr={2}
              data-cy="player-summary-compact-position-badge"
            >
              {positionAbbreviation(player.positionsGruppe)}
            </Badge>
          }
          <RatingBadgeStack player={player} />
        </HStack>
      </Flex>
    </Box>
  );
});
export default PlayerSummaryCompact;
