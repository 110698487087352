import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  Heading,
  HStack,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Spacer,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { useContext, useEffect } from "react";
import { FaEye } from "react-icons/fa";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import picture13 from "../../assets/placeholder-picture.png";
import { colorsPrimary, colorsSecondary, textColors } from "../../colorStyles";
import CountryFlag from "../../components/CountryFlag";
import {
  exportPlayerProfilePDF,
  useGetGamesAndGametime,
  useIsInWatchlistOrShadowteam,
  usePlayer,
  usePlayerBaseData,
  useProfilePicture,
  useTeamPicture,
} from "../../hooks/usePlayer";
import {
  useComparisonStore,
  usePlayerModalStore,
  usePlayerProfileStore,
} from "../../store/store";
import { playerBaseDataToPlayer } from "../../util/utilFunctions";
import "./animations.scss";
import CustomCheckboxIcon from "./CheckboxIcon";
import DateRangeSelector from "./DateRangeSelector";
import ColorExplanation from "./polarCharts/ColorExplanation";
import ExportButton from "../general/ExportButton";
import { LanguageContext } from "../../store/languageContext";
import { LanguageKeys } from "../../language/LanguageKeys";
import { text } from "d3";

interface Props {
  onOpen: () => void;
  playerId?: number;
  printView?: boolean;
}

const PlayerBaseData = ({ onOpen, playerId, printView = false }: Props) => {
  const { language, guiText } = useContext(LanguageContext);
  const location = useLocation();

  let { id } = useParams();

  if (playerId == undefined) {
    playerId = Number(id);
  }

  const queryParams = new URLSearchParams(location.search);

  const navigate = useNavigate();

  const { data: player } = usePlayerBaseData(playerId);

  const { setPlayerName, date } = usePlayerProfileStore();

  const position = queryParams.get("position")!;

  const { data: playerData } = usePlayer(playerId, position);

  const { data: gamesAndGametime } = useGetGamesAndGametime(
    playerId,
    position,
    date
  );

  useEffect(() => {
    if (player) {
      setPlayerName(player?.firstName + " " + player?.surname);
    }
  }, [position, player]);

  useEffect(() => {
    return () => setPlayerName("");
  }, []);

  const handleSetPosition = (newPosition: string | string[]) => {
    queryParams.set("position", newPosition as string);
    // Update the URL's query parameter
    navigate({
      pathname: location.pathname,
      search: queryParams.toString(),
    });
  };

  const { data: profilePic } = useProfilePicture(playerId);
  const { data: teamPic, error: teamError } = useTeamPicture(
    player?.teamId ?? -1
  );

  const { addPlayer, deletePlayer, isInComparison } = useComparisonStore();

  const { setPlayer } = usePlayerModalStore();

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && playerData) {
      addPlayer(playerData);
    } else {
      deletePlayer(player!.id, position);
    }
  };

  const openWyscoutLink = () => {
    const url = "https://platform.wyscout.com/#/players/" + playerId;
    window.open(url, "_blank");
  };

  const { data: isInWatchlistOrShadowteam } = useIsInWatchlistOrShadowteam(
    playerId,
    position
  );

  const isInWatchlistOrShadowteamBoolean =
    isInWatchlistOrShadowteam != undefined &&
    parseInt(isInWatchlistOrShadowteam + "") == 1;

  const insufficientGametime =
    gamesAndGametime?.totalGametime !== undefined
      ? gamesAndGametime.totalGametime < 900
      : true;
  const gameTimeColor = insufficientGametime ? "red.500" : textColors.primary;

  return (
    <>
      {player && (
        <>
          <Box position="relative">
            <Center>
              <Image
                src={profilePic ?? picture13}
                borderRadius="lg"
                boxSize="160px"
                align="center"
              />
            </Center>
            {/* left badges ---------------------------------------------- */}
            <VStack position="absolute" top="0px" left="0px" spacing={3}>
              {!printView && (
                <IconButton
                  icon={
                    <FaEye
                      size="30px"
                      color={
                        isInWatchlistOrShadowteamBoolean
                          ? colorsSecondary.accent
                          : "#CBD5E0"
                      }
                    />
                  }
                  aria-label="Add to Watchlist or ShadowTeam"
                  onClick={() => {
                    setPlayer(playerBaseDataToPlayer(player, position));
                    onOpen();
                  }}
                  data-cy={"watchlistButton"}
                />
              )}

              {player.nationality && (
                <CountryFlag
                  nationality={player.nationality}
                  size={30}
                ></CountryFlag>
              )}
              {!teamError && teamPic && (
                <Image src={teamPic} boxSize={8}></Image>
              )}
            </VStack>
            {!printView && (
              <Checkbox
                size="xl"
                onChange={handleCheckboxChange}
                isChecked={isInComparison(player.id, position)}
                position="absolute"
                top="10px"
                right="10px"
                icon={<CustomCheckboxIcon />}
              />
            )}

            <Center pt={2}>
              <Box bg={colorsSecondary.background} width="100%">
                <Heading
                  size="sm"
                  color="white"
                  textAlign="center"
                  p={2}
                  boxShadow="md"
                >
                  {player.firstName + " " + player.surname}
                </Heading>
              </Box>
            </Center>
          </Box>
          <Tooltip
            borderRadius={5}
            label={guiText[LanguageKeys.RatingsBasierenAufWenigerAls900Spielminuten]}
            isDisabled={!insufficientGametime}
          >
            <Box
              pt={3}
              display="flex"
              justifyContent="space-between"
              px={1}
              color={gameTimeColor}
            >
              <Text>
                {gamesAndGametime
                  ? gamesAndGametime.noOfGames
                  : "-"} {guiText[LanguageKeys.SpielerprofilSpiele]}
              </Text>
              |
              <Text>
                {gamesAndGametime
                  ? gamesAndGametime.totalGametime
                  : "-"} {guiText[LanguageKeys.SpielerprofilMinuten]}
              </Text>
              |
              <Text>
                {gamesAndGametime
                  ? "Ø " + gamesAndGametime.averageGametime
                  : "Ø -"} {guiText[LanguageKeys.SpielerprofilMinuten]}
              </Text>
            </Box>
          </Tooltip>

          <Box pt={4} pb={1}>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon color={colorsSecondary.accent} />}
                width="100%"
                height="45px"
                className="animatedBorder"
              >
                <Button borderColor={textColors.primary} width="100%" height="100%">
                  <Text align="left" fontWeight="normal" fontSize={17} color={textColors.primary}>
                    <Box as="span" fontWeight="bold" color={colorsSecondary.accent}>
                      {guiText[LanguageKeys.SearchBarPosition]}:
                    </Box>
                    {" " + (position != "null" ? position : "-")}
                  </Text>
                </Button>
              </MenuButton>
              <MenuList zIndex={999} backgroundColor={colorsSecondary.cardBackground}>
                <MenuOptionGroup
                  type="radio"
                  value={position}
                  onChange={handleSetPosition}
                >
                  {player?.positionsGruppen?.map((pos) => (
                    <MenuItemOption key={pos} value={pos} backgroundColor={colorsSecondary.cardBackground} color={textColors.primary}>
                      {pos}
                    </MenuItemOption>
                  ))}
                </MenuOptionGroup>
              </MenuList>
            </Menu>
          </Box>
          <VStack
            spacing={1}
            w="100%"
            pt={4}
            fontSize={15}
            className="scroll-portal scroll-y"
            h="100%"
          >
            <HStack w="100%" spacing={3} align="start">
              <Flex w="40%" fontWeight="bold" alignItems="start">
                <Text color={textColors.primary}>{guiText[LanguageKeys.SpielerprofilGeburtsdatum]}</Text>
              </Flex>
              <Flex w="60%" alignItems="start">
                <Text color={textColors.primary}>
                  {player.birthday
                    ? format(new Date(player.birthday), "dd.MM.yyyy") +
                    " (" +
                    player.age + " " + guiText[LanguageKeys.Jahre] + ")"
                    : "-"}
                </Text>
              </Flex>
            </HStack>

            <HStack w="100%" spacing={3} align="start">
              <Flex w="40%" fontWeight="bold" alignItems="start">
                <Text color={textColors.primary}>{guiText[LanguageKeys.SearchBarKorpergrobe]}</Text>
              </Flex>
              <Flex w="60%" alignItems="start">
                <Text color={textColors.primary}>{player.height ? player.height + " cm" : "-"}</Text>
              </Flex>
            </HStack>

            <HStack w="100%" spacing={3} align="start">
              <Flex w="40%" fontWeight="bold" alignItems="start">
                <Text color={textColors.primary}>{guiText[LanguageKeys.SearchBarVerein]}</Text>
              </Flex>
              <Flex w="60%" alignItems="start">
                <Text color={textColors.primary}>{player.team ? player.team : "-"}</Text>
              </Flex>
            </HStack>

            <HStack w="100%" spacing={3} align="start">
              <Flex w="40%" fontWeight="bold" alignItems="start">
                <Text color={textColors.primary}>{guiText[LanguageKeys.Wettbewerb]}</Text>
              </Flex>
              <Flex w="60%" alignItems="start">
                <Text color={textColors.primary}>
                  {player.league ? player.league.replace(/_/g, " ") : "-"}
                </Text>
              </Flex>
            </HStack>

            <HStack w="100%" spacing={3} align="start">
              <Flex w="40%" fontWeight="bold" alignItems="start">
                <Text color={textColors.primary}>{guiText[LanguageKeys.SearchBarNationalitat]}</Text>
              </Flex>
              <Flex w="60%" alignItems="start">
                <Text color={textColors.primary}>{player.nationality ? player.nationality : "-"}</Text>
              </Flex>
            </HStack>

            <HStack w="100%" spacing={3} align="start">
              <Flex w="40%" fontWeight="bold" alignItems="start">
                <Text color={textColors.primary}>{guiText[LanguageKeys.SearchBarStarterFuß]}</Text>
              </Flex>
              <Flex w="60%" alignItems="start">
                {player.strongFoot ? (
                  <Text color={textColors.primary}>
                    {player.strongFoot == "right"
                      ? guiText[LanguageKeys.Rechts]
                      : player.strongFoot == "left"
                        ? guiText[LanguageKeys.Links]
                        : guiText[LanguageKeys.Beide]}
                  </Text>
                ) : (
                  <Text color={textColors.primary}>{"-"}</Text>
                )}
              </Flex>
            </HStack>

            <HStack w="100%" spacing={3} align="start">
              <Flex w="40%" fontWeight="bold" alignItems="start">
                <Text color={textColors.primary}>{guiText[LanguageKeys.SpielerprofilVerletzungstage]}</Text>
              </Flex>
              <Flex w="60%" alignItems="start">
                <Text color={textColors.primary}>
                  {player.totalInjuryDays != undefined
                    ? player.totalInjuryDays + " " + guiText[LanguageKeys.Tage] + " (" + guiText[LanguageKeys.Karriere] + ")"
                    : "-"}
                </Text>
              </Flex>
            </HStack>

            <HStack w="100%" spacing={3} align="start">
              <Flex w="40%" fontWeight="bold" alignItems="start">
                <Text color={textColors.primary}>{guiText[LanguageKeys.SearchBarMarktwert]}</Text>
              </Flex>
              <Flex w="60%" alignItems="start">
                <Text color={textColors.primary}>
                  {player.marketValue
                    ? player.marketValue.toLocaleString("de-DE")
                    : "-"}
                </Text>
              </Flex>
            </HStack>

            <HStack w="100%" spacing={3} align="start">
              <Flex w="40%" fontWeight="bold" alignItems="start">
                <Text color={textColors.primary}>{guiText[LanguageKeys.Vertragsende]}</Text>
              </Flex>
              <Flex w="60%" alignItems="start">
                <Text color={textColors.primary}>
                  {player.contractExpiryDate
                    ? format(new Date(player.contractExpiryDate), "dd.MM.yyyy")
                    : "-"}
                </Text>
              </Flex>
            </HStack>

            <HStack w="100%" spacing={3} align="start">
              <Flex w="40%" fontWeight="bold" alignItems="start">
                <Text color={textColors.primary}>{guiText[LanguageKeys.SearchBarLeihe]}</Text>
              </Flex>
              <Flex w="60%" alignItems="start">
                <Text color={textColors.primary}>
                  {player.loan !== undefined
                    ? player.loan
                      ? guiText[LanguageKeys.Ja]
                      : guiText[LanguageKeys.Nein]
                    : "-"}
                </Text>
              </Flex>
            </HStack>

            <HStack w="100%" spacing={3} align="start">
              <Flex w="40%" fontWeight="bold" alignItems="start">
                <Text color={textColors.primary}>{guiText[LanguageKeys.SpielerprofilBerater]}</Text>
              </Flex>
              <Flex w="60%" alignItems="start">
                <Text color={textColors.primary}>{player.agent ? player.agent : "-"}</Text>
              </Flex>
            </HStack>

            <ColorExplanation position={position} />
            <Spacer />
            {!printView && (
              <>
                <ExportButton
                  w="80%"
                  mt={3}
                  padding={4}
                  cursor="pointer"
                  onClick={() => exportPlayerProfilePDF(playerId!, position, date, language)}
                  filename={`PlayerProfile-${player.surname}-${playerId!}.pdf`}
                >
                  {guiText[LanguageKeys.PDFExport]}
                </ExportButton>
                <Button
                  alignSelf="center"
                  w="80%"
                  background="black"
                  color="white"
                  onClick={openWyscoutLink}
                  boxShadow="sm"
                  mt={3}
                  py={3}
                >
                  <Text fontWeight="normal" mr={1}>
                    {guiText[LanguageKeys.SpielerprofilZum]}
                  </Text>
                  <Text fontFamily="Gabarito" mr={1}>
                    Wyscout&reg;
                  </Text>
                  <Text fontWeight="normal">{guiText[LanguageKeys.SpielerprofilProfil]}</Text>
                </Button>
              </>
            )}
          </VStack>
          {printView && (
            <>
              <Text id="player-base-data-printview-text" fontSize="sm" pb={5} pl={1}>
                {guiText[LanguageKeys.DatenBeziehenSichAufDasLetzteJahr]}
              </Text>
              <DateRangeSelector
                usecase="profile"
                active={false}
                playerId={playerId}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default PlayerBaseData;
