export const colorsPrimary = {
  koenigsblau: "#004B9C",
  dunkelblau40: "#00346E",
  dunkelblau60: "#002855",
  dunkelblau80: "#001437",
  hellblau: "#4bafe8",
  schwarz: "#040404",
  grey80: "#333333",

};

export const colorsSecondary = {
  background: "#0C232F",
  cardBackground: "#123048",
  searchBackground: "#10112D",
  hover: "#1E3955",
  highlight1: "#4bafe8",
  highlight2: "#004B9C",
  highlight3: "#7E84A3",
  highlight4: "#B30C00",
  accent: "#6DDC85",
  accentOrange: "#EE8853",
  accentBlue: "#8AB4F8",
  accentPurple: "#181F2B",
  accentDarkRed: "#471912",
  accentBabyBlue: "#0E9DD5",
  accentDarkBlue: "#024469",
  accentPolarchartStart: "#244B6A",
  accentHover: "#004E79"
};

export const textColors = {
  primary: "white",
  lightgrey: "#E6E8E8",
  secondary: "#7E84A3",
};

export const specialColors = {
  pdfRed: "#B30C00"
}
