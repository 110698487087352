import { useKeycloak } from "@react-keycloak/web";
import Keycloak from "keycloak-js";
import {
  ColumnSettingApi,
  Configuration,
  FilterPresetApi,
  FilterSpecificationApi,
  HomeApi,
  PlayerApi,
  ShadowteamApi,
  UserApi,
  WatchlistApi,
  type ConfigurationParameters,
} from "../generated";

const apiBaseUrl = window.REACT_APP_API_BASEURL ?? "https://alleyes-local.app";

const keycloakConfig: Keycloak.KeycloakConfig = {
  realm: window.REACT_APP_KEYCLOAK_REALM,
  url: window.REACT_APP_KEYCLOAK_URL,
  clientId: window.REACT_APP_KEYCLOAK_CLIENT_ID,
};
const keycloak = new Keycloak(keycloakConfig);

export const getUserID = (): string => {
  var userID = "";
  keycloak
    .loadUserProfile()
    .then(function (profile) {
      userID = profile.id || "";
    })
    .catch(function () {
      console.error("Failed to load user profile");
    });

  return userID;
};

export const onClickLogout = (): void => {
  keycloak
    .logout()
    .then(() => { })
    .catch(() => {
      console.log("Error logging out");
    });
};
export const onLogin = (): void => {
  // keycloak
  //   .init({
  //     onLoad: "check-sso",
  //     pkceMethod: "S256",
  //     silentCheckSsoRedirectUri:
  //       window.location.origin + "/silent-check-sso.html",
  //   })
  //   .then(() => {})
  //   .catch(() => {
  //     console.log("Error initializing keycloak");
  //   });
  keycloak
    .login()
    .then(() => { })
    .catch(() => {
      console.log("Error logging in");
    });
};

export function Loggedin(): boolean {
  const k = useKeycloak();
  if (k.keycloak.authenticated != null && k.keycloak.authenticated) {
    return true;
  }
  return false;
}

export const getPlayerApi = (): PlayerApi => {
  const params: ConfigurationParameters = {
    basePath: apiBaseUrl,
    accessToken: "Bearer " + (keycloak.token != null ? keycloak.token : ""),
    headers: {
      Authorization: "Bearer " + (keycloak.token != null ? keycloak.token : ""),
      Accept: "application/json",
    },
  };
  const configuration = new Configuration(params);
  return new PlayerApi(configuration);
};

export const getHomeApi = (): HomeApi => {
  const params: ConfigurationParameters = {
    basePath: apiBaseUrl,
    accessToken: "Bearer " + (keycloak.token != null ? keycloak.token : ""),
    headers: {
      Authorization: "Bearer " + (keycloak.token != null ? keycloak.token : ""),
      Accept: "application/json",
    },
  };
  const configuration = new Configuration(params);
  return new HomeApi(configuration);
};

export const getWatchlistApi = (): WatchlistApi => {
  const params: ConfigurationParameters = {
    basePath: apiBaseUrl,
    accessToken: "Bearer " + (keycloak.token != null ? keycloak.token : ""),
    headers: {
      Authorization: "Bearer " + (keycloak.token != null ? keycloak.token : ""),
      Accept: "application/json",
    },
  };
  const configuration = new Configuration(params);
  return new WatchlistApi(configuration);
};

export const getShadowteamApi = (): ShadowteamApi => {
  const params: ConfigurationParameters = {
    basePath: apiBaseUrl,
    accessToken: "Bearer " + (keycloak.token != null ? keycloak.token : ""),
    headers: {
      Authorization: "Bearer " + (keycloak.token != null ? keycloak.token : ""),
      Accept: "application/json",
    },
  };
  const configuration = new Configuration(params);
  return new ShadowteamApi(configuration);
};

export const getFilterPresetApi = (): FilterPresetApi => {
  const params: ConfigurationParameters = {
    basePath: apiBaseUrl,
    accessToken: "Bearer " + (keycloak.token != null ? keycloak.token : ""),
    headers: {
      Authorization: "Bearer " + (keycloak.token != null ? keycloak.token : ""),
      Accept: "application/json",
    },
  };

  const configuration = new Configuration(params);
  return new FilterPresetApi(configuration);
};

export const getColumnSettingApi = (): ColumnSettingApi => {
  const params: ConfigurationParameters = {
    basePath: apiBaseUrl,
    accessToken: "Bearer " + (keycloak.token != null ? keycloak.token : ""),
    headers: {
      Authorization: "Bearer " + (keycloak.token != null ? keycloak.token : ""),
      Accept: "application/json",
    },
  };

  const configuration = new Configuration(params);
  return new ColumnSettingApi(configuration);
};

export const getFilterSpecApi = (): FilterSpecificationApi => {
  const params: ConfigurationParameters = {
    basePath: apiBaseUrl,
    accessToken: "Bearer " + (keycloak.token != null ? keycloak.token : ""),
    headers: {
      Authorization: "Bearer " + (keycloak.token != null ? keycloak.token : ""),
      Accept: "application/json",
    },
  };
  const configuration = new Configuration(params);
  return new FilterSpecificationApi(configuration);
};

export const getUserApi = (): UserApi => {
  const params: ConfigurationParameters = {
    basePath: apiBaseUrl,
    accessToken: "Bearer " + (keycloak.token != null ? keycloak.token : ""),
    headers: {
      Authorization: "Bearer " + (keycloak.token != null ? keycloak.token : ""),
      Accept: "application/json",
    },
  };
  const configuration = new Configuration(params);
  return new UserApi(configuration);
};

export default keycloak;
