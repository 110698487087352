import { Box, HStack, Icon, Text } from "@chakra-ui/react";
import {
  ActionMeta,
  ChakraStylesConfig,
  InputActionMeta,
  Select,
} from "chakra-react-select";
import { useContext, useEffect, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { FilterSpecification } from "../../../generated";
import { useTextMenuFilterInfo } from "../../../hooks/useFilterSpecification";
import { FilterValueStoreCore } from "../../../store/store";
import { filterOpacity } from "../searchStyles";
import { LocaleKeys } from "../../../language/LocaleKeys";
import { LanguageContext } from "../../../store/languageContext";
import { colorsSecondary, textColors } from "../../../colorStyles";

interface Props {
  filter: FilterSpecification;
  filterValueFunc: FilterValueStoreCore;
}

interface Specification {
  options: string[];
  allowMultiple: boolean;
}

const TextMenuFilter = ({ filter, filterValueFunc }: Props) => {
  const { language } = useContext(LanguageContext);
  const filterSpecification = JSON.parse(filter.specification) as Specification;
  const [filterValue, setFilterValue] = useState<
    readonly { value: string; label: string }[]
  >([]);

  const [inputText, setInputText] = useState("");

  const { data: options, isLoading } = useTextMenuFilterInfo(
    inputText,
    filter.dbColumn!
  );

  useEffect(() => {
    const filterString = filterValueFunc.filterValues[filter.specId - 1]?.value;
    if (filterString !== null && filterString !== undefined) {
      const parsedFilter = JSON.parse(filterString);
      setFilterValue(parsedFilter.values);
    } else {
      setFilterValue([]);
    }
  }, [filterValueFunc.filterValues[filter.specId - 1]]);

  const active = filterValue.length > 0;

  const transformedOptions = options?.map((option) => ({
    value: option,
    label: option.replaceAll("_", " "),
  }));

  const handleChange = (newValue: any, actionMeta: ActionMeta<unknown>) => {
    if (newValue) {
      if (newValue instanceof Array) {
        setFilterValue(newValue);

        if (newValue.length === 0) {
          filterValueFunc.removeFilter(filter.specId);
        } else {
          filterValueFunc.addFilter({
            specId: filter.specId,
            value: `{"values":${JSON.stringify(newValue)}}`,
          });
        }
      } else {
        setFilterValue([newValue]);
        filterValueFunc.addFilter({
          specId: filter.specId,
          value: `{"values":${JSON.stringify([newValue])}}`,
        });
      }
    } else {
      setFilterValue([]);
    }
  };

  const handleInputChange = (newValue: string, actionMeta: InputActionMeta) => {
    if (actionMeta.action.toString() !== "set-value") {
      setInputText(newValue);
    }
  };

  const chakraStyles: ChakraStylesConfig = {
    dropdownIndicator: (provided: any, state: any) => ({
      ...provided,

      display: "none",
    }),
    indicatorSeparator: (provided: any, state: any) => ({
      ...provided,

      display: "none",
    }),
    indicatorsContainer: (provided: any, state: any) => ({
      ...provided,
      display: "flex",
      flexDir: "row",
      justifyContent: "flex-end",
    }),
    downChevron: (provided: any, state: any) => ({
      ...provided,
      display: "none",
    }),
    clearIndicator: (provided: any, state: any) => ({
      ...provided,
      ml: "auto",
    }),
    noOptionsMessage: (provided: any, state: any) => ({
      ...provided,
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      color: textColors.primary,
      // borderColor: state.isFocused ? colorsSecondary.accent : 'gray.300',
      // outline: "none",
      // boxShadow: state.isFocused ? '0 0 0 1px red' : 'none',
      '&:hover': {
        borderColor: state.isFocused ? colorsSecondary.accent : state.isSelected ? 'green' : 'gray.400',
      },
    }),
  };

  return (
    <Box pb={3} data-cy={"textSelectFilter"}>
      <HStack
        marginBottom={2}
        justifyContent="space-between"
        opacity={active ? filterOpacity.active : filterOpacity.notActive}
      >
        <Text fontSize="md" color={textColors.primary}>{language == LocaleKeys.DE ? filter.labelGerman : language == LocaleKeys.EN ? filter.labelEnglish : filter.labelSpanish}</Text>
        <Icon
          as={AiFillCloseCircle}
          color="gray.500"
          style={{ cursor: "pointer" }}
          onClick={() => filterValueFunc.removeFilter(filter.specId)}
          boxSize={4}
          data-cy={"resetButton"}
        ></Icon>
      </HStack>
      <Select
        isMulti={filterSpecification.allowMultiple}
        options={transformedOptions}
        placeholder={language == LocaleKeys.DE ? filter.labelGerman : language == LocaleKeys.EN ? filter.labelEnglish : filter.labelSpanish}
        closeMenuOnSelect={false}
        value={filterValue}
        onChange={handleChange}
        chakraStyles={chakraStyles}
        onInputChange={handleInputChange}
        menuIsOpen={!!inputText}
        noOptionsMessage={() => (isLoading ? "Loading..." : "No options")}
        filterOption={null}
      />
    </Box>
  );
};

export default TextMenuFilter;
