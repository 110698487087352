/* tslint:disable */
/* eslint-disable */
/**
 * alleyes SCOUT BACKEND
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MarketValueEntry
 */
export interface MarketValueEntry {
    /**
     * Identifier of the player.
     * @type {number}
     * @memberof MarketValueEntry
     */
    spielerIdTm: number;
    /**
     * Identifier of old team.
     * @type {number}
     * @memberof MarketValueEntry
     */
    teamIdTm?: number;
    /**
     * Name of the team.
     * @type {string}
     * @memberof MarketValueEntry
     */
    teamNameTm?: string;
    /**
     * Image URL of the team.
     * @type {string}
     * @memberof MarketValueEntry
     */
    teamImageTm?: string;
    /**
     * Transfer value.
     * @type {number}
     * @memberof MarketValueEntry
     */
    marktwert?: number;
    /**
     * Date of the transfer.
     * @type {Date}
     * @memberof MarketValueEntry
     */
    datum: Date;
}

/**
 * Check if a given object implements the MarketValueEntry interface.
 */
export function instanceOfMarketValueEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "spielerIdTm" in value;
    isInstance = isInstance && "datum" in value;

    return isInstance;
}

export function MarketValueEntryFromJSON(json: any): MarketValueEntry {
    return MarketValueEntryFromJSONTyped(json, false);
}

export function MarketValueEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): MarketValueEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'spielerIdTm': json['spielerIdTm'],
        'teamIdTm': !exists(json, 'teamIdTm') ? undefined : json['teamIdTm'],
        'teamNameTm': !exists(json, 'teamNameTm') ? undefined : json['teamNameTm'],
        'teamImageTm': !exists(json, 'teamImageTm') ? undefined : json['teamImageTm'],
        'marktwert': !exists(json, 'marktwert') ? undefined : json['marktwert'],
        'datum': (new Date(json['datum'])),
    };
}

export function MarketValueEntryToJSON(value?: MarketValueEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'spielerIdTm': value.spielerIdTm,
        'teamIdTm': value.teamIdTm,
        'teamNameTm': value.teamNameTm,
        'teamImageTm': value.teamImageTm,
        'marktwert': value.marktwert,
        'datum': (value.datum.toISOString().substr(0,10)),
    };
}

