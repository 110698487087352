import { IconButton } from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { ShadowteamOverview } from "../../../generated";
import { useShadowteamOverviewStore } from "../../../store/store";
import { textColors } from "../../../colorStyles";
interface Props {
  shadowteamOverview: ShadowteamOverview;
  onOpenModal: () => void;
}

const EditIcon = ({ shadowteamOverview, onOpenModal }: Props) => {
  const { setShadowteam } = useShadowteamOverviewStore();
  const { keycloak } = useKeycloak();

  const userId = keycloak.idTokenParsed?.sub;

  const handleOnClick = () => {
    setShadowteam(shadowteamOverview);
    onOpenModal();
  };
  return (
    <IconButton
      aria-label="Add to Watchlist or ShadowTeam"
      onClick={handleOnClick}
      disabled={userId == shadowteamOverview.userId ? false : true}
    >
      <MdOutlineModeEditOutline
        visibility={userId == shadowteamOverview.userId ? "visible" : "hidden"}
        color={textColors.primary}
      />
    </IconButton>
  );
};

export default EditIcon;
