import { IconButton } from "@mui/material";
import { AiFillCloseCircle } from "react-icons/ai";
import { ShadowteamOverview } from "../../../generated";
import { useShadowteamOverviewStore } from "../../../store/store";

interface Props {
  onOpenModal: () => void;
  shadowteam: ShadowteamOverview;
}

const DeleteIconShadowteamsOverview = ({ onOpenModal, shadowteam }: Props) => {
  const { setShadowteam } = useShadowteamOverviewStore();

  const handleOnClick = () => {
    setShadowteam(shadowteam);
    onOpenModal();
  };

  return (
    <IconButton aria-label="Delete" onClick={handleOnClick}>
      <AiFillCloseCircle size="18px" color="#8a8a8a" />
    </IconButton>
  );
};

export default DeleteIconShadowteamsOverview;
