import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import SchalkeSidebar from "../components/sidebar/Sidebar";
import { useSidebarCollapsedStore } from "../store/store";

const ErrorPage = () => {
  const error = useRouteError();
  const { collapsed } = useSidebarCollapsedStore();

  return (
    <Flex>
      <SchalkeSidebar />
      <Box padding={5} pl={collapsed ? "90px" : "240px"}>
        <Heading>Oops</Heading>
        <Text>
          {isRouteErrorResponse(error)
            ? "This page does not exist."
            : "An unexpected error occurred."}
        </Text>
      </Box>
    </Flex>
  );
};

export default ErrorPage;
