import { QueryClient, useQuery, useQueryClient } from "@tanstack/react-query";
import { PlayerApi } from "../generated/apis/PlayerApi";
import { Player } from "../generated/models/Player";
import { getFilterSpecApi, getPlayerApi } from "../util/keycloak";
import { FilterSpecification, ScoutingReport } from "../generated";
import ms from "ms";
import { useFilterSpecificationStore } from "../store/store";

const fetchScoutingReports = async (id: number): Promise<ScoutingReport[]> => {
  const api = getPlayerApi();
  return api.getScoutingReports(id);
};

export const useScoutingReports = (id: number) => {
  return useQuery<ScoutingReport[], Error>(
    ["scoutingReports", id],
    () => fetchScoutingReports(id),
    {
      staleTime: ms("1h"),
    }
  );
};
