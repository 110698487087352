import { Box, Heading, HStack, Spacer, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { colorsSecondary, textColors } from "../../colorStyles";
import { Player } from "../../generated";
import { useComparisonStore } from "../../store/store";
import { positionAbbreviation } from "../../util/utilFunctions";

interface Props {
  player: Player | undefined;
  columnNo: number;
}

const PlayerBaseDataText = ({ player, columnNo }: Props) => {
  const customShadow = "0 4px 6px -1px rgba(0, 0, 0, 0.1)";

  const {
    setAddToComp1,
    setAddToComp2,
    setAddToComp3,
    clearPlayer1,
    clearPlayer2,
    clearPlayer3,
  } = useComparisonStore();

  const handleRemove = (event: React.MouseEvent) => {
    event.stopPropagation();
    switch (columnNo) {
      case 1:
        clearPlayer1();
        break;
      case 2:
        clearPlayer2();
        break;
      case 3:
        clearPlayer3();
        break;
    }
  };

  const focusSearchBar = () => {
    switch (columnNo) {
      case 1:
        setAddToComp1(true);
        break;
      case 2:
        setAddToComp2(true);
        break;
      case 3:
        setAddToComp3(true);
        break;
    }

    const inputElement = document.getElementById("searchbar");
    if (inputElement && document.activeElement !== inputElement) {
      inputElement.focus();
    }
  };

  return (
    <>
      {player ? (
        <Stack
          spacing="1"
          bg={colorsSecondary.cardBackground}
          pb={3}
          pt={3}
          flex={1}
          h="95px"
          borderBottomRightRadius={3}
          borderBottomLeftRadius={3}
          boxShadow="md"
        >
          <HStack>
            <Heading size="sm" pl={5} pr={5} color={textColors.primary}>
              {player.firstName +
                " " +
                player.surname +
                "  |  " +
                positionAbbreviation(player.positionsGruppe)}
            </Heading>
            <Spacer />
            <Box onClick={handleRemove} cursor="pointer" pr={5}>
              <AiFillCloseCircle size={20} fill="#718096" />
            </Box>
          </HStack>
          <Text color={textColors.secondary} fontSize="sm" pl={5} pr={5}>
            {(player.league ? player.league.replace(/_/g, " ") : "") +
              (player.team
                ? player.league
                  ? " | " + player.team
                  : player.team
                : "")}
          </Text>

          <Text fontSize="sm" color={textColors.secondary} pl={5} pr={5}>
            {(player.age ? player.age + " y.o." : "") +
              (player.height
                ? player.age
                  ? " | " + player.height + " cm"
                  : player.height + " cm"
                : "")}
          </Text>
        </Stack>
      ) : (
        <Box
          h="95px"
          bg={colorsSecondary.cardBackground}
          flex={1}
          borderBottomRightRadius={3}
          borderBottomLeftRadius={3}
          boxShadow={customShadow}
          onClick={focusSearchBar}
          cursor="pointer"
        />
      )}
    </>
  );
};

export default PlayerBaseDataText;
