import * as d3 from "d3";
import { useContext, useEffect } from "react";
import fieldImage from "../../../assets/Spielfeld_halb.svg";

import { Box, Center, Heading, Text } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { Shot } from "../../../generated";
import { LanguageContext } from "../../../store/languageContext";
import { LanguageKeys } from "../../../language/LanguageKeys";
import { textColors } from "../../../colorStyles";
interface Props {
  shots: Shot[] | undefined;
  isLoading: boolean;
  postShot: boolean;
}
const GoalsMap = ({ shots, isLoading, postShot }: Props) => {
  const { guiText } = useContext(LanguageContext);

  const { id } = useParams();

  const containerId = `container-${postShot ? "postShot" : "preShot"}-${id}`;

  const width = 656;
  const height = 510;

  const margin = { top: 20, right: 25, bottom: 20, left: 25 };

  const torAndNotKopfball: number[][] = [];
  const notTorAndNotKopfball: number[][] = [];
  const torAndKopfball: number[][] = [];
  const notTorAndKopfball: number[][] = [];

  shots?.forEach((shot) => {
    const { xPos, yPos, kopfball, tor, xgSchuss, postShotXgSchuss } = shot;
    const valueToUse = postShot ? postShotXgSchuss : xgSchuss;

    if (postShot && postShotXgSchuss == null) return; // If postShot is true but postShotXgSchuss is null, skip

    if (tor && !kopfball) {
      torAndNotKopfball.push([yPos, xPos, valueToUse!]);
    } else if (!tor && !kopfball) {
      notTorAndNotKopfball.push([yPos, xPos, valueToUse!]);
    } else if (tor && kopfball) {
      torAndKopfball.push([yPos, xPos, valueToUse!]);
    } else if (!tor && kopfball) {
      notTorAndKopfball.push([yPos, xPos, valueToUse!]);
    }
  });

  const thresholds = [0.1, 0.2, 0.3, 0.4, 0.5];
  const colors = [
    "rgb(0,128,0)",
    "rgb(0,255,0)",
    "rgb(254,207,0)",
    "rgb(231,81,19)",
    "rgb(227,6,19)",
    "rgb(126,0,21)",
    "darkBlue",
  ];

  const colorScale = d3
    .scaleThreshold<number, string>()
    .domain(thresholds)
    .range(colors.slice(0, -1));

  useEffect(() => {
    const container = d3.select(`div#${containerId}`);
    container.selectAll("*").remove();
    const svg = container

      .append("svg")
      .attr("preserveAspectRatio", "xMidYMid meet")
      .attr(
        "viewBox",
        `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom
        }`
      )
      .classed("svg-content", true);
    svg.selectAll("*").remove();

    svg
      .append("image")
      .attr("href", fieldImage)
      .attr("x", 10)
      .attr("y", 10)
      .attr("width", width - 3)
      .attr("height", height - 4);

    const xScale = d3
      .scaleLinear()
      .domain([0, 100])
      .range([margin.left - 12, width + 6]);
    const yScale = d3
      .scaleLinear()
      .domain([0, 100])
      .range([height - margin.bottom + 19, margin.top - 6]);

    const patternSize = 5;

    colors.forEach((color, i) => {
      const patternID = `stripePattern-${i}`;

      svg

        .append("defs")
        .append("pattern")

        .attr("id", patternID)

        .attr("width", patternSize)
        .attr("height", patternSize)
        .attr("patternUnits", "userSpaceOnUse")
        .attr("patternTransform", "rotate(45)")

        .append("rect")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", patternSize)
        .attr("height", patternSize / 2)
        .attr("fill", "white");

      svg
        .select("#" + patternID)

        .append("rect")

        .attr("x", 0)
        .attr("y", patternSize / 2)
        .attr("width", patternSize)
        .attr("height", patternSize / 2)
        .attr("fill", color);
    });

    svg
      .selectAll("data1")
      .data(notTorAndNotKopfball)
      .join("circle")
      .attr("cx", (d) => xScale(d[0]))
      .attr("cy", (d) => yScale(d[1]))
      .attr("r", 8)
      .attr("fill", (d) => colorScale(d[2]))
      .attr("opacity", 1);

    svg
      .selectAll("data2")
      .data(torAndNotKopfball)
      .join("circle")
      .attr("cx", (d) => xScale(d[0]))
      .attr("cy", (d) => yScale(d[1]))
      .attr("r", 8)
      .attr(
        "fill",
        (d) => `url(#stripePattern-${colors.indexOf(colorScale(d[2]))})`
      )
      .attr("opacity", 1);

    svg
      .selectAll("data3")
      .data(notTorAndKopfball)
      .join("rect")
      .attr("x", (d) => xScale(d[0]) - 8)
      .attr("y", (d) => yScale(d[1]) - 8)
      .attr("width", 16)
      .attr("height", 16)
      .attr("fill", (d) => colorScale(d[2]))
      .attr("opacity", 1);

    svg
      .selectAll("data4")
      .data(torAndKopfball)
      .join("rect")
      .attr("x", (d) => xScale(d[0]) - 8)
      .attr("y", (d) => yScale(d[1]) - 8)
      .attr("width", 16)
      .attr("height", 16)
      .attr(
        "fill",
        (d) => `url(#stripePattern-${colors.indexOf(colorScale(d[2]))})`
      )
      .attr("opacity", 1);
  }, [shots]);

  return (
    <>
      {isLoading ? (
        <Center h="100%" w="100%">
          <Text fontSize={22} color={textColors.primary}>Loading...</Text>
        </Center>
      ) : !shots || shots.length == 0 ? (
        <Center id={postShot ? "post-shot-goalsmap-no-data" : "pre-shot-goalsmap-no-data"} h="100%" w="100%">
          <Text fontSize={22} color={textColors.primary}>{guiText[LanguageKeys.KeineTorschussdatenvorhanden]}</Text>
        </Center>
      ) : (
        <Box
          id={postShot ? "post-shot-goalsmap" : "pre-shot-goalsmap"}
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          flexDir="column"
          flex={1}
          height="95%"
          sx={{
            ".svg-container": {
              display: "flex",
              flexDir: "column",
              width: "100%",
              height: "100%",
              overflow: "hidden",
              alignItems: "center",
              justifyContent: "center",
            },
            ".svg-content": {
              display: "flex",
              flexDir: "column",
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            },
          }}
        >
          <Heading fontSize="20" pb={5} color={textColors.primary}>
            {postShot ? "Post Shot" : "Pre Shot"}
          </Heading>
          <div id={containerId} className="svg-container"></div>
        </Box>
      )}
    </>
  );
};

export default GoalsMap;
