import React, {PropsWithChildren} from 'react';

interface Props {
  condition: string
}

export const Switch = ({ condition, children }: PropsWithChildren<Props>) => {
  if (!children) {
    return null
  }

  const childrenArray = Array.isArray(children) ? children : [children]
  const cases = childrenArray.filter((child) => child.props.value == condition)
  const defaultCases = childrenArray.filter((child) => child.props.isDefault ?? false)

  if (defaultCases.length > 1) {
    throw new Error('At most one default case is allowed')
  }

  const defaultCase = defaultCases[0]

  return cases.length > 0 ? <>{cases}</> : <>{defaultCase}</>
}

type RequireOnlyOne<T, Keys extends keyof T = keyof T> =
  Pick<T, Exclude<keyof T, Keys>> & {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Record<Exclude<Keys, K>, undefined>>
  }[Keys]
interface CaseProps {
  value?: string;
  isDefault?: boolean;
}
type EitherValueOrDefaultType = RequireOnlyOne<CaseProps, 'value' | 'isDefault'>

export const Case = ({ value, isDefault, children }: PropsWithChildren<EitherValueOrDefaultType>) =>
    children ? <>{children}</> : null;
