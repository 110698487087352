import CommentIcon from "@mui/icons-material/Comment";
import { Icon, IconButton } from "@mui/material";
import { colorsSecondary } from "../../../colorStyles";
import { useHasComments } from "../../../hooks/usePlayer";

interface Props {
  playerId: number;
  onOpen: () => void;
}

const HasCommentsIcon = ({ playerId, onOpen }: Props) => {
  const data = useHasComments(playerId);

  return (
    <IconButton aria-label="Duplicate" onClick={onOpen}>
      <CommentIcon
        style={{
          color:
            data.data?.toString() == "true"
              ? colorsSecondary.accent
              : "#CBD5E0",
        }}
        fontSize="small"
      />
    </IconButton>
  );
};

export default HasCommentsIcon;
