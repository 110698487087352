import { Box, Center, Text, VStack } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { useGetShadowteam } from "../../hooks/useShadowteams";
import { ShadowteamEntry } from "../../generated";
import { sortByPositionBackToFront } from "../shadowteams/shadowteamUtilFunctions";
import ShadowteamHeaderPrintView from "./ShadowteamHeaderPrintView";
import PlayerTable, { playerId } from "../general/PlayerTable";
import {
  age,
  contractExpiryDate,
  height,
  marketValue,
  positionsGruppe,
  stammdaten,
  alleyes,
  subjectiveGameRatingsOverall, subjectiveGameRatingsPotential,
  subjectiveOverall,
  subjectivePotential,
} from "../general/ColumnDefinitions";
import { GridColDef } from "@mui/x-data-grid-pro";
import { useContext, useEffect } from "react";
import { GuiTextType, LanguageContext } from "../../store/languageContext";
import { LanguageKeys } from "../../language/LanguageKeys";
import { LocaleKeys } from "../../language/LocaleKeys";

export const columns = (
  guiText: GuiTextType
): GridColDef[] => {
  return [
    stammdaten("row.player", guiText[LanguageKeys.SpielerprofilStammdaten], {}, "row.color"),
    positionsGruppe("row.shadowteamPosition", guiText[LanguageKeys.SearchBarPosition], { flex: 0.6 }),
    age("row.player.age", guiText[LanguageKeys.SearchBarAlter], { flex: 0.5 }),
    height("row.player.height", guiText[LanguageKeys.SearchBarKorpergrobe]),
    contractExpiryDate("row.player.contractExpiryDate", guiText[LanguageKeys.Vertragsende]),
    marketValue("row.player.marketValue", guiText[LanguageKeys.SearchBarMarktwert]),
    alleyes("row.player.alleyes", guiText[LanguageKeys.SearchBarAlleyesAktuell]),
    subjectiveOverall("row.player.subjectiveOverall", guiText[LanguageKeys.GesamtbewertungA], { flex: 1.0 }),
    subjectivePotential("row.player.subjectivePotential", guiText[LanguageKeys.GesamtbewertungP], { flex: 1.0 }),
    subjectiveGameRatingsOverall("row.player.subjectiveGameRatingsOverall", guiText[LanguageKeys.SpielbewertungA], { flex: 1.0 }),
    subjectiveGameRatingsPotential("row.player.subjectiveGameRatingsPotential", guiText[LanguageKeys.SpielbewertungP], { flex: 1.0 }),
  ]
};

const ShadowteamPlayersPrintView = () => {
  const { setLanguage, guiText } = useContext(LanguageContext);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = Number(queryParams.get("id")!);
  const formation = queryParams.get("formation") ?? undefined;

  const { data, isLoading, error } = useGetShadowteam(id, formation);

  const players = sortByPositionBackToFront(data?.players);

  const language = queryParams.get("language");
  useEffect(() => {
    switch (language) {
      case LocaleKeys.DE:
        setLanguage(LocaleKeys.DE);
        break;
      case LocaleKeys.EN:
        setLanguage(LocaleKeys.EN);
        break;
      case LocaleKeys.ES:
        setLanguage(LocaleKeys.ES);
        break;
      default:
        setLanguage(LocaleKeys.DE);
    }
  }, []);

  return (
    <>
      {isLoading && !error &&
        <Center h="100%" w="100%">
          <Text fontSize={20}>{guiText[LanguageKeys.DatenWerdenGeladen]}</Text>
        </Center>
      }
      {!isLoading && error &&
        <Center h="100%" w="100%">
          <Text fontSize={20}>{guiText[LanguageKeys.Datenkonntennichtgeladenwerden]}</Text>
        </Center>
      }
      {!isLoading && data &&
        <VStack id="shadowteam-players-print-view-main-content" w="100%">
          <ShadowteamHeaderPrintView shadowteam={data} />
          <Box h="1150px" width="100%" pb="100px">
            <PlayerTable
              name="printViewShadowteamPlayers"
              players={players ?? []}
              getRowId={(entry: ShadowteamEntry) => playerId(entry.player)}
              columnDefinitions={columns(
                guiText
              )}
              initialState={{ pagination: { paginationModel: { pageSize: 14 } } }}
              sx={{
                "& .MuiDataGrid-iconButtonContainer": {
                  width: "0 !important"
                },
              }}
            />
          </Box>
        </VStack>
      }
    </>
  );
};

export default ShadowteamPlayersPrintView;
