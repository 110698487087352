import { createGlobalStyle } from "styled-components";

const FontStyles = createGlobalStyle`

@font-face {
font-family: "Text_Regular";
src: url("/fonts/Lato-Regular.ttf") format("truetype");
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: "Text_Bold";
src: url("/fonts/Lato-Bold.ttf") format("truetype");
font-weight: bold;
font-style: normal;
}

@font-face {
font-family: "Title_Bold";
src: url("/fonts/Lato-Black.tff") format("truetype");
font-weight: 900; /* Assuming this is a very bold variant */
font-style: normal;
}
  
`;

export default FontStyles;
