/* tslint:disable */
/* eslint-disable */
/**
 * alleyes SCOUT BACKEND
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PolarChartComparisonData
 */
export interface PolarChartComparisonData {
    /**
     * 
     * @type {Date}
     * @memberof PolarChartComparisonData
     */
    datum: Date;
    /**
     * 
     * @type {string}
     * @memberof PolarChartComparisonData
     */
    positionsGruppe: string;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    athletischDistanz1?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    athletischAktivitaetInBallbesitz1?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    athletischAktivitaetGegenDenBall1?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    athletischIntensitaetMitBall1?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    athletischIntensitaetGegenDenBall1?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    athletischSprints1?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    athletischTempolaeufe1?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    athletischExplosivitaet1?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    athletischMaxSpeed1?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    technischPsPaesse1?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    technischPsPaesseAnzahl1?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    technischPsPassenUnterMittleremDruck1?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    technischPsPassenUnterHohemDruck1?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    technischPsFlanken1?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    technischPsTorgefahrSchuesse1?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    technischPsTorgefahrSchuesseAnzahl1?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    technischPsBessermacher1?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    technischPsChancenqualitaet1?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    technischDDribbling1?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    technischDBodenzweikampfInBallbesitz1?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    technischDLuftzweikampfInBallbesitz1?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    technischDBallbehauptungInBallbesitzMittlererDruck1?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    technischDBallbehauptungInBallbesitzHoherDruck1?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    technischDAbwehrGgDribbler1?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    technischDBodenzweikampfGegenDenBall1?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    technischDLuftzweikampfGegenDenBall1?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    technischD2Baelle1?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    taktischRunAbkippen1?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    taktischRunNachruecken1?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    taktischRunEntgegenkommen1?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    taktischRunHinterlaufen1?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    taktischRunLaufInDieHalbspur1?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    taktischRunFreilaufenInDieBreite1?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    taktischRunFreilaufenVorDerGegnKette1?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    taktischRunTiefenlaeufeHinterDieKette1?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    taktischRunLaufInDieBox1?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    taktischPassesAbkippen1?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    taktischPassesNachruecken1?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    taktischPassesEntgegenkommen1?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    taktischPassesHinterlaufen1?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    taktischPassesLaufInDieHalbspur1?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    taktischPassesFreilaufenInDieBreite1?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    taktischPassesFreilaufenVorDerGegnKette1?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    taktischPassesTiefenlaeufeHinterDieKette1?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    taktischPassesLaufInDieBox1?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    athletischDistanz2?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    athletischAktivitaetInBallbesitz2?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    athletischAktivitaetGegenDenBall2?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    athletischIntensitaetMitBall2?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    athletischIntensitaetGegenDenBall2?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    athletischSprints2?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    athletischTempolaeufe2?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    athletischExplosivitaet2?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    athletischMaxSpeed2?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    technischPsPaesse2?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    technischPsPaesseAnzahl2?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    technischPsPassenUnterMittleremDruck2?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    technischPsPassenUnterHohemDruck2?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    technischPsFlanken2?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    technischPsTorgefahrSchuesse2?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    technischPsTorgefahrSchuesseAnzahl2?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    technischPsBessermacher2?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    technischPsChancenqualitaet2?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    technischDDribbling2?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    technischDBodenzweikampfInBallbesitz2?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    technischDLuftzweikampfInBallbesitz2?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    technischDBallbehauptungInBallbesitzMittlererDruck2?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    technischDBallbehauptungInBallbesitzHoherDruck2?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    technischDAbwehrGgDribbler2?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    technischDBodenzweikampfGegenDenBall2?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    technischDLuftzweikampfGegenDenBall2?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    technischD2Baelle2?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    taktischRunAbkippen2?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    taktischRunNachruecken2?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    taktischRunEntgegenkommen2?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    taktischRunHinterlaufen2?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    taktischRunLaufInDieHalbspur2?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    taktischRunFreilaufenInDieBreite2?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    taktischRunFreilaufenVorDerGegnKette2?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    taktischRunTiefenlaeufeHinterDieKette2?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    taktischRunLaufInDieBox2?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    taktischPassesAbkippen2?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    taktischPassesNachruecken2?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    taktischPassesEntgegenkommen2?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    taktischPassesHinterlaufen2?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    taktischPassesLaufInDieHalbspur2?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    taktischPassesFreilaufenInDieBreite2?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    taktischPassesFreilaufenVorDerGegnKette2?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    taktischPassesTiefenlaeufeHinterDieKette2?: number;
    /**
     * 
     * @type {number}
     * @memberof PolarChartComparisonData
     */
    taktischPassesLaufInDieBox2?: number;
}

/**
 * Check if a given object implements the PolarChartComparisonData interface.
 */
export function instanceOfPolarChartComparisonData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "datum" in value;
    isInstance = isInstance && "positionsGruppe" in value;

    return isInstance;
}

export function PolarChartComparisonDataFromJSON(json: any): PolarChartComparisonData {
    return PolarChartComparisonDataFromJSONTyped(json, false);
}

export function PolarChartComparisonDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): PolarChartComparisonData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'datum': (new Date(json['datum'])),
        'positionsGruppe': json['positionsGruppe'],
        'athletischDistanz1': !exists(json, 'athletischDistanz1') ? undefined : json['athletischDistanz1'],
        'athletischAktivitaetInBallbesitz1': !exists(json, 'athletischAktivitaetInBallbesitz1') ? undefined : json['athletischAktivitaetInBallbesitz1'],
        'athletischAktivitaetGegenDenBall1': !exists(json, 'athletischAktivitaetGegenDenBall1') ? undefined : json['athletischAktivitaetGegenDenBall1'],
        'athletischIntensitaetMitBall1': !exists(json, 'athletischIntensitaetMitBall1') ? undefined : json['athletischIntensitaetMitBall1'],
        'athletischIntensitaetGegenDenBall1': !exists(json, 'athletischIntensitaetGegenDenBall1') ? undefined : json['athletischIntensitaetGegenDenBall1'],
        'athletischSprints1': !exists(json, 'athletischSprints1') ? undefined : json['athletischSprints1'],
        'athletischTempolaeufe1': !exists(json, 'athletischTempolaeufe1') ? undefined : json['athletischTempolaeufe1'],
        'athletischExplosivitaet1': !exists(json, 'athletischExplosivitaet1') ? undefined : json['athletischExplosivitaet1'],
        'athletischMaxSpeed1': !exists(json, 'athletischMaxSpeed1') ? undefined : json['athletischMaxSpeed1'],
        'technischPsPaesse1': !exists(json, 'technischPsPaesse1') ? undefined : json['technischPsPaesse1'],
        'technischPsPaesseAnzahl1': !exists(json, 'technischPsPaesseAnzahl1') ? undefined : json['technischPsPaesseAnzahl1'],
        'technischPsPassenUnterMittleremDruck1': !exists(json, 'technischPsPassenUnterMittleremDruck1') ? undefined : json['technischPsPassenUnterMittleremDruck1'],
        'technischPsPassenUnterHohemDruck1': !exists(json, 'technischPsPassenUnterHohemDruck1') ? undefined : json['technischPsPassenUnterHohemDruck1'],
        'technischPsFlanken1': !exists(json, 'technischPsFlanken1') ? undefined : json['technischPsFlanken1'],
        'technischPsTorgefahrSchuesse1': !exists(json, 'technischPsTorgefahrSchuesse1') ? undefined : json['technischPsTorgefahrSchuesse1'],
        'technischPsTorgefahrSchuesseAnzahl1': !exists(json, 'technischPsTorgefahrSchuesseAnzahl1') ? undefined : json['technischPsTorgefahrSchuesseAnzahl1'],
        'technischPsBessermacher1': !exists(json, 'technischPsBessermacher1') ? undefined : json['technischPsBessermacher1'],
        'technischPsChancenqualitaet1': !exists(json, 'technischPsChancenqualitaet1') ? undefined : json['technischPsChancenqualitaet1'],
        'technischDDribbling1': !exists(json, 'technischDDribbling1') ? undefined : json['technischDDribbling1'],
        'technischDBodenzweikampfInBallbesitz1': !exists(json, 'technischDBodenzweikampfInBallbesitz1') ? undefined : json['technischDBodenzweikampfInBallbesitz1'],
        'technischDLuftzweikampfInBallbesitz1': !exists(json, 'technischDLuftzweikampfInBallbesitz1') ? undefined : json['technischDLuftzweikampfInBallbesitz1'],
        'technischDBallbehauptungInBallbesitzMittlererDruck1': !exists(json, 'technischDBallbehauptungInBallbesitzMittlererDruck1') ? undefined : json['technischDBallbehauptungInBallbesitzMittlererDruck1'],
        'technischDBallbehauptungInBallbesitzHoherDruck1': !exists(json, 'technischDBallbehauptungInBallbesitzHoherDruck1') ? undefined : json['technischDBallbehauptungInBallbesitzHoherDruck1'],
        'technischDAbwehrGgDribbler1': !exists(json, 'technischDAbwehrGgDribbler1') ? undefined : json['technischDAbwehrGgDribbler1'],
        'technischDBodenzweikampfGegenDenBall1': !exists(json, 'technischDBodenzweikampfGegenDenBall1') ? undefined : json['technischDBodenzweikampfGegenDenBall1'],
        'technischDLuftzweikampfGegenDenBall1': !exists(json, 'technischDLuftzweikampfGegenDenBall1') ? undefined : json['technischDLuftzweikampfGegenDenBall1'],
        'technischD2Baelle1': !exists(json, 'technischD2Baelle1') ? undefined : json['technischD2Baelle1'],
        'taktischRunAbkippen1': !exists(json, 'taktischRunAbkippen1') ? undefined : json['taktischRunAbkippen1'],
        'taktischRunNachruecken1': !exists(json, 'taktischRunNachruecken1') ? undefined : json['taktischRunNachruecken1'],
        'taktischRunEntgegenkommen1': !exists(json, 'taktischRunEntgegenkommen1') ? undefined : json['taktischRunEntgegenkommen1'],
        'taktischRunHinterlaufen1': !exists(json, 'taktischRunHinterlaufen1') ? undefined : json['taktischRunHinterlaufen1'],
        'taktischRunLaufInDieHalbspur1': !exists(json, 'taktischRunLaufInDieHalbspur1') ? undefined : json['taktischRunLaufInDieHalbspur1'],
        'taktischRunFreilaufenInDieBreite1': !exists(json, 'taktischRunFreilaufenInDieBreite1') ? undefined : json['taktischRunFreilaufenInDieBreite1'],
        'taktischRunFreilaufenVorDerGegnKette1': !exists(json, 'taktischRunFreilaufenVorDerGegnKette1') ? undefined : json['taktischRunFreilaufenVorDerGegnKette1'],
        'taktischRunTiefenlaeufeHinterDieKette1': !exists(json, 'taktischRunTiefenlaeufeHinterDieKette1') ? undefined : json['taktischRunTiefenlaeufeHinterDieKette1'],
        'taktischRunLaufInDieBox1': !exists(json, 'taktischRunLaufInDieBox1') ? undefined : json['taktischRunLaufInDieBox1'],
        'taktischPassesAbkippen1': !exists(json, 'taktischPassesAbkippen1') ? undefined : json['taktischPassesAbkippen1'],
        'taktischPassesNachruecken1': !exists(json, 'taktischPassesNachruecken1') ? undefined : json['taktischPassesNachruecken1'],
        'taktischPassesEntgegenkommen1': !exists(json, 'taktischPassesEntgegenkommen1') ? undefined : json['taktischPassesEntgegenkommen1'],
        'taktischPassesHinterlaufen1': !exists(json, 'taktischPassesHinterlaufen1') ? undefined : json['taktischPassesHinterlaufen1'],
        'taktischPassesLaufInDieHalbspur1': !exists(json, 'taktischPassesLaufInDieHalbspur1') ? undefined : json['taktischPassesLaufInDieHalbspur1'],
        'taktischPassesFreilaufenInDieBreite1': !exists(json, 'taktischPassesFreilaufenInDieBreite1') ? undefined : json['taktischPassesFreilaufenInDieBreite1'],
        'taktischPassesFreilaufenVorDerGegnKette1': !exists(json, 'taktischPassesFreilaufenVorDerGegnKette1') ? undefined : json['taktischPassesFreilaufenVorDerGegnKette1'],
        'taktischPassesTiefenlaeufeHinterDieKette1': !exists(json, 'taktischPassesTiefenlaeufeHinterDieKette1') ? undefined : json['taktischPassesTiefenlaeufeHinterDieKette1'],
        'taktischPassesLaufInDieBox1': !exists(json, 'taktischPassesLaufInDieBox1') ? undefined : json['taktischPassesLaufInDieBox1'],
        'athletischDistanz2': !exists(json, 'athletischDistanz2') ? undefined : json['athletischDistanz2'],
        'athletischAktivitaetInBallbesitz2': !exists(json, 'athletischAktivitaetInBallbesitz2') ? undefined : json['athletischAktivitaetInBallbesitz2'],
        'athletischAktivitaetGegenDenBall2': !exists(json, 'athletischAktivitaetGegenDenBall2') ? undefined : json['athletischAktivitaetGegenDenBall2'],
        'athletischIntensitaetMitBall2': !exists(json, 'athletischIntensitaetMitBall2') ? undefined : json['athletischIntensitaetMitBall2'],
        'athletischIntensitaetGegenDenBall2': !exists(json, 'athletischIntensitaetGegenDenBall2') ? undefined : json['athletischIntensitaetGegenDenBall2'],
        'athletischSprints2': !exists(json, 'athletischSprints2') ? undefined : json['athletischSprints2'],
        'athletischTempolaeufe2': !exists(json, 'athletischTempolaeufe2') ? undefined : json['athletischTempolaeufe2'],
        'athletischExplosivitaet2': !exists(json, 'athletischExplosivitaet2') ? undefined : json['athletischExplosivitaet2'],
        'athletischMaxSpeed2': !exists(json, 'athletischMaxSpeed2') ? undefined : json['athletischMaxSpeed2'],
        'technischPsPaesse2': !exists(json, 'technischPsPaesse2') ? undefined : json['technischPsPaesse2'],
        'technischPsPaesseAnzahl2': !exists(json, 'technischPsPaesseAnzahl2') ? undefined : json['technischPsPaesseAnzahl2'],
        'technischPsPassenUnterMittleremDruck2': !exists(json, 'technischPsPassenUnterMittleremDruck2') ? undefined : json['technischPsPassenUnterMittleremDruck2'],
        'technischPsPassenUnterHohemDruck2': !exists(json, 'technischPsPassenUnterHohemDruck2') ? undefined : json['technischPsPassenUnterHohemDruck2'],
        'technischPsFlanken2': !exists(json, 'technischPsFlanken2') ? undefined : json['technischPsFlanken2'],
        'technischPsTorgefahrSchuesse2': !exists(json, 'technischPsTorgefahrSchuesse2') ? undefined : json['technischPsTorgefahrSchuesse2'],
        'technischPsTorgefahrSchuesseAnzahl2': !exists(json, 'technischPsTorgefahrSchuesseAnzahl2') ? undefined : json['technischPsTorgefahrSchuesseAnzahl2'],
        'technischPsBessermacher2': !exists(json, 'technischPsBessermacher2') ? undefined : json['technischPsBessermacher2'],
        'technischPsChancenqualitaet2': !exists(json, 'technischPsChancenqualitaet2') ? undefined : json['technischPsChancenqualitaet2'],
        'technischDDribbling2': !exists(json, 'technischDDribbling2') ? undefined : json['technischDDribbling2'],
        'technischDBodenzweikampfInBallbesitz2': !exists(json, 'technischDBodenzweikampfInBallbesitz2') ? undefined : json['technischDBodenzweikampfInBallbesitz2'],
        'technischDLuftzweikampfInBallbesitz2': !exists(json, 'technischDLuftzweikampfInBallbesitz2') ? undefined : json['technischDLuftzweikampfInBallbesitz2'],
        'technischDBallbehauptungInBallbesitzMittlererDruck2': !exists(json, 'technischDBallbehauptungInBallbesitzMittlererDruck2') ? undefined : json['technischDBallbehauptungInBallbesitzMittlererDruck2'],
        'technischDBallbehauptungInBallbesitzHoherDruck2': !exists(json, 'technischDBallbehauptungInBallbesitzHoherDruck2') ? undefined : json['technischDBallbehauptungInBallbesitzHoherDruck2'],
        'technischDAbwehrGgDribbler2': !exists(json, 'technischDAbwehrGgDribbler2') ? undefined : json['technischDAbwehrGgDribbler2'],
        'technischDBodenzweikampfGegenDenBall2': !exists(json, 'technischDBodenzweikampfGegenDenBall2') ? undefined : json['technischDBodenzweikampfGegenDenBall2'],
        'technischDLuftzweikampfGegenDenBall2': !exists(json, 'technischDLuftzweikampfGegenDenBall2') ? undefined : json['technischDLuftzweikampfGegenDenBall2'],
        'technischD2Baelle2': !exists(json, 'technischD2Baelle2') ? undefined : json['technischD2Baelle2'],
        'taktischRunAbkippen2': !exists(json, 'taktischRunAbkippen2') ? undefined : json['taktischRunAbkippen2'],
        'taktischRunNachruecken2': !exists(json, 'taktischRunNachruecken2') ? undefined : json['taktischRunNachruecken2'],
        'taktischRunEntgegenkommen2': !exists(json, 'taktischRunEntgegenkommen2') ? undefined : json['taktischRunEntgegenkommen2'],
        'taktischRunHinterlaufen2': !exists(json, 'taktischRunHinterlaufen2') ? undefined : json['taktischRunHinterlaufen2'],
        'taktischRunLaufInDieHalbspur2': !exists(json, 'taktischRunLaufInDieHalbspur2') ? undefined : json['taktischRunLaufInDieHalbspur2'],
        'taktischRunFreilaufenInDieBreite2': !exists(json, 'taktischRunFreilaufenInDieBreite2') ? undefined : json['taktischRunFreilaufenInDieBreite2'],
        'taktischRunFreilaufenVorDerGegnKette2': !exists(json, 'taktischRunFreilaufenVorDerGegnKette2') ? undefined : json['taktischRunFreilaufenVorDerGegnKette2'],
        'taktischRunTiefenlaeufeHinterDieKette2': !exists(json, 'taktischRunTiefenlaeufeHinterDieKette2') ? undefined : json['taktischRunTiefenlaeufeHinterDieKette2'],
        'taktischRunLaufInDieBox2': !exists(json, 'taktischRunLaufInDieBox2') ? undefined : json['taktischRunLaufInDieBox2'],
        'taktischPassesAbkippen2': !exists(json, 'taktischPassesAbkippen2') ? undefined : json['taktischPassesAbkippen2'],
        'taktischPassesNachruecken2': !exists(json, 'taktischPassesNachruecken2') ? undefined : json['taktischPassesNachruecken2'],
        'taktischPassesEntgegenkommen2': !exists(json, 'taktischPassesEntgegenkommen2') ? undefined : json['taktischPassesEntgegenkommen2'],
        'taktischPassesHinterlaufen2': !exists(json, 'taktischPassesHinterlaufen2') ? undefined : json['taktischPassesHinterlaufen2'],
        'taktischPassesLaufInDieHalbspur2': !exists(json, 'taktischPassesLaufInDieHalbspur2') ? undefined : json['taktischPassesLaufInDieHalbspur2'],
        'taktischPassesFreilaufenInDieBreite2': !exists(json, 'taktischPassesFreilaufenInDieBreite2') ? undefined : json['taktischPassesFreilaufenInDieBreite2'],
        'taktischPassesFreilaufenVorDerGegnKette2': !exists(json, 'taktischPassesFreilaufenVorDerGegnKette2') ? undefined : json['taktischPassesFreilaufenVorDerGegnKette2'],
        'taktischPassesTiefenlaeufeHinterDieKette2': !exists(json, 'taktischPassesTiefenlaeufeHinterDieKette2') ? undefined : json['taktischPassesTiefenlaeufeHinterDieKette2'],
        'taktischPassesLaufInDieBox2': !exists(json, 'taktischPassesLaufInDieBox2') ? undefined : json['taktischPassesLaufInDieBox2'],
    };
}

export function PolarChartComparisonDataToJSON(value?: PolarChartComparisonData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'datum': (value.datum.toISOString().substr(0,10)),
        'positionsGruppe': value.positionsGruppe,
        'athletischDistanz1': value.athletischDistanz1,
        'athletischAktivitaetInBallbesitz1': value.athletischAktivitaetInBallbesitz1,
        'athletischAktivitaetGegenDenBall1': value.athletischAktivitaetGegenDenBall1,
        'athletischIntensitaetMitBall1': value.athletischIntensitaetMitBall1,
        'athletischIntensitaetGegenDenBall1': value.athletischIntensitaetGegenDenBall1,
        'athletischSprints1': value.athletischSprints1,
        'athletischTempolaeufe1': value.athletischTempolaeufe1,
        'athletischExplosivitaet1': value.athletischExplosivitaet1,
        'athletischMaxSpeed1': value.athletischMaxSpeed1,
        'technischPsPaesse1': value.technischPsPaesse1,
        'technischPsPaesseAnzahl1': value.technischPsPaesseAnzahl1,
        'technischPsPassenUnterMittleremDruck1': value.technischPsPassenUnterMittleremDruck1,
        'technischPsPassenUnterHohemDruck1': value.technischPsPassenUnterHohemDruck1,
        'technischPsFlanken1': value.technischPsFlanken1,
        'technischPsTorgefahrSchuesse1': value.technischPsTorgefahrSchuesse1,
        'technischPsTorgefahrSchuesseAnzahl1': value.technischPsTorgefahrSchuesseAnzahl1,
        'technischPsBessermacher1': value.technischPsBessermacher1,
        'technischPsChancenqualitaet1': value.technischPsChancenqualitaet1,
        'technischDDribbling1': value.technischDDribbling1,
        'technischDBodenzweikampfInBallbesitz1': value.technischDBodenzweikampfInBallbesitz1,
        'technischDLuftzweikampfInBallbesitz1': value.technischDLuftzweikampfInBallbesitz1,
        'technischDBallbehauptungInBallbesitzMittlererDruck1': value.technischDBallbehauptungInBallbesitzMittlererDruck1,
        'technischDBallbehauptungInBallbesitzHoherDruck1': value.technischDBallbehauptungInBallbesitzHoherDruck1,
        'technischDAbwehrGgDribbler1': value.technischDAbwehrGgDribbler1,
        'technischDBodenzweikampfGegenDenBall1': value.technischDBodenzweikampfGegenDenBall1,
        'technischDLuftzweikampfGegenDenBall1': value.technischDLuftzweikampfGegenDenBall1,
        'technischD2Baelle1': value.technischD2Baelle1,
        'taktischRunAbkippen1': value.taktischRunAbkippen1,
        'taktischRunNachruecken1': value.taktischRunNachruecken1,
        'taktischRunEntgegenkommen1': value.taktischRunEntgegenkommen1,
        'taktischRunHinterlaufen1': value.taktischRunHinterlaufen1,
        'taktischRunLaufInDieHalbspur1': value.taktischRunLaufInDieHalbspur1,
        'taktischRunFreilaufenInDieBreite1': value.taktischRunFreilaufenInDieBreite1,
        'taktischRunFreilaufenVorDerGegnKette1': value.taktischRunFreilaufenVorDerGegnKette1,
        'taktischRunTiefenlaeufeHinterDieKette1': value.taktischRunTiefenlaeufeHinterDieKette1,
        'taktischRunLaufInDieBox1': value.taktischRunLaufInDieBox1,
        'taktischPassesAbkippen1': value.taktischPassesAbkippen1,
        'taktischPassesNachruecken1': value.taktischPassesNachruecken1,
        'taktischPassesEntgegenkommen1': value.taktischPassesEntgegenkommen1,
        'taktischPassesHinterlaufen1': value.taktischPassesHinterlaufen1,
        'taktischPassesLaufInDieHalbspur1': value.taktischPassesLaufInDieHalbspur1,
        'taktischPassesFreilaufenInDieBreite1': value.taktischPassesFreilaufenInDieBreite1,
        'taktischPassesFreilaufenVorDerGegnKette1': value.taktischPassesFreilaufenVorDerGegnKette1,
        'taktischPassesTiefenlaeufeHinterDieKette1': value.taktischPassesTiefenlaeufeHinterDieKette1,
        'taktischPassesLaufInDieBox1': value.taktischPassesLaufInDieBox1,
        'athletischDistanz2': value.athletischDistanz2,
        'athletischAktivitaetInBallbesitz2': value.athletischAktivitaetInBallbesitz2,
        'athletischAktivitaetGegenDenBall2': value.athletischAktivitaetGegenDenBall2,
        'athletischIntensitaetMitBall2': value.athletischIntensitaetMitBall2,
        'athletischIntensitaetGegenDenBall2': value.athletischIntensitaetGegenDenBall2,
        'athletischSprints2': value.athletischSprints2,
        'athletischTempolaeufe2': value.athletischTempolaeufe2,
        'athletischExplosivitaet2': value.athletischExplosivitaet2,
        'athletischMaxSpeed2': value.athletischMaxSpeed2,
        'technischPsPaesse2': value.technischPsPaesse2,
        'technischPsPaesseAnzahl2': value.technischPsPaesseAnzahl2,
        'technischPsPassenUnterMittleremDruck2': value.technischPsPassenUnterMittleremDruck2,
        'technischPsPassenUnterHohemDruck2': value.technischPsPassenUnterHohemDruck2,
        'technischPsFlanken2': value.technischPsFlanken2,
        'technischPsTorgefahrSchuesse2': value.technischPsTorgefahrSchuesse2,
        'technischPsTorgefahrSchuesseAnzahl2': value.technischPsTorgefahrSchuesseAnzahl2,
        'technischPsBessermacher2': value.technischPsBessermacher2,
        'technischPsChancenqualitaet2': value.technischPsChancenqualitaet2,
        'technischDDribbling2': value.technischDDribbling2,
        'technischDBodenzweikampfInBallbesitz2': value.technischDBodenzweikampfInBallbesitz2,
        'technischDLuftzweikampfInBallbesitz2': value.technischDLuftzweikampfInBallbesitz2,
        'technischDBallbehauptungInBallbesitzMittlererDruck2': value.technischDBallbehauptungInBallbesitzMittlererDruck2,
        'technischDBallbehauptungInBallbesitzHoherDruck2': value.technischDBallbehauptungInBallbesitzHoherDruck2,
        'technischDAbwehrGgDribbler2': value.technischDAbwehrGgDribbler2,
        'technischDBodenzweikampfGegenDenBall2': value.technischDBodenzweikampfGegenDenBall2,
        'technischDLuftzweikampfGegenDenBall2': value.technischDLuftzweikampfGegenDenBall2,
        'technischD2Baelle2': value.technischD2Baelle2,
        'taktischRunAbkippen2': value.taktischRunAbkippen2,
        'taktischRunNachruecken2': value.taktischRunNachruecken2,
        'taktischRunEntgegenkommen2': value.taktischRunEntgegenkommen2,
        'taktischRunHinterlaufen2': value.taktischRunHinterlaufen2,
        'taktischRunLaufInDieHalbspur2': value.taktischRunLaufInDieHalbspur2,
        'taktischRunFreilaufenInDieBreite2': value.taktischRunFreilaufenInDieBreite2,
        'taktischRunFreilaufenVorDerGegnKette2': value.taktischRunFreilaufenVorDerGegnKette2,
        'taktischRunTiefenlaeufeHinterDieKette2': value.taktischRunTiefenlaeufeHinterDieKette2,
        'taktischRunLaufInDieBox2': value.taktischRunLaufInDieBox2,
        'taktischPassesAbkippen2': value.taktischPassesAbkippen2,
        'taktischPassesNachruecken2': value.taktischPassesNachruecken2,
        'taktischPassesEntgegenkommen2': value.taktischPassesEntgegenkommen2,
        'taktischPassesHinterlaufen2': value.taktischPassesHinterlaufen2,
        'taktischPassesLaufInDieHalbspur2': value.taktischPassesLaufInDieHalbspur2,
        'taktischPassesFreilaufenInDieBreite2': value.taktischPassesFreilaufenInDieBreite2,
        'taktischPassesFreilaufenVorDerGegnKette2': value.taktischPassesFreilaufenVorDerGegnKette2,
        'taktischPassesTiefenlaeufeHinterDieKette2': value.taktischPassesTiefenlaeufeHinterDieKette2,
        'taktischPassesLaufInDieBox2': value.taktischPassesLaufInDieBox2,
    };
}

