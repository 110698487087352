/* tslint:disable */
/* eslint-disable */
/**
 * alleyes SCOUT BACKEND
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FilterValue } from './FilterValue';
import {
    FilterValueFromJSON,
    FilterValueFromJSONTyped,
    FilterValueToJSON,
} from './FilterValue';
import type { PaginationInfo } from './PaginationInfo';
import {
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
} from './PaginationInfo';
import type { SortingInfo } from './SortingInfo';
import {
    SortingInfoFromJSON,
    SortingInfoFromJSONTyped,
    SortingInfoToJSON,
} from './SortingInfo';

/**
 * 
 * @export
 * @interface PlayerQuery
 */
export interface PlayerQuery {
    /**
     * 
     * @type {PaginationInfo}
     * @memberof PlayerQuery
     */
    paginationInfo?: PaginationInfo;
    /**
     * 
     * @type {SortingInfo}
     * @memberof PlayerQuery
     */
    sortingInfo?: SortingInfo;
    /**
     * 
     * @type {string}
     * @memberof PlayerQuery
     */
    playerBase?: string;
    /**
     * 
     * @type {Array<FilterValue>}
     * @memberof PlayerQuery
     */
    filters?: Array<FilterValue>;
}

/**
 * Check if a given object implements the PlayerQuery interface.
 */
export function instanceOfPlayerQuery(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PlayerQueryFromJSON(json: any): PlayerQuery {
    return PlayerQueryFromJSONTyped(json, false);
}

export function PlayerQueryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlayerQuery {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paginationInfo': !exists(json, 'paginationInfo') ? undefined : PaginationInfoFromJSON(json['paginationInfo']),
        'sortingInfo': !exists(json, 'sortingInfo') ? undefined : SortingInfoFromJSON(json['sortingInfo']),
        'playerBase': !exists(json, 'playerBase') ? undefined : json['playerBase'],
        'filters': !exists(json, 'filters') ? undefined : ((json['filters'] as Array<any>).map(FilterValueFromJSON)),
    };
}

export function PlayerQueryToJSON(value?: PlayerQuery | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'paginationInfo': PaginationInfoToJSON(value.paginationInfo),
        'sortingInfo': SortingInfoToJSON(value.sortingInfo),
        'playerBase': value.playerBase,
        'filters': value.filters === undefined ? undefined : ((value.filters as Array<any>).map(FilterValueToJSON)),
    };
}

