import {createContext, Dispatch, PropsWithChildren, SetStateAction, useEffect, useMemo, useState} from 'react';
import { LocaleKeys } from '../language/LocaleKeys';
import {LanguageKeys} from "../language/LanguageKeys";
import { guiText as germanGuiText } from '../language/germanLocale';

export type GuiTextType = { [key in LanguageKeys]: string };

export interface GuiTextProps {
  guiText: GuiTextType;
}

interface Props extends GuiTextProps {
  language: LocaleKeys;
  setLanguage: Dispatch<SetStateAction<LocaleKeys>>;
  guiText: GuiTextType;
}

export interface LanguageProps {
  language: LocaleKeys;
}

const getBrowserDefaultLanguage = (): LocaleKeys => {
  const tag = navigator.language.slice(0, 2).toUpperCase();
  switch (tag) {
    case "DE":
      return LocaleKeys.DE;
    case "EN":
      return LocaleKeys.EN;
    case "ES":
      return LocaleKeys.ES;
    default:
      return LocaleKeys.EN;
  }
}

const defaultContext: Props = {
  language: LocaleKeys.DE,
  setLanguage: (action: SetStateAction<LocaleKeys>) => {},
  guiText: germanGuiText
}

const LanguageContext = createContext(defaultContext);

const LanguageProvider = ({ children }: PropsWithChildren) => {
  const [language, setLanguage] = useState(LocaleKeys.DE);

  const [guiText, setGuiText] = useState<GuiTextType>(germanGuiText);

  useEffect(() => {
    getGuiText();
  }, [language]);

  const getGuiText = () => {
    const loadGuiText = async () => (await import(`../language/${language}Locale`)).guiText;

    loadGuiText()
      .then(data => setGuiText(data))
      .catch((error) => console.error('Error fetching language data:', error));
  };

  const memoizedContextValues: Props =  useMemo(() => ({language, setLanguage, guiText}), [language, setLanguage, guiText]);

  return (
    <LanguageContext.Provider value={memoizedContextValues}>
      {children}
    </LanguageContext.Provider>
  );
};

export { LanguageContext, LanguageProvider };
