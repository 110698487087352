import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { isEqual, isLastDayOfMonth, startOfDay } from "date-fns";
import ms from "ms";
import { useNavigate } from "react-router-dom";
import {
  Comment,
  GetGamesAndGametime200Response,
  Heatmap,
  Injury, KpiClip,
  MarketValueEntry,
  Player,
  PlayerBaseData, PlayerQuery,
  PolarChartComparisonData,
  PolarChartData,
  Rating,
  AlleyesRatingHistory,
  ShadowteamBaseInfo,
  Shot,
  Transfer,
} from "../generated";
import { usePlayerProfileStore, useSimilarPlayersQueryStore } from "../store/store";
import { getPlayerApi } from "../util/keycloak";
import { formatDate } from "../util/utilFunctions";

/////////////////////////////////////////////////////////////////////////////

const fetchPlayerBaseData = async (id: number): Promise<PlayerBaseData> => {
  const api = getPlayerApi();
  return api.getPlayer(id);
};

export const usePlayerBaseData = (id: number) => {
  return useQuery<PlayerBaseData, Error>(
    ["player", id],
    () => fetchPlayerBaseData(id),
    {
      staleTime: ms("10h"),
    }
  );
};

/////////////////////////////////////////////////////////////////////////////

export const fetchPlayer = async (
  id: number,
  position: string
): Promise<Player> => {
  const api = getPlayerApi();
  return await api.getPlayerWithPosition(id, position);
};

export const usePlayer = (id: number, position: string) => {
  return useQuery<Player, Error>(
    ["player", id, position],
    () => fetchPlayer(id, position),
    {
      staleTime: ms("10h"),
      enabled: id !== -1 && position !== null && position !== undefined,
    }
  );
};

/////////////////////////////////////////////////////////////////////////////

export const fetchPlayerWithDate = async (
  id: number,
  position: string,
  date: Date
): Promise<Player> => {
  const api = getPlayerApi();
  return await api.getPlayerWithPositionAndDate(id, position, date);
};

export const usePlayerWithDate = (id: number, position: string) => {
  const { date } = usePlayerProfileStore();

  return useQuery<Player, Error>(
    ["player", id, position, date],
    () => fetchPlayerWithDate(id, position, date),
    {
      staleTime: ms("10h"),
      enabled: id !== -1 && position !== null && position !== undefined && date !== null && date !== undefined,
    }
  );
};

/////////////////////////////////////////////////////////////////////////////

const fetchPolarChartData = async (
  id: number | undefined,
  date?: Date | undefined,
  position?: string | undefined
): Promise<PolarChartData[]> => {
  const api = getPlayerApi();
  return api.getPolarCharts(id!, date, position);
};

export const usePolarChartData = (
  id: number | undefined,
  position?: string,
  datee?: Date
) => {
  const { date: profileDate } = usePlayerProfileStore();

  const date = datee ?? profileDate;

  const dateString = formatDate(date);

  const isToday = dateString === formatDate(new Date());
  const shouldFetch = id !== undefined;

  return useQuery<PolarChartData[], Error>(
    ["polarcharts", id, dateString, position],
    () =>
      isToday
        ? position
          ? fetchPolarChartData(id, undefined, position)
          : fetchPolarChartData(id)
        : position
          ? fetchPolarChartData(id, date, position)
          : fetchPolarChartData(id, date),
    {
      enabled: shouldFetch,
      staleTime: ms("10h"),
    }
  );
};

/////////////////////////////////////////////////////////////////////////////

const fetchPolarChartComparisonData = async (
  position: string,
  date: Date
): Promise<PolarChartComparisonData> => {
  const api = getPlayerApi();
  return api.getPolarChartsComparisonData(position, date);
};

export const usePolarChartComparisonData = (
  position: string | undefined,
  datee?: Date
) => {
  const { date: profileDate } = usePlayerProfileStore();

  const date = datee ?? profileDate;

  return useQuery<PolarChartComparisonData, Error>(
    ["polarchartcomparisondata", position, formatDate(date)],
    () => fetchPolarChartComparisonData(position!, date),
    {
      staleTime: ms("10h"),
      retry: 0,
      enabled: position !== undefined,
    }
  );
};

/////////////////////////////////////////////////////////////////////////////

const fetchRatingHistory = async (
  id: number,
  position: string,
  rating: string
): Promise<Rating[]> => {
  const api = getPlayerApi();
  return api.getRatingHistory(id, position, rating);
};

export const useRatingHistory = (
  id: number | undefined,
  position: string | undefined,
  rating: string | undefined
) => {
  const enabled =
    position !== undefined && rating !== undefined && id !== undefined;

  return useQuery<Rating[], Error>(
    ["ratinghistory", id, position, rating],
    () => fetchRatingHistory(id!, position!, rating!),
    {
      staleTime: ms("10h"),
      enabled: enabled,
    }
  );
};

//////////////////////////////////////////////////////////////////////////
const fetchTransferHistory = async (id: number): Promise<Transfer[]> => {
  const api = getPlayerApi();
  return api.getTransferHistory(id);
};

export const useTransferHistory = (id: number) => {
  return useQuery<Transfer[], Error>(
    ["transferhistory", id],
    () => fetchTransferHistory(id),
    {
      staleTime: ms("10h"),
      enabled: !isNaN(id),
    }
  );
};

////////////////////////////////////////////////////////////////////////

const fetchMarketHistory = async (id: number): Promise<MarketValueEntry[]> => {
  const api = getPlayerApi();
  return api.getMarketHistory(id);
};

export const useMarketHistory = (id: number) => {
  return useQuery<MarketValueEntry[], Error>(
    ["markethistory", id],
    () => fetchMarketHistory(id),
    {
      staleTime: ms("10h"),
      enabled: !isNaN(id),
    }
  );
};

////////////////////////////////////////////////////////////////////////

const fetchInjuryHistory = async (id: number): Promise<Injury[]> => {
  const api = getPlayerApi();
  return api.getInjuryHistory(id);
};

export const useInjuryHistory = (id: number) => {
  return useQuery<Injury[], Error>(
    ["injuryhistory", id],
    () => fetchInjuryHistory(id),
    {
      staleTime: ms("10h"),
      enabled: !isNaN(id),
    }
  );
};

////////////////////////////////////////////////////////////////////////

const fetchShotHistory = async (
  id: number,
  position: string,
  date: Date
): Promise<Shot[]> => {
  const api = getPlayerApi();
  return api.getShots(id, position, date);
};

export const useShotHistory = (id: number, position: string) => {
  const { date } = usePlayerProfileStore();

  return useQuery<Shot[], Error>(
    ["shots", id, position, formatDate(date)],
    () => fetchShotHistory(id, position, date),
    {
      staleTime: ms("10h"),
    }
  );
};

////////////////////////////////////////////////////////////////////////

const fetchalleyesRatingHistory = async (
  id: number,
  position: string
): Promise<AlleyesRatingHistory> => {
  const api = getPlayerApi();
  return api.getAlleyesRatingHistory(id, position);
};

export const useAlleyesRatingHistory = (id: number, position: string) => {
  return useQuery<AlleyesRatingHistory, Error>(
    ["alleyesratinghistory", id, position],
    () => fetchalleyesRatingHistory(id, position),
    {
      staleTime: ms("10h"),
    }
  );
};

////////////////////////////////////////////////////////////////////////

const fetchProfilePicture = async (id: number): Promise<Blob> => {
  const api = getPlayerApi();
  return await api.getProfilePicture(id, {
    headers: {
      Accept: "image/png",
    },
  });
};

export const useProfilePicture = (id: number) => {
  return useQuery<string | null, Error>(
    ["profilepicture", id],
    () => {
      return fetchProfilePicture(id).then((blob) => {
        if (blob.size === 0) {
          return null;
        } else {
          return URL.createObjectURL(blob);
        }
      });
    },
    {
      staleTime: ms("10h"),
      enabled: id !== -1 && id !== null && id !== undefined,
    }
  );
};

////////////////////////////////////////////////////////////////////////

export const exportPlayerProfilePDF = (id: number, position: string, date: Date, language: string) => {
  const api = getPlayerApi();
  return api.exportPlayerProfileToPDF(id, position, date, language,
    {
      headers: {
        Accept: "application/pdf",
      },
    }
  );
};

////////////////////////////////////////////////////////////////////////

const fetchTeamPicture = async (id: number): Promise<Blob> => {
  const api = getPlayerApi();
  return api.getTeamPicture(id, {
    headers: {
      Accept: "image/png",
    },
  });
};

export const useTeamPicture = (id: number) => {
  return useQuery<string | null, Error>(
    ["teampicture", id],
    async () => {
      const blob: Blob = await fetchTeamPicture(id);
      if (blob.size === 0) {
        return null;
      }
      return URL.createObjectURL(blob);
    },
    {
      staleTime: ms("10h"),
    }
  );
};

////////////////////////////////////////////////////////////////////////

const fetchPlayersByName = async (name: string): Promise<Player[]> => {
  const api = getPlayerApi();
  return api.getPlayersByName(name);
};

export const usePlayersByName = (name: string) => {
  return useQuery<Player[], Error>(
    ["playersByName", name],
    () => fetchPlayersByName(name),
    {
      staleTime: ms("10h"),
      enabled: !!name, // The query will only run if name has content (i.e., not an empty string)
    }
  );
};

////////////////////////////////////////////////////////////////////////

const fetchHeatmapData = async (
  id: number,
  position: string,
  date: Date
): Promise<Heatmap> => {
  const api = getPlayerApi();
  return api.getHeatmap(id, position, date);
};

export const useHeatmap = (id: number, position: string) => {
  const { date } = usePlayerProfileStore();

  return useQuery<Heatmap, Error>(
    ["heatmap", id, position, formatDate(date)],
    () => fetchHeatmapData(id, position, date),
    {
      staleTime: ms("10h"),
    }
  );
};

////////////////////////////////////////////////////////////////////////

const fetchDataAvailability = async (
  id: number,
  position: string
): Promise<Date[]> => {
  const api = getPlayerApi();
  return api.getDataAvailability(id, position);
};

export const useDataAvailability = (id: number, position: string) => {
  const active = id !== -1 && position !== "";
  return useQuery<Date[], Error>(
    ["dataavailability", id, position],
    () => fetchDataAvailability(id, position),
    {
      staleTime: ms("10h"),
      enabled: active,
    }
  );
};

////////////////////////////////////////////////////////////////////////

const fetchIsInWatchlistOrShadowteam = async (
  id: number,
  position: string
): Promise<number> => {
  const api = getPlayerApi();
  return await api.isInWatchlistOrShadowteam(id, position);
};

export const useIsInWatchlistOrShadowteam = (id: number, position: string) => {
  return useQuery<number, Error>(
    ["isInWatchlistOrShadowteam", id, position],
    () => fetchIsInWatchlistOrShadowteam(id, position),
    {
      staleTime: ms("10h"),
    }
  );
};

////////////////////////////////////////////////////////////////////////

const fetchIsInWatchlist = async (
  id: number,
  position: string
): Promise<number> => {
  const api = getPlayerApi();
  return api.isInWatchlist(id, position);
};

export const useIsInWatchlist = (
  id: number | undefined,
  position: string | undefined
) => {
  const active = id !== undefined && position !== undefined;

  return useQuery<number, Error>(
    ["isInWatchlist", id, position],
    () => {
      // Ensure id and position are defined before calling fetchIsInWatchlist
      if (typeof id === "number" && typeof position === "string") {
        return fetchIsInWatchlist(id, position);
      }
      // Return a default value or throw an error if id or position is undefined
      throw new Error("Invalid arguments for fetchIsInWatchlist");
    },
    {
      staleTime: ms("10h"),
      enabled: active,
    }
  );
};

////////////////////////////////////////////////////////////////////////

const fetchIsInShadowteams = async (id: number): Promise<string[]> => {
  const api = getPlayerApi();
  return api.isInShadowteams(id);
};

export const useIsInShadowteams = (id: number | undefined) => {
  const active = id !== undefined;

  return useQuery<string[], Error>(
    ["isInShadowteams", id],
    () => fetchIsInShadowteams(id!),
    {
      staleTime: ms("5m"),
      enabled: active,
    }
  );
};

////////////////////////////////////////////////////////////////////////

const fetchIsNotInShadowteams = async (
  id: number
): Promise<ShadowteamBaseInfo[]> => {
  const api = getPlayerApi();
  return api.isNotInShadowteams(id);
};

export const useIsNotInShadowteams = (id: number | undefined) => {
  const active = id !== undefined;

  return useQuery<ShadowteamBaseInfo[], Error>(
    ["isNotInShadowteams", id],
    () => fetchIsNotInShadowteams(id!),
    {
      staleTime: ms("5m"),
      enabled: active,
    }
  );
};

///////////////////////////////////////////////////////////////////////

const fetchComments = async (playerId: number): Promise<Comment[]> => {
  const api = getPlayerApi();
  return await api.getComments(playerId);
};

export const useGetComments = (playerId: number) => {
  return useQuery<Comment[], Error>(
    ["comments", playerId],
    () => fetchComments(playerId),
    {
      staleTime: ms("10m"),
      enabled: !!playerId,
    }
  );
};

/////////////////////////////////////////////////////////////////////

const addComment = async ({
  playerId,
  comment,
}: {
  playerId: number;
  comment: string;
}) => {
  const api = getPlayerApi();
  await api.addComment(playerId, { comment });
};

export const useAddComment = (onError: (error: any) => void) => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation(addComment, {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: ["comments", variables.playerId],
      });
      queryClient.invalidateQueries({
        queryKey: ["hasComments", variables.playerId],
      });
    },
    onError: (error) => {
      onError(error);
      console.error("Error adding comment:", error);
    },
  });

  return mutate;
};

/////////////////////////////////////////////////////////////////////

const deleteComment = async ({
  playerId,
  commentId,
}: {
  playerId: number;
  commentId: number;
}) => {
  const api = getPlayerApi();
  await api.deleteComment(playerId, commentId);
};

export const useDeleteComment = () => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation(deleteComment, {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: ["comments", variables.playerId],
      });
      queryClient.invalidateQueries({
        queryKey: ["hasComments", variables.playerId],
      });
    },
    onError: (error) => {
      console.error("Error deleting comment:", error);
    },
  });

  return mutate;
};

/////////////////////////////////////////////////////////////////////

const editComment = async ({
  playerId,
  comment,
}: {
  playerId: number;
  comment: Comment;
}) => {
  const api = getPlayerApi();
  await api.editComment(playerId, comment);
};

export const useEditComment = () => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation(editComment, {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: ["comments", variables.playerId],
      });
    },
    onError: (error) => {
      console.error("Error editing comment:", error);
    },
  });

  return mutate;
};

/////////////////////////////////////////////////////////////////////

const fetchHasComments = async (playerId: number): Promise<boolean> => {
  const api = getPlayerApi();
  return await api.hasComments(playerId);
};

export const useHasComments = (playerId: number) => {
  return useQuery<boolean, Error>(
    ["hasComments", playerId],
    () => fetchHasComments(playerId),
    {
      staleTime: ms("10m"),
      enabled: !!playerId,
    }
  );
};

/////////////////////////////////////////////////////////////////////
const fetchGamesAndGametime = async (
  id: number,
  position: string,
  date: Date
): Promise<GetGamesAndGametime200Response> => {
  const api = getPlayerApi();
  return api.getGamesAndGametime(id, position, date);
};

export const useGetGamesAndGametime = (
  id: number,
  position: string,
  date: Date
) => {
  return useQuery<GetGamesAndGametime200Response, Error>(
    ["gamesAndGametime", id, position, date],
    () => fetchGamesAndGametime(id, position, date),
    {
      staleTime: ms("10h"),
      enabled:
        date &&
        (!isEqual(startOfDay(new Date()), startOfDay(date)) ||
          isLastDayOfMonth(new Date())),
    }
  );
};

/////////////////////////////////////////////////////////////////////

export const useNavigateToPlayer = () => {
  const navigate = useNavigate();
  return (player: Player) =>
    navigate(
      `/player/${player.id}?position=${player.positionsGruppe}&idTm=${player.idTm}`
    );
};

/////////////////////////////////////////////////////////////////////

const fetchSimilarPlayers = async (
  id: number,
  position: string,
  date: Date,
  playerQuery?: PlayerQuery
): Promise<Array<Player>> => {
  const api = getPlayerApi();
  return api.getSimilarPlayers(id, position, date, playerQuery);
};

export const useSimilarPlayers = (
  id: number,
  position: string
) => {
  const { playerQuery } = useSimilarPlayersQueryStore();
  const { date } = usePlayerProfileStore();

  return useQuery<Array<Player>, Error>(
    ["similar", id, position, date, playerQuery],
    () => fetchSimilarPlayers(id, position, date, playerQuery),
    {
      staleTime: ms("10h"),
    }
  );
};

/////////////////////////////////////////////////////////////////////////////

export const fetchAvailableKpiClips = async (
  id: number,
  position: string
): Promise<KpiClip[]> => {
  const api = getPlayerApi();
  return api.getAvailableKpiClips(id, position);
};

export const useAvailableKpiClips = (id: number | undefined, position: string | undefined) => {
  return useQuery<KpiClip[], Error>(
    ["availableClips", id, position],
    () => fetchAvailableKpiClips(id!, position!),
    {
      staleTime: ms("10h"),
      enabled: id !== -1 && id !== undefined && position !== null && position !== undefined,
    }
  );
};

/////////////////////////////////////////////////////////////////////////////

export const fetchKpiClip = async (
  id: number,
  position: string,
  kpi: string,
  matchId: number,
  start: number,
  end: number
): Promise<Blob> => {
  const api = getPlayerApi();
  return api.getKpiClip(id, position, kpi, matchId, start, end);
};

export const useKpiClip = (id: number, position: string, kpi: string, matchId: number | undefined, start: number | undefined, end: number | undefined, enabled: boolean) => {
  return useQuery<Blob, Error>(
    [id, position, kpi, matchId, start, end],
    () => fetchKpiClip(id, position, kpi, matchId!, start!, end!),
    {
      staleTime: ms("10h"),
      enabled: id !== -1 && kpi !== null && kpi != "" && matchId !== undefined && start !== undefined && end !== undefined && enabled && position !== null && position !== undefined,
    }
  );
};
