import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useKeycloak } from "@react-keycloak/web";
import React, { useContext, useEffect, useRef, useState } from "react";
import { colorsPrimary, colorsSecondary, textColors } from "../../../colorStyles";
import { ShadowteamBaseInfo } from "../../../generated";
import {
  useIsInShadowteams,
  useIsInWatchlist,
  useIsNotInShadowteams,
} from "../../../hooks/usePlayer";
import { useAddPlayerToShadowteam } from "../../../hooks/useShadowteams";
import {
  useAddPlayerToWatchlist,
  useDeletePlayerFromWatchlist,
} from "../../../hooks/useWatchlist";
import { usePlayerModalStore } from "../../../store/store";
import { hexToRgba } from "../../../util/utilFunctions";
import { formationMapping } from "../../shadowteams/formation/FormationsAndPositions";
import { LanguageContext } from "../../../store/languageContext";
import { LanguageKeys } from "../../../language/LanguageKeys";
import { text } from "d3";
import { colors } from "@mui/material";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  location: "search" | "profile" | "watchlist" | "comparison" | "shadowteams" | "home";
}

const PlayerModal = React.memo(({ isOpen, onClose, location }: Props) => {
  const { guiText, language } = useContext(LanguageContext);
  const initialRef = useRef(null);

  const k = useKeycloak();

  const { player } = usePlayerModalStore();
  const addPlayerToWatchlist = useAddPlayerToWatchlist();
  const deletePlayerFromWatchlist = useDeletePlayerFromWatchlist();

  const playerId = player ? player.id : undefined;
  const position = player ? player.positionsGruppe : undefined;

  const { data: isInWatchlist } = useIsInWatchlist(playerId, position);

  const addPlayerToShadowteam = useAddPlayerToShadowteam();

  const { data: isInShadowteams } = useIsInShadowteams(playerId);
  const { data: isNotInShadowteams } = useIsNotInShadowteams(playerId);

  const [selectedShadowteam, setSelectedShadowteam] = useState<
    ShadowteamBaseInfo | undefined
  >(undefined);
  const [selectedPosition, setSelectedPosition] = useState(guiText[LanguageKeys.SearchBarPosition]);
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    setSelectedPosition(guiText[LanguageKeys.SearchBarPosition]);
  }, [language]);

  const handleClose = () => {
    setSelectedShadowteam(undefined);
    setSelectedPosition(guiText[LanguageKeys.SearchBarPosition]);
    setShowWarning(false);
    onClose();
  };

  const handleSelect = (shadowteamBaseInfo: ShadowteamBaseInfo) => {
    setSelectedShadowteam(shadowteamBaseInfo);
    setSelectedPosition(guiText[LanguageKeys.SearchBarPosition]);
  };

  const handleAddPlayerToShadowteam = () => {
    if (selectedShadowteam && selectedPosition != guiText[LanguageKeys.SearchBarPosition]) {
      addPlayerToShadowteam({
        shadowteamId: selectedShadowteam?.shadowteamId,
        playerId: playerId!,
        shadowteamPosition: selectedPosition,
      });

      setSelectedShadowteam(undefined);
      setSelectedPosition(guiText[LanguageKeys.SearchBarPosition]);
      setShowWarning(false);
    } else {
      setShowWarning(true);
    }
  };

  const isInWatchlistBool =
    isInWatchlist != undefined && parseInt(isInWatchlist + "") == 1;

  return (
    <Modal
      initialFocusRef={initialRef}
      isOpen={isOpen}
      onClose={handleClose}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent backgroundColor={colorsSecondary.cardBackground} color={textColors.primary}>
        <ModalHeader borderBottom="2px solid #CBD5E0">
          {location == "watchlist"
            ? guiText[LanguageKeys.SearchBarZurSchattenmannschaftHinzufugen]
            : guiText[LanguageKeys.SearchBarZurWatchlistOderSchattenmannschaftHinzufugen]
          }
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6} pt={4}>
          {location != "watchlist" && (
            <>
              <Box pb={5}>
                <Heading size="md" pb={2}>
                  {guiText[LanguageKeys.SidebarWatchlist]}
                </Heading>
                {isInWatchlistBool ? (
                  <>
                    <Text pb={3}>
                      {guiText[LanguageKeys.DieserSpielerIstBereitsInDeinerWatchlist]}
                    </Text>
                    <Button
                      // backgroundColor="white"
                      variant="outline"
                      textColor={colorsSecondary.accent}
                      boxShadow="sm"
                      fontSize="sm"
                      height="auto"
                      border={"1px solid " + textColors.primary}
                      p={2}
                      onClick={() =>
                        deletePlayerFromWatchlist({
                          playerId: player!.id,
                          position: player!.positionsGruppe,
                        })
                      }
                    >
                      {guiText[LanguageKeys.VonderWatchlistEntfernen]}
                    </Button>
                  </>
                ) : (
                  <>
                    <Text pb={3}>
                      {guiText[LanguageKeys.SearchBarDieserSpielerIstNochNichtInDeinerWatchlist]}
                    </Text>
                    <Button
                      backgroundColor={colorsSecondary.accent}
                      textColor="white"
                      boxShadow="sm"
                      fontWeight="thin"
                      fontSize="sm"
                      height="auto"
                      p={2}
                      onClick={() =>
                        addPlayerToWatchlist({
                          playerId: player!.id,
                          positionsGruppe: player!.positionsGruppe,
                          comment: "",
                          favorite: false,
                        })
                      }
                    >
                      {guiText[LanguageKeys.SearchBarZurWatchlistHinzufugen]}
                    </Button>
                  </>
                )}
              </Box>
              <Divider borderColor="gray.300" />
            </>
          )}
          <Box pt={3}>
            <Heading size="md" pb={2}>
              {guiText[LanguageKeys.SidebarSchattenmannschaften]}
            </Heading>
            {isInShadowteams && isInShadowteams.length > 0 ? (
              <>
                <Text pb={1}>
                  {guiText[LanguageKeys.DieserSpielerIstBereitsInFolgendenSchattenmannschaften]}
                </Text>
                <HStack pb={3}>
                  {isInShadowteams.map((shadowteam, idx) => (
                    <Badge key={idx} backgroundColor={colorsSecondary.accent} p={1} pl={2} pr={2}>
                      <Text color={textColors.primary}>{shadowteam}</Text>
                    </Badge>
                  ))}
                </HStack>
              </>
            ) : (
              <Text pb={2}>
                {guiText[LanguageKeys.SearchBarDieserSpielerIstNochInKeinerSchattenmannschaft]}
              </Text>
            )}
            {isNotInShadowteams && isNotInShadowteams.length > 0 && (
              <>
                <HStack pb={showWarning ? 1 : 3}>
                  <Menu>
                    <MenuButton
                      as={Button}
                      rightIcon={<ChevronDownIcon />}
                      width="60%"
                      backgroundColor={colorsSecondary.cardBackground}
                      outline={
                        "2px solid " + hexToRgba(textColors.lightgrey, 0.4)
                      }
                    >
                      <Text align="left" fontWeight="thin">
                        {selectedShadowteam
                          ? selectedShadowteam.shadowteamName
                          : guiText[LanguageKeys.Schattenmannschaft]}
                      </Text>
                    </MenuButton>
                    <MenuList zIndex={999} backgroundColor={colorsSecondary.background}>
                      {isNotInShadowteams.map((shadowteam, idx) => (
                        <MenuItem
                          key={idx}
                          id={idx + ""}
                          onClick={() => handleSelect(shadowteam)}
                          backgroundColor={colorsSecondary.background}
                        >
                          {shadowteam.shadowteamName}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>
                  <Menu>
                    <MenuButton
                      as={Button}
                      rightIcon={<ChevronDownIcon />}
                      width="40%"
                      backgroundColor={colorsSecondary.cardBackground}
                      outline={
                        "2px solid " + hexToRgba(textColors.lightgrey, 0.4)
                      }
                      isDisabled={selectedShadowteam == undefined}
                    >
                      <Text align="left" fontWeight="thin">
                        {selectedPosition}
                      </Text>
                    </MenuButton>
                    <MenuList zIndex={999} backgroundColor={colorsSecondary.cardBackground}>
                      {selectedShadowteam &&
                        formationMapping
                          .get(selectedShadowteam.formation)!
                          .map((position, idx) => (
                            <MenuItem
                              key={idx}
                              id={idx + ""}
                              onClick={() => setSelectedPosition(position.name)}
                              backgroundColor={colorsSecondary.cardBackground}
                            >
                              {position.name}
                            </MenuItem>
                          ))}
                      <MenuItem
                        key={99}
                        id={"BENCH"}
                        onClick={() => setSelectedPosition(guiText[LanguageKeys.SchattenmannschaftenBank])}
                        backgroundColor={colorsSecondary.background}
                      >
                        {guiText[LanguageKeys.SchattenmannschaftenBank]}
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </HStack>
                {showWarning && (
                  <Text color="red" fontSize="sm" pb={1}>
                    {guiText[LanguageKeys.WaehleEineSchattenmannschaftUndEinePositionAus]}
                  </Text>
                )}
                <Box display="flex" justifyContent="end">
                  <Button
                    backgroundColor={colorsSecondary.accent}
                    textColor="white"
                    boxShadow="sm"
                    fontWeight="thin"
                    fontSize="sm"
                    height="auto"
                    p={2}
                    onClick={handleAddPlayerToShadowteam}
                  >
                    {guiText[LanguageKeys.SearchBarZurSchattenmannschaftHinzufugen]}
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button
            onClick={handleClose}
            variant="outline"
            color={colorsSecondary.accent}
          >
            {guiText[LanguageKeys.SearchBarSchliessen]}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});

export default PlayerModal;
