import { FilterPreset } from "../generated";
import { getFilterPresetApi } from "../util/keycloak";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useFilterPresetStore } from "../store/store";
import ms from "ms";
import useShowToast from "./useShowToast";

const addFilterPreset = async (filterPreset: FilterPreset) => {
  const api = getFilterPresetApi();
  return await api.saveFilterPreset(filterPreset);
};

const deleteFilterPreset = async (id: number) => {
  const api = getFilterPresetApi();
  return await api.deleteFilterPreset(id);
};

const getFilterPresets = async () => {
  const api = getFilterPresetApi();
  return await api.getFilterPresets();
};

export const useAddFilterPreset = () => {
  const queryClient = useQueryClient();
  const { setActivePreset } = useFilterPresetStore();
  const showToast = useShowToast();

  const { mutate } = useMutation(addFilterPreset, {
    onSuccess: (newPresetId, filterPreset) => {
      queryClient.invalidateQueries({ queryKey: ["filterPresets"] });
      setActivePreset({ ...filterPreset, id: newPresetId });
      showToast("add", false);
    },
    onError: (error) => {
      console.error("Error editing preset:", error);
      showToast("add", true);
    },
  });

  return mutate;
};

export const useDeleteFilterPreset = () => {
  const queryClient = useQueryClient();

  const { getActivePreset, clearActivePreset } = useFilterPresetStore();

  const { mutate } = useMutation(deleteFilterPreset, {
    onSuccess: (data, deletedPresedId) => {
      queryClient.invalidateQueries({ queryKey: ["filterPresets"] });

      if (deletedPresedId == getActivePreset()?.id) {
        clearActivePreset();
      }
    },
    onError: (error) => {
      console.error("Error editing preset:", error);
    },
  });
  return mutate;
};

const editFilterPreset = async ({
  presetId,
  filterPreset,
}: {
  presetId: number;
  filterPreset: FilterPreset;
}) => {
  const api = getFilterPresetApi();
  return await api.editFilterPreset(presetId, filterPreset);
};

export const useEditFilterPreset = () => {
  const queryClient = useQueryClient();
  const { setActivePreset } = useFilterPresetStore();
  const showToast = useShowToast();

  const { mutate } = useMutation(editFilterPreset, {
    onSuccess: (updatedPresetId, { presetId, filterPreset }) => {
      queryClient.invalidateQueries({ queryKey: ["filterPresets"] });
      setActivePreset(filterPreset);
      showToast("edit", false);
    },
    onError: (error) => {
      console.error("Error editing preset:", error);
      showToast("edit", true);
    },
  });

  return mutate;
};

export const useFilterPresets = () => {
  const { data, error, ...rest } = useQuery<FilterPreset[], Error>(
    ["filterPresets"],
    getFilterPresets,
    { staleTime: ms("24h") }
  );

  return { data, error, ...rest };
};
