import {
  Box,
  HStack,
  Icon,
  Text,
} from "@chakra-ui/react";
import { ThemeProvider } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { endOfMonth } from "date-fns";
import { useContext, useEffect, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { FilterSpecification } from "../../../generated";
import { FilterValueStoreCore } from "../../../store/store";
import theme from "../../../themes/playerTableTheme";
import { filterOpacity } from "../searchStyles";
import { LocaleKeys } from "../../../language/LocaleKeys";
import { LanguageContext } from "../../../store/languageContext";
import { colorsSecondary, textColors } from "../../../colorStyles";

interface Props {
  filter: FilterSpecification;
  filterValueFunc: FilterValueStoreCore;
}

const DateSelectorFilter = ({ filter, filterValueFunc }: Props) => {
  const { language } = useContext(LanguageContext);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  useEffect(() => {
    const filterString = filterValueFunc.filterValues[filter.specId - 1]?.value;
    if (filterString !== null && filterString !== undefined) {
      const parsedFilter = JSON.parse(filterString);
      setSelectedDate(new Date(parsedFilter.date));
    } else {
      setSelectedDate(null);
    }
  }, [filterValueFunc.filterValues[filter.specId - 1]]);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(endOfMonth(date!));
    filterValueFunc.addFilter({
      specId: filter.specId,
      value: JSON.stringify({ date: endOfMonth(date!) }),
    });
  };

  const active = selectedDate != null;

  const currentYear = new Date().getFullYear();

  // Setting minDate and maxDate
  const minDate = new Date(currentYear - 1, 0, 1); // January 1st of the previous year
  const maxDate = new Date(currentYear + 10, 11, 31); // December 31st, 10 years from now

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box w="100%" data-cy={"dateSelectorFilter"}>
          <HStack
            marginBottom={2}
            justifyContent="space-between"
            opacity={active ? filterOpacity.active : filterOpacity.notActive}
          >
            <Text fontSize="md" color={textColors.primary}>{language == LocaleKeys.DE ? filter.labelGerman : language == LocaleKeys.EN ? filter.labelEnglish : filter.labelSpanish}</Text>
            <Icon
              as={AiFillCloseCircle}
              color="gray.500"
              style={{ cursor: "pointer" }}
              onClick={() => filterValueFunc.removeFilter(filter.specId)}
              boxSize={4}
              data-cy={"resetButton"}
            ></Icon>
          </HStack>
          <DatePicker
            slotProps={{
              textField: { fullWidth: true, size: "small" },
              inputAdornment: { position: "start" },
              popper: {
                sx: {
                  "& .MuiPaper-root": {
                    backgroundColor: colorsSecondary.cardBackground,
                    color: textColors.primary,// Set Paper background color
                  },
                  "& .MuiYearPicker-root": {
                    backgroundColor: colorsSecondary.cardBackground, // Year picker background color
                    color: textColors.primary,
                  },
                  "& .MuiMonthPicker-root": {
                    backgroundColor: colorsSecondary.cardBackground,
                    color: textColors.primary, // Month picker background color
                  },
                  "& .MuiPickersCalendarHeader-root": {
                    backgroundColor: colorsSecondary.cardBackground,
                    color: textColors.primary,
                  },
                },
              },
            }}
            minDate={minDate}
            maxDate={maxDate}
            views={["year", "month"]}
            value={selectedDate}
            onAccept={handleDateChange}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "1px solid #CBD5E0",
                },
                "& .MuiSvgIcon-root": {
                  fill: textColors.lightgrey,
                },
                "& .MuiInputBase-input": {
                  color: textColors.primary, // Change text color
                },
                "& .MuiPickersDay-dayWithMargin": {
                  color: colorsSecondary.accent, // Change day color
                },
                "& .MuiPickersDay-today": {
                  borderColor: colorsSecondary.accent, // Change today's border color
                },
                "& .MuiPickersDay-root.Mui-selected": {
                  backgroundColor: colorsSecondary.accent, // Change selected day background color
                },
              },

            }}
          />
        </Box>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default DateSelectorFilter;
