import { Box, Flex, HStack, Image, Text } from "@chakra-ui/react";
import { Injury } from "../../../generated";
import { formatDate } from "../../../util/utilFunctions";
import { LanguageKeys } from "../../../language/LanguageKeys";
import { GuiTextType } from "../../../store/languageContext";
import { colorsSecondary, textColors } from "../../../colorStyles";

interface Props {
  injury: Injury;
  guiText: GuiTextType;
}
const InjuryBar = ({ injury, guiText }: Props) => {
  let missedMatchesString = "";
  if (injury.verletzungMissedMatches) {
    const spieleString = injury.verletzungMissedMatches > 1 ? guiText[LanguageKeys.SpielerprofilSpiele] : guiText[LanguageKeys.SpielerprofilSpiel];
    missedMatchesString = ` (${injury.verletzungMissedMatches} ${spieleString})`;
  }
  const verletzungTage = injury.verletzungTage || "";
  const modifiedString = verletzungTage.replace("Tage", "");


  return (
    <HStack width="100%" bg={colorsSecondary.cardBackground} spacing={0} pl={2} boxShadow="md" py={3}>
      <Box w="20%">
        <Text color={textColors.primary}>
          {injury.verletzungStart && formatDate(injury.verletzungStart)}
        </Text>
      </Box>
      <Box w="20%">
        <Text color={textColors.primary}>
          {injury.verletzungEnde && formatDate(injury.verletzungEnde)}
        </Text>
      </Box>
      <Box w="30%">
        <Text color={textColors.primary}>
          {modifiedString + " " + guiText[LanguageKeys.Tage] + " " + missedMatchesString}
        </Text>
      </Box>
      <Box w="30%">
        <Text color={textColors.primary}>{injury.verletzung}</Text>
      </Box>
    </HStack>
  );
};

export default InjuryBar;
