import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { toNewFormation } from "../components/shadowteams/shadowteamUtilFunctions";
import { Formation, Shadowteam } from "../generated";

interface Props {
  shadowteam: Shadowteam | undefined;
  existingFormations: Formation[] | undefined;
  deleteFormation: (params: {
    shadowteamId: number;
    formation: string;
  }) => void;
  saveShadowteam: (shadowteam: Shadowteam) => void;
  setShadowteam: (shadowteam: Shadowteam | undefined) => void;
}
const useFormationManagement = ({
  shadowteam,
  existingFormations,
  deleteFormation,
  saveShadowteam,
  setShadowteam,
}: Props) => {
  const navigate = useNavigate();

  const handleFormationDeletion = useCallback(
    (formation: string) => {
      if (!shadowteam?.shadowteamId) return;

      deleteFormation({
        shadowteamId: shadowteam.shadowteamId,
        formation,
      });

      const searchParams = new URLSearchParams(window.location.search);
      searchParams.delete("formation");
      navigate(`?${searchParams.toString()}`);
    },
    [deleteFormation, navigate, shadowteam?.shadowteamId]
  );

  const handleFormationChange = useCallback(
    (formation: string) => {
      if (!shadowteam) return;

      if (
        existingFormations?.some(
          (existingFormation) => existingFormation.name === formation
        )
      ) {
        addQueryParameters(formation);
      } else {
        const updatedFormation = toNewFormation(shadowteam, formation);
        setShadowteam(updatedFormation);
        saveShadowteam(updatedFormation);
        addQueryParameters(formation);
      }
    },
    [existingFormations, saveShadowteam, setShadowteam, shadowteam]
  );

  const addQueryParameters = useCallback(
    (formation: string) => {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set("formation", formation);
      navigate(`?${searchParams.toString()}`);
    },
    [navigate]
  );

  return { handleFormationDeletion, handleFormationChange };
};

export default useFormationManagement;
